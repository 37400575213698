import {
  fetchSheetsInProduction,
  sendAssemblySheetWasSendToProductionNotification,
} from '../../../../operations/sheets';

import {
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_MAIN_ROUTE,
} from '../../../../constants/routes';
import { matchPath } from 'react-router-dom';
import { fetchEquipmentClassInDepartmentTasksRemoteTableData } from '../../../../operations/tasks';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { fetchSheetTypeRemoteTableDataCbFactory } from '../../../../components/Sheets/SheetsContainer';

import { getEquipmentClassInDepartmentTasksTableId } from '../../../../utils/tables';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import { fetchMasterDepartmentsTasksToDoRemoteTableData } from '../../../../operations/masterWorkspace/index';
import { getMasterAppDepartmentIdsFromRouteParam } from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { masterTasksToDoAdditionalFiltersSelector } from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import { fetchFullAssemblySheetsPartsAndMaterials } from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';

/*
 * Подробно о влиянии события "отдать в работу" на типы приложения описано в комментарии к
 * _handleAssemblySheetWasSendToProduction в PartsAndMaterialsReservedAssemblySheetContainer.
 *  т.к. _handleAssemblySheetWasSendToProduction выполняется для пользователя, который сам передаёт МЛ в работу
 * из раздела Комплектование -> Резервирование, то в нём для всех остальных разделов, где надо обновить данные,
 * в основном, сбрасывается "клиентский кэш" (т.е. данные из стора), чтобы, при повторном входе в интерфейсы,
 * запрос выполнился по новой.
 *
 *  В случае с получением события "отлать в работу" другими пользователями нужна похожая
 * обработка, но, с учетом, того, что пользователи могут находиться в этот момент в разделах, которые сразу же должны
 * быть обновлены - в этом случае,  сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если
 * перед перезапросом данных мы сначала очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим
 * новые данные, то у пользователя в интерфейсе случится "скачок" (данные пропадут, кое-где мелькнет сообщение
 * о пустых данных, а потом тут же появятся новые данные), что нежелательно.
 *
 * Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
 * повторном входе в интерфейсы запросы выполнились заново
 * */
export const handleAssemblySheetWasSendToProduction = message =>
  (dispatch, getState) => {
    const {
      sheetIdentity,
    } = message;

    sendAssemblySheetWasSendToProductionNotification(sheetIdentity);

    const state = getState();

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);


    //Если находимся в разделе "Плановик. МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });
    if(sheetsInProductionRouteMatch !== null)
      return dispatch(_updateIfOnSheetsInProductionScreen(allTasksTablesIds));


    //Если находимся в разделе "Комплектование" - "сборочные"
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if (assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null) {
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(allTasksTablesIds));
    }

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
      ));


    //Если находимся в разделе "Рабочий. Просмотр заданий для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_MAIN_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
      ));
    }

    /*
     * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
     * очистить данные всех интерфейсов, где должны быть обновления из-за передачи МЛ в работу, чтобы при следующем
     * входе в эти разделы данные были запрошены заново
     * */
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),
    ]);
  };

/*
 * Если находимся в разделе "Плановик. МЛ в производстве", то очищаем все данные других интерфейсов кроме этого, где
 * должны быть обновления из-за передачи МЛ в работу, а для этого раздела перезапрашиваем данные списка для текущей
 * страницы
 * */
const _updateIfOnSheetsInProductionScreen = allTasksTablesIds =>
  dispatch => {
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.IN_PRODUCTION,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchSheetsInProduction),
    ));
  };


/*
 * Если находимся в разделе "Комплектование. Сборочные", то очищаем все данные других интерфейсов кроме
 * этого, где должны быть обновления из-за передачи МЛ в работу, а для этого раздела перезапрашиваем данные списка для
 * текущей страницы. Также после передачи в работу кнопка "отдать в работу" должна удалиться/задизейблиться, это
 * зависит от флага providingState, который автоматически обновляется при обновлении данных всего списка сборочных МЛ.
 * */
const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = allTasksTablesIds =>
  dispatch => {
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),
    ]);

    return dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchFullAssemblySheetsPartsAndMaterials),
    ));
  };

/*
 * Если находимся в разделе "Мастер. Требуется выполнить", то очищаем все данные других интерфейсов кроме этого, где
 * должны быть обновления из-за передачи МЛ в работу, а для этого раздела перезапрашиваем данные для таблицы заданий,
 * которые нужно выполнить в подразделениях из параметров роутинга, с учетом текущих параметров таблицы
 * */
const _updateIfOnMasterTasksTodoScreen = (departmentIds, allTasksTablesIds) =>
  (dispatch, getState) => {
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
    ]);

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_ID,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(departmentIds, masterTasksToDoAdditionalFilters, tableParams)),
    ));
  };


/*
 * Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем все данные других
 * интерфейсов кроме этого, где должны быть обновления передачи МЛ в работу, а для этого раздела перезапрашиваем
 * задания для текущих параметров просматриваеомй таблицы, чтобы информация обновилась
 * */
const _updateIfOnTasksViewScreen = (
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
) =>
  dispatch => {
    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
    ]);

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
        )),
    ));
  };