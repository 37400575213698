import React from 'react';
import { sheetOperationFeaturesValuesSelector } from '../../../../reducers/sheetOperationReview/selectors';
import _isEmpty from 'lodash/isEmpty';
import { deleteSheetOperationFeatures } from '../../../../reducers/sheetOperationReview/actions';
import { NOTIFICATION_LEVEL, sendNotification } from '../../../../constants/notification';
import { Trans } from '@lingui/macro';


/*
Обработчик события удаления дополнительной характеристики операции МЛ из раздела "Администрирование". Удаление
характеристики на данный момент влияет только на экран просмотра операции, на котором после удаления должен обновиться
список значений характеристик.
*/
export const handleSheetOperationFeatureDeleted = message =>
  (dispatch, getState) => {

    const {
      sheetOperationFeatureId,
    } = message;

    const state = getState();

    const sheetOperationFeaturesValues = sheetOperationFeaturesValuesSelector(state);

    /*
    sheetOperationFeaturesValues очищается на unmount компонента SheetOperationFeaturesValuesForm. Если в store нет
    данных, то ничего не делаем, т.к. данные значений характеристик для операции будут запрошены при открытии экрана
    просмотра операции.
    */
    if (_isEmpty(sheetOperationFeaturesValues)) return;

    /*
    Ищем значение для удалённой характеристики
    */
    const deletedSheetOperationFeature = sheetOperationFeaturesValues[sheetOperationFeatureId];


    /*
    Если для текущей операции используется удаленная характеристика, то удаляем её из store. Характеристика пропадет
    из интерфейса автоматически
    */
    if (deletedSheetOperationFeature) {
      sendNotification(
        <Trans id="sheet_operation_review@sheet_operation_feature_deleted">
          Характеристика {deletedSheetOperationFeature.name} была удалена
        </Trans>,
        NOTIFICATION_LEVEL.INFO,
      );

      dispatch(deleteSheetOperationFeatures([
        sheetOperationFeatureId,
      ]));
    }
  };