import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import {
  FUNC_IS_REQUIRED_TYPE,
  TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
  TABLE_FILTER_PARAMS_TYPE,
} from '../../../../constants/propTypes';

import { Filters } from '../../../common/Filters/Filters';

import './style.css';
import {
  AddFilterLabelTrans,
  AllAvailableFiltersSetLabelTrans,
  ApplyFilterLabelTrans,
  CancelLabelTrans,
  ChooseFiltersLabelTrans,
  ChooseTableColumnToFilterLabelTrans,
  NoMatchesFoundLabelTrans,
  RemoveAllFiltersLabelTrans,
} from '../../../../utils/commonTransComponents';


export class TableFilters extends Component {
  static propTypes = {
    changeFilters: FUNC_IS_REQUIRED_TYPE,
    filterParams: TABLE_FILTER_PARAMS_TYPE,
    customFilters: TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
    schemaFields: PropTypes.objectOf(
      PropTypes.shape({
        columnName: PropTypes.string.isRequired,
        displayName: PropTypes.node.isRequired,
        disableFilter: PropTypes.bool,
      }),
    ),
  };

  _getFilterSchema = () => {
    const {
      schemaFields,
      customFilters = {},
    } = this.props;

    return Object
      .values(schemaFields)
      .filter(({ disableFilter }) => !disableFilter)
      .map(({ columnName, displayName }) => {
        const {
          filterComponent,
          filterComponentProps = {},
          getFilterChipContent,
          renderFilterChip,
          getFilterTitle = () => displayName,
        } = customFilters[columnName] || {};

        return {
          getFilterChipContent,
          renderFilterChip,
          getFilterTitle,
          filterKey: columnName,
          filterComponent,
          filterComponentProps,
        };
      });
  };

  render() {
    const {
      filterParams,
      changeFilters,
    } = this.props;

    return (
      <div className="table-filters">
        <Paper className="table-filters__paper" elevation={0}>
          <Filters
              withFiltersEditing
              filterSchema={this._getFilterSchema()}
              filters={filterParams}
              onApplyFilters={changeFilters}
              filtersModalTitle={ChooseFiltersLabelTrans}
              applyFiltersBtnTitle={ApplyFilterLabelTrans}
              cancelFiltersBtnTitle={CancelLabelTrans}
              addFiltersBtnTitle={AddFilterLabelTrans}
              removeAllFiltersBtnTitle={RemoveAllFiltersLabelTrans}
              chooseFilterToAddPlaceholderTitle={ChooseTableColumnToFilterLabelTrans}
              allAvailableFiltersAddedPlaceholderTitle={AllAvailableFiltersSetLabelTrans}
              createFilterControlNoOptionsTitle={NoMatchesFoundLabelTrans}
          />
        </Paper>
      </div>
    );
  }
}
