import React from 'react';
import PropTypes from 'prop-types';
import { TableCellRenderer } from '../TableCellRenderer';
import { NUMBER_OR_STRING_TYPE } from '../../../../constants/propTypes';
import { NotAssignedLabelTrans } from '../../../../utils/commonTransComponents';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export class MasterTasksEquipmentCombinedDataRenderer extends TableCellRenderer {

  static propTypes = {
    data: PropTypes.shape({
      equipmentId:  NUMBER_OR_STRING_TYPE,
      equipment__identity: PropTypes.string,
      equipment__name: PropTypes.string,
    }),
  };

  static getViewValue(props) {
    const {
      data: {
        equipmentId,
        equipment__identity,
        equipment__name,
      },
    } = props;

    if(equipmentId === null) {
      return null;
    }

    return getResourceCombinedName({ identity: equipment__identity, name: equipment__name });
  };

  render() {
    const viewValue = MasterTasksEquipmentCombinedDataRenderer.getViewValue(this.props);

    return(
      <div title={viewValue}>
        {
          viewValue === null ?
            NotAssignedLabelTrans :
            viewValue
        }
      </div>
    );
  }
}
