import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import _find from 'lodash/find';

import { WORKER_TASKS_TABLE_MAIN_SETTINGS_ID } from '../../../../constants/settings';

import {
  FUNC_IS_REQUIRED_TYPE,
  NUMBER_OR_STRING_TYPE,
  SETTINGS_SELECT_OPTION_TYPE,
} from '../../../../constants/propTypes';

import cn from 'classnames';

import './style.css';
import {
  DeleteLabelTrans,
  DepartmentLabelTrans,
  EditLabelTrans,
  EquipmentClassLabelTrans,
  NoDataLabelTrans,
  NotSelectedLabelTrans,
  SettingsLabelTrans,
} from '../../../../utils/commonTransComponents';
import { DefaultAutocompleteContainer } from '../../../common/Autocomplete/AutocompleteContainer';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';


export class WorkerTasksTableSettingsControlPanel extends Component {

  static propTypes = {
    isDisabled: PropTypes.bool,
    startNewSettingsCreating: FUNC_IS_REQUIRED_TYPE,
    startNewSettingsCreatingUsingSelectedAsTemplate: FUNC_IS_REQUIRED_TYPE,
    selectedSettingsId: NUMBER_OR_STRING_TYPE.isRequired,
    settingsSelectOptions: PropTypes.arrayOf(SETTINGS_SELECT_OPTION_TYPE).isRequired,
    selectSettings: FUNC_IS_REQUIRED_TYPE,
    startSettingsEditing: FUNC_IS_REQUIRED_TYPE,
    deleteSelectedSettings: FUNC_IS_REQUIRED_TYPE,
    isDeleteSettingsButtonVisible: PropTypes.bool,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  _renderCreateNewSettingsButtons = () => {
    const {
      startNewSettingsCreating,
      startNewSettingsCreatingUsingSelectedAsTemplate,
    } = this.props;
    return(
      <div className="worker-tasks-table-settings-control-panel__create-new-settings-buttons">
        <Button
            onClick={startNewSettingsCreating}
            variant={MATERIAL_UI_VARIANT.CONTAINED}
            color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
        >
          <Trans id="worker_tasks_table_settings@create_new_settings">
            Создать новые настройки
          </Trans>
        </Button>
        <Button
            onClick={startNewSettingsCreatingUsingSelectedAsTemplate}
            variant={MATERIAL_UI_VARIANT.CONTAINED}
            color={MATERIAL_UI_STYLE_COLOR.INHERIT}
        >
          <Trans id="worker_tasks_table_settings@create_settings_using_selected_as_template">
            Создать новые настройки на основании выбранных
          </Trans>
        </Button>
      </div>
    );
  };

  _renderSettingsSelectTitle = () =>
    <div className="worker-tasks-table-settings-control-panel__settings-select-title">
      {SettingsLabelTrans}
    </div>;

  _renderSettingsSelect = () => {
    const {
      selectedSettingsId,
      selectSettings,
      settingsSelectOptions,
    } = this.props;

    const value = _find(settingsSelectOptions, ({ id }) => id === selectedSettingsId);

    return (
      <DefaultAutocompleteContainer
          value={value}
          onChange={selectSettings}
          options={settingsSelectOptions}
          getOptionLabel={this.getSettingsSelectOptionLabel}
          isClearable={false}
          placeholder={NotSelectedLabelTrans}
          noOptionsMessage={NoDataLabelTrans}
          wrapperClassName="worker-tasks-table-settings-control-panel__settings-select"
      />
    );
  };

  getSettingsSelectOptionLabel = ({ id, value }) => {

    if(id === WORKER_TASKS_TABLE_MAIN_SETTINGS_ID)
      return (
        <React.Fragment>
          <Trans id="worker_tasks_table_settings@main_settings">
            Основные настройки
          </Trans>
          {' '}
          {'('}
          <Trans id="worker_tasks_table_settings@main_settings_description">
            Общие настройки распространяются на отображение заданий для всех подразделений и классов РЦ кроме
            исключений
          </Trans>
          {')'}
        </React.Fragment>
      );

    /*
    * От прошлой реализации осталась логика, согласно которой мы храним в настройках в БД и клиентский идентификатор и
    * имя (кроме идентификатора БД), чтобы иметь возможность отобразить их в случае, если после переимпорта данных таких
    * сущностей уже нет в системе.
    * */

    const {
      departmentIdentity,
      departmentName,
      equipmentClassIdentity,
      equipmentClassName,
    } = value;

    return this._getSelectOptionLabel(
      departmentIdentity,
      departmentName,
      equipmentClassIdentity,
      equipmentClassName,
    );
  }


  _getSelectOptionLabel = (
    departmentIdentity,
    departmentName,
    equipmentClassIdentity,
    equipmentClassName,
  ) => {

    const departmentTitleContent = this._getSelectOptionTitleContent(DepartmentLabelTrans, departmentIdentity, departmentName);

    if(!equipmentClassIdentity) return departmentTitleContent;

    const equipmentClassTitleContent =
      this._getSelectOptionTitleContent(EquipmentClassLabelTrans, equipmentClassIdentity, equipmentClassName);

    return(
      <React.Fragment>
        {departmentTitleContent}
        {', '}
        {equipmentClassTitleContent}
      </React.Fragment>
    );

  };

  _getSelectOptionTitleContent = (labelTrans, identity, name) =>
    <React.Fragment>
      {labelTrans}
      {': '}
      {this._getEntityFullName(identity, name)}
    </React.Fragment>;


  _getEntityFullName = (identity, name) => [
    name,
    `(${identity})`,
  ].join(' ');

  _renderSettingsSelectControlButtons = () => {
    const {
      startSettingsEditing,
      deleteSelectedSettings,
      isDeleteSettingsButtonVisible,
    } = this.props;
    return(
      <React.Fragment>
        <Button
            onClick={startSettingsEditing}
            variant={MATERIAL_UI_VARIANT.OUTLINED}
            color={MATERIAL_UI_STYLE_COLOR.INHERIT}
        >
          {EditLabelTrans}
        </Button>
        {
          isDeleteSettingsButtonVisible &&
          <Button
              onClick={deleteSelectedSettings}
              variant={MATERIAL_UI_VARIANT.OUTLINED}
              color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
          >
            {DeleteLabelTrans}
          </Button>
        }
      </React.Fragment>
    );
  };

  render() {

    const isWorkerTasksTableSettingsPermissionGranted =
      this.props.PermissionsManager.isGranted(PERMISSION.MANAGE_WORKER_TASKS_TABLE_SETTINGS);

    return (
      <div
          className={cn(
            'worker-tasks-table-settings-control-panel',
            { 'worker-tasks-table-settings-control-panel--disabled': this.props.isDisabled },
          )}
      >
        {
          isWorkerTasksTableSettingsPermissionGranted ?
            this._renderCreateNewSettingsButtons() :
            null
        }
        {this._renderSettingsSelectTitle()}
        <div className="worker-tasks-table-settings-control-panel__settings-select-with-controls-wrap">
          {this._renderSettingsSelect()}
          {
            isWorkerTasksTableSettingsPermissionGranted ?
              this._renderSettingsSelectControlButtons() :
              null
          }
        </div>
      </div>
    );
  }
}