import React, { Component } from 'react';
import { SheetsContainer } from '../../../Sheets/SheetsContainer';
import { PLANNER_APP_SHEETS_TO_START_ROUTE } from '../../../../constants/routes';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { SheetToStartReviewContentContainer } from '../SheetToStartReviewContent/SheetToStartReviewContentContainer';
import PropTypes from 'prop-types';
import {
  ARRAY_OF_ANY_OBJECTS_TYPE,
  NUMBER_OR_STRING_TYPE,
  SHEET_TO_START_SUMMARY_TYPE,
} from '../../../../constants/propTypes';
import {
  FilterNotSelectedLabelTrans,
  NoDataLabelTrans,
  NoMatchesFoundLabelTrans,
} from '../../../../utils/commonTransComponents';
import { Trans } from '@lingui/macro';
import './style.css';


export class AssemblySheetsToStart extends Component {

  static propTypes = {
    mainPlanningSessionId: NUMBER_OR_STRING_TYPE,
    assemblySheetsToStartData: PropTypes.arrayOf(SHEET_TO_START_SUMMARY_TYPE),
    filtersSchema: PropTypes.objectOf(
      PropTypes.shape({
        getFilterTitle: PropTypes.func.isRequired,
        getFilterChipContent: PropTypes.func.isRequired,
        filterComponent: PropTypes.elementType.isRequired,
        filterComponentProps: PropTypes.shape({
          autocompleteId: PropTypes.string.isRequired,
          getOptionLabel: PropTypes.func.isRequired,
          placeholder: FilterNotSelectedLabelTrans.isRequired,
          noOptionsMessage: NoMatchesFoundLabelTrans.isRequired,
          loadOptionsActionCreator: PropTypes.func,
          options: ARRAY_OF_ANY_OBJECTS_TYPE,
        }).isRequired,
      }),
    ),
  };

  _renderSheets = () => {

    if(!this.props.mainPlanningSessionId)
      return (
        <div className="assembly-sheets-to-start__no-sheets-to-start-label">
          <Trans id="assembly_sheets_to_start@no_sheets_to_start">
            В данный момент нет ни одного маршрутного листа на запуск
          </Trans>
        </div>
      );

    const {
      assemblySheetsToStartData,
      filtersSchema,
    } = this.props;

    return (
      <SheetsContainer
          className="assembly-sheets-to-start__sheets-container"
          mainRoutePath={PLANNER_APP_SHEETS_TO_START_ROUTE}
          sheetsIdentity={SHEET_TYPE.ASSEMBLY_TO_START}
          sheetItemIdProperty="entityBatchId"
          sheetsData={assemblySheetsToStartData}
          sheetReviewContentComponent={SheetToStartReviewContentContainer}
          noDataText={NoDataLabelTrans}
          filtersSchema={filtersSchema}
      />
    );
  };

  render() {

    return(
      <div className="assembly-sheets-to-start">
        {this._renderSheets()}
      </div>
    );
  }
}