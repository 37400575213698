import { ShortAmountLabelTrans, ShortPercentLabelTrans } from '../../../../utils/commonTransComponents';
import _isNumber from 'lodash/isNumber';
import { divDecimals, mulDecimals } from '../../../../utils/decimal';

export const SHEET_OPERATION_PROGRESS_MODE = {
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT',
};

export const SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP = {
  [SHEET_OPERATION_PROGRESS_MODE.PERCENT]: ShortPercentLabelTrans,
  [SHEET_OPERATION_PROGRESS_MODE.AMOUNT]: ShortAmountLabelTrans,
};

export const getProgressInAmountFromPercent = (entitiesInBatchAmount, progress) => {
  const numberProgress = _isNumber(progress) ? progress : Number(progress);

  if (numberProgress === 0) return 0;

  const fractionalPercentProgress = divDecimals(numberProgress, 100);

  return Number(mulDecimals(entitiesInBatchAmount, fractionalPercentProgress).toFixed(3));
};

export const getProgressInPercentFromAmount = (entitiesInBatchAmount, progress) => {
  const numberProgress = _isNumber(progress) ? progress : Number(progress);

  if (numberProgress === 0) return 0;

  const fractionalPercentProgress = divDecimals(numberProgress, entitiesInBatchAmount);

  return Number(mulDecimals(fractionalPercentProgress, 100).toFixed(6));
};

/*
 * Отображаются только целые числа для любого из режимов
 * */
export const prepareSheetOperationProgressValueToDisplay = progress => {
  const numberProgress = _isNumber(progress) ? progress : Number(progress);

  return Number.isInteger(numberProgress) ?
    numberProgress.toString() :
    Math.floor(numberProgress).toString();
};