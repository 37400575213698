import { fetchData, postData } from '../httpRequestsApi/actions';

import humps from 'humps';


/*
* Для инциирования различных действий сервера со стороны клиента используется отправка POST и GET запросов на, так называемые,
* action-точки.
* */

export const getActionUrl = (host, actionPoint) => [
  host,
  'action',
  actionPoint,
].join('/');

export const sendAction = (host, actionPoint, actionData, options = {}) =>
  dispatch => {

    const requestBody = actionData.action ?
      { ...actionData, action: humps.decamelize(actionData.action) } :
      actionData;

    return dispatch(postData(
      getActionUrl(host, actionPoint),
      requestBody,
      options,
    ));
  };

export const fetchDataFromActionPoint = (host, actionPoint, queryParams = {}, options = {}) =>
  dispatch =>
    dispatch(fetchData(
      getActionUrl(host, actionPoint),
      queryParams,
      options,
    ));
