import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';
import {
  FUNC_IS_REQUIRED_TYPE,
  NUMBER_OR_STRING_TYPE,
  SHEET_SUMMARY_TYPE,
} from '../../../../constants/propTypes';
import { Trans } from '@lingui/macro';

import { Button, Tooltip } from '@mui/material';

import { EntitiesTableWithEditableColumn } from '../../../common/EntitiesTableWithEditableColumn/EntitiesTableWithEditableColumn';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';

import { ENTITY_BATCH_PROVIDING_STATE } from '../../../../constants/sheets';
import { SimpleLabel } from '../../../common/SimpleLabel/SimpleLabel';
import { SimpleHelpAlert } from '../../../common/SimpleHelpAlert/SimpleHelpAlert';
import {
  reserveAndConsumeTableEditableFieldNormalizer,
  reserveAndConsumeTableFilterFunc,
} from '../../../../utils/reserveAndConsumeCommon';

import './style.css';
import {
  DATA_TO_RESERVE_TABLE_COLUMNS_SCHEMA,
  reserveEntitiesComparator,
  reserveEntitiesPrepareDataBeforeSubmit,
  reserveEntitiesPrepareTableRowData,
  reserveEntityValidator,
} from './constants';
import { InProductionLabelTrans } from '../../../../utils/commonTransComponents';


export const PartsAndMaterialsReserveForAssemblySheet = props => {

  const {
    initialReserveData,
    onPartsAndMaterialsReserve,
    PermissionsManager,
    sheetToReviewData: { providingState },
    onSendToProduction,
  } = props;

  const hasReserveInInitialData = useMemo(
    () => Object
      .values(initialReserveData)
      .some(({ reservedAmount }) => reservedAmount !== 0),
    [initialReserveData],
  );

  return (
    <div className="parts-and-materials-reserve-for-assembly-sheet">

      <div className="parts-and-materials-reserve-for-assembly-sheet__title">
        <Trans id="assembly_sheet_parts_and_materials_reserving@required_parts_and_materials">
          Необходимая комплектация
        </Trans>
      </div>

      {
        _renderSendToProductionControl(
          providingState,
          onSendToProduction,
          hasReserveInInitialData,
          PermissionsManager,
        )
      }

      {
        providingState === ENTITY_BATCH_PROVIDING_STATE.PARTIALLY_PROVIDED ?
          <SimpleHelpAlert
              className="parts-and-materials-reserve-for-assembly-sheet__info-alert"
              content={
                <Trans id="assembly_sheet_parts_and_materials_reserving@sent_to_production_info_alert">
                  МЛ был передан в производство, задания по нему стали доступны в приложении "Мастер" и "Рабочий".
                  На сборочных операциях будет происходить потребление зарезервированных здесь деталей. Нужно
                  продолжать резервировать детали под сборочный МЛ после их поступления на склад, если ещё не все
                  нужные детали были зарезервированы. Резервирование будет доступно до момента завершения МЛ
                </Trans>
              }
          /> :
          null
      }

      <EntitiesTableWithEditableColumn
          className="parts-and-materials-reserve-for-assembly-sheet__table"
          initialRowsDataMap={initialReserveData}
          onSubmitChanges={onPartsAndMaterialsReserve}
          submitChangesConfirmTitle={
            <Trans id="assembly_sheet_parts_and_materials_reserving@confirm_dialog_title">
              Подтверждение резервирования ДСЕ
            </Trans>
          }
          submitChangesConfirmText={
            <Trans id="assembly_sheet_parts_and_materials_reserving@confirm_dialog_message">
              Вы уверены, что хотите зарезервировать детали на складе для выбранного МЛ? Это действие нельзя отменить
            </Trans>
          }
          areChangesPermitted={PermissionsManager.isGranted(PERMISSION.RESERVE_ENTITIES_FOR_ASSEMBLY_SHEET)}
          editableField="reservedAmount"
          availableAmountKey="warehouseAmount"
          rowKeyField="entityId"
          rowValidator={reserveEntityValidator}
          filterFunc={reserveAndConsumeTableFilterFunc}
          sortFunc={reserveEntitiesComparator}
          prepareRowData={reserveEntitiesPrepareTableRowData}
          prepareDataBeforeSubmit={reserveEntitiesPrepareDataBeforeSubmit}
          columnsSchema={DATA_TO_RESERVE_TABLE_COLUMNS_SCHEMA}
          editableFieldNormalizer={reserveAndConsumeTableEditableFieldNormalizer}
      />
    </div>
  );
};

const _renderSendToProductionControl = (
  providingState,
  onSendToProduction,
  hasReserveInInitialData,
  PermissionsManager,
) => {

  if(providingState === ENTITY_BATCH_PROVIDING_STATE.PARTIALLY_PROVIDED) {
    return (
      <SimpleLabel
          className="parts-and-materials-reserve-for-assembly-sheet__sent-to-production-label"
          content={InProductionLabelTrans}
          color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
      />
    );
  }

  if(
    PermissionsManager.isDenied(PERMISSION.ENTITY_BATCH_PROVIDE_PARTIALLY) ||
    providingState === ENTITY_BATCH_PROVIDING_STATE.PROVIDED
  ) {
    return null;
  }

  const SentToProductionButtonElement = (
    <Button
        className={
          cn(
            'parts-and-materials-reserve-for-assembly-sheet__send-to-production-button',
            {
              'parts-and-materials-reserve-for-assembly-sheet__send-to-production-button--can-send-to-production': hasReserveInInitialData,
            },
          )
        }
        color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
        variant={MATERIAL_UI_VARIANT.CONTAINED}
        onClick={onSendToProduction}
        disabled={!hasReserveInInitialData}
    >
      <Trans id="assembly_sheet_parts_and_materials_reserving@send_to_production_button">
        Отдать в работу
      </Trans>
    </Button>
  );

  if(hasReserveInInitialData) {
    return SentToProductionButtonElement;
  }

  return (
    <Tooltip
        classes={{
          popper: 'parts-and-materials-reserve-for-assembly-sheet__send-to-production-button-tooltip-popper',
          tooltip: 'parts-and-materials-reserve-for-assembly-sheet__send-to-production-button-tooltip',
        }}
        placement="left"
        title={
          <Trans id="assembly_sheet_parts_and_materials_reserving@can_not_send_to_production_with_no_reserve">
            Для передачи сборочного МЛ в производство требуется зарезервировать хотя бы одну комплектующую, чтобы
            можно было начинать сборку. На текущий момент резервирование для этого МЛ ещё не выполнялось
          </Trans>
        }
    >
      <span className="parts-and-materials-reserve-for-assembly-sheet__send-to-production-button-wrap">
        {SentToProductionButtonElement}
      </span>
    </Tooltip>
  );
};

const ENTITY_BATCH_FOR_RESERVE_FROM_ASSEMBLY_SHEET_TYPE = PropTypes.objectOf(
  PropTypes.shape({
    id: NUMBER_OR_STRING_TYPE,
    identity: PropTypes.string,
    warehouseAmount: PropTypes.number,
    reservedAmount: PropTypes.number,
  }),
).isRequired;

PartsAndMaterialsReserveForAssemblySheet.propTypes = {
  PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  sheetToReviewData: SHEET_SUMMARY_TYPE,
  onPartsAndMaterialsReserve: FUNC_IS_REQUIRED_TYPE,
  onSendToProduction: FUNC_IS_REQUIRED_TYPE,
  initialReserveData: PropTypes.objectOf(
    PropTypes.shape({
      entityId: NUMBER_OR_STRING_TYPE.isRequired,
      entityCombinedName: PropTypes.string.isRequired,
      requiredAmount: PropTypes.number.isRequired,
      warehouseAmount: PropTypes.number.isRequired,
      reservedAmount: PropTypes.number.isRequired,
      remainingAmount: PropTypes.number.isRequired,
      entityBatches: ENTITY_BATCH_FOR_RESERVE_FROM_ASSEMBLY_SHEET_TYPE,
    }),
  ),
};
