import {
  MAIN_PLANNING_SESSION_CHANGED_EVENT_TYPE,
  PLAN_AND_PRODUCTION_STATE_ACTUALIZATION_EVENT_TYPE,
} from '../../../../constants/sockets';
import { handleMainPlanningSessionChange } from './mainPlanningSessionChanged';
import _partition from 'lodash/partition';
import _isFunction from 'lodash/isFunction';
import { getClientMessagesDataArray } from '../getClientMessagesDataArray';
import { handlePlanAndProductionStateActualization } from './planAndProductionStateActualization';


const EVENTS_HANDLERS = {
  [MAIN_PLANNING_SESSION_CHANGED_EVENT_TYPE]: handleMainPlanningSessionChange,
  [PLAN_AND_PRODUCTION_STATE_ACTUALIZATION_EVENT_TYPE]: handlePlanAndProductionStateActualization,
};

export const handleIaClientMessage = message =>
  dispatch =>
    dispatch(getClientMessagesDataArray(message))
      .then(messageDataArr => {
        /*
        * Клиентские сообщения могут отправляться с накоплением, поэтому полученные сообщения - это всегда массив.
        * Сообщение о смене основной сессии, по идее, должно быть одно, но, теоретически, если быстро поменяли сессии,
        * то может прийти и несколько. На эти события обработка всегда одинаковая, нам необходимо узнать последнюю сессию.
        * Т.е. нет необходимости выполнять это несколько раз, если есть хотя бы одно такое событие, то нужно выполнить
        * обработку 1 раз. Поэтому события этого типа выфильтровываются из общего массива накопленных событий и
        * обрабатываются отдельно.
        * */
        const [
          mainPlanningSessionChangeMessages,
          otherMessages,
        ] = _partition(messageDataArr, ({ event }) => event === MAIN_PLANNING_SESSION_CHANGED_EVENT_TYPE);

        if (mainPlanningSessionChangeMessages.length > 0)
          dispatch(EVENTS_HANDLERS[MAIN_PLANNING_SESSION_CHANGED_EVENT_TYPE]());

        otherMessages
          .forEach(({ event, data }) => {
            const handler = EVENTS_HANDLERS[event];
            if(!_isFunction(handler)) return;

            dispatch(handler(data));
          });
      });
