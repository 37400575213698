import React from 'react';

import { TableCellRenderer } from '../TableCellRenderer';
import { NUMBER_OR_STRING_TYPE } from '../../../../constants/propTypes';
import { humanizeTimeFromHoursWithoutSeconds } from '../../../../utils/humanizeTimeFromHours/humanizedTimeFromHoursFactory';
import { nilValueLabelTableCellRendererFactory } from '../nilValueLabelTableCellRendererFactory';

export class _HumanizedTimeFromHoursRenderer extends TableCellRenderer {

  static getViewValue() {
    // Пояснение см. в OperationLaborRenderer, коммент в методе getViewValue
    return null;
  };

  render() {
    const { value } = this.props;

    return (
      <div>
        {humanizeTimeFromHoursWithoutSeconds(Number(value))}
      </div>
    );
  }
}

_HumanizedTimeFromHoursRenderer.propTypes = {
  value: NUMBER_OR_STRING_TYPE,
};

export const HumanizedTimeFromHoursRenderer = nilValueLabelTableCellRendererFactory(_HumanizedTimeFromHoursRenderer);