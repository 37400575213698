import React from 'react';
import PropTypes from 'prop-types';

import { UserRoleChip } from '../../common/UserRoleChip/UserRoleChip';

import { MATERIAL_UI_STYLED_REACT_SELECT_COMPONENTS } from '../../../constants/autocomplete';

import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';

import cn from 'classnames';


const USER_ROLES_SELECT_STYLES = {
  multiValue: base => ({
    ...base,
    backgroundColor: 'transparent',
    margin: 0,
  }),
  option: base => ({
    ...base,
    padding: '0.4rem',
  }),
};

const USER_ROLES_SELECT_COMPONENTS = {
  MultiValueRemove: () => null,
  Option: props => {
    const { Option } = MATERIAL_UI_STYLED_REACT_SELECT_COMPONENTS;

    /* eslint-disable react/prop-types */
    const {
      data: {
        name,
      } = {},
    } = props;

    /* eslint-enable react/prop-types */

    return (
      <Option {...props}>
        <UserRoleChip roleIdentity={name}/>
      </Option>
    );
  },
  SingleValue: props => {
    /* eslint-disable react/prop-types */
    const {
      data: {
        name,
      } = {},
    } = props;
    /* eslint-enable react/prop-types */

    return (
      <UserRoleChip roleIdentity={name} />
    );
  },
  MultiValue: props => {
    /* eslint-disable react/prop-types */
    const {
      data: {
        name,
      } = {},
      removeProps,
    } = props;

    const {
      onClick,
    } = removeProps;

    /* eslint-enable react/prop-types */

    return (
      <UserRoleChip
          onDelete={onClick}
          roleIdentity={name}
      />
    );
  },
};

const userRoleSelectGetOptionValue = ({ id }) => id;
const userRoleSelectGetOptionLabel = ({ name }) => name;


/*
* Компонент обертка селекта выбора ролей пользователя. Работает вместе с контейнером UserRoleSelectContainer и, в
* общем случае, не должен использоваться без него, т.к. в контейнере запрашиваются и передаются сюда опции ролей для
* селекта. Комментарий к контейнеру в файле самого контейнера.
*
* Компонент обертка получает запрошенные в контейнере опции и использует из для селекта. Для селекта определяются
* кастомные компоненты для опций и значений в виде UserRoleChip, а также необходимая стилизация. Для мультиселекта
* переделана логика удаления значений, дефолтный элемент управления MultiValueRemove задизейблен, значения удаляются
* при помощи крестика на самом кастомном чипе UserRoleChip.
* Компонент обертка также принимает все основные необходимые пропсы для кастомизации поведения, которые будут
* прокидываться на сам AutocompleteContainer
* */
export const UserRoleSelect = props => {
  const {
    className,
    title,
    onChange,
    value,
    error,
    options,
    isMulti,
    placeholder,
    noOptionsMessage,
    isClearable,
  } = props;

  return (
    <div className={cn('user-role-select', className)}>
      <AutocompleteContainer
          styles={USER_ROLES_SELECT_STYLES}
          components={USER_ROLES_SELECT_COMPONENTS}
          value={value}
          options={options}
          onChange={onChange}
          isMulti={isMulti}
          title={title}
          error={error}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          isSearchable={false}
          isClearable={isClearable}
          getOptionValue={userRoleSelectGetOptionValue}
          getOptionLabel={userRoleSelectGetOptionLabel}
      />
    </div>
  );
};


UserRoleSelect.defaultProps = {
  placeholder: null,
};

const USER_ROLE_SELECT_OPTION = PropTypes.shape({
  id: NUMBER_OR_STRING_TYPE.isRequired,
  name: PropTypes.string.isRequired,
});

UserRoleSelect.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([USER_ROLE_SELECT_OPTION, PropTypes.arrayOf(USER_ROLE_SELECT_OPTION)]),
  error: PropTypes.node,
  options: PropTypes.arrayOf(USER_ROLE_SELECT_OPTION).isRequired,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.node,
  noOptionsMessage: PropTypes.node.isRequired,
  isClearable: PropTypes.bool,
};