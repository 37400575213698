import { fetchEntitiesFromServer } from '../entities/actions';


export const SET_AUTOCOMPLETE_OPTIONS = 'SET_AUTOCOMPLETE_OPTIONS';
export const ADD_AUTOCOMPLETE_OPTIONS = 'ADD_AUTOCOMPLETE_OPTIONS';
export const SELECT_AUTOCOMPLETE_VALUE = 'SELECT_AUTOCOMPLETE_VALUE';
export const CLEAR_AUTOCOMPLETE_DATA = 'CLEAR_AUTOCOMPLETE_DATA';

export const setAutocompleteOptions = (id, options) => ({
  type: SET_AUTOCOMPLETE_OPTIONS,
  id,
  options,
});

export const addAutocompleteOptions = (id, options) => ({
  type: ADD_AUTOCOMPLETE_OPTIONS,
  id,
  options,
});

export const selectAutocompleteValue = (id, value) => ({
  type: SELECT_AUTOCOMPLETE_VALUE,
  id,
  value,
});

export const clearAutocompleteData = id => ({
  type: CLEAR_AUTOCOMPLETE_DATA,
  id,
});



/*
* Функции помощники для генерации функций и экшнкриетеров для использования в качестве пропсов loadOptions и
* loadOptionsActionCreator загрузки опций сущностей БД CA в автокомплитной абстракции. Автокомплит позволяет задать
* один из указанных пропсов, поэтому функций для генерации тоже 2
* Основные положения:
* 1. В качестве экшенкриетора запроса данных сущностей CA используется fetchEntitiesFromServer. Основная модель запроса
* задается параметром requestModel. Полученные в результате запроса сущности этой же модели requestModel, по умолчанию,
* будут считаться опциями автокомплита, т.е. будут возвращаться из fetchEntitiesFromServer. Можно указать параметр
* optionsModel, тогда опциями автокомплита будут считать сущности этой модели из ответа, а не модели requestModel
* 2. При помощи getRequestQuery формируются гет параметры запроса на основании введенного в автокомплит текста. Есть
* дефолтный параметр - это количество запрашиваемых записей, т.е. по умолчанию запрашивается не более
* DEFAULT_AUTOCOMPLETE_LOAD_OPTIONS_LIMIT записей (что можно переопределить, если getRequestQuery вернет объект с
* ключом limit).
* ВАЖНО! Нужно иметь в виду, что, если для автокомплита задается опция "прелоадинга" опций, то функция загрузки опций
* может быть вызвана с "пустым" inputValue (не определен или пустая строка), если в пропсах автокомплита не задан
* preloadInputValue. Поэтому, в случае "прелоадинга", колбэк getRequestQuery, который получает inputValue на вход,
* из функции запроса должен обязательно обрабатывать этот момент.
* 3. При помощи getRequestOptions формируются параметры запроса на основании введенного в автокомплит текста.
* Опции запроса, как правило, никак не зависят от inputValue, но решено сделать его по аналогии с getRequestQuery -
* колбэком, чтобы было однотипное апи. Есть дефолтный параметр - это то, что при запросе опций автокомплита не крутится
* глобальный спиннер приложения. Вероятно, на первый взгляд это может показаться спорным и нужно будет посмотреть будет
* ли это корректно работать, но сейчас показалось, что появляющийся глобальный спиннер немного мешает, особенно когда
* запросы выполняются быстро. Кроме того, спиннер мешает при прелоадинге опций, т.к. пользователь даже может не открыть
* селект в интерфейсе, а мы крутим ему спиннер. С другой стороны, когда данные большие и запрос долгий, то совсем
* без спиннера (а у автокомплита его собственный спиннер мы пока тоже не используем), вероятно, будет не очень понятно
* что происходит. По идее, если поймём, что это так, то спиннер можно отключать только для случая прелоадинга (пока
* показатель этого, что inputValue не определено, но это может измениться)
* ВАЖНО! Нужно иметь в виду, что, если для автокомплита задается опция "прелоадинга" опций, то функция загрузки опций
* может быть вызвана с "пустым" inputValue (не определен или пустая строка), если в пропсах автокомплита не задан
* preloadInputValue. Поэтому, в случае "прелоадинга", колбэк getRequestOptions, который получает inputValue на вход,
* должен обрабатывать этот момент, если это требуется. Как было описано ранее, как правило, getRequestOptions не
* зависит от inputValue, поэтому для этого колбэка, вероятно, это не так важно, как для getRequestQuery, но отметить,
* всё же, будет не лишним.
* */
const DEFAULT_AUTOCOMPLETE_LOAD_OPTIONS_LIMIT = 20;
const DEFAULT_CA_ENTITIES_AUTOCOMPLETE_REQUEST_QUERY = {
  limit: DEFAULT_AUTOCOMPLETE_LOAD_OPTIONS_LIMIT,
};
const DEFAULT_CA_ENTITIES_AUTOCOMPLETE_REQUEST_OPTIONS = {
  isBlockingRequest: false,
};

const _getEmptyObject = () => ({});

export const createCaEntitiesAutocompleteLoadOptionsFunction = ({
  requestModel,
  getRequestQuery = _getEmptyObject,
  getRequestOptions = _getEmptyObject,
  optionsModel,
  dispatch,
}) =>
  inputValue =>
    _fetchAutoCompleteOptions(
      inputValue,
      requestModel,
      getRequestQuery,
      getRequestOptions,
      optionsModel,
      dispatch,
    );

export const createCaEntitiesAutocompleteLoadOptionsActionCreator = ({
  requestModel,
  getRequestQuery = _getEmptyObject,
  getRequestOptions = _getEmptyObject,
  optionsModel,
}) =>
  inputValue =>
    dispatch =>
      _fetchAutoCompleteOptions(
        inputValue,
        requestModel,
        getRequestQuery,
        getRequestOptions,
        optionsModel,
        dispatch,
      );


const _fetchAutoCompleteOptions = (
  inputValue,
  requestModel,
  getRequestQuery = _getEmptyObject,
  getRequestOptions = _getEmptyObject,
  optionsModel,
  dispatch,
) => {
  const requestQuery = {
    ...DEFAULT_CA_ENTITIES_AUTOCOMPLETE_REQUEST_QUERY,
    ...getRequestQuery(inputValue),
  };

  const requestOptions = {
    ...DEFAULT_CA_ENTITIES_AUTOCOMPLETE_REQUEST_OPTIONS,
    ...getRequestOptions(inputValue),
  };

  return dispatch(fetchEntitiesFromServer(requestModel, requestQuery, requestOptions))
    .then(response => {
      const {
        entities = {},
      } = response;

      const optionsEntities = entities[optionsModel || requestModel] || {};

      return Object.values(optionsEntities);
    });
};