import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { sendPartsAndMaterialsForAssemblySheetConsumedNotification } from '../../../../operations/sheets';
import { sheetOperationReviewIsConsumeEntitiesDialogOpenSelector } from '../../../../reducers/sheetOperationReview/selectors';


export const handlePartsAndMaterialsForAssemblySheetConsumption = message =>
  (dispatch, getState) => {
    const {
      sheetId,
      sheetIdentity,
    } = message;

    sendPartsAndMaterialsForAssemblySheetConsumedNotification(sheetIdentity);

    /*
    * Очищаем данные потребления в общем обработчике только если модальное окно потребление не открыто (не важно на
    * каком роуте, SheetOperationReviewDialog рендерится на нескольких). Если модальное окно открыто, то ничего не
    * делаем, обработка передается в SheetOperationReviewDialog, где уже известны данные просматриваемой операции и
    * можно вычислить нужно ли очищать кэш или делать перезапрос данных
    * */
    const isConsumeEntitiesDialogOpen = sheetOperationReviewIsConsumeEntitiesDialogOpenSelector(getState());

    if(isConsumeEntitiesDialogOpen) {
      return;
    }

    dispatch(deleteAssemblySheetConsumeData({ sheetId }));
  };