import _mapValues from 'lodash/mapValues';
import { COLUMN_TYPE, TOOLS_COLUMN_NAME } from '../../constants/table';


const TOOLS_COLUMN_WIDTH_IN_PX = 50;
const TOOLS_COLUMN_COMMON_PROPS = {
  columnName: TOOLS_COLUMN_NAME,
  display: true,
  disableFilter: true,
  disableSort: true,
  disableResize: true,
  show: true,
  textAlign: 'center',
  displayName: '',
  width: TOOLS_COLUMN_WIDTH_IN_PX,
  customComponent: 'TableOptionsButtonRenderer',
  type: COLUMN_TYPE.TOOLS,
};

const getToolsColumnProps = ({ order }) => ({
  ...TOOLS_COLUMN_COMMON_PROPS,
  order,
});

const getUndisplayedColumnProps = value => ({
  ...value,
  show: value.show === undefined ? true : value.show,
  disableFilter: true,
  disableSort: true,
  disableResize: true,
});

export const initSchema = schemaData => {
  const {
    fields,
    sortParams,
    relations,
  } = schemaData;

  const updatedFields = _mapValues(
    fields,
    (value, key) => {
      if (key === TOOLS_COLUMN_NAME) return getToolsColumnProps(value);

      const {
        display,
        show,
      } = value;

      if (display === false) return getUndisplayedColumnProps(value);

      return {
        ...value,
        display: display === undefined ? true : display,
        show: show === undefined ? true : show,
      };
    },
  );

  return {
    ...schemaData,
    fields: updatedFields,
    sortParams: sortParams === undefined ? [] : sortParams,
    relations: relations === undefined ? {} : relations,
  };
};