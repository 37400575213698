/*
* Специальный экшен для того, чтобы, в случае необходимости, сбросить данные всех ключей в redux state, кроме ключей
* заданных в stateKeysToPreserver,  на дефолтные, т.е. на те, которыми state инициализировался в начале работы
* приложения. Подробное описание логики работы в reducers/index при обработке этого экшена
* */
export const RE_INIT_REDUX_STATE = 'RE_INIT_REDUX_STATE';

export const reInitReduxState = ({ stateKeysToPreserve = [] } = {}) => ({
  type: RE_INIT_REDUX_STATE,
  stateKeysToPreserve,
});