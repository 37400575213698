import { floatNumsNormalizer } from '@bfg-frontend/normalizers';
import { DEFAULT_FLOAT_NUMS_PRECISION } from '../../constants/sheets';
import {
  complexComparatorFactory,
  fieldComparatorFactory,
  numberComparator,
  stringComparator,
} from '@bfg-frontend/utils/lib/array';
import { subDecimals } from '../decimal';

export const reserveAndConsumeTableFilterFunc = ({ entityCombinedName }, filterValue) =>
  entityCombinedName.toLowerCase().includes(filterValue.toLowerCase());

export const reserveAndConsumeTableEditableFieldNormalizer =
    value => floatNumsNormalizer(value, DEFAULT_FLOAT_NUMS_PRECISION);


export const reserveAndConsumeEntitiesComparatorFactory = availableAmountKey =>
  complexComparatorFactory([
    /*
    * Первая сортировка переносит в конец списка ДСЕ, по которым уже всё зарезервировано\потреблено, т.е. те, у кого
    * initialRemainingAmount = 0
    * */
    (
      { remainingAmount: firstInitialRemainingAmount },
      { remainingAmount: secondInitialRemainingAmount },
    ) => {
      if(firstInitialRemainingAmount === 0 && secondInitialRemainingAmount > 0) {
        return 1;
      }

      if(firstInitialRemainingAmount > 0 && secondInitialRemainingAmount === 0) {
        return -1;
      }

      return 0;
    },

    /*
    * Вторая сортировка размещает выше те ДСЕ, по которым есть доступные для резервирования \ потребления ДСЕ. Ключ
    * по которому определяется доступные ДСЕ задается в параметре фабрики availableAmountKey
    *
    * */
    (first, second) => {

      /*
      * Эту сортировка нет смысла применять к ДСЕ, по которым уже всё зарезервировано и которые были перенесены в конец
      * списка первым компаратором
      * */
      const { remainingAmount: firstInitialRemainingAmount } = first;
      const { remainingAmount: secondInitialRemainingAmount } = second;

      if(firstInitialRemainingAmount === 0 && secondInitialRemainingAmount === 0) {
        return 0;
      }

      const { [availableAmountKey]: firstInitialAvailableAmount } = first;
      const { [availableAmountKey]: secondInitialAvailableAmount } = second;

      if(firstInitialAvailableAmount === 0 && secondInitialAvailableAmount > 0) {
        return 1;
      }

      if(firstInitialAvailableAmount > 0 && secondInitialAvailableAmount === 0) {
        return -1;
      }

      return 0;
    },

    /*
    * Третья сортировка для удобства по имени
    * */
    fieldComparatorFactory(stringComparator, 'entityCombinedName'),
  ]);


/**
 * Функция распределяет общее количество зарезервированных\потребленных ДСЕ, заданное в интерфейсе, по доступным
 * партиям этих ДСЕ. Алгоритм распределения: Сначала используются партии, у которых доступное количество меньше, для
 * того, чтобы  не оставалось много мелких партий.
 * @param initialData {object} Начальные данные резервирования \ потребления, из которых берется начальное общее
 * количество зарезервированных\потребленных ДСЕ и партии, по которым это количество нужно распределить
 * @param newAmountData {object} Новые данные по общему количеству зарезервированных\потребленных после редактирования
 * @param fieldsSchema {object} вида {
 *    - entityPrevAmountKey: {string}, - ключ в initialData для ДСЕ, значение которого определяет общее количество
 *      зарезервированных\потребленных ДСЕ до редактирования (reservedAmount || consumedAmount)
 *    - entityBatchAvailableAmountKey: {string}, - ключ в объекте партии элемент массива initialData.entityBatches для
 *      ДСЕ, значение которого определяет доступное количество в партии для резервирования \ потребления
 *      (warehouseAmount || reservedAmount)
 * }
 * @return {object} вида: {
 *  123: 2, // [entityBatchId]: reservedOrConsumedAmount,
 *  ...
 * }
 */
export const calculateDetailedEntitiesReserveOrConsumeFromEntityBatches = (initialData, newAmountData, fieldsSchema) => {
  const {
    entityPrevAmountKey,
    entityBatchAvailableAmountKey,
  } = fieldsSchema;

  return Object
    .keys(initialData)
    .reduce(
      (acc, entityId) => {
        const {
          [entityPrevAmountKey]: prevAmount,
          entityBatches,
        } = initialData[entityId];

        const newAmount = Number(newAmountData[entityId]);

        /*
         * Если значение резерва/потребления не изменилось, то не включаем данные по этому ДСЕ в запрос
         * */
        if(newAmount === prevAmount) return acc;

        /*
         * Сортируем партии данного ДСЕ на складе/в резерве по возрастанию доступного для резервирования количества,
         * попутно вычисляя значение availableAmount - объем партии, доступный для резерва/потребления и
         * отфильтровываем партии склада, из которых уже нечего резервировать/потреблять
         * Таким образом, мы располагаем в начале массива данные тех партий, в которых меньше осталось
         * "свободных" ДСЕ. Из таких нужно резервировать/потреблять в первую очередь, чтобы не копить партии
         * мелкого размера на складе.
         *
         * Понятно, что может возникнуть ситуация, когда такая логика распределения не устраивает
         * пользователя, но для таких случаев в будущем будет прорабатываться отдельный интерфейс
         * для детализированного резервирования/потребления из конкретных партий склада.
         * */
        const sortedEntityBatchesAvailableAmountToReserveOrConsume = Object
          .values(entityBatches)
          .map(entityBatch => {
            const {
              id: entityBatchId,
              [entityBatchAvailableAmountKey]: availableAmount,
            } = entityBatch;
            return {
              entityBatchId,
              availableAmount,
            };
          })
          .filter(({ availableAmount }) => availableAmount !== 0)
          .sort(fieldComparatorFactory(numberComparator, 'availableAmount'));

        /*
         * Поскольку отмена резервирования/потребления пока не предусмотрена, разность значений может быть только
         * положительной.
         * */
        let totalAmountOfEntitiesToReserveOrConsume = subDecimals(newAmount, prevAmount);
        let detailedEntitiesReserveOrConsumeFromBatches = {};

        /*
         * Пробегаемся по отсортированному списку партий и резервируем/потребляем из них по очереди до тех пор, пока
         * totalAmountOfEntitiesToReserveOrConsume не будет равно 0
         * */
        sortedEntityBatchesAvailableAmountToReserveOrConsume
          .some(({ entityBatchId, availableAmount }) => {
            const consumeOrReserveFromCurrentBatch =
              availableAmount > totalAmountOfEntitiesToReserveOrConsume ?
                totalAmountOfEntitiesToReserveOrConsume :
                availableAmount;

            detailedEntitiesReserveOrConsumeFromBatches[entityBatchId] = consumeOrReserveFromCurrentBatch;

            totalAmountOfEntitiesToReserveOrConsume =
              subDecimals(totalAmountOfEntitiesToReserveOrConsume, consumeOrReserveFromCurrentBatch);

            return totalAmountOfEntitiesToReserveOrConsume === 0;
          });

        return {
          ...acc,
          ...detailedEntitiesReserveOrConsumeFromBatches,
        };
      },
      {},
    );
};

