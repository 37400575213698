import {
  setAppUserData,
  setMainPlanningSession,
} from '../reducers/appState/actions';
import { fetchEntitiesFromIa } from '../reducers/entities/actions';

import { fetchDataFromIaActionPoint } from '../api';

import { connectToSystemMessagesSocket } from '../api/socketApi/actions';

import { push } from 'connected-react-router';

import { SERVER_ACTION_POINT } from '../constants/serverActions';
import { SIMULATION_SESSION_MODEL } from '../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../api/restCollectionApi';

import { routerPathnameSelector } from '../reducers/router/selectors';
import { getUserPermittedUrlAfterAuthentication } from '../utils/permissions/index';
import { logout } from './users/index';
import {
  SHEET_OPERATION_SETTINGS_GROUP,
  SHEET_OPERATION_MAIN_SETTINGS_NAME,
} from '../components/AdminApp/SheetOperationSettings/constants';
import { fetchSettings } from './settings';


/*
* При инициализации приложения:
*  - выполняется подключение к сокетам
*  - запрашиваются необходимые данные для работы приложения.
*  - если все описанное выше выполнено успешно, то данные текущего пользователя записываются в store и пользователь
* может начать работу в системе. Если были какие-то критические ошибки, то их обработка выполняется в самих
* функциях, в этом случае в store будут установлены идентификаторы критических ошибок и работа в приложение
* будет остановлена, чтобы повторить действие будет требоваться перезагрузка страницы
* */
export const initApp = userData =>
  dispatch => {
    dispatch(connectToAppSockets());

    return dispatch(fetchAppRequiredData())
      .then(() => {
        dispatch(setAppUserData(userData));
        return userData;
      });
  };


/*
* Подключение к веб-сокетам приложения.
* В данный момент подключаемся только в общесистемному сокету, но обработка сразу вынесена в отдельную функцию, т.к.
* в будущем могут появиться и другие сокеты.
* Для подключения к сокетам не реализуются (возможно, пока) какие-то особые проверки на то, удалось подключиться или нет,
* поэтому здесь просто вызываются функции подключения к сокетам и не возвращается промис. Неудачное подключение к
* сокетам, конечно, нарушает многопользовательский сценарий работы, но, по идее, в приложении можно работать и без
* них. Ну и ошибка подключения, по идее, может случиться только в случаях, когда сервер недоступен - на этот случай,
* у самих сокетов реализованы механизмы перепоключения
* */
export const connectToAppSockets = () =>
  dispatch => {
    dispatch(connectToSystemMessagesSocket());
  };


/*
* Запрос необходимых данных для работы при инициализации приложения.
* Пока что, это только запрос основного расчета IA, но сразу проаработан отдельный экшен, т.к. в будущем возможны и
* другие запросы.
* В теории, запрос основного расчета IA можно тоже выполнить отдельно в рамках какого-нибудь конкретного интерфейса, но
* в первых реализациях считалось, что о наличии в системе основного расчета, по которому ведутся работы, нужно знать
* сразу при инициализации приложения и с этимм была связана определенная логика. В данный момент номер сессии основного
* расчета используется только в интерфейсах, связанных с запуском МЛ, но сам запрос сессии пока было решено, всё же,
* оставить выполняться при инициализации приложения, т.к. непонятно, точно ли он будет нужен только в интерфейсах
* запуска, и сам запрос не сильно трудоёмкий
* */
export const fetchAppRequiredData = () =>
  dispatch =>
    Promise.all([
      dispatch(fetchMainPlanningSession()),
      dispatch(fetchSettings([{
        group: SHEET_OPERATION_SETTINGS_GROUP,
        name: SHEET_OPERATION_MAIN_SETTINGS_NAME,
      }])),
    ]);


export const fetchMainPlanningSession = () =>
  dispatch =>
    dispatch(fetchDataFromIaActionPoint(
      SERVER_ACTION_POINT.SIMULATION_SESSION_FOR_TASKS,
      {},
      { isBlockingRequest: false },
    ))
      .then(({ data: sessionId }) => {
        if(sessionId === null)
          return Promise.reject(null);

        return dispatch(fetchEntitiesFromIa(
          SIMULATION_SESSION_MODEL,
          {
            filter: {
              filterGroupType: FILTER_GROUP_TYPES.AND,
              filters: [
                {
                  column: 'id',
                  filterType: FILTER_TYPES.EQUALS,
                  filterValue: sessionId,
                },
              ],
            },
          },
          { isBlockingRequest: false },
        ));
      })
      .then(({ entities, responseEntitiesIds, responseMeta }) => {
        if (responseMeta.count === 0)
          return Promise.reject(null);

        const sessionId = responseEntitiesIds[SIMULATION_SESSION_MODEL][0];

        const sessionEntity = entities[SIMULATION_SESSION_MODEL][sessionId];

        dispatch(setMainPlanningSession(sessionEntity));

        return sessionEntity;
      })

      /*
      * ВАЖНО! Тут не реджектится промис, отсутствие основной сессии планирования не является критичной ошибкой для
      * работы + вся обработка ошибок при инициализации приложении осуществляется в самом App по различным полям.
      * Возвращаем null, т.к. реджект на этот запрос интерпретируем, как то, что основной сессии не существует
      * */
      .catch(() => null);

/*
* В случае, если при старте приложения удалось сразу аутентифицировать пользователя, т.к. куки с прошлой сессии
* работы в приложении не истекли, т.е. когда пользователя не редиректнуло для аутентификации на роут формы
* аутентификации, то необходимо проверить является ли разрешенной ссылка, по которой был осуществлен вход в приложение,
* для аутентифицированного пользователя (исходя из наборов его разрещений). Если ссылка для пользователя
* запрещена, то нужно редиректнуть пользователя на дефолтный урл, рассчитаный в соответствии с набором его разрешений
* */
export const checkIfCurrentUrlPermittedAfterInitialAuthentication = userData =>
  (dispatch, getState) => {
    const currentUrl = routerPathnameSelector(getState());

    /*
    * Функция getUserPermittedUrlAfterAuthentication проверяет переданный ей текущий урл приложения (т.е. тот, по
    * которому и осуществлялся вход) на основании разрешений пользователя и возвращает либо переданный ей текущий урл,
    * если проверка пройдена, либо дефолтный урл, рассчитанный на основании разрешений пользователя, если проверка
    * не пройдена. Подробнее по приницпе работы getUserPermittedUrlAfterAuthentication в комментарии к этой функции
    * */
    const permittedUrl = getUserPermittedUrlAfterAuthentication(currentUrl, userData.permissions);

    /*
    * Явно обрабатываем случай, когда у пользователя нет разрешений на просмотр хотя бы одного из разделов приложения.
    * В таком случае необходимо выполнить выход из системы, чтобы пользователь при входе увидел форму логина.
    *
    * Такая ситуация возникает, когда пользователь авторизован в приложении под служебной учетной записью, которая
    * требуется лишь для какой-то конкретной цели и имеет ограниченный набор разрешений, например, разрешение на обращение
    * к каким-нибудь data- или action-точкам.
    *
    * Пока единственный возможный кейс - это запуск процесса актуализации Плана и Состояния производства из IA для
    * пользователя, который ранее не был авторизован в СА (запуск будет выполнен от имени служебного пользователя)/
    * */
    if(permittedUrl === null) {
      return dispatch(logout());
    }

    /*
    * Если текущий урл является разрешенным, то просто продолжаем работу. Если он запрещен, то редиректим на
    * рассчитанный разрешенный урл.
    * */
    if(currentUrl === permittedUrl) return;

    dispatch(push(permittedUrl));
  };

export const exitFromAppType = () => dispatch => dispatch(push('/'));