import React from 'react';
import PropTypes from 'prop-types';
import { URL_REGEX } from '../../../utils/url';
import { Link } from '@mui/material';

export const TextWithUrls = React.memo(props => {
  const { text } = props;

  /*
  Получаем массив ссылок из текста
  */
  const urlsArray = text.match(URL_REGEX);

  if (urlsArray === null) return text;

  /*
  Делим текст на части. Деление происходит по местам, в которых были ссылки
  */
  const textChunks = text.split(URL_REGEX);

  return (
    <React.Fragment>
      {
        textChunks
          .map((chunk, i) => {

            if (!urlsArray[i]) return chunk;

            return (
              <React.Fragment key={`${textChunks}_${i}`}>
                {chunk}
                {
                  <Link target="_blank" rel="noopener" href={urlsArray[i]}>
                    {urlsArray[i]}
                  </Link>
                }
              </React.Fragment>
            );
          })
      }
    </React.Fragment>
  );
});

TextWithUrls.propTypes = {
  text: PropTypes.string.isRequired,
};