import {
  ORDER_MODEL,
} from '../../constants/models';
import { ordersRemoteTableDataSelector } from '../../selectors/orders';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Orders } from './Orders';
import { reactRouterParamsSelector } from '../../selectors/reactRouter';
import _isFunction from 'lodash/isFunction';
import { push } from 'connected-react-router';
import _isEmpty from 'lodash/isEmpty';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../api/restCollectionApi/index';
import _get from 'lodash/get';


const ORDERS_REMOTE_TABLE_DATA_SORT_PARAMS = [
  {
    column: 'startDate',
    params: [{ key: 'asc', value: true }],
  },

  //Дополнительная сортировка по id для стабильности
  {
    column: 'id',
    params: [{ key: 'asc', value: true }],
  },
];

const mapStateToProps = (state, ownProps) => {
  const {
    ordersIdentity,
  } = ownProps;

  const { orderId: orderToReviewIdFromRoute } = reactRouterParamsSelector(null, ownProps);

  return {
    ordersData: ordersRemoteTableDataSelector(state, { ordersIdentity }),
    orderToReviewIdFromRoute,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {

  const {
    mainRoutePath,
    fetchOrderEntitiesActionCreator,
  } = ownProps;

  const pushNewPathName = newPathName => dispatch(push(newPathName));

  return {
    startOrderReview: orderId =>
      pushNewPathName([mainRoutePath, orderId].join('/')),

    stopOrderReview: () => pushNewPathName(mainRoutePath),

    fetchOrdersData: _isFunction(fetchOrderEntitiesActionCreator) ?
      fetchOrderRemoteTableDataCbFactory(dispatch, fetchOrderEntitiesActionCreator) :
      undefined,

    fetchOrderToReview: _isFunction(fetchOrderEntitiesActionCreator) ?
      fetchOrderToReviewCbFactory(dispatch, fetchOrderEntitiesActionCreator) :
      undefined,
  };
};

export const fetchOrderRemoteTableDataCbFactory = (dispatch, fetchOrderEntitiesActionCreator) =>
  ({ tableParams }) => {

    const {
      activePage,
      pageSize,
      filterParams,
    } = tableParams;

    const query = {
      filter: _isEmpty(filterParams) ?
        undefined :
        ({
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: Object
            .keys(filterParams)
            .map(column => {
              const {
                filterValue,
                filterType,
              } = filterParams[column];

              return {
                column,
                filterType,
                filterValue,
              };
            }),
        }),
      sortBy: ORDERS_REMOTE_TABLE_DATA_SORT_PARAMS,
      limit: pageSize,
      page: activePage,
    };

    return dispatch(fetchOrderEntitiesActionCreator(query))
      .then(({ responseEntitiesIds = {}, entities = {}, responseMeta }) => ({
        itemsIds: responseEntitiesIds,
        itemsById: entities,
        totalItemsAmount: _get(responseMeta, 'count', 0),
      }));
  };

export const fetchOrderToReviewCbFactory = (dispatch, fetchOrderEntitiesActionCreator) =>
  orderId => {

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'id',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: orderId,
          },
        ],
      },
    };

    return dispatch(fetchOrderEntitiesActionCreator(query))
      .then(response => {
        if(!response || response.responseMeta.count === 0) return null;

        const {
          entities: {
            [ORDER_MODEL]: orderEntities = {},
          },
        } = response;

        return orderEntities[orderId];
      });
  };

const mergeProps = (stateProps, dispatchProps, ownProps) => {

  const {
    ordersData,
    orderToReviewIdFromRoute,
  } = stateProps;

  const {
    startOrderReview,
    stopOrderReview,
    fetchOrdersData,
    fetchOrderToReview,
  } = dispatchProps;

  /*
  * Чтобы не было путаницы с пропсами, явно распаковываем и передаем дальше в компонент используемые им пропсы из
  * ownProps контейнера. Сами ownProps не распаковываем.
  * */
  const {
    className,
    mainRoutePath,
    ordersIdentity,
    orderItemIdProperty,
    noDataText,
    orderReviewContentComponent,
    getOrderItemAdditionalInfoLabel,
    filtersSchema,
  } = ownProps;

  return {
    ordersData,
    orderToReviewIdFromRoute,

    startOrderReview,
    stopOrderReview,
    fetchOrdersData,
    fetchOrderToReview,

    className,
    mainRoutePath,
    ordersIdentity,
    orderItemIdProperty,
    noDataText,
    orderReviewContentComponent,
    getOrderItemAdditionalInfoLabel,
    filtersSchema,
  };
};

export const OrdersContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(Orders);