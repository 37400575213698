import { createSocketConnection } from './index';
import {
  SYSTEM_MESSAGES_SOCKET_IDENTITY,
} from '../../constants/sockets';
import _isFunction from 'lodash/isFunction';
import { SOCKET_MESSAGE_HANDLERS_BY_TYPE_MAP } from './socketMessageHandlers/index';


//Это скорее всего изменится, сейчас используем сокеты приложение IA, в будущем у приложения CA будут свои сокеты либо
//какие то общие.
const {
  WEBSOCKET_SERVER_HOST,
} = window.config;


export const connectToSystemMessagesSocket = () =>
  dispatch => {
    const connectionOptions = {
      onMessageCb: message => dispatch(handleMessagesInSystemSocket(message)),
    };
    const socketUrl = [
      WEBSOCKET_SERVER_HOST,
      SYSTEM_MESSAGES_SOCKET_IDENTITY,
    ].join('/');
    createSocketConnection(socketUrl, connectionOptions);
  };

export const handleMessagesInSystemSocket = message =>
  dispatch => {

    const handler = SOCKET_MESSAGE_HANDLERS_BY_TYPE_MAP[message.type];
    if(!_isFunction(handler)) return;

    return dispatch(handler(message));
  };