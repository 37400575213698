import { OrdersContainer } from '../../Orders/OrdersContainer';
import {
  PLANNER_APP_ORDERS_COMPLETED_ROUTE,
} from '../../../constants/routes';
import { fetchCompletedOrders } from '../../../operations/orders';
import {
  COMPLETED_ORDERS_FILTERS_SCHEMA,
} from '../../Orders/ordersFilters/ordersFilters';
import React from 'react';
import { getArchivedOrdersInfoLabel, ORDER_TYPE } from '../../../constants/orders';
import { CompletedOrdersReviewContentContainer } from './CompletedOrdersReviewContent/CompletedOrdersReviewContentContainer';
import { Trans } from '@lingui/macro';


export const CompletedOrders = () => (
  <OrdersContainer
      className="completed-orders"
      mainRoutePath={PLANNER_APP_ORDERS_COMPLETED_ROUTE}
      ordersIdentity={ORDER_TYPE.COMPLETED}
      orderItemIdProperty="id"
      fetchOrderEntitiesActionCreator={fetchCompletedOrders}
      orderReviewContentComponent={CompletedOrdersReviewContentContainer}
      noDataText={
        <Trans id="completed_orders@no_completed_orders">
          В данный момент нет ни одного завершенного заказа
        </Trans>
      }
      filtersSchema={COMPLETED_ORDERS_FILTERS_SCHEMA}
      getOrderItemAdditionalInfoLabel={getArchivedOrdersInfoLabel}
  />
);