import { DEV } from '../../constants/environment';

import _isFunction from 'lodash/isFunction';

const { WEBSOCKET_RECONNECTION_TIMEOUT_MS } = window.config;

const IS_DEVELOPMENT_ENVIRONMENT = process.env.NODE_ENV === DEV;

const SOCKET_CONNECTIONS = {};

export const createSocketConnection = (socketUrl, socketConnectionOptions = {}) => {

  //не инициализируем несколько сокетов по одному url
  if(!!SOCKET_CONNECTIONS[socketUrl]) {
    IS_DEVELOPMENT_ENVIRONMENT &&
      //eslint-disable-next-line
      console.error(
        `Попытка повторно установить соединение по адресу ${socketUrl}. Новое соединение не было установлено`,
      );
    return;
  }

  const socket = new WebSocket(socketUrl);

  socket.onopen = createSocketOnOpenCb(socketUrl, socketConnectionOptions);
  socket.onclose = createSocketOnCloseCb(socketUrl, socketConnectionOptions);
  socket.onmessage = createSocketOnMessageCb(socketUrl, socketConnectionOptions);

  SOCKET_CONNECTIONS[socketUrl] = socket;
};

const createSocketOnOpenCb = (socketUrl, socketConnectionOptions) =>
  () => {
    //eslint-disable-next-line
    IS_DEVELOPMENT_ENVIRONMENT && console.log(['Соединение установлено', socketUrl].join(': '));
    const { onOpenCb } = socketConnectionOptions;
    _isFunction(onOpenCb) && onOpenCb();
  };

const DEFAULT_WEBSOCKET_RECONNECTION_TIMEOUT = 5000;
const createSocketOnCloseCb = (socketUrl, socketConnectionOptions) =>
  event => {

    //удаляем из хранилища сокетов объект по ключу socketUrl.
    delete SOCKET_CONNECTIONS[socketUrl];

    if (event.wasClean) {
      //eslint-disable-next-line
      IS_DEVELOPMENT_ENVIRONMENT && console.log(['Соединение закрыто чисто', socketUrl].join(': '));
      return;
    }

    /*
    * если сокет закрыт "некорректно", например, упал сервер и т.д., то пытаемся переподключить сокет через таймаут
    * */
    //eslint-disable-next-line
    IS_DEVELOPMENT_ENVIRONMENT && console.warn('Обрыв соединения. Код: ' + event.code, event);

    setTimeout(
      () => createSocketConnection(socketUrl, socketConnectionOptions),
      WEBSOCKET_RECONNECTION_TIMEOUT_MS || DEFAULT_WEBSOCKET_RECONNECTION_TIMEOUT,
    );
  };

const createSocketOnMessageCb = (socketUrl, socketConnectionOptions) =>
  message => {
    const { onMessageCb } = socketConnectionOptions;
    if(!_isFunction(onMessageCb)) return;
    onMessageCb(JSON.parse(message.data));
  };

export const closeSocketConnection = socketUrl => {
  const socket = SOCKET_CONNECTIONS[socketUrl];
  socket && socket.close();
};

export const closeAllSocketConnections = () =>
  Object
    .keys(SOCKET_CONNECTIONS)
    .forEach(closeSocketConnection);

export const sendMessageViaSocket = (socketUrl, message) => {
  const socket = SOCKET_CONNECTIONS[socketUrl];

  if(!socket) {
    //eslint-disable-next-line
    console.error(
      `Попытка отправить сообщение по адресу ${socketUrl}. Соединение по этому адресу не существует. Сообщение не было отправлено.`,
    );
    return;
  }

  socket.send(JSON.stringify(message));
};


/*
* Добавляет дополнительный обработчик к событиям, которые приходят в сокет по адресу socketUrl, если такое соединение
* существует.
* Слушатель событий добавляется к стандартному типу 'message', тем самым события в сокете будут обрабатываться и
* стандартным onmessage, если он был задан при инициализации соединения сокета createSocketConnection и этим
* дополнительным обработчиком.
* Функция, как это часто делается, возвращает функцию очистки, т.е. удаление назначаемого обработчика
* */
export const addSocketMessageListener = (socketUrl, handler) => {
  const socket = SOCKET_CONNECTIONS[socketUrl];

  if(!socket) {
    //eslint-disable-next-line
    console.error(
      `Попытка добавить слушателя для сокета по адресу ${socketUrl}. Соединение по этому адресу не существует. Слушатель не был добавлен.`,
    );
    return;
  }

  const resultHandler = message => handler(JSON.parse(message.data));

  socket.addEventListener(
    'message',
    resultHandler,
  );

  return () => socket.removeEventListener('message', resultHandler);
};