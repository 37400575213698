import PropTypes from 'prop-types';


const {
  shape,
  string,
  func,
  oneOfType,
  node,
} = PropTypes;

export const SIMPLE_SUMMARY_ROW_SCHEMA_TYPE = shape({
  key: string.isRequired,
  label: oneOfType([node, string]),
  rowIdentity: string.isRequired,
  getCustomValue: func,
  getCustomLabel: func,
});

