import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';

import { blockingAsyncAction } from './blockingAsyncAction/reducer';
import { appState } from './appState/reducer';
import { entities } from './entities/reducer';

import { plannerAppReducer } from './plannerApp/reducer';
import { masterAppReducer } from './masterApp/reducer';
import { workerAppReducer } from './workerApp/reducer';
import { workerTasksTableSettings } from './workerTasksTableSettings/reducer';
import { filters } from './filters/reducer';
import { storageManagementAppReducer } from './storageManagementApp/reducer';
import { schemaModelReducer } from './schemaModel/reducer';
import { tableReducer } from './table/reducer';
import { autocompleteReducer } from './autocomplete/reducer';
import { RE_INIT_REDUX_STATE } from './actions';
import { collapsibleReducer } from './collapsible/reducer';
import { sheetOperationReviewReducer } from './sheetOperationReview/reducer';

import _pick from 'lodash/pick';


const crateAppReducer = history => {

  const combinedReducer = combineReducers({
    router: connectRouter(history),
    blockingAsyncAction,
    appState,
    entities,
    workerApp: workerAppReducer,
    plannerApp: plannerAppReducer,
    masterApp: masterAppReducer,
    storageManagementApp: storageManagementAppReducer,
    workerTasksTableSettings,
    filters,
    schemaModel: schemaModelReducer,
    tables: tableReducer,
    autocomplete: autocompleteReducer,
    collapsible: collapsibleReducer,
    sheetOperationReview: sheetOperationReviewReducer,
  });

  return (state, action) => {

    /*
    * Дополнительная обработка специального экшена для того, чтобы иметь возможность сбросить все данные в redux
    * state на дефолтные т.е. на те, которые были при начальной инициализации redux state. При помощи
    * action.stateKeysToPreserve задются ключи, по которым сбрасывать данные на дефолтные не нужно
    * Логика в том, что первым параметром в общий редьюсер отправляется не текущий state, как это бывает обычно, а
    * объект, в котором присутствуют только данные по ключам action.stateKeysToPreserve или пустой объект, если
    * нужно сбросить все данные и action.stateKeysToPreserve не задан. Таким образом, каждый отдельный редьюсер,
    * который обрабатывает ключ в общем state, который нужно сбросить, получит в себя первым параметром undefined и
    * поэтому в своей обработке будет использовать дефолтное значение state, которое для него было определено. И т.к.
    * этот специальный тип экшена в самих отдельных редьюсерах не обрабатывается, то, в результате вызова такого экшена,
    * все такие редьюсеры для своих ключей вернут дефолтные значение. Принцип аналогичен тому, как redux state
    * заполняется дефолтными значениями при ициницализации store в функции createStore самим redux'ом, за исключением,
    * что здесь даётся возможность определить ключи, сбрасывать которые ну нужно (в createStore у redux главный
    * редьюсер вызывается просто с undefined, даже не с объектом)
    *
    * */
    if(action.type === RE_INIT_REDUX_STATE) {
      return combinedReducer(_pick(state, action.stateKeysToPreserve), action);
    }

    return combinedReducer(state, action);
  };

};


export default crateAppReducer;