import { Transformer } from '../Transformer';


export class Sheet extends Transformer {

  static transformToState(item) {
    const {
      id,
      identity,
      entityBatchId,
      type,
      note,
      desc,
      startDate,
      stopDate,
      fullProvidingRequired,
    } = item;

    return {
      id,
      identity,
      entityBatchId,
      type,
      note,
      desc,
      startDate,
      stopDate,
      isAssembly: !fullProvidingRequired,
    };
  };
}