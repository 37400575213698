import { createSheetOperationEquipmentAutocompleteId }
from '../../../../components/common/SheetOperationReviewDialog/SheetOperationEquipmentSection/constants';
import { autocompleteOptionsSelector } from '../../../../reducers/autocomplete/selectors';
import { addAutocompleteOptions } from '../../../../reducers/autocomplete/actions';


export const handleEquipmentEntityCreation = message =>
  (dispatch, getState) => {
    const {
      id,
      identity,
      name,
      departmentId,
      equipmentClassId,
    } = message;

    const sheetOperationEquipmentAutocompleteId =
      createSheetOperationEquipmentAutocompleteId(departmentId, equipmentClassId);

    const autocompleteOptions = autocompleteOptionsSelector(getState(), { id: sheetOperationEquipmentAutocompleteId });

    if(autocompleteOptions.length === 0) return;

    return dispatch(addAutocompleteOptions(
      sheetOperationEquipmentAutocompleteId,
      [{
        id,
        identity,
        name,
        departmentId,
        equipmentClassId,
      }],
    ));
  };