/*
* Константы для работы с моделью БД settings. Особенность модели в том, что данные в ней хранятся в поле
* JSON и, фактически, здесь может храниться любая нужная фронту информация, без создания дополнительных моделей.
* Для сущности модели в БД уникальным ключом является комбинация "группы настройки" (то, к какой области настройка
* относится) и "имени настройки".я
*
 * Настройки в ca_frontend разделены на группы, идентификатор каждой группы задаётся константой, например
 * WORKER_TASKS_TABLE_SETTINGS_ID. В каждой такой группе может храниться неограниченное количество разных экземпляров группы настроек,
 * такие экземпляры хранятся по ключам id (name в бд) внутри группы настроек.
*
* */

import { NOTIFICATION_LEVEL, sendNotification } from './notification';
import { SettingsSavedLabelTrans } from '../utils/commonTransComponents';

export const WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE = {
  READ_ONLY: 'READ_ONLY',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

const SETTINGS_ID_DELIMITER = '__';
export const getSettingsEntityUniqId = (group, name) => [group, name].join(SETTINGS_ID_DELIMITER);
export const getSettingsNameFromId = id => id.split(SETTINGS_ID_DELIMITER)[1];

export const WORKER_TASKS_TABLE_SETTINGS_GROUP = 'WORKER_TASKS_TABLE_SETTINGS_GROUP';
export const WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME = 'WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME';
export const WORKER_TASKS_TABLE_MAIN_SETTINGS_ID = getSettingsEntityUniqId(
  WORKER_TASKS_TABLE_SETTINGS_GROUP,
  WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME,
);

export const sendSettingsSavedNotification = () => sendNotification(
  SettingsSavedLabelTrans,
  NOTIFICATION_LEVEL.SUCCESS,
  { timeOut: 3000 },
);