import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './style.css';

import _isFunction from 'lodash/isFunction';

import {
  NUMBER_OR_STRING_TYPE,
  OBJECT_OF_ANY_TYPE,
} from '../../../constants/propTypes';


/* временно не используется, пока не найдется нормальная реализация, позволяющая решить проблему с overflow и скроллом
* внутри вложенных меню.
* */
//const DEFAULT_SUB_MENU_ITEMS_AMOUNT_TO_SHOW = 4;
const MENU_MIN_WIDTH_IN_PX = 200;
const MENU_ITEM_HEIGHT_IN_PX = 48;

export class NestedMenu extends Component {

  _renderMenuItem = menuItem => {
    const {
      optionId,
      optionTitle,
      optionClassName,
      optionIconComponent,
      onSelect,
      isActive,
      isDisabled,
      subMenu,
    } = menuItem;

    const {
      menuItemHeightInPx = MENU_ITEM_HEIGHT_IN_PX,
    } = this.props;

    const menuOptionElementStyle = {
      height: menuItemHeightInPx,
    };

    const hasSubMenu = !!subMenu;

    const menuItemMarkup = (
      <span className="nested-menu__option-content-wrapper">
        {this._renderMenuOptionIcon(optionIconComponent)}
        <span className="nested-menu__option-title">{optionTitle}</span>
      </span>
    );

    return (
      <li
          key={optionId}
          onClick={onSelect}
          style={menuOptionElementStyle}
          className={
            cn(
              'nested-menu__option',
              optionClassName,
              {
                'nested-menu__option--disabled': _isFunction(isDisabled) ? isDisabled() : false,
                'nested-menu__option--active': _isFunction(isActive) ? isActive() : false,
                'nested-menu__option--without-submenu': !hasSubMenu,
              },
            )
          }
      >
        <div>
          { hasSubMenu && this._renderMenuOptionSubMenuCaret()}
          {menuItemMarkup}
        </div>
        {
          hasSubMenu &&
            <NestedMenu
                {...this.props}
                menu={subMenu.subMenuOptions}
                className={cn('nested-menu__submenu', subMenu.subMenuClassName)}
            />
        }
      </li>
    );
  };

  _renderMenuOptionSubMenuCaret = () => {
    const {
      subMenuCaretIconComponent,
    } = this.props;

    const CaretIconComponent = subMenuCaretIconComponent || this._getDefaultSubMenuCaret();

    return (
      <span className="nested-menu__option-sub-menu-caret-icon-wrapper">
      <CaretIconComponent className="nested-menu__option-sub-menu-caret-icon"/>
    </span>
    );
  };

  _getDefaultSubMenuCaret = () =>
    ({ className }) => (
      <div className={className}>
        &lt;
      </div>
    );

  _renderMenuOptionIcon = OptionIconComponent => (
    <span className="nested-menu__option-icon-wrapper">
      {
        OptionIconComponent ?
          <OptionIconComponent className="nested-menu__option-icon"/>
          : null
      }
    </span>
  );

  _getDefaultNestedMenuComponent = () =>
    props => (
      <div
          {...props}
          style={{
            ...props.style,
            backgroundColor: 'white',
          }}
      >
        {props.children}
      </div>
    );

  render() {
    const {
      menu,
      className,
      nestedMenuWrapperComponent,
      nestedMenuWrapperComponentProps,
      menuItemWidthInPx = MENU_MIN_WIDTH_IN_PX,
    } = this.props;

    const NestedMenuComponent = nestedMenuWrapperComponent || this._getDefaultNestedMenuComponent();

    return (
      <NestedMenuComponent
          {...nestedMenuWrapperComponentProps}
          style={{
            minWidth: `${menuItemWidthInPx}px`,
          }}
          className={cn('nested-menu', className)}
      >
        <ul>
          {menu.map(this._renderMenuItem)}
        </ul>
      </NestedMenuComponent>
    );
  }
}

const MENU_OPTION_OBJECT_TYPE = {
  optionId: NUMBER_OR_STRING_TYPE.isRequired,
  optionTitle: PropTypes.node.isRequired,
  optionClassName: PropTypes.string,
  optionIconComponent: PropTypes.elementType,
  onSelect: PropTypes.func,
  isActive: PropTypes.func,
  isDisabled: PropTypes.func,
};

const MENU_OPTION_TYPE = PropTypes.shape({
  ...MENU_OPTION_OBJECT_TYPE,
  subMenu: PropTypes.shape({
    subMenuClassName: PropTypes.string,
    //eslint-disable-next-line no-use-before-define
    subMenuOptions: PropTypes.arrayOf(PropTypes.shape(MENU_OPTION_OBJECT_TYPE)),
  }),
});

NestedMenu.propTypes = {
  menu: PropTypes.arrayOf(MENU_OPTION_TYPE).isRequired,
  className: PropTypes.string,
  subMenuCaretIconComponent: PropTypes.elementType,
  nestedMenuWrapperComponent: PropTypes.elementType,
  nestedMenuWrapperComponentProps: OBJECT_OF_ANY_TYPE,

  // на данный момент не используется
  subMenuItemsAmountToShow: PropTypes.number,
  menuItemWidthInPx: PropTypes.number,
  menuItemHeightInPx: PropTypes.number,
  style: OBJECT_OF_ANY_TYPE,
};