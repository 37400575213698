/*
* "Абстрактный класс" трансформера данных, описывающий структуру таких классов. Все классы трансформеры
* должны наследоваться от этого класса.
*
* Трансформеры - это классы с методами, которые преобразуют сущности моделей из БД в удобный для фронта вид
* (в котором они и будут храниться), и осуществляют обратные преобразования фронтовой сущности в нужный для БД вид при
* отправке данных на сервер. Таким образом, трансформеры позволяют зафиксировать нужные для фронта форматы и меньше
* зависеть от рефакторинга \ миграций полей в БД (в этом случае достаточно подкорректировать только трансформер,
* чтобы сущность по-прежнему представлялась в нужном виде).
*
*
* В любом классе транформере могут быть определены
* 2 статичных метода:
*   - transformToDb описывает, как сущность модели фронта, для которой описывается этот трансформер, должна
*   преобразовываться при отправке на сервер.
*   - transformToState описывает, каким образом сущность модели фронта, для которой описывается этот трансформер,
*   должна быть получена из сущности модели БД
*
* */

export class Transformer {

// eslint-disable-next-line no-unused-vars
  static transformToDb(item, state) { return item; }

// eslint-disable-next-line no-unused-vars
  static transformToState(item, state) { return item; }

}
