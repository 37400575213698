export const CHANGE_COLUMNS_ORDER = 'CHANGE_COLUMNS_ORDER';
export const RESIZE_COLUMNS = 'RESIZE_COLUMNS';
export const CHANGE_COLUMN_VISIBILITY = 'CHANGE_COLUMN_VISIBILITY';
export const UPDATE_SCHEMA_MODELS_DATA = 'UPDATE_SCHEMA_MODELS_DATA';
export const INIT_SCHEMA_MODEL_FROM_SETTINGS = 'INIT_SCHEMA_MODEL_FROM_SETTINGS';
export const RESET_SCHEMA_MODEL_SETTINGS = 'RESET_SCHEMA_MODEL_SETTINGS';
export const CREATE_NEW_SCHEMA_MODEL = 'CREATE_NEW_SCHEMA_MODEL';
export const DELETE_SCHEMA_MODEL = 'DELETE_SCHEMA_MODEL';

export function changeColumnsOrder(model, dragSourceColumnIndex, dragDestinationColumnIndex) {
  return {
    type: CHANGE_COLUMNS_ORDER,
    model,
    dragSourceColumnIndex,
    dragDestinationColumnIndex,
  };
}

export function resizeColumns(
  model,
  columnsWidthData,
) {
  return {
    type: RESIZE_COLUMNS,
    model,
    columnsWidthData,
  };
}

export function changeColumnVisibility(model, columnName, visibility) {
  return {
    type: CHANGE_COLUMN_VISIBILITY,
    model,
    columnName,
    visibility,
  };
}

export function updateSchemaModelsData(updatedSchemaModelsData) {
  return {
    type: UPDATE_SCHEMA_MODELS_DATA,
    updatedSchemaModelsData,
  };
}

export function initSchemaModelFromSettings(settings) {
  return {
    type: INIT_SCHEMA_MODEL_FROM_SETTINGS,
    settings,
  };
}

export function resetSchemaModelSettings(model) {
  return {
    type: RESET_SCHEMA_MODEL_SETTINGS,
    model,
  };
}

export function createNewSchemaModel(model, schemaModelData) {
  return {
    type: CREATE_NEW_SCHEMA_MODEL,
    model,
    schemaModelData,
  };
}

export function deleteSchemaModel(model) {
  return {
    type: DELETE_SCHEMA_MODEL,
    model,
  };
}