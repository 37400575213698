import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';

import { CARD_MENU_OPTIONS_TYPE } from './cardWithCustomHeader.types';

import _size from 'lodash/size';
import _partial from 'lodash/partial';

import cn from 'classnames';

import './style.css';


export class CardWithCustomHeader extends Component {

  static propTypes = {
    className: PropTypes.string,
    header: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    actionsContent: PropTypes.node,
    menuOptions: CARD_MENU_OPTIONS_TYPE,
  };

  constructor(props) {
    super(props);
    this.state = {
      menuAnchorEl: null,
    };
  }

  _setMenuAnchorEl = menuAnchorEl => this.setState({ menuAnchorEl });

  _renderCardHeader = () => {
    const {
      header,
      actionsContent,
      menuOptions,
    } = this.props;

    return(
      <Box
          className="card-with-custom-header__header-wrap"
          sx={{
            color: 'primary.contrastText',
            backgroundColor: 'primary.main',
          }}
      >
        {header}
        <div className="card-with-custom-header__actions-content-and-menu-wrap">
          {
            actionsContent
          }
          {
            _size(menuOptions) > 0 ?
              this._renderCardMenu(menuOptions) :
              null
          }
        </div>
      </Box>
    );
  };

  _renderCardMenu = menuOptions => {
    const { menuAnchorEl } = this.state;

    return (
      <React.Fragment>
        <IconButton
            className="card-with-custom-header__menu-icon"
            onClick={this._onCardMenuIconClick}
            color="inherit"
            size="large"
        >
          <MoreIcon />
        </IconButton>
        <Menu
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            onClose={this._onCardMenuClose}
            className="card-with-custom-header__card-menu"
        >
          {
            menuOptions
              .map(
                ({ menuItemId, menuItemTitle, menuItemClick }) =>
                  <MenuItem
                      key={menuItemId}
                      className="card-with-custom-header__card-menu-item"
                      onClick={this._cardMenuItemClickCbFactory(menuItemClick)}
                  >
                    <div>{menuItemTitle}</div>
                  </MenuItem>,
              )
          }
        </Menu>
      </React.Fragment>
    );
  };

  _onCardMenuIconClick = event => this._setMenuAnchorEl(event.currentTarget);

  _onCardMenuClose = _partial(this._setMenuAnchorEl, null);

  _cardMenuItemClickCbFactory = onItemClickCb =>
    () => {
      onItemClickCb();
      this._onCardMenuClose();
    };

  _renderCardContent = () =>
    <div className="card-with-custom-header__card-content-wrap">
      <CardContent className="card-with-custom-header__card-content">
        {this.props.content}
      </CardContent>
    </div>;

  render() {
    return (
      <Card
          className={
            cn(
              this.props.className,
              'card-with-custom-header',
              //Этот класс в основном, чтобы обеспечить большую специфичность для селекта в css, иначе стили material
              //не удается переопределить
              'card-with-custom-header--no-overflow',
            )
          }
      >
        {this._renderCardHeader()}
        {this._renderCardContent()}
      </Card>
    );
  }
}

