import { Settings } from './Settings';

import {
  SETTINGS_MODEL,
  ENTITY_BATCH_MODEL,
  SHEET_OPERATION_MODEL,
  USER_ROLE_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  ORDER_ENTRY_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_FEATURE_SCOPE_MODEL,
  SHEET_OPERATION_FEATURE_VALUE_MODEL,
} from '../../constants/models';

import _size from 'lodash/size';
import _partial from 'lodash/partial';
import { EntityBatch } from './EntityBatch';
import { SheetOperation } from './SheetOperation';
import { UserRole } from './UserRole';
import { SheetOperationAssignee } from './SheetOperationAssignee';
import { OrderEntry } from './OrderEntry';
import { Sheet } from './Sheet';
import { SheetOperationFeatureScope } from './sheetOperationFeatureScope';
import { SheetOperationFeatureValueEntity } from './sheetOperationFeatureValueEntity';


export const ENTITIES_TRANSFORMERS_BY_MODEL_MAP = {
  [SETTINGS_MODEL]: Settings,
  [SHEET_MODEL]: Sheet,
  [ENTITY_BATCH_MODEL]: EntityBatch,
  [SHEET_OPERATION_MODEL]: SheetOperation,
  [USER_ROLE_MODEL]: UserRole,
  [SHEET_OPERATION_ASSIGNEE_MODEL]: SheetOperationAssignee,
  [ORDER_ENTRY_MODEL]: OrderEntry,
  [SHEET_OPERATION_FEATURE_SCOPE_MODEL]: SheetOperationFeatureScope,
  [SHEET_OPERATION_FEATURE_VALUE_MODEL]: SheetOperationFeatureValueEntity,
};

/*
* Подразумевается, что все трансформеры наследуются от абстрактного класса Transformer, у которого есть реализация
* по умолчанюи для методов transformToState и transformToDb, поэтому дополнительных проверок на существование
* этих функций не делается, а экспортируемые функции transformEntitiesToState и transformEntitiesToDb вызывают
* функцию transform с этими значениями.
* */
const transform = (transformFuncIdentity, modelEntitiesData, stateModel, state) => {
  const EntityTransformer = ENTITIES_TRANSFORMERS_BY_MODEL_MAP[stateModel];

  if(!EntityTransformer) return modelEntitiesData;

  if(!_size(modelEntitiesData)) return [];

  return modelEntitiesData.map(entity => EntityTransformer[transformFuncIdentity](entity, state));
};

export const transformEntitiesToState = _partial(transform, 'transformToState');

export const transformEntitiesToDb = _partial(transform, 'transformToDb');