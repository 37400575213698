import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactTable, { ReactTableDefaults } from 'react-table';
import 'react-table/react-table.css';

import {
  ARRAY_OF_ANY_OBJECTS_TYPE,
  OBJECT_OF_ANY_TYPE,
  TABLE_COLUMNS_TYPE,
} from '../../../../constants/propTypes';

import _isFunction from 'lodash/isFunction';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import _size from 'lodash/size';

import cn from 'classnames';

import './style.css';


/*
* Компонент обертка над ReactTable прокидывает все пропы в сам компонент, чтобы иметь возможность задавать пропсы для
* самого копоненты при использовании компонента обертки и назначит, некоторые пропсы по умолчанию.
* Константа WRAPPER_PROP_TYPES_KEYS описывает ключи самого компонента-обертки, которые прокидывать не нужно.
* Это, по сути, ключи из ReactTableWrapper.propTypes, и ранее они и использовались ({..._omit(this.props, Object.keys(ReactTableWrapper.propTypes))}),
* но, как оказалось, реакт становится всё умнее и в продакшене проптайпсы могут отключаться для увеличения производительности,
* поэтому там этого статичного свойства просто не будет. Поэтому приходится просто продублировать возможные ключи пропсов обертки
* в этой константе
* */
const WRAPPER_PROP_TYPES_KEYS = [
  'className',
  'tableColumns',
  'tableData',
  'reactTableClassName',
  'getTheadProps',
  'getTbodyProps',
  'getTrGroupProps',
  'columnProps',
  'pageSize',
  'page',
];

const TABLE_MIN_HEIGHT_IN_PX = 200;

export class ReactTableWrapper extends Component {

  /*
  * Пропы компонента ReactTable и всех его составляющих можно посмотреть в документации компонента - https://react-table.js.org.
  * Все парадаваемые пропы, кроме указанных пропов обертки прокидываются далее в сам компонент ReactTable
  * */
  static propTypes = {
    className: PropTypes.string,
    tableColumns: TABLE_COLUMNS_TYPE,
    tableData: ARRAY_OF_ANY_OBJECTS_TYPE,
    reactTableClassName: PropTypes.string,
    getTheadProps: PropTypes.func,
    getTbodyProps: PropTypes.func,
    getTrGroupProps: PropTypes.func,
    getTrProps: PropTypes.func,
    getTdProps: PropTypes.func,
    columnProps: OBJECT_OF_ANY_TYPE,
    noDataText: PropTypes.node.isRequired,
    onRowClick: PropTypes.func,
    sorted: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        desc: PropTypes.bool,
      }),
    ),
    onSortedChange: PropTypes.func,
    resizeColumns: PropTypes.func,
    style: PropTypes.shape({
      height: PropTypes.number,
    }),
    pageSize: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
  };

  static defaultProps = {
    tableColumns: [],
    tableData: [],
    reactTableClassName: '-striped',
    columnProps: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      columnSizesData: this._initializeColumnSizesData(),
    };
  }

  /*
  * С ресайзом колонок сложилась довольно интересная ситуация.
  *   У нас в проекте, все данные по таблицам хранятся внутри стора табличной абстракции, включая размеры колонок.
  * Таблицу react-table, которую мы используем в этом проекте, можно сделать полностью controlled в отношении размеров
  * колонок, за одним исключением, что onResizedChange вызывается миллион раз, пока пользователь меняет размер одной
  * из колонок таблицы. Это было бы не так страшно, если бы мы не хранили данные по ресайзу в сторе. Но, так как мы храним,
  * то при такой реализации получается, что стор обновляется миллион раз за одно изменение размера одной колонки, что,
  * естественно, недопустимо (ещё и лагает капец)).
  *   Была попытка реализации через debounce, но безуспешная, т.к. пропадает анимация, потому что промежуточные
  * значения отсеиваются.
  *   Далее была попытка сделать таблицу uncontrolled, в таком случае анимация ресайза отрабатывает нормально, но
  * нет никакой возможности ограничить желание пользователя уменьшить ширину колонки до 1px.
  *
  * В результате, было принято решение сделать таблицу все же controlled, но данные по ширине колонок хранить в
  * локальном стейте компонента, а стор обновлять в вызове componentWillUnmount, т.е., когда компонент готов к размонтированию.
  * Такая реализация позволяет обновлять стор всего 1 раз.
  * */
  componentWillUnmount() {
    const {
      resizeColumns,
    } = this.props;
    const {
      columnSizesData,
    } = this.state;

    if(!_isFunction(resizeColumns) || !_size(columnSizesData)) return;

    resizeColumns(columnSizesData);
  }

  _initializeColumnSizesData = () => this.props.tableColumns
    .filter(({ width }) => width !== undefined)
    .map(({ accessor, width }) => ({
      id: accessor,
      value: width,
    }));

  _handleColumnResize = newResized => this.setState({
    columnSizesData: newResized
      .map(columnData => {
        const { id, value } = columnData;

        return {
          id,
          value,
        };
      }),
  });

  /*
  * для корректного отображения сообщения об отсутствии рядов в таблице и фиксирования минимальной высоты таблицы
  *
  * - когда данные в таблице есть и высота задана явно - возвращаем это значение
  * - когда данные в таблице есть и высота не задана - возвращаем undefined, что заставляет компонент выставить автоматическую
  * высоту для таблицы, ограничив ее высотой строки с заголовками плюс высотой рядов с данными
  * - когда данных нет и высота явно не задана - возвращаем значение TABLE_MIN_HEIGHT_IN_PX для корректной отрисовки
  * сообщения
  * - когда данных нет и задано значение высоты - возвращаем максимальное из двух значений [заданная высота, TABLE_MIN_HEIGHT_IN_PX]
  */
  calculateTableHeight = () => {
    const {
      tableData,
      style: {
        height,
      } = {},
    } = this.props;

    if (tableData.length > 0) return height;

    return !!height ? Math.max(height, TABLE_MIN_HEIGHT_IN_PX) : TABLE_MIN_HEIGHT_IN_PX;
  };

  _renderTable = () => {
    const {
      tableData,
      reactTableClassName,
      sorted,
      onSortedChange,
      columnProps,
      tableColumns,
      getTrProps,
      noDataText,
      resizeColumns,
      pageSize,
      page,
    } = this.props;

    const {
      columnSizesData,
    } = this.state;

    return(
      <ReactTable
          {..._omit(this.props, Object.keys(WRAPPER_PROP_TYPES_KEYS))}
          style={{
            height: this.calculateTableHeight(),
          }}
          column={{
            ...ReactTableDefaults.column,
            headerClassName: 'react-table-wrapper__table-column-default-header',
            className: 'react-table-wrapper__table-column-default-cell',
            ...columnProps,
          }}
          noDataText={noDataText}
          columns={tableColumns}
          data={tableData}
          className={reactTableClassName}
          getTrProps={getTrProps}
          getTheadProps={this._getTheadPropsCb}
          getTbodyProps={this._getTbodyPropsCb}
          getTrGroupProps={this._getTrGroupPropsCb}
          sorted={sorted}
          onSortedChange={onSortedChange}
          resized={_isFunction(resizeColumns) ? columnSizesData : undefined}
          onResizedChange={_isFunction(resizeColumns) ? this._handleColumnResize : undefined}
          showPagination={false}
          multiSort={false}

          /*
          *  Т.к. мы всем управляем сами, то указываем параметр manual, иначе react-table начинает самостоятельно
          * дополнительно обрабатывать многие вещи: применяет встроенную сортировку, начинает вычислять какие из данных
          * отображать на текущей странице оперируя page и pageSize (слайсит массив данных) и т.д.
          *
          * Если не задать параметры pageSize и page явно, то будут использованы дефолтные значения и с ними очень много
          * разной логики. Большинство этой логики, вроде бы, ни на что не влияет из-за manual, но, например, при
          * подсчете минимального количества рядов это начинает использоваться и какие-то дефолтные значения оставлять
          * не хчоется. Возможно, где-то и ещё это используется, весь код библиотеки уж изучать не стал, просто, на
          * всякий случай, задаём их явно реальными значениями из адаптера.
          *
          * Если не задать minRows = 0, то это значение высчитывается на основании pageSize. В итоге, если значение
          * рядов становится меньше, чем minRows, то рисуются "пустые ряды" без данных. В случае, когда есть
          * обработчики на ряды (обработчик клика или getRowStyle), то для пустых рядов туда приходит undefined вместо
          * объекта ряда и нужно каждый раз обрабатывать этот момент (не делать клик, или не назначать стиль), что
          * очень неудобно. Классности ситуации добавляет то, что реакт-тейбл рисует сами ряды данных только на
          * основании своего локального стейта, даже при "контролируемой" таблице, когда компонент сначала копирует
          * данные из пропсов в свой локальный стейт, а потом уже с ними работает. Т.е., чтобы обновить данные таблицы,
          * сначала в методе жизненного цикла просто детектируется, что "данные изменены" и на этом цикле отрисовки ещё
          * используются старые данные рядов из локального стейта, НО уже с НОВЫМИ остальными пропсами. Теперь, когда
          * изменение задетектировано, то копия данных устанавливается в локальный стейт и происходит ещё одна отрисовка
          * уже с новыми обновленными данными в локальном стейте и с новыми остальными пропсами. Из-за такой не очень
          * понятной реализации случаются разные казусы, например, мы находились на последеней странице в таблице, где
          * было только 2 ряда, а в таблице на странице по 20 рядов (ну т.е. просто так вышло, что последняя страница
          * не заполнена полностью). Далее, мы переключаемся на прошлую страницу с 20 рядами. Происходит первый цикл
          * отрисовки, в котором в локальном стейте по-прежнему 2 ряда, но в пропсах уже данные, что номер страницы
          * другой, количество рядов другое и т.д. Если ничего не сделать в этом случае, то minRows уже будет равно 20,
          * тогда как рядов в локальном стейте ещё 2. Это провоцирует отрисовку 18 "пустых рядов" и, если есть, какие-то
          * обработчики, то всё начинает валиться с эксепшенами, если там не обработаны пустые ряды. А, по факту, эта
          * отрисовка, вообще, не нужна. Чтобы такого не происходило устанавливаем явно minRows в 0, чтобы генерации
          * пустых рядов никогда не происхоидло и никогда не было таких проблем. С самими лишними непонятными
          * отрисовками ничего не сделать :(
          */
          manual
          pageSize={pageSize}
          page={page}
          minRows={0}
      />
    );
  };

  _getElementPropsCbFactory = (defaultElementClassName, callbackFromPropsToOverride) =>
    (...args) => ({
      className: defaultElementClassName,
      ...this._overrideElementProps(callbackFromPropsToOverride, ...args),
    });

  _overrideElementProps = (callbackFromPropsToOverride, ...rest) => {
    if(!_isFunction(callbackFromPropsToOverride)) return {};

    return callbackFromPropsToOverride(...rest);
  };

  _getTheadPropsCb = this._getElementPropsCbFactory(
    'react-table-wrapper__table-default-head',
    this.props.getTheadProps,
  );
  _getTbodyPropsCb = this._getElementPropsCbFactory(
    'react-table-wrapper__table-default-body',
    this.props.getTbodyProps,
  );
  _getTrGroupPropsCb = (state, rowInfo, column, instance) => ({
    className: 'react-table-wrapper__table-default-row-group',
    onClick: _isFunction(this.props.onRowClick) && !!_get(rowInfo, ['original']) ?
      () => this.props.onRowClick(rowInfo.original, rowInfo.index) :
      undefined,
    ...this._overrideElementProps(this.props.getTrGroupProps, state, rowInfo, column, instance),
  });

  render() {
    return (
      <div
          className={
            cn(
              this.props.className,
              'react-table-wrapper',
              { 'react-table-wrapper--clickable-rows': _isFunction(this.props.onRowClick) },
              { 'react-table-wrapper--no-data': this.props.tableData.length === 0 },
            )
          }
      >
        {this._renderTable()}
      </div>
    );
  }
}
