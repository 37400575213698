
export const MATERIAL_UI_STYLE_COLOR = {
  INHERIT: 'inherit',
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const MATERIAL_UI_THEME_COLOR_CODES = {
  DEFAULT: '#e0e0e0',
  PRIMARY: '#2196f3',
  SECONDARY: '#dc004e',
  WARNING: '#ff9800',
  SUCCESS: '#4caf50',
};

export const MATERIAL_UI_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const MATERIAL_UI_VARIANT = {
  STANDARD: 'standard',
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
};

export const MATERIAL_UI_DIALOG_MAX_WIDTH = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const MATERIAL_UI_LABEL_PLACEMENT = {
  START: 'start',
  END: 'end',
  TOP: 'top',
  BOTTOM: 'bottom',
};
