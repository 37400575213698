import { compose } from 'redux';
import { connect } from 'react-redux';
import { PlannerWorkspace } from './PlannerWorkspace';
import { withPermissionsManager } from '../../../hoc/withPermissionsManager/withPermissionsManager';
import { exportProductionState } from '../../../operations/exportProductionState';
import { PLANNER_APP_MAIN_ROUTE } from '../../../constants/routes';
import { push } from 'connected-react-router';


const mapDispatchToProps = dispatch => ({
  exportProductionState: () => dispatch(exportProductionState()),

  handlePlannerAppViewModeChange: newViewMode =>
    dispatch(push([
      PLANNER_APP_MAIN_ROUTE,
      newViewMode,
    ].join('/'))),
});


export const PlannerWorkspaceContainer = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withPermissionsManager,
)(PlannerWorkspace);

PlannerWorkspaceContainer.displayName = 'PlannerWorkSpaceContainer';