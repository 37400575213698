import {
  CA_CLIENT_SOCKET_MESSAGE_TYPE,
  IA_CLIENT_SOCKET_MESSAGE_TYPE,
} from '../../../constants/sockets';
import { handleIaClientMessage } from './iaClientMessageHandler/index';
import { handleCaClientMessage } from './caClientMessageHandler/index';


/*
* Карта обработчиков для типов сообщений
* */
export const SOCKET_MESSAGE_HANDLERS_BY_TYPE_MAP = {
  [IA_CLIENT_SOCKET_MESSAGE_TYPE]: handleIaClientMessage,
  [CA_CLIENT_SOCKET_MESSAGE_TYPE]: handleCaClientMessage,
};