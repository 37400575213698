import { SET_TASKS_VIEW_SCREEN_ADDITIONAL_FILTERS } from './actions';


export const TASKS_VIEW_SCREEN_ADDITIONAL_FILTERS_INITIAL_STATE = [];

export const tasksAdditionalFilters = (
  state = [...TASKS_VIEW_SCREEN_ADDITIONAL_FILTERS_INITIAL_STATE],
  action,
) => {
  switch(action.type) {
  case SET_TASKS_VIEW_SCREEN_ADDITIONAL_FILTERS:
    return action.filters;
  default:
    return state;
  }
};
