import { connect } from 'react-redux';

import { sheetOperationStatusRowStyle } from '../../../../tableProperties/rowStyles/sheetOperationRowStyles';
import { sheetOperationsRemoteTableDataSelector } from '../../../../selectors/sheets';
import {
  fetchReviewedSheetOperationsRemoteTableEntities,
} from '../../../../operations/sheets';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { createSheetTypeOperationsTableId } from '../../../../utils/tables';
import { CompletedSheetReviewContent } from './CompletedSheetReviewContent';


const mapStateToProps = (state, { sheetToReviewData }) => {
  const {
    sheetId,
    entitiesInBatchAmount,
  } = sheetToReviewData;

  const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.COMPLETED, sheetId);

  return {
    sheetOperationsTableId,
    sheetOperationsTableData: sheetOperationsRemoteTableDataSelector(
      state,
      { sheetOperationsTableId, entitiesInBatchAmount },
    ),
    getSheetOperationsTableRowStyle: sheetOperationStatusRowStyle,
    sheetToReviewData,
  };
};

const mapDispatchToProps = (dispatch, { sheetToReviewData }) => ({
  fetchSheetOperations: ({ tableParams }) =>
    dispatch(fetchReviewedSheetOperationsRemoteTableEntities(sheetToReviewData.sheetId, tableParams)),
});

export const CompletedSheetReviewContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompletedSheetReviewContent);

CompletedSheetReviewContentContainer.displayName = 'CompletedSheetReviewContentContainer';
