import { TableCellRenderer } from '../TableCellRenderer';
import React from 'react';
import PropTypes from 'prop-types';
import { getUserFullName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export class UsernameRenderer extends TableCellRenderer {
  static propTypes = {
    data: PropTypes.shape({
      name: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      patronymicName: PropTypes.string,
    }),
  }

  static defaultProps = {
    data: {},
  };

  static getViewValue(props) {
    return getUserFullName(props.data);
  };

  render() {
    const usernameValue = UsernameRenderer.getViewValue(this.props);

    return(
      <div>
        {usernameValue}
      </div>
    );
  }
}