import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import { SHEET_OPERATION_STATUS_CHANGED_ACTION_TYPE } from '../../../operations/sheetOperations/index';
import {
  SHEET_TYPE,
} from '../../../constants/sheets';
import { getEntityDataByTemplate } from '@bfg-frontend/utils/lib/stringBuilders/entity';


const CURRENT_SHEET_OPERATION_DATA_TEMPLATE = {
  identity: 'operationIdentity',
  name: 'operationName',
  nop: 'operationNumber',
  progress: 'progress',
  status: 'status',
};

export const additionalTableReducer = (state, action) => {

  switch (action.type) {
  case SHEET_OPERATION_STATUS_CHANGED_ACTION_TYPE: {

    const {
      sheetOperationDataAfterStatusChange,
    } = action;

    const sheetOperationDataInSheetsInProductionTable = _get(
      state,
      [
        SHEET_TYPE.IN_PRODUCTION,
        'remoteData',
        'currentRemoteItemsById',
        'currentSheetOperationDataBySheetId',
        sheetOperationDataAfterStatusChange.sheetId,
      ],
    );

    if(_isNil(sheetOperationDataInSheetsInProductionTable)) {
      return state;
    }

    return {
      ...state,
      [SHEET_TYPE.IN_PRODUCTION]: {
        ...state[SHEET_TYPE.IN_PRODUCTION],
        remoteData: {
          ...state[SHEET_TYPE.IN_PRODUCTION].remoteData,
          currentRemoteItemsById: {
            ...state[SHEET_TYPE.IN_PRODUCTION].remoteData.currentRemoteItemsById,
            currentSheetOperationDataBySheetId: {
              ...state[SHEET_TYPE.IN_PRODUCTION].remoteData.currentRemoteItemsById.currentSheetOperationDataBySheetId,
              [sheetOperationDataAfterStatusChange.sheetId]:
                getEntityDataByTemplate(sheetOperationDataAfterStatusChange, CURRENT_SHEET_OPERATION_DATA_TEMPLATE),
            },
          },
        },
      },
    };
  }
  default:
    return state;
  }
};