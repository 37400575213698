import {
  ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID,
  DISABLED_SHEET_OPERATION_FEATURES_TABLE_ID,
  fetchSheetOperationFeaturesTableData,
  reFetchSheetOperationFeaturesTableData,
  SHEET_OPERATION_FEATURES_TABLE_MENU_OPTIONS,
} from './constants';
import { SHEET_OPERATION_FEATURES_MODEL } from '../../../constants/models';
import { TOOLS_COLUMN_NAME } from '../../../constants/table';
import { ActivateLabelTrans, DeleteLabelTrans, NoDataLabelTrans } from '../../../utils/commonTransComponents';
import { Table } from '../../Table/Table';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sheetOperationFeaturesRemoteTableDataSelector } from '../../../selectors/sheetOperationFeatures';
import {
  deleteSheetOperationFeature,
  toggleSheetOperationFeatureActiveStateRequest,
} from '../../../operations/sheetOperationFeature';
import { clearTableRemoteData } from '../../../reducers/table/actions';
import { Trans } from '@lingui/macro';
import { MATERIAL_UI_DIALOG_MAX_WIDTH } from '../../../constants/materialUI';
import { useConfirm } from '../../AppConfirm/AppConfirmContext';
import {
  DISABLED_SHEET_OPERATION_FEATURES_TABLE_CUSTOM_FILTERS,
} from './sheetOperationFeaturesTableCustomFilters/sheetOperationFeaturesTableCustomFilters';

export const DisabledSheetOperationFeaturesTable = () => {
  const dispatch = useDispatch();

  const confirm = useConfirm();

  const rowsData = useSelector(
    state => sheetOperationFeaturesRemoteTableDataSelector(state, { tableId: DISABLED_SHEET_OPERATION_FEATURES_TABLE_ID }),
  );

  const activateSheetOperationFeature = useCallback(
    (_, { id, name }) =>
      confirm({
        confirmText: (
          <Trans id="sheet_operation_settings.sheet_operation_features@activate_feature_confirm">
            После активации характеристика станет доступной для заполнения на экране просмотра операции МЛ. Вы уверены,
            что хотите активировать характеристику "{name}"?
          </Trans>
        ),
        confirmDialogInnerProps: {
          dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
        },
      })
        .then(() => dispatch(toggleSheetOperationFeatureActiveStateRequest(id, name, false))
          .then(() => {
            dispatch(clearTableRemoteData([ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID]));
            return dispatch(reFetchSheetOperationFeaturesTableData(DISABLED_SHEET_OPERATION_FEATURES_TABLE_ID, true));
          })),
    [dispatch, confirm],
  );

  const deleteSheetOperationFeatureClickHandler = useCallback(
    (_, { id, name }) => confirm({
      confirmText: (
        <Trans id="sheet_operation_settings.sheet_operation_features@delete_feature_confirm">
          Удалённую характеристику нельзя восстановить. Вы уверены, что хотите удалить характеристику "{name}"?
        </Trans>
      ),
      confirmDialogInnerProps: {
        dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
      },
    })
      .then(() => dispatch(deleteSheetOperationFeature(id))
        .then(() => dispatch(reFetchSheetOperationFeaturesTableData(DISABLED_SHEET_OPERATION_FEATURES_TABLE_ID, true)))),
    [dispatch, confirm],
  );

  const customCellRenderersProps = useMemo(() => ({
    [TOOLS_COLUMN_NAME]: {
      toolsOptions: [
        {
          id: 'edit-option',
          title: ActivateLabelTrans,
          onClick: activateSheetOperationFeature,
        },
        {
          id: 'delete-option',
          title: DeleteLabelTrans,
          onClick: deleteSheetOperationFeatureClickHandler,
        },
      ],
    },
  }), [activateSheetOperationFeature, deleteSheetOperationFeatureClickHandler]);

  const fetchTableData = useCallback(
    ({ tableParams }) => dispatch(fetchSheetOperationFeaturesTableData(tableParams, true)),
    [dispatch],
  );

  return (
    <div className="sheet-operation-features__table-wrapper">
      <Table
          tableId={DISABLED_SHEET_OPERATION_FEATURES_TABLE_ID}
          tableModel={SHEET_OPERATION_FEATURES_MODEL}
          fetchRemoteTableData={fetchTableData}
          rowsData={rowsData}
          customCellRenderersProps={customCellRenderersProps}
          tableMenu={SHEET_OPERATION_FEATURES_TABLE_MENU_OPTIONS}
          noDataContent={NoDataLabelTrans}
          customFilters={DISABLED_SHEET_OPERATION_FEATURES_TABLE_CUSTOM_FILTERS}
      />
    </div>
  );
};