
import { blockingAsyncActionWrap } from '../blockingAsyncAction/actions';
import { deleteData, fetchData, postData } from '../../api/httpRequestsApi/actions';

import {
  REST_REQUEST_TYPE,
  REST_COLLECTION_API_REQUEST_TYPE,
  transformModelsInQueryParams,
  transformQueryParamsToCollectionApi,
} from '../../api/restCollectionApi';

import { transformEntitiesToState, transformEntitiesToDb } from '../../transformers/entities';

import { HTTP_REQUEST_METHOD, HTTP_REQUEST_STATUS } from '../../api/httpRequestsApi/constants';


import _flow from 'lodash/flow';
import _omit from 'lodash/omit';
import _mapValues from 'lodash/mapValues';
import _size from 'lodash/size';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import humps from 'humps';
import {
  errorHandlerIa,
  errorHandlerCa,
  showError,
} from '../../api/requestHandlers/errorHandlers/errorHandlers';
import { createRequestHandler } from '../../api/requestHandlers/requestHandlers';

import {
  AllEntitiesNotSavedServerErrorLabelTrans,
} from '../../utils/commonTransComponents';
import { normalizeData } from '@bfg-frontend/utils/lib/normalizeData';


const { CA_BACKEND_SERVER_HOST, IA_BACKEND_SERVER_PROXY_HOST } = window.config;

export const ADD_ENTITIES_TO_STORE = 'ADD_ENTITIES_TO_STORE';
export const DELETE_ENTITIES_FROM_STORE = 'DELETE_ENTITIES_FROM_STORE';
export const DELETE_ALL_MODEL_ENTITIES_FROM_STORE = 'DELETE_ALL_MODEL_ENTITIES_FROM_STORE';

export const ENTITIES_SERVER_ERROR_MAP = {
  REST_RECORD_WITH_SAME_FIELDS_ALREADY_EXISTS: 'REST_RECORD_WITH_SAME_FIELDS_ALREADY_EXISTS',
  REST_REFERENCE_DOES_NOT_EXIST_OR_DEPENDS_ON_OTHER: 'REST_REFERENCE_DOES_NOT_EXIST_OR_DEPENDS_ON_OTHER',
  REST_REQUIRED_FIELD_WAS_NOT_SPECIFIED: 'REST_REQUIRED_FIELD_WAS_NOT_SPECIFIED',
  REST_REFERENCE_DOES_NOT_EXIST: 'REST_REFERENCE_DOES_NOT_EXIST',
};


/**
 * Основной экшн записи сущностей полученных их БД через REST в state.entities
 *
 * @param entitiesData {Object} Нормализованные данные моделей вида:
 * {
 *   model1: {
 *     [id1]: {
 *       id: id1,
 *       ...другие поля сущности
 *     },
 *     [id2]: {
 *       id: id2,
 *       ...другие поля сущности
 *     },
 *     ...другие сущности
 *   },
 *   model2: {
 *     ...аналогично
 *   },
 *   ...другие модели
 *
 * }
 *
 */
export const  addEntitiesToStore = entitiesData => ({
  type: ADD_ENTITIES_TO_STORE,
  entitiesData,
});

export const deleteEntitiesFromStore = (model, ids) => ({
  type: DELETE_ENTITIES_FROM_STORE,
  model,
  ids,
});

export const deleteAllModelEntitiesFromStore = models => ({
  type: DELETE_ALL_MODEL_ENTITIES_FROM_STORE,
  models,
});


/**
 * Получение сущностей определенной модели фронта через REST используя API коллекций.
 * Полученные данные обрабатываются трансформерами (подробное описание в Transformer.js) и нормализуются
 * Если в запросе указываются связанные модели, то все преобразования проводятся также и по ним
 *
 * @param model {String} Модель фронта создаваемой \ редактируемой сущности (не обязательно должна быть равна модели в БД).
 * На основании этой модели, параметра options и queryParams формируется правильный запрос.
 * @param queryParams {Object} GET параметры запроса:
 * {
 *   sortBy: [      множественная сортировка
 *     {
 *        column: {String}  наименование колонки БД основной или связанной модели,
 *        params: [{key: 'asc', value: true|false}] - параметры сортировки, пока только направление сортировки
 *     },
 *     ...{параметры следующих колонок для множественной сортировки, если необходимо}
 *   ],
 *   limit: {number} количество запрашиваемых записей модели, если не задано или null\ 0, то все записи независимо от значения page
 *   page: {number} страница, с которой получать записи. Работает вместе с limit, если limit не falsy.
 *   Например: нужны записи с 10 по 20 - limit = 10, page = 2
 *   filter: {      фильтрация
 *     filterGroupType: FILTER_GROUP_TYPES.AND|OR]    -  указатель группы фильтров (по И \ ИЛИ)
 *     filters: [  - данные фильтров группы, которые будет объединены по И \ ИЛИ согласно типу группы, указанному выше
 *         {
 *           column: {String}, наименование колонки БД или связанной модели
 *           filterType: {String}, тип фильтра, константы FILTER_TYPES
 *           filterValue: {String|Number}, значение фильтра
 *         },
 *         {
 *           ...данные фильтра по другой колонке группы фильтра
 *         },
 *         {
 *           filterGroupType: FILTER_GROUP_TYPES.AND|OR]    - вложенная группа фильтров
 *           filters: [
 *             ... данные фильтров вложенной группы
 *           ]
 *         }
 *         ...
 *       ]
 *   },
 *   with: [   связанные модели. Элемент массива либо строка, либо объект
 *     'superModel' {String},  наименование связанной модели
 *    {
 *       column: {String}, наименование связанной модели
 *       params: [{key: WITH_PARAMS.STRICT, value: false}] - параметры связанной модели. Пока это только возможность
 *       указания строгой и нестрогой стыковки связанной модели. Сейчас, по умолчанию, API стыкует строго, т.е. если
 *       так и надо, то связанную модель можно просто задавать строкой. Если нужна нестрогая стыковка то задавать
 *       связанную модель нужно в описанном блочном виде [{key: WITH_PARAMS.STRICT, value: false}].
 *
 *    },
 *    ...
 *   ]
 *
 *   Пример стыковки:
 *       Исходные данные:
 *       entity_route:[
 *         {id: 1, ...params},
 *         {id: 2, ...params}
 *       ],
 *       operation: {
 *         {id: 3, entity_route_id: 1, ...params},
 *         {id: 3, entity_route_id: 1, ...params},
 *       }
 *
 *       Строгая стыковка:
 *       Запрос модели entity_route с параметром with = ['operation'] или [{column: 'operation', [{key: WITH_PARAMS.STRICT, value: true}]}].
 *       Выборка в ответе:
 *       entity_route:[
 *         {id: 1, ...params},
 *       ],
 *       operation: {
 *         {id: 3, entity_route_id: 1, ...params},
 *         {id: 3, entity_route_id: 1, ...params},
 *       }
 *
 *       Нестрогая стыковка:
 *       Запрос модели entity_route с параметром with = [{column: 'operation', [{key: WITH_PARAMS.STRICT, value: false}]}].
 *       Выборка в ответе:
 *       entity_route:[
 *         {id: 1, ...params},
 *         {id: 2, ...params}
 *       ],
 *       operation: {
 *         {id: 3, entity_route_id: 1, ...params},
 *         {id: 3, entity_route_id: 2, ...params},
 *       }
 *
 * }
 *
 * @param options {Object} Дополнительные опции для формирования запроса.
 *  - options.dbModel {String} модель БД, сущности которой запрашиваются. Если не задано, то, по-умолчанию, модель
 *  БД принимается равной первому параметру model. Т.е. задавать этот параметр следует, только если названия модели
 *  фронта и модели в БД отличаются.
 *  - options.modelRelations {Object} объект, описывающий отношения связанных моделей к основной и структуру вложенности,
 *  (что на что ссылается по уровням вложенности) для конкретного запроса (API коллекций гибкое, поэтому одни и те же данные, теоретически,
 *  можно получить с разных REST-точек виртуозно манипулируя параметрами и стыкуя модели, как душа пожелает, именно
 *  поэтому в этой 'абстракции' отношения задаются под конкретный запрос). На основании этого описания отношений моделей
 *  будут преобразованы названия колонок связанных моделей любого уровня вложенности в GET параметрах (queryParams).
 *  Если никакие GET-параметры не нужны или есть уверенность, что ссылки на колонки в GET-параметрах - это колонки
 *  основной модели или модели первого уровня вложенности или же полные наименования связанных колонок в GET параметрах
 *  сформированы  вручную, то modelRelations задавать незачем.
 *  - Также, при помощи options, если необходимо, можно задать параметры низкоуровневой абстракции выполнения http GET
 *  запроса - fetchData
 *
 * В результате выполнения thunk'а возвращается Promise c объектом ответа на GET запрос вида:
 * {
 *   responseEntitiesIds: массив идентификаторов запрашиваемых сущностей, определяющих порядок полученных сущностей в ответе
 *   entities - трансформированные и нормализованные данные
 *   responseMeta - мета информация по запросу
 * }
 * или объект ошибки, если запрос не удался
 */
const DEFAULT_FETCH_ENTITIES_REQUEST_OPTIONS = {
  isBlockingRequest: true,
};
function fetchEntities(host, model, queryParams = {}, options = {}) {

  return (dispatch, getState) => {

    const fetchEntitiesRequestOptions = {
      ...DEFAULT_FETCH_ENTITIES_REQUEST_OPTIONS,
      ...options,
    };

    const fetchEntitiesAsyncCb = () => {

      const {
        dbModel,
        modelRelations,
      } = fetchEntitiesRequestOptions;

      /*
      * если определено options.dbModel, то модели сущности фронта и ДБ не совпадают, запрос делаем на модель options.dbModel,
      * а записаны данные будут по ключу фронтовой модели model
      */
      const modelForRest = dbModel || model;

      const requestUrl = [
        host,
        REST_COLLECTION_API_REQUEST_TYPE,
        modelForRest,
      ].join('/');


      const transformedQueryParams = _flow(
        transformModelsInQueryParams,
        transformQueryParamsToCollectionApi,
      )(queryParams, modelRelations);

      return dispatch(fetchData(
        requestUrl,
        transformedQueryParams,
        { ...fetchEntitiesRequestOptions, isBlockingRequest: false },
      ))
        .then(response => {

          const responseData = {
            ..._omit(response, [modelForRest, 'meta']),
            [model]: response[modelForRest],
          };

          const state = getState();

          const transformedResponseData = _mapValues(
            responseData,
            (modelEntitiesArray, modelIdentity) => transformEntitiesToState(modelEntitiesArray, modelIdentity, state),
          );

          const responseModels = Object.keys(transformedResponseData);

          const { result: responseEntitiesIds, entities } = normalizeData(transformedResponseData, responseModels);
          return { responseEntitiesIds, entities, responseMeta: response.meta };
        });
    };

    return fetchEntitiesRequestOptions.isBlockingRequest ?
      dispatch(blockingAsyncActionWrap(fetchEntitiesAsyncCb)) :
      fetchEntitiesAsyncCb();
  };
}

/*
* Зачастую сразу после получения необходимо записать данные в общее хранилище для entities в store,
* поэтому выделено в отдельный экшен
* */
const fetchEntitiesAndAddToStore = (host, model, queryParams = {}, options = {}) =>
  dispatch =>
    dispatch(fetchEntities(host, model, queryParams, options))
      .then(response => {
        dispatch(addEntitiesToStore(response.entities));
        return response;
      });

export const fetchEntitiesFromServer = createRequestHandler(fetchEntities, CA_BACKEND_SERVER_HOST, errorHandlerCa);
export const fetchEntitiesFromServerAndAddToStore = createRequestHandler(
  fetchEntitiesAndAddToStore,
  CA_BACKEND_SERVER_HOST,
  errorHandlerCa,
);

export const fetchEntitiesFromIa = createRequestHandler(fetchEntities, IA_BACKEND_SERVER_PROXY_HOST, errorHandlerIa);
export const fetchEntitiesFromIaAndAddToStore = createRequestHandler(
  fetchEntitiesAndAddToStore,
  IA_BACKEND_SERVER_PROXY_HOST,
  errorHandlerIa,
);

export function fetchRemoteTableEntities(model, queryParams, options) {
  return dispatch =>
    dispatch(fetchEntitiesFromServer(model, queryParams, options))
      .then(({ responseEntitiesIds = {}, entities = {}, responseMeta }) => ({
        itemsIds: responseEntitiesIds,
        itemsById: entities,
        totalItemsAmount: _get(responseMeta, 'count', 0),
      }));
}


/**
 * Выполняет POST или PUT на REST-точки БД
 * @param model {string} Модель фронта создаваемой \ редактируемой сущности (не обязательно должна быть равна модели в БД).
 * На основании этой модели и параметра options формируется правильный запрос. Данные ответа в нормализованном виде
 * будут записаны в state.entities[model]
 * @param entityOrEntitiesArr {Object || Array} Данные которые будут переданы в теле POST/PUT запроса
 * @param isCreating {boolean} Флаг создания \ редактирования: true, если данные создаются (POST), false если редактируются (PUT)
 * @param options {Object} Дополнительные опции для формирования запроса.
 *  - options.dbModel - модель БД, сущности которой создаются \ редактируются. Если не задано, то, по-умолчанию, модель
 *  БД принимается равной первому параметру model. Т.е. задавать этот параметр следует, только если названия модели
 *  фронта и модели в БД отличаются.
 *  - Также, при помощи options, если необходимо, можно задать параметры низкоуровневой абстракции postData
 *
 * В результате выполнения thunk'а возвращается Promise c массивом удачно созданных \ отредактированных (трансформированных)
 * объектов модели
 */
const DEFAULT_SAVE_ENTITIES_REQUEST_OPTIONS = {
  isBlockingRequest: false,
  showServerError: true,
};
const saveEntities = (host, model, entityOrEntitiesArr, isCreating = true, options = {}) =>

  (dispatch, getState) => {

    /*
    * Не отправляем запросы с пустым телом.
    * Для проверки на пустоту используем !_size, он отработает одинаково для пустого массива и объекта,
    * поэтому можно не выполнять дополнительные проверки.
    * */
    if(!_size(entityOrEntitiesArr)) {
      return Promise.resolve({
        responseEntitiesWithoutErrors: [],
        responseEntitiesWithErrors: [],
        areAllEntitiesSaved: true,
      });
    }

    const isArray = _isArray(entityOrEntitiesArr);
    const entitiesArr = isArray ? entityOrEntitiesArr : [entityOrEntitiesArr];

    const saveEntitiesRequestOptions = {
      ...DEFAULT_SAVE_ENTITIES_REQUEST_OPTIONS,
      ...options,
    };

    const saveEntitiesAsyncCb = () => {

      const state = getState();

      const httpMethod = isCreating ? HTTP_REQUEST_METHOD.POST : HTTP_REQUEST_METHOD.PUT;

      const modelForRest = saveEntitiesRequestOptions.dbModel || model;

      const baseUrl = [
        host,
        REST_REQUEST_TYPE,
        modelForRest,
      ].join('/');

      const dataToSend = transformEntitiesToDb(entitiesArr, model, state);

      const requestOptions = {
        ...saveEntitiesRequestOptions,
        httpMethod,
        isBlockingRequest: false,
      };

      return dispatch(postData(
        baseUrl,
        {
          [modelForRest]: isArray ? dataToSend : dataToSend[0],
        },
        requestOptions,
      ))
        .then(response => handleEntitiesErrors(response, modelForRest, requestOptions, isArray))
        .then(({
          responseEntitiesWithoutErrors,
          responseEntitiesWithErrors,
          areAllEntitiesSaved,
        }) => ({
          responseEntitiesWithoutErrors: transformEntitiesToState(responseEntitiesWithoutErrors, model, state),
          responseEntitiesWithErrors,
          areAllEntitiesSaved,
        }));
    };

    return saveEntitiesRequestOptions.isBlockingRequest ?
      dispatch(blockingAsyncActionWrap(saveEntitiesAsyncCb)) :
      saveEntitiesAsyncCb();
  };


export const defaultSaveOrDeleteEntitiesErrorHandler =
  (errorMessage = AllEntitiesNotSavedServerErrorLabelTrans) => showError(errorMessage);

const handleEntitiesErrors = (response, model, options, isArray) => {

  const responseEntityOrEntities = response[model];
  const responseEntitiesArray = isArray ? responseEntityOrEntities : [ responseEntityOrEntities ];

  const { responseEntitiesWithErrors, responseEntitiesWithoutErrors } = divideErrorResponse(responseEntitiesArray);

  const totalEntitiesAmount = responseEntitiesArray.length;
  const entitiesWithErrorsAmount = responseEntitiesWithErrors.length;

  const areAllEntitiesWithErrors = totalEntitiesAmount === entitiesWithErrorsAmount;

  if (areAllEntitiesWithErrors) {
    return Promise.reject({
      status: HTTP_REQUEST_STATUS.FAILED,
      response,
      options,
      areAllEntitiesWithErrors,
      responseEntitiesWithErrors,
    });
  }

  const areAllEntitiesSaved = entitiesWithErrorsAmount === 0;

  return {
    responseEntitiesWithoutErrors,
    responseEntitiesWithErrors,
    areAllEntitiesSaved,
  };
};

const divideErrorResponse = responseEntitiesArray => {
  const {
    responseEntitiesWithErrors,
    responseEntitiesWithoutErrors,
  } = responseEntitiesArray
    .reduce((acc, responseEntity) => {
      if (_get(responseEntity, ['_error']) !== undefined) {
        acc.responseEntitiesWithErrors.push(responseEntity);
        return acc;
      }

      acc.responseEntitiesWithoutErrors.push(responseEntity);

      return acc;
    }, {
      responseEntitiesWithErrors: [],
      responseEntitiesWithoutErrors: [],
    });

  return {
    responseEntitiesWithoutErrors,
    responseEntitiesWithErrors,
  };
};

const saveEntitiesAndAddToStore = (host, model, entityOrEntitiesArr, isCreating = true, options = {}) =>
  dispatch =>
    dispatch(saveEntities(host, model, entityOrEntitiesArr, isCreating, options))
      .then(response => {

        const {
          responseEntitiesWithoutErrors,
          responseEntitiesWithErrors,
          areAllEntitiesSaved,
        } = response;

        const normalizedEntities = _keyBy(responseEntitiesWithoutErrors, 'id');

        dispatch(addEntitiesToStore({
          [model]: normalizedEntities,
        }));

        return {
          normalizedSavedEntities: normalizedEntities,
          responseEntitiesWithoutErrors,
          responseEntitiesWithErrors,
          areAllEntitiesSaved,
        };
      });


export const saveEntitiesOnServer = createRequestHandler(saveEntities, CA_BACKEND_SERVER_HOST, errorHandlerCa);
export const saveEntitiesOnServerAndAddToStore = createRequestHandler(
  saveEntitiesAndAddToStore,
  CA_BACKEND_SERVER_HOST,
  errorHandlerCa,
);

const deleteEntities = (host, model, entityOrEntitiesArr, options = {}) =>
  dispatch => {

    /*
    * Непонятно какой ответ возвращать в этом случае, потому что пока из функции 2 формата ответа,
    * если обработка ответа станет важной, то надо будет здесь доработать (см. комментарий после deleteEntitiesFromServer)
    * */
    if(!_size(entityOrEntitiesArr)) return Promise.resolve([]);

    const isArray = _isArray(entityOrEntitiesArr);
    const entitiesArr = isArray ? entityOrEntitiesArr : [entityOrEntitiesArr];

    const decamelizedModel = humps.decamelize(model);

    const baseUrl = [
      host,
      REST_REQUEST_TYPE,
      decamelizedModel,
    ].join('/');

    if (isArray) {
      return dispatch(deleteData(
        baseUrl,
        {
          [decamelizedModel]: entitiesArr,
        },
        options,
      ))
        .then(response => handleEntitiesErrors(response, decamelizedModel, options, isArray));
    }


    /*
    Для удаления одной сущности есть только ошибки со статусом 400, REST-ошибок валидации со статусом 200 для таких
    запросов нет и, вероятно, не будет, потому что ошибки со статусом 200 требуются только из-за кейсов, когда на сервере
    была сохранена часть списка. Поэтому handleEntitiesErrors тут не нужен.
    */
    return dispatch(deleteData(
      baseUrl,
      {
        [model]: entityOrEntitiesArr,
      },
      options,
    ))
      .then(() => entitiesArr);
  };

export const deleteEntitiesFromServer = createRequestHandler(deleteEntities, CA_BACKEND_SERVER_HOST, errorHandlerCa);

/*
* Если когда-нибудь понадобится action аналогичный другим, но для удаления сущностей - deleteEntitiesFromServerAndDeleteFromStore,
* то надо будет обдумать, как его правильно реализовать, т.к. deleteEntities возвращает сейчас разные форматы в
* зависимости от того удаляется ли 1 сущность или массив, при чём для массива, в ответе нет удаленных идентификаторов, а
* приходит массив с null'ами [null, null, ...]. Старая версия deleteEntitiesAndDeleteFromStore, когда удалялась только 1
* сущность выглядела так, но не использовалась (если понадобится, то надо адаптировать):
* const deleteEntitiesAndDeleteFromStore = (host, model, entityOrEntitiesArr, options = {}) =>
  dispatch => {
    if(!_size(entityOrEntitiesArr)) return Promise.resolve([]);

    return dispatch(deleteEntities(host, model, entityOrEntitiesArr, options))
      .then(entitiesArr => {
        const entitiesIds = entitiesArr.map(({ id }) => id);
        dispatch(deleteEntitiesFromStore(model, entitiesIds));
      });
  };
  *
  export const deleteEntitiesFromServerAndDeleteFromStore = createRequestHandler(
    deleteEntitiesAndDeleteFromStore,
    CA_BACKEND_SERVER_HOST,
    errorHandlerCa,
  );
* */