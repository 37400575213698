import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { CancelLabelTrans } from '../../../../utils/commonTransComponents';
import { SimpleConfirmDialog } from '../../SimpleConfirmDialog/SimpleConfirmDialog';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import { TextField } from '@mui/material';

import './style.css';
import { MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';


const PAUSE_SHEET_REASON_TEXTAREA_ROWS = 3;

export const PauseSheetDialog = props => {

  const {
    isOpen,
    closeDialog,
    onSubmit,
  } = props;

  const [pauseSheetReason, setPauseSheetReason] = useState('');

  return (
    <SimpleConfirmDialog
        className="pause-sheet-dialog"
        isOpen={isOpen}
        closeDialog={closeDialog}
        title={
          <Trans id="sheet_operation_review.pause_sheet_dialog@title">
            Остановить маршрутный лист
          </Trans>
        }
        confirmText={
          <Trans id="sheet_operation_review.pause_sheet_dialog@help_info">
            Остановленный маршрутный лист и задания по нему станут недоступными для исполнения. Вернуть
            остановленный маршрутный лист в производство сможет только пользователь с соответствующими
            правами ("Плановик" и "Мастер"). Если Вы уверены, что необходимо остановить маршрутный лист на выбранной
            операции с её текущим прогрессом выполнения, то укажите причину остановки и подтвердите действие
            нажатием кнопки "Остановить"
          </Trans>
        }
        additionalComponent={
          _renderPauseSheetReasonTextarea(pauseSheetReason, setPauseSheetReason)
        }
        confirmBtn={
          <Trans id="sheet_operation_review.pause_sheet_dialog@submit_button">
            Остановить
          </Trans>
        }
        cancelBtn={CancelLabelTrans}
        onConfirm={() => onSubmit(pauseSheetReason.trim())}
        disableConfirm={pauseSheetReason === ''}
    />
  );
};

const _renderPauseSheetReasonTextarea = (pauseSheetReason, setPauseSheetReason) =>
  <div className="pause-sheet-dialog___pause-sheet-reason">
    <div className="pause-sheet-dialog___pause-sheet-reason-label">
      <Trans id="sheet_operation_review.pause_sheet_dialog@reason_label">
        Причина остановки МЛ
      </Trans>
      {':'}
    </div>
    <TextField
        id="pause-sheet-dialog__pause-sheet-reason-textarea-id"
        className="pause-sheet-dialog__pause-sheet-reason-textarea"
        type="text"
        value={pauseSheetReason}
        onChange={e => setPauseSheetReason(e.target.value)}
        variant={MATERIAL_UI_VARIANT.OUTLINED}
        multiline
        rows={PAUSE_SHEET_REASON_TEXTAREA_ROWS}
        fullWidth
        autoFocus
    />
  </div>;

PauseSheetDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: FUNC_IS_REQUIRED_TYPE,
  onSubmit: FUNC_IS_REQUIRED_TYPE,
};
