import {
  fetchSheetsInProduction,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
  sendPartAndMaterialsForDefaultSheetConsumedNotification,
  fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
  fetchPartAndMaterialsReserveDataForAssemblyEntityBatch,
} from '../../../../operations/sheets';
import { fetchEquipmentClassInDepartmentTasksRemoteTableData } from '../../../../operations/tasks';
import { clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { deleteEntitiesFromStore } from '../../../../reducers/entities/actions';
import { SHEET_MODEL } from '../../../../constants/models';
import { clearAllDefaultSheetsPartsAndMaterialsToConsume } from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import {
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE, STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_MAIN_ROUTE,
} from '../../../../constants/routes';
import { matchPath } from 'react-router-dom';
import { getEquipmentClassInDepartmentTasksTableId } from '../../../../utils/tables';
import { push } from 'connected-react-router';
import {
  fetchSheetTypeRemoteTableDataCbFactory,
  fetchSheetTypeToReviewCbFactory,
} from '../../../../components/Sheets/SheetsContainer';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import { getMasterAppDepartmentIdsFromRouteParam } from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { fetchMasterDepartmentsTasksToDoRemoteTableData } from '../../../../operations/masterWorkspace/index';
import { masterTasksToDoAdditionalFiltersSelector } from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import { fetchFullAssemblySheetsPartsAndMaterials } from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import { deleteAllAssemblySheetsReserveData } from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';


/*
* Подробно о влиянии события комплектации МЛ на типы приложения описано в комментарии к handlePartsAndMaterialsForDefaultSheetConsumed
* в PartsAndMaterialsConsumingContentContainer. Обработка в handlePartsAndMaterialsConsumed реализована для
* пользователя, подтверждающего комплектацию в интерфейсе просмотра МЛ, ожидающих комплектацию
* В случае с получением события комлектации МЛ другими пользователями нужна похожая обработка, но, с учетом, того, что
* пользователи могут находиться в этот момент в разделах, которые сразу же должны быть обновлены - в этом случае,
* сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если перед перезапросом данных мы сначала
* очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим новые данные, то у пользователя в интерфейсе
* случится "скачок" (данные быстро пропадут, кое-где мелькнет сообщение о пустых данных, а потом тут же появятся новые
* данные), что нежелательно.
* Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
* повторном входе в интерфейсы запросы выполнились заново
* */
export const handlePartsAndMaterialsForDefaultSheetConsumption = message =>
  (dispatch, getState) => {
    const {
      sheetId,
      sheetIdentity,
      entityBatchId,
    } = message;

    sendPartAndMaterialsForDefaultSheetConsumedNotification(sheetIdentity);

    const state = getState();

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);

    //Если находимся в разделе "Плановик. МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });
    if(sheetsInProductionRouteMatch !== null)
      return dispatch(_updateIfOnSheetsInProductionScreen(sheetId, allTasksTablesIds));

    //Если находимся в разделе "Комплектование - "стандартные" неукомплектованные маршрутные листы"
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Комплектование - "сборочные" маршрутные листы"
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        entityBatchId,
        currentPathname,
        allTasksTablesIds,
      ));

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_MAIN_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        sheetId,
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
      ));
    }

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за комлектации МЛ, чтобы при следующем
    * входе в эти разделы данные были запрошены заново
    * */
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ]);
  };

/*
* Если находимся в разделе "Плановик. МЛ в производстве", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за комлектации МЛ, а для этого раздела перезапрашиваем данные списка МЛ для текущей
* страницы.
* */
const _updateIfOnSheetsInProductionScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      clearTableRemoteData([
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.IN_PRODUCTION,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchSheetsInProduction),
    ));
  };

/*
* Если находимся в разделе "Комлектование - стандартные МЛ", то очищаем данные других интерфейсов кроме этого, где должны
* быть обновления из-за комлектации МЛ.
* Для текущего раздела:
*  - Учитывая все особенности клиентского списка МЛ, ожидающих комплектацию, (описано в комментарии к
* handlePartsAndMaterialsConsumed в PartsAndMaterialsConsumingContentContainer), для обновления списка в данный момент
* не нужен перезапрос данных, надо удалить сущность укомплектованного МЛ из entities
* - Перезапрашиваем данные по возможной комплектации, после запроса они перезапишут старые данные в стор и экран
* обновится.
* - Если находимся в режиме просмотра конкретного МЛ и это только что укомплектованный МЛ, то выполняем редирект на
* список МЛ
* - Если находимся в режиме просмотра конкретного МЛ и это не тот МЛ, который только что укомплектовали, то нужно
* перезапросить детальную комплектацию, т.к. данные обновились (и мы чуть ранее очистили весь стор с детальной
* комплектацией)
*
* Первые 2 пункта обработки должны измениться при переходе на серверную таблицу.
*
* */
const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen = (sheetId, currentPathname, allTasksTablesIds) =>
  dispatch => {
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ]);

    dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage());

    const sheetWaitingPartsAndMaterialsReviewRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    if(sheetWaitingPartsAndMaterialsReviewRouteMatch === null) return;

    const {
      sheetId: sheetIdFromRoute,
    } = sheetWaitingPartsAndMaterialsReviewRouteMatch.params;

    if(sheetIdFromRoute === sheetId.toString()) {
      dispatch(push(STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE));
      return;
    }

    //Т.к. при броадкастинге нам, в общем случае, в обработчике неизвестны параметры просматриваемого МЛ (сейчас
    //они в entities, но таблица должна стать серверной и данные по просматриваемому МЛ будут храниться в локальном
    //state), то придется запросить его, чтобы получить идентификатор партии для перезапроса детальной комплектации
    const fetchSheet = fetchSheetTypeToReviewCbFactory(dispatch, fetchSheetsInProduction);

    fetchSheet(sheetIdFromRoute)
      .then(response => {

        if(!response || response.responseMeta.count === 0) return;

        const { entityBatchId } = response.entities[SHEET_MODEL][sheetIdFromRoute];

        dispatch(fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet(
          entityBatchId,
          sheetIdFromRoute,
        ));
      });

  };

/*
 * Если находимся в разделе "Комплектование - сборочные", то очищаем все данные других интерфейсов кроме
 * этого, где должны быть обновления из-за комлектации МЛ, а для этого раздела перезапрашиваем данные списка
 * сборочных МЛ для текущей страницы.
 * */
const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = (
  sheetId,
  entityBatchId,
  currentPathname,
  allTasksTablesIds,
) =>
  dispatch => {
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchFullAssemblySheetsPartsAndMaterials),
    ));

    const partsAndMaterialsReserveRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    // если находимся НЕ на экране резервирвоания, то удаляем все данные резервирования из store
    if (partsAndMaterialsReserveRouteMatch === null) {
      return dispatch(deleteAllAssemblySheetsReserveData());
    }

    // если на экране резервирования, то перезапрашиваем данные резервирования для него, а данные остальных
    // удаляем из store
    dispatch(deleteAllAssemblySheetsReserveData([sheetId]));
    return dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(entityBatchId, sheetId));
  };

/*
* Если находимся в разделе "Мастер. Требуется выполнить", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за комлектации МЛ, а для этого раздела перезапрашиваем задания для текущих параметров п
* росматриваемой таблицы заданий мастера, чтобы информация обновилась
* */
const _updateIfOnMasterTasksTodoScreen = (sheetId, departmentIds, allTasksTablesIds) =>
  (dispatch, getState) => {

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ]);

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_ID,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(departmentIds, masterTasksToDoAdditionalFilters, tableParams)),
    ));
  };

/*
* Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем все данные других
* интерфейсов кроме этого, где должны быть обновления из-за комлектации МЛ, а для этого раздела перезапрашиваем
* задания для текущих параметров просматриваемой таблицы, чтобы информация обновилась
* */
const _updateIfOnTasksViewScreen = (
  sheetId,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
) =>
  dispatch => {
    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ]);

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
        )),
    ));
  };