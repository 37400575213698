import { compose } from 'redux';
import { connect } from 'react-redux';

import { push } from 'connected-react-router';

import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';

import {
  setTasksEquipmentClassFilter,
  resetTasksEquipmentClassFilter,
} from '../../../reducers/workerApp/tasksMainFilters/actions';
import { departmentTasksFilterSelector } from '../../../reducers/workerApp/tasksMainFilters/selectors';

import {
  clearNotifications,
  EQUIPMENT_CLASS_CHOOSE_SCREEN_NOTIFICATION_ID,
  NOTIFICATION_LEVEL,
  sendNotification,
} from '../../../constants/notification';
import { WORKER_APP_MAIN_ROUTE } from '../../../constants/routes';

import { TasksOwnerChooseScreen } from './TasksOwnerChooseScreen';
import { initTasksDepartmentFilterFromRoute } from '../../../operations/departments';
import { fetchEquipmentClassesForDepartmentWithTasks } from '../../../operations/equipmentClasses';
import { reactRouterParamsSelector } from '../../../selectors/reactRouter';
import {
  eqClForDepWithTasksListSortedByIdentitySelector,
} from '../../../reducers/workerApp/tasksOwners/selectors';
import { NoEquipmentClassesWithTasksTrans, OnlyEquipmentClassesWithTasksDisplayedTrans } from './constants';


const mapStateToProps = (state, ownProps) => {
  const {
    departmentId: departmentIdFromRoute,
  } = reactRouterParamsSelector(null, ownProps);
  const {
    id: departmentIdTasksFilter,
  } = departmentTasksFilterSelector(state);
  return {
    departmentIdFromRoute,
    departmentIdTasksFilter,
    equipmentClassesList: eqClForDepWithTasksListSortedByIdentitySelector(state, { departmentId: departmentIdFromRoute }),
  };
};

const mapDispatchToProps = {
  initTasksDepartmentFilterFromRoute,
  push,
  setTasksEquipmentClassFilter,
  resetTasksEquipmentClassFilter,
  fetchEquipmentClassesForDepartmentWithTasks,
};

const mergeProps = (stateProps, dispatchProps) => {
  const {
    departmentIdFromRoute,
    departmentIdTasksFilter,
    equipmentClassesList,
  } = stateProps;
  const {
    push,
    setTasksEquipmentClassFilter,
    resetTasksEquipmentClassFilter,
    initTasksDepartmentFilterFromRoute,
    fetchEquipmentClassesForDepartmentWithTasks,
  } = dispatchProps;

  const fetchEquipmentClassesForDepartmentWithTasksCb = departmentId => {
    if(equipmentClassesList.length > 0) return Promise.resolve();

    return fetchEquipmentClassesForDepartmentWithTasks(departmentId);
  };

  return {
    tasksOwnersList: equipmentClassesList
      .map(equipmentClassEntity => {
        const {
          id,
          name: ownerMainTitle,
          identity: ownerSecondaryTitle,
        } = equipmentClassEntity;
        return {
          id,
          ownerMainTitle,
          ownerSecondaryTitle,
          ownerOriginalData: equipmentClassEntity,
        };
      }),
    resetTasksOwnerInStore: resetTasksEquipmentClassFilter,
    onSelectTasksOwner: (_, equipmentClassEntity) => {
      setTasksEquipmentClassFilter(equipmentClassEntity);
      push([
        WORKER_APP_MAIN_ROUTE,
        departmentIdTasksFilter,
        equipmentClassEntity.id,
      ].join('/'));
    },
    emptyOwnerListTrans: NoEquipmentClassesWithTasksTrans,
    showDisplayedTasksOwnersInfoNotification: () =>
      sendNotification(
        OnlyEquipmentClassesWithTasksDisplayedTrans,
        NOTIFICATION_LEVEL.INFO,
        { timeOut: 0, id: EQUIPMENT_CLASS_CHOOSE_SCREEN_NOTIFICATION_ID },
      ),
    clearDisplayedTasksOwnersInfoNotification: () =>
      clearNotifications([EQUIPMENT_CLASS_CHOOSE_SCREEN_NOTIFICATION_ID]),
    fetchRequiredData: () => {
      /*
      * Если фильтр уже установлен в state при маунте компонента, это означает, что в store такое подразделение точно есть
      * потому что экшн установки вызывается при нажатии на подразделение на экране выбора подразделений
      * */
      if(!!departmentIdTasksFilter) return fetchEquipmentClassesForDepartmentWithTasksCb(departmentIdTasksFilter);

      /*
      * Если же фильтр при маунте не установлен в store, то необходимо инициализировать его из параметра урла.
      * Если инициализировать не удалось (такого подразделения нет в store), то редиректим на экран выбора подразделений
      * */
      return initTasksDepartmentFilterFromRoute(departmentIdFromRoute)
        .then(
          departmentEntity => fetchEquipmentClassesForDepartmentWithTasksCb(departmentEntity.id),
          () => push(WORKER_APP_MAIN_ROUTE),
        );
    },
  };
};

export const EquipmentClassChooseScreenContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchRequiredData }) => fetchRequiredData(),
      },
    ],
  }),
)(TasksOwnerChooseScreen);

EquipmentClassChooseScreenContainer.displayName = 'EquipmentClassChooseScreenContainer';