import { Trans } from '@lingui/macro';
import React from 'react';
import { getSettingsEntityUniqId } from '../../../constants/settings';

export const IS_EQUIPMENT_FOR_OPERATION_REQUIRED = 'isEquipmentForOperationRequired';
export const SHOULD_CHECK_EQUIPMENT_AVAILABILITY = 'shouldCheckEquipmentAvailability';

export const SHEET_OPERATION_SETTINGS_INITIAL_STATE = {
  [IS_EQUIPMENT_FOR_OPERATION_REQUIRED]: false, // обязательность выбора оборудования
  [SHOULD_CHECK_EQUIPMENT_AVAILABILITY]: false, // проверка доступности оборудования
};


export const SHEET_OPERATION_SETTINGS_GROUP = 'SHEET_OPERATION_SETTINGS_GROUP';
export const SHEET_OPERATION_MAIN_SETTINGS_NAME = 'SHEET_OPERATION_MAIN_SETTINGS_NAME';
// будет использоваться для запроса
export const SHEET_OPERATION_MAIN_SETTINGS_ID = getSettingsEntityUniqId(
  SHEET_OPERATION_SETTINGS_GROUP,
  SHEET_OPERATION_MAIN_SETTINGS_NAME,
);

export const SHEET_OPERATIONS_SETTINGS_CHECKBOX_SCHEMA = [
  {
    name: IS_EQUIPMENT_FOR_OPERATION_REQUIRED,
    label: (
      <Trans id="sheet_operation_settings@equipment_is_required_option">
        Обязательный выбор оборудования
      </Trans>
    ),
    tooltipText: (
      <Trans id="sheet_operation_settings@equipment_is_required_option_description">
        Если настройка включена, то для начала выполнения операции будет требоваться заполнение поля "Оборудование"
      </Trans>
    ),
  },
  {
    name: SHOULD_CHECK_EQUIPMENT_AVAILABILITY,
    label: (
      <Trans id="sheet_operation_settings@should_check_equipment_availability_option">
        Проверка доступности оборудования
      </Trans>
    ),
    tooltipText: (
      <Trans id="sheet_operation_settings@should_check_equipment_availability_option_description">
        Если настройка включена, то начинать выполнение операции на выбранном оборудовании будет можно только, если
        это оборудование не занято на других операциях
      </Trans>
    ),
  },
];