import React, { useState } from 'react';

import { Trans } from '@lingui/macro';

import { CardWithCustomHeader } from '../common/CardWithCustomHeader/CardWithCustomHeader';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import FaceIcon from '@mui/icons-material/Face';
import LockIcon from '@mui/icons-material/Lock';

import Button from '@mui/material/Button';

import { FUNC_IS_REQUIRED_TYPE } from '../../constants/propTypes';

import './style.css';
import { MATERIAL_UI_SIZE, MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../constants/materialUI';
import { PasswordLabelTrans, UserIdentityLabelTrans } from '../../utils/commonTransComponents';
import { FullScreenReloadIconButton } from '../../fullscreen/FullScreenReloadIconButton/FullScreenReloadIconButton';
import { SHOW_APP_RELOAD_BUTTONS_ONLY_ON_FULL_SCREEN } from '../../fullscreen/constants';
import { ReloadIconButton } from '../ReloadIconButton/ReloadIconButton';


export const LoginScreen = ({ submitLogin }) => {

  const [userIdentity, setUserIdentity] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmitLogin = e => {
    e.preventDefault();
    submitLogin({
      userIdentity: userIdentity.trim(),
      password: password.trim(),
    });
  };

  const LoginFormCardContent = (
    <div className="login-screen__form-card-content">
      <form onSubmit={handleSubmitLogin}>
        <div className="login-screen__form-fields-wrap">
          {
            renderFormField({
              formKey: 'userIdentity',
              label: UserIdentityLabelTrans,
              value: userIdentity,
              onChange: setUserIdentity,
              type: 'text',
              IconComponent: FaceIcon,
              autoFocus: true,
            })
          }
          {
            renderFormField({
              formKey: 'password',
              label: PasswordLabelTrans,
              value: password,
              onChange: setPassword,
              type: 'password',
              IconComponent: LockIcon,
              autoFocus: false,
            })
          }
        </div>
        {
          renderSubmitButton(userIdentity, password)
        }
      </form>
    </div>
  );

  return (
    <div className="login-screen">
      <div className="login-screen__top-panel">
        {
          SHOW_APP_RELOAD_BUTTONS_ONLY_ON_FULL_SCREEN ?
            <FullScreenReloadIconButton /> :
            <ReloadIconButton />
        }
      </div>
      <div className="login-screen__form-card-wrapper">
        <CardWithCustomHeader
            className="login-screen__form-card"
            header={LoginFormCardHeader}
            content={LoginFormCardContent}
        />
      </div>
    </div>
  );
};

const LoginFormCardHeader = (
  <div className="login-screen__form-card-header">
    {
      <Trans id="login@authorization_title">
        Авторизация
      </Trans>
    }
  </div>
);

/*eslint-disable react/prop-types*/
const renderFormField = ({
  formKey,
  label,
  placeholder,
  value,
  onChange,
  type,
  IconComponent,
  autoFocus,
}) =>
  <div className="login-screen__form-field-wrap">
    <TextField
        className="login-screen__form-field"
        variant={MATERIAL_UI_VARIANT.STANDARD}
        id={`login-screen__form-field_${formKey}`}
        label={label}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        fullWidth
        InputProps={{
          className: 'login-screen__form-field-input-wrap',
          type,
          startAdornment: (
            <InputAdornment position="start">
              <IconComponent className="login-screen__form-field-icon" fontSize={MATERIAL_UI_SIZE.LARGE} />
            </InputAdornment>
          ),
        }}
        autoFocus={autoFocus || undefined}
    />
  </div>;
/*eslint-enable react/prop-types*/

const renderSubmitButton = (userIdentity, password) =>
  <div className="login-screen__form-submit-button-wrap">
    <Button
        className="login-screen__form-submit-button"
        type="submit"
        color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
        disabled={
          userIdentity === '' ||
          password === ''
        }
    >
      <Trans id="login@login_submit_button">
        Войти
      </Trans>
    </Button>
  </div>;

LoginScreen.propTypes = {
  submitLogin: FUNC_IS_REQUIRED_TYPE,
};