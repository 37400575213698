import { ORDER_ENTRY_MODEL, SHEET_OPERATION_MODEL } from '../constants/models';
import { TASK_VIEW_SCREEN_MODEL } from '../reducers/schemaModel/models/taskViewScreenSchema';


/*
* Для удаленных таблиц важна стабильность сортировки, т.к., иначе, при получении данных на ближайших страницах, ряды
* начинают повторяться, анализировать данные таблицы становится очень сложно. По-умолчанию, сортировка в запросах
* API коллекций - нестабильна. Для реализации стабильности сортировки, к основной сортировке таблицы,
* обычно добавляют дополнительную сортировку по полям, которые являются уникальными для ряда таблицы. Эта функция, как
* раз, для этого, к основной сортировке tableSortParams добавляется множественная сортировка по одной или нескольким
* заданным колонкам - columnsForSortStability (сюда нужно задавать колонку или несколько колонок, которые
* определяют уникальность ряда удаленной таблицы). Т.к., иногда, в таблице пользователю может быть разрешена сортировка
* по колонкам, определяющим уникальность ряда, то здесь дополнительно проверяется наличие tableSortParams в
* columnsForSortStability, чтобы не делать несколько сортировок по одной и той же колонке. После указанной выше
* фильтрации, по колонкам в columnsForSortStability добавляется дополнительная сортировка просто "по возрастанию",
* независимо от направления основной сортировки, т.к. это не особо важно, здесь цель - обеспечить стабильность сортировки
* */
export const prepareRemoteTableSortParamsForRequest = (tableSortParams, columnsForSortStability = []) => {

  const resultColumnsForSortStability =  columnsForSortStability
    .filter(columnName => tableSortParams.every(({ column }) => column !== columnName));

  return tableSortParams
    .map(({ column, asc }) => ({
      column,
      params: [{ key: 'asc', value: asc }],
    }))
    .concat(
      resultColumnsForSortStability
        .map(columnName => ({
          column: columnName,
          params: [{ key: 'asc', value: true }],
        })),
    );
};

export const createSheetTypeOperationsTableId = (sheetType, sheetId) =>
  [SHEET_OPERATION_MODEL, sheetType, sheetId].join('--');

export const getEquipmentClassInDepartmentTasksTableId = (departmentId, equipmentClassId) => [
  TASK_VIEW_SCREEN_MODEL,
  departmentId,
  equipmentClassId,
].join('--');

export const createOrderEntriesTableId = (orderIdentity, orderId) =>
  [ORDER_ENTRY_MODEL, orderIdentity, orderId].join('--');

export const prepareRemoteTableFilterParamsForRequest = tableFilterParams => Object
  .keys(tableFilterParams)
  .map(column => {
    const {
      filterType,
      filterValue,
    } = tableFilterParams[column];

    return {
      column,
      filterType,
      filterValue,
    };
  });