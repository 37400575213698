import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FUNC_IS_REQUIRED_TYPE,
} from '../../../constants/propTypes';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import { AllSheetsToStartContainer } from './AllSheetsToStart/AllSheetsToStartContainer';
import { AssemblySheetsToStartContainer } from './AssemblySheetsToStart/AssemblySheetsToStartContainer';

import './style.css';
import { SHEET_TYPE } from '../../../constants/sheets';
import { AllLabelTrans, AssemblyLabelTrans } from '../../../utils/commonTransComponents';


export const SHEETS_TO_START_TYPES_TABS = [
  {
    value: SHEET_TYPE.ALL_TO_START,
    label: AllLabelTrans,
  },
  {
    value: SHEET_TYPE.ASSEMBLY_TO_START,
    label: AssemblyLabelTrans,
  },
];

export class SheetsToStart extends Component {

  static propTypes = {
    selectedSheetsToStartType: PropTypes.oneOf(SHEETS_TO_START_TYPES_TABS.map(({ value }) => value)),
    setSelectedSheetsToStartType: FUNC_IS_REQUIRED_TYPE,
  };

  _onSelectSheetsToStartType = (e, newViewMode) => {
    /*
    * Если повторно нажата кнопка? то в обработчик приходит viewMode = null. В таком случае не требуется вызывать
    * обработчик, т.к. значение не изменилось
    * */
    if (newViewMode === null) return;

    this.props.setSelectedSheetsToStartType(newViewMode);
  }

  render() {

    const { selectedSheetsToStartType } = this.props;

    return(
      <div className="sheets-to-start">
        <ToggleButtonGroup
            className="sheets-to-start__selected-type-toggle-btn-group"
            value={selectedSheetsToStartType}
            exclusive
            onChange={this._onSelectSheetsToStartType}
        >
          {
            SHEETS_TO_START_TYPES_TABS
              .map(({ value, label }) =>
                <ToggleButton
                    key={value}
                    classes={{
                      selected: 'sheets-to-start__selected-type-btn--selected',
                    }}
                    value={value}
                >
                  {label}
                </ToggleButton>)
          }
        </ToggleButtonGroup>
        {
          selectedSheetsToStartType === SHEETS_TO_START_TYPES_TABS[0].value ?
            <AllSheetsToStartContainer /> :
            <AssemblySheetsToStartContainer />
        }
      </div>
    );
  }
}
