import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';

import './style.css';

import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../../hoc/withPermissionsManager/constants';
import { FUNC_IS_REQUIRED_TYPE, NUMBER_OR_STRING_TYPE } from '../../../../../constants/propTypes';
import { Trans } from '@lingui/macro';
import {
  reserveAndConsumeTableEditableFieldNormalizer,
  reserveAndConsumeTableFilterFunc,
} from '../../../../../utils/reserveAndConsumeCommon/index';
import {
  CONSUME_ENTITIES_TABLE_COLUMNS_SCHEMA,
  consumeEntitiesComparator,
  consumeEntitiesPrepareDataBeforeSubmit,
  consumeEntitiesPrepareTableRowData,
  consumeEntityValidator,
} from './constants';
import { EntitiesTableWithEditableColumn } from '../../../EntitiesTableWithEditableColumn/EntitiesTableWithEditableColumn';


export const ConsumeEntities = props => {
  const {
    initialConsumeData,
    onEntitiesConsume,
    PermissionsManager,
    onEditableValuesChange,
  } = props;

  return (
    <div className="consume-entities">

      <div className="consume-entities__title">
        <Trans id="sheet_operation_consume_entities_dialog@title">
          Потребление ДСЕ
        </Trans>
      </div>

      <Divider />

      <EntitiesTableWithEditableColumn
          onEditableValuesChange={onEditableValuesChange}
          className="consume-entities__table"
          initialRowsDataMap={initialConsumeData}
          onSubmitChanges={onEntitiesConsume}
          submitChangesConfirmTitle={
            <Trans id="sheet_operation_consume_entities_dialog@confirm_consume_title">
              Подтверждение потребления ДСЕ
            </Trans>
          }
          submitChangesConfirmText={
            <Trans id="sheet_operation_consume_entities_dialog@confirm_consume_message">
              Вы уверены, что хотите потребить выбранные ДСЕ из резерва? Это действие нельзя отменить
            </Trans>
          }
          areChangesPermitted={PermissionsManager.isGranted(PERMISSION.CONSUME_ENTITIES_FOR_ASSEMBLY_SHEET)}
          editableField="consumedAmount"
          availableAmountKey="reservedAmount"
          rowKeyField="entityId"
          rowValidator={consumeEntityValidator}
          filterFunc={reserveAndConsumeTableFilterFunc}
          sortFunc={consumeEntitiesComparator}
          prepareRowData={consumeEntitiesPrepareTableRowData}
          prepareDataBeforeSubmit={consumeEntitiesPrepareDataBeforeSubmit}
          columnsSchema={CONSUME_ENTITIES_TABLE_COLUMNS_SCHEMA}
          editableFieldNormalizer={reserveAndConsumeTableEditableFieldNormalizer}
      />

    </div>
  );
};


const ENTITY_BATCH_FOR_CONSUME_FROM_ASSEMBLY_SHEET_TYPE = PropTypes.objectOf(
  PropTypes.shape({
    id: NUMBER_OR_STRING_TYPE,
    identity: PropTypes.string,
    reservedAmount: PropTypes.number,
    consumedAmount: PropTypes.number,
  }),
).isRequired;

ConsumeEntities.propTypes = {
  PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  onEntitiesConsume: FUNC_IS_REQUIRED_TYPE,
  onEditableValuesChange: PropTypes.func,
  initialConsumeData: PropTypes.objectOf(
    PropTypes.shape({
      entityId: NUMBER_OR_STRING_TYPE.isRequired,
      entityCombinedName: PropTypes.string.isRequired,
      consumedAmount: PropTypes.number.isRequired,
      reservedAmount: PropTypes.number.isRequired,
      requiredAmount: PropTypes.number.isRequired,
      remainingAmount: PropTypes.number.isRequired,
      entityBatches: ENTITY_BATCH_FOR_CONSUME_FROM_ASSEMBLY_SHEET_TYPE,
    }),
  ),
};
