import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { SimpleItemChoosePanel } from '../../common/SimpleItemChoosePanel/SimpleItemChoosePanel';

import { FUNC_IS_REQUIRED_TYPE, NUMBER_OR_STRING_TYPE, OBJECT_OF_ANY_TYPE } from '../../../constants/propTypes';

import './style.css';
import { IdentityLabelTrans } from '../../../utils/commonTransComponents';


export class TasksOwnerChooseScreen extends Component {

  static propTypes = {
    tasksOwnersList: PropTypes.arrayOf(PropTypes.shape({
      id: NUMBER_OR_STRING_TYPE.isRequired,
      ownerMainTitle: PropTypes.string.isRequired,
      ownerSecondaryTitle: PropTypes.string.isRequired,
      ownerOriginalData: OBJECT_OF_ANY_TYPE,
    })),
    onSelectTasksOwner: FUNC_IS_REQUIRED_TYPE,
    resetTasksOwnerInStore: FUNC_IS_REQUIRED_TYPE,
    emptyOwnerListTrans: PropTypes.node,
    showDisplayedTasksOwnersInfoNotification: FUNC_IS_REQUIRED_TYPE,
    clearDisplayedTasksOwnersInfoNotification: FUNC_IS_REQUIRED_TYPE,
  };

  componentDidMount() {
    const {
      resetTasksOwnerInStore,
      showDisplayedTasksOwnersInfoNotification,
    } = this.props;
    /*
    * Если заходим на экран, то сбрасываем установленное значение в store по выбранной ранее сущности , т.к., в любом
    * случае, будет выбран новая, а от значения в store зависит заголовок на навигационной панели
    * */
    resetTasksOwnerInStore();

    showDisplayedTasksOwnersInfoNotification();
  }

  componentWillUnmount() {
    this.props.clearDisplayedTasksOwnersInfoNotification();
  }

  _createItemsList = () => this.props.tasksOwnersList
    .map(tasksOwnerData => ({
      itemId: tasksOwnerData.id,
      itemData: tasksOwnerData.ownerOriginalData,
      itemContent: this._renderTasksOwnerItemContent(tasksOwnerData),
      gridItemWrapClassName: 'tasks-owner-choose-screen__tasks-owner-card-grid-wrap',
      itemClassName: 'tasks-owner-choose-screen__tasks-owner-card',
    }));

  _renderTasksOwnerItemContent = ({ ownerMainTitle, ownerSecondaryTitle }) =>
    <React.Fragment>
      {this._renderTasksOwnerMainTitle(ownerMainTitle)}
      {this._renderTasksOwnerSecondaryTitle(ownerSecondaryTitle)}
    </React.Fragment>;

  _renderTasksOwnerMainTitle = ownerMainTitle =>
    <div className="tasks-owner-choose-screen__tasks-owner-card-main-title">
      {ownerMainTitle}
    </div>;

  _renderTasksOwnerSecondaryTitle = ownerSecondaryTitle =>
    <div className="tasks-owner-choose-screen__tasks-owner-card-secondary-title">
      <div className="tasks-owner-choose-screen__tasks-owner-card-secondary-title-label">
        {IdentityLabelTrans}
      </div>
      <div className="tasks-owner-choose-screen__tasks-owner-card-secondary-title-value">
        {ownerSecondaryTitle}
      </div>
    </div>;

  render() {
    const {
      onSelectTasksOwner,
      emptyOwnerListTrans,
    } = this.props;
    return (
      <div className="tasks-owner-choose-screen">
        <SimpleItemChoosePanel
            itemsList={this._createItemsList()}
            onSelectItem={onSelectTasksOwner}
            noItemsText={emptyOwnerListTrans}
        />
      </div>
    );
  }
}
