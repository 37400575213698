import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import _isFunction from 'lodash/isFunction';
import cn from 'classnames';
import {
  ChooseDepartmentLabelTrans,
  ChooseEquipmentClassLabelTrans,
  DepartmentLabelTrans,
  EquipmentClassLabelTrans,
} from '../../../utils/commonTransComponents';

import './style.css';

export class WorkerNavigationPanel extends Component {

  static propTypes = {
    departmentTitle: PropTypes.string,
    equipmentClassTitle: PropTypes.string,
    onGoBackButtonClick: PropTypes.func,
  };

  _renderGoBackButton = () => {
    const { onGoBackButtonClick } = this.props;
    const isGoBackButtonInvisible = !_isFunction(onGoBackButtonClick);

    return this._renderControlButton({
      className: cn(
        'worker-navigation-panel__go-back-button',
        { 'worker-navigation-panel__go-back-button--invisible': isGoBackButtonInvisible },
      ),
      IconComponent: ArrowBack,
      onClick: onGoBackButtonClick,
    });
  };

  _renderControlButton = ({
    className,
    IconComponent,
    onClick,
  }) =>
    <IconButton
        className={cn(
          'worker-navigation-panel__control-button',
          className,
        )}
        onClick={onClick}
        color="inherit"
        size="large"
    >
      <IconComponent className="worker-navigation-panel__control-button-icon"/>
    </IconButton>

  _renderTitles = () =>
    <div className="worker-navigation-panel__titles">
      {this._renderEquipmentClassTitle()}
      {this._renderDepartmentTitle()}
    </div>;

  _renderEquipmentClassTitle = () => {
    const { departmentTitle, equipmentClassTitle } = this.props;
    return this._renderTitle({
      titleContent: this._getTitleContent(
        equipmentClassTitle,
        EquipmentClassLabelTrans,
        ChooseEquipmentClassLabelTrans,
      ),
      isActive: true,
      isInvisible: !departmentTitle,
      title: equipmentClassTitle ? equipmentClassTitle : undefined,
    });
  };

  _getTitleContent = (titleValue, titleLabelTrans, selectCurrentEntityTitleTrans) => {
    if(!titleValue)
      return selectCurrentEntityTitleTrans;

    return(
      <React.Fragment>
        {titleLabelTrans}
        {': '}
        {titleValue}
      </React.Fragment>
    );
  };

  _renderDepartmentTitle = () => {
    const { departmentTitle, equipmentClassTitle } = this.props;
    return this._renderTitle({
      titleContent: this._getTitleContent(
        departmentTitle,
        DepartmentLabelTrans,
        ChooseDepartmentLabelTrans,
      ),
      isActive: !departmentTitle || !!equipmentClassTitle,
      isInvisible: false,
      title: departmentTitle ? departmentTitle : undefined,
    });
  };

  _renderTitle = ({ titleContent, isActive = false, isInvisible = false, title }) =>
    <div
        className={cn(
          'worker-navigation-panel__title',
          { 'worker-navigation-panel__title--active': isActive },
          { 'worker-navigation-panel__title--invisible': isInvisible },
        )}
        title={title}
    >
      {titleContent}
    </div>;


  render() {
    return(
      <nav className="worker-navigation-panel">
        {this._renderGoBackButton()}
        {this._renderTitles()}
      </nav>
    );
  }
}
