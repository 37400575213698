import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../../api/restCollectionApi/index';
import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../../reducers/autocomplete/actions';
import { EQUIPMENT_MODEL } from '../../../../constants/models';


export const createSheetOperationEquipmentAutocompleteId = (departmentId, equipmentClassId) =>
  ['SHEET_OPERATION_EQUIPMENT_AUTOCOMPLETE_ID', departmentId, equipmentClassId].join('__');

export const getSheetOperationEquipmentAutocompleteLoadOptionsActionCreator = (departmentId, equipmentClassId) => {
  const departmentIdFilter = {
    filterGroupType: FILTER_GROUP_TYPES.AND,
    filters: [
      {
        column: 'departmentId',
        filterType: FILTER_TYPES.EQUALS,
        filterValue: departmentId,
      },
      {
        column: 'equipmentClassId',
        filterType: FILTER_TYPES.EQUALS,
        filterValue: equipmentClassId,
      },
    ],
  };

  return createCaEntitiesAutocompleteLoadOptionsActionCreator(
    {
      requestModel: EQUIPMENT_MODEL,
      getRequestQuery: inputValue => {

        if (!inputValue) return {
          filter: departmentIdFilter,
          limit: undefined,
        };

        return {
          limit: undefined,
          filter: {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters: [
              departmentIdFilter,
              {
                filterGroupType: FILTER_GROUP_TYPES.OR,
                filters: [
                  {
                    column: 'identity',
                    filterType: FILTER_TYPES.CONTAINS,
                    filterValue: inputValue,
                  },
                  {
                    column: 'name',
                    filterType: FILTER_TYPES.CONTAINS,
                    filterValue: inputValue,
                  },
                ],
              },
            ],
          },
        };
      },
    },
  );
};