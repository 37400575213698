import { SHEET_OPERATION_STATUS } from '../../constants/sheets';


export const sheetOperationStatusRowStyle = (itemId, rowData) => {
  // В поле _original хранятся все данные строки, включая те, для которых в модели таблицы не предусмотрено колонок
  const { status } = rowData;
  return ({
    opacity: status === SHEET_OPERATION_STATUS.FINISHED ? 0.5 : 1,
    fontWeight: (status === SHEET_OPERATION_STATUS.IN_PRODUCTION) ?
      'bold' :
      'normal',
  });
};