import { toast } from 'react-toastify';
import _size from 'lodash/size';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import { ChangesSavedSuccessfullyLabelTrans } from '../utils/commonTransComponents';

export const NOTIFICATION_LEVEL = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const DEPARTMENT_CHOOSE_SCREEN_NOTIFICATION_ID = 'DEPARTMENT_CHOOSE_SCREEN_NOTIFICATION_ID';

export const EQUIPMENT_CLASS_CHOOSE_SCREEN_NOTIFICATION_ID = 'EQUIPMENT_CLASS_CHOOSE_SCREEN_NOTIFICATION_ID';

const DEFAULT_NOTIFICATION_CLOSE_TIMEOUT_IN_MS = 5000;
const DEFAULT_NOTIFICATION_POSITION = 'bottom-right';


const {
  success,
  warning,
  info,
  error,
  dismiss,
} = toast;

const NOTIFICATION_ACTIONS_BY_LEVEL = {
  [NOTIFICATION_LEVEL.SUCCESS]: success,
  [NOTIFICATION_LEVEL.WARNING]: warning,
  [NOTIFICATION_LEVEL.INFO]: info,
  [NOTIFICATION_LEVEL.ERROR]: error,
};

const getAutoCloseValue = timeOut => {
  /*
  * В нашем апи передача timeOut: 0 означает, что такие нотификейшены не нужно скрывать без явной команды на скрытие.
  * Апи компонента ожидает для такого случая значение false
  */
  if (timeOut === 0) return false;

  if (_isNil(timeOut)) return DEFAULT_NOTIFICATION_CLOSE_TIMEOUT_IN_MS;

  return timeOut;
};

const transformOptionsToNotificationSystemApi = (options = {}) => {

  const {
    id: toastId,
    timeOut: autoClose,
    ...restNotificationOptions
  } = options;

  return {
    toastId,
    autoClose: getAutoCloseValue(autoClose),
    position: DEFAULT_NOTIFICATION_POSITION,
    renderDefaultComponent: true,
    draggable: false,
    hideProgressBar: true,
    icon: false,
    theme: 'colored',

    // остальные опции можно посмотреть в документации: https://fkhadra.github.io/react-toastify/api/toast
    ...restNotificationOptions,
  };
};

export const sendNotification = (message, level, options) => {

  const transformedOptions = {
    ...transformOptionsToNotificationSystemApi(options),
    message,
  };

  const action = _get(NOTIFICATION_ACTIONS_BY_LEVEL, [level], NOTIFICATION_ACTIONS_BY_LEVEL[NOTIFICATION_LEVEL.INFO]);

  return action(message, transformedOptions);
};

export const clearNotifications = toastToDismissIds =>
  _size(toastToDismissIds) > 0
    ? toastToDismissIds.forEach(dismiss)
    : dismiss();


export const sendChangesSavedSuccessfullyNotification = () =>
  sendNotification(
    ChangesSavedSuccessfullyLabelTrans,
    NOTIFICATION_LEVEL.SUCCESS,
  );