import { createSelector } from 'reselect';
import { tableCurrentRemoteDataSelector } from '../reducers/table/selectors';
import { SHEET_OPERATION_FEATURES_MODEL } from '../constants/models';
import _size from 'lodash/size';

export const sheetOperationFeaturesRemoteTableDataSelector = createSelector(
  tableCurrentRemoteDataSelector,
  currentRemoteData => {

    if(currentRemoteData === null) return [];

    const{
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const sheetOperationsFeaturesEntitiesIds = currentRemoteItemsIds[SHEET_OPERATION_FEATURES_MODEL];

    if (_size(sheetOperationsFeaturesEntitiesIds) === 0) return [];

    const {
      [SHEET_OPERATION_FEATURES_MODEL]: sheetOperationFeaturesEntities = {},
    } = currentRemoteItemsById;

    return sheetOperationsFeaturesEntitiesIds
      .map(sheetOperationId => {

        const sheetOperationFeaturesEntity = sheetOperationFeaturesEntities[sheetOperationId];

        const {
          id,
          name,
          createStamp,
        } = sheetOperationFeaturesEntity;

        return {
          id,
          name,
          createStamp,
        };
      });
  },
);
