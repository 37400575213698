import {
  transformUrlGetParamsToTableParams,
} from '../../components/tableFactory/TableUsedUrlGetParams/transformUrlGetParamsToTableParams';
import { tableParamsSelector } from './selectors';

export const TABLE_SET_PARAMS = 'TABLE_SET_PARAMS';
export const TABLE_CLEAR_REMOTE_DATA = 'TABLE_CLEAR_REMOTE_DATA';
export const TABLE_CLEAR_DATA = 'TABLE_CLEAR_DATA';
export const TABLE_SET_REMOTE_SUMMARY_DATA = 'TABLE_SET_REMOTE_SUMMARY_DATA';
export const TABLE_UPDATE_REMOTE_ITEM_BY_ID = 'TABLE_UPDATE_REMOTE_ITEM_BY_ID';

/*
* Сортировка таблицы в текущей реализации привязана к модели таблицы на не к id. Подробнее о причинах в reducers/table/reducer.js,
* поэтому в экшн передается и tableId и tableModel, а обрабатывается он в редьюсерах table и schemaModel.
* tableModel на третьем месте среди параметров, т.к. предполагалось, что всё будет привязываться к tableId, а tableModel
* просто удалится, но пока было решено оставить такую логику
*
* */
export const setTableParams = (
  tableId,
  tableParams,
  tableModel,
) => ({
  type: TABLE_SET_PARAMS,
  tableId,
  tableParams,
  tableModel,
});

export const updateRemoteTableItemById = (tableId, model, itemId, itemDataToUpdate = {}) => ({
  type: TABLE_UPDATE_REMOTE_ITEM_BY_ID,
  tableId,
  model,
  itemId,
  itemDataToUpdate,
});

export const clearTableRemoteData = tableIds => ({
  type: TABLE_CLEAR_REMOTE_DATA,
  tableIds,
});

export const clearTableData = tableIdOrIdsArray => ({
  type: TABLE_CLEAR_DATA,
  tableIdOrIdsArray,
});

export const setRemoteTableSummaryData = (tableId, summaryData) => ({
  type: TABLE_SET_REMOTE_SUMMARY_DATA,
  tableId,
  summaryData,
});

export const setTableParamsFromUrlGetParams = (
  urlGetParams,
  { tableId, tableModel },
) =>
  setTableParams(
    tableId,
    transformUrlGetParamsToTableParams(urlGetParams),
    tableModel,
  );

/*
* Довольно часто требуется обновить данные удаленной таблицы для текущих параметров. Например, когда стало известно, что
* таблицу обновил какой-то другой источник. Т.е. это аналогия сброса кэша (хотя у таблиц у нас, можно сказать, кэша и
* нет, точнее будет сказать, это просто сброс\обновление текущих данных таблицы в табличном store)
* */
export const reFetchRemoteTableData = (tableId, tableModel, fetchRemoteTableData) =>
  (dispatch, getState) => {
    const {
      activePage,
      pageSize,
      sortParams,
      filterParams,
     } = tableParamsSelector(getState(), { tableId, tableModel });

    return fetchRemoteTableData({
      tableId,
      tableModel,
      tableParams: {
        sortParams,
        filterParams,
        activePage,
        pageSize,
      },
    })
    /*
    * API подразумевает, что параметр fetchRemoteTableData должен возвращать:
    * itemsIds - массив идентификаторов, определяющих ряды удаленной таблицы для текущих параметров запроса
    * (массив определяет порядок серверной сортировки, данные рядов будут собираться по itemsById)
    * itemsById - нормализованные данные, для рядов удаленной таблицы для текущих параметров запроса
    * (чтобы уменьшить количество хранимых данных, в нормализованном виде все уникальные сущности будут
    * храниться в одном экземпляре + это облегчает быстрый доступ к сущностям по id без пробегов по массивам)
    * totalItemsAmount - общее количество рядов удаленной таблицы для текущих параметров запроса (чтобы корректно
    * отображать пагинацию)
    * */
      .then(
        ({ itemsIds, itemsById, totalItemsAmount }) =>
          dispatch(setTableParams(
            tableId,
            {
              remoteData: {
                currentRemoteItemsIds: itemsIds,
                currentRemoteItemsById: itemsById,
                totalRemoteItemsAmount: totalItemsAmount,
              },
            },
            tableModel,
          )),
      );
  };