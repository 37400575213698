import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { SheetOperationsTable } from '../../SheetOperationsTable/SheetOperationsTable';
import { SheetToStartParamsFormDialog } from './SheetToStartParamsFormDialog/SheetToStartParamsFormDialog';

import {
  ARRAY_OF_ANY_OBJECTS_TYPE,
  SHEET_SUMMARY_TYPE,
  FUNC_IS_REQUIRED_TYPE,
} from '../../../../constants/propTypes';

import './style.css';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';
import { SHEET_TO_START_OPERATION_MODEL } from '../../../../reducers/schemaModel/models/sheetToStartOperationSchema';
import { StartSheetInProductionLabelTrans } from '../../../../utils/commonTransComponents';


export class SheetToStartReviewContent extends Component {

  static propTypes = {
    mainPlanningSessionId: PropTypes.number,
    sheetOperationsTableData: ARRAY_OF_ANY_OBJECTS_TYPE,
    sheetToReviewData: SHEET_SUMMARY_TYPE,
    startSheet: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  constructor(props) {
    super(props);
    this.state = {
      isStartSheetDialogOpen: false,
    };
  };

  _renderStartNewSheetButton = () =>
    <div className="sheet-to-start-review-content__start-button">
      <Button
          color="secondary"
          variant="contained"
          onClick={this._openStartSheetDialog}
      >
        {StartSheetInProductionLabelTrans}
      </Button>
    </div>;

  _openStartSheetDialog = () => this.setState({ isStartSheetDialogOpen: true });

  _renderSheetOperationsTable = () => {
    const {
      sheetOperationsTableData,
    } = this.props;

    //Если таблица станет серверной, то sheetOperationsTableId должен начать учитывать идентификатор МЛ, как в случае с
    //МЛ в производстве и с завершенными МЛ
    return(
      <SheetOperationsTable
          sheetOperationsTableModel={SHEET_TO_START_OPERATION_MODEL}
          sheetOperationsTableId={SHEET_TO_START_OPERATION_MODEL}
          sheetOperationsTableData={sheetOperationsTableData}
      />
    );
  };

  _renderSheetToStartParamsFormDialog = () => {
    const {
      sheetToReviewData,
      mainPlanningSessionId,
    } = this.props;
    const {
      isStartSheetDialogOpen,
    } = this.state;
    return(
      <SheetToStartParamsFormDialog
          isOpen={isStartSheetDialogOpen}
          onSubmit={this._handleSheetStart}
          closeDialog={this._handleSheetStartDialogClose}
          sheetToStartData={{ ...sheetToReviewData }}
          mainPlanningSessionId={mainPlanningSessionId}
      />
    );
  };

  _handleSheetStart = newSheetIdentity => {
    const {
      startSheet,
      sheetToReviewData: {
        entityBatchId,
        entityBatchIdentity,
      },
    } = this.props;

    return startSheet(entityBatchId, entityBatchIdentity, newSheetIdentity);
  };

  _handleSheetStartDialogClose = () => this.setState({ isStartSheetDialogOpen: false });

  render() {
    return(
      <div className="sheet-to-start-review-content">
        {
          this.props.PermissionsManager.isGranted(PERMISSION.START_SHEET) ?
            this._renderStartNewSheetButton() :
            null
        }
        {this._renderSheetOperationsTable()}
        {this._renderSheetToStartParamsFormDialog()}
      </div>
    );
  }
}
