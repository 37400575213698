import { SHOW_WORKER_TASKS_TABLE_SETTINGS } from './actions';

import { WORKER_TASKS_TABLE_MAIN_SETTINGS_ID } from '../../constants/settings';

const WORKER_TASKS_TABLE_SETTINGS_INITIAL_STATE = {
  selectedSettingsId: WORKER_TASKS_TABLE_MAIN_SETTINGS_ID,
};

export const workerTasksTableSettings = (state = { ...WORKER_TASKS_TABLE_SETTINGS_INITIAL_STATE }, action) => {
  switch(action.type) {
  case SHOW_WORKER_TASKS_TABLE_SETTINGS:
    return {
      ...state,
      selectedSettingsId: action.settingsId,
    };
  default:
    return state;
  }
};