import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AppConfirmContext } from './AppConfirmContext';
import { AppConfirmDialog } from './AppConfirmDialog';


export const AppConfirmProvider = props => {

  const [confirmOptions, setConfirmOptions] = useState(null);

  const closeConfirm = useCallback(
    () => setConfirmOptions(null),
    [setConfirmOptions],
  );

  const confirm = useCallback(
    options =>
      new Promise(
        (resolve, reject) => {
          setConfirmOptions({
            ...options,
            onConfirm: () => {
              resolve();
              closeConfirm();
            },
            onCancel: () => {
              reject('Confirmation cancelled');
              closeConfirm();
            },
          });
        },
    ),
    [setConfirmOptions, closeConfirm],
  );

  const {
    confirmModalTitle,
    confirmText,
    confirmBtnTitle,
    cancelBtnTitle,
    onConfirm,
    onCancel,
    confirmDialogInnerProps,
  } = confirmOptions || {};

  return (
    <AppConfirmContext.Provider value={confirm} >
      {props.children}
      <AppConfirmDialog
          isConfirmModalOpen={!!confirmOptions}
          confirmModalTitle={confirmModalTitle}
          confirmText={confirmText}
          confirmBtnTitle={confirmBtnTitle}
          cancelBtnTitle={cancelBtnTitle}
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmDialogInnerProps={confirmDialogInnerProps}
      />
    </AppConfirmContext.Provider>
  );
};

AppConfirmProvider.propTypes = {
  children: PropTypes.node,
};
