import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { DETAILED_DATA_TO_CONSUME_TYPE } from '../../../StorageManagementApp/SheetsWaitingPartsAndMaterials/PartsAndMaterialsConsumingContent/DefaultSheetPartsAndMaterialsConsumingContent';

import { SheetOperationsTable }
  from '../../SheetOperationsTable/SheetOperationsTable';

import {
  SHEET_SUMMARY_TYPE,
  FUNC_IS_REQUIRED_TYPE,
  SHEET_OPERATION_STATUS_TYPE,
} from '../../../../constants/propTypes';

import { SheetToFinishDialog } from '../SheetToFinishDialog/SheetToFinishDialog';
import { SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ } from '../../SheetOperationsTable/SheetOperationsTable.types';
import { sheetOperationStatusRowStyle } from '../../../../tableProperties/rowStyles/sheetOperationRowStyles';
import { SheetOperationReviewDialogContainer } from '../../../common/SheetOperationReviewDialog/SheetOperationReviewDialogContainer';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';

import './style.css';
import { ENTITY_BATCH_PROVIDING_STATE, SHEET_OPERATION_STATUS } from '../../../../constants/sheets';
import { FinishSheetLabelTrans } from '../../../../utils/commonTransComponents';


export class SheetInProductionReviewContent extends Component {

  static propTypes = {
    sheetOperationsTableId: PropTypes.string.isRequired,
    sheetOperationsTableData: PropTypes.arrayOf(
      PropTypes.shape({
        ...SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ,
        status: SHEET_OPERATION_STATUS_TYPE.isRequired,
        progress: PropTypes.number.isRequired,
        isLastSheetOperation: PropTypes.bool.isRequired,
        isPreviousSheetOperationFinished: PropTypes.bool.isRequired,
        isLastAssemblySheetOperation: PropTypes.bool.isRequired,
      }),
    ),
    sheetToReviewData: SHEET_SUMMARY_TYPE,
    fetchSheetOperations: FUNC_IS_REQUIRED_TYPE,
    finishSheet: FUNC_IS_REQUIRED_TYPE,
    handleSheetOperationStatusChanged: FUNC_IS_REQUIRED_TYPE,
    handleSheetFinished: FUNC_IS_REQUIRED_TYPE,
    handleSheetOperationDataChanged: FUNC_IS_REQUIRED_TYPE,
    handleSheetPaused: FUNC_IS_REQUIRED_TYPE,
    handleEntityBatchSplit: FUNC_IS_REQUIRED_TYPE,
    handleDefectiveEntitiesMarked: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
    fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet: FUNC_IS_REQUIRED_TYPE,
    provideAndFinishSheet: FUNC_IS_REQUIRED_TYPE,
    detailedDataToConsumeForDefaultSheet: DETAILED_DATA_TO_CONSUME_TYPE,
  };

  static defaultProps = {
    detailedDataToConsumeForDefaultSheet: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      isSheetToFinishDialogOpen: false,
      selectedSheetOperationData: null,
    };
  };

  _renderFinishSheetButton = () =>
    <div className="sheet-in-production-review-content__finish-button">
      <Button
          color="secondary"
          variant="contained"
          onClick={this._onFinishSheetButtonClick}
      >
        {FinishSheetLabelTrans}
      </Button>
    </div>;

  _onFinishSheetButtonClick = () => {
    const {
      fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
      PermissionsManager,
      sheetToReviewData: {
        entityBatchId,
        sheetId,
        providingState,
        isAssembly,
      },
    } = this.props;

    if(
      PermissionsManager.isGranted(PERMISSION.CONSUME_ENTITIES_FOR_DEFAULT_SHEET) &&
      !isAssembly &&
      providingState !== ENTITY_BATCH_PROVIDING_STATE.PROVIDED
    ) {
      return fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet(entityBatchId, sheetId)
        .then(() => this._openSheetToFinishDialog());
    }

    return this._openSheetToFinishDialog();
  }

  _openSheetToFinishDialog = () => this.setState({ isSheetToFinishDialogOpen: true });

  _renderSheetOperationsTable = () => {
    const {
      sheetOperationsTableId,
      sheetOperationsTableData,
      fetchSheetOperations,
    } = this.props;

    return(
      <SheetOperationsTable
          sheetOperationsTableId={sheetOperationsTableId}
          sheetOperationsTableData={sheetOperationsTableData}
          fetchSheetOperations={fetchSheetOperations}
          onSheetOperationRowClick={this._handleSheetOperationRowClick}
          getSheetOperationsTableRowStyle={sheetOperationStatusRowStyle}
      />
    );
  };

  _handleSheetOperationRowClick = (_, sheetOperationData) => {

    const {
      sheetToReviewData: {
        entityIdentity,
        entityCode,
        entityName,
        sheetIdentity,
        entityBatchId,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },
    } = this.props;

    this.setState({
      selectedSheetOperationData: {
        ...sheetOperationData,
        entityIdentity,
        entityCode,
        entityName,
        entityBatchId,
        sheetIdentity,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },
    });
  };

  _renderSheetToFinishDialog = () => {
    const {
      sheetToReviewData,
      finishSheet,
      PermissionsManager,
      provideAndFinishSheet,
      detailedDataToConsumeForDefaultSheet,
    } = this.props;

    const { isSheetToFinishDialogOpen } = this.state;

    return(
      <SheetToFinishDialog
          isOpen={isSheetToFinishDialogOpen}
          onFinishSheet={finishSheet}
          onProvideAndFinishSheet={provideAndFinishSheet}
          closeDialog={this._handleSheetToFinishDialogClose}
          sheetToFinishData={{ ...sheetToReviewData }}
          PermissionsManager={PermissionsManager}
          detailedDataToConsumeForDefaultSheet={detailedDataToConsumeForDefaultSheet}
      />
    );
  };

  _handleSheetToFinishDialogClose = () => this.setState({ isSheetToFinishDialogOpen: false });

  _renderSheetOperationReviewDialog = () => {
    const {
      selectedSheetOperationData,
    } = this.state;

    if(selectedSheetOperationData === null) return;

    const {
      handleSheetFinished,
      handleSheetOperationDataChanged,
      handleSheetPaused,
      handleEntityBatchSplit,
      handleDefectiveEntitiesMarked,
    } = this.props;

    return(
      <SheetOperationReviewDialogContainer
          closeDialog={this._handleSheetOperationReviewDialogClose}
          sheetOperationData={selectedSheetOperationData}
          handleSheetOperationStatusChanged={this._handleSheetOperationStatusChanged}
          handleSheetFinished={handleSheetFinished}
          handleSheetOperationDataChanged={handleSheetOperationDataChanged}
          handleSheetPaused={handleSheetPaused}
          handleEntityBatchSplit={handleEntityBatchSplit}
          handleDefectiveEntitiesMarked={handleDefectiveEntitiesMarked}
          getHelpAlertContent={this._getSheetOperationReviewHelpAlertContent}
          areStatusChangeButtonsHidden={this._isSheetInProductionOperationFinished}
          isPauseSheetAdditionalActionHidden={this._isSheetInProductionOperationFinished}
          isSplitEntityBatchAdditionalActionHidden={this._isSheetInProductionOperationFinished}
          isAssigneesChangeDisabled={this._isSheetInProductionOperationFinished}
          isEquipmentChangeDisabled={this._isSheetInProductionOperationFinished}
          isNoteChangeDisabled={this._isSheetInProductionOperationFinished}
      />
    );
  };

  _handleSheetOperationReviewDialogClose = () => this.setState({ selectedSheetOperationData: null });

  _handleSheetOperationStatusChanged = sheetOperationData => {
    this.props.handleSheetOperationStatusChanged(sheetOperationData);
    this._handleSheetOperationReviewDialogClose();
  };

  _getSheetOperationReviewHelpAlertContent = sheetOperationData => {

    if(!sheetOperationData.isLastSheetOperation) {
      return null;
    }

    /*
    * Если операция последняя, то выводится оповещение о том, что в случае её завершения будет завершен весь
    * маршрутный лист
    * */
    return(
      <Trans id="sheet_in_production@sheet_will_be_finished_after_finish_last_operation">
        Это последняя операция маршрутного листа. При завершении операции маршрутный лист будет переведен в
        статус "Завершенный"
      </Trans>
    );
  };

  _isSheetInProductionOperationFinished = sheetOperationData =>
    sheetOperationData.status === SHEET_OPERATION_STATUS.FINISHED;

  render() {
    return(
      <div className="sheet-in-production-review-content">
        {
          this.props.PermissionsManager.isGranted(PERMISSION.FORCE_SHEET_FINISH) ?
            this._renderFinishSheetButton() :
            null
        }
        {this._renderSheetOperationsTable()}
        {this._renderSheetToFinishDialog()}
        {this._renderSheetOperationReviewDialog()}
      </div>
    );
  }
}