import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';

import './style.css';
import { Trans } from '@lingui/macro';

import cn from 'classnames';
import { SaveLabelTrans } from '../../../../utils/commonTransComponents';
import { TextWithUrls } from '../../TextWithUrls/TextWithUrls';


const SHEET_OPERATION_NOTE_TEXTAREA_ROWS = 3;

export const SheetOperationNoteSection = props => {

  const {
    isEditable,
    note,
    setNote,
  } = props;

  const [localNote, localSetNote] = useState(note);

  /*
  * Как правило, обновление комментарии происходит только из этого компонента, но в случае "рассылки" событий другой
  * пользователь, теоретически, может обновить просматриваемый сейчас комментарий. Сейчас принято, что в этом
  * случае мы обновляем значение комментарии. Хотя, вероятно, это может показаться спорным - например, один пользователь
  * пишет комментарий, в этот момент его изменил кто-то другой и всё, что ввёл пользователь заменилось на другой
  * текст. С другой стороны, нормальное поведение в таком случае сложно спрогнозировать, если не заменять текст, то
  * информации об изменении совсем никакой не будет и пользователь просто повторно обновит комментарий своим текстом,
  * о чём первый пользователь, скорее всего, не узнает, т.к. он уже вышел из модальника просмотра операции.
  * В развитии темы, возможно, тут стоило обновлять значения только для isEditable = false, т.к., как указывалось, выше
  * когда доступно редактирование, то изменение значения может казаться не очень логичным.
  *
  * P.S. Нам не надо на маунт устанавливать note в локальный стейт, т.к. значение будет проиницилазировано в useState
  * выше, но useEffect по-другому не умеет, если только ифить с флагами для первого рендера. Решено, что в этом случае
  * одна лишняя перерисовка одного текстового поля не так уж и страшна
  * */
  useEffect(
    () => localSetNote(note),
    [note],
  );

  return (
    <div className="sheet-operation-note-section">
      {
        isEditable ?
          _renderSheetOperationNoteTextarea(localNote, localSetNote, note, setNote) :
          _renderSheetOperationReadOnlyNote(localNote)
      }
    </div>
  );
};

const _renderSheetOperationNoteTextarea = (localNote, localSetNote, note, setNote) => {

  const localNoteTextFieldValue = _prepareTextFieldValue(localNote);
  const noteTextFieldValue = _prepareTextFieldValue(note);

  const isNoteChanged = localNoteTextFieldValue.trim() !== noteTextFieldValue.trim();

  return(
    <TextField
        id="sheet-operation-note-section__note-text-area-id"
        className={cn(
          'sheet-operation-note-section__note-text-area',
          {
            'sheet-operation-note-section__note-text-area--changed': isNoteChanged,
          },
        )}
        multiline
        rows={SHEET_OPERATION_NOTE_TEXTAREA_ROWS}
        variant={MATERIAL_UI_VARIANT.OUTLINED}
        fullWidth
        value={localNoteTextFieldValue}
        onChange={
          e => {
            const newValue = e.target.value;
            const newLocalNote = newValue === '' ? null : newValue;
            localSetNote(newLocalNote);
          }
        }
        InputProps={{
          endAdornment: (
            <InputAdornment className="sheet-operation-note-section__note-textarea-adornment" position="end">
              <Button
                  className="sheet-operation-note-section__note-submit-button"
                  onClick={() => {
                    const noteToSubmit = localNote === null ?
                      null :
                      localNote.trim();

                    setNote(noteToSubmit);
                  }}
                  color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
                  disabled={!isNoteChanged}
              >
                {SaveLabelTrans}
              </Button>
            </InputAdornment>
          ),
        }}
    />
  );
};

const _prepareTextFieldValue = value => value === null ? '' : value;

const _renderSheetOperationReadOnlyNote = localNote =>
  localNote === null ?
    <Chip
        className="sheet-operation-note-section__read-only-note-is-not-defined-chip"
        label={
          <Trans id="sheet_operation_review@note_is_not_defined">
            Не задан
          </Trans>
        }
    /> :
    <div className="sheet-operation-note-section__read-only-note">
      <TextWithUrls text={localNote} />
    </div>;


SheetOperationNoteSection.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  note: PropTypes.string,
  setNote: FUNC_IS_REQUIRED_TYPE,
};