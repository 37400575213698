import React from 'react';
import PropTypes from 'prop-types';
import { CardWithNavLinksHeader } from '../../common/CardWithNavLinksHeader/CardWithNavLinksHeader';
import { SHEET_TYPE } from '../../../constants/sheets';
import {
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
} from '../../../constants/routes';
import { DefaultSheetsWaitingPartsAndMaterialsContainer } from './DefaultSheetsWaitingPartsAndMaterialsContainer/DefaultSheetsWaitingPartsAndMaterialsContainer';
import { Redirect } from 'react-router-dom';
import { AssemblySheetsWaitingPartsAndMaterials } from './AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import {
  AssemblySheetsLabelTrans,
  DefaultSheetsLabelTrans,
  SheetsWaitingPartsAndMaterialsLabelTrans,
} from '../../../utils/commonTransComponents';

const SHEETS_WAITING_PARTS_AND_MATERIALS_TABS = [
  {
    tabId: SHEET_TYPE.DEFAULT_WAITING_PARTS_AND_MATERIALS,
    tabTitle: DefaultSheetsLabelTrans,
    tabLink: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    tabRouteParams: {
      path: [
        [STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE, ':sheetId'].join('/'),
        STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
      ],
      component: DefaultSheetsWaitingPartsAndMaterialsContainer,
    },
  },
  {
    tabId: SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
    tabTitle: AssemblySheetsLabelTrans,
    tabLink: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    tabRouteParams: {
      path: [
        [STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE, ':sheetId'].join('/'),
        STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
      ],
      component: AssemblySheetsWaitingPartsAndMaterials,
    },
  },
];

export const SheetsWaitingPartsAndMaterials = ({ match: { isExact } }) => {
  if(isExact)
    return <Redirect to={STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/>;

  return (
    <div className="sheets-waiting-parts-and-materials">
      <CardWithNavLinksHeader
          className="sheets-waiting-parts-and-materials__card"
          title={SheetsWaitingPartsAndMaterialsLabelTrans}
          navTabs={SHEETS_WAITING_PARTS_AND_MATERIALS_TABS}
      />
    </div>
  );
};

SheetsWaitingPartsAndMaterials.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
  }).isRequired,
};
