import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SheetsContainer } from '../../../Sheets/SheetsContainer';
import { SheetToStartReviewContentContainer } from '../SheetToStartReviewContent/SheetToStartReviewContentContainer';

import {
  ARRAY_OF_ANY_OBJECTS_TYPE,
  FUNC_IS_REQUIRED_TYPE,
  NUMBER_OR_STRING_TYPE,
  SHEET_TO_START_SUMMARY_TYPE,
} from '../../../../constants/propTypes';

import {
  DEFAULT_DATE_INTERVAL_START,
  DEFAULT_DATE_INTERVAL_STOP,
  SHEET_TYPE,
} from '../../../../constants/sheets';
import { PLANNER_APP_SHEETS_TO_START_ROUTE } from '../../../../constants/routes';
import {
  AcceptLabelTrans,
  CancelLabelTrans,
  FilterNotSelectedLabelTrans,
  NoDataLabelTrans,
  NoMatchesFoundLabelTrans,
} from '../../../../utils/commonTransComponents';
import moment from 'moment';
import { FORMAT_SHORT_TIME } from '../../../../constants/dateFormats';
import { Trans } from '@lingui/macro';

import './style.css';
import { DateTimeIntervalForm } from '../DateTimeIntervalForm/DateTimeIntervalForm';


const dateIntervalFilterValidator = (intervalStart, intervalStop) =>
  moment(intervalStart).isSameOrAfter(intervalStop) ?
    <Trans id="all_sheets_to_start@date_interval_filters_error">
      Начало интервала должно быть раньше, чем конец интервала
    </Trans> :
    undefined;

export class AllSheetsToStart extends Component {

  static propTypes = {
    fetchSheetsToStart: FUNC_IS_REQUIRED_TYPE,
    currentSheetToStartDataRequestStartDate: PropTypes.string,
    currentSheetToStartDataRequestStopDate: PropTypes.string,
    mainPlanningSessionId: NUMBER_OR_STRING_TYPE,
    isSheetsToStartDataInStore: PropTypes.bool.isRequired,
    sheetsToStartData: PropTypes.arrayOf(SHEET_TO_START_SUMMARY_TYPE),
    filtersSchema: PropTypes.objectOf(
      PropTypes.shape({
        getFilterTitle: PropTypes.func.isRequired,
        getFilterChipContent: PropTypes.func.isRequired,
        filterComponent: PropTypes.elementType.isRequired,
        filterComponentProps: PropTypes.shape({
          autocompleteId: PropTypes.string.isRequired,
          getOptionLabel: PropTypes.func.isRequired,
          placeholder: FilterNotSelectedLabelTrans.isRequired,
          noOptionsMessage: NoMatchesFoundLabelTrans.isRequired,
          loadOptionsActionCreator: PropTypes.func,
          options: ARRAY_OF_ANY_OBJECTS_TYPE,
        }).isRequired,
      }),
    ),
  };

  _renderDateIntervalFilters = () => {
    const {
      mainPlanningSessionId,
      currentSheetToStartDataRequestStartDate,
      currentSheetToStartDataRequestStopDate,
      fetchSheetsToStart,
      isSheetsToStartDataInStore,
    } = this.props;

    if(!mainPlanningSessionId)
      return (
        <div className="all-sheets-to-start__no-sheets-to-start-label">
          <Trans id="all_sheets_to_start@no_sheets_to_start">
            В данный момент нет ни одного маршрутного листа на запуск
          </Trans>
        </div>
      );

    const intervalStart = currentSheetToStartDataRequestStartDate === null ?
      DEFAULT_DATE_INTERVAL_START :
      currentSheetToStartDataRequestStartDate;

    const intervalStop = currentSheetToStartDataRequestStopDate === null ?
      DEFAULT_DATE_INTERVAL_STOP :
      currentSheetToStartDataRequestStopDate;

    return (
      <div className="all-sheets-to-start__date-time-interval-filters">
        <DateTimeIntervalForm
            format={FORMAT_SHORT_TIME}
            intervalStartLabel={
              <Trans id="all_sheets_to_start@date_interval_filters_start_label">
                Начало интервала
              </Trans>
            }
            intervalStopLabel={
              <Trans id="all_sheets_to_start@date_interval_filters_stop_label">
                Конец интервала
              </Trans>
            }
            submitBtnLabel={AcceptLabelTrans}
            cancelChangesBtnLabel={CancelLabelTrans}
            intervalStartInitValue={intervalStart}
            intervalStopInitValue={intervalStop}
            validator={dateIntervalFilterValidator}
            isSubmitBtnHidden={(intervalStartFromPicker, intervalStopFromPicker) => {
              if(isSheetsToStartDataInStore === false) {
                return false;
              }

              return (
                moment(intervalStartFromPicker).isSame(intervalStart) &&
                moment(intervalStopFromPicker).isSame(intervalStop)
              );
            }}
            onSubmit={(startDate, stopDate) =>
              fetchSheetsToStart(
                moment(startDate).format(),
                moment(stopDate).format(),
              )}
        />
      </div>
    );
  };

  _renderSheets = () => {
    const {
      isSheetsToStartDataInStore,
    } = this.props;

    if(!isSheetsToStartDataInStore) {
      return null;
    }

    const {
      sheetsToStartData,
      filtersSchema,
    } = this.props;

    return (
      <SheetsContainer
          className="all-sheets-to-start__sheets-container"
          mainRoutePath={PLANNER_APP_SHEETS_TO_START_ROUTE}
          sheetsIdentity={SHEET_TYPE.ALL_TO_START}
          sheetItemIdProperty="entityBatchId"
          sheetsData={sheetsToStartData}
          sheetReviewContentComponent={SheetToStartReviewContentContainer}
          noDataText={NoDataLabelTrans}
          filtersSchema={filtersSchema}
      />
    );
  };

  render() {

    return(
      <div className="all-sheets-to-start">
        {this._renderDateIntervalFilters()}
        {this._renderSheets()}
      </div>
    );
  }
}
