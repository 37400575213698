import { connect } from 'react-redux';


import { AppErrorScreen } from './AppErrorScreen';
import { appCriticalErrorSelector } from '../../reducers/appState/selectors';

const mapStateToProps = state => ({
  appCriticalError: appCriticalErrorSelector(state),
});

export const AppErrorScreenContainer = connect(
  mapStateToProps,
)(AppErrorScreen);

AppErrorScreenContainer.displayName = 'AppErrorScreenContainer';