import React from 'react';
import { CheckBoxFiltersPanel } from '../../common/CheckboxFiltersPanel/CheckboxFiltersPanel';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { TASKS_VIEW_SCREEN_TABLE_ADDITIONAL_FILTERS_SCHEMA } from '../../WorkerApp/TasksViewScreen/constants';
import { SwitchControl } from '../../common/SwitchControl/SwitchControl';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import { Collapse } from '@mui/material';


export const WorkerTasksAdminFiltersSettings = props => {
  const {
    onManageWorkerTasksFiltersChange,
    onWorkerTasksFiltersChange,
    tasksFilters,
    isWorkerTasksFiltersManagingByAdmin,
  } = props;

  return (
    <div>
      <SwitchControl
          checked={isWorkerTasksFiltersManagingByAdmin}
          onChange={onManageWorkerTasksFiltersChange}
          label={
            <Trans id="worker_tasks_admin_filters_settings@manage_filters">
              Управлять фильтрами заданий рабочего
            </Trans>
          }
      />
      <Collapse in={isWorkerTasksFiltersManagingByAdmin}>
        <CheckBoxFiltersPanel
            schema={TASKS_VIEW_SCREEN_TABLE_ADDITIONAL_FILTERS_SCHEMA}
            filters={tasksFilters}
            setFilters={onWorkerTasksFiltersChange}
        />
      </Collapse>
    </div>
  );
};

WorkerTasksAdminFiltersSettings.propTypes = {
  onManageWorkerTasksFiltersChange: FUNC_IS_REQUIRED_TYPE,
  onWorkerTasksFiltersChange: FUNC_IS_REQUIRED_TYPE,
  tasksFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  isWorkerTasksFiltersManagingByAdmin: PropTypes.bool.isRequired,
};