import React, { Component } from 'react';

import PropTypes from 'prop-types';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';

import {
  FUNC_IS_REQUIRED_TYPE,
  TABLE_FILTER_PARAMS_TYPE,
  OBJECT_OF_ANY_TYPE,
} from '../../../../../constants/propTypes';

import _isFunction from 'lodash/isFunction';
import _isEmpty from 'lodash/isEmpty';
import { NoDataLabelTrans } from '../../../../../utils/commonTransComponents';

/*
* Этот компонент используется как параметр в фабрике tableFactory. По сути, он представляет собой список, но
* логика работы однотипная, почему-бы не реализовать это через абстракцию
* */

export const entitiesReviewTableComponentPropsAdapter = tableDataLayerProps => {
  const{
    filteredAndSortedRowsDataForCurrentPage,
    noDataContent,
    onRowCombinedClicksHandler,
    filterParams,
  } = tableDataLayerProps;

  return {
    entitiesData: filteredAndSortedRowsDataForCurrentPage,
    noDataText: noDataContent,
    onItemClick: onRowCombinedClicksHandler,
    filterParams,
  };
};

export class EntitiesReviewTableComponent extends Component {

  static propTypes = {
    entityItemIdProperty: PropTypes.string.isRequired,
    renderItemContent: PropTypes.func.isRequired,
    entitiesData: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
    noDataText: PropTypes.node,
    onItemClick: FUNC_IS_REQUIRED_TYPE,
    filterParams: TABLE_FILTER_PARAMS_TYPE,
  };

  static defaultProps = {
    entitiesData: [],
    noDataText: NoDataLabelTrans,
  };

  _renderContent = () =>
    <List>
      {
        this.props.entitiesData
          .map(this._renderListItem)
      }
    </List>;

  _renderListItem = listItemData => {
    const {
      entityItemIdProperty,
      renderItemContent,
    } = this.props;

    /*
    * Такого быть не должно, но на всякий случай перестраховываемся и добавляем проверку
    * */
    if(!_isFunction(renderItemContent)) return null;

    const itemId = listItemData[entityItemIdProperty];

    return(
      <ListItem
          key={itemId}
          className="entities-review-table__list-item"
          button
          divider
          onClick={this._itemClickHandlerFactory(itemId, listItemData)}
      >
        {renderItemContent(listItemData)}
      </ListItem>
    );
  };

  _itemClickHandlerFactory = (itemId, listItemData) =>
    () => this.props.onItemClick(itemId, listItemData);

  _renderNoDataContent = () => {
    const {
      noDataText,
      filterParams,
    } = this.props;

    return (
      <div className="entities-review-table__no-data-content">
        {
          _isEmpty(filterParams) ?
            noDataText :
            NoDataLabelTrans
        }
      </div>
    );
  };

  render() {
    return this.props.entitiesData.length > 0 ?
      this._renderContent() :
      this._renderNoDataContent();
  }
}