import React from 'react';

import {
  Route,
} from 'react-router-dom';

import { AppTypeTopBarContainer } from '../AppTypeTopBar/AppTypeTopBarContainer';

import { MASTER_APP_MAIN_ROUTE, MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM } from '../../constants/routes';
import { MasterWorkspaceContainer } from './MasterWorkspace/MasterWorkspaceContainer';
import { MasterLabelTrans } from '../../utils/commonTransComponents';


export const MasterApp = () =>
  <div className="master-app">
    <AppTypeTopBarContainer
        className="master-app__main-app-bar"
        appTitle={MasterLabelTrans}
    />
    <Route
        path={[
          [MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM, ':viewMode'].join('/'),
          MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM,
          MASTER_APP_MAIN_ROUTE,
        ]}
        component={MasterWorkspaceContainer}
    />
  </div>;
