import { PAUSED_SHEETS_FILTERS_SCHEMA } from '../../Sheets/sheetsFilters/sheetsFilters';
import { fetchPausedSheets } from '../../../operations/sheets';
import { pausedSheetsViewFactory } from '../../pausedSheetsViewFactory/pausedSheetsViewFactory';
import { PLANNER_APP_PAUSED_SHEETS_TABLE_ID } from '../../../constants/table';
import { PLANNER_APP_PAUSED_SHEETS_ROUTE } from '../../../constants/routes';


export const PlannerPausedSheets = pausedSheetsViewFactory({
  tableId: PLANNER_APP_PAUSED_SHEETS_TABLE_ID,
  fetchPausedSheets,
  filtersSchema: PAUSED_SHEETS_FILTERS_SCHEMA,
  getMainRoutePath: () => PLANNER_APP_PAUSED_SHEETS_ROUTE,
});