import React, { useCallback } from 'react';
import { Collapsible } from './Collapsible';
import { useDispatch, useSelector } from 'react-redux';
import { setCollapsibleIsOpen } from '../../../reducers/collapsible/actions';
import PropTypes from 'prop-types';
import { collapsibleByIdSelector } from '../../../reducers/collapsible/selectors';

export const CollapsibleContainer = props => {
  const {
    id,
    initialIsOpen,
    header,
    children,
    className,
  } = props;

  const dispatch = useDispatch();

  const _setCollapsibleIsOpenHandler = useCallback(
    isOpen => {
      dispatch(setCollapsibleIsOpen({ id, isOpen }));
    },
    [dispatch, id],
  );

  const isOpen = useSelector(state => collapsibleByIdSelector(state, { id, initialIsOpen }));

  return (
    <Collapsible
        id={id}
        isOpen={isOpen}
        header={header}
        onChange={_setCollapsibleIsOpenHandler}
        className={className}
    >
      { children }
    </Collapsible>
  );
};


CollapsibleContainer.propTypes = {
  id: PropTypes.string.isRequired,
  initialIsOpen: PropTypes.bool,
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CollapsibleContainer.defaultProps = {
  initialIsOpen: true,
};
