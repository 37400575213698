import React, { Component } from 'react';

import DefaultErrorComponent from './DefaultErrorComponent';
import DefaultLoadingComponent from './DefaultLoadingComponent';

import { DEV } from '../../constants/environment';

import _isEqual from 'lodash/isEqual';

const ASYNC_COMPONENT_STATE = {
  LOADING: 'LOADING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

/**
 * Декоратор для компонентов, для которых перед началом нужно выполнить некоторые асинхронные действия
 * (выполнить запрос, произвести сложные длительные вычисления). Обычно, до выполнения этих асинхронных действий
 * компонент не имеет смысла, т.к. отображает только пустые данные. Эти пустые данные ещё и приходиться обрабатывать, т.к.
 * обычно там null или undefined. Задекорировав такой компонент, по-умолчанию, первая отрисовка начнется только
 * после выполнения всех асинхронных действий, т.е. с учетом, что все нужные данные у компонента уже есть.
 * Кроме поведения по-умолчанию, есть настройки, которые позволяют отрисовывать компонент всегда, определять компоненты,
 * которые будут отрисовываться во время выполнения асинхронных действий или в случае ошибки выполнения асинхронных
 * действий
 *
 * Простой пример использования
 *
 * asyncComponent(
 *   {
 *     resolve: [
 *     {
 *       propsToObserve: ['sessionId'],
 *       fn: props => props.fetchDataForSession(props.sessionId)
 *     }
 *    ]
 *   }
 * )(Component)
 *
 * На didMount будет выполнен fetchDataForSession, если sessionId не null и не undefined
 * На willReceiveProps будет выполнен fetchDataForSession, если sessionId изменился
 *
 * */
export const asyncComponent = ({
  /*
   * Object shape{fn, propsToObserve}. fn будет вызываться на didMount и на willReceiveProps (если изменились пропы).
   * На didmount в них инжектятся props, на componentWillReceiveProps - nextProps
  * */
  resolve = [],

  /*
  * Нужно ли рендерить компонент, пока идут асинхронные действия. В состоянии false избавляет от доп. проверок, т.к.
  * первый раз компонент будет отрисован только после окончания выполнения всех асинхронных действий, т.е., все данные
  * для него уже будут получены
  * */
  shouldRenderAlways = false,

  /*
  * Нужно ли рендерить декорируемый компонент, в случае если асинхронные действия завершились с ошибкой.
  * Иногда отработку ошибок может потребоваться осуществить в самом декорируемом компоненте. Т.е. компонент всё равно
  * отрендериться в первый раз только после выполнения асинхронных действий (в этом отличие от shouldRenderAlways).
  *
  * По-умолчанию, false, и при ошибке рендериться ErrorComponent (описано ниже), если же true, то рендериться сам
  * декорируемый компонент, параметр ErrorComponent игнорируется, даже если определен.
  * */
  shouldRenderOnError = false,

  /*
  * Можно назначить кастомный компонент, который будет отрисовываться пока производятся. Обычно это какой то спиннер
  * */
  loadingComponent: LoadingComponent = DefaultLoadingComponent,

  /*
  * Можно назначить кастомный компонент, который будет отрисовываться в случае, если асинхронные действия закончились
  * с ошибкой, например, данные не загрузились
  * */
  errorComponent: ErrorComponent = DefaultErrorComponent,
}) => DecoratedComponent => {
  class AsyncComponent extends Component {

    state = {
      asyncComponentState: ASYNC_COMPONENT_STATE.LOADING,
    };

    componentDidMount() {
      this._isMounted = true;

      const asyncFunctionsToResolve = this.filterCallbacksWithNullableProps(this.props)
        .map(({ fn }) => fn);

      Promise.all(asyncFunctionsToResolve.map(fn => fn(this.props)))
        .then(() => this.setLoaded(ASYNC_COMPONENT_STATE.COMPLETED))
        .catch(err => {
          this.setLoaded(ASYNC_COMPONENT_STATE.FAILED);

          //eslint-disable-next-line
          process.env.NODE_ENV === DEV && console.error('Произошла ошибка при загрузке асинхронного компонента', err);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const asyncFunctionsToResolve = this.filterCallbacksWithNullableProps(nextProps)
      //фильтруем измененные пропы
        .filter(({ propsToObserve = [] }) =>
          propsToObserve.some(
            propName => !_isEqual(this.props[propName], nextProps[propName]),
          ))
        .map(({ fn }) => fn);
      asyncFunctionsToResolve.forEach(fn => fn(nextProps));
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    filterCallbacksWithNullableProps = props => (
        resolve
        //фильтруем falsy значнеия
          .filter(({ propsToObserve = [] }) =>
            propsToObserve.every(
              propName => props[propName] !== undefined && props[propName] !== null,
            ))
      );

    setLoaded = asyncComponentState => {
      this._isMounted &&
      this.setState({
        asyncComponentState,
      });
    };

    render() {
      const { asyncComponentState } = this.state;
      const {
        LOADING,
        COMPLETED,
        FAILED,
      } = ASYNC_COMPONENT_STATE;
      if(
        shouldRenderAlways ||
        asyncComponentState === COMPLETED ||
        (shouldRenderOnError && asyncComponentState === FAILED)
      ) return <DecoratedComponent {...this.props} />;

      if(asyncComponentState === LOADING) return <LoadingComponent />;

      return <ErrorComponent />;
    }
  }

  return AsyncComponent;
};
