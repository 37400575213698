export const SET_ASSEMBLY_SHEET_RESERVE_DATA = 'SET_ASSEMBLY_SHEET_RESERVE_DATA';
export const DELETE_ASSEMBLY_SHEET_RESERVE_DATA = 'DELETE_ASSEMBLY_SHEET_RESERVE_DATA';
export const DELETE_ALL_ASSEMBLY_SHEETS_RESERVE_DATA = 'DELETE_ALL_ASSEMBLY_SHEETS_RESERVE_DATA';

export const setAssemblySheetReserveData = ({ sheetId, data }) => ({
  type: SET_ASSEMBLY_SHEET_RESERVE_DATA,
  sheetId,
  data,
});

export const deleteAssemblySheetReserveData = ({ sheetId }) => ({
  type: DELETE_ASSEMBLY_SHEET_RESERVE_DATA,
  sheetId,
});

export const deleteAllAssemblySheetsReserveData = (sheetsIdsToOmit = []) => ({
  type: DELETE_ALL_ASSEMBLY_SHEETS_RESERVE_DATA,
  sheetsIdsToOmit,
});