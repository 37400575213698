import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';
import { SimpleConfirmDialog } from '../../../../common/SimpleConfirmDialog/SimpleConfirmDialog';

import { CancelLabelTrans } from '../../../../../utils/commonTransComponents';

import { FUNC_IS_REQUIRED_TYPE } from '../../../../../constants/propTypes';


export class DefaultSheetPartsAndMaterialsConsumeConfirmDialog extends Component {

  static propTypes = {
    closeDialog: FUNC_IS_REQUIRED_TYPE,
    isOpen: PropTypes.bool.isRequired,
    onSubmit: FUNC_IS_REQUIRED_TYPE,
  };

  render() {
    const { closeDialog, isOpen, onSubmit } = this.props;

    return (
      <SimpleConfirmDialog
          className="default-sheet-parts-and-materials-consume-confirm-dialog"
          closeDialog={closeDialog}
          isOpen={isOpen}
          title={
            <Trans id="default_sheet_parts_and_materials_consuming@confirm_dialog_title">
              Подтверждение комплектации МЛ
            </Trans>
          }
          confirmText={
            <Trans id="default_sheet_parts_and_materials_consuming@confirm_dialog_message">
              Вы уверены, что хотите списать детали со склада для комплектации выбранного МЛ? Это действие нельзя отменить
            </Trans>
          }
          confirmBtn={
            <Trans id="default_sheet_parts_and_materials_consuming@confirm_dialog_consume_button">
              Укомплектовать
            </Trans>
          }
          cancelBtn={CancelLabelTrans}
          onConfirm={onSubmit}
      />
    );
  }
}