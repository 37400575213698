import { Link } from '@mui/material';
import { Link as RouterLinkComponent } from 'react-router-dom';
import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import { STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE } from '../../../../constants/routes';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';
import { _getRenderDialogActionsWithTwoConfirmOptionsCallback } from '../../../common/SimpleConfirmDialog/constants';

import { SheetSummary } from '../../SheetSummary/SheetSummary';

import {
  SHEET_SUMMARY_TYPE,
  FUNC_IS_REQUIRED_TYPE,
} from '../../../../constants/propTypes';

import './style.css';
import { SimpleConfirmDialog } from '../../../common/SimpleConfirmDialog/SimpleConfirmDialog';
import { CancelLabelTrans, FinishSheetLabelTrans } from '../../../../utils/commonTransComponents';
import { ENTITY_BATCH_PROVIDING_STATE } from '../../../../constants/sheets';
import { DETAILED_DATA_TO_CONSUME_TYPE } from '../../../StorageManagementApp/SheetsWaitingPartsAndMaterials/PartsAndMaterialsConsumingContent/DefaultSheetPartsAndMaterialsConsumingContent';


const FinishSheetConfirmMessageTrans = (
  <Trans id="sheet_in_production@finish_sheet_confirm_message">
    Вы уверены, что хотите перевести выбранный маршрутный лист в статус "Завершенный"? Все операции
    по маршрутному листу будут помечены, как завершенные, задания по маршрутному листу больше не будут
    отображаться в приложении "Рабочий"
  </Trans>
);

const FinishSheetConfirmButtonTrans = (
  <Trans id="sheet_in_production@finish_sheet_confirm_button">
    Завершить
  </Trans>
);

const FinishUnprovidedDefaultSheetConfirmMessageTrans = (
  <Trans id="sheet_in_production@finish_unprovided_default_sheet_confirm_message">
    При завершении неукомплектованного маршрутного листа запланированная под него комплектация не будет потреблена,
    что может привести к логическим ошибкам в работе системы. Возможно, выбранный маршрутный лист забыли укомплектовать
  </Trans>
);

const ProvideAndFinishDefaultSheetConfirmMessageTrans = (
  <Trans id="sheet_in_production@provide_and_finish_default_sheet_confirm_message">
    В данный момент на складе есть все необходимые ДСЕ для комплектации маршрутного листа, если Вы уверены, что
    выбранный маршрутный лист должен быть завершен, то рекомендуется нажать на кнопку "Укомплектовать и завершить"
  </Trans>
);

const CanNotProvideAndFinishDefaultSheetConfirmMessageTrans = (
  <Trans id="sheet_in_production@can_not_provide_and_finish_default_sheet_confirm_message">
    В данный момент на складе недостаточно ДСЕ для комплектации выбранного маршрутного листа
  </Trans>
);

const FinishUnprovidedDefaultSheetButtonTrans = (
  <Trans id={'sheet_in_production@finish_unprovided_default_sheet_button'}>
    Завершить неукомплектованный МЛ
  </Trans>
);

const ProvideAndFinishDefaultSheetButtonTrans = (
  <Trans id={'sheet_in_production@provide_and_finish_default_sheet_button'}>
    Укомплектовать и завершить
  </Trans>
);

const CheckDefaultSheetProvidingStateConfirmMessageTrans = (
  <Trans id={'sheet_in_production@check_default_sheet_providing_state'}>
    Посмотреть детальную информацию о комплектации маршрутного листа
  </Trans>
);


export const SheetToFinishDialog = props => {
  const {
    closeDialog,
    isOpen,
    onFinishSheet,
    onProvideAndFinishSheet,
    sheetToFinishData,
    PermissionsManager,
    detailedDataToConsumeForDefaultSheet,
  } = props;

  const {
    sheetId,
    isAssembly,
    providingState,
  } = sheetToFinishData;


  const commonSimpleConfirmDialogProps = {
    closeDialog,
    isOpen,
    sheetToFinishData,
  };

  const isDefaultSheetProvidingGranted = PermissionsManager.isGranted(PERMISSION.CONSUME_ENTITIES_FOR_DEFAULT_SHEET);

  const canProvideDefaultSheet = useMemo(
    () => (
      !isAssembly &&
      providingState !== ENTITY_BATCH_PROVIDING_STATE.PROVIDED &&
      isDefaultSheetProvidingGranted &&
      detailedDataToConsumeForDefaultSheet.length !== 0 &&
      detailedDataToConsumeForDefaultSheet.every(({ entityMissingAmount }) => entityMissingAmount === 0)
    ),
    [isAssembly, providingState, isDefaultSheetProvidingGranted, detailedDataToConsumeForDefaultSheet],
  );

  const isStorageManagementAppReviewGranted = PermissionsManager.isGranted(PERMISSION.REVIEW_STORAGE_MANAGEMENT_APP);


  /*
  * Для случая, когда пользователь пытается завершить полностью укомплектованный МЛ, в модальнике завершения выводим
  * стандартное оповещение о том, что все операции МЛ будут завершены, и стандартные кнопки "Завершить"
  * и "Отменить". Это правильный сценарий работы в системе - сначала комплектуем, потом завершаем. (В идеале,
  * завершаться МЛ должен тогда, когда Рабочий завершит последнюю операцию, но здесь рассматривается флоу работы
  * плановика, когда он "наводит порядок" и может форсированно завершать МЛ, чтобы не отмечать все операции
  * последовательно)
  *
  * Тоже самое отображаем и для ситуации, когда пользователь пытается завершить сборочный МЛ. Это пока особый кейс, т.к.
  * по сборочным МЛ принято, что полностью укомплектованными они бывают только после завершения. И, в целом, сборочные
  * МЛ - это МЛ, которые не требуют полной комплектации - пользователь сам решает когда их правильно завершать. С
  * другой стороны, мы могли бы предупредить пользователя, что, возможно, он не всё зарезервировал и потребил согласно
  * спецификации сборочного МЛ, как мы это делаем далее для стандартного МЛ, но текущая точка проверки "всего ли
  * достаточно для МЛ", работает только для стандартных МЛ, т.к. проверяет детали только в модели склада без учета
  * данных о резервировании и частичном потреблении. Поэтому для сборочных МЛ сейчас нет никаких проверок.
  * TODO Возможно, такие проверки добавится в будущем, тогда нужно будет везде скорректировать логику при завершении
  * TODO сборочных МЛ.
  * */
  if(
    providingState === ENTITY_BATCH_PROVIDING_STATE.PROVIDED ||
    isAssembly
  ) {
    return _renderSimpleConfirmDialog({
      ...commonSimpleConfirmDialogProps,
      confirmText: FinishSheetConfirmMessageTrans,
      confirmBtn: FinishSheetConfirmButtonTrans,
      onConfirm: onFinishSheet,
      cancelBtn: CancelLabelTrans,
    });
  }


  /*
  * Если пользователь завершает стандартный неукомплектованный МЛ, то выводим варнинг, что, возможно, это ошибка и
  * завершать правильно только укомплектованные МЛ, также, выводим ссылку перехода на экран комплектации, если есть права
  * на просмотр этого раздела + предлагаем опцию "завершить МЛ неукомплектованным" в виде кнопки вторичного выбора
  * (серая кнопка) и опцию "Укомплектовать и завершить" в виде кнопки первичного выбора, если у пользователя есть
  * разрешение на комплектацию и если данные для комплектации есть на складе (detailedDataToConsumeForDefaultSheet)
  * */
  return _renderSimpleConfirmDialog({
    ...commonSimpleConfirmDialogProps,

    confirmText: _renderUnprovidedDefaultSheetToFinishWarning({
      sheetId,
      isDefaultSheetProvidingGranted,
      canProvideDefaultSheet,
      isStorageManagementAppReviewGranted,
    }),

    confirmTextChipClassName: 'sheet-to-finish-dialog__confirm-text--warning',

    renderDialogActions: _getRenderDialogActionsWithTwoConfirmOptionsCallback({
      cancelBtn: CancelLabelTrans,
      onCancelBtnClick: closeDialog,
      secondaryConfirmBtn: FinishUnprovidedDefaultSheetButtonTrans,
      onSecondaryConfirmBtnClick: onFinishSheet,
      primaryConfirmBtn: canProvideDefaultSheet ? ProvideAndFinishDefaultSheetButtonTrans : null,
      onPrimaryConfirmBtnClick: onProvideAndFinishSheet,
    }),
  });
};

const _renderSimpleConfirmDialog = props => {
  /* eslint-disable react/prop-types */
  const {
    closeDialog,
    isOpen,
    sheetToFinishData,

    confirmText,
    confirmTextChipClassName,
    confirmBtn,
    onConfirm,
    cancelBtn,
    renderDialogActions = null,
  } = props;
  /* eslint-enable react/prop-types */

  return (
    <SimpleConfirmDialog
        className="sheet-to-finish-dialog"
        closeDialog={closeDialog}
        isOpen={isOpen}
        title={FinishSheetLabelTrans}
        confirmText={confirmText}
        confirmTextChipClassName={confirmTextChipClassName}
        additionalComponent={_renderSheetToFinishSummary(sheetToFinishData)}
        confirmBtn={confirmBtn}
        onConfirm={onConfirm}
        cancelBtn={cancelBtn}
        renderDialogActions={renderDialogActions}
    />
  );
};

const _renderUnprovidedDefaultSheetToFinishWarning = props => {
  /* eslint-disable react/prop-types */
  const {
    sheetId,
    isDefaultSheetProvidingGranted,
    canProvideDefaultSheet,
    isStorageManagementAppReviewGranted,
  } = props;
  /* eslint-enable react/prop-types */

  return(
    <React.Fragment>
      {FinishUnprovidedDefaultSheetConfirmMessageTrans}

      {_renderUnprovidedDefaultSheetProvidingAdditionalInfo(isDefaultSheetProvidingGranted, canProvideDefaultSheet)}

      {_renderUnprovidedDefaultSheetStorageManagementAppLink(isStorageManagementAppReviewGranted, sheetId)}
    </React.Fragment>
  );
};

const _renderUnprovidedDefaultSheetProvidingAdditionalInfo = (isDefaultSheetProvidingGranted, canProvideDefaultSheet) => {
  if(!isDefaultSheetProvidingGranted) {
    return null;
  }

  return(
    <div className="sheet-to-finish-dialog__unprovided-default-sheet-providing-additional-info">
      {
        canProvideDefaultSheet ?
          ProvideAndFinishDefaultSheetConfirmMessageTrans :
          CanNotProvideAndFinishDefaultSheetConfirmMessageTrans
      }
    </div>
  );
};

const _renderUnprovidedDefaultSheetStorageManagementAppLink = (isStorageManagementAppReviewGranted, sheetId) => {
  if(!isStorageManagementAppReviewGranted) {
    return null;
  }

  return (
    <div className="sheet-to-finish-dialog__unprovided-default-sheet-storage-management-app-link">
      <Link
          component={RouterLinkComponent}
          to={[STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE, sheetId].join('/')}
          underline="hover"
      >
        {CheckDefaultSheetProvidingStateConfirmMessageTrans}
      </Link>
    </div>
  );

};

const _renderSheetToFinishSummary = sheetToFinishData =>
  <SheetSummary
      className="sheet-to-finish-dialog__summary"
      withEntityLabel
      {...sheetToFinishData}
  />;

SheetToFinishDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: FUNC_IS_REQUIRED_TYPE,
  onFinishSheet: FUNC_IS_REQUIRED_TYPE,
  onProvideAndFinishSheet: FUNC_IS_REQUIRED_TYPE,
  sheetToFinishData: SHEET_SUMMARY_TYPE.isRequired,
  PermissionsManager: PERMISSIONS_MANAGER_TYPE.isRequired,
  detailedDataToConsumeForDefaultSheet: DETAILED_DATA_TO_CONSUME_TYPE,
};
