import {
  ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID, DISABLED_SHEET_OPERATION_FEATURES_TABLE_ID,
  fetchSheetOperationFeaturesTableData, reFetchSheetOperationFeaturesTableData,
  SHEET_OPERATION_FEATURES_TABLE_MENU_OPTIONS,
} from './constants';
import { SHEET_OPERATION_FEATURES_MODEL } from '../../../constants/models';
import { TOOLS_COLUMN_NAME } from '../../../constants/table';
import { Table } from '../../Table/Table';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeactivateLabelTrans,
  DeleteLabelTrans,
  EditLabelTrans,
  NoDataLabelTrans,
} from '../../../utils/commonTransComponents';
import { sheetOperationFeaturesRemoteTableDataSelector } from '../../../selectors/sheetOperationFeatures';
import { push } from 'connected-react-router';
import { ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE } from '../../../constants/routes';
import {
  deleteSheetOperationFeature,
  toggleSheetOperationFeatureActiveStateRequest,
} from '../../../operations/sheetOperationFeature';
import { clearTableRemoteData } from '../../../reducers/table/actions';
import { Trans } from '@lingui/macro';
import { MATERIAL_UI_DIALOG_MAX_WIDTH } from '../../../constants/materialUI';
import { useConfirm } from '../../AppConfirm/AppConfirmContext';
import {
  ACTIVE_SHEET_OPERATION_FEATURES_TABLE_CUSTOM_FILTERS,
} from './sheetOperationFeaturesTableCustomFilters/sheetOperationFeaturesTableCustomFilters';

export const ActiveSheetOperationFeaturesTable = () => {
  const dispatch = useDispatch();

  const confirm = useConfirm();

  const rowsData = useSelector(
    state => sheetOperationFeaturesRemoteTableDataSelector(state, { tableId: ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID }),
  );

  const openSheetOperationFeatureEditingScreen = useCallback(
    (_, { id }) => dispatch(push(`${ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE}/${id}`)),
    [dispatch],
  );

  const deactivateSheetOperationFeature = useCallback(
  (_, { id, name }) => {
    confirm({
      confirmText: (
        <Trans id="sheet_operation_settings.sheet_operation_features@deactivate_feature_confirm">
          После деактивации характеристика будет недоступна для заполнения на экране просмотра операции МЛ. Вы уверены,
          что хотите деактивировать характеристику "{name}"?
        </Trans>
      ),
      confirmDialogInnerProps: {
        dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
      },
    })
      .then(() => dispatch(toggleSheetOperationFeatureActiveStateRequest(id, name, true))
        .then(() => {
          dispatch(clearTableRemoteData([DISABLED_SHEET_OPERATION_FEATURES_TABLE_ID]));
          return dispatch(reFetchSheetOperationFeaturesTableData(ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID));
        }));
  },
    [dispatch, confirm],
);

  const deleteSheetOperationFeatureClickHandler = useCallback(
    (_, { id, name }) => confirm({
      confirmText: (
        <Trans id="sheet_operation_settings.sheet_operation_features@delete_feature_confirm">
          Удалённую характеристику нельзя восстановить. Вы уверены, что хотите удалить характеристику "{name}"?
        </Trans>
      ),
      confirmDialogInnerProps: {
        dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
      },
    })
      .then(() => dispatch(deleteSheetOperationFeature(id, true))
        .then(() => dispatch(reFetchSheetOperationFeaturesTableData(ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID)))),
    [dispatch, confirm],
  );

  const customCellRenderersProps = useMemo(() => ({
    [TOOLS_COLUMN_NAME]: {
      toolsOptions: [
        {
          id: 'edit-option',
          title: EditLabelTrans,
          onClick: openSheetOperationFeatureEditingScreen,
        },
        {
          id: 'deactivate-option',
          title: DeactivateLabelTrans,
          onClick: deactivateSheetOperationFeature,
        },
        {
          id: 'delete-option',
          title: DeleteLabelTrans,
          onClick: deleteSheetOperationFeatureClickHandler,
        },
      ],
    },
  }), [
    openSheetOperationFeatureEditingScreen,
    deactivateSheetOperationFeature,
    deleteSheetOperationFeatureClickHandler,
  ]);

  const fetchTableData = useCallback(
    ({ tableParams }) => dispatch(fetchSheetOperationFeaturesTableData(tableParams)),
    [dispatch],
  );

  return (
    <div className="sheet-operation-features__table-wrapper">
      <Table
          tableId={ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID}
          tableModel={SHEET_OPERATION_FEATURES_MODEL}
          fetchRemoteTableData={fetchTableData}
          rowsData={rowsData}
          customCellRenderersProps={customCellRenderersProps}
          tableMenu={SHEET_OPERATION_FEATURES_TABLE_MENU_OPTIONS}
          noDataContent={NoDataLabelTrans}
          onRowClick={openSheetOperationFeatureEditingScreen}
          customFilters={ACTIVE_SHEET_OPERATION_FEATURES_TABLE_CUSTOM_FILTERS}
      />
    </div>
  );
};