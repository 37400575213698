import React from 'react';
import {
  EQUIPMENT_MODEL,
  SHEET_OPERATION_AGGREGATED_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  SHEET_OPERATION_FEATURE_VALUE_MODEL,
  SHEET_OPERATION_MODEL,
  SHEET_OPERATION_TRANSACTION_MODEL,
  USER_MODEL,
} from '../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../api/restCollectionApi/index';
import {
  fetchEntitiesFromServer,
  fetchRemoteTableEntities,
  saveEntitiesOnServer,
} from '../../reducers/entities/actions';
import { SHEET_OPERATIONS_DEFAULT_REQUEST_OPTIONS } from '../../constants/sheets';
import _get from 'lodash/get';
import { fetchSheetOperationsAggregatedData } from '../tasks';
import { fetchDataFromServerDataPoint, sendActionToServer } from '../../api/index';
import { SERVER_ACTION_POINT } from '../../constants/serverActions';
import { getErrorMessage, showError } from '../../api/requestHandlers/errorHandlers/errorHandlers';
import {
  SET_SHEET_OPERATION_ASSIGNEES_ERRORS,
  SET_SHEET_OPERATION_EQUIPMENT_ERRORS,
} from '../../api/requestHandlers/errorHandlers/errorMaps';
import { broadcastEventMessage } from '../../api/socketApi/broadcastApi/broadcast/broadcastEventMessage';
import {
  SHEET_OPERATION_DATA_CHANGED_EVENT_TYPE,
  SHEET_OPERATION_FEATURES_VALUES_CHANGED_EVENT_TYPE,
} from '../../constants/sockets';
import {
  setSheetOperationFeaturesValues,
} from '../../reducers/sheetOperationReview/actions';
import { SERVER_DATA_POINT } from '../../constants/serverDataPoints';
import _keyBy from 'lodash/keyBy';
import { NOTIFICATION_LEVEL, sendNotification } from '../../constants/notification';
import { Trans } from '@lingui/macro';


export const SHEET_OPERATION_STATUS_CHANGED_ACTION_TYPE = 'SHEET_OPERATION_STATUS_CHANGED_ACTION_TYPE';

export const sheetOperationStatusChanged = sheetOperationDataAfterStatusChange => ({
  type: SHEET_OPERATION_STATUS_CHANGED_ACTION_TYPE,
  sheetOperationDataAfterStatusChange,
});

/*
* Т.к. однотипных серверных таблиц просмотра операций МЛ (или заданий) стало уже достаточного много, то решено
* создать общий экшн криетор запросов табличных данных.
*
* Параметры:
*  - mainFilters - Как правило просто таблиц всех операций всех МЛ не бывает, поэтому всегда есть какая-то основная
* фильтрация, например, таблица операции какого-то одного МЛ, или таблица операций, выполняемых в каком-то подразделении,
* и т.д.. Эти фильтры будут группироваться с фильтрацией самой таблицы (из tableParams), которые может задать
* пользователь в приложении
*  - mainSortParams - Как правило, для таблиц с операций МЛ табличная сортировка дизейблиться и указывается
* фиксированная основная сортировка, т.к. операции в основном располагаются по какому-то фиксированному порядку -
* номер операции или стартовая дата или какой-то приоритет. Управлять тем, что табличная сортировка дизейблиться мы,
* понятно, здесь не можем, поэтому эти параметры для общего случая группируются с табличной сортировкой (из tableParams),
* на случай если она задана (т.е. не задизеблена и пользователь сортирует таблицу в интерфейсе). При этом, принято, что
* сначала применяется сортировка из таблицы, которую указал пользователь, а только потом по mainSortParams, иначе,
* с точки зрения UX для пользователя будет очень неожиданно, что он меняет сортировку, а она выполняется, как то
* неочевидно после основной сортировки, которую он не видит. Обратный вариант, тоже, конечно, не очень логичный, но
* это реализовано больше "на всякий случай", задавать mainSortParams и не дизейблить табличную сортировку, в общем
* случае, само по себе странно.
*  - withModels - Связанные модели для запроса операций
*  - withAssigneesData - Параметр, указывающий, нужно ли дозапрашивать данные по исполнителя для запрашиваемых операций
* МЛ. МЫ НЕ МОЖЕМ ВКЛЮЧАТЬ модель связи исполнителей с операцией в withModels, т.к. в этом случае записей этой модели
* связей может стать больше, чем самих операци и параметр серверной таблицы о количество рядов станет распространяться
* на неё, а мы строим таблицу по операциям а не по их связям с операцией. Поэтому в данный момент это выполняется
* отдельным дополнительным запросом после запроса самих операций на текущей страницы и получения идентификаторов
* операций для этого дополнительного запроса. Возможно, это изменится с доработками по АПИ коллекций, через которое
* мы запрашиваем данные. Если данные по испольнителям дозапрашиваются, то они укладываются дополнительно в
* соответствующие ключи моделей только в itemsById, т.к. последовательность айдишников и количество их записей для
* этих моделей не важны.
*  - withAggregatedData - Параметр, указывающий, нужно ли дозапрашивать агрегированные данные по запрашиваемым
* операциям МЛ. Для этого есть специальная DATA-точка, поэтому нужен дополнительный запрос по айдишникам операций Мл
* на текущей странице, если эти данные требуются. Если агрегированные данные дозапрашиваются, то они укладываются
* дополнительно в специальный ключ служебной модели SHEET_OPERATION_AGGREGATED_MODEL в itemsById, каких-то других
* данных в этом запросе нет, да они и не нужны.
*  - requestOptions - Дополнительные параметры запросы абстракции fetchEntitiesFromServer, подмерживаются с
* дефолтными SHEET_OPERATIONS_DEFAULT_REQUEST_OPTIONS, в которым описаны отношения для всех возможных связанных
* моделей, которые могкут указываться для запроса операци МЛ в withModels
*  - tableParams - Стандартные параметры таблицы из табличной абстракии
* */
export const fetchSheetsOperationsRemoteTableEntities = ({
  mainFilters,
  mainSortParams,
  withModels,
  withAssigneesData = false,
  withAggregatedData = false,
  requestOptions = {},
  tableParams,
}) =>
  dispatch => {

    const {
      activePage,
      pageSize,
      filterParams,
      sortParams,
    } = tableParams;

    const query = {
      filter: _getSheetOperationRequestResultQueryFilter(mainFilters, filterParams),
      with: withModels,
      sortBy: _getSheetOperationRequestResultQuerySortBy(mainSortParams, sortParams),
      limit: pageSize,
      page: activePage,
    };

    return dispatch(fetchEntitiesFromServer(
      SHEET_OPERATION_MODEL,
      query,
      {
        ...SHEET_OPERATIONS_DEFAULT_REQUEST_OPTIONS,
        ...requestOptions,
      },
    ))
      .then(sheetOperationResponse => {

        if (!sheetOperationResponse || sheetOperationResponse.responseMeta.count === 0)
          return {
            itemsIds: {},
            itemsById: {},
            totalItemsAmount: 0,
          };

        if (!withAssigneesData && !withAggregatedData) {

          const { responseEntitiesIds = {}, entities = {}, responseMeta } = sheetOperationResponse;

          return {
            itemsIds: responseEntitiesIds,
            itemsById: entities,
            totalItemsAmount: _get(responseMeta, 'count', 0),
          };
        }

        const {
          responseEntitiesIds: {
            [SHEET_OPERATION_MODEL]: sheetOperationsIds = [],
          },
          entities: {
            [SHEET_OPERATION_MODEL]: sheetOperationModelEntities = {},
          },
        } = sheetOperationResponse;

        /*
        * TODO сейчас из-за невозможности получить модель equipment связанную с моделью entityRouteSheetOperation,
        *  добавлен отдельный запрос данных по оборудованию.
        *  Когда проблема (https://jira.bfg-soft.ru/browse/IA-3395) будет решена, можно будет удалить этот запрос и
        *  добавить equipment (с нестрогой стыковкой) в константу связанных моделей и в modelRelations.
        * */
        const equipmentIdsToRequest = Object
          .values(sheetOperationModelEntities)
          .filter(({ equipmentId }) => !!equipmentId)
          .map(({ equipmentId }) => equipmentId);

        const equipmentForSheetOperationsRequestPromise = equipmentIdsToRequest.length === 0 ?
          Promise.resolve({}) :
          dispatch(fetchEntitiesFromServer(
            EQUIPMENT_MODEL,
            {
              filter: {
                filterGroupType: FILTER_GROUP_TYPES.AND,
                filters: [
                  {
                    column: 'id',
                    filterType: FILTER_TYPES.ONE_OF,
                    filterValue: equipmentIdsToRequest,
                  },
                ],
              },
            },
          ));

        const assigneesForSheetOperationsRequestPromise = withAssigneesData && sheetOperationsIds.length > 0 ?
          dispatch(fetchEntitiesFromServer(
            SHEET_OPERATION_ASSIGNEE_MODEL,
            {
              with: [USER_MODEL],
              filter: {
                filterGroupType: FILTER_GROUP_TYPES.AND,
                filters: [
                  {
                    column: 'entityRouteSheetOperationId',
                    filterType: FILTER_TYPES.ONE_OF,
                    filterValue: sheetOperationsIds,
                  },
                ],
              },
            },
          )) :
          Promise.resolve({});

        const sheetOperationAggregatedDataRequestPromise = withAggregatedData && sheetOperationsIds.length > 0 ?
          dispatch(fetchSheetOperationsAggregatedData(sheetOperationsIds)) :
          Promise.resolve({});

        return Promise.all([
          assigneesForSheetOperationsRequestPromise,
          equipmentForSheetOperationsRequestPromise,
          sheetOperationAggregatedDataRequestPromise,
        ])
          .then(([
            assigneesForSheetOperationsResponse,
            equipmentForSheetOperationsResponse,
            sheetOperationsAggregatedData,
          ]) => {

            const {
              responseEntitiesIds: sheetOperationResponseEntitiesIds,
              entities: sheetOperationResponseEntities,
              responseMeta: sheetOperationResponseMeta,
            } = sheetOperationResponse;

            const {
              entities: equipmentForSheetOperationsDataResponseEntities = {},
            } = equipmentForSheetOperationsResponse;

            const {
              entities: assigneesForSheetOperationsDataResponseEntities = {},
            } = assigneesForSheetOperationsResponse;

            return {
              itemsIds: sheetOperationResponseEntitiesIds,
              itemsById: {
                ...sheetOperationResponseEntities,
                [SHEET_OPERATION_AGGREGATED_MODEL]: sheetOperationsAggregatedData,
                ...equipmentForSheetOperationsDataResponseEntities,
                ...assigneesForSheetOperationsDataResponseEntities,
              },
              totalItemsAmount: _get(sheetOperationResponseMeta, 'count', 0),
            };
          });
      });
  };

/*
* Не обрабатываем как-то отдельно случай, что не заданы основные фильтры mainFilters, предполагается, что для запроса
* операций они есть всегда, т.к. незачем иметь серверную таблицу, где по умолчанию отображаются все возможные операции
* всех МЛ
* */
const _getSheetOperationRequestResultQueryFilter = (mainFilters, filtersFromTableParams = {}) => {

  const tableParamsFiltersForRequest = Object
    .keys(filtersFromTableParams)
    .map(column => {
      const {
        filterValue,
        filterType,
      } = filtersFromTableParams[column];

      return {
        column,
        filterType,
        filterValue,
      };
    });

  return {
    filterGroupType: FILTER_GROUP_TYPES.AND,
    filters: [
      ...mainFilters,
      ...tableParamsFiltersForRequest,
    ],
  };
};


/*
* В случае с сортировкой, в отличии от фильтрации, не очень понятно, как именно нужно комбинировать основные параметры
* сортировки и параметры сортировки таблицы. Как правило, сортировка таблиц операций бывает запрещена, потому что
* нужна какая-то строгая сортировка, заданная в mainSortParams. Если же она не запрещена, то при изменении сортировки
* в таблице, учитывая, что есть основная сортировка, непонятно в каком порядке применять сортировку -
* если сначала применить основную сортировку, а потом табличную, то пользователь не будет понимать, почему он выбрал
* сортировку, а она не сработала; если же наоборот, как будто-бы, особого смысла в основной сортировке тогда не много,
* но это будет более логично с точки зрения пользователя, поэтому сначала применяем табличную сортировку.
* Как правило, скорее всего будет указываться что-то одно, т.к. вместе это не очень логично, хотя допустимо.
* Также, нужно обработать случай, когда не заданы mainSortParams и не задана сортировка в таблице, что возможно,
* в этом случае параметр сортировки в запросе должен быть равено undefined.
*
* */
const _getSheetOperationRequestResultQuerySortBy = (mainSortParams = [], sortParamsFromTableParams = []) => {

  if (mainSortParams.length === 0 && sortParamsFromTableParams.length === 0) {
    return undefined;
  }

  return [
    ...sortParamsFromTableParams
      .map(({ column, asc }) => ({
        column,
        params: [{ key: 'asc', value: asc }],
      })),
    ...mainSortParams,
  ];
};


const SHEET_OPERATION_TRANSACTION_REQUEST_WITH_PARAMS = [USER_MODEL];
const SHEET_OPERATION_TRANSACTION_REQUEST_MODEL_RELATIONS = {
  [USER_MODEL]: {
    level: 1,
  },
};

export const fetchSheetOperationTransactions = (sheetOperationId, tableParams) =>
  dispatch => {
    const {
      activePage,
      pageSize,
    } = tableParams;

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'entityRouteSheetOperationId',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: sheetOperationId,
          },
        ],
      },
      with: SHEET_OPERATION_TRANSACTION_REQUEST_WITH_PARAMS,
      sortBy: [
        {
          column: 'startDate',
          params: [{ key: 'asc', value: true }],
        },
      ],
      page: activePage,
      limit: pageSize,
    };

    return dispatch(fetchRemoteTableEntities(
      SHEET_OPERATION_TRANSACTION_MODEL,
      query,
      { modelRelations: SHEET_OPERATION_TRANSACTION_REQUEST_MODEL_RELATIONS },
    ));
  };


const SET_SHEET_OPERATION_ASSIGNEES_ACTION_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const setSheetOperationAssignees = (sheetOperationId, assigneesIdsArray, requestOptions = {}) =>
  dispatch => dispatch(sendActionToServer(
    SERVER_ACTION_POINT.SET_SHEET_OPERATION_ASSIGNEES,
    {
      data: {
        entityRouteSheetOperationId: sheetOperationId,
        userIds: assigneesIdsArray,
      },
    },
    {
      ...SET_SHEET_OPERATION_ASSIGNEES_ACTION_DEFAULT_REQUEST_OPTIONS,
      ...requestOptions,
    },
  ))
    .catch(({ response, status }) => {
      if (!response) return Promise.reject(status);

      const errorMsg = getErrorMessage(response, SET_SHEET_OPERATION_ASSIGNEES_ERRORS);
      showError(errorMsg);
      return Promise.reject({ response, status });
    });

const SET_SHEET_OPERATION_EQUIPMENT_ACTION_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const setSheetOperationEquipment = (sheetOperationId, equipmentId, requestOptions = {}) =>
  dispatch => dispatch(sendActionToServer(
    SERVER_ACTION_POINT.SET_SHEET_OPERATION_EQUIPMENT,
    {
      data: {
        entityRouteSheetOperationId: sheetOperationId,
        equipmentId,
      },
    },
    {
      ...SET_SHEET_OPERATION_EQUIPMENT_ACTION_DEFAULT_REQUEST_OPTIONS,
      ...requestOptions,
    },
  ))
    .catch(({ response, status }) => {
      if (!response) return Promise.reject(status);

      const errorMsg = getErrorMessage(response, SET_SHEET_OPERATION_EQUIPMENT_ERRORS);
      showError(errorMsg);
      return Promise.reject({ response, status });
    });


export const broadcastSheetOperationDataChanged = (sheetId, sheetOperationId, sheetOperationChangedData) =>
  broadcastEventMessage(
    SHEET_OPERATION_DATA_CHANGED_EVENT_TYPE,
    {
      sheetId,
      sheetOperationId,
      sheetOperationChangedData,
    },
  );

export const fetchSheetOperationFeaturesValuesAndAddToStore = sheetOperationId =>
  dispatch => dispatch(fetchDataFromServerDataPoint(
    SERVER_DATA_POINT.SHEET_OPERATION_FEATURES_VALUE,
    {
      id: [sheetOperationId],
    },
  ))
    .then(response => {
      const entitiesArray = _get(response, ['data']);

      if (entitiesArray.length === 0) return {};

      const sheetOperationFeaturesValuesById = _keyBy(entitiesArray, 'sheetOperationFeatureId');

      dispatch(setSheetOperationFeaturesValues(sheetOperationFeaturesValuesById));

      return sheetOperationFeaturesValuesById;
    });

export const saveSheetOperationFeaturesValues = ({
  sheetOperationId,
  sheetOperationFeaturesValuesEntitiesToCreate,
  sheetOperationFeaturesValuesEntitiesToEdit,
}) =>
  async dispatch => {

    const {
      responseEntitiesWithoutErrors: createdSheetOperationFeaturesValuesEntities = [],
    } = sheetOperationFeaturesValuesEntitiesToCreate.length === 0 ?
      {} :
      await dispatch(saveEntitiesOnServer(
        SHEET_OPERATION_FEATURE_VALUE_MODEL,
        sheetOperationFeaturesValuesEntitiesToCreate,
      ));


    const {
      responseEntitiesWithoutErrors: editedSheetOperationFeaturesValuesEntities = [],
    } = sheetOperationFeaturesValuesEntitiesToEdit.length === 0 ?
      {} :
      await dispatch(saveEntitiesOnServer(
        SHEET_OPERATION_FEATURE_VALUE_MODEL,
        sheetOperationFeaturesValuesEntitiesToEdit,
        false,
      ));


    broadcastEventMessage(
      SHEET_OPERATION_FEATURES_VALUES_CHANGED_EVENT_TYPE,
      { sheetOperationId },
    );


    if (
      createdSheetOperationFeaturesValuesEntities.length !== sheetOperationFeaturesValuesEntitiesToCreate.length ||
      editedSheetOperationFeaturesValuesEntities.length !== sheetOperationFeaturesValuesEntitiesToEdit.length
    ) {
      sendNotification(
        <Trans id="sheet_operation_review@not_all_features_values_saved">
          Не все значения доп. характеристик были сохранены, пожалуйста проверьте список значений характеристик и
          отредактируйте его, если потребуется
        </Trans>,
        NOTIFICATION_LEVEL.WARNING,
        { timeOut: 7000 },
      );
    }
  };