import React, { useCallback, useMemo } from 'react';
import {
  ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
  ADMIN_APP_SHEET_OPERATION_FEATURES_ACTIVE_STATE_ROUTE_PARAMS_MAP,
  NEW_USER_SUB_ROUTE,
} from '../../../constants/routes';
import { CardWithNavLinksHeader } from '../../common/CardWithNavLinksHeader/CardWithNavLinksHeader';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Button from '@mui/material/Button';
import {
  CreateNewSheetOperationFeatureFeatureTrans,
  SHEET_OPERATION_FEATURES_NAV_TABS,
  SheetOperationFeaturesTitleTrans,
} from './constants';

import './style.css';
import { reactRouterParamsSelector } from '../../../selectors/reactRouter';

export const SheetOperationFeatures = props => {

  const dispatch = useDispatch();
  const { activeState } = reactRouterParamsSelector(null, props);

  const openSheetOperationFeatureReviewScreen = useCallback(() => dispatch(
    push([
      ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
      NEW_USER_SUB_ROUTE,
    ].join('/')),
  ), [dispatch]);

  const isOnActiveFeaturesPage = useMemo(
    () => activeState === ADMIN_APP_SHEET_OPERATION_FEATURES_ACTIVE_STATE_ROUTE_PARAMS_MAP.ACTIVE,
    [activeState],
  );

  return (
    <CardWithNavLinksHeader
        className="sheet-operation-features"
        title={SheetOperationFeaturesTitleTrans}
        navTabs={SHEET_OPERATION_FEATURES_NAV_TABS}
        actionsContent={isOnActiveFeaturesPage ? _renderActionContent(openSheetOperationFeatureReviewScreen) : null}
    />
  );
};

const _renderActionContent = openSheetOperationFeatureReviewScreen => (
  <Button
      className="sheet-operation-features__create-new"
      onClick={openSheetOperationFeatureReviewScreen}
  >
    {CreateNewSheetOperationFeatureFeatureTrans}
  </Button>
);
