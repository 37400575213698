import { connect } from 'react-redux';
import { sheetsToStartSelectedTypeSelector } from '../../../reducers/plannerApp/sheetsToStart/selectors';
import { setSelectedSheetsToStartType } from '../../../reducers/plannerApp/sheetsToStart/actions';
import { SheetsToStart } from './SheetsToStart';


const mapStateToProps = state => ({
  selectedSheetsToStartType: sheetsToStartSelectedTypeSelector(state),
});

const mapDispatchToProps = {
  setSelectedSheetsToStartType,
};

export const SheetsToStartContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SheetsToStart);

SheetsToStartContainer.displayName = 'SheetsToStartContainer';