import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';
import PropTypes from 'prop-types';

import './style.css';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import Tooltip from '@mui/material/Tooltip';
import { Trans } from '@lingui/macro';
import {
  SHOULD_CHECK_EQUIPMENT_AVAILABILITY,
  IS_EQUIPMENT_FOR_OPERATION_REQUIRED,
  SHEET_OPERATIONS_SETTINGS_CHECKBOX_SCHEMA,
} from './constants';


export const SheetOperationSettings = props => {

  const {
    settings,
    onChange,
  } = props;

  return (
    <Card>
      <CardContent>

        <Typography variant="h4" component="h1">
          <Trans id="sheet_operation_settings@title">
            Настройки оборудования операции маршрутного листа
          </Trans>
        </Typography>

        <FormControl className="sheet-operation-settings__form" component="fieldset">
          <FormGroup>

            {SHEET_OPERATIONS_SETTINGS_CHECKBOX_SCHEMA.map(({ name, label, tooltipText }) => (
              _renderCheckbox({
                onChange,
                settings,
                name,
                label,
                tooltipText,
              })
            ))}

          </FormGroup>
        </FormControl>

      </CardContent>
    </Card>
  );
};

SheetOperationSettings.propTypes = {
  onChange: FUNC_IS_REQUIRED_TYPE,
  settings: PropTypes.shape({
    [IS_EQUIPMENT_FOR_OPERATION_REQUIRED]: PropTypes.bool.isRequired,
    [SHOULD_CHECK_EQUIPMENT_AVAILABILITY]: PropTypes.bool.isRequired,
  }),
};

const _renderCheckbox = params => {
  const {
    name,
    label,
    onChange,
    settings,
    tooltipText,
  } = params;

  return (
    <Tooltip
        placement={'right'}
        title={tooltipText}
        key={name}
        classes={{
          popper: 'sheet-operation-settings__tooltip-popper',
          tooltip: 'sheet-operation-settings__tooltip',
        }}
    >
      <FormControlLabel
          className="sheet-operation-settings__checkbox"
          label={label}
          control={
          <Checkbox
              color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
              checked={settings[name]}
              onChange={onChange}
              name={name}
          />
        }
      />
    </Tooltip>
  );
};