import { SheetsContainer } from '../Sheets/SheetsContainer';
import { getSheetPartsAndMaterialsInfoLabels } from '../../utils/sheets';
import React from 'react';
import cn from 'classnames';

import { PAUSED_SHEETS_TABLES_IDS_ARRAY } from '../../constants/table';
import { pausedSheetReviewContentFactory } from './pausedSheetReviewContentFactory';
import { Trans } from '@lingui/macro';


/*
* Фабрика, с помощью которой создаются компоненты для  просмотра приостановленных машрутных листов.
* Сюда входит серверный список листов + модальное окно просмотра операций выбранного маршрутного листа с возможностью
* просмотра данных по конкретной операции. Окно открывается на роуте "${mainRoutePath}/:sheetId".
*
* В качестве параметров фабрика принимает:
* - идентификатор таблицы (должен быть добавлен в PAUSED_SHEETS_TABLES_IDS_ARRAY),
* - mainRoutePath - роут, на котором располагается интерфейс просмотра списка МЛ (тоже описан в упомянутой выше карте)
* - filtersSchema - схема фильтров для списка МЛ
* - fetchPausedSheets - экшн запроса табличных данных по приостановленным маршрутным листам
* - className
* */
export const pausedSheetsViewFactory = ({
  tableId,
  getMainRoutePath,
  filtersSchema,
  fetchPausedSheets,
  className,
}) => {
  if(!PAUSED_SHEETS_TABLES_IDS_ARRAY.includes(tableId))
    // eslint-disable-next-line no-console
    return console.warn('Для каждого компонента приостановленных маршрутных листов, сгенерированного с ' +
      'использованием фабрики pausedSheetsViewFactory в массив PAUSED_SHEETS_TABLES_IDS_ARRAY должен быть добавлен ' +
      'идентификатор таблицы tableId');

  const pausedSheetReviewContent = pausedSheetReviewContentFactory(tableId);

  return props => (
    <SheetsContainer
        className={cn('paused-sheets', className)}
        mainRoutePath={getMainRoutePath(props)}
        sheetsIdentity={tableId}
        sheetItemIdProperty="sheetId"
        fetchSheetTypeEntitiesActionCreator={fetchPausedSheets}
        sheetReviewContentComponent={pausedSheetReviewContent}
        noDataText={
          <Trans id="paused_sheets@no_paused_sheets">
            В данный момент нет ни одного остановленного маршрутного листа
          </Trans>
        }
        filtersSchema={filtersSchema}
        getSheetItemAdditionalInfoLabels={getSheetPartsAndMaterialsInfoLabels}
        {...props}
    />
  );
};