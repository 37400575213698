import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';

import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';

import cn from 'classnames';

import './style.css';


export class SimpleLabel extends Component {

  static propTypes = {
    className: PropTypes.string,
    content: PropTypes.node.isRequired,
    color: PropTypes.oneOf([
      MATERIAL_UI_STYLE_COLOR.DEFAULT,
      MATERIAL_UI_STYLE_COLOR.PRIMARY,
      MATERIAL_UI_STYLE_COLOR.SECONDARY,
    ]),
  };

  render() {
    const {
      className,
      content,
      color,
    } = this.props;
    return (
      <div
          className={
            cn(
              'simple-label',
              className,
            )
          }
      >
        <Chip
            label={content}
            color={color}
            className="simple-label__chip"
        />
      </div>
    );
  }
}
