import { Transformer } from '../Transformer';


export class EntityBatch extends Transformer {

  static transformToState(item) {
    const {
      id,
      calculationIdentity,
      status,
      providingState,
      entityId,
      orderId,
      quantity,
      fromState,
      calculationSessionId,
      defectState,
    } = item;

    return {
      id,
      calculationIdentity,
      status,
      providingState,
      entityId,
      orderId,
      amount: quantity,
      fromState,
      mainPlanningSessionId: calculationSessionId,
      defectState,
    };
  };

}