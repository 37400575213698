export const SET_APP_USER_DATA = 'SET_APP_USER_DATA';
export const SET_MAIN_PLANNING_SESSION = 'SET_MAIN_PLANNING_SESSION';
export const SET_APP_LANGUAGE = 'SET_APP_LANGUAGE';
export const SET_APP_CRITICAL_ERROR = 'SET_APP_CRITICAL_ERROR';


export const setAppUserData = userData => ({
  type: SET_APP_USER_DATA,
  userData,
});

export const setMainPlanningSession = mainPlanningSession => ({
  type: SET_MAIN_PLANNING_SESSION,
  mainPlanningSession,
});


export const setAppLanguage = language => ({
  type: SET_APP_LANGUAGE,
  language,
});

export const setAppCriticalError = criticalError => ({
  type: SET_APP_CRITICAL_ERROR,
  criticalError,
});