import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import cn from 'classnames';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import './style.css';


export const CheckBoxFiltersPanel = props => {
  const {
    filters,
    setFilters,
    schema,
    className,
    materialUIStyleColor,
  } = props;

  const taskViewScreenAdditionalFiltersSet = new Set(filters);

  return(
    <FormGroup className={cn('checkbox-filters-panel', className)} row>
      {
        schema
          .map(({ filterKey, filterLabel, filterChangeCbFactory }) => {
            const isFilterChecked = taskViewScreenAdditionalFiltersSet.has(filterKey);

            return(
              <FormControlLabel
                  key={filterKey}
                  classes={{
                    root: 'checkbox-filters-panel__checkbox-wrap',
                    label: 'checkbox-filters-panel__checkbox-label',
                  }}
                  control={
                    <Checkbox
                        className="checkbox-filters-panel__checkbox"
                        checked={isFilterChecked}
                        onChange={
                          filterChangeCbFactory(
                            filterKey,
                            isFilterChecked,
                            filters,
                            setFilters,
                          )}
                        name={filterKey}
                        color={materialUIStyleColor}
                    />
                  }
                  label={filterLabel}
              />
            );
          })
      }
    </FormGroup>
  );
};

CheckBoxFiltersPanel.defaultProps = {
  materialUIStyleColor: MATERIAL_UI_STYLE_COLOR.PRIMARY,
};

CheckBoxFiltersPanel.propTypes = {
  materialUIStyleColor: PropTypes.oneOf(Object.values(MATERIAL_UI_STYLE_COLOR)),
  className: PropTypes.string,
  setFilters: FUNC_IS_REQUIRED_TYPE,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      filterKey: PropTypes.string.isRequired,
      filterLabel: PropTypes.node.isRequired,
      filterChangeCbFactory: FUNC_IS_REQUIRED_TYPE,
    }),
  ).isRequired,
};
