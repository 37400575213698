import { NUMBER_OR_STRING_TYPE } from '../../../../constants/propTypes';
import PropTypes from 'prop-types';
import { SheetOperationFeaturesValuesForm } from './SheetOperationFeaturesValuesForm';
import { connect } from 'react-redux';
import { sheetOperationFeaturesValuesSelector } from '../../../../reducers/sheetOperationReview/selectors';
import { asyncComponent } from '../../../../hoc/asyncComponent/asyncComponent';
import { bindActionCreators, compose } from 'redux';
import {
  fetchSheetOperationFeaturesValuesAndAddToStore,
  saveSheetOperationFeaturesValues,
} from '../../../../operations/sheetOperations';
import { LocalSpinner } from '../../../LocalSpinner/LocalSpinner';
import _isEmpty from 'lodash/isEmpty';

const mapStateToProps = state => ({
  sheetOperationFeaturesValues: sheetOperationFeaturesValuesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchSheetOperationFeaturesValuesAndAddToStore,
    saveSheetOperationFeaturesValues,
  }, dispatch),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    sheetOperationFeaturesValues,
  } = stateProps;

  const {
    fetchSheetOperationFeaturesValuesAndAddToStore,
    saveSheetOperationFeaturesValues,
  } = dispatchProps;

  const {
    sheetOperationId,
    isFormDisabled,
    hasEditedSheetOperationFeaturesValues,
  } = ownProps;

  return {
    sheetOperationFeaturesValues,
    fetchSheetOperationFeaturesValuesAndAddToStore,
    sheetOperationId,
    onSubmit: saveSheetOperationFeaturesValues,
    isFormDisabled,
    hasEditedSheetOperationFeaturesValues,
  };
};


export const SheetOperationFeaturesValuesFormContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ sheetOperationFeaturesValues, sheetOperationId, fetchSheetOperationFeaturesValuesAndAddToStore }) => {
          if (!_isEmpty(sheetOperationFeaturesValues)) return Promise.resolve();
          
          return fetchSheetOperationFeaturesValuesAndAddToStore(sheetOperationId);
        },
      },
    ],
    loadingComponent: LocalSpinner,
  }),
)(SheetOperationFeaturesValuesForm);


SheetOperationFeaturesValuesFormContainer.propTypes = {
  sheetOperationId: NUMBER_OR_STRING_TYPE,
  isFormDisabled: PropTypes.bool.isRequired,
};

SheetOperationFeaturesValuesFormContainer.displayName = 'SheetOperationFeaturesValuesFormContainer';