import PropTypes from 'prop-types';
import React from 'react';

import { TableCellRenderer } from '../TableCellRenderer';
import { WithoutOrderLabelTrans } from '../../../../utils/commonTransComponents';


export class OrderNameRenderer extends TableCellRenderer {

  static getViewValue(props) {
    const { value } = props;

    /*
    * Сейчас есть проблема со строковыми константами.
    * Ранее, это были просто строки, а теперь, в условиях локализации, всюду
    * приходится, в основном, оперировать разметкой, которая в зависимости от выбранной
    * локали отрисовывает строковое значение.
    *
    * Из-за таких особенностей здесь невозможно корректно реализовать фильтрацию, поэтому
    * для случая "без заказа" просто возвращается null.
    * По идее, можно в случае null возвращать, например, символ "-", который достаточно
    * явно будет указывать на отсутствие заказа
    * */
    return value;

  };

  render() {
    //const orderName = OrderNameRenderer.getViewValue(this.props);
    const { value } = this.props;

    const orderName = value === null ? WithoutOrderLabelTrans : value;

    return(
      <div title={orderName}>
        {orderName}
      </div>
    );
  }
}

OrderNameRenderer.propTypes = {
  value: PropTypes.string,
  dateFormat: PropTypes.string,
};