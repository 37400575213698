import React, { useContext } from 'react';
import { FullScreenContext } from '../constants';
import { ReloadIconButton } from '../../components/ReloadIconButton/ReloadIconButton';


export const FullScreenReloadIconButton = props => {

  const { isFullScreen } = useContext(FullScreenContext);

  if(!isFullScreen) {
    return null;
  }

  return (
    <ReloadIconButton {...props} />
  );
};