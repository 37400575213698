import PropTypes from 'prop-types';
import React from 'react';
import _isNil from 'lodash/isNil';
import { NoDataLabelTrans } from '../../../utils/commonTransComponents';
import { TableCellRenderer } from './TableCellRenderer';

/*
* Фабрика рендереров для ячеек таблиц, которые должны отображать некоторый служебный лейбл в случаях, когда value равно
* null или undefined
* */
export const nilValueLabelTableCellRendererFactory = Renderer => {
  class NilValueLabelTableCellRenderer extends Renderer {
    static propTypes = {
      // eslint-disable-next-line react/forbid-prop-types
      value: PropTypes.any,
      noValueLabel: PropTypes.node,
    };

    static defaultProps = {
      ...Renderer.defaultProps,
      noValueLabel: NoDataLabelTrans,
    };

    static getViewValue(props) {
      const {
        value,
      } = props;

      /*
      * Статическая функция getViewValue должна возвращать строку, т.к. может вызывается вне контекста
      * реакт компонента, здесь не должно быть разметки. С введением в проект локализации через lingui, с этим
      * появилась проблема, т.к. для локализации фраз используется реакт компонент и если отрисовывать в самом
      * рендерере мы его можем, то что возвращать из getViewValue непонятно. До конца непонятно как быть и с самой
      * с фильтрацией текстовых констант, когда есть локализация, т.к. допустим мы пишем фильтр, когда в приложении
      * установлен русский язык и фильтрация фронтовая проходит корректно, потом изменяем язык и фильтр, по сути,
      * становится некорретным, т.к. текстовая константа изменилась (она отображается теперь на другом языке).
      * Эти все вопросы нужно будет обдумать и решить отдельно. Пока здесь в случае, когда должна выводится строковая
      * константа для null значения (т.е. noValueLabel, но не в виде реакт компонета, а в виде строки), то выводится
      * просто null. Фильтрация фронтовая по таким ячейкам в данный момент работать не будет.
      * */
      if(_isNil(value)) return null;

      return Renderer.getViewValue(props);
    }

    render() {

      return _isNil(this.props.value) ?
        <div>
          {this.props.noValueLabel}
        </div>
        :
        super.render();
    }
  }

  return NilValueLabelTableCellRenderer;
};

export const DefaultNilValueLabelRenderer = nilValueLabelTableCellRendererFactory(TableCellRenderer);