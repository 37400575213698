import React from 'react';
import { modDecimals, mulDecimals } from '../decimal';
import { MINUTES_IN_HOUR, SECS_IN_HOUR, SECS_IN_MINUTE } from '../../constants/magics';
import { HoursLabelTrans, MinutesLabelTrans, SecondsLabelTrans } from '../commonTransComponents';
import _isNil from 'lodash/isNil';



const humanizedTimeStringBuilder = unitsArray => unitsArray
  .map(({ value, label, id }, i) => (
    <React.Fragment key={id}>
      {value}
      {' '}
      {label}
      {/* Добавляем пробел только между единицами измерения */}
      {i === unitsArray.length - 1 ? '' : ' '}
    </React.Fragment>
  ));

const defaultHumanizeTimeFromHoursFactoryParams =  {
  hoursLabel: HoursLabelTrans,
  minutesLabel: MinutesLabelTrans,
  secondsLabel: SecondsLabelTrans,
  stringBuilder: humanizedTimeStringBuilder,
};

/**
 * Результатом выполнения фабрики - функция, которая принимает количество часов в дробном значении (hours) и по
 * умолчанию рендерит строку вида: 1ч. 2мин. 3с. (вид строки зависит от stringBuilder).
 * Если какое-то из значений равно нулю, то оно не рендерится, то есть, если 0 часов, то итоговая строка будет: 2мин. 3с.
 * @param hoursLabel - (строка/разметка) лейбл для обозначения часов
 * @param minutesLabel - (строка/разметка) - лейбл для обозначения минут
 * @param secondsLabel - (строка/разметка) - лейбл для обозначения секунд
 * @param stringBuilder {function} - калбек, который формотирует итоговую строку, на вход принимает массив units.
 */
export const humanizeTimeFromHoursFactory = ({
  hoursLabel,
  minutesLabel,
  secondsLabel,
  stringBuilder,
} = defaultHumanizeTimeFromHoursFactoryParams) =>
  (hours = 0, shouldRenderSeconds = false) => {

    if (_isNil(hours) || hours <= 0) {
      return stringBuilder([{
        value: 0,
        label: minutesLabel,
        id: 'minutes',
      }]);
    }
  
    const units = [
      {
        value: Math.trunc(hours),
        label: hoursLabel,
        id: 'hours',
      },
      {
        value: Math.trunc( modDecimals( mulDecimals(hours, MINUTES_IN_HOUR),  MINUTES_IN_HOUR) ),
        label: minutesLabel,
        id: 'minutes',
      },
    ];

    if (shouldRenderSeconds) {
      units.push({
        value: Math.trunc( modDecimals( mulDecimals(hours, SECS_IN_HOUR), SECS_IN_MINUTE) ),
        label: secondsLabel,
        id: 'seconds',
      });
    }

    const filteredUnits = units.filter(({ value }) => value > 0);

    /*
    Обработка кейса, когда количество часов минут и секунд равно нулю. Может показаться, что это условие дублирует
    проверку на "hours >= 0" в начале функции. Но это не так, потому что компонент по умолчанию не рендерит секунды, а
    в пропс hours может быть передано значение 0.01 и проверка "hours === 0" не сработает, поэтому выполняем проверку
    только после всех преобразований и фильтрации значений. То есть, если задан параметр shoulRenderSeconds = false и
    при этом hours больше 0 и меньше минуты, то возвращаем значение "1мин.".
    */
    if (filteredUnits.length === 0) {
      return stringBuilder([{
        value: 1,
        label: minutesLabel,
        id: 'minutes',
      }]);
    }

    return stringBuilder(filteredUnits);
  };

export const humanizeTimeFromHoursWithoutSeconds = humanizeTimeFromHoursFactory();