import React from 'react';

import PropTypes from 'prop-types';
import {
  MASTER_APP_MAIN_ROUTE,
  MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM, MASTER_APP_PAUSED_SHEETS_SUB_ROUTE,
  MASTER_APP_SHEETS_SUB_ROUTE,
} from '../../../constants/routes';
import { reactRouterIsExactSelector } from '../../../selectors/reactRouter';
import { Redirect } from 'react-router-dom';
import { CardWithNavLinksHeader } from '../../common/CardWithNavLinksHeader/CardWithNavLinksHeader';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';

import './style.css';
import { MasterPausedSheetsContainer } from '../MasterPausedSheets/MasterPausedSheetsContainer';
import { PausedLabelTrans, SheetsLabelTrans } from '../../../utils/commonTransComponents';


const MASTER_SHEETS_TABS_DATA = [
  {
    tabId: 'PAUSED',
    tabTitle: PausedLabelTrans,
    tabSubRoute: MASTER_APP_PAUSED_SHEETS_SUB_ROUTE,
    tabComponent: MasterPausedSheetsContainer,
  },
];


export const MasterSheets = props => {
  const {
    departmentIdsRouteParam,
    departmentIdsFromRoute,
  } = props;

  /*
  * Формируем текущую ссылку (а НЕ путь роутера) основного экрана мастера с установленными параметрами
  * идентификаторов подразделений и параметром подраздела просмотра машрутных листов
  * */
  const currentMasterAppSubRouteUrlWithDepartmentsAndViewModeParam = [
    MASTER_APP_MAIN_ROUTE,
    departmentIdsRouteParam,
    MASTER_APP_SHEETS_SUB_ROUTE,
  ].join('/');

  /*
  * Если мы находимся строго на пути MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM/sheets, то нужно редиректнуться
  * на роут одного из табов для выбранного подраздела. Выбираем первый из табов в MASTER_SHEETS_TABS_DATA.
  * Для редиректа в to нужна именно полная ссылка с реальным параметром а не path роута, поэтому
  * используется currentMasterAppSubRouteUrlWithDepartmentsAndViewModeParam, а не
  * MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM/sheets
  * */
  const isExact = reactRouterIsExactSelector(null, props);

  if(isExact) {
    return  (
      <Redirect
          to={`${currentMasterAppSubRouteUrlWithDepartmentsAndViewModeParam}/${MASTER_SHEETS_TABS_DATA[0].tabSubRoute}`}
      />
    );
  }

  return (
    <CardWithNavLinksHeader
        className="master-sheets__card-with-nav-links"
        title={SheetsLabelTrans}
        navTabs={
        MASTER_SHEETS_TABS_DATA
          .map(({ tabId, tabTitle, tabSubRoute, tabComponent: TabComponent }) => {

            /*
            * Формирование данных для табов нужно указать:
            *  - tabLink - реальную ссылку для таба с реальными текущими параметрами, поэтому
            * при формировании этого параметра используется currentMasterAppSubRouteUrlWithDepartmentsAndViewModeParam
            * - tabRouteParams.path - путь роута для компонента, располагающегося на табе, поэтому при
            * формировании это параметра используется MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM и
            * MASTER_APP_SHEETS_SUB_ROUTE
            *
            * Кроме того, для экранов просмотра содержимого табов справедлива логика, что по основному роуту
            * отображается список/таблица, а по роуту ${основной роут таба}/:sheetId - интерфейс просмотра конкретного
            * элемента списка/таблицы. Чтобы иметь доступ к параметру :sheetId в компоненте таба, назначаем для path
            * (который далее установится в Route у CardWithNavLinkHeader) сразу оба роута в массиве.
            * */
            const tabRoutePath = [
              MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM,
              MASTER_APP_SHEETS_SUB_ROUTE,
              tabSubRoute,
            ].join('/');

            return {
              tabId,
              tabTitle,
              tabLink: `${currentMasterAppSubRouteUrlWithDepartmentsAndViewModeParam}/${tabSubRoute}`,
              tabRouteParams: {
                path: [
                  `${tabRoutePath}/:sheetId`,
                  tabRoutePath,
                ],
                render: () => <TabComponent departmentIds={departmentIdsFromRoute.map(Number)} />,
              },
            };
          })
      }
    />
  );
};

MasterSheets.propTypes = {
  departmentIdsRouteParam: PropTypes.string.isRequired,
  departmentIdsFromRoute: PropTypes.arrayOf(NUMBER_OR_STRING_TYPE).isRequired,
};