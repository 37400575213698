import PropTypes from 'prop-types';
import { FUNC_IS_REQUIRED_TYPE } from '../../constants/propTypes';

export const PERMISSION = {
  REVIEW_PLANNER_APP: 1,
  REVIEW_WORKER_APP: 2,
  REVIEW_MASTER_APP: 3,
  REVIEW_STORAGE_MANAGEMENT_APP: 4,

  /*
  * В документации серверного приложения разрешение описывается, как "Просмотр пользователей", но нам необходимо
  * разрешение на просмотр всего приложения "Администрирование" (там несколько разделов), поэтому на клиенте называем
  * его именно так. Сервер его в данный момент не использует, поэтому какой-то рассинхронизации быть не должно
  * */
  REVIEW_ADMIN_APP: 10,
  CREATE_USER: 11,
  EDIT_USER: 12,
  DEACTIVATE_USER: 13,

  /*
  * Пока решил в клиентском приложении не обрабатывать это разрешение, т.к. сценариев работы с администраторами,
  * обладающими разными правами, у нас нет и они до конца не понятны. Сейчас все администраторы обладают и
  * MANAGE_ADMIN_USER, и CREATE_USER, EDIT_USER, DEACTIVATE_USER. Когда сценарии появятся, тогда и реализуем
  * обработку этого в клиентском приложении. Со стороны сервера разрешение будет обрабатываться уже сейчас,
  * поэтому, в принципе, мы тут защищены даже на случай, если не будем обрабатывать это разрешение совсем.
  * */
  MANAGE_ADMIN_USER: 14,
  MANAGE_WORKER_TASKS_TABLE_SETTINGS: 100,

  START_SHEET: 101,
  CHANGE_SHEET_OPERATION_STATUS: 102,
  FORCE_SHEET_FINISH: 103,
  EXPORT_SHEETS_IN_PRODUCTION_DATA: 104,
  CONSUME_ENTITIES_FOR_DEFAULT_SHEET: 105,

  SET_SHEET_OPERATION_ASSIGNEES_OR_EQUIPMENT: 106,
  SET_ENTITY_NOTE: 107,
  PAUSE_OR_RESUME_SHEET: 108,
  SPLIT_ENTITY_BATCH: 109,
  CLEAR_DB: 113,
  RESERVE_ENTITIES_FOR_ASSEMBLY_SHEET: 114,
  ENTITY_BATCH_PROVIDE_PARTIALLY: 116,
  CONSUME_ENTITIES_FOR_ASSEMBLY_SHEET: 118,

  MARK_DEFECTIVE_ENTITIES: 120,
};

export const PERMISSIONS_MANAGER_TYPE = PropTypes.shape({
  isGranted: FUNC_IS_REQUIRED_TYPE,
  isDenied: FUNC_IS_REQUIRED_TYPE,
});