import { compose } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import {
  defaultSheetsWaitingPartsAndMaterialsToShowDataSelector,
} from '../../../../selectors/sheets';

import {
  fetchDefaultSheetsWaitingPartsAndMaterials,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
} from '../../../../operations/sheets';
import { asyncComponent } from '../../../../hoc/asyncComponent/asyncComponent';

import { DefaultSheetsWaitingPartsAndMaterials } from './DefaultSheetsWaitingPartsAndMaterials';
import { ENTITY_BATCH_MODEL, ENTITY_MODEL, ORDER_MODEL } from '../../../../constants/models';
import { WITH_PARAMS } from '../../../../api/restCollectionApi/index';
import { addEntitiesToStore } from '../../../../reducers/entities/actions';


const SHEETS_WAITING_PARTS_AND_MATERIALS_REQUEST_WITH_PARAMS = [
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  {
    column: ORDER_MODEL,
    params: [{ key: WITH_PARAMS.STRICT, value: false }],
  },
];


const mapStateToProps = state => ({
  defaultSheetsWaitingPartsAndMaterials: defaultSheetsWaitingPartsAndMaterialsToShowDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchDefaultSheetsWaitingPartsAndMaterials: () =>
    Promise.all([
      dispatch(fetchDefaultSheetsWaitingPartsAndMaterials({
        with: SHEETS_WAITING_PARTS_AND_MATERIALS_REQUEST_WITH_PARAMS,
      }))
        .then(response => dispatch(addEntitiesToStore(response.entities))),

      dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage()),
    ]),
});

export const DefaultSheetsWaitingPartsAndMaterialsContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchDefaultSheetsWaitingPartsAndMaterials }) => fetchDefaultSheetsWaitingPartsAndMaterials(),
      },
    ],
  }),
)(DefaultSheetsWaitingPartsAndMaterials);

DefaultSheetsWaitingPartsAndMaterialsContainer.displayName = 'SheetsWaitingForPartsAndMaterialsContainer';