import { FILTER_TYPES } from '../api/restCollectionApi/index';
import { Trans } from '@lingui/macro';
import { MATERIAL_UI_STYLE_COLOR } from './materialUI';
import React from 'react';
import { ArchivedLabelTrans } from '../utils/commonTransComponents';


export const ORDER_TYPE = {
  IN_PRODUCTION: 'IN_PRODUCTION_ORDER_TYPE',
  READY_TO_COMPLETE: 'READY_TO_COMPLETE_ORDER_TYPE',
  COMPLETED: 'COMPLETED_ORDER_TYPE',
  ARCHIVED: 'ARCHIVED_ORDER_TYPE',
};

export const SERVER_ORDER_STATUS = {
  IN_PRODUCTION: 0,
  READY_TO_COMPLETE: 1,
  COMPLETED: 2,
  ARCHIVED: 3,
};

export const ORDERS_IN_PRODUCTION_REQUEST_FILTERS = [
  {
    column: 'status',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: SERVER_ORDER_STATUS.IN_PRODUCTION,
  },
];

export const ORDERS_READY_TO_COMPLETE_REQUEST_FILTERS = [
  {
    column: 'status',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: SERVER_ORDER_STATUS.READY_TO_COMPLETE,
  },
];

export const COMPLETED_ORDERS_REQUEST_FILTERS = [
  {
    column: 'status',
    filterType: FILTER_TYPES.ONE_OF,
    filterValue: [SERVER_ORDER_STATUS.COMPLETED, SERVER_ORDER_STATUS.ARCHIVED],
  },
];

export const ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS = [ORDER_TYPE.IN_PRODUCTION, ORDER_TYPE.READY_TO_COMPLETE];

export const getArchivedOrdersInfoLabel = ({ status }) => {
  if(status !== SERVER_ORDER_STATUS.ARCHIVED) return [];

  return [
    {
      labelContent: ArchivedLabelTrans,
      labelTooltipContent: (
        <Trans id="completed_orders@archived_order_info_label">
          Завершение заказа было учтено в системе планирования. Заказ с таким же именем будет считаться новым заказом
        </Trans>
      ),
      labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
      labelKey: 'archivedOrderStatus',
    },
  ];
};
