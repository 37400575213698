import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useConfirm } from '../../AppConfirm/AppConfirmContext';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { SearchField } from '../SearchField/SearchField';
import { Button, Tooltip } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import _isEmpty from 'lodash/isEmpty';
import _mapValues from 'lodash/mapValues';
import _isNil from 'lodash/isNil';
import _isFunction from 'lodash/isFunction';

import { FUNC_IS_REQUIRED_TYPE, OBJECT_OF_ANY_TYPE } from '../../../constants/propTypes';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../constants/materialUI';

import cn from 'classnames';

import './style.css';
import { DebouncedTextField } from '../DebouncedTextField/DebouncedTextField';
import { useConfirmOnLeave } from '../../../hoc/confirmOnLeave/confirmOnLeave';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../constants/table';
import { Pagination } from '../Pagination/Pagination';
import { addDecimals } from '../../../utils/decimal';
import {
  ApplyChangesLabelTrans,
  BeforeEditingLabelTrans,
  CancelChangesLabelTrans,
  FillAllValuesLabelTrans,
} from '../../../utils/commonTransComponents';


export const EntitiesTableWithEditableColumn = props => {

  const {
    className,
    initialRowsDataMap,
    onSubmitChanges: onSubmitChangesFromProps,
    shouldConfirmOnSubmit,
    submitChangesConfirmTitle,
    submitChangesConfirmText,
    areChangesPermitted,
    editableField,
    rowKeyField,
    availableAmountKey,
    rowValidator,
    filterFunc,
    sortFunc,
    prepareRowData,
    prepareDataBeforeSubmit,
    columnsSchema,
    editableFieldNormalizer,
    onEditableValuesChange,
    rowsPerPage,
  } = props;

  const [newEditableValuesData, setNewEditableValuesData] = useState(
    () => _initializeNewEditableValuesData(initialRowsDataMap, editableField),
  );

  const [state, setState] = useState({
    currentPage: 1,
    filterValue: '',
  });

  const { filterValue, currentPage } = state;

  const setCurrentPage = useCallback(
    newCurrentPage => setState(prevState => ({
      ...prevState,
      currentPage: newCurrentPage,
    })),
    [setState],
  );

  const initialRowsKeys = useMemo(
    () => Object.keys(initialRowsDataMap),
    [initialRowsDataMap],
  );

  const setEditableValue = useCallback(
    (rowKey, newEditableValue) => setNewEditableValuesData(
      prevState => {
        if(prevState[rowKey] === newEditableValue) return prevState;

        return {
          ...prevState,
          [rowKey]: newEditableValue,
        };
      },
    ),
    [setNewEditableValuesData],
  );

  const errors = !!rowValidator ?
    initialRowsKeys
      .reduce(
        (acc, rowKey) => {
          const initialRowData = initialRowsDataMap[rowKey];

          const newEditableValue = newEditableValuesData[rowKey];

          const error = rowValidator(
            initialRowData,
            newEditableValue,
          );

          if(error !== undefined) {
            acc[rowKey] = error;
          }

          return acc;
        },
        {},
      ) :
    {};

  const filteredAndSortedInitialRowsData = useMemo(
    () => {
      const initialRowsDataArray = Object.values(initialRowsDataMap);

      const filteredInitialRowsDataArray = !!filterFunc && !!filterValue ?
        initialRowsDataArray
          .filter(initialRowData => filterFunc(initialRowData, filterValue)) :
        initialRowsDataArray;

      return !!sortFunc ?
        filteredInitialRowsDataArray.sort(sortFunc) :
        filteredInitialRowsDataArray;
    },
    [initialRowsDataMap, filterValue, filterFunc, sortFunc],
  );

  const shouldRenderPagination = useMemo(() =>
    filteredAndSortedInitialRowsData.length > rowsPerPage,
    [filteredAndSortedInitialRowsData.length, rowsPerPage]);

  const filteredAndSortedInitialRowsDataForCurrentPage = useMemo(() => (
    shouldRenderPagination
      ? filteredAndSortedInitialRowsData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
      : filteredAndSortedInitialRowsData
  ), [shouldRenderPagination, filteredAndSortedInitialRowsData, currentPage, rowsPerPage]);


  const rowsData = !!prepareRowData ?
    filteredAndSortedInitialRowsDataForCurrentPage
      .map(initialRowData => {
        const rowKey = initialRowData[rowKeyField];
        return prepareRowData(
          initialRowData,
          newEditableValuesData[rowKey],
          errors[rowKey],
        );
      }) :
    filteredAndSortedInitialRowsDataForCurrentPage;

  const confirm = useConfirm();

  const onSubmitChanges = () =>
    (
      shouldConfirmOnSubmit ?
        confirm({
          confirmModalTitle: submitChangesConfirmTitle,
          confirmText: submitChangesConfirmText,
        }) :
        Promise.resolve()
    )
      .then(() => {
        const dataToSubmit = prepareDataBeforeSubmit(initialRowsDataMap, newEditableValuesData);

        if(_isEmpty(dataToSubmit)) return;

        return onSubmitChangesFromProps(dataToSubmit);
      });

  const onCancelChanges = useCallback(
    () => setNewEditableValuesData(_initializeNewEditableValuesData(initialRowsDataMap, editableField)),
    [setNewEditableValuesData, initialRowsDataMap, editableField],
  );

  const onFillAllValues = useCallback(() =>
      setNewEditableValuesData(
        _mapValues(
          initialRowsDataMap,
          rowData => {
            const availableAmount = rowData[availableAmountKey];
            const requiredAmount = rowData.requiredAmount;
            const editableValue = Number(rowData[editableField]);

            if (availableAmount >= requiredAmount) {
              return String(requiredAmount);
            }

            return String(addDecimals(availableAmount, editableValue));
          },
        ),
      )
  , [availableAmountKey, editableField, initialRowsDataMap]);

  const isAutofillAvailable = useMemo(
    () => initialRowsKeys.some(key => {
      const initialRowData = initialRowsDataMap[key];
      return initialRowData.remainingAmount > 0 && initialRowData[availableAmountKey] > 0;
    }),
      [availableAmountKey, initialRowsDataMap, initialRowsKeys],
  );



  const areEditableValuesChanged = initialRowsKeys
      .some(rowKey => {
        const newEditableValue = newEditableValuesData[rowKey];
        return _isNil(newEditableValue) || newEditableValue === ''
            || Number(newEditableValue) !== initialRowsDataMap[rowKey][editableField];
      });

  useEffect(() => {
    // при изменении значени в таблицу вызывается колбэк onEditableValuesChange, если он задан, для того, чтобы
    // оповестить родителя о том, есть ли в редактируемых данных изменения
    if (_isFunction(onEditableValuesChange)) onEditableValuesChange(areEditableValuesChanged);
  }, [areEditableValuesChanged, onEditableValuesChange]);

  useConfirmOnLeave(
    ({ areEditableValuesChanged }) => areEditableValuesChanged,
    { areEditableValuesChanged },
  );

  const componentRef = useRef();

  const renderProps = {
    initialRowsKeys,
    initialRowsDataMap,
    newEditableValuesData,
    onSubmitChanges,
    onCancelChanges,
    onFillAllValues,
    areChangesPermitted,
    errors,
    filterValue,
    rowsData,
    editableField,
    rowKeyField,
    setEditableValue,
    columnsSchema,
    editableFieldNormalizer,
    areEditableValuesChanged,
    rowsPerPage,
    currentPage,
    setCurrentPage,
    setState,
    filteredAndSortedInitialRowsData,
    shouldRenderPagination,
    availableAmountKey,
    isAutofillAvailable,
    componentRef,
  };

  return (
    <div ref={componentRef} className={cn('entities-table-with-editable-column', className)}>
      <Card className="entities-table-with-editable-column__card">
        {_renderCardHeader(renderProps)}
        {_renderCardContent(renderProps)}
      </Card>
    </div>
  );
};

const _initializeNewEditableValuesData = (initialRowsDataMap, editableField) =>
  _mapValues(
    initialRowsDataMap,

    //Новое значение редактируемой колонки передается далее в компонент текстового поля, поэтому в state осознанно
    //хранятся значения в виде строк. Преобразования выполняются только при арифметических операциях и сравнениях
    initialRowData => String(initialRowData[editableField]),
  );

/*eslint-disable react/prop-types*/
const _renderCardHeader = props => (
  <CardHeader
      className="entities-table-with-editable-column__card-header"
      title={_renderCardHeaderContent(props)}
  />
);


const _renderCardHeaderContent = ({
  onSubmitChanges,
  onCancelChanges,
  onFillAllValues,
  areChangesPermitted,
  errors,
  filterValue,
  areEditableValuesChanged,
  setState,
  isAutofillAvailable,
}) => (
    <div className="entities-table-with-editable-column__card-header-content">
      <SearchField
          className="entities-table-with-editable-column__search-field"
          value={filterValue}
          onChange={newFilterValue => setState({
            filterValue: newFilterValue,
            currentPage: 1,
          })}
      />
      {
        areChangesPermitted ?
          <div className="entities-table-with-editable-column__card-header-btn-block">
            <Button
                variant={MATERIAL_UI_VARIANT.CONTAINED}
                color={MATERIAL_UI_STYLE_COLOR.INHERIT}
                onClick={onFillAllValues}
                disabled={!isAutofillAvailable}
                className="entities-table-with-editable-column__fill-all-values-btn"
            >
              {FillAllValuesLabelTrans}
            </Button>
            <Button
                variant={MATERIAL_UI_VARIANT.CONTAINED}
                color={MATERIAL_UI_STYLE_COLOR.INHERIT}
                onClick={onCancelChanges}
                disabled={!areEditableValuesChanged}
            >
              {CancelChangesLabelTrans}
            </Button>
            <Button
                variant={MATERIAL_UI_VARIANT.CONTAINED}
                color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
                onClick={onSubmitChanges}
                disabled={
                  !_isEmpty(errors) || !areEditableValuesChanged
                }
            >
              {ApplyChangesLabelTrans}
            </Button>
          </div> :
          null
      }
    </div>
  );

const _renderCardContent = props => {
  const paginationMarkup = _renderTablePagination(props);
  return (
  <CardContent className="entities-table-with-editable-column__card-content">
    {paginationMarkup}
    { _renderCardTable(props) }
    {paginationMarkup}
  </CardContent>
  );
};

const _renderCardTableHead = ({ columnsSchema }) => (
  <TableHead className="entities-table-with-editable-column__card-table-header" >
    <TableRow>
      {
        columnsSchema
          .map(
            ({ key, title }) =>
              _renderCardTableCell(['table-header-cell', key].join('--'), title),
          )
      }
    </TableRow>
  </TableHead>
);

const _renderCardTable = props =>
  <Table className="entities-table-with-editable-column__card-table">
    {_renderCardTableHead(props)}
    {_renderCardTableBody(props)}
  </Table>;

const _renderTablePagination = props => {
  const {
    filteredAndSortedInitialRowsData,
    rowsPerPage,
    setCurrentPage,
    currentPage,
    shouldRenderPagination,
  } = props;

  if (!shouldRenderPagination) return null;

  return (
    <Pagination
        activePage={currentPage}
        total={filteredAndSortedInitialRowsData.length}
        limit={rowsPerPage}
        onClick={setCurrentPage}
        className="entities-table-with-editable-column__pagination"
    />
  );
};

const _renderCardTableCell = (tableCellKey, tableCellValue) =>
  <TableCell
      key={tableCellKey}
      className="entities-table-with-editable-column__card-table-cell"
  >
    {tableCellValue}
  </TableCell>;

const _renderCardTableBody = props => {

  const {
    rowsData,
    errors,
    initialRowsDataMap,
    editableField,
    rowKeyField,
    newEditableValuesData,
    columnsSchema,
    areChangesPermitted,
    editableFieldNormalizer,
    setEditableValue,
    componentRef,
  } = props;

  return (
    <TableBody>
      {
        rowsData
          .map(
            rowData => {
              const {
                [rowKeyField]: rowKey,
              } = rowData;

              const editableFieldError = errors[rowKey];

              const errorTooltipContent = !!editableFieldError ?
                <div className="entities-table-with-editable-column__card-table-row-error-tooltip-content">
                  {editableFieldError}
                </div> :
                '';

              const editableFieldInitialValue = initialRowsDataMap[rowKey][editableField];
              const editableFieldNewValue = newEditableValuesData[rowKey];

              const isEditableFieldChanged = _isNil(editableFieldNewValue) ||
                editableFieldNewValue === '' ||
                editableFieldInitialValue !== Number(editableFieldNewValue);

              return (
                <Tooltip
                    key={rowKey}
                    classes={{
                      tooltip: 'entities-table-with-editable-column__card-table-row-error-tooltip',
                    }}
                    placement="top"
                    title={errorTooltipContent}
                    open
                    TransitionProps={{
                      timeout: 0,
                    }}

                  /*
                  * Требуемое поведение:
                  *
                  * Когда есть ошибка, то тултип должен появляться всегда сверху ряда таблицы.
                  *
                  * Без дополнительных настроек получили ситуацию, что когда рядов в таблице много и есть скрол, если
                  * ошибочный ряд попадает под, например, навигационную панель или какой-то другой фиксированный
                  * элемент управления, то ряд сам, понятно, скрывается, а тултип остается поверх этого фиксированного
                  * элемента. Это происходило, потому что тултип по умолчанию рендерится в портале на уровне body, т.е.
                  * на одном уровне с таблицей или даже на уровне выше. И, получается, что с одной стороны тултип
                  * должен быть по z-index выше, чем компонент, в котором располагается таблица, чтобы он был видим,
                  * но, с другой стороны, должен быть ниже по z-index фиксированного элемента внутри того же самого
                  * компонента. В такой ситуации как-то проработать стили через css, если и можно (но, скорее всего, и
                  * нельзя), то очень сложно
                  *
                  * Поэтому, для тултипа можно переопределить опцию container в пропсах Popper, тогда тултип будет
                  * также рендериться в портале, но не на уровне body, а, например, на уровне компонента в разметке и
                  * тогда уже ничего дополнительно настраивать по z-index будет не нужно, тултип будет скрываться
                  * аналогично самому ряду
                  *
                  * Дополнительно к этому, как оказалось, с тултипом есть ещё одно нежелаемое в данном случае
                  * поведение - Тултип, по умолчанию, умный и меняет свою позицию с заданной, если не помещается в
                  * контейнер, к которому он относится. Т.е. мы устанавливаем его сверху ряда, но если ряд уходит за
                  * пределы видимой области, то тултип перескакивает вниз и хочет постоянно быть на экране. Это же
                  * поведение было и когда тултип был в портале на уровне body, только его было сложнее воспроизвести.
                  * В общем случае, понятно, что описанное выше поведение тултипа логично, но мы здесь используем его
                  * не совсем стандартно, как своеобразный HelpText для ошибочного ряда, который по задумке всегда
                  * должен быть рядом с ошибочным рядом и всегда показываться. Кроме того, если тултип начинает
                  * перескакивать, то они начинают конфликтовать с тултипами других рядов и все перемешиваются.
                  * Поэтому, в нашей текущей логике было важно, чтобы тултип был всегда привязан к ряду. Это можно
                  * достичь переопределив дефолтный modifier flip у внутреннего компонента тултипа, отвечающего за это
                  *  поведение, Popper'а (нашёл это всё только в исходниках сначала material-ui, а потом и самой
                  * библиотеки по созданию popper, которую использует material ui - https://popper.js.org/docs/v2/).
                  * Т.е. в данном случае мы просто отключаем дефолтное поведение, которое пересчитывает позицию
                  * тултипа, если он выходит за пределы контейнера.
                  */
                    PopperProps={{
                      container: componentRef.current,
                      modifiers: [
                        {
                          name: 'flip',
                          enabled: false,
                        },
                      ],
                    }}
                >
                  <TableRow
                      className={
                        cn(
                          'entities-table-with-editable-column__card-table-row',
                          {
                            'entities-table-with-editable-column__card-table-row--highlighted':
                            isEditableFieldChanged,
                          },
                        )
                      }
                  >
                    {
                      columnsSchema
                        .map(
                          ({ key }) => {

                            const cellContent = areChangesPermitted && key === editableField ?
                              _renderEditableFieldCell(
                                editableFieldInitialValue,
                                editableFieldNewValue,
                                isEditableFieldChanged,
                                editableFieldNormalizer,
                                editableFieldError,
                                value => setEditableValue(rowKey, value),
                              ) :
                              rowData[key];

                            return _renderCardTableCell(['table-row-cell', key].join('--'), cellContent);
                          },
                        )
                    }
                  </TableRow>
                </Tooltip>
              );
            },
          )
      }
    </TableBody>
  );
};

const _renderEditableFieldCell = (
  editableFieldInitialValue,
  editableFieldNewValue,
  isEditableFieldChanged,
  editableFieldNormalizer,
  editableFieldError,
  onEditableFieldChange,
) =>
  <div className="entities-table-with-editable-column__card-table-cell-input-wrapper">
    <DebouncedTextField
        className="entities-table-with-editable-column__card-table-cell-input"
        value={editableFieldNewValue}
        onChange={onEditableFieldChange}
        valueNormalizer={editableFieldNormalizer}
        error={!!editableFieldError}
        variant={MATERIAL_UI_VARIANT.OUTLINED}
        helperText={
          isEditableFieldChanged ?
            <span className="entities-table-with-editable-column__card-table-cell-input-helper-text-content">
              {BeforeEditingLabelTrans}
              {': '}
              {editableFieldInitialValue}
            </span> :
            null

        }
    />
  </div>;

/*eslint-enable react/prop-types*/

EntitiesTableWithEditableColumn.defaultProps = {
  initialRowsDataMap: {},
  shouldConfirmOnSubmit: true,
  submitChangesConfirmTitle: 'Action confirmation',
  submitChangesConfirmText: 'Are you sure?',
  areChangesPermitted: true,
  prepareDataBeforeSubmit: (initialRowsDataMap, newEditableValuesData, editableField) => _mapValues(
    initialRowsDataMap,
    (initialRowData, rowKey) => ({ ...initialRowData, [editableField]: newEditableValuesData[rowKey] }),
  ),
  rowKeyField: 'id',
  rowsPerPage: DEFAULT_TABLE_PAGE_SIZE,
};

EntitiesTableWithEditableColumn.propTypes = {
  className: PropTypes.string,
  initialRowsDataMap: PropTypes.objectOf(OBJECT_OF_ANY_TYPE),
  onSubmitChanges: FUNC_IS_REQUIRED_TYPE,
  shouldConfirmOnSubmit: PropTypes.bool,
  submitChangesConfirmTitle: PropTypes.node,
  submitChangesConfirmText: PropTypes.node,
  areChangesPermitted: PropTypes.bool,
  editableField: PropTypes.string.isRequired,
  rowKeyField: PropTypes.string,
  availableAmountKey: PropTypes.string.isRequired,
  rowValidator: PropTypes.func,
  filterFunc: PropTypes.func,
  sortFunc: PropTypes.func,
  prepareRowData: PropTypes.func,
  prepareDataBeforeSubmit: PropTypes.func,
  onEditableValuesChange: PropTypes.func,
  columnsSchema: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
    }),
  ),
  editableFieldNormalizer: PropTypes.func,
  rowsPerPage: PropTypes.number,
};