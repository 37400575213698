import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';

import cn from 'classnames';

import './style.css';


export const SimpleHelpAlert = ({ className, content }) => (
  <div
      className={
        cn(
          'simple-help-alert',
          className,
        )
      }
  >
    <Chip
        className="simple-help-alert__chip"
        label={content}
    />
  </div>
);

SimpleHelpAlert.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
};