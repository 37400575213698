import { combineReducers } from 'redux';


import { tasksMainFilters } from './tasksMainFilters/reducer';
import { tasksOwners } from './tasksOwners/reducer';
import { assemblySheets } from './assemblySheets';
import { tasksAdditionalFilters } from './tasksAdditionalFilters/reducer';

export const workerAppReducer = combineReducers({
  tasksMainFilters,
  tasksAdditionalFilters,
  tasksOwners,
  assemblySheets,
});
