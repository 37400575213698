import _isNil from 'lodash/isNil';
import { getUserFullName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const getSheetOperationCombinedName = (operationNumber, operationName, operationIdentity) =>
  `${operationNumber}_${operationName}(${operationIdentity})`;


export const getUserFullNameWithNote = ({ name, lastName, patronymicName, note }) => _isNil(note) || note === '' ?
  getUserFullName({ name, lastName, patronymicName }) :
  `${getUserFullName({ name, lastName, patronymicName })} (${note})`;