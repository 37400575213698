import { Broadcaster } from './Broadcaster';
import { CA_CLIENT_SOCKET_MESSAGE_TYPE } from '../../../../constants/sockets';


export const broadcaster = new Broadcaster(CA_CLIENT_SOCKET_MESSAGE_TYPE);
export const broadcastEventMessage = (event, data) => {
  broadcaster.sendMessage({
    event,
    data,
  });
};
