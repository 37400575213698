import PropTypes from 'prop-types';
import { FILTER_TYPES } from '../../../api/restCollectionApi';
import { NUMBER_OR_STRING_TYPE, OBJECT_OF_ANY_TYPE } from '../../../constants/propTypes';

export const FILTER_DATA_TYPE = PropTypes.shape({
  filterType: PropTypes.oneOf(Object.values(FILTER_TYPES)),
  filterValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(NUMBER_OR_STRING_TYPE),
  ]),
  filterAdditionalData: PropTypes.oneOfType([
    OBJECT_OF_ANY_TYPE,
    PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
  ]),
});
