import { ORDER_MODEL } from '../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi/index';
import React from 'react';
import {
  ORDERS_IN_PRODUCTION_REQUEST_FILTERS,
  ORDERS_READY_TO_COMPLETE_REQUEST_FILTERS,
  ORDER_TYPE,
  COMPLETED_ORDERS_REQUEST_FILTERS,
} from '../../../constants/orders';
import { FILTER_COMPONENTS_MAP } from '../../common/Filters/FilterComponents/index';
import {
  FilterNotSelectedLabelTrans,
  NoMatchesFoundLabelTrans,
  OrderNameLabelTrans,
} from '../../../utils/commonTransComponents';
import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../reducers/autocomplete/actions';
import { SHEETS_DEFAULT_REQUEST_OPTIONS } from '../../../constants/sheets';


export const createOrdersFiltersSchema = (orderTypeIdentity, orderTypePredefinedFilters) => ({
  id: _createOrderIdFilterSchema(orderTypeIdentity, orderTypePredefinedFilters),
});

const _createAutocompleteFilterSchemaItem = ({
   orderTypeIdentity,
   autocompleteEntitiesModel,
   getFilterTitle,
   getOptionValue,
   getOptionLabel,
   orderTypePredefinedFilters,
   getRequestFiltersFromAutocompleteInputValue,
   options,
 }) => ({
   getFilterTitle,
  //eslint-disable-next-line react/prop-types
   getFilterChipContent: ({ filterAdditionalData }, title) => (
    <span>
      {title}
      <b>{`: ${getOptionLabel(filterAdditionalData)}`}</b>
    </span>
  ),
   filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
   filterComponentProps: {
     autocompleteId: [orderTypeIdentity, autocompleteEntitiesModel].join('__'),
     getOptionValue,
     getOptionLabel,
     placeholder: FilterNotSelectedLabelTrans,
     noOptionsMessage: NoMatchesFoundLabelTrans,
     loadOptionsActionCreator: orderTypePredefinedFilters && getRequestFiltersFromAutocompleteInputValue ?
      createCaEntitiesAutocompleteLoadOptionsActionCreator({
        requestModel: ORDER_MODEL,
        getRequestQuery: inputValue => {

          if(!inputValue) return {
            filter: {
              filterGroupType: FILTER_GROUP_TYPES.AND,
              filters: orderTypePredefinedFilters,
            },
          };

          return {
            filter: {
              filterGroupType: FILTER_GROUP_TYPES.AND,
              filters: orderTypePredefinedFilters
                .concat(getRequestFiltersFromAutocompleteInputValue(inputValue)),
            },
          };
        },
        getRequestOptions: () => SHEETS_DEFAULT_REQUEST_OPTIONS,
        optionsModel: autocompleteEntitiesModel,
      }) :
      undefined,
     options,
   },
 });

const _createOrderIdFilterSchema = (orderTypeIdentity, orderTypePredefinedFilters) =>
  _createAutocompleteFilterSchemaItem({
    orderTypeIdentity,
    autocompleteEntitiesModel: ORDER_MODEL,
    getFilterTitle: () => OrderNameLabelTrans,
    getOptionLabel: ({ name }) => name,
    orderTypePredefinedFilters,
    getRequestFiltersFromAutocompleteInputValue: inputValue => ({
      column: 'name',
      filterType: FILTER_TYPES.CONTAINS,
      filterValue: inputValue,
    }),
  });


export const ORDERS_IN_PRODUCTION_FILTERS_SCHEMA = createOrdersFiltersSchema(
  ORDER_TYPE.IN_PRODUCTION,
  ORDERS_IN_PRODUCTION_REQUEST_FILTERS,
);

export const ORDERS_READY_TO_COMPLETE_FILTERS_SCHEMA = createOrdersFiltersSchema(
  ORDER_TYPE.READY_TO_COMPLETE,
  ORDERS_READY_TO_COMPLETE_REQUEST_FILTERS,
);

export const COMPLETED_ORDERS_FILTERS_SCHEMA = createOrdersFiltersSchema(
  ORDER_TYPE.COMPLETED,
  COMPLETED_ORDERS_REQUEST_FILTERS,
);