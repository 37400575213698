import React from 'react';
import PropTypes from 'prop-types';
import { FUNC_IS_REQUIRED_TYPE } from '../../constants/propTypes';

import './style.css';

export const NotificationContentWithAction = props => {
  const {
    message,
    actionTitle,
    onClick,
  } = props;

  return (
    <div className="notification-content-with-actions">
      { message }
      {/*
      тут спецаиально использована обычная кнопка, а не из Material-UI. Если этот нотификатор вызывается с
      кнопкой из material-ui, то в окне операции кнопки в правом верхнем углу растяягиваются, потому что у них меняются
      стили и ставится дефолтный font-family. А стили у них меняются, потому что при появлении этого нотификатора в
      теге </head> появляются новые фалы стилей, они как-будто дублируются. Возможно в процессе работы приложения такое
      происходит довольно часто и в итоге мы загружаем очень много лишних стилей, нужно разбираться с этим отдельно.
      */}
      <button
          className="notification-content-with-actions__button"
          onClick={onClick}
      >
        { actionTitle }
      </button>
    </div>
  );
};

NotificationContentWithAction.propTypes = {
  message: PropTypes.node.isRequired,
  actionTitle: PropTypes.node.isRequired,
  onClick: FUNC_IS_REQUIRED_TYPE,
};