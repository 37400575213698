import { connect } from 'react-redux';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import { appLanguageSelector } from '../../reducers/appState/selectors';
import { blockingAsyncActionWrap } from '../../reducers/blockingAsyncAction/actions';
import { FUNC_IS_REQUIRED_TYPE } from '../../constants/propTypes';
import { en, ru } from 'make-plural/plurals';


const { AVAILABLE_LOCALES } = window.config;

/*
* Для lingui обязательна загрузка plurals для языка даже если этим не особо то и хочется пользоваться.
* В проекте продуманы кастомные словари языков, принято, что  ключи начинаются так же, как ключ основного словаря языка,
* поэтому для определения plurals здесь такая логика проверка со startsWith.
* По умолчанию решено ничего не возвращать, чтобы lingui ругнулся, если при добавлении новых словарей забудем добавить
* для них plurals
* */
const getPluralsForLanguage = language => {
  if(language.startsWith('en')) {
    return en;
  }

  if(language.startsWith('ru')) {
    return ru;
  }
};


const TranslationsProviderComponent = props => {
  const {
    language,
    blockingAsyncActionWrap,
    children,
  } = props;

  useEffect(
    () => {
      const importCatalogsModuleDynamic = () =>
        /*
        * Подменяем файл модуля каталога с messages.js на файл словаря messages.po.
        * Подобная манипуляция позволит нам пользоваться лоадером для вебпака "@lingui/loader".
        * При его использовании будет вызываться компиляция модулей переводов в режиме реального времени
        * */
        import(
          /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
          `@lingui/loader!../../locales/${AVAILABLE_LOCALES[language].catalogModule.replace(/js$/, () => 'po')}`
        )
          .then(catalogModule => {

            i18n.loadLocaleData(language, { plurals: getPluralsForLanguage(language) });
            i18n.load(language, catalogModule.default.messages);
            i18n.activate(language);
          });

      blockingAsyncActionWrap(importCatalogsModuleDynamic);
    },
    [language, blockingAsyncActionWrap],
  );

  return (
    <I18nProvider i18n={i18n}>
      {children}
    </I18nProvider>
  );
};

TranslationsProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
  blockingAsyncActionWrap: FUNC_IS_REQUIRED_TYPE,
};

const mapStateToProps = state => ({
  language: appLanguageSelector(state),
});

const mapDispatchToProps = {
  blockingAsyncActionWrap,
};

export const TranslationsProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TranslationsProviderComponent);