import { connect } from 'react-redux';

import { exitFromAppType } from '../../operations/app';

import { AppTypeTopBar } from './AppTypeTopBar';
import { isOnlyOneAppReviewPermittedForUserSelector } from '../../reducers/appState/selectors';

const mapStateToProps = state => ({
  isOnlyOneAppReviewPermitted: isOnlyOneAppReviewPermittedForUserSelector(state),
});

const mapDispatchToProps = {
  exitFromAppType,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {

  const { isOnlyOneAppReviewPermitted } = stateProps;
  const { exitFromAppType } = dispatchProps;
  const {
    className,
    appTitle,
    position,
    size,
    showReloadButtonOnlyOnFullscreen,
    TitleComponent,
    renderButtons,
  } = ownProps;

  return {
    size,
    isOnlyOneAppReviewPermitted,
    exitFromAppType,
    className,
    appTitle,
    position,
    showReloadButtonOnlyOnFullscreen,
    TitleComponent,
    renderButtons,
  };
};

export const AppTypeTopBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AppTypeTopBar);

AppTypeTopBarContainer.displayName = 'AppTypeTopBarContainer';