import { compose } from 'redux';
import { connect } from 'react-redux';
import { AssemblySheetsToStart } from './AssemblySheetsToStart';
import { asyncComponent } from '../../../../hoc/asyncComponent/asyncComponent';
import { fetchAssemblySheetsToStart } from '../../../../reducers/plannerApp/sheetsToStart/actions';
import { mainPlanningSessionIdSelector } from '../../../../reducers/appState/selectors';
import {
  assemblySheetsToStartDataSelector,
  assemblySheetsToStartFiltersSchemaSelector,
} from '../../../../reducers/plannerApp/sheetsToStart/selectors';


const mapStateToProps = state => ({
  mainPlanningSessionId: mainPlanningSessionIdSelector(state),
  assemblySheetsToStartData: assemblySheetsToStartDataSelector(state),
  filtersSchema: assemblySheetsToStartFiltersSchemaSelector(state),
});

const mapDispatchToProps = {
  fetchAssemblySheetsToStart,
};

export const AssemblySheetsToStartContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchAssemblySheetsToStart, mainPlanningSessionId }) =>
          fetchAssemblySheetsToStart(mainPlanningSessionId),
      },
    ],
  }),
)(AssemblySheetsToStart);

AssemblySheetsToStartContainer.displayName = 'AssemblySheetsToStartContainer';