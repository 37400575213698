import React from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './style.css';
import {
  ADMIN_APP_USERS_ROUTE,
  ADMIN_APP_WORKER_TASKS_SETTINGS_ROUTE,
  ADMIN_APP_CLEAR_DB_ROUTE,
  ADMIN_APP_SHEET_OPERATION_EQUIPMENT_SETTINGS_SUB_ROUTE,
  ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_SUB_ROUTE,
  ADMIN_APP_SHEET_OPERATION_SETTINGS_ROUTE,
  ADMIN_APP_WORKER_TASKS_TABLE_ROUTE,
  ADMIN_APP_WORKER_TASKS_TABLE_FILTERS_ROUTE,
  ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
} from '../../constants/routes';
import { UserAdministrationScreenContainer } from './UserAdministrationScreen/UserAdministrationScreenContainer';
import PeopleIcon from '@mui/icons-material/People';

import EventNoteIcon from '@mui/icons-material/EventNote';


import { SideBarMenu } from '../common/SideBarMenu/SideBarMenu';
import { WorkerTasksTableSettingsContainer } from './WorkerTasksTableSettingsScreen/WorkerTasksTableSettingsContainer';
import { AppTypeTopBarContainer } from '../AppTypeTopBar/AppTypeTopBarContainer';
import { ClearDbScreen } from './DataAdministration/ClearDbScreen/ClearDbScreen';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../hoc/withPermissionsManager/constants';
import { SheetOperationSettingsContainer } from './SheetOperationSettings/SheetOperationSettingsContainer';
import {
  CalendarViewWeek,
  Construction,
  FilterAlt,
  Engineering,
  PrecisionManufacturing,
} from '@mui/icons-material';
import {
  WorkerTasksAdminFiltersSettingsContainer,
} from './WorkerTasksAdminFiltersSettings/WorkerTasksAdminFiltersSettingsContainer';
import {
  AdministrationLabelTans,
  EquipmentLabelTrans,
} from '../../utils/commonTransComponents';
import { SheetOperationFeaturesScreen } from './SheetOperationFeaturesScreen/SheetOperationFeaturesScreen';
import { reactRouterIsExactSelector } from '../../selectors/reactRouter';


const ADMIN_APP_MENU_SCHEMA = [
  {
    key: ADMIN_APP_USERS_ROUTE,
    icon: PeopleIcon,
    title: (
      <Trans id="admin_app_nav_menu@users">
        Пользователи
      </Trans>
    ),
    linkTo: ADMIN_APP_USERS_ROUTE,
  },
  {
    key: ADMIN_APP_WORKER_TASKS_SETTINGS_ROUTE,
    icon: Construction,
    title: (
      <Trans id="admin_app_nav_menu@tasks_settings">
        Задания рабочего
      </Trans>
    ),
    linkTo: ADMIN_APP_WORKER_TASKS_SETTINGS_ROUTE,
    subMenu: [
      {
        key: ADMIN_APP_WORKER_TASKS_TABLE_ROUTE,
        icon: CalendarViewWeek,
        title: (
          <Trans id="admin_app_nav_menu@tasks_table_columns">
            Колонки таблицы
          </Trans>
        ),
        linkTo: ADMIN_APP_WORKER_TASKS_TABLE_ROUTE,
      },
      {
        key: ADMIN_APP_WORKER_TASKS_TABLE_FILTERS_ROUTE,
        icon: FilterAlt,
        title: (
          <Trans id="admin_app_nav_menu@tasks_table_filters">
            Фильтры заданий
          </Trans>
        ),
        linkTo: ADMIN_APP_WORKER_TASKS_TABLE_FILTERS_ROUTE,
      },
    ],
  },
  {
    key: ADMIN_APP_SHEET_OPERATION_SETTINGS_ROUTE,
    icon: EventNoteIcon,
    title: (
      <Trans id="admin_app_nav_menu@sheet_operation_settings">
        Операция МЛ
      </Trans>
    ),
    linkTo: ADMIN_APP_SHEET_OPERATION_SETTINGS_ROUTE,
    subMenu: [
      {
        key: ADMIN_APP_SHEET_OPERATION_EQUIPMENT_SETTINGS_SUB_ROUTE,
        icon: PrecisionManufacturing,
        title: EquipmentLabelTrans,
        linkTo: ADMIN_APP_SHEET_OPERATION_EQUIPMENT_SETTINGS_SUB_ROUTE,
      },
      {
        key: ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_SUB_ROUTE,
        icon: Engineering,
        title: (
          <Trans id="admin_app_nav_menu@sheet_operation_features">
            Доп. характеристики
          </Trans>
        ),
        linkTo: ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_SUB_ROUTE,
      },
    ],
  },
];

const _renderAdminAppTopBar = () => (
  <AppTypeTopBarContainer
      position="fixed"
      className="admin-app__top-bar"
      appTitle={AdministrationLabelTans}
  />
);

const _renderAdminAppSideBar = path => (
  <SideBarMenu
      className="admin-app__side-bar"
      menuSchema={ADMIN_APP_MENU_SCHEMA}
      currentPath={path}
  />
);

const _renderAdminAppContent = PermissionsManager => (
    <div className="admin-app__content">
      <Switch>
        <Route
            path={ADMIN_APP_USERS_ROUTE}
            component={UserAdministrationScreenContainer}
        />
        <Route
            exact
            path={ADMIN_APP_WORKER_TASKS_SETTINGS_ROUTE}
            render={() => <Redirect to={ADMIN_APP_WORKER_TASKS_TABLE_ROUTE}/>}
        />
        <Route
            path={ADMIN_APP_WORKER_TASKS_TABLE_ROUTE}
            component={WorkerTasksTableSettingsContainer}
        />
        <Route
            path={ADMIN_APP_WORKER_TASKS_TABLE_FILTERS_ROUTE}
            component={WorkerTasksAdminFiltersSettingsContainer}
        />
        <Route
            exact
            path={ADMIN_APP_SHEET_OPERATION_SETTINGS_ROUTE}
            render={() => <Redirect to={ADMIN_APP_SHEET_OPERATION_EQUIPMENT_SETTINGS_SUB_ROUTE}/>}
        />
        <Route
            path={ADMIN_APP_SHEET_OPERATION_EQUIPMENT_SETTINGS_SUB_ROUTE}
            component={SheetOperationSettingsContainer}
        />
        <Route
            path={ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_SUB_ROUTE}
            render={routerProps => {

              const isExact = reactRouterIsExactSelector(null, routerProps);

              if (isExact) return <Redirect to={ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE}/>;

              return <SheetOperationFeaturesScreen  {...routerProps} />;
            }}
        />
        {
          PermissionsManager.isGranted(PERMISSION.CLEAR_DB) ?
            <Route
                path={ADMIN_APP_CLEAR_DB_ROUTE}
                component={ClearDbScreen}
            /> :
            null
        }
        <Redirect to={ADMIN_APP_USERS_ROUTE}/>
      </Switch>
    </div>
  );

export const AdminApp = ({ pathName, PermissionsManager }) => (
   <div className="admin-app">
     {_renderAdminAppTopBar()}
     {_renderAdminAppSideBar(pathName)}
     {_renderAdminAppContent(PermissionsManager)}
   </div>
 );

AdminApp.propTypes = {
  pathName: PropTypes.string.isRequired,
  PermissionsManager: PERMISSIONS_MANAGER_TYPE,
};