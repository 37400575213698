import { createSelector } from 'reselect';
import { getQueryParamsFromQueryString } from '../../utils/url';

/*
* редьюсер для роутера (подключается при инициализации store), а также все его экшены получаются из библиотеки
* connected-react-router, поэтому тут определяются только селекторы для этого предопределенного state'а роутера
*
*/

export const routerStateSelector = state => state.router;

export const routerLocationSelector = state => routerStateSelector(state).location;

export const routerPathnameSelector = state => routerLocationSelector(state).pathname;
export const routerSearchSelector = state => routerLocationSelector(state).search;

export const locationQuerySelector = createSelector(
  routerSearchSelector,
  search => search ? getQueryParamsFromQueryString(search) : {},
);