import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  consumePartsAndMaterialsForDefaultSheet,
  fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
  sendPartAndMaterialsForDefaultSheetConsumedNotification,
} from '../../../../operations/sheets';
import { asyncComponent } from '../../../../hoc/asyncComponent/asyncComponent';
import _get from 'lodash/get';
import { DefaultSheetPartsAndMaterialsConsumingContent } from './DefaultSheetPartsAndMaterialsConsumingContent';
import { detailedDataToConsumeForDefaultSheetSelector } from '../../../../selectors/sheets';
import { clearAllDefaultSheetsPartsAndMaterialsToConsume } from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import { deleteEntitiesFromStore } from '../../../../reducers/entities/actions';
import { SHEET_MODEL } from '../../../../constants/models';
import { clearTableRemoteData } from '../../../../reducers/table/actions';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { withPermissionsManager } from '../../../../hoc/withPermissionsManager/withPermissionsManager';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../MasterApp/MasterTasksToDo/constants';
import { deleteAllAssemblySheetsReserveData } from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';


const mapStateToProps = (state, ownProps) => {
  const {
    entityBatchId,
    sheetId,
    sheetIdentity,
  } = _get(ownProps, 'sheetToReviewData', {});
  return {
    entityBatchId,
    sheetId,
    sheetIdentity,
    detailedDataToConsume: detailedDataToConsumeForDefaultSheetSelector(state, { sheetId }),
    allTasksTablesIds: allTasksTablesIdsSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
    consumePartsAndMaterialsForDefaultSheet,
  }, dispatch),

  /*
  * Влияние события комлектации МЛ на разные типы приложения и обработка этого с учетом того, что комлектация МЛ
  * происходит на экране просмотра МЛ, ожидающих комлектацию:
  *
  * 1. Плановик.
  * 1.1. Раздел "МЛ в производстве". Если МЛ был в списке на текущей странице, то у него должен смениться лейбл с
  *      "Не укомплектован" на "Укомплектован":
  *      - Можно было заморочиться, проверить данные текущей страницы в табличном хранилище, найти МЛ, которые был
  *        укомплектован и заменить у него поле, но это достаточно трудоемко и в таком случае мы не синхронизированы с
  *        сервером, а сами меняем поле, это может обернуться провалом, если сервер изменяет или будет изменять в
  *        будущем не только лишь поле комплектации. В итоге, просто очищаем хранилище списка МЛ в производстве
  *        (табличное хранилище по ID таблицы), чтобы при следующем входе в этот раздел был выполнен новый запрос.
  *
  * 1.2. Раздел "МЛ на запуск". Укомплектованный МЛ не влияет на этот раздел.
  *
  * 1.3. Раздел "МЛ приостановленные". Укомплектованный МЛ не влияет на этот раздел, т.к. комплектация выполняется
  * только над МЛ в производстве, а их нет в этом разделе
  *
  * 1.4. Раздел "МЛ завершенные". Укомплектованный МЛ не влияет на этот раздел.
  *
  * 2. Комплектование.
  * 2.1. Раздел "МЛ ожидающие комплектации". Укомплектованный МЛ должен пропасть из списка МЛ, ожидающих комплектацию.
  *      Т.к. детали со склада были списаны, то информация о МЛ, которые можно укомплектовать, и детальные комплектации
  *      остальных МЛ должны обновиться:
  *      - По идее, здесь для списка нужен перезапрос данных для текущей страниц, НО, в данный момент этот список
  *        не является серверным, т.к., сейчас сделать его таким не получается из-за особенностей сортировки и
  *        необходимости получать дополнительные параметры о том, могут ли МЛ быть укомплектованы. Поэтому в данный
  *        момент тут другая логика. Данные по отображаемым в списке МЛ хранятся в entities, чтобы МЛ пропал из списка
  *        нужно удалить его из entities. Когда таблица станет серверной надо будет скорректировать эту обработку.
  *      - Перезапрашиваем МЛ, которые могут быть укомплектованы, эти данные перезаписываются в стор, таким образом,
  *        информация в списке обновится
  *      - Комплектация подтверждается на экране детальной комплектации, сразу после этого модальник детальной
  *        комплектации закрывается и информация по детальной комплектации для всех МЛ в store становится неакутальной.
  *        Очищаем это хранилище, чтобы при следующем входе в детальную комплектацию выполнился новый запрос и
  *        информация обновилась
  * 2.2 Раздел "сборочные". При изменении количества ДСЕ на складе могут, они могут стать недоступными для
  * резервирования, поэтому нужно очистить кеш таблицы сборочных МЛ.
  * 2.3 Раздел "сборочные", экран резервирования. При потребления ДСЕ для комплектации меняется количество ДСЕ на
  * складе, поэтмоу нужно удалить из store все данные резервирования, чтобы при следующем перехаоде на этот экран
  * данные обновились.
  *
  * 3. Мастер
  * 3.1 Раздел просмотра заданий Мастера. В списке "заданий, которые нужно сделать" недоступные задания теперь
  *      становятся доступными, их можно взять в работу:
  *      - Аналогично п.1.1., событие не изменяет количество записей в таблице заданий Мастера и, теоретически,
  *      можно было бы в таблице и обновить задания по только что укомплектованному МЛ, но, это  достаточно трудоемко,
  *      т.к., как правило, мы не знаем на клиенте всех операций по МЛ. Также, теряется синхронизация с сервером, т.к.
  *      мы сами начинаем корректировать поля сущностей дополнительно к тому, как это делает сервер. Поэтому пока
  *      решено просто очищать кэш таблиц заданий Мастера. При следующем входе в этот раздел будет выполнен новый
  *      запрос и данные обновятся
  *      - Комлектация МЛ, очевидно, не влияет на таблицу "выполненных заданий"
  * 3.2 Раздел "МЛ приостановленные". Укомплектованный МЛ не влияет на этот раздел, т.к. комплектация выполняется
  * только над МЛ в производстве, а их нет в этом разделе
  *
  * 4. Рабочий.
  * 4.1. Раздел "Выбора подразделений". Укомплектованный МЛ не влияет на этот раздел.
  *
  * 4.2. Раздел "Выбора класса РЦ в подразделении". Укомплектованный МЛ не влияет на этот раздел.
  *
  * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Задания, которые раньше были недоступны, теперь
  *      становятся доступными, их можно взять в работу:
  *      - Аналогично п.1.1., событие не изменяет количество записей в текущих таблицах заданий и, теоретически,
  *      можно было бы пробежаться по всем таблицам и обновить задания по только что укомплектованному МЛ, но, это
  *      достаточно трудоемко и теряется синхронизация с сервером, т.к. мы сами начинаем корректировать поля сущностей
  *      дополнительно к тому, как это делает сервер. Поэтому пока решено просто очищать кэши всех таблиц просмотра
  *      заданий, т.к. не знаем какие из именно затронула комплектация нового МЛ. При следующем входе в эти разделы
  *      будет выполнен новый запрос и данные обновятся
  * */
  handlePartsAndMaterialsForDefaultSheetConsumed: (sheetId, sheetIdentity, allTasksTablesIds) => {
    //Выводим нотификейшен об успешной комлектации МЛ
    sendPartAndMaterialsForDefaultSheetConsumedNotification(sheetIdentity);

    dispatch([
      //1.1, 2.2, 3.1, 4.3
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),

      //2.1 обновление списка МЛ, ожидающих комлпектации и очистка данных детальной комплектации
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      //2.3
      deleteAllAssemblySheetsReserveData(),
    ]);

    //2.1 Перезапрос данных стандартных МЛ, которые можно укомплектовать, чтобы обновить лейблы в списке МЛ,
    //ожидающих комплектации
    dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage());
  },
});


const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    entityBatchId,
    sheetId,
    sheetIdentity,
    detailedDataToConsume,
    allTasksTablesIds,
  } = stateProps;
  const {
    fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
    consumePartsAndMaterialsForDefaultSheet,
    handlePartsAndMaterialsForDefaultSheetConsumed,
  } = dispatchProps;
  return {
    ...ownProps,
    entityBatchId,
    sheetId,
    detailedDataToConsume,
    fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
    onPartsAndMaterialsConsume: () =>
      consumePartsAndMaterialsForDefaultSheet(
        sheetId,
        sheetIdentity,
        entityBatchId,
        detailedDataToConsume,
      )
        .then(() => {
          handlePartsAndMaterialsForDefaultSheetConsumed(sheetId, sheetIdentity, allTasksTablesIds);
          ownProps.closeReviewDialog();
        }),
  };
};

export const DefaultSheetPartsAndMaterialsConsumingContentContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  asyncComponent({
    resolve: [
      {
        propsToObserve: ['sheetId'],
        fn: ({ fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet, sheetId, entityBatchId }) => {
          if(!sheetId || !entityBatchId)
            return Promise.resolve();

          return fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet(entityBatchId, sheetId);
        },
      },
    ],
  }),
  withPermissionsManager,
)(DefaultSheetPartsAndMaterialsConsumingContent);

DefaultSheetPartsAndMaterialsConsumingContentContainer.displayName = 'DefaultSheetPartsAndMaterialsConsumingContentContainer';