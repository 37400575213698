import { Trans } from '@lingui/macro';
import React from 'react';
import { NOTIFICATION_LEVEL, sendNotification } from '../../../constants/notification';
import {
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  SHEET_OPERATION_FEATURE_SCOPE_MODEL,
  SHEET_OPERATION_FEATURES_MODEL,
} from '../../../constants/models';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import { push } from 'connected-react-router';
import {
  ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE, ADMIN_APP_DISABLED_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
  ADMIN_APP_SHEET_OPERATION_FEATURES_ACTIVE_STATE_ROUTE_PARAMS_MAP,
} from '../../../constants/routes';
import { isIdInUrlParamsValid } from '../../../utils/url';
import { Redirect } from 'react-router-dom';
import { AllLabelTrans, DeleteLabelTrans, NoChangesOnFormLabelTrans } from '../../../utils/commonTransComponents';
import { TOOLS_COLUMN_NAME } from '../../../constants/table';
import { deepPlainCopy } from '@bfg-frontend/utils/lib/deepPlainCopy';
import { fetchSheetOperationFeatureData } from '../../../operations/sheetOperationFeature';
import _isNil from 'lodash/isNil';

export const CreatingNewSheetOperationFeatureModalTitleTrans = (
  <Trans id="sheet_operation_settings.sheet_operation_features@creating_featrue">
    Создание новой характеристики
  </Trans>
);

export const EditingSheetOperationFeatureModalTitleTrans = (
  <Trans id="sheet_operation_settings.sheet_operation_features@editing_feature">
    Редактирование характеристики
  </Trans>
);

/*
Для строки таблицы областей действия характеристик используется комбинированный идентификатор
deaprmentId_equipmentClassId_operationId. Если ни один из идентификаторов не задан, то получим "__" - идентификатор,
который обозначает что область действия - все операции системы. Тут сформирована эта константа отдельно для ясности и
из за того, что линтер форматирует код вида someObject['__'] в someObject.__ - выглядит не очень понятно.
*/
export const ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID = ['', '', ''].join('_');

export const getCombinedSheetOperationFeatureScopeId = (
  departmentId,
  equipmentClassId,
  operationId,
) => [departmentId, equipmentClassId, operationId].map(id => id ? id : '').join('_');

export const parseCombinedSheetOperationFeatureScopeId = combinedId =>
  combinedId.split('_');

export const isSheetOperationFeatureScopeUniqValidator = (combinedScopeId, featuresScopesById) => {

  /*
  Если добавляется область действия, которая действует на все операции, то сразу возвращаем undefined, обработка
  этого кейса сделана в getFeatureScopeChildrenIds
  */
  if (combinedScopeId === ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID) return undefined;

  /*
  Если идентификатор области действия равен ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID, это значит, что область характеристика
  уже назначена на все операции, новые добавлять нет смысла
  */
  if (featuresScopesById[ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID]) {
    return <Trans id="sheet_operation_settings.sheet_operation_feature_scope_form@all_operations_scope_error">
      Характеристика уже была назначена на все операции в системе. Если этого не требуется, то удалите соответствующую
      область действия из таблицы
    </Trans>;
  }


  /*
  Если в scopesIdsMap есть ключ с комбинированным идентификатором подразделения, класса РЦ и операции, для которой
  добавляется новая область действия - это значит, что характеристика уже назначена на эту операцию, новые добавлять
  нет смысла.
  */
  if (featuresScopesById[combinedScopeId]) {
    return <Trans id="sheet_operation_settings.sheet_operation_feature_scope_form@same_feature_scope_error">
      Характеристика уже назначена на выбранную область действия
    </Trans>;
  }

  /*
  combinedScopeId - комбинированный идентификатор вида "departmentId_equipmentClassId_operationId". При этом, если
  не выбраны класс РЦ и операция, то в идентификаторе будет departmentId__, потому что отсутствующие идентификаторы
  заменяются пустой строкой. Тут получаем идентификаторы подразделения и класса РЦ и используем их для проверки на
   наличие области действия в данных таблицы.
  */
  const [departmentId, equipmentClassId, operationId] = parseCombinedSheetOperationFeatureScopeId(combinedScopeId);

  /*
  Если не указан equipmentClassId - значит область действия добавляется только для подразделения, предыдущая проверка
  не сработала - значит этого подразделения еще нет в scopesIdsMap. Возвращаем undefined, дальше выполнять проверки
  смысла нет.
  */
  if (!equipmentClassId) return undefined;

  /*
  Если в scopesIdsMap есть ключ с идентификатором подразделения, для которого добавляется новая область действия -
  это значит, что характеристика уже назначена на все классы РЦ и операции в этом подразделении, новые добавлять нет
  смысла.
  */
  if (featuresScopesById[ [departmentId, '', ''].join('_') ]) {
    return <Trans id="sheet_operation_settings.sheet_operation_feature_scope_form@same_department_error">
      Характеристика уже была назначена на все операции всех классов РЦ выбранного подразделения. Если этого не
      требуется, то удалите соответствующую область действия из таблицы
    </Trans>;
  }

  /*
  Если не указан operationId - значит область действия добавляется только для подразделения и класса РЦ. Предыдущая
  проверка не сработала - значит для этого подразделения область видимости еще не назначена. Проверка на полное
  дублирование идентификатора не сработала - значит в scopesIdsMap еше нет области с этим подразделением и классом РЦ.
  */
  if (!operationId) return undefined;

  /*
  Если в featuresScopesById есть ключ с комбинированным идентификатором подразделения и класса РЦ, для которого
  добавляется новая область действия - это значит, что характеристика уже назначена на все операции для этого класса РЦ,
   новыe добавлять нет смысла.
  */
  if (featuresScopesById[ getCombinedSheetOperationFeatureScopeId(departmentId, equipmentClassId, '') ]) {
    return <Trans id="sheet_operation_settings.sheet_operation_feature_scope_form@same_equipment_class_error">
      Область действия для выбранного класса РЦ уже назначена, это означает, что характеристика будет
      действовать на все операции этого подразделения. Если вы хотите назначить характеристику только
      для некоторых операций, то удалите из таблицы область действия для выбранного класса РЦ, а затем
      создайте новую область действия для операций
    </Trans>;
  }

  /*
  Просто для удобства чтения явно возвращаем undefined, если нет ошибок валидации
  */
  return undefined;
};

export const getFeatureScopeChildrenIds = (combinedScopeId, possibleChildrenFeatureScopesIds) => {

  /*
  Если добавляется область действия, которая действует на все операции в системе, то возвращаем все идентификаторы
  областей действия, потому что для ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID любая область дочерняя
  */
  if (combinedScopeId === ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID && possibleChildrenFeatureScopesIds.length) {
    return possibleChildrenFeatureScopesIds;
  }

  const [departmentId, equipmentClassId, operationId] = parseCombinedSheetOperationFeatureScopeId(combinedScopeId);

  /*
  У области действия, в которой есть идентификатор операции, не может быть дочерних областей действия, поэтому
  сразу возвращаем []
  */
  if (operationId) return [];

  /*
  Если указан идентификатор класса РЦ, то нужно вычислить, все дочерние области для этого класса РЦ.
  Дочерними для класса РЦ могут быть только области с идентификатором операции. Создаём регэксп, которому
  будут соответствовать только идентификаторы с этим же подразделением, классом РЦ и любой операцией.
  */
  if (equipmentClassId) {
    const equipmentClassChildrenRegexp = new RegExp(`^${departmentId}_${equipmentClassId}_\\d+$`);

    return possibleChildrenFeatureScopesIds.filter(combinedId => equipmentClassChildrenRegexp.test(combinedId));
  }

  /*
  Если указан только идентификатор подразделения, то нужно вычислить все дочерние области действия для этого
  подразделения. Дочерними областями подразделения могут быть только области с идентификатором класса РЦ и с или
  без идентификатора операции.
  */
  const departmentChildrenRegExp = new RegExp(`^${departmentId}_\\d+_\\d*$`);

  return possibleChildrenFeatureScopesIds.filter(combinedId => departmentChildrenRegExp.test(combinedId));
};

const sheetOperationFeatureScopesCustomCellRenderersProps = {
  noValueLabel: AllLabelTrans,
};

export const getSheetOperationFeatureScopesTableCustomCellRenderersProps = deleteFeatureScope => ({
  departmentIdentity: sheetOperationFeatureScopesCustomCellRenderersProps,
  departmentName: sheetOperationFeatureScopesCustomCellRenderersProps,
  equipmentClassIdentity: sheetOperationFeatureScopesCustomCellRenderersProps,
  equipmentClassName: sheetOperationFeatureScopesCustomCellRenderersProps,
  operationIdentity: sheetOperationFeatureScopesCustomCellRenderersProps,
  operationNumber: sheetOperationFeatureScopesCustomCellRenderersProps,
  operationName: sheetOperationFeatureScopesCustomCellRenderersProps,
  [TOOLS_COLUMN_NAME]: {
    toolsOptions: [
      {
        id: 'delete-option',
        title: DeleteLabelTrans,
        onClick: deleteFeatureScope,
      },
    ],
  },
});

const SHEET_OPERATION_FEATURE_CREATE_FORM_INITIAL_STATE = {
  featureName: '',
  featureScopesById: {
    [ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID]: {
      [DEPARTMENT_MODEL]: { id: null },
      [EQUIPMENT_CLASS_MODEL]: { id: null },
      [OPERATION_MODEL]: { id: null },
    },
  },
};

export const initializeSheetOperationFeatureReviewData = ({
  featureId,
  setFeatureName,
  setFeatureScopesById,
  setInitialValues,
  isCreateMode,
  activeState,
}) =>
  dispatch => {

    if (isCreateMode && activeState === ADMIN_APP_SHEET_OPERATION_FEATURES_ACTIVE_STATE_ROUTE_PARAMS_MAP.DISABLED) {
      return <Redirect to={ADMIN_APP_DISABLED_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE}/>;
    }

    const isFeatureIdValid = isCreateMode || isIdInUrlParamsValid(featureId);

    if (!isFeatureIdValid) {
      return <Redirect to={ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE}/>;
    }

    if (isCreateMode) {
      setFeatureScopesById(deepPlainCopy(SHEET_OPERATION_FEATURE_CREATE_FORM_INITIAL_STATE.featureScopesById));
      return setInitialValues(deepPlainCopy(SHEET_OPERATION_FEATURE_CREATE_FORM_INITIAL_STATE));
    }

    dispatch(fetchSheetOperationFeatureData(featureId))
      .then(response => {


        const {
          entities = {},
          responseEntitiesIds = {},
          responseMeta,
        } = response;

        // если в БД нет характеристики с переданным идентификатором, то возвращаемся к таблице активных характеристик
        if (responseMeta.count === 0) {
          sendNotification(
            <Trans id="sheet_operation_settings.sheet_operation_features@feature_with_specified_id_not_found">
              Характеристика с указанным идентификатором не найдена
            </Trans>,
            NOTIFICATION_LEVEL.ERROR,
          );
          return dispatch(push(ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE));
        }

        const sheetOperationFeatureEntity = _get(entities, [SHEET_OPERATION_FEATURES_MODEL, featureId]);
        const departmentEntities = entities[DEPARTMENT_MODEL];
        const equipmentClassEntities = entities[EQUIPMENT_CLASS_MODEL];
        const operationEntities = entities[OPERATION_MODEL];
        const sheetOperationFeatureScopesIds = _get(responseEntitiesIds, SHEET_OPERATION_FEATURE_SCOPE_MODEL, []);

        const featureName = _get(sheetOperationFeatureEntity, ['name']);

        const sheetOperationFeatureScopes = sheetOperationFeatureScopesIds
          .map(sheetOperationFeatureScopeIdFromDb => {
            const {
              departmentId,
              equipmentClassId,
              operationId,
            } = _get(entities, [SHEET_OPERATION_FEATURE_SCOPE_MODEL, sheetOperationFeatureScopeIdFromDb], {});


            return {
              id: getCombinedSheetOperationFeatureScopeId(departmentId, equipmentClassId, operationId),
              sheetOperationFeatureScopeIdFromDb,
              [DEPARTMENT_MODEL]: _get(departmentEntities, [departmentId], {}),
              [EQUIPMENT_CLASS_MODEL]: _get(equipmentClassEntities, [equipmentClassId], {}),
              [OPERATION_MODEL]: _get(operationEntities, [operationId], {}),
            };
          });

        const featureScopesById = _keyBy(sheetOperationFeatureScopes, 'id');

        setFeatureName(featureName);

        setFeatureScopesById(featureScopesById);

        setInitialValues({
          featureName,
          featureScopesById: deepPlainCopy(featureScopesById),
        });
      });
  };


export const getFeatureScopeDataForSubmit = featureScope => {
  const {
    [DEPARTMENT_MODEL]: { id: departmentId },
    [EQUIPMENT_CLASS_MODEL]: { id: equipmentClassId },
    [OPERATION_MODEL]: { id: operationId },
  } = featureScope;

  const finalFeatureScopeData = {};

  if (!_isNil(departmentId)) {
    finalFeatureScopeData.departmentId = departmentId;
  }

  if (!_isNil(equipmentClassId)) {
    finalFeatureScopeData.equipmentClassId = equipmentClassId;
  }

  if (!_isNil(operationId)) {
    finalFeatureScopeData.operationId = operationId;
  }

  return finalFeatureScopeData;
};

export const SHEET_OPERATION_FEATURES_SCOPES_TABLE_MENU_OPTIONS = {
  disableSummaryOption: true,
};

export const SHEET_OPERATION_FEATURE_REVIEW_SCREEN_HELP_ALERT_COLLAPSIBLE_ID =
  'SHEET_OPERATION_FEATURE_REVIEW_SCREEN_HELP_ALERT_COLLAPSIBLE_ID';

export const sendNoChangesOnFormNotification = () => sendNotification(
  NoChangesOnFormLabelTrans,
  NOTIFICATION_LEVEL.INFO,
);
