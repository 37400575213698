import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { matchPath } from 'react-router-dom';
import {
  PLANNER_APP_ORDERS_COMPLETED_ROUTE,
} from '../../../../constants/routes';
import { clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { ORDER_TYPE } from '../../../../constants/orders';
import { fetchOrderRemoteTableDataCbFactory } from '../../../../components/Orders/OrdersContainer';
import { fetchCompletedOrders } from '../../../../operations/orders';

const PLAN_AND_PRODUCTION_STATE_ACTUALIZATION_PROCESS_STATUS = {
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};


export const handlePlanAndProductionStateActualization = msg =>
  (dispatch, getState) => {

    const {
      act,
    } = msg;

    if(act !== PLAN_AND_PRODUCTION_STATE_ACTUALIZATION_PROCESS_STATUS.COMPLETED) return;

    const currentPathname = routerPathnameSelector(getState());

    //Если находимся в разделе "Плановик. Завершенные заказы"
    const completedOrdersRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_COMPLETED_ROUTE,
    });
    if(completedOrdersRouteMatch !== null)
      return dispatch(_updateIfOnCompletedOrdersScreen());

    // для всех остальных экранов сбрасываем кэш таблицы завершенных заказов
    return dispatch(
      clearTableRemoteData([ORDER_TYPE.COMPLETED]),
    );
  };


const _updateIfOnCompletedOrdersScreen = () =>
  dispatch =>
    // перезапрашиваем данные таблицы завершенных заказов
     dispatch(reFetchRemoteTableData(
      ORDER_TYPE.COMPLETED,
      ORDER_TYPE.COMPLETED,
      fetchOrderRemoteTableDataCbFactory(dispatch, fetchCompletedOrders),
    ));
