import React, { Component } from 'react';

import {
  PLANNER_APP_MAIN_ROUTE,
} from '../../constants/routes';

import { AppTypeTopBarContainer } from '../AppTypeTopBar/AppTypeTopBarContainer';

import { Route } from 'react-router-dom';

import { PlannerWorkspaceContainer } from './PlannerWorkspace/PlannerWorkspaceContainer';
import { PlannerLabelTrans } from '../../utils/commonTransComponents';


export class PlannerApp extends Component {

  render() {
    return(
      <div className="planner-app">
        <AppTypeTopBarContainer
            className="planner-app__main-app-bar"
            appTitle={PlannerLabelTrans}
        />
        <Route
            path={[PLANNER_APP_MAIN_ROUTE, ':viewMode'].join('/')}
            component={PlannerWorkspaceContainer}
        />
      </div>
    );
  }
}
