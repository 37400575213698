import _size from 'lodash/size';
import React, { useCallback, useState } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { _getRenderDialogActionsWithTwoConfirmOptionsCallback } from '../../../common/SimpleConfirmDialog/constants';
import {
  ORDER_ENTRIES_TABLE_DATA_TYPE,
  OrderEntriesTable,
} from '../../OrderEntriesTable/OrderEntriesTable';
import { FUNC_IS_REQUIRED_TYPE, OBJECT_OF_ANY_TYPE } from '../../../../constants/propTypes';
import Button from '@mui/material/Button';
import { SimpleConfirmDialog } from '../../../common/SimpleConfirmDialog/SimpleConfirmDialog';
import { CancelLabelTrans, CompleteOrderLabelTrans } from '../../../../utils/commonTransComponents';
import { SimpleSummary } from '../../../common/SimpleSummary/SimpleSummary';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';

import './style.css';
import {
  ORDER_PRIMARY_CONTENT_SCHEMA_WITH_ORDER__NAME_LABEL,
  ORDER_SECONDARY_CONTENT_SCHEMA,
} from '../../../Orders/constants';

const CompleteOrderConfirmButtonTrans = <Trans id="orders_ready_to_complete.complete_order@confirm_complete_button">
  Завершить
</Trans>;

const CompleteOrderConfirmTextTrans = <Trans id="orders_ready_to_complete.complete_order@confirm_complete_message">
  Вы уверены, что хотите завершить выбранный заказ?
</Trans>;

const _getCompleteOrderWithNeedlessBatchesConfirmTextTrans = orderWarehouseNeedlessEntityBatchesAmount => (
  <Trans id="orders_ready_to_complete.complete_order@with_needless_batches_confirm_complete_message">
    На складе есть партии, которые относятся к выбранному заказу, но не нужны для его завершения (количество таких партий: {orderWarehouseNeedlessEntityBatchesAmount}).
    Выберите действие, которое требуется выполнить с этими партиями при завершении заказа
  </Trans>
);

const CompleteOrderAndWriteOffNeedlessBatchesConfirmTextTrans = <Trans id="orders_ready_to_complete.complete_order@complete_and_write_off_needless_batches_confirm_message">
  Завершить и списать ненужные партии
</Trans>;

const CompleteOrderAndUntieNeedlessBatchesConfirmTextTrans = <Trans id="orders_ready_to_complete.complete_order@complete_and_untie_needless_batches_confirm_message">
  Завершить и отвязать ненужные партии от заказа
</Trans>;

export const OrdersReadyToCompleteReviewContent = props => {
  const {
    orderEntriesTableId,
    orderEntriesTableData,
    fetchOrderEntriesTableData,
    entityToReviewData,
    completeOrder,
    checkOrderWarehouseNeedlessEntityBatches,
  } = props;

  const [isCompleteOrderModalOpen, setIsCompleteOrderModalOpen] = useState(false);
  const [orderWarehouseNeedlessEntityBatches, setOrderWarehouseNeedlessEntityBatches] = useState(null);

  const handleCompleteOrderButtonClick = useCallback(() => {

    if (orderWarehouseNeedlessEntityBatches !== null) return setIsCompleteOrderModalOpen(true);

    checkOrderWarehouseNeedlessEntityBatches()
      .then(orderWarehouseNeedlessEntityBatchesResponse => {
        setOrderWarehouseNeedlessEntityBatches(orderWarehouseNeedlessEntityBatchesResponse);
        setIsCompleteOrderModalOpen(true);
      });
  }, [checkOrderWarehouseNeedlessEntityBatches, orderWarehouseNeedlessEntityBatches]);

  return (
    <div className="orders-ready-to-complete-review-content">
      {_renderCompleteOrderButton(handleCompleteOrderButtonClick)}
      {_renderOrderEntriesTable(orderEntriesTableId, orderEntriesTableData, fetchOrderEntriesTableData)}
      {_renderCompleteOrderModal(
        isCompleteOrderModalOpen,
        setIsCompleteOrderModalOpen,
        entityToReviewData,
        completeOrder,
        orderWarehouseNeedlessEntityBatches,
      )}
    </div>
  );
};

const _renderOrderEntriesTable = (orderEntriesTableId, orderEntriesTableData, fetchOrderEntriesTableData) => (
  <OrderEntriesTable
      orderEntriesTableId={orderEntriesTableId}
      orderEntriesTableData={orderEntriesTableData}
      fetchOrderEntriesTableData={fetchOrderEntriesTableData}
  />
);

const _renderCompleteOrderButton = setIsCompleteOrderModalOpen =>
  <div className="orders-ready-to-complete-review-content__complete-order-button">
    <Button
        color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
        variant={MATERIAL_UI_VARIANT.CONTAINED}
        onClick={() => setIsCompleteOrderModalOpen(true)}
    >
      {CompleteOrderLabelTrans}
    </Button>
  </div>;

const COMPLETE_ORDER_MODAL_SUMMARY_SCHEMA = [
  ...ORDER_PRIMARY_CONTENT_SCHEMA_WITH_ORDER__NAME_LABEL,
  ...ORDER_SECONDARY_CONTENT_SCHEMA,
];

const _renderCompleteOrderModal = (
  isCompleteOrderModalOpen,
  setIsCompleteOrderModalOpen,
  entityToReviewData,
  completeOrder,
  orderWarehouseNeedlessEntityBatches,
) => {

  const orderWarehouseNeedlessEntityBatchesAmount = _size(orderWarehouseNeedlessEntityBatches);
  const orderWarehouseHasNeedlessEntityBatches = !!orderWarehouseNeedlessEntityBatchesAmount;

  const getRenderDialogActionsCb = () => {
    if (orderWarehouseHasNeedlessEntityBatches) {
      return _getRenderDialogActionsWithTwoConfirmOptionsCallback({
        cancelBtn: CancelLabelTrans,
        onCancelBtnClick: () => setIsCompleteOrderModalOpen(false),
        primaryConfirmBtn: CompleteOrderAndWriteOffNeedlessBatchesConfirmTextTrans,
        onPrimaryConfirmBtnClick: () => completeOrder(true),
        secondaryConfirmBtn: CompleteOrderAndUntieNeedlessBatchesConfirmTextTrans,
        onSecondaryConfirmBtnClick: () => completeOrder(),
      });
    }

    return _getRenderDialogActionsWithTwoConfirmOptionsCallback({
      cancelBtn: CancelLabelTrans,
      onCancelBtnClick: () => setIsCompleteOrderModalOpen(false),
      primaryConfirmBtn: CompleteOrderConfirmButtonTrans,
      onPrimaryConfirmBtnClick: () => completeOrder(),
    });
  };

  return (
    <SimpleConfirmDialog
        className="orders-ready-to-complete-review-content__complete-order-modal"
        closeDialog={() => setIsCompleteOrderModalOpen(false)}
        isOpen={isCompleteOrderModalOpen}
        title={CompleteOrderLabelTrans}
        confirmText={
          orderWarehouseHasNeedlessEntityBatches ?
            _getCompleteOrderWithNeedlessBatchesConfirmTextTrans(orderWarehouseNeedlessEntityBatchesAmount) :
            CompleteOrderConfirmTextTrans
        }
        confirmTextChipClassName={orderWarehouseHasNeedlessEntityBatches ?
          'orders-ready-to-complete-review-content__confirm-text--warning' : ''}
        additionalComponent={(
          <SimpleSummary
              summaryData={entityToReviewData}
              secondarySummarySchema={COMPLETE_ORDER_MODAL_SUMMARY_SCHEMA}
          />
        )}
        renderDialogActions={getRenderDialogActionsCb()}
    />
  );
};

OrdersReadyToCompleteReviewContent.propTypes = {
  orderEntriesTableId: PropTypes.string.isRequired,
  orderEntriesTableData: ORDER_ENTRIES_TABLE_DATA_TYPE,
  fetchOrderEntriesTableData: PropTypes.func.isRequired,
  entityToReviewData: OBJECT_OF_ANY_TYPE,
  completeOrder: FUNC_IS_REQUIRED_TYPE,
  checkOrderWarehouseNeedlessEntityBatches: FUNC_IS_REQUIRED_TYPE,
};