import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import React from 'react';
import { MATERIAL_UI_SIZE, MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';

export const _getRenderDialogActionsWithTwoConfirmOptionsCallback = props => {
  /* eslint-disable react/prop-types */
  const {
    cancelBtn,
    onCancelBtnClick,
    secondaryConfirmBtn,
    onSecondaryConfirmBtnClick,
    primaryConfirmBtn,
    onPrimaryConfirmBtnClick,
  } = props;
  /* eslint-enable react/prop-types */

  return () => (

    <DialogActions className="simple-confirm-dialog__buttons-panel">
      <Button
          className="simple-confirm-dialog__cancel-button"
          onClick={onCancelBtnClick}
          color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
          size={MATERIAL_UI_SIZE.MEDIUM}
      >
        { cancelBtn }
      </Button>

      {
        !!secondaryConfirmBtn ?

          <Button
              className="simple-confirm-dialog__confirm-button--default"
              color={MATERIAL_UI_STYLE_COLOR.INHERIT}
              size={MATERIAL_UI_SIZE.MEDIUM}
              onClick={onSecondaryConfirmBtnClick}
          >
            { secondaryConfirmBtn }
          </Button> :

          null
      }

      {
        !!primaryConfirmBtn ?

          <Button
              className="simple-confirm-dialog__confirm-button"
              color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
              size={MATERIAL_UI_SIZE.MEDIUM}
              onClick={onPrimaryConfirmBtnClick}
          >
            { primaryConfirmBtn }
          </Button> :

          null
      }
    </DialogActions>
  );
};
