import {
  isSheetsToStartDataAlreadyInStoreSelector, isAssemblySheetsToStartDataAlreadyInStoreSelector,
  isSheetToStartOperationsDataAlreadyInStoreSelector,
} from './selectors';

import { fetchDataFromServerDataPoint, sendActionToServer } from '../../../api';
import { fetchEntitiesFromIa } from '../../entities/actions';
import { SERVER_ACTION_POINT } from '../../../constants/serverActions';

import {
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  SIMULATION_ENTITY_BATCH_MODEL,
  SIMULATION_OPERATION_TASK_MODEL,
  SIMULATION_ORDER_ENTITY_BATCH_MODEL,
} from '../../../constants/models';
import { SERVER_DATA_POINT } from '../../../constants/serverDataPoints';
import { FILTER_GROUP_TYPES, FILTER_TYPES, WITH_PARAMS } from '../../../api/restCollectionApi';

import _keyBy from 'lodash/keyBy';
import { TASK_TYPES } from '../../../constants/tasks';
import { getErrorMessage, showError } from '../../../api/requestHandlers/errorHandlers/errorHandlers';
import { broadcastEventMessage } from '../../../api/socketApi/broadcastApi/broadcast/broadcastEventMessage';
import { SHEET_STARTED_EVENT_TYPE } from '../../../constants/sockets';
import { SHEET_TO_START_ERRORS } from '../../../api/requestHandlers/errorHandlers/errorMaps';
import { CommonServerErrorLabelTrans } from '../../../utils/commonTransComponents';


export const SET_SELECTED_SHEETS_TO_START_TYPE = 'SET_SELECTED_SHEETS_TO_START_TYPE';
export const SAVE_SHEETS_TO_START_DATA = 'SAVE_SHEETS_TO_START_DATA';
export const SAVE_ASSEMBLY_SHEETS_TO_START_DATA = 'SAVE_ASSEMBLY_SHEETS_TO_START_DATA';
export const SAVE_SHEET_TO_START_OPERATIONS_DATA = 'SAVE_SHEET_TO_START_OPERATIONS_DATA';
export const REMOVE_SHEET_TO_START = 'REMOVE_SHEET_TO_START';

const SHEET_TO_START_EMPTY_RESPONSE_ERROR = 'EMPTY_RESPONSE';
const SHEET_TO_START_NO_PRIMARY_SESSION_RESPONSE_ERROR = 'NO_PRIMARY_SESSION';
const IA_ERROR_SOURCE = 'IA';

export const setSelectedSheetsToStartType = selectedType => ({
  type: SET_SELECTED_SHEETS_TO_START_TYPE,
  selectedType,
});

export const saveSheetsToStartData = (
  mainPlanningSessionId,
  startDate,
  stopDate,
  dataEntities,
) => ({
  type: SAVE_SHEETS_TO_START_DATA,
  mainPlanningSessionId,
  startDate,
  stopDate,
  dataEntities,
});

export const saveAssemblySheetsToStartData = (
  mainPlanningSessionId,
  dataEntities,
) => ({
  type: SAVE_ASSEMBLY_SHEETS_TO_START_DATA,
  mainPlanningSessionId,
  dataEntities,
});

export const saveSheetToStartOperationsData = (
  mainPlanningSessionId,
  entityBatchFromIaId,
  dataEntities,
) => ({
  type: SAVE_SHEET_TO_START_OPERATIONS_DATA,
  mainPlanningSessionId,
  entityBatchFromIaId,
  dataEntities,
});

export const removeSheetToStart = entityBatchFromIaId => ({
  type: REMOVE_SHEET_TO_START,
  entityBatchFromIaId,
});

const START_SHEET_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const startSheet = (entityBatchFromIaId, entityBatchFromIaIdentity, sheetIdentity) =>
  dispatch =>
    dispatch(sendSheetStartActionOnServer(sheetIdentity, entityBatchFromIaIdentity, START_SHEET_DEFAULT_REQUEST_OPTIONS))
      .then(
        ({ data: { entityRouteSheetId: sheetId, entityBatchId } }) => {

          broadcastEventMessage(
            SHEET_STARTED_EVENT_TYPE,
            { entityBatchFromIaId, entityBatchId, sheetId, sheetIdentity },
          );
        },
        ({ response, status }) => {
          if(!response) return Promise.reject(status);

          const errorMsg = getErrorMessage(response, SHEET_TO_START_ERRORS);
          showError(errorMsg);
          return Promise.reject({ response, status });
        },
      );

export const sendSheetStartActionOnServer = (sheetIdentity, entityBatchFromIaIdentity, options) =>
  dispatch => dispatch(sendActionToServer(
    SERVER_ACTION_POINT.START_SHEET,
    {
      data: {
        entityBatchIdentity: entityBatchFromIaIdentity,
        entityRouteSheetIdentity: sheetIdentity,
      },
    },
    { ...options },
  ));

export const fetchSheetsToStart = (
  mainPlanningSessionId,
  startDate,
  stopDate,
) =>
  (dispatch, getState) => {

    //Если нет сессии для запроса, значит никаких данных в этот момент нет, партии для запуска не сформированы
    if(!mainPlanningSessionId) return Promise.resolve();

    const isSheetsToStartDataAlreadyInStore =
      isSheetsToStartDataAlreadyInStoreSelector(getState(), { mainPlanningSessionId, startDate, stopDate });

    if(isSheetsToStartDataAlreadyInStore) return Promise.resolve();

    return dispatch(fetchDataFromServerDataPoint(
      SERVER_DATA_POINT.SHEETS_TO_START,
      {
        lowerHorizonDate: startDate,
        upperHorizonDate: stopDate,
      },

      /*
      * отключаем стандартные уведомления об ошибках, т.к. для этой точки требуется кастомная обработка кодов
      * ошибок - SHEET_TO_START_EMPTY_RESPONSE_ERROR и SHEET_TO_START_NO_PRIMARY_SESSION_RESPONSE_ERROR
      * */
      { showServerError: false },
    ))
      .then(
        ({ data: sheetsToStartData }) =>
          dispatch(saveSheetsToStartData(
            mainPlanningSessionId,
            startDate,
            stopDate,
            _keyBy(sheetsToStartData, 'entityBatchId'),
          )),
      )
      .catch(
        (({ response, status }) => {
          if(!response) return Promise.reject(status);

          const {
            source,
            identity,
          } = response;

          /*
          * для ошибок SHEET_TO_START_EMPTY_RESPONSE_ERROR и SHEET_TO_START_NO_PRIMARY_SESSION_RESPONSE_ERROR резолвим
          * promise для asyncComponent, т.к. для клиентского приложения эти ответы не являются ошибками
          * */
          if(
            source === IA_ERROR_SOURCE &&
            (
              identity === SHEET_TO_START_EMPTY_RESPONSE_ERROR ||
              identity === SHEET_TO_START_NO_PRIMARY_SESSION_RESPONSE_ERROR
            )
          ) {

            // стираем из стор потенциальные данные по партиям на запуск предыдущего основного расчета
            dispatch(saveSheetsToStartData(mainPlanningSessionId, startDate, stopDate, {}));
            return Promise.resolve();
          }

          /* для всех остальных кодов ошибок реализуем стандартную обработку */
          showError(CommonServerErrorLabelTrans);
          return Promise.reject(response);
        }),
      );
  };

export const fetchAssemblySheetsToStart = mainPlanningSessionId =>
  (dispatch, getState) => {

    //Если нет сессии для запроса, значит никаких данных в этот момент нет, партии для запуска не сформированы
    if(!mainPlanningSessionId) return Promise.resolve();

    const isAssemblySheetsToStartDataAlreadyInStore =
      isAssemblySheetsToStartDataAlreadyInStoreSelector(getState(), { mainPlanningSessionId });

    if(isAssemblySheetsToStartDataAlreadyInStore) return Promise.resolve();

    return dispatch(fetchDataFromServerDataPoint(
      SERVER_DATA_POINT.ASSEMBLY_SHEETS_TO_START,
      {},

      /*
      * отключаем стандартные уведомления об ошибках, т.к. для этой точки требуется кастомная обработка кодов
      * ошибок
      * */
      { showServerError: false },
    ))
      .then(
        ({ data: sheetsToStartData }) =>
          dispatch(saveAssemblySheetsToStartData(
            mainPlanningSessionId,
            _keyBy(sheetsToStartData, 'entityBatchId'),
          )),
      )
      .catch(
        (({ response, status }) => {
          if(!response) return Promise.reject(status);

          const {
            source,
            identity,
          } = response;

          /*
          * для ошибок SHEET_TO_START_EMPTY_RESPONSE_ERROR и SHEET_TO_START_NO_PRIMARY_SESSION_RESPONSE_ERROR резолвим
          * promise для asyncComponent, т.к. для клиентского приложения эти ответы не являются ошибками
          * */
          if(
            source === IA_ERROR_SOURCE &&
            (
              identity === SHEET_TO_START_EMPTY_RESPONSE_ERROR ||
              identity === SHEET_TO_START_NO_PRIMARY_SESSION_RESPONSE_ERROR
            )
          ) {

            // стираем из стор потенциальные данные по партиям на запуск предыдущего основного расчета
            dispatch(saveAssemblySheetsToStartData(mainPlanningSessionId, {}));
            return Promise.resolve();
          }

          /* для всех остальных кодов ошибок реализуем стандартную обработку */
          showError(CommonServerErrorLabelTrans);
          return Promise.reject(response);
        }),
      );
  };

const SHEET_TO_START_OPERATIONS_REQUEST_FROM_IA_WITH_PARAMS = [
  OPERATION_MODEL,
  SIMULATION_ENTITY_BATCH_MODEL,
  EQUIPMENT_CLASS_MODEL,
  DEPARTMENT_MODEL,
  {
    column: SIMULATION_ORDER_ENTITY_BATCH_MODEL,
    params: [
      { key: WITH_PARAMS.STRICT, value: false },
    ],
  },
  ORDER_MODEL,
];
const SHEET_TO_START_OPERATIONS_REQUEST_FROM_IA_MODEL_RELATIONS = {
  [OPERATION_MODEL]: {
    level: 1,
  },
  [SIMULATION_ENTITY_BATCH_MODEL]: {
    level: 1,
  },
  [EQUIPMENT_CLASS_MODEL]: {
    level: 2,
    relates: OPERATION_MODEL,
  },
  [DEPARTMENT_MODEL]: {
    level: 2,
    relates: OPERATION_MODEL,
  },
  [SIMULATION_ORDER_ENTITY_BATCH_MODEL]: {
    level: 2,
    relates: SIMULATION_ENTITY_BATCH_MODEL,
  },
  [ORDER_MODEL]: {
    level: 3,
    relates: SIMULATION_ORDER_ENTITY_BATCH_MODEL,
  },
};

/*
* Запрос данных из IA для формирования предполагаемых операций запускаемого маршрутного листа.
* Запрашиваются сущности модели simulationOperationTask, которые детально описывают задания и подзадания на партии
* (если задание поделилось). В CA мы не оперируем наладками (по крайней мере, сейчас установлено, что это так), поэтому в
* запросе сразу фильтруем задания по типу (не запрашиваем задания с type 2 - Наладка). Дальнейшая обработка по
* формированию данных для операций МЛ производится в селекторе данных sheetToStartOperationsTableDataSelector (можно бы,
* наверно, было сделать и здесь, только это не очень удобно, т.к. вся стыковка проихводится в селекторе, да и часть
* запрашиваемых данных может пригодиться в store для каких-то других целей)
* */
export const fetchSheetToStartOperationsFromIa = (mainPlanningSessionId, entityBatchFromIaId) =>
  (dispatch, getState) => {

    //Если нет сессии для запроса, значит никаких данных в этот момент нет, партии для запуска не сформированы
    if(!mainPlanningSessionId) return Promise.resolve();

    const isSheetToStartOperationsDataAlreadyInStore =
      isSheetToStartOperationsDataAlreadyInStoreSelector(getState(), { mainPlanningSessionId, entityBatchFromIaId });

    if(isSheetToStartOperationsDataAlreadyInStore) return Promise.resolve();

    const queryParams = {
      with: SHEET_TO_START_OPERATIONS_REQUEST_FROM_IA_WITH_PARAMS,
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'simulationEntityBatch.simulationSessionId',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: mainPlanningSessionId,
          },
          {
            column: 'simulationEntityBatchId',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: entityBatchFromIaId,
          },
          {
            column: 'type',
            filterType: FILTER_TYPES.NOT_EQUALS,
            filterValue: TASK_TYPES.SETUP,
          },
        ],
      },
    };

    return dispatch(fetchEntitiesFromIa(
      SIMULATION_OPERATION_TASK_MODEL,
      queryParams,
      {
        modelRelations: SHEET_TO_START_OPERATIONS_REQUEST_FROM_IA_MODEL_RELATIONS,
      },
    ))
      .then(({ entities }) =>
        dispatch(saveSheetToStartOperationsData(
          mainPlanningSessionId,
          entityBatchFromIaId,
          entities,
        )));
  };