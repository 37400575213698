import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import _partial from 'lodash/partial';

import {
  ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
  FUNC_IS_REQUIRED_TYPE,
  TASK_TABLE_ROW_DATA_TYPE,
} from '../../../constants/propTypes';

import './style.css';
import { Table } from '../../Table/Table';
import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';
import {
  SheetOperationReviewDialogContainer,
} from '../../common/SheetOperationReviewDialog/SheetOperationReviewDialogContainer';
import {
  DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE,
  EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE,
} from '../../../utils/entities';
import {
  areTaskStartOrContinueActionButtonsHidden,
  getTaskCanNotStartOrContinueHelpInfoContent,
  isConsumeEntitiesOnTaskActionHidden,
} from '../../../utils/tasks';
import { CheckBoxFiltersPanel } from '../../common/CheckboxFiltersPanel/CheckboxFiltersPanel';
import { TASKS_VIEW_SCREEN_TABLE_ADDITIONAL_FILTERS_SCHEMA } from './constants';
import { getEntityDataByTemplate } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export class TasksViewScreen extends Component {

  static propTypes = {
    departmentTasksFilter: ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE.isRequired,
    equipmentClassTasksFilter: ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE.isRequired,
    tasksTableId: PropTypes.string.isRequired,
    tasksTableData: PropTypes.arrayOf(TASK_TABLE_ROW_DATA_TYPE),
    fetchTasksRemoteTableData: FUNC_IS_REQUIRED_TYPE,
    handleTaskStatusChanged: FUNC_IS_REQUIRED_TYPE,
    handleSheetOperationDataChanged: FUNC_IS_REQUIRED_TYPE,
    handleSheetFinished: FUNC_IS_REQUIRED_TYPE,
    handleEntityBatchSplit: FUNC_IS_REQUIRED_TYPE,
    handleSheetPaused: FUNC_IS_REQUIRED_TYPE,
    handleDefectiveEntitiesMarked: FUNC_IS_REQUIRED_TYPE,
    getTasksViewScreenTableRowStyle: PropTypes.func,
    tasksViewScreenAdditionalFilters: PropTypes.arrayOf(PropTypes.string),
    setTasksViewScreenAdditionalFilters: FUNC_IS_REQUIRED_TYPE,
    isWorkerTasksFiltersManagingByAdmin: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTaskData: null,
    };
  }

  _renderTableTranslatedNoDataText = () =>
    <Trans id="worker_tasks@no_tasks_for_equipment_class_in_department">
      Для указанного класса РЦ в подразделении нет заданий
    </Trans>;

  _setSelectedTaskData = taskData => this.setState({ selectedTaskData: taskData });

  _handleTaskRowClick = (_, rowData) => {
    const {
      departmentTasksFilter,
      equipmentClassTasksFilter,
    } = this.props;

    this._setSelectedTaskData({
      ...rowData,
      ...getEntityDataByTemplate(departmentTasksFilter, DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE),
      ...getEntityDataByTemplate(equipmentClassTasksFilter, EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE),
    });
  };

  _handleSelectedTaskReviewDialogClose = _partial(this._setSelectedTaskData, null);

  _handleTaskStatusChanged = selectedTaskData => {
    this.props.handleTaskStatusChanged(selectedTaskData);
    this._handleSelectedTaskReviewDialogClose();
  };

  _handleSheetFinished = selectedTaskData => {
    this.props.handleSheetFinished(selectedTaskData);
    this._handleSelectedTaskReviewDialogClose();
  };

  _handleSheetPaused = selectedTaskData => {
    this.props.handleSheetPaused(selectedTaskData);
    this._handleSelectedTaskReviewDialogClose();
  };

  _handleEntityBatchSplit = selectedTaskData => {
    this.props.handleEntityBatchSplit(selectedTaskData);
    this._handleSelectedTaskReviewDialogClose();
  };

  _handleDefectiveEntitiesMarked = data => {
    this.props.handleDefectiveEntitiesMarked(data);
    this._handleSelectedTaskReviewDialogClose();
  };

  _renderAdditionalTableFilters = () => {
    const {
      tasksViewScreenAdditionalFilters,
      setTasksViewScreenAdditionalFilters,
      isWorkerTasksFiltersManagingByAdmin,
    } = this.props;

    if (isWorkerTasksFiltersManagingByAdmin) return null;

    return(
      <CheckBoxFiltersPanel
          className="task-view-screen__additional-table-filters"
          schema={TASKS_VIEW_SCREEN_TABLE_ADDITIONAL_FILTERS_SCHEMA}
          filters={tasksViewScreenAdditionalFilters}
          setFilters={setTasksViewScreenAdditionalFilters}
      />
    );
  }

  _renderTasksTable = () => {
    const {
      tasksTableId,
      tasksTableData,
      fetchTasksRemoteTableData,
      getTasksViewScreenTableRowStyle,
    } = this.props;

    return (
      <Table
          tableId={tasksTableId}
          tableModel={tasksTableId}
          getRowStyle={getTasksViewScreenTableRowStyle}
          fetchRemoteTableData={({ tableParams }) => fetchTasksRemoteTableData({ tableParams })}
          rowsData={tasksTableData}
          noDataContent={this._renderTableTranslatedNoDataText()}
          onRowClick={this._handleTaskRowClick}
          tableMenu={{
            disableMenu: true,
          }}
          customCellRenderersProps={{
            operationStartDate: {
              dateFormat: FORMAT_SHORT_TIME,
            },
            operationStopDate: {
              dateFormat: FORMAT_SHORT_TIME,
            },
          }}
          disableFilter
          disableSort
      />
    );
  };

  _renderSheetOperationReviewDialog = () => {
    const {
      selectedTaskData,
    } = this.state;

    if(selectedTaskData === null) return null;

    const {
      handleSheetOperationDataChanged,
    } = this.props;

    return (
      <SheetOperationReviewDialogContainer
          closeDialog={this._handleSelectedTaskReviewDialogClose}
          sheetOperationData={selectedTaskData}
          handleSheetOperationStatusChanged={this._handleTaskStatusChanged}
          handleSheetOperationDataChanged={handleSheetOperationDataChanged}
          handleSheetFinished={this._handleSheetFinished}
          handleSheetPaused={this._handleSheetPaused}
          handleEntityBatchSplit={this._handleEntityBatchSplit}
          handleDefectiveEntitiesMarked={this._handleDefectiveEntitiesMarked}
          getHelpAlertContent={getTaskCanNotStartOrContinueHelpInfoContent}
          areStatusChangeButtonsHidden={areTaskStartOrContinueActionButtonsHidden}
          isConsumeEntitiesActionHidden={isConsumeEntitiesOnTaskActionHidden}
      />
    );
  }

  render() {
    return(
      <div className="task-view-screen">
        {this._renderAdditionalTableFilters()}
        {this._renderTasksTable()}
        {this._renderSheetOperationReviewDialog()}
      </div>
    );
  }
}