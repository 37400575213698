import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import {
  FUNC_IS_REQUIRED_TYPE,
  OBJECT_OF_ANY_TYPE,
  TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
  TABLE_FILTER_PARAMS_TYPE,
} from '../../../../../constants/propTypes';

import { Filters } from '../../../Filters/Filters';

import _isEmpty from 'lodash/isEmpty';


export class EntitiesReviewTableFiltersComponent extends Component {
  static propTypes = {
    changeFilters: FUNC_IS_REQUIRED_TYPE,
    filterParams: TABLE_FILTER_PARAMS_TYPE,
    customFilters: TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
    titles: PropTypes.shape({
      filtersModalTitle: PropTypes.node,
      applyFiltersBtnTitle: PropTypes.node,
      cancelFiltersBtnTitle: PropTypes.node,
      addFiltersBtnTitle: PropTypes.node,
      removeAllFiltersBtnTitle: PropTypes.node,
    }),
    filteredAndSortedRowsDataForCurrentPage: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
  };

  _getFilterSchema = () => {
    const {
      customFilters = {},
    } = this.props;

    return Object
      .keys(customFilters)
      .map(filterKey => {
        const {
          getFilterTitle,
          getFilterChipContent,
          filterComponent,
          filterComponentProps = {},
        } = customFilters[filterKey] || {};

        return {
          filterKey,
          getFilterTitle,
          getFilterChipContent,
          filterComponent,
          filterComponentProps,
        };
      });
  };

  render() {
    const {
      filterParams,
      changeFilters,
      titles,
      filteredAndSortedRowsDataForCurrentPage,
    } = this.props;

    if(_isEmpty(filterParams) && filteredAndSortedRowsDataForCurrentPage.length === 0) return null;

    return (
      <div className="entities-review-table-filters">
        <Paper className="entities-review-table-filters__paper" elevation={0}>
          <Filters
              withFiltersEditing={false}
              filterSchema={this._getFilterSchema()}
              filters={filterParams}
              onApplyFilters={changeFilters}
              {...titles}
          />
        </Paper>
      </div>
    );
  }
}