import { connect } from 'react-redux';

import { push } from 'connected-react-router';

import {
  departmentTasksFilterSelector,
  departmentTasksFilterTitleSelector,
  equipmentClassTasksFilterSelector,
  equipmentClassTasksFilterTitleSelector,
} from '../../../reducers/workerApp/tasksMainFilters/selectors';

import { WorkerNavigationPanel } from './WorkerNavigationPanel';

import { WORKER_APP_MAIN_ROUTE } from '../../../constants/routes';


const mapStateToProps = state => {
  const {
    id: departmentIdTasksFilter,
  } = departmentTasksFilterSelector(state);
  const {
    id: equipmentClassIdTasksFilter,
  } = equipmentClassTasksFilterSelector(state);
  return {
    departmentIdTasksFilter,
    equipmentClassIdTasksFilter,
    departmentTitle: departmentTasksFilterTitleSelector(state),
    equipmentClassTitle: equipmentClassTasksFilterTitleSelector(state),
  };
};

const mapDispatchToProps = {
  push,
};

const mergeProps = (stateProps, dispatchProps) => {
  const {
    departmentIdTasksFilter,
    equipmentClassIdTasksFilter,
    departmentTitle,
    equipmentClassTitle,
  } = stateProps;

  const {
    push,
  } = dispatchProps;

  const onGoBackButtonClick = !!departmentIdTasksFilter ?
    () => {
      if (!equipmentClassIdTasksFilter) return push(WORKER_APP_MAIN_ROUTE);
      return push([
        WORKER_APP_MAIN_ROUTE,
        departmentIdTasksFilter,
      ].join('/'));
    } :
    undefined;

  return {
    departmentTitle,
    equipmentClassTitle,
    onGoBackButtonClick,
  };
};

export const WorkerNavigationPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(WorkerNavigationPanel);

WorkerNavigationPanelContainer.displayName = 'WorkerNavigationPanelContainer';
