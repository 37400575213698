import { PROD, TEST } from '../../constants/environment';
import throttle from 'lodash/throttle';
import pick from 'lodash/pick';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import size from 'lodash/size';
import set from 'lodash/set';

/**
* StoreEnhancer, который добавляет создаваемому store обработчик сохранения указанных в persistingSchema
* частей в localStorage. А при стартовой инициализации store, эти сохраненные данные из localStorage запишутся
* в него. Для тестового окружения обработка не производится!
* ВАЖНО иметь в виду, что при работе с этим StoreEnhancer (в текущей реализации) не подразумевается, что в финальную
* версию createStore будет добавлен второй параметр initialState полученный ещё откуда-то.
* Этот StoreEnhancer возвращает StoreCreator c предопределенным  initialState из localStorage, а любой передаваемой
* параметр будет проигнорирован. Также, подразумевается, что главный редьюсер должен быть сформирован через combineRеducers.
*
* @param config {Object}
*  - localStorageKey {String} - ключ в localStorage для хранения данных
*  - persistingSchema {Object} - схема описывающая какую часть state сохранять в localStorage. В ключах объекта -
*  ключи state, данные по которым будут храниться в localStorage. В текущей реализации допускается для каждого ключа
*  state указывать ещё конкретные пути paths (в виде массива внутренних ключей, последовательно описывающих
*  вложенность) внутри этого ключа, описывающие любую вложенность внутренних ключей, которые нужно сохранять, если
*  весь state для основного ключа не требуется.
*
*  persistingSchema = {
*
*    [stateKey1]: {
*      //Т.е. в локалсторадж для ключа stateKey1 будут записаны только данные внутренних ключей по указанным путям -
*      //state[stateKey1].a и state[stateKey1].b.c.d. Значение в ключах будет получаться безопасно через _get, но,
*      //очевидно, что за правильной структурой нужно следить, иначе случится провал при восстановлении стора из
*      //локалстораджа
*      paths: [ ['a'], ['b', 'c', 'd'] ],
*
*      //Для корректной работы, для каждого из ключей нужно указывать initialState, который использует при инициализации
*      //соответствующего редьюсера, т.е. если данных в localStorage по каким-то причинам нет, то должен вернуться
*      //нормальный initialState по умолчанию.
*      initialState: {Object}
*
*      //Callback для формирования финального initialState для ключа store, который принимает initialState по умолчанию,
*      //и данные, сохраненные в localStorage для ключа. Определяется, чтобы проделать какие-то кастомные вычисления на
*      //основании описанных данных. Если не определен, то реализация по умолчанию - обычное подмерживание:
*      //getFinalInitialStateForKey: (initialState, persistedState) => ({...initialState, ...persistedState})
*      //ЗАДАВАТЬ ЕГО ТОЧНО НЕОБХОДИМО если сохраняются вложенные ключи, определенные в paths. Иначе
*      //дефолтный колбэк формирования финального стейта точно отработает некорректно, все ключи, кроме вложенных
*      //удалятся
*      getFinalInitialStateForKey: {Function}
*    },
*    [stateKey2]: {...}
*  }
*
*  Пример persistingSchema для state:
*
*  state = {
*    appType: {
*      a: 1,
*      b: 3,
*      c: { c1: 5 }
*    },
*    settings: {
*       d: 'dfa',
*       e: {},
*       g: 2
*    }
*  }
*
*  persistingSchema = {
*    appType: {
*      paths: [ ['b'], ['c', 'c1'] ],      //сохраняются только данные по внутренним ключам appType.b и appType.c.c1
*      initialState: {
*        a: 2,
*        b: 3,
*        c: { c1: 7}
*      }
*    },
*    settings: {
*      paths: [],             //сохраняются все данные по ключу settings
*      initialState: {
*        d: '',
*        e: {},
*        g: ''
*      },
*      //есть возможность определить кастомный callback'а getFinalInitialStateForKey, которая должна на основании
*      //initialState по умолчанию и сохраненного state для ключа в localStorage, вернуть итоговое initialState для
*      //ключа. ЭТО ТОЧНО НЕОБХОДИМО если сохраняются вложенные ключи, определенные в paths. Иначе
*      //дефолтный колбэк формирования финального стейта точно отработает некорректно, все ключи, кроме вложенных
*      //удалятся
*      getFinalInitialStateForKey: (initialStateForKey, persistedStateForKey) = {
*        if(!persistedStateForKey.b) return;
*        return {
*          ...initialStateForKey,
*          ...persistedStateForKey
*        }
*      }
*    }
*  }
*
*
* Возвращает StoreCreator
* */

const IS_PROD_MODE = process.env.NODE_ENV === PROD;
const IS_TEST_MODE = process.env.NODE_ENV === TEST;

export function persistStatePartsInLocalStorage({
  localStorageKey = 'redux-local',
  persistingSchema,
}) {
  return nextStoreCreator => {
    /*
    * Для тестового окружения манипуляции с localStorage не нужны.
    * Также если не указана схема сохранения, то непонятно что делать, сохранять весь state в localStorage вряд ли
    * когда-то будет нужно
    * */
    if(IS_TEST_MODE || !persistingSchema) return nextStoreCreator;

    //ещё раз стоит обратить внимание, что возвращаемый StoreCreator, в данный момент игнорирует второй и третий параметры
    return reducer => {

      const persistedState = loadState(localStorageKey);
      const finalInitialState = getFinalInitialState(persistedState, persistingSchema);

      const store = nextStoreCreator(reducer, finalInitialState);

      const persistStatePartsCb = () => {
        const state = store.getState();
        const stateDataToPersist = getStateDataToPersist(state, persistingSchema);
        persistState(localStorageKey, stateDataToPersist);
      };

      //не сохраняем данные state слишком часто
      store.subscribe(throttle(persistStatePartsCb, 2000));
      return store;
    };

  };
}

const loadState = localStorageKey => {
  try{
    const serializedState = localStorage.getItem(localStorageKey);
    if(serializedState === null) return;
    return JSON.parse(serializedState);
  } catch(err) {
    // eslint-disable-next-line no-console
    IS_PROD_MODE || console.warn('Не удалось загрузить данные из локального хранилища', err);
  }
};

const getFinalInitialState = (persistedState, persistingSchema) => {
  if(!persistedState) return;
  /*
  * из localStorage забираем только те ключи, который определены в persistingSchema, т.к. при редактировании структуры
  * persistingSchema и структуры финального reducer'а, полученного через combineReducers в localStorage могут оказаться
  * ключи, которых уже нет в store. В этом случае redux выдает warning и игнорирует их, но лучше обработать это самостоятельно
  * */
  return mapValues(
    pick(persistedState, Object.keys(persistingSchema)),
    (persistedDataForKey, stateKey) => {
      const initialStateForKey = get(persistingSchema[stateKey], 'initialState', {});
      const getFinalInitialStateForKey =
        get(persistingSchema[stateKey], 'getFinalInitialStateForKey', defaultFinalInitialStateForKeyGetter);

      return getFinalInitialStateForKey(initialStateForKey, persistedDataForKey);
    },
  );
};

const defaultFinalInitialStateForKeyGetter = (initialStateForKey, persistedDataForKey) => ({
  ...initialStateForKey,
  ...persistedDataForKey,
});

const getStateDataToPersist = (state, persistingSchema) =>
  Object
    .keys(persistingSchema)
    .reduce((stateDataToPersist, stateKey) => {
      const { paths }  = persistingSchema[stateKey];

      //если для определенной части хранилища не указаны внутренние ключи, то сохраняем эту часть полностью
      if(!size(paths)) {
        // eslint-disable-next-line no-param-reassign
        stateDataToPersist[stateKey] = state[stateKey];
        return stateDataToPersist;
      }

      //если указаны внутренние ключи, то сохраняем только их
      paths.forEach(innerKeyPath => {
        const path = [stateKey].concat(innerKeyPath);
        set(stateDataToPersist, path, get(state, path));
      });
      return stateDataToPersist;
    }, {});

const persistState = (localStorageKey, stateDataToPersist) => {
  try{
    const serializedState = JSON.stringify(stateDataToPersist);
    localStorage.setItem(localStorageKey, serializedState);
  } catch(err) {
    // eslint-disable-next-line no-console
    IS_PROD_MODE || console.warn('Не удалось сохранить данные в локальное хранилище', err);
  }
};
