export const SHEET_OPERATION_REVIEW_SET_IS_CONSUME_ENTITIES_DIALOG_OPEN =
  'SHEET_OPERATION_REVIEW_SET_IS_CONSUME_ENTITIES_DIALOG_OPEN';

export const sheetOperationReviewSetIsConsumeEntitiesDialogOpen = isOpen => ({
  type: SHEET_OPERATION_REVIEW_SET_IS_CONSUME_ENTITIES_DIALOG_OPEN,
  isOpen,
});

/*
sheetOperationFeaturesValues
*/
export const SET_SHEET_OPERATION_FEATURES_VALUES = 'SET_SHEET_OPERATION_FEATURES_VALUES';
export const CLEAR_SHEET_OPERATION_FEATURES_VALUES = 'CLEAR_SHEET_OPERATION_FEATURES_VALUES';
export const DELETE_SHEET_OPERATION_FEATURES = 'DELETE_SHEET_OPERATION_FEATURES';

export const setSheetOperationFeaturesValues = data => ({
  type: SET_SHEET_OPERATION_FEATURES_VALUES,
  data,
});

export const clearSheetOperationFeaturesValues = () => ({
  type: CLEAR_SHEET_OPERATION_FEATURES_VALUES,
});

export const deleteSheetOperationFeatures = (sheetOperationFeaturesIds = []) => ({
  type: DELETE_SHEET_OPERATION_FEATURES,
  sheetOperationFeaturesIds,
});

/*
sheetOperationProgressMode
*/
export const SET_SHEET_OPERATION_PROGRESS_MODE = 'SET_SHEET_OPERATION_PROGRESS_MODE';

export const setSheetOperationProgressMode = mode => ({
  type: SET_SHEET_OPERATION_PROGRESS_MODE,
  mode,
});
