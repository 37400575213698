import { CLEAR_ALL_LAST_SHEETS_OPERATIONS, CLEAR_LAST_SHEET_OPERATION, SET_LAST_SHEET_OPERATION } from './actions';

import _omit from 'lodash/omit';


/*
* Служебное хранилище для идентификаторов последних операций по конкретным МЛ. Иногда нужно идентифицировать, что
* операция является последней в технологии, т.к. с этим бывает связана отдельная логика в обработке. Как правило,
* понять, что операция последняя бывает можно только выполнив отдельный запрос, т.к. большинство таблиц серверные
* (данные получаются постранично). Чтобы слишком часто не выполнять запросы и проработано это хранилище.
*
* Как правило, данные сюда записываются сразу после специального запроса на последнюю операцию МЛ -
* fetchLastSheetOperation.
*
* Для хранилища предусмотрены экшены очистки, но непонятно нужно ли их использовать, потому что:
* - эти данные сейчас неизменяемы, технология МЛ постоянна
* - хранимых данных не очень много, т.к. по ключам идентификаторов МЛ в значении находится только 1 идентификатор
* последней операции.
* Поэтому при использовании можно пока и не заморачиваться сильно очисткой, ничего страшного если они будут просто
* копиться. Если это изменится, то комментарий нужно будет скорректировать!!!
* */

/*
* ВНИМАНИЕ: Это хранилище, а также и его экшены и селекторы не используются. Довольно часто стала нужна эта информация
* по операции в разрезе списка операций и для этого была проработана новая серверная точка агрегированной информации по
* операции, которая сейчас везде и используется. Это хранилище и его логика стали пока не нужны, то решено не удалять
* его полностью, т.к. ещё может пригодиться для каких-то других кейсов, который пока нет.
* */
export const lastSheetsOperations = (state = {}, action) => {
  switch(action.type) {
  case SET_LAST_SHEET_OPERATION: {
    const {
      sheetId,
      sheetOperationId,
    } = action;
    return {
      ...state,
      [sheetId]: sheetOperationId,
    };
  }
  case CLEAR_LAST_SHEET_OPERATION:
    return _omit(state, action.sheetId);
  case CLEAR_ALL_LAST_SHEETS_OPERATIONS:
    return {};
  default:
    return state;
  }
};