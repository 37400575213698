import React, { Component } from 'react';
import Select from 'react-select';

import _isNil from 'lodash/isNil';
import _isEqual from 'lodash/isEqual';
import _isFunction from 'lodash/isFunction';
import _size from 'lodash/size';
import { AUTOCOMPLETE_DEFAULT_PROPS, AUTOCOMPLETE_PROP_TYPES } from './constants';


export class Autocomplete extends Component {
  static propTypes = AUTOCOMPLETE_PROP_TYPES;
  static defaultProps = AUTOCOMPLETE_DEFAULT_PROPS

  componentDidMount() {
    const {
      shouldPreloadData,
      preloadInputValue,
      loadOptions,
      options,
    } = this.props;

    if(!_isFunction(loadOptions) || !shouldPreloadData || _size(options) > 0) return;

    loadOptions(preloadInputValue);
  }

  componentWillUnmount() {
    const {
      onUnmount,
    } = this.props;

    _isFunction(onUnmount) && onUnmount();
  }

  _handleSingleValueChange = newValue => {
    const {
      value,
      onChange,
    } = this.props;

    if (_isNil(newValue)) return onChange(null);

    if (_isEqual(value, newValue)) return;

    onChange(newValue);
  };

  _handleMultiValueChange = newValues => {
    const {
      onChange,
    } = this.props;

    onChange(newValues);
  };

  _handleInputChange = inputValue => {
    const {
      loadOptions,
      onInputChange,
    } = this.props;

    _isFunction(onInputChange) && onInputChange(inputValue);

    /*
    * Теоретически, при стирании всех букв можно тоже вызывать запрос загрузки опций, подразумевая, что функция запроса
    * должна этот момент обрабатывать. Дополнительно можно вызывать функцию, как это делается в componentDidMount c
    * preloadInputValue, если значение определено. Но пока решено ничего этого не делать.
    * */
    if(!_isFunction(loadOptions) || !inputValue) return;

    loadOptions(inputValue);
  };

  _getNoOptionsMessageCb = () => {
    const { noOptionsMessage } = this.props;

    if(!noOptionsMessage) return;

    return () => noOptionsMessage;
  };

  render() {
    const {
      value,
      options,
      getOptionValue,
      getOptionLabel,
      isMulti,
      title,
      error,
      showError,
      placeholder,
      isClearable,
      isDisabled,
      isSearchable,
      reactSelectProps,
      wrapperClassName,
      components,
      styles,
    } = this.props;

    return (
      <div className={wrapperClassName}>
        <Select
            {...reactSelectProps}
            components={components}
            styles={styles}
            value={value}
            onChange={
              isMulti ?
                this._handleMultiValueChange :
                this._handleSingleValueChange
            }
            options={options}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            onInputChange={this._handleInputChange}
            isMulti={isMulti}
            placeholder={placeholder}
            noOptionsMessage={this._getNoOptionsMessageCb()}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isSearchable={isSearchable}

            /*
            * Пропсы title, error и showError не являются частью апи реакт-селекта.
            * Реакт-селект предоставляет возможность прокидывать любые пропсы внутрь подлежащих компонентов. Для этого
            * используется специальный ключ selectProps, именно по этому ключу будет доступ к кастомным пропсам из
            * компонентов-составляющих реакт-селекта.
            * */
            title={title}
            error={error}
            showError={showError}
        />
      </div>
    );
  }
}
