import {
  CLEAR_ALL_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS,
  CLEAR_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS,
  CLEAR_ALL_DEPARTMENTS_WITH_TASKS,
  SAVE_DEPARTMENTS_WITH_TASKS,
  SAVE_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS,
  CLEAR_ALL_TASKS_OWNERS_DATA,
} from './actions';

import _omit from 'lodash/omit';
import _get from 'lodash/get';


export const TASKS_OWNERS_INITIAL_STATE = {
  departments: {},
  equipmentClassesInDepartments: {},
};

export const tasksOwners = (state = { ...TASKS_OWNERS_INITIAL_STATE }, action) => {
  switch(action.type) {
  case SAVE_DEPARTMENTS_WITH_TASKS: {
    const { departmentsEntities } = action;
    return {
      ...state,
      departments: {
        ...state.departments,
        ...departmentsEntities,
      },
    };
  }
  case CLEAR_ALL_DEPARTMENTS_WITH_TASKS: {
    return{
      ...state,
      departments: {},
    };
  }
  case SAVE_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS: {
    const { departmentId, equipmentClassesInDepartmentEntities } = action;
    return {
      ...state,
      equipmentClassesInDepartments: {
        ...state.equipmentClassesInDepartments,
        [departmentId]: {
          ..._get(state.equipmentClassesInDepartments, departmentId, {}),
          ...equipmentClassesInDepartmentEntities,
        },
      },
    };
  }
  case CLEAR_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS: {
    const { departmentIds } = action;

    if(departmentIds.length === 0) return state;

    return {
      ...state,
      equipmentClassesInDepartments: _omit(state.equipmentClassesInDepartments, departmentIds),
    };
  }
  case CLEAR_ALL_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS: {
    return {
      ...state,
      equipmentClassesInDepartments: {},
    };
  }
  case CLEAR_ALL_TASKS_OWNERS_DATA: {
    return { ...TASKS_OWNERS_INITIAL_STATE };
  }
  default:
    return state;
  }
};
