import { COLUMN_TYPE } from '../../../constants/table';
import { Trans } from '@lingui/macro';
import React from 'react';
import { initializeTableModelFields } from '../initializeTableModelFields';


export const ORDER_ENTRY_SCHEMA_FIELDS = [
  {
    columnName: 'entity__identity',
    displayName: (
      <Trans id="order_entries.table_column@entity_identity">
        ИД ДСЕ
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'entity__code',
    displayName: (
      <Trans id="order_entries.table_column@entity_code">
        Шифр ДСЕ
      </Trans>
    ),
    type: COLUMN_TYPE.NUMBER,
  },
  {
    columnName: 'entity__name',
    displayName: (
      <Trans id="order_entries.table_column@entity_name">
        ДСЕ
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'quantity',
    displayName: (
      <Trans id="order_entries.table_column@quantity">
        Количество
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
];

export const orderEntrySchema = {
  fields: initializeTableModelFields(ORDER_ENTRY_SCHEMA_FIELDS),
};