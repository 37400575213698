import { Trans } from '@lingui/macro';
import { COLUMN_TYPE } from '../../../constants/table';
import React from 'react';


export const sheetOperationTransactionSchema = {
  fields: {
    id: {
      columnName: 'id',
      displayName: (
        <Trans id="sheet_operation_review.history_table_column@id">
          ИД Транзакции
        </Trans>
      ),
      order: 0,
      show: false,
      type: COLUMN_TYPE.INTEGER,
    },
    startDate: {
      columnName: 'startDate',
      displayName: (
        <Trans id="sheet_operation_review.history_table_column@operation_start_date">
          Фактическая дата начала
        </Trans>
      ),
      order: 1,
      type: COLUMN_TYPE.DATE,
      customComponent: 'DateRenderer',
    },
    stopDate: {
      columnName: 'stopDate',
      displayName: (
        <Trans id="sheet_operation_review.history_table_column@operation_stop_date">
          Фактическая дата окончания
        </Trans>
      ),
      order: 2,
      type: COLUMN_TYPE.DATE,
      customComponent: 'DateRenderer',
    },
    userName: {
      columnName: 'userName',
      displayName: (
        <Trans id="sheet_operation_review.history_table_column@operation_user_name">
          ФИО исполнителя
        </Trans>
      ),
      order: 3,
      type: COLUMN_TYPE.STRING,
      customComponent: 'UsernameRenderer',
    },
    userIdentity: {
      columnName: 'userIdentity',
      displayName: (
        <Trans id="sheet_operation_review.history_table_column@operation_user_identity">
          Табельный № исполнителя
        </Trans>
      ),
      order: 4,
      type: COLUMN_TYPE.STRING,
    },
    stopProgress: {
      columnName: 'stopProgress',
      displayName: (
        <Trans id="sheet_operation_review.history_table_column@stop_progress">
          Прогресс операции
        </Trans>
      ),
      order: 5,
      type: COLUMN_TYPE.NUMBER,
      customComponent: 'SheetOperationProgressRenderer',
      disableFilter: true,
    },
  },
};