import { createSelector } from 'reselect';

import _get from 'lodash/get';
import { getUserAppsReviewPermissions } from '../../utils/permissions/index';


export const appStateSelector = state => state.appState;

export const appUserSelector = state => appStateSelector(state).userData;
export const isAppUserAuthenticatedSelector = state => appUserSelector(state) !== null;
export const appUserIdSelector = state => _get(appUserSelector(state), 'id', null);
export const appUserPermissionsSelector = state => _get(appUserSelector(state), 'permissions', null);
export const isOnlyOneAppReviewPermittedForUserSelector = createSelector(
  appUserPermissionsSelector,
  appUserPermissions => {
    if(appUserPermissions === null) return false;

    return getUserAppsReviewPermissions(appUserPermissions).length === 1;
  },
);

export const mainPlanningSessionSelector = state => appStateSelector(state).mainPlanningSession;
export const mainPlanningSessionIdSelector = state => _get(mainPlanningSessionSelector(state), 'id', null);

export const appLanguageSelector = state => appStateSelector(state).language;

export const appCriticalErrorSelector = state => appStateSelector(state).criticalError;
export const hasAppCriticalErrorSelector = state => appCriticalErrorSelector(state) !== null;