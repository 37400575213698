import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';

import AppBar from '@mui/material/AppBar';

import {
  FUNC_IS_REQUIRED_TYPE,
  OBJECT_OF_ANY_TYPE,
} from '../../../../constants/propTypes';
import _isFunction from 'lodash/isFunction';

import _identity from 'lodash/identity';
import './style.css';
import { isIdInUrlParamsValid } from '../../../../utils/url';
import { Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import { CA_CLIENT_SOCKET_MESSAGE_TYPE, SYSTEM_MESSAGES_SOCKET_IDENTITY } from '../../../../constants/sockets';
import { addSocketMessageListener } from '../../../../api/socketApi';
import { GoBackToListLabelTrans } from '../../../../utils/commonTransComponents';


export class EntityReviewDialog extends Component {

  static propTypes = {
    entityToReviewIdFromRoute: PropTypes.string,
    fetchEntityToReview: PropTypes.func,
    entityReviewContentComponent: PropTypes.elementType,
    entityReviewContentComponentPropsAdapter: PropTypes.func,
    stopEntityReview: FUNC_IS_REQUIRED_TYPE,
    renderDialogAppBarContent: FUNC_IS_REQUIRED_TYPE,
    fetchEntitiesData: PropTypes.func,
    entityIdProperty: PropTypes.string.isRequired,
    entitiesData: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
    fixedDialogAppBarHeight: PropTypes.number.isRequired,
    onSocketMessage: PropTypes.func,
  };

  static defaultProps = {
    entityReviewContentComponentPropsAdapter: _identity,
  }

  constructor(props) {
    super(props);
    this.reviewContentClientHeight = this._calculateReviewContentClientHeight();
    this.state = {
      entityToReviewData: null,
    };
  }
  /*
  * Из-за того, текущие данные просматриваемой сущности хранятся в локальном стейте мы не можем повлиять
  * на них извне (например из стандартных обработчиков событий броадкастинга) подключаем этот компонент
  * к сокетам и передаем калбек в пропс onSocketMessage, который обновляет локальный стейт EntityReviewDialog
  * при получении сообщий из сокетов, для того, чтобы при броадкастинге можно было обновлять интерфейс дочернего
  * компонента
  */
  componentDidMount() {

    const {
      onSocketMessage,
    } = this.props;

    if (_isFunction(onSocketMessage)) {
      const systemMessageSocketUrl = [
        window.config.WEBSOCKET_SERVER_HOST,
        SYSTEM_MESSAGES_SOCKET_IDENTITY,
      ].join('/');

      this.removeSheetOperationUpdateEventListener = addSocketMessageListener(
        systemMessageSocketUrl,
        this._socketMessageHandler,
      );
    }

    /*
    * Компонент EntityReviewDialog монтируется вместе с некоторым компонентом списка сущностей т.к. это модальник и если
    * его рендерить только по условию, когда модальник открыт, то потеряется анимация открытия\закрытия этого модальника.
    * Поэтому, в случае, когда мы заходим на экран просмотра списка сущностей (entityToReviewIdFromRoute === undefined),
    * т.е. когда модальник должен быть закрыт, то ничего не делаем, обработка открытия модальника будет в didUpdate.
    *
    * Для случая, когда мы переходим к экрану просмотра модальника по прямой ссылке, и на этапе маунта компонента списка
    * сущностей и EntityReviewDialog уже определен entityToReviewIdFromRoute, то выполняем инициализацию просматриваемой
    * сущности из списка
    * */
    if(this.props.entityToReviewIdFromRoute !== undefined) {
      this._initEntityToReviewData();
    }
  }

  componentDidUpdate(prevProps) {
    const { entityToReviewIdFromRoute: prevEntityToReviewIdFromRoute } = prevProps;
    const { entityToReviewIdFromRoute } = this.props;

    /*
    * Обработка случая, когда заходим в режим просмотра сущности при клике на строку в списке, т.к. при клике выполняется
    * редирект с добавлением в url идентификатора просматриваемой сущности (т.е. идентификатора не было в url и он
    * появился). Это также обрабатывает случай навигации кнопками браузера "вперед" и "назад".
    * */
    if(prevEntityToReviewIdFromRoute === undefined && entityToReviewIdFromRoute !== undefined) {
      return this._initEntityToReviewData();
    }

    /*
    * Обработка выхода из режима просмотра сущности, т.к. при любых событиях, связанных с закрытием/завершением просмотра
    * сущности выполняется редирект на соответствующий экран списка, т.е. из урла удаляется идентификатор просматриваемой
    * сущности (т.е., идентификатор был в url и его не стало). Это также обрабатывает случай навигации кнопками браузера
    * "вперед" и "назад".
    *
    * Проверка this.state.entityToReviewData !== null добавлена для обработки редиректа, который может выполнится при
    * вызове this._initEntityToReviewData() на didMount в случае, если проверки на существование просматриваемой сущности
    * не пройдены. В этом случае this.state.entityToReviewData бывает ещё не проинициализирован (т.е. равен null), незачем
    * сбрасывать его повторно, это лишняя перерисовка
    * */
    if(
      prevEntityToReviewIdFromRoute !== undefined &&
      entityToReviewIdFromRoute === undefined &&
      this.state.entityToReviewData !== null
    ) {
      this._setEntityToReviewData(null);
    }
  };

  componentWillUnmount() {
    if (_isFunction(this.removeSheetOperationUpdateEventListener)) {
      this.removeSheetOperationUpdateEventListener();
    }
  }

  _socketMessageHandler = message => {
    if(message.type !== CA_CLIENT_SOCKET_MESSAGE_TYPE) {
      return;
    }

    const {
      onSocketMessage,
    } = this.props;

    const socketMsgHandlerAdditionalProps = {
      setEntityToReviewData: this._setEntityToReviewData,
      initEntityToReviewData: this._initEntityToReviewData,
      initEntityToReviewDataRemote: this._initEntityToReviewDataRemote,
    };

    /*
    * в качестве аргументов в колбэк обработки сообщений из сокета передаем:
    * - полученное сообщение
    * - локальный стейт компонента EntityReviewDialog
    * - пропсы компонента EntityReviewDialog
    * - карта вспомогательных функций:
    *     - setEntityToReviewData - колбэк записи нового значения сущности в state.EntityToReviewData
    *     - initEntityToReviewData - колбэк инициализации стейта компонента на основании данных из пропсов
    *     - initEntityToReviewDataRemote - колбэк инициализации стейта данными, полученными после запроса сущности
    * */
    onSocketMessage(message, this.state, this.props, socketMsgHandlerAdditionalProps);
  }

  _setEntityToReviewData = entityToReviewData => this.setState({ entityToReviewData })

  _initEntityToReviewData = () => {

    const {
      entityToReviewIdFromRoute,
      fetchEntityToReview,
      entityIdProperty,
      entitiesData,
      stopEntityReview,
    } = this.props;

    /*
    * Сначала пытаемся найти объект данных просматриваемой сущности среди текущих данных таблицы entitiesData. Если
    * объект найден, то иницилизируем им локальный стейт, что приведет к открыванию модальника
    * Это самый распространенный кейс - мы заходим в список, видим нужный элемент и кликаем по нему, чтобы перейти к
    * просмотру. В этом случае объект данных элемента точно будет среди рядов таблицы entitiesData и для серверного списка
    * (т.к. кликаем по элементу серверного списка на странице, данные которой и располагаются entitiesData), так и для
    * клиентской (в этом случае данные по всем элементам списка сразу находятся в entitiesData).
    * */
    const entityToReviewData = entitiesData
      .find(
        /*
        * Приводим entityId к строке для строгого сравнения, т.к. entityToReviewIdFromRoute это параметр из url, а он
        * всегда строка, при этом, приводить entityToReviewIdFromRoute к числу нельзя, т.к. в случае ошибочного ввода
        * в url, в этой строке может быть всё что угодно
        * */
        ({ [entityIdProperty]: entityId }) => entityId.toString() === entityToReviewIdFromRoute,
      );

    if(!!entityToReviewData) {
      this._setEntityToReviewData(entityToReviewData);
      return;
    }

    /*
    * Если объект данных просматриваемой сущности не найден среди данных рядов таблицы, то:
    *
    * 1. Для клиентской таблицы это значит, что сущность с идентификатором entityToReviewIdFromRoute не существует, т.к.
    * для такой таблицы все ряды сразу определены в entitiesData. В этом случае "заканчиваем просмотр", т.е.
    * выполняем редирект на экран списка, что приведет к закрытию модальника, если он был открыт.
    * */
    if(!_isFunction(fetchEntityToReview)) {
      stopEntityReview();
      return;
    }


    /* 2. Для серверной таблицы запрашиваем сущность с заданным идентификатором с сервера. Если она существует, то
    * инициалзируем локальный стейт. Если нет - аналогично п.1. "заканчиваем просмотр", т.е. выполняем редирект на
    * экран списка, что приведет к закрытию мождальника, если он был открыт. (Перед запросом дополнительно
    * проверяем валидность идентификатора сущности entityToReviewIdFromRoute, т.к. если он невалидный, то выполнять запрос
    * не имеет смысла, это сразу указывает на то, что такой сущности не существует. Если не выполнить проверку и сделать
    * запрос к серверу с таким ID, то сервер ответит с ошибкой, т.к. для поля ID ожидаются только числа и тогда
    * придется этот момент отдельно обрабатывать)
    *
    * Проверка через запрос нужна для случая входа на экран просмотра сущности по прямой ссылке, когда либо данные для
    * серверного списка ещё не запрошены, либо запрошены данные для какой-то другой страницы и поэтому объекта данных
    * этой сущности сейчас нет в entitiesData
    * */

    if(!isIdInUrlParamsValid(entityToReviewIdFromRoute)) {
      stopEntityReview();
      return;
    }

    return this._initEntityToReviewDataRemote();
  };

  _initEntityToReviewDataRemote = () => {
    const {
      fetchEntityToReview,
      entityToReviewIdFromRoute,
      stopEntityReview,
    } = this.props;

    return fetchEntityToReview(entityToReviewIdFromRoute)
      .then(entityToReviewData => {

        if(entityToReviewData === null) return stopEntityReview();

        return this._setEntityToReviewData(entityToReviewData);
      });
  }

  /*
   * Просмотр данных по сущностиу осуществляется в специальном полноэкранном модальнике, у которого сверху
   * панель с контентом, который задается через renderDialogAppBarContent. Высота этой панели фиксируется пользователем
   * через fixedDialogAppBarHeight.
   * Поэтому при маунте компонента дополнительно высчитывается высота видимого контента в модальнике, исходя из
   * клиентского размера экрана и фиксируется в этом компоненте для обертки контента.
   * Расчет высоты: Вычитаем из высоты экрана размер высоты панели с пользовательским контентом.
   * Высота определяется только при маунте компонента и не пересчитывается при перерисовках.
   *
   * В последствии эта логика должна быть переписана на расчет высоты с использованием прямого обращения к DOM
   * */
  _calculateReviewContentClientHeight = () => {
    const clientHeight = document.documentElement.clientHeight;
    return clientHeight -
      this.props.fixedDialogAppBarHeight;
  };

  _renderDialogBar = entityToReviewData =>
    <AppBar className="entity-review-dialog__dialog-bar">
      <Toolbar className="entity-review-dialog__dialog-bar-toolbar">
        {this.props.renderDialogAppBarContent(entityToReviewData)}
        <Button color="inherit" onClick={this.props.stopEntityReview}>
          {GoBackToListLabelTrans}
        </Button>
      </Toolbar>
    </AppBar>;

  _renderDialogContent = entityToReviewData => {
    const {
      entityReviewContentComponent: EntityReviewContentComponent,
      entityReviewContentComponentPropsAdapter,
      stopEntityReview,
      fetchEntitiesData,
      fixedDialogAppBarHeight,
    } = this.props;

    if(!EntityReviewContentComponent) return null;

    /*
    * Появление адаптера для пропсов контентКомпонента обусловлено тем, что абстракция перерабатывается с учетом текущих
    * использований, и корректировки нейминга пропсов повлекли бы за собой большие изменения в существующих компонентах.
    * Идея же с тем, чтобы пробрасывать все необходимые пропсы через ключ entityReviewContentComponentProps нереализуема,
    * т.к. мы не просто транслируем часть пропсов сквозь уровни компонентов, а и передаем с вышестоящего уровня данные
    * по просматриваемой сущности, которые хранятся там нутри локального стейта компонента.
    * */
    const entityReviewContentComponentProps =
      entityReviewContentComponentPropsAdapter({
        stopEntityReview,
        fetchEntitiesData,
        entityToReviewData,
        initEntityToReviewDataRemote: this._initEntityToReviewDataRemote,
      });

    return(
      <div
          style={{
            height: this.reviewContentClientHeight,
            overflow: 'auto',
            marginTop: fixedDialogAppBarHeight,
          }}
      >
        <div className="entity-review-dialog__dialog-content">
          <EntityReviewContentComponent {...entityReviewContentComponentProps}/>
        </div>
      </div>
    );
  };

  render() {
    const {
      stopEntityReview,
    } = this.props;
    const {
      entityToReviewData,
    } = this.state;

    return(
      <Dialog
          className="entity-review-dialog"
          fullScreen
          open={entityToReviewData !== null}
          onClose={stopEntityReview}
          TransitionComponent={DialogTransition}
      >
        {
          entityToReviewData !== null ?
            <React.Fragment>
              {this._renderDialogBar(entityToReviewData)}
              {this._renderDialogContent(entityToReviewData)}
            </React.Fragment> :
           <noscript />
        }
      </Dialog>
    );
  }
}

const DialogTransition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));
