import { MASTER_APP, PLANNER_APP } from './app';
import { SHEET_TYPE } from './sheets';

export const TOOLS_COLUMN_NAME = 'tools';

export const COLUMN_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  INTEGER: 'integer',
  DATE: 'date',
  TOOLS: 'tools',
};

export const AVAILABLE_TABLE_PAGE_SIZES = [20, 40, 80, 160];
export const DEFAULT_TABLE_PAGE_SIZE = AVAILABLE_TABLE_PAGE_SIZES[0];



export const PLANNER_APP_PAUSED_SHEETS_TABLE_ID = [PLANNER_APP, SHEET_TYPE.PAUSED].join('__');
export const MASTER_APP_PAUSED_SHEETS_TABLE_ID = [MASTER_APP, SHEET_TYPE.PAUSED].join('__');

export const PAUSED_SHEETS_TABLES_IDS_ARRAY = [
  PLANNER_APP_PAUSED_SHEETS_TABLE_ID,
  MASTER_APP_PAUSED_SHEETS_TABLE_ID,
];