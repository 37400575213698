import React from 'react';
import { Trans } from '@lingui/macro';
import { COLUMN_TYPE, TOOLS_COLUMN_NAME } from '../../../constants/table';
import { initializeTableModelFields } from '../initializeTableModelFields';

const SHEET_OPERATION_FEATURES_SCHEMA_FIELDS = [
  {
    columnName: 'id',
    displayName: (
        <Trans id="sheet_operation_settings.sheet_operation_features.table_columns@feature_id">
          ИД характеристики
        </Trans>
      ),
    type: COLUMN_TYPE.NUMBER,
    show: false,
  },
  {
    columnName: 'name',
    displayName: (
        <Trans id="sheet_operation_settings.sheet_operation_features.table_columns@feature_name">
          Характеристика
        </Trans>
      ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'createStamp',
    displayName: (
        <Trans id="sheet_operation_settings.sheet_operation_features.table_columns@feature_create_stamp">
          Дата создания
        </Trans>
      ),
    customComponent: 'DateRenderer',
    type: COLUMN_TYPE.DATE,
    disableFilter: true,
  },
  {
    columnName: TOOLS_COLUMN_NAME,
  },
];

export const sheetOperationFeaturesSchema = {
  fields: initializeTableModelFields(SHEET_OPERATION_FEATURES_SCHEMA_FIELDS),
  sortParams: [
    {
      column: 'name',
      asc: true,
    },
  ],
};