import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import { SheetSummary } from '../../PlannerApp/SheetSummary/SheetSummary';

import {
  SHEET_SUMMARY_TYPE,
  FUNC_IS_REQUIRED_TYPE,
} from '../../../constants/propTypes';

import './style.css';
import { SimpleConfirmDialog } from '../../common/SimpleConfirmDialog/SimpleConfirmDialog';
import { CancelLabelTrans, ResumeToProductionLabelTrans } from '../../../utils/commonTransComponents';


export class ResumeSheetDialog extends Component {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSubmit: FUNC_IS_REQUIRED_TYPE,
    closeDialog: FUNC_IS_REQUIRED_TYPE,
    sheetToResumeData: SHEET_SUMMARY_TYPE.isRequired,
  };

  _renderSheetToResumeSummary = () =>
    <SheetSummary
        className="resume-sheet-dialog__summary"
        withEntityLabel
        {...this.props.sheetToResumeData}
    />;

  render() {
    const { closeDialog, isOpen, onSubmit } = this.props;

    return (
      <SimpleConfirmDialog
          className="resume-sheet-dialog"
          closeDialog={closeDialog}
          isOpen={isOpen}
          title={ResumeToProductionLabelTrans}
          confirmText={
            <Trans id="paused_sheet.resume_sheet@confirm_message">
              Вы уверены, что хотите вернуть остановленный маршрутный лист в производство? Маршрутный лист и задания по
              нему снова станут доступными для исполнения
            </Trans>
          }
          additionalComponent={this._renderSheetToResumeSummary()}
          confirmBtn={
            <Trans id="paused_sheet.resume_sheet@confirm_button">
              Вернуть в производство
            </Trans>
          }
          cancelBtn={CancelLabelTrans}
          onConfirm={onSubmit}
      />
    );
  }
}
