import _get from 'lodash/get';

/*
* В этом файле находятся селекторы данных из пропсов реакт роутера, которые передаются в компоненты находящиеся
* на роутах. Селекторы созданы для удобства, чтобы постоянно не повторять преобразования и выглядели в так же, как и
* остальные селекторы, хотя в них не передается state, а только ownProps. Чтобы не смешивать эти селекторы с селекторами
* connected-react-router они выделены в отдельный файл, а не находятся внутри reducers, т.к. не относятся к store
* */
export const reactRouterMatchSelector = (_, { match }) => match;

const propertyFromMatchPropSelectorFactory = (property, defaultValue) =>
  (state, props) => _get(reactRouterMatchSelector(null, props), [property], defaultValue);

export const reactRouterParamsSelector = propertyFromMatchPropSelectorFactory('params', {});

export const reactRouterUrlSelector = propertyFromMatchPropSelectorFactory('url', '');

export const reactRouterPathSelector = propertyFromMatchPropSelectorFactory('path', '');

export const reactRouterIsExactSelector = propertyFromMatchPropSelectorFactory('isExact', false);


export const reactRouterLocationSelector = (_, { location }) => location;

const propertyFromLocationPropSelectorFactory = (property, defaultValue) =>
  (state, props) => _get(reactRouterLocationSelector(null, props), [property], defaultValue);

export const reactRouterCurrentPathnameSelector = propertyFromLocationPropSelectorFactory('pathname', '');

export const reactRouterCurrentSearchSelector = propertyFromLocationPropSelectorFactory('search', '');