import { createSelector } from 'reselect';

import { workerAppStateSelector } from '../selectors';

export const tasksMainFiltersSelector = state => workerAppStateSelector(state).tasksMainFilters;

export const departmentTasksFilterSelector = state => tasksMainFiltersSelector(state).department;

const getSelectedEntityTasksFilterTitle = selectedEntityTasksFilter => {
  const { name, identity } = selectedEntityTasksFilter;
  if(name === undefined || identity === undefined) return null;

  return `${name} (${identity})`;
};

export const departmentTasksFilterTitleSelector = createSelector(
  departmentTasksFilterSelector,
  getSelectedEntityTasksFilterTitle,
);

export const equipmentClassTasksFilterSelector = state => tasksMainFiltersSelector(state).equipmentClass;

export const equipmentClassTasksFilterTitleSelector = createSelector(
  equipmentClassTasksFilterSelector,
  getSelectedEntityTasksFilterTitle,
);
