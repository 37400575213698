import {
  CHANGE_COLUMN_VISIBILITY,
  CHANGE_COLUMNS_ORDER,
  CREATE_NEW_SCHEMA_MODEL,
  DELETE_SCHEMA_MODEL,
  INIT_SCHEMA_MODEL_FROM_SETTINGS,
  RESET_SCHEMA_MODEL_SETTINGS,
  RESIZE_COLUMNS,
  UPDATE_SCHEMA_MODELS_DATA,
} from './actions';

import {
  fieldComparatorFactory,
  numberComparator,
} from '@bfg-frontend/utils/lib/array';

import _cloneDeep from 'lodash/cloneDeep';
import _mapValues from 'lodash/mapValues';
import _omit from 'lodash/omit';
import { TABLE_SET_PARAMS } from '../table/actions';
import { SCHEMA_MODEL_INITIAL_STATE } from './initialState';


export const schemaModelReducer = (state = _cloneDeep(SCHEMA_MODEL_INITIAL_STATE), action)  => {
  switch (action.type) {
  case CHANGE_COLUMNS_ORDER:
  case RESIZE_COLUMNS:
  case CHANGE_COLUMN_VISIBILITY: {
    const { model } = action;
    return {
      ...state,
      [model]: updateModelData(state[model], action),
    };
  }
  case CREATE_NEW_SCHEMA_MODEL:
    return{
      ...state,
      [action.model]: _cloneDeep(action.schemaModelData),
    };
  case DELETE_SCHEMA_MODEL:
    return _omit(state, [action.model]);
  case UPDATE_SCHEMA_MODELS_DATA:
    const { updatedSchemaModelsData = {} } = action;
    return _mapValues(
        state,
        (schemaForModel, model) => {
          const updatedSchemaDataForModel = updatedSchemaModelsData[model];
          if(!updatedSchemaDataForModel) return schemaForModel;
          return {
            ...schemaForModel,
            fields: { ...schemaForModel.fields, ...updatedSchemaDataForModel.fields },
            sortParams: updatedSchemaDataForModel.sortParams ?
              updatedSchemaDataForModel.sortParams.map(sortParam => ({ ...sortParam })) :
              schemaForModel.sortParams,
            pageSize: updatedSchemaDataForModel.pageSize || schemaForModel.pageSize,
          };
        },
      );
  case INIT_SCHEMA_MODEL_FROM_SETTINGS: {
    const { settings } = action;
    const defaultState = SCHEMA_MODEL_INITIAL_STATE;
    if (!settings) return defaultState;
    return _mapValues(defaultState, (defaultSchemaModel, modelName) => {

      const settingsForModel = settings[modelName];

      if(!settingsForModel) return defaultSchemaModel;

      return {
        ...defaultSchemaModel,
        ...settingsForModel,
          /*
          * В настройках храниться только часть параметров полей, поэтому для каждого поля, нужно подмержить
          * дефолтные параметры с сохраненными, чтобы всё нормально сработало
          * */
        fields: _mapValues(
            defaultSchemaModel.fields,
            (defaultFieldParams, fieldKey) => ({
              ...defaultFieldParams,
              ...settingsForModel.fields[fieldKey],
            }),
          ),
      };
    });
  }
  case RESET_SCHEMA_MODEL_SETTINGS: {
    const { model } = action;
    return {
      ...state,
      [model]: SCHEMA_MODEL_INITIAL_STATE[model],
    };
  }

    /*
    * Подробнее про обработку этого экшена в комментарии к обработке этого экшена в reducers/tables.js. В текущей
    * реализации принято, что информация о сортировке и количестве строк на странице таблицы привязывается к модели
    * таблицы, а не к id таблицы, как другие параметры, поэтому этот экшн отрабатывается, также, и здесь, если была
    * изменена сортировка или количество строк на странице при вызове setTableParams
    */
  case TABLE_SET_PARAMS: {
    const { tableModel, tableParams: { sortParams, pageSize } } = action;

    if(!tableModel) return state;

    if(!sortParams && !pageSize) return state;

    const schemaForModel = state[tableModel];

    return {
      ...state,
      [tableModel]: {
        ...schemaForModel,
        sortParams: !!sortParams ?
            sortParams.slice() :
            schemaForModel.sortParams,
        pageSize: !!pageSize ?
            pageSize :
            schemaForModel.pageSize,
      },
    };
  }
  default:
    return state;
  }
};

const updateModelData = (state, action) => {
  switch(action.type) {
  case CHANGE_COLUMNS_ORDER:
  case RESIZE_COLUMNS:
  case CHANGE_COLUMN_VISIBILITY:
    return {
      ...state,
      fields: updateFields(state.fields, action),
    };
  default: return state;
  }
};


const updateFields = (state, action) => {
  switch (action.type) {
  case CHANGE_COLUMNS_ORDER:
    return changeFieldsOrder(state, action);
  case RESIZE_COLUMNS:
    return resizeColumns(state, action);
  case CHANGE_COLUMN_VISIBILITY:
    return changeColumnVisibility(state, action);
  default: return state;
  }
};

function changeFieldsOrder(state, action) {
  const {
    dragSourceColumnIndex,
    dragDestinationColumnIndex,
  } = action;

  if(dragSourceColumnIndex === dragDestinationColumnIndex) return state;

  /*
  * В массиве менять местами для реализации поведения drag and drop удобнее
  * */
  const displayedColumnsSortedByOrderArr = Object
    .values(state)
    /*
    * Фильтруем только отображаемые на экране колонки с display = true (не путать с полем show, это поле определяет
    * видимость колонок, т.е. его можно сделать видимым и поменять местами, а display = false никогда не отображается, это
    * служебные колонки), т.к. только у них есть поле order, определяющее положение колонки, и только они передаются в
    * таблицу и отображаются на экране, а пользователь меняет только их положение и их индексы нужно будет изменить
    * */
    .filter(({ display }) => display)
    .sort(fieldComparatorFactory(numberComparator, 'order'));

  const [draggedItem] = displayedColumnsSortedByOrderArr.splice(dragSourceColumnIndex, 1);
  displayedColumnsSortedByOrderArr.splice(dragDestinationColumnIndex, 0, draggedItem);

  const ordersForDisplayedColumnsAfterChangesMap = displayedColumnsSortedByOrderArr
    .reduce(
      (ordersForDisplayedColumnsAfterChangesMap, columnData, columnNewOrderIndex) => {
        const { columnName: columnId } = columnData;
        // eslint-disable-next-line no-param-reassign
        ordersForDisplayedColumnsAfterChangesMap[columnId] = columnNewOrderIndex;
        return ordersForDisplayedColumnsAfterChangesMap;
      },
      {},
    );

  /*
  * Пробегаемся по исходному объекту колонок, для служебных колонок с display=false, ничего менять не нужно,
  * а для отображаемых колонок поле order поставляется из orderForColumnsAfterDragMap
  * */
  return _mapValues(
    state,
    ((columnData, columnId) => {
      const { display } = columnData;
      return display ?
        ({
          ...columnData,
          order: ordersForDisplayedColumnsAfterChangesMap[columnId],
        }) :
        ({
          ...columnData,
        });
    }),
  );
}

function resizeColumns(state, action) {
  const {
    columnsWidthData,
  } = action;

  const update = _mapValues(
    columnsWidthData,
    (columnNewWidth, columnName) => ({
      ...state[columnName],
      width: columnNewWidth,
    }),
  );

  return {
    ...state,
    ...update,
  };
}

function changeColumnVisibility(state, action) {
  const { columnName, visibility } = action;
  return {
    ...state,
    [columnName]: {
      ...state[columnName],
      show: visibility,
    },
  };
}
