import { COLUMN_TYPE, TOOLS_COLUMN_NAME } from '../../../constants/table';
import { initializeTableModelFields } from '../initializeTableModelFields';
import {
  DepartmentIdentityLabelTrans,
  DepartmentLabelTrans,
  EquipmentClassIdentityLabelTrans,
  EquipmentClassLabelTrans,
  OperationIdentityLabelTrans,
  OperationLabelTrans,
  OperationNumberLabelTrans,
} from '../../../utils/commonTransComponents';

export const SHEET_OPERATION_FEATURE_SCOPES_SCHEMA_FIELDS = [
  {
    columnName: 'departmentIdentity',
    displayName: DepartmentIdentityLabelTrans,
    type: COLUMN_TYPE.STRING,
    customComponent: 'DefaultNilValueLabelRenderer',
  },
  {
    columnName: 'departmentName',
    displayName: DepartmentLabelTrans,
    type: COLUMN_TYPE.STRING,
    customComponent: 'DefaultNilValueLabelRenderer',
  },
  {
    columnName: 'equipmentClassIdentity',
    displayName: EquipmentClassIdentityLabelTrans,
    type: COLUMN_TYPE.STRING,
    customComponent: 'DefaultNilValueLabelRenderer',
  },
  {
    columnName: 'equipmentClassName',
    displayName: EquipmentClassLabelTrans,
    type: COLUMN_TYPE.STRING,
    customComponent: 'DefaultNilValueLabelRenderer',
  },
  {
    columnName: 'operationIdentity',
    displayName: OperationIdentityLabelTrans,
    type: COLUMN_TYPE.STRING,
    customComponent: 'DefaultNilValueLabelRenderer',
  },
  {
    columnName: 'operationNumber',
    displayName: OperationNumberLabelTrans,
    type: COLUMN_TYPE.STRING,
    customComponent: 'DefaultNilValueLabelRenderer',
  },
  {
    columnName: 'operationName',
    displayName: OperationLabelTrans,
    type: COLUMN_TYPE.STRING,
    customComponent: 'DefaultNilValueLabelRenderer',
  },
  {
    columnName: TOOLS_COLUMN_NAME,
  },
];

export const sheetOperationFeatureScopesSchema = {
  fields: initializeTableModelFields(SHEET_OPERATION_FEATURE_SCOPES_SCHEMA_FIELDS),
  sortParams: [
    {
      column: 'departmentName',
      asc: true,
    },
  ],
};