import { connect } from 'react-redux';

import { OrdersInProductionReviewContent } from './OrdersInProductionReviewContent';
import { ORDER_TYPE } from '../../../../constants/orders';
import { orderEntriesTableDataSelector } from '../../../../selectors/orders';
import { fetchOrderEntriesRemoteTableData } from '../../../../operations/orders';
import { createOrderEntriesTableId } from '../../../../utils/tables';


const mapStateToProps = (state, { entityToReviewData }) => {

  const {
    id,
  } = entityToReviewData;

  const orderEntriesTableId = createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, id);

  return {
    orderEntriesTableId,
    orderEntriesTableData: orderEntriesTableDataSelector(state, { tableId: orderEntriesTableId }),
  };
};

const mapDispatchToProps = (dispatch, { entityToReviewData }) => {
  const {
    id,
  } = entityToReviewData;

  return {
    fetchOrderEntriesTableData: ({ tableParams }) => dispatch(fetchOrderEntriesRemoteTableData(id, tableParams)),
  };
};

export const OrdersInProductionReviewContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersInProductionReviewContent);