import { Route, Switch } from 'react-router-dom';
import {
  ADMIN_APP_SHEET_OPERATION_FEATURE_REVIEW_SCREEN_ROUTE,
  ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_TABS_ROUTE,
} from '../../../constants/routes';
import React from 'react';
import { SheetOperationFeatures } from '../SheetOperationFeatures/SheetOperationFeatures';
import {
  SheetOperationFeatureReviewScreen,
} from '../SheetOperationFeatureReviewScreen/SheetOperationFeatureReviewScreen';

export const SheetOperationFeaturesScreen = () => (
    <Switch>
      <Route
          path={ADMIN_APP_SHEET_OPERATION_FEATURE_REVIEW_SCREEN_ROUTE}
          component={SheetOperationFeatureReviewScreen}
      />
      <Route
          exact
          path={ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_TABS_ROUTE}
          component={SheetOperationFeatures}
      />
    </Switch>
  );