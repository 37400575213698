import { COLUMN_TYPE } from '../../../constants/table';
import { Trans } from '@lingui/macro';
import React from 'react';
import { initializeTableModelFields } from '../initializeTableModelFields';


export const SHEET_OPERATION_SCHEMA_FIELDS = [
  {
    columnName: 'operationIdentity',
    displayName: (
      <Trans id="sheet_operations.table_column@operation_identity">
        ИД Операции
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'operationNumber',
    displayName: (
      <Trans id="sheet_operations.table_column@operation_number">
        № Операции
      </Trans>
    ),
    type: COLUMN_TYPE.NUMBER,
  },
  {
    columnName: 'operationName',
    displayName: (
      <Trans id="sheet_operations.table_column@operation_name">
        Операция
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'operationProdTime',
    type: COLUMN_TYPE.NUMBER,
    displayName: (
      <Trans id="sheet_operations.table_column@operation_prod_time">
        Тшт., ч.
      </Trans>
    ),
    customComponent: 'HumanizedTimeFromHoursRenderer',
    // пояснение см. в masterTasksSchema.js
    disableFilter: true,
  },
  {
    columnName: 'progress',
    displayName: (
      <Trans id="sheet_operations.table_column@progress">
        Прогресс операции
      </Trans>
    ),
    type: COLUMN_TYPE.NUMBER,
    customComponent: 'SheetOperationProgressRenderer',
    disableFilter: true,
  },
  {
    columnName: 'operationLabor',
    type: COLUMN_TYPE.NUMBER,
    displayName: (
      <Trans id="sheet_operations.table_column@operation_labor">
        Трудоемкость операции, ч.
      </Trans>
    ),
    customComponent: 'OperationLaborRenderer',
    disableFilter: true,
  },
  {
    columnName: 'departmentIdentity',
    displayName: (
      <Trans id="sheet_operations.table_column@department_identity">
        ИД Подразделения
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'departmentName',
    displayName: (
      <Trans id="sheet_operations.table_column@department_name">
        Подразделение
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'equipmentClassIdentity',
    displayName: (
      <Trans id="sheet_operations.table_column@equipment_class_identity">
        ИД Класса РЦ
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'equipmentClassName',
    displayName: (
      <Trans id="sheet_operations.table_column@equipment_class_name">
        Класс РЦ
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'assignees',
    displayName: (
      <Trans id="sheet_operations.table_column@assignees">
        Исполнители
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
    disableFilter: true,
    customComponent: 'AssigneesRenderer',
  },
  {
    columnName: 'equipment',
    displayName: (
      <Trans id="sheet_operations.table_column@equipment">
        Оборудование
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
    disableFilter: true,
    customComponent: 'PlannerSheetOperationsEquipmentCombinedDataRenderer',
  },
  {
    columnName: 'operationStartDate',
    displayName: (
      <Trans id="sheet_operations.table_column@operation_start_date">
        Плановая дата начала
      </Trans>
    ),
    type: COLUMN_TYPE.DATE,
    customComponent: 'DateRenderer',
  },
  {
    columnName: 'operationStopDate',
    displayName: (
      <Trans id="sheet_operations.table_column@operation_stop_date">
        Плановая дата окончания
      </Trans>
    ),
    type: COLUMN_TYPE.DATE,
    customComponent: 'DateRenderer',
  },
];

export const sheetOperationSchema = {
  fields: initializeTableModelFields(SHEET_OPERATION_SCHEMA_FIELDS),
};
