import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { USER_ROLE_CHIP_PARAMS_MAP } from '../../../constants/users';
import _get from 'lodash/get';

import './style.css';


const CUSTOM_USER_ROLE_COLOR = '#777';

export const UserRoleChip = props => {
  const {
    className,
    onDelete,
    roleIdentity,
  } = props;

  const {
    label,
    color,
    avatarTrans,
  } = _get(
    USER_ROLE_CHIP_PARAMS_MAP,
    [roleIdentity],
    {
      label: roleIdentity,
      color: CUSTOM_USER_ROLE_COLOR,
      avatarTrans: roleIdentity[0].toUpperCase(),
    },
  );

  return (
    <Chip
        classes={{
          root: cn('user-role-chip', className),
          avatar: 'user-role-chip__avatar',
          deleteIcon: 'user-role-chip__delete-icon',
        }}
        onDelete={onDelete}
        avatar={(
          <Avatar style={{ backgroundColor: color }}>
            {avatarTrans}
          </Avatar>
        )}
        label={label}
    />
  );
};

UserRoleChip.propTypes = {
  roleIdentity: PropTypes.string.isRequired,
  className: PropTypes.string,
  onDelete: PropTypes.func,
};