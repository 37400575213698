import { Trans } from '@lingui/macro';
import React from 'react';
import { COLUMN_TYPE } from '../../../constants/table';

export const TASK_VIEW_SCREEN_MODEL = 'taskViewScreen';

export const taskViewScreenSchema = {
  fields: {
    sheetIdentity: {
      columnName: 'sheetIdentity',
      displayName: (
        <Trans id="worker_tasks.table_column@sheet_identity">
          ИД МЛ партии
        </Trans>
      ),
      order: 0,
      type: COLUMN_TYPE.STRING,
    },
    orderName: {
      columnName: 'orderName',
      displayName: (
        <Trans id="worker_tasks.table_column@order_name">
          Заказ
        </Trans>
      ),
      order: 1,
      type: COLUMN_TYPE.STRING,
      customComponent: 'OrderNameRenderer',
    },
    entityIdentity: {
      columnName: 'entityIdentity',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="worker_tasks.table_column@entity_identity">
          ИД ДСЕ
        </Trans>
      ),
      order: 2,
      show: false,
    },
    entityCode: {
      columnName: 'entityCode',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="worker_tasks.table_column@entity_code">
          Шифр ДСЕ
        </Trans>
      ),
      order: 3,
    },
    entityName: {
      columnName: 'entityName',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="worker_tasks.table_column@entity_name">
          ДСЕ
        </Trans>
      ),
      order: 4,
    },
    entitiesInBatchAmount: {
      columnName: 'entitiesInBatchAmount',
      type: COLUMN_TYPE.NUMBER,
      displayName: (
        <Trans id="worker_tasks.table_column@entities_in_batch_amount">
          Размер партии
        </Trans>
      ),
      order: 5,
    },
    operationIdentity: {
      columnName: 'operationIdentity',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="worker_tasks.table_column@operation_identity">
          ИД Операции
        </Trans>
      ),
      order: 6,
      show: false,
    },
    operationNumber: {
      columnName: 'operationNumber',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="worker_tasks.table_column@operation_number">
          № Операции
        </Trans>
      ),
      order: 7,
    },
    operationName: {
      columnName: 'operationName',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="worker_tasks.table_column@operation_name">
          Операция
        </Trans>
      ),
      order: 8,
    },
    operationProdTime: {
      columnName: 'operationProdTime',
      type: COLUMN_TYPE.NUMBER,
      displayName: (
        <Trans id="worker_tasks.table_column@operation_prod_time">
          Тшт., ч.
        </Trans>
      ),
      order: 9,
      customComponent: 'HumanizedTimeFromHoursRenderer',
      // пояснение см. в masterTasksSchema.js
      disableFilter: true,
    },
    progress: {
      columnName: 'progress',
      displayName: (
        <Trans id="worker_tasks.table_column@progress">
          Прогресс операции
        </Trans>
      ),
      order: 10,
      type: COLUMN_TYPE.NUMBER,
      customComponent: 'SheetOperationProgressRenderer',
      disableFilter: true,
    },
    operationLabor: {
      columnName: 'operationLabor',
      type: COLUMN_TYPE.NUMBER,
      displayName: (
        <Trans id="worker_tasks.table_column@operation_labor">
          Трудоемкость операции, ч.
        </Trans>
      ),
      order: 11,
      customComponent: 'OperationLaborRenderer',
      disableFilter: true,
    },
    assignees: {
      columnName: 'assignees',
      displayName: (
        <Trans id="worker_tasks.table_column@assignees">
          Исполнители
        </Trans>
      ),
      type: COLUMN_TYPE.STRING,
      order: 12,
      disableFilter: true,
      customComponent: 'AssigneesRenderer',
    },
    equipment: {
      columnName: 'equipment',
      displayName: (
        <Trans id="worker_tasks.table_column@equipment">
          Оборудование
        </Trans>
      ),
      order: 13,
      type: COLUMN_TYPE.STRING,
      disableFilter: true,
      customComponent: 'WorkerTasksEquipmentCombinedDataRenderer',
    },
    operationStartDate: {
      columnName: 'operationStartDate',
      type: COLUMN_TYPE.DATE,
      displayName: (
        <Trans id="worker_tasks.table_column@operation_start_date">
          Плановая дата начала
        </Trans>
      ),
      order: 14,
      customComponent: 'DateRenderer',
    },
    operationStopDate: {
      columnName: 'operationStopDate',
      type: COLUMN_TYPE.DATE,
      displayName: (
        <Trans id="worker_tasks.table_column@operation_stop_date">
          Плановая дата окончания
        </Trans>
      ),
      order: 15,
      customComponent: 'DateRenderer',
    },
  },
};
