import React from 'react';
import { Trans } from '@lingui/macro';


export const NoEquipmentClassesWithTasksTrans = (
  <Trans id="worker_equipment_class_choosing@no_equipment_classes_with_tasks">
    На текущий момент в системе нет заданий ни для одного из классов РЦ этого подразделения. Выберите другое
    подразделение
  </Trans>
);

export const OnlyEquipmentClassesWithTasksDisplayedTrans = (
  <Trans id="worker_equipment_class_choosing@only_equipment_classes_with_tasks_displayed">
    В системе не отображаются классы РЦ в подразделении, для которых не были сформированы задания
  </Trans>
);

export const NoDepartmentsWithTasksTrans = (
  <Trans id="worker_department_choosing@no_departments_with_tasks">
    На текущий момент в системе нет заданий ни для одного из подразделений
  </Trans>
);

export const OnlyDepartmentsWithTasksDisplayedTrans = (
  <Trans id="worker_department_choosing@only_departments_with_tasks_displayed">
    В системе не отображаются подразделения, для которых не были сформированы задания
  </Trans>
);











