import _get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDataFromServerDataPoint } from '../../../../api';
import { getCheckOrderWarehouseNeedlessEntityBatchesDataPoint } from '../../../../constants/serverDataPoints';

import { OrdersReadyToCompleteReviewContent } from './OrdersReadyToCompleteReviewContent';
import { ORDER_TYPE } from '../../../../constants/orders';
import { orderEntriesTableDataSelector } from '../../../../selectors/orders';
import { createOrderEntriesTableId } from '../../../../utils/tables';
import {
  fetchOrderEntriesRemoteTableData,
  broadcastOrderCompleted,
  completeOrder,
  sendOrderCompletedNotification,
} from '../../../../operations/orders';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';


const mapStateToProps = (state, { entityToReviewData }) => {
  const {
    id,
  } = entityToReviewData;

  const orderEntriesTableId = createOrderEntriesTableId(ORDER_TYPE.READY_TO_COMPLETE, id);

  return {
    orderEntriesTableId,
    orderEntriesTableData: orderEntriesTableDataSelector(state, { tableId: orderEntriesTableId }),
  };
};

const mapDispatchToProps = (dispatch, { entityToReviewData, fetchEntitiesData }) => {
  const {
    id,
  } = entityToReviewData;

  return {
    fetchOrderEntriesTableData: ({ tableParams }) => dispatch(fetchOrderEntriesRemoteTableData(id, tableParams)),

    ...bindActionCreators(
      {
        completeOrder,
        fetchDataFromServerDataPoint,
      },
      dispatch,
    ),

    /*
    * Обработчик события завершения заказа
    * */
    handleOrderCompleted: (id, name) => {

      sendOrderCompletedNotification(name);

        // перезапрашиваем данные таблицы заказов готовых к завершению
      dispatch(reFetchRemoteTableData(
        ORDER_TYPE.READY_TO_COMPLETE,
        ORDER_TYPE.READY_TO_COMPLETE,
        fetchEntitiesData,
      ));

      // сбрасываем кэш таблицы завершенных заказов и таблицы просмотра содержимого заказа в списке готовых к завершению
      dispatch([
        clearTableRemoteData([
          ORDER_TYPE.COMPLETED,
        ]),
        clearTableData([
          createOrderEntriesTableId(ORDER_TYPE.READY_TO_COMPLETE, id),
        ]),
      ]);

    },
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    orderEntriesTableId,
    orderEntriesTableData,
  } = stateProps;

  const {
    fetchOrderEntriesTableData,
    handleOrderCompleted,
    completeOrder,
    fetchDataFromServerDataPoint,
  } = dispatchProps;

  const {
    entityToReviewData,
    stopEntityReview,
  } = ownProps;

  const {
    id,
    name,
  } = entityToReviewData;

  const checkOrderWarehouseNeedlessEntityBatches = () =>
    fetchDataFromServerDataPoint(getCheckOrderWarehouseNeedlessEntityBatchesDataPoint(id))
      .then(response => _get(response, ['data'], []));

  return {
    orderEntriesTableId,
    orderEntriesTableData,
    fetchOrderEntriesTableData,
    entityToReviewData,

    checkOrderWarehouseNeedlessEntityBatches,
    completeOrder: (writeOff = false) => {
      completeOrder(id, writeOff)
        .then(() => {
          broadcastOrderCompleted(id, name);
          handleOrderCompleted(id, name);
          stopEntityReview();
        });
    },
  };
};

export const OrdersReadyToCompleteReviewContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(OrdersReadyToCompleteReviewContent);