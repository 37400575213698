import { connect } from 'react-redux';

import { mainPlanningSessionIdSelector } from '../../../../reducers/appState/selectors';

import {
  fetchSheetsToStart,
} from '../../../../reducers/plannerApp/sheetsToStart/actions';
import {
  sheetsToStartDataSelector,
  sheetsToStartFiltersSchemaSelector,
  sheetsToStartStateSheetsDataSelector,
} from '../../../../reducers/plannerApp/sheetsToStart/selectors';

import { AllSheetsToStart } from './AllSheetsToStart';


const mapStateToProps = state => ({
  mainPlanningSessionId: mainPlanningSessionIdSelector(state),
  sheetsToStartData: sheetsToStartDataSelector(state),
  filtersSchema: sheetsToStartFiltersSchemaSelector(state),
  currentSheetToStartData: sheetsToStartStateSheetsDataSelector(state),
});

const mapDispatchToProps = {
  fetchSheetsToStart,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    mainPlanningSessionId,
    sheetsToStartData,
    filtersSchema,
    currentSheetToStartData: {
      startDate: currentSheetToStartDataRequestStartDate,
      stopDate: currentSheetToStartDataRequestStopDate,
      dataEntities: currentSheetToStartDataEntities,
    },
  } = stateProps;
  const {
    fetchSheetsToStart,
  } = dispatchProps;
  return {
    ...ownProps,
    mainPlanningSessionId,
    sheetsToStartData,
    filtersSchema,
    currentSheetToStartDataRequestStartDate,
    currentSheetToStartDataRequestStopDate,
    isSheetsToStartDataInStore: currentSheetToStartDataEntities !== null,
    fetchSheetsToStart: (newSheetToStartDataRequestStartDate, newSheetToStartDataRequestStopDate) =>
      fetchSheetsToStart(mainPlanningSessionId, newSheetToStartDataRequestStartDate, newSheetToStartDataRequestStopDate),
  };
};

export const AllSheetsToStartContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AllSheetsToStart);

AllSheetsToStartContainer.displayName = 'AllSheetsToStartContainer';
