import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import _identity from 'lodash/identity';
import { TextField } from '@mui/material';

import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import { MATERIAL_UI_VARIANT } from '../../../constants/materialUI';


const DEFAULT_ON_CHANGE_DEBOUNCE_TIMEOUT_IN_MS = 400;

export const DebouncedTextField = ({
  value: valueFromProps,
  onChange: onChangeFromProps,
  onChangeDebounceTimeoutInMs,
  valueNormalizer,
  ...textFieldRestProps
}) => {

  const [value, setValue] = useState(valueFromProps);

  useEffect(
    () =>
      setValue(
        currentValue =>
          currentValue === valueFromProps ? currentValue : valueFromProps,
      ),
    [valueFromProps, setValue],
  );

  /*
  * Т.к. внутри компонента невозможно контролировать, что для него зададут "неизменяемую" функцию onChangeFromProps, а,
  * также, чтобы и дать такую возможность - задавать для компонента onChangeFromProps через стрелочную функцию, то
  * здесь реализована запись функции в ref. Далее эта ссылка используется в зависимостях useCallback у debouncedOnChange
  * и это исключает кейс, что будут каждый раз создаваться новые задебауншенные функции, что является нежелательным
  * явлением
  * */
  const onChangeCbRef = useRef(onChangeFromProps);

  /*
  * отключаем линтер для useCallback, т.к. внутрь передается функция, которая является результатом вызова debounce и
  * плагин линтера не может определить зависимости хука
  * */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    _debounce(onChangeCbRef.current, onChangeDebounceTimeoutInMs),
    [onChangeCbRef],
  );

  const onChange = useCallback(
    e => {
      const newValue = e.target.value;

      const normalizedNewValue = valueNormalizer(newValue);

      setValue(
        currentValue => {
          if (currentValue === normalizedNewValue) {
            return currentValue;
          }

          debouncedOnChange(normalizedNewValue);

          return normalizedNewValue;
        },
      );

    },
    [setValue, debouncedOnChange, valueNormalizer],
  );

  return (
    <TextField
        {...textFieldRestProps}
        value={value}
        onChange={onChange}
    />
  );
};

DebouncedTextField.defaultProps = {
  value: '',
  onChangeDebounceTimeoutInMs: DEFAULT_ON_CHANGE_DEBOUNCE_TIMEOUT_IN_MS,
  valueNormalizer: _identity,
  variant: MATERIAL_UI_VARIANT.STANDARD,
};

DebouncedTextField.propTypes = {
  value: PropTypes.string,
  onChange: FUNC_IS_REQUIRED_TYPE,
  onChangeDebounceTimeoutInMs: PropTypes.number,
  valueNormalizer: PropTypes.func,
};
