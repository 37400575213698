import React from 'react';
import _get from 'lodash/get';
import { ENTITIES_SERVER_ERROR_MAP } from '../../reducers/entities/actions';
import { NOTIFICATION_LEVEL, sendNotification } from '../../constants/notification';
import { Trans } from '@lingui/macro';

export const getSheetOperationFeatureNameErrorMessage = responseEntitiesWithErrors => {

  const errorIdentity = _get(responseEntitiesWithErrors, [0, '_error', 'identity']);

  if (errorIdentity === ENTITIES_SERVER_ERROR_MAP.REST_RECORD_WITH_SAME_FIELDS_ALREADY_EXISTS) {
    return (
      <Trans id="sheet_operation_settings.sheet_operation_features@feature_with_same_name_already_exist">
        Характеристика с таким именем уже существует, пожалуйста введите другое имя
      </Trans>
    );
  }
};

export const sendNotAllEditedSheetOperationFeatureScopesWasSavedNotification = () => sendNotification(
  <Trans id="sheet_operation_settings.sheet_operation_features@not_all_feature_scopes_was_saved">
    Некоторые добавленные или удалённые области действия характеристик не были сохранены, пожалуйста
    проверьте список областей действия в таблице и отредактируйте его, если потребуется
  </Trans>,
  NOTIFICATION_LEVEL.WARNING,
);

export const sendNotAlCreatedSheetOperationFeatureScopesSavedNotification = () => {
  sendNotification(
    <Trans id="sheet_operation_settings.sheet_operation_features@not_all_added_feature_scopes_saved">
      Не все добавленные области действия были сохранены, пожалуйста проверьте список областей
      действия в таблице и отредактируйте его, если потребуется
    </Trans>,
    NOTIFICATION_LEVEL.ERROR,
  );
};

export const AllAddedFeatureScopesNotSavedTrans = (
  <Trans id="sheet_operation_settings.sheet_operation_features@all_added_feature_scopes_not_saved">
    Все добавленные области действия не сохранены, сервер ответил с ошибкой
  </Trans>
);

export const AllDeletedFeatureScopesNotSavedTrans = (
  <Trans id="sheet_operation_settings.sheet_operation_features@all_deleted_feature_scopes_not_saved">
    Изменения для удалённых областей действия не сохранены, сервер ответил с ошибкой
  </Trans>
);


export const FeatureWasNotCreatedTrans = (
  <Trans id="sheet_operation_settings.sheet_operation_features@feature_was_not_created_error">
    Не удалось создать характеристику операции. Сервер ответил с ошибкой. Произведён откат изменений
  </Trans>
);

export const sendSheetOperationFeatureCreatedNotification = () =>
  sendNotification(
    <Trans id="sheet_operation_settings.sheet_operation_features@feature_successfully_created">
      Характеристика успешно создана
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );

export const sendSheetOperationFeatureCannotBeDeletedNotification = withDeactivationInfo =>
  sendNotification(
    withDeactivationInfo ?
      <Trans id="sheet_operation_settings.sheet_operation_features@feature_cannot_be_deleted_with_deactivation_info">
        Для этой характеристики уже есть записи, она не может быть удалена. Вы можете Деактивировать характеристику
        выбрав соответствующую опцию в контекстном меню таблицы
      </Trans> :
      <Trans id="sheet_operation_settings.sheet_operation_features@feature_cannot_be_deleted">
        Для этой характеристики уже есть записи, она не может быть удалена
      </Trans>,
    NOTIFICATION_LEVEL.WARNING,
  );

export const sendSheetOperationFeatureSuccessfullyDeletedNotification = () =>
  sendNotification(
    <Trans id="sheet_operation_settings.sheet_operation_features@feature_successfully_deleted">
      Характеристика успешно удалена
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );

export const getDeleteSheetOperationFeatureErrorMessage = (responseEntitiesWithErrors, withDeactivationInfo) => {
  const errorIdentity = _get(responseEntitiesWithErrors, [0, '_error', 'identity']);

  if (errorIdentity === ENTITIES_SERVER_ERROR_MAP.REST_REFERENCE_DOES_NOT_EXIST_OR_DEPENDS_ON_OTHER) {
    return withDeactivationInfo ?
      <Trans id="sheet_operation_settings.sheet_operation_features@feature_cannot_be_deleted_with_deactivation_info">
        Для этой характеристики уже есть записи, она не может быть удалена. Вы можете Деактивировать характеристику
        выбрав соответствующую опцию в контекстном меню таблицы
      </Trans> :
      <Trans id="sheet_operation_settings.sheet_operation_features@feature_cannot_be_deleted">
        Для этой характеристики уже есть записи, она не может быть удалена
      </Trans>;
  }
};