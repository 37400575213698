import {
  fetchPausedSheets,
  fetchSheetsInProduction,
  sendSheetPausedNotification,
} from '../../../../operations/sheets';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { matchPath } from 'react-router-dom';
import {
  MASTER_APP_PAUSED_SHEETS_ROUTE,
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_PAUSED_SHEETS_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE, STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_MAIN_ROUTE,
} from '../../../../constants/routes';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { createSheetTypeOperationsTableId, getEquipmentClassInDepartmentTasksTableId } from '../../../../utils/tables';
import {  deleteEntitiesFromStore } from '../../../../reducers/entities/actions';
import { SHEET_MODEL } from '../../../../constants/models';
import {
  clearDefaultSheetPartsAndMaterialsToConsume,
} from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import {
  fetchSheetTypeRemoteTableDataCbFactory,
} from '../../../../components/Sheets/SheetsContainer';
import { push } from 'connected-react-router';
import { fetchEquipmentClassInDepartmentTasksRemoteTableData } from '../../../../operations/tasks';
import { getMasterAppDepartmentIdsFromRouteParam } from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import {
  fetchMasterDepartmentsTasksToDoRemoteTableData,
} from '../../../../operations/masterWorkspace/index';
import { masterTasksToDoAdditionalFiltersSelector } from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import {
  MASTER_APP_PAUSED_SHEETS_TABLE_ID,
  PAUSED_SHEETS_TABLES_IDS_ARRAY,
  PLANNER_APP_PAUSED_SHEETS_TABLE_ID,
} from '../../../../constants/table';
import { fetchFullAssemblySheetsPartsAndMaterials } from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';


/*
* Подробно о влиянии события приостановки МЛ на типы приложения описано в комментарии к handleSheetPaused в
* SheetInProductionReviewContentContainer, MasterTasksToDoContainer и TasksViewScreenContainer.
* В случае с получением события приостановки МЛ другими пользователями нужна похожая обработка, но, с учетом, того, что
* пользователи могут находиться в этот момент в разделах, которые сразу же должны быть обновлены - в этом случае,
* сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если перед перезапросом данных мы сначала
* очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим новые данные, то у пользователя в интерфейсе
* случится "скачок" (данные быстро пропадут, кое-где мелькнет сообщение о пустых данных, а потом тут же появятся новые
* данные), что нежелательно.
* Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
* повторном входе в интерфейсы запросы выполнились заново
* */
export const handleSheetPause = message =>
  (dispatch, getState) => {
    const {
      sheetId,
      sheetIdentity,
    } = message;


    //Выводим нотификейшен о приостановке МЛ
    sendSheetPausedNotification(sheetIdentity);

    const state = getState();

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);

    //Если находимся в разделе "Плановик. МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });
    if(sheetsInProductionRouteMatch !== null)
      return dispatch(_updateIfOnSheetsInProductionScreen(sheetId, currentPathname, allTasksTablesIds));


    //Если находимся в разделе "Плановик. Приостановленные МЛ"
    const plannerAppPausedSheetsRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_PAUSED_SHEETS_ROUTE,
    });
    if(plannerAppPausedSheetsRouteMatch !== null)
      return dispatch(_updateIfOnPlannerAppPausedSheets(
        sheetId,
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Мастер. Приостановленные МЛ"
    const masterAppPausedSheetsRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_PAUSED_SHEETS_ROUTE,
    });
    if(masterAppPausedSheetsRouteMatch !== null)
      return dispatch(_updateIfOnMasterAppPausedSheets(
        sheetId,
        allTasksTablesIds,
      ));


    //Если находимся в разделе "Комплектование - "стандартные" неукомплектованные маршрутные листы"
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Комплектование - "сборочные" маршрутные листы"
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        allTasksTablesIds,
      ));

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_MAIN_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        sheetId,
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
      ));
    }

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за приостановки МЛ, чтобы при следующем
    * входе в эти разделы данные были запрошены заново
    * */
    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);
  };


/*
* Если находимся в разделе "Плановик. МЛ в производстве", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за приостановки МЛ, а для этого раздела перезапрашиваем данные списка для текущей
* страницы.
* Дополнительно, если находимся в режиме просмотра МЛ, который только что был приостановлен, то редиректим пользователя
* на экран просмотра списка МЛ
* */
const _updateIfOnSheetsInProductionScreen = (sheetId, currentPathname, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);


    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.IN_PRODUCTION,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchSheetsInProduction),
    ));

    const sheetInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE}/:sheetId`,
    });

    if(
      sheetInProductionReviewRouteMatch !== null &&
      sheetInProductionReviewRouteMatch.params.sheetId === sheetId.toString()
    ) {
      dispatch(push(PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE));
    }
  };


/*
* Если находимся в интерфейсе просмотра списка приостановленных МЛ в приложении Плановик,
* то очищаем все данные других интерфейсов кроме этого, где должны быть обновления из-за приостановки МЛ, а для этого
* раздела перезапрашиваем данные списка для текущей страницы
* */
const _updateIfOnPlannerAppPausedSheets = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY.filter(tableId => tableId !== PLANNER_APP_PAUSED_SHEETS_TABLE_ID),
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);

    dispatch(reFetchRemoteTableData(
      PLANNER_APP_PAUSED_SHEETS_TABLE_ID,
      PLANNER_APP_PAUSED_SHEETS_TABLE_ID,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchPausedSheets),
    ));
  };

/*
* Если находимся в интерфейсе просмотра списка приостановленных МЛ в приложении Мастер,
* то очищаем все данные других интерфейсов кроме этого, где должны быть обновления из-за приостановки МЛ, а для этого
* раздела перезапрашиваем данные списка для текущей страницы
* */
const _updateIfOnMasterAppPausedSheets = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY.filter(tableId => tableId !== MASTER_APP_PAUSED_SHEETS_TABLE_ID),
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);

    dispatch(reFetchRemoteTableData(
      MASTER_APP_PAUSED_SHEETS_TABLE_ID,
      MASTER_APP_PAUSED_SHEETS_TABLE_ID,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchPausedSheets),
    ));
  };

/*
* Если находимся в разделе "Комлектование - стандартные МЛ", то очищаем данные других интерфейсов кроме этого, где должны
* быть обновления из-за приостановки МЛ.
* Для текущего раздела:
*  - Учитывая все особенности клиентского списка МЛ, ожидающих комплектацию, (описано в комментарии к handleSheetPaused
* в SheetInProductionReviewContentContainer), для обновления списка в данный момент не нужен перезапрос данных, надо
* удалить сущность приостановленного МЛ из entities
* - Не перезапрашиваем данные по возможной комплектации, т.к. в этом случае приостановленный МЛ просто удаляется
* из списка, а возможная комплектация у нас считается для каждого из МЛ отдельно, т.е. остается актуальной, пусть и
* в хранилище останется указатель о возможности комплектации для приостановленного МЛ, это информация не мешает.
* - Данные по детальной комплектации приостановленного МЛ более не нужны, т.к. после восстановления они почти точно
* будут неактуальными, просто удаляем их
* - Если находимся в режиме просмотра конкретного МЛ и это только что приостановленный МЛ, то выполняем редирект на
* список МЛ
*
* Обрабокта должна измениться при переходе на серверную таблицу.
*
* */
const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen = (sheetId, currentPathname, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);

    const defaultSheetWaitingPartsAndMaterialsReviewRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    if(defaultSheetWaitingPartsAndMaterialsReviewRouteMatch === null) return;

    const {
      sheetId: sheetIdFromRoute,
    } = defaultSheetWaitingPartsAndMaterialsReviewRouteMatch.params;

    if(sheetIdFromRoute === sheetId.toString()) {
      return dispatch(push(STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE));
    }
  };


/*
 * Если находимся в разделе "Комплектование - сборочные", то очищаем все данные других интерфейсов кроме
 * этого, где должны быть обновления из-за приостановки МЛ, а для этого раздела перезапрашиваем данные списка
 * сборочных МЛ для текущей страницы.
 * */
const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {
    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchFullAssemblySheetsPartsAndMaterials),
    ));
  };

/*
* Если находимся в разделе просмотра заданий "Мастер. Требуется выполнить", то очищаем все данные других интерфейсов
* кроме этого, где должны быть обновления из-за приостановки МЛ, а для этого раздела перезапрашиваем данные для текущей
* страницы таблицы
* */
const _updateIfOnMasterTasksTodoScreen = (
  sheetId,
  departmentIds,
  allTasksTablesIds,
) =>
  (dispatch, getState) => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_ID,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(departmentIds, masterTasksToDoAdditionalFilters, tableParams)),
    ));
  };


/*
* Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем все данные других
* интерфейсов кроме этого, где должны быть обновления из-за приостановки МЛ, а для этого раздела перезапрашиваем
* задания для текущих параметров просматриваемой таблицы, чтобы информация обновилась
* */
const _updateIfOnTasksViewScreen = (
  sheetId,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
) =>
  dispatch => {

    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
        )),
    ));
  };