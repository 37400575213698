import PropTypes from 'prop-types';
import { FUNC_IS_REQUIRED_TYPE, NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';


export const CARD_MENU_OPTION_TYPE = PropTypes.shape({
  menuItemId: NUMBER_OR_STRING_TYPE.isRequired,
  menuItemTitle: PropTypes.node.isRequired,
  menuItemClick: FUNC_IS_REQUIRED_TYPE,
});

export const CARD_MENU_OPTIONS_TYPE = PropTypes.arrayOf(CARD_MENU_OPTION_TYPE);