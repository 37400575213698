import { fetchDataFromActionPoint, sendAction } from './actionsApi/actions';
import { fetchDataFromDataPoint } from './dataApi/actions';
import { createRequestHandler } from './requestHandlers/requestHandlers';
import { errorHandlerIa, errorHandlerCa } from './requestHandlers/errorHandlers/errorHandlers';


const { CA_BACKEND_SERVER_HOST, IA_BACKEND_SERVER_PROXY_HOST } = window.config;

export const sendActionToServer = createRequestHandler(sendAction, CA_BACKEND_SERVER_HOST, errorHandlerCa);

export const sendActionToIa = createRequestHandler(sendAction, IA_BACKEND_SERVER_PROXY_HOST, errorHandlerIa);

export const fetchDataFromServerActionPoint = createRequestHandler(
  fetchDataFromActionPoint,
  CA_BACKEND_SERVER_HOST,
  errorHandlerCa,
);

export const fetchDataFromIaActionPoint = createRequestHandler(
  fetchDataFromActionPoint,
  IA_BACKEND_SERVER_PROXY_HOST,
  errorHandlerIa,
);

export const fetchDataFromServerDataPoint = createRequestHandler(
  fetchDataFromDataPoint,
  CA_BACKEND_SERVER_HOST,
  errorHandlerCa,
);
