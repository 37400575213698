import { createSelector } from 'reselect';
import {
  fieldComparatorFactory,
  numberComparator,
} from '@bfg-frontend/utils/lib/array';
import { SCHEMA_MODEL } from '../../constants/models';
import { TOOLS_COLUMN_NAME } from '../../constants/table';
import _get from 'lodash/get';


export const schemasSelector = state => state[SCHEMA_MODEL];
export const schemaForModelSelector = (state, { model }) =>
  schemasSelector(state)[model];

export const schemaFieldsForModelSelector = (state, { model }) =>
  _get(schemaForModelSelector(state, { model }), 'fields');

export const schemaModelSortParamsSelector = createSelector(
  schemaForModelSelector,
  schemaModel => _get(schemaModel, ['sortParams', 0]),
);

export const activeSortFieldNameSelector = createSelector(
  schemaModelSortParamsSelector,
  schemaModelSortParams => _get(schemaModelSortParams, ['column'], null),
);

export const tableModelsRelationsSelector = (state, { model }) =>
  _get(schemasSelector(state), [model, 'relations'], {});


export const sortedDisplayedSchemaFieldsSelector = createSelector(
  schemaFieldsForModelSelector,
  (_, { tableParams }) => tableParams,
  (schemaFieldsForModel, tableParams = {}) => {
    if(!schemaFieldsForModel) return [];
    return Object
      .values(schemaFieldsForModel)
      .filter(({ display, columnName }) => {
        //для служебной колонки также проверяем наличие опций в params, иначе смысла её отрисовывать нет
        if(columnName === TOOLS_COLUMN_NAME) {
          return display && !!_get(tableParams, 'toolsOptions', []).length;
        }
        return display;
      })
      .sort(fieldComparatorFactory(numberComparator, 'order'));
  },
);
