import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { CancelLabelTrans } from '../../../../utils/commonTransComponents';
import { SimpleConfirmDialog } from '../../SimpleConfirmDialog/SimpleConfirmDialog';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import { required } from '../../../../utils/formValidators';
import {
  greaterThanValidatorFabric,
  isInRangeValidatorFabric,
  lessThanValidatorFabric,
} from '@bfg-frontend/validators';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { floatNumsNormalizer, onlyNumsNormalizer } from '@bfg-frontend/normalizers';

import './style.css';
import {
  MATERIAL_UI_DIALOG_MAX_WIDTH,
  MATERIAL_UI_STYLE_COLOR,
  MATERIAL_UI_VARIANT,
} from '../../../../constants/materialUI';
import { SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES } from '../constants';
import { SHEET_OPERATION_STATUS } from '../../../../constants/sheets';
import cn from 'classnames';
import { SwitchControl } from '../../SwitchControl/SwitchControl';
import { SheetOperationProgressInput } from '../SheetOperationProgressInput/SheetOperationProgressInput';
import { useDispatch, useSelector } from 'react-redux';
import { sheetOperationProgressModeSelector } from '../../../../reducers/sheetOperationReview/selectors';
import {
  getProgressInAmountFromPercent,
  getProgressInPercentFromAmount,
  prepareSheetOperationProgressValueToDisplay,
  SHEET_OPERATION_PROGRESS_MODE,
} from '../SheetOperationProgressInput/constants';
import { setSheetOperationProgressMode } from '../../../../reducers/sheetOperationReview/actions';
import { useConfirm } from '../../../AppConfirm/AppConfirmContext';
import { subDecimals } from '../../../../utils/decimal';


const progressInPercentLessThenHundredValidator = lessThanValidatorFabric({
  maxValue: 100,
  errorIdentityCreator: () =>
    <Trans id="sheet_operation_review.status_change_dialog@progress_must_be_less_than_hundred">
      Значение прогресса должно быть меньше 100%
    </Trans>,
});


const DIALOG_PARAMS_BY_VIEW_MODE = {
  [SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.PAUSE]: {
    dialogTitle: (
      <Trans id="sheet_operation_review.status_change_dialog@finish_title">
        Приостановление операции
      </Trans>
    ),
    confirmBtnTitle: (
      <Trans id="sheet_operation_review.status_change_dialog@finish_submit_button">
        Остановить
      </Trans>
    ),
  },
  [SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.FINISH]: {
    dialogTitle: (
      <Trans id="sheet_operation_review.status_change_dialog@pause_title">
        Завершение операции
      </Trans>
    ),
    confirmBtnTitle: (
      <Trans id="sheet_operation_review.status_change_dialog@pause_submit_button">
        Завершить
      </Trans>
    ),
  },
};

const newProgressFieldLabel = (
  <Trans id="sheet_operation_review.status_change_dialog@new_progress_field_label">
    Прогресс выполнения
  </Trans>
);

const defectiveEntityBatchProgressFieldLabel = (
  <Trans id="sheet_operation_review.status_change_dialog@defective_entity_batch_progress_field_label">
    Прогресс выполнения бракованной партии, %
  </Trans>
);

const notDefectivePartProgressFieldLabel = (
  <Trans id="sheet_operation_review.status_change_dialog@not_defective_part_progress_field_label">
    Прогресс выполнения небракованной части партии
  </Trans>
);

const markDefectiveEntityBatchCheckboxLabel = (
  <Trans id="sheet_operation_review.status_change_dialog@mark_defective_entity_batch_checkbox_label">
    Отметить всю партию как брак
  </Trans>
);

const defectiveEntitiesMarkingFormSwitchLabel = (
  <Trans id="sheet_operation_review.status_change_dialog@defective_entities_marking_form_switch_label">
    Отметить брак
  </Trans>
);

const entityBatchAmountLabel = (
  <Trans id="sheet_operation_review.status_change_dialog@entity_batch_amount_label">
    Размер партии
  </Trans>
);

const defectivePartAmountFieldLabel = (
  <Trans id="sheet_operation_review.status_change_dialog@defective_part_amount_field_label">
    Количество бракованных ДСЕ
  </Trans>
);

const defectivePartProgressFieldLabel = (
  <Trans id="sheet_operation_review.status_change_dialog@defective_part_progress_field_label">
    Прогресс выполнения бракованной части партии, %
  </Trans>
);

const defectReasonLabel = (
  <Trans id="sheet_operation_review.status_change_dialog@defect_reason_label">
    Причина брака
  </Trans>
);

const NEW_PROGRESS_FIELD_KEY = 'NEW_PROGRESS_FIELD_KEY';
const NOT_DEFECTIVE_PART_PROGRESS_FIELD_KEY = 'NOT_DEFECTIVE_PART_PROGRESS_FIELD_KEY';
const DEFECTIVE_PART_AMOUNT_FIELD_KEY = 'DEFECTIVE_PART_AMOUNT_FIELD_KEY';
const DEFECTIVE_PART_PROGRESS_FIELD_SERVICE_KEYS = ['KEY_A', 'KEY_B'];

const DEFECT_REASON_TEXTAREA_ROWS_AMOUNT = 3;

const SET_IS_DEFECTIVE_ENTITIES_MARKING_FORM_OPEN = 'SET_IS_DEFECTIVE_ENTITIES_MARKING_FORM_OPEN';
const SET_IS_MARK_DEFECTIVE_WHOLE_ENTITY_BATCH_CHECKBOX_CHECKED = 'SET_IS_MARK_DEFECTIVE_WHOLE_ENTITY_BATCH_CHECKBOX_CHECKED';
const SET_NOT_DEFECTIVE_PART_PROGRESS = 'SET_NOT_DEFECTIVE_PART_PROGRESS';
const SET_NEW_PROGRESS = 'SET_NEW_PROGRESS';
const SET_DEFECTIVE_PART_AMOUNT = 'SET_DEFECTIVE_PART_AMOUNT';
const SET_DEFECTIVE_PART_PROGRESS = 'SET_DEFECTIVE_PART_PROGRESS';
const SET_DEFECT_REASON = 'SET_DEFECT_REASON';
const SET_IS_DEFECT_REASON_FIELD_TOUCHED = 'SET_IS_DEFECT_REASON_FIELD_TOUCHED';
const RESET_FORM_VALUES = 'RESET_FORM_VALUES';
const CHANGE_SHEET_OPERATION_PROGRESS_MODE = 'CHANGE_SHEET_OPERATION_PROGRESS_MODE';

const statusChangeDialogReducer = (state, action) => {
  switch(action.type) {
  case SET_IS_DEFECTIVE_ENTITIES_MARKING_FORM_OPEN:

    const { isDefectiveEntitiesMarkingFormOpen } = action;

    /*
    * обрабатываем кейс, когда мы ввели изменения в форму брака и нажали чекбокс скрытия формы брака. В этом случае
    * необходимо стереть все данные формы брака, чтобы при повторном входе не увидеть ошибок на форме. Поэтому сбрасываем
    * весь локальный стейт на начальные значения кроме значений переключателя формы брака и значения инпута нового прогресса
    * операции, т.к. он не находится на форме задания брака
    * */
    if(!isDefectiveEntitiesMarkingFormOpen) {
      return {
        ...action.initialState,
        newProgress: state.newProgress,
        isDefectiveEntitiesMarkingFormOpen,
      };
    }

    return {
      ...state,
      isDefectiveEntitiesMarkingFormOpen,
    };
  case SET_IS_MARK_DEFECTIVE_WHOLE_ENTITY_BATCH_CHECKBOX_CHECKED:
    const { isMarkDefectiveWholeEntityBatchCheckboxChecked } = action;
    return {
      ...state,
      isMarkDefectiveWholeEntityBatchCheckboxChecked,
    };
  case SET_NOT_DEFECTIVE_PART_PROGRESS:
    const { notDefectivePartProgress } = action;

    return {
      ...state,
      notDefectivePartProgress,
    };
  case SET_NEW_PROGRESS:
    const { newProgress } = action;

    return {
      ...state,
      newProgress,
    };
  case SET_DEFECTIVE_PART_AMOUNT:
    const { defectivePartAmount } = action;
    return {
      ...state,
      defectivePartAmount,
    };
  case SET_DEFECTIVE_PART_PROGRESS:
    const { defectivePartProgress } = action;
    return {
      ...state,
      defectivePartProgress,
    };
  case SET_DEFECT_REASON:
    const { defectReason } = action;
    return {
      ...state,
      defectReason,
    };
  case SET_IS_DEFECT_REASON_FIELD_TOUCHED:
    return {
      ...state,
      isDefectReasonFieldTouched: true,
    };
  case CHANGE_SHEET_OPERATION_PROGRESS_MODE:
    const { initialSheetOperationProgress } = action;
    const initialSheetOperationProgressForTextField = prepareSheetOperationProgressValueToDisplay(
      initialSheetOperationProgress,
    );
    return {
      ...state,
      newProgress: initialSheetOperationProgressForTextField,
      notDefectivePartProgress: initialSheetOperationProgressForTextField,
    };
  case RESET_FORM_VALUES:
    const { initialState } = action;
    return initialState;
  default:
    return state;
  }
};

export const SheetOperationStatusChangeDialog = props => {

  const {
    isOpen,
    closeDialog,
    viewMode,
    sheetOperationData,
    onSubmit: onSubmitFromProps,
    isDefectiveEntitiesMarkingPermissionGranted,
  } = props;

  const {
    entityBatchId,
    sheetOperationId,
    entitiesInBatchAmount,
    progress: initialSheetOperationProgressInPercents,
  } = sheetOperationData;

  const sheetOperationProgressMode = useSelector(state => sheetOperationProgressModeSelector(state));

  const isCurrentProgressModePercent = useMemo(
    () => sheetOperationProgressMode === SHEET_OPERATION_PROGRESS_MODE.PERCENT,
    [sheetOperationProgressMode],
  );

  const initialSheetOperationProgressInAmount = useMemo(
    () => getProgressInAmountFromPercent(entitiesInBatchAmount, initialSheetOperationProgressInPercents),
    [initialSheetOperationProgressInPercents, entitiesInBatchAmount],
  );

  const initialSheetOperationProgress = useMemo(
    () => isCurrentProgressModePercent ?
      initialSheetOperationProgressInPercents :
      initialSheetOperationProgressInAmount,
    [isCurrentProgressModePercent, initialSheetOperationProgressInPercents, initialSheetOperationProgressInAmount],
  );

  const statusChangeDialogInitialState = useMemo(
    () => {

      const initialSheetOperationProgressForTextField = prepareSheetOperationProgressValueToDisplay(
        initialSheetOperationProgress,
      );

      return {
        isDefectiveEntitiesMarkingFormOpen: false,
        isMarkDefectiveWholeEntityBatchCheckboxChecked: true,
        notDefectivePartProgress: initialSheetOperationProgressForTextField,
        newProgress: initialSheetOperationProgressForTextField,
        //назначаем по умолчанию единицу, просто чтобы было какое-то значение, иначе при 0 сразу ошибка
        defectivePartAmount: 1,
        defectivePartProgress: 0,
        defectReason: '',
        isDefectReasonFieldTouched: false,
      };
    },
    [initialSheetOperationProgress],
  );

  const [ state, dispatch ] = useReducer(
    statusChangeDialogReducer,
    statusChangeDialogInitialState,
  );

  const {
    isDefectiveEntitiesMarkingFormOpen,
    isMarkDefectiveWholeEntityBatchCheckboxChecked,
    notDefectivePartProgress,
    newProgress,
    defectivePartAmount,
    defectivePartProgress,
    defectReason,
    isDefectReasonFieldTouched,
  } = state;

  const setIsDefectiveEntitiesMarkingFormOpen = useCallback(
    isDefectiveEntitiesMarkingFormOpen => dispatch({
      type: SET_IS_DEFECTIVE_ENTITIES_MARKING_FORM_OPEN,
      initialState: statusChangeDialogInitialState,
      isDefectiveEntitiesMarkingFormOpen,
    }),
    [statusChangeDialogInitialState],
  );

  const setIsMarkDefectiveWholeEntityBatchCheckboxChecked = useCallback(
    isMarkDefectiveWholeEntityBatchCheckboxChecked => dispatch({
      type: SET_IS_MARK_DEFECTIVE_WHOLE_ENTITY_BATCH_CHECKBOX_CHECKED,
      isMarkDefectiveWholeEntityBatchCheckboxChecked,
    }),
    [],
  );

  const setDefectivePartAmount = useCallback(
    defectivePartAmount => dispatch({ type: SET_DEFECTIVE_PART_AMOUNT, defectivePartAmount }),
    [],
  );

  const defectivePartAmountError =
    _getDefectivePartAmountError(
      isDefectiveEntitiesMarkingFormOpen,
      isMarkDefectiveWholeEntityBatchCheckboxChecked,
      defectivePartAmount,
      sheetOperationData,
    );

  /*
  * Количество в небракованной части партии, которое получится, если вводимое значение в поле defectivePartAmount
  * будет засабмичено. Функция _getNotDefectiveEntitiesInBatchAmount обрабатывает, также, различные
  * ошибки ввода и особые случаи, когда просто вычитание двух числе выдавало бы ошибку.
  * */
  const notDefectiveEntitiesInBatchAmount = _getNotDefectiveEntitiesInBatchAmount(
    isDefectiveEntitiesMarkingFormOpen,
    isMarkDefectiveWholeEntityBatchCheckboxChecked,
    defectivePartAmount,
    sheetOperationData,
    defectivePartAmountError,
  );

  const setDefectivePartProgress = useCallback(
    defectivePartProgress => dispatch({ type: SET_DEFECTIVE_PART_PROGRESS, defectivePartProgress }),
    [],
  );

  const defectivePartProgressError =
    _getDefectivePartProgressError(isDefectiveEntitiesMarkingFormOpen, defectivePartProgress);


  const setNotDefectivePartProgress = useCallback(
    notDefectivePartProgress => dispatch({ type: SET_NOT_DEFECTIVE_PART_PROGRESS, notDefectivePartProgress }),
    [],
  );

  const notDefectivePartProgressError = _getNotDefectivePartProgressError(
    notDefectivePartProgress,
    isDefectiveEntitiesMarkingFormOpen,
    isMarkDefectiveWholeEntityBatchCheckboxChecked,
    isCurrentProgressModePercent,
    viewMode,
    defectivePartAmountError,
    notDefectiveEntitiesInBatchAmount,
  );

  const setNewProgress = useCallback(
    newProgress => dispatch({ type: SET_NEW_PROGRESS, newProgress }),
    [],
  );

  const newProgressError = _getNewProgressError(
    newProgress,
    isDefectiveEntitiesMarkingFormOpen,
    isMarkDefectiveWholeEntityBatchCheckboxChecked,
    isCurrentProgressModePercent,
    viewMode,
    initialSheetOperationProgress,
    entitiesInBatchAmount,
  );

  const setDefectReason = useCallback(
    defectReason => dispatch({ type: SET_DEFECT_REASON, defectReason }),
    [],
  );

  const setIsDefectReasonFieldTouched = useCallback(
    () => dispatch({ type: SET_IS_DEFECT_REASON_FIELD_TOUCHED }),
    [],
  );

  const reduxDispatch = useDispatch();

  const confirm = useConfirm();

  const onSheetOperationProgressModeChange = useCallback(
    value =>
      confirm({
        confirmText: (
          <Trans id="sheet_operation_review.status_change_dialog@change_progress_mode_confirm_text">
            При изменении режима ввода прогресса по операции, текущее значение прогресса будет пересчитано, несохраненные
            данные будут потеряны. Вы уверены?
          </Trans>
        ),
        confirmDialogInnerProps: {
          dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
        },
      })
        .then(() => {
          reduxDispatch(setSheetOperationProgressMode(value));

          dispatch({
            type: CHANGE_SHEET_OPERATION_PROGRESS_MODE,
            initialSheetOperationProgress: value === SHEET_OPERATION_PROGRESS_MODE.PERCENT ?
              initialSheetOperationProgressInPercents :
              initialSheetOperationProgressInAmount,
          });
        }),
    [reduxDispatch, dispatch, initialSheetOperationProgressInPercents, initialSheetOperationProgressInAmount, confirm],
  );

  //TODO раскомментировать код, когда станем отображать поле для ввода причины брака, в MVP оно скрыто
  const defectReasonError = undefined; //required(defectReason);

  const {
    dialogTitle,
    confirmBtnTitle,
  } = DIALOG_PARAMS_BY_VIEW_MODE[viewMode];

  const renderProps = {
    entitiesInBatchAmount,
    notDefectiveEntitiesInBatchAmount,

    sheetOperationProgressMode,
    isCurrentProgressModePercent,
    onSheetOperationProgressModeChange,

    viewMode,
    sheetOperationData,
    isDefectiveEntitiesMarkingPermissionGranted,

    isDefectiveEntitiesMarkingFormOpen,
    setIsDefectiveEntitiesMarkingFormOpen,

    notDefectivePartProgress,
    setNotDefectivePartProgress,
    notDefectivePartProgressError,

    defectivePartAmount,
    setDefectivePartAmount,
    defectivePartAmountError,

    defectivePartProgress,
    setDefectivePartProgress,
    defectivePartProgressError,

    isMarkDefectiveWholeEntityBatchCheckboxChecked,
    setIsMarkDefectiveWholeEntityBatchCheckboxChecked,

    defectReason,
    setDefectReason,
    defectReasonError,
    isDefectReasonFieldTouched,
    setIsDefectReasonFieldTouched,

    newProgress,
    setNewProgress,
    newProgressError,
  };

  const formDataError = useMemo(
    () => !isDefectiveEntitiesMarkingFormOpen ?
      newProgressError :
      defectivePartAmountError || defectivePartProgressError || notDefectivePartProgressError || defectReasonError,
    [
      isDefectiveEntitiesMarkingFormOpen,
      notDefectivePartProgressError,
      defectivePartAmountError,
      defectivePartProgressError,
      defectReasonError,
      newProgressError,
    ],
  );

  /*
  * Храним данные в форме в обновляемом рефе, чтобы оптимизировать функцию отправки данных формы с использованием
  * хука useCallback, т.е. не пересоздавать его на каждое изменение данных на форме.
  * */
  const formDataRef = useRef({});
  useEffect(
    () => {
      formDataRef.current = {
        notDefectivePartProgress,
        isDefectiveEntitiesMarkingFormOpen,
        isMarkDefectiveWholeEntityBatchCheckboxChecked,
        defectivePartAmount,
        defectivePartProgress,
        defectReason,
        newProgress,
      };
    },
  );

  const onSubmit = useCallback(
    () => {

      /*
      * формируем набор данных для отправки формы в следующем формате:
      * {
      *   entityBatchId,
      *   operationId,
      *   operationStatus,
      *   notDefectivePartOperationProgress,
      *   defectivePartAmount,
      *   defectivePartOperationProgress,
      *   defectReason,
      * }
      * */
      const dataToSubmit = {
        entityBatchId,
        sheetOperationId,
        sheetOperationStatus: viewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.PAUSE ?
          SHEET_OPERATION_STATUS.PAUSED :
          SHEET_OPERATION_STATUS.FINISHED,
        ..._getDefectDataToSubmit(
          formDataRef.current,
          entitiesInBatchAmount,
          viewMode,
          isCurrentProgressModePercent,
        ),
      };

      return onSubmitFromProps(dataToSubmit);
    },
    [
      viewMode,
      entityBatchId,
      sheetOperationId,
      entitiesInBatchAmount,
      onSubmitFromProps,
      isCurrentProgressModePercent,
    ],
  );

  return (
    <div>
      <SimpleConfirmDialog
          className="sheet-operation-status-change-dialog"
          dialogMaxWidth={MATERIAL_UI_DIALOG_MAX_WIDTH.SM}
          isOpen={isOpen}
          closeDialog={closeDialog}
          title={dialogTitle}
          additionalComponent={_renderAdditionalComponent(renderProps)}
          confirmBtn={confirmBtnTitle}
          cancelBtn={CancelLabelTrans}
          onConfirm={onSubmit}
          disableConfirm={!!formDataError}
      />
    </div>
  );
};

const _renderAdditionalComponent = renderProps => {
  const {
    viewMode,
    isDefectiveEntitiesMarkingPermissionGranted,
  } = renderProps;

  return (
    <React.Fragment>
      {
        isDefectiveEntitiesMarkingPermissionGranted ?
          _renderDefectiveEntitiesMarkingSection(renderProps) :
          null
      }
      {
        viewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.PAUSE ?
          _renderNewProgressTextField(renderProps) :
          null
      }
    </React.Fragment>
  );
};

const _renderDefectiveEntitiesMarkingSection = renderProps => {
  const {
    isDefectiveEntitiesMarkingFormOpen,
    setIsDefectiveEntitiesMarkingFormOpen,
  } = renderProps;

  return (
    <React.Fragment>
      <SwitchControl
          checked={isDefectiveEntitiesMarkingFormOpen}
          onChange={setIsDefectiveEntitiesMarkingFormOpen}
          label={defectiveEntitiesMarkingFormSwitchLabel}
          className="sheet-operation-status-change-dialog__defective-entities-marking-form-switch"
      />
      <Collapse in={isDefectiveEntitiesMarkingFormOpen}>
        {_renderDefectiveEntitiesMarkingForm(renderProps)}
      </Collapse>
    </React.Fragment>
  );
};

//TODO в MVP поле для ввода причины прогресса скрыто, в будущем нужно добавить _renderDefectReasonField в самый низ формы
const _renderDefectiveEntitiesMarkingForm = renderProps => (
  <div>
    <div className="sheet-operation-status-change-dialog__entity-batch-row">
      {_renderEntityBatchInfo(renderProps)}
      {_renderMarkDefectiveWholeEntityBatchCheckbox(renderProps)}
    </div>
    {_renderDefectivePartAmountTextField(renderProps)}
    {_renderDefectivePartProgressTextField(renderProps)}
    {_renderNotDefectivePartProgressTextField(renderProps)}
  </div>
);

//eslint-disable-next-line
const _renderDefectReasonField = renderProps => {
  const {
    defectReason,
    setDefectReason,
    defectReasonError,
    isDefectReasonFieldTouched,
    setIsDefectReasonFieldTouched,
  } = renderProps;

  const error = isDefectReasonFieldTouched && !!defectReasonError;

  return (
    <div className="sheet-operation-status-change-dialog__defect-reason-text-area-wrap">
      <span
          className={
            cn(
              'sheet-operation-status-change-dialog__defect-reason-text-area-label',
              { 'sheet-operation-status-change-dialog__defect-reason-text-area-label--has-error': error },
            )
          }
      >
        {defectReasonLabel}
      </span>
      <TextField
          id="sheet-operation-status-change-dialog__defect-reason-text-area-id"
          className="sheet-operation-status-change-dialog__defect-reason-text-area"
          value={defectReason}
          onChange={e => setDefectReason(e.target.value)}
          error={error}
          helperText={error ? defectReasonError : undefined}
          onBlur={setIsDefectReasonFieldTouched}
          rows={DEFECT_REASON_TEXTAREA_ROWS_AMOUNT}
          variant={MATERIAL_UI_VARIANT.OUTLINED}
          multiline
          fullWidth
      />
    </div>
  );
};

const _renderEntityBatchInfo = renderProps => {
  const {
    sheetOperationData: {
      entitiesInBatchAmount,
    },
  } = renderProps;

  return (
    <div className="sheet-operation-status-change-dialog__defective-entities-marking-section-entity-batch-amount">
      {entityBatchAmountLabel}
      {': '}
      {entitiesInBatchAmount}
    </div>
  );
};

const _renderDefectivePartAmountTextField = renderProps => {
  const {
    defectivePartAmount,
    setDefectivePartAmount,
    defectivePartAmountError,
    isMarkDefectiveWholeEntityBatchCheckboxChecked,
  } = renderProps;

  if(isMarkDefectiveWholeEntityBatchCheckboxChecked) {
    return null;
  }

  return _renderTextField({
    value: defectivePartAmount,
    onChange: e => setDefectivePartAmount(floatNumsNormalizer(e.target.value)),
    error: defectivePartAmountError,
    label: defectivePartAmountFieldLabel,
    key: DEFECTIVE_PART_AMOUNT_FIELD_KEY,

    /*
    * поле перемонтируется по нажатию чекбокса отметки всей партии как брак, поэтому тут просто ставим autoFocus, чтобы
    * каждый раз при появлении поле было выделено
    * */
    autoFocus: true,
  });
};

const _renderDefectivePartProgressTextField = renderProps => {

  if(renderProps.viewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.FINISH) {
    return null;
  }

  const {
    defectivePartProgress,
    setDefectivePartProgress,
    defectivePartProgressError,
    isMarkDefectiveWholeEntityBatchCheckboxChecked,
  } = renderProps;

  return _renderTextField({
    value: defectivePartProgress,
    onChange: e => setDefectivePartProgress(onlyNumsNormalizer(e.target.value)),
    error: defectivePartProgressError,
    label: isMarkDefectiveWholeEntityBatchCheckboxChecked ?
      defectiveEntityBatchProgressFieldLabel :
      defectivePartProgressFieldLabel,

    /*
    * это поле присутствует на экране как при отметке всей партии так и при отметке ее части, поэтому оно сохраняет
    * дефолтное значение автофокуса при открытии формы брака. Поэтому используем разные key для поля, чтобы контролировать
    * фокус
    * */
    autoFocus: !!isMarkDefectiveWholeEntityBatchCheckboxChecked,
    key: isMarkDefectiveWholeEntityBatchCheckboxChecked ?
      DEFECTIVE_PART_PROGRESS_FIELD_SERVICE_KEYS[0] :
      DEFECTIVE_PART_PROGRESS_FIELD_SERVICE_KEYS[1],
  });
};

/* eslint-disable react/prop-types */
const _renderMarkDefectiveWholeEntityBatchCheckbox = ({
  isMarkDefectiveWholeEntityBatchCheckboxChecked,
  setIsMarkDefectiveWholeEntityBatchCheckboxChecked,
}) =>
  /* eslint-enable react/prop-types */
(
  <FormControlLabel
      className="sheet-operation-status-change-dialog__mark-whole-entity-batch-defective-checkbox"
      control={
        <Checkbox
            checked={isMarkDefectiveWholeEntityBatchCheckboxChecked}
            onChange={e => setIsMarkDefectiveWholeEntityBatchCheckboxChecked(e.target.checked)}
            color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
        />
      }
      label={markDefectiveEntityBatchCheckboxLabel}
  />
);

const _renderNotDefectivePartProgressTextField = renderProps => {

  const {
    viewMode,
    isMarkDefectiveWholeEntityBatchCheckboxChecked,
  } = renderProps;

  if(
    viewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.FINISH ||
    isMarkDefectiveWholeEntityBatchCheckboxChecked
  ) {
    return null;
  }


  const {
    notDefectiveEntitiesInBatchAmount,
    sheetOperationProgressMode,
    onSheetOperationProgressModeChange,
    notDefectivePartProgress,
    setNotDefectivePartProgress,
    notDefectivePartProgressError,
  } = renderProps;

  return (
    <SheetOperationProgressInput
        entitiesInBatchAmount={notDefectiveEntitiesInBatchAmount}
        label={notDefectivePartProgressFieldLabel}
        progress={notDefectivePartProgress}
        sheetOperationProgressMode={sheetOperationProgressMode}
        onSheetOperationProgressModeChange={onSheetOperationProgressModeChange}
        key={NOT_DEFECTIVE_PART_PROGRESS_FIELD_KEY}
        error={notDefectivePartProgressError}
        onChange={setNotDefectivePartProgress}
    />
  );
};

const _renderNewProgressTextField = renderProps => {
  const {
    newProgress,
    setNewProgress,
    newProgressError,
    isDefectiveEntitiesMarkingFormOpen,
    entitiesInBatchAmount,
    sheetOperationProgressMode,
    onSheetOperationProgressModeChange,
  } = renderProps;

  if(isDefectiveEntitiesMarkingFormOpen) {
    return null;
  }

  return (
    <SheetOperationProgressInput
        entitiesInBatchAmount={entitiesInBatchAmount}
        label={newProgressFieldLabel}
        progress={newProgress}
        sheetOperationProgressMode={sheetOperationProgressMode}
        onSheetOperationProgressModeChange={onSheetOperationProgressModeChange}
        autoFocus
        key={NEW_PROGRESS_FIELD_KEY}
        error={newProgressError}
        onChange={setNewProgress}
    />
  );
};

// eslint-disable-next-line react/prop-types
const _renderTextField = ({ value, onChange, error, label, isDisabled, autoFocus, key }) =>
  <div className="sheet-operation-status-change-dialog__text-field-wrap">
    <TextField
        id="sheet-operation-status-change-dialog__text-field-id"
        className="sheet-operation-status-change-dialog__text-field"
        type="text"
        key={key}
        label={label}
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={error}
        autoFocus={autoFocus}
        fullWidth
        disabled={isDisabled}
        InputLabelProps={{
          shrink: true,
        }}
        variant={MATERIAL_UI_VARIANT.STANDARD}
    />
  </div>;

const _getDefectivePartAmountError = (
  isDefectiveEntitiesMarkingFormOpen,
  isMarkDefectiveWholeEntityBatchCheckboxChecked,
  defectivePartAmount,
  sheetOperationData,
) => {
  if(!isDefectiveEntitiesMarkingFormOpen || isMarkDefectiveWholeEntityBatchCheckboxChecked) return undefined;

  const {
    entitiesInBatchAmount,
  } = sheetOperationData;

  const lessThanValidator = isInRangeValidatorFabric({
    maxValue: entitiesInBatchAmount,
    maxValueInclusive: false,
    minValue: 0,
    minValueInclusive: false,
    errorIdentityCreator: () =>
      <Trans id="sheet_operation_review.status_change_dialog@defective_part_amount_must_be_in_correct_range">
        Количество бракованных ДСЕ должно быть больше 0, но меньше текущего размера партии - {entitiesInBatchAmount}
      </Trans>,
  });

  return required(defectivePartAmount) || lessThanValidator(Number(defectivePartAmount));
};

const _getNotDefectiveEntitiesInBatchAmount = (
  isDefectiveEntitiesMarkingFormOpen,
  isMarkDefectiveWholeEntityBatchCheckboxChecked,
  defectivePartAmount,
  sheetOperationData,
  defectivePartAmountError,
) => {

  const {
    entitiesInBatchAmount,
  } = sheetOperationData;

  /*
  * При данном условии, значение, высчитываемое этой функцией не важно, но, всё равно, выдаем логически правильное
  * значение: если брак не отмечается, то небракованная часть партии - это всё количество партии
  * */
  if(!isDefectiveEntitiesMarkingFormOpen) {
    return entitiesInBatchAmount;
  }

  /*
  * При данном условии, значение, высчитываемое этой функцией не важно, но, всё равно, выдаем логически правильное
  * значение: если всё количество отправляется в брак, значит размер небракованной части партии после сабмита станет 0
  * */
  if(isMarkDefectiveWholeEntityBatchCheckboxChecked) {
    return 0;
  }

  /*
  * Если в поле ввода количества бракованных ДСЕ допущена ошибка, то в логике этой функции предполагаем, что брак не
  * введен и выдаем общее количество, хотя в обработке вне этой функции это будет особый случай и это значение
  * использоваться не будет
  * */
  if(!!defectivePartAmountError) {
    return entitiesInBatchAmount;
  }

  return subDecimals(Number(entitiesInBatchAmount), Number(defectivePartAmount));

};

const _getDefectivePartProgressError = (isDefectiveEntitiesMarkingFormOpen, defectivePartProgress) => {
  if(!isDefectiveEntitiesMarkingFormOpen) return undefined;

  return required(defectivePartProgress) ||
    progressInPercentLessThenHundredValidator(Number(defectivePartProgress));
};

const _getNotDefectivePartProgressError = (
  notDefectivePartProgress,
  isDefectiveEntitiesMarkingFormOpen,
  isMarkDefectiveWholeEntityBatchCheckboxChecked,
  isCurrentProgressModePercent,
  viewMode,
  defectivePartAmountError,
  notDefectiveEntitiesInBatchAmount,
) => {

  if(viewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.FINISH) return undefined;

  if(isDefectiveEntitiesMarkingFormOpen && isMarkDefectiveWholeEntityBatchCheckboxChecked) return undefined;

  if(!!defectivePartAmountError) {
    return (
      <Trans id="sheet_operation_review.status_change_dialog@defective-part-amount-error-should-be-fixed">
        Необходимо исправить ошибку в поле "Количество бракованных ДСЕ"
      </Trans>
    );
  }

  const notDefectivePartProgressLessThenValidator = isCurrentProgressModePercent ?
    progressInPercentLessThenHundredValidator :
    lessThanValidatorFabric({
      maxValue: notDefectiveEntitiesInBatchAmount,
      errorIdentityCreator: () =>
        <Trans id="sheet_operation_review.status_change_dialog@progress_must_be_less_than_not_defective_entities_in_batch_amount">
          Значение прогресса должно быть меньше нового размера партии после отметки брака - { notDefectiveEntitiesInBatchAmount }
        </Trans>,
    });

  const notDefectivePartProgressNumber = Number(notDefectivePartProgress);

  return(
    required(notDefectivePartProgress) ||
    notDefectivePartProgressLessThenValidator(notDefectivePartProgressNumber)
  );
};


const _getNewProgressError = (
  newProgress,
  isDefectiveEntitiesMarkingFormOpen,
  isMarkDefectiveWholeEntityBatchCheckboxChecked,
  isCurrentProgressModePercent,
  viewMode,
  initialSheetOperationProgress,
  entitiesInBatchAmount,
) => {

  if(viewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.FINISH) return undefined;

  if(isDefectiveEntitiesMarkingFormOpen && isMarkDefectiveWholeEntityBatchCheckboxChecked) return undefined;

  const newProgressGreaterOrEqualToCurrentValidator = greaterThanValidatorFabric({
    minValue: initialSheetOperationProgress,
    orEqual: true,
    errorIdentityCreator: (_, { minValue }) => Number.isInteger(initialSheetOperationProgress) ?
      <Trans id="sheet_operation_review.status_change_dialog@new_progress_must_be_greater_or_equal_to_current">
        Новое значение прогресса должно быть больше или равно текущему - {minValue}
      </Trans> :
      <Trans id="sheet_operation_review.status_change_dialog@new_progress_must_be_greater_than_current">
        Новое значение прогресса должно быть больше текущего - {Math.floor(minValue)}
      </Trans>,
  });

  const newProgressLessThenValidator = isCurrentProgressModePercent ?
    progressInPercentLessThenHundredValidator :
    lessThanValidatorFabric({
      maxValue: entitiesInBatchAmount,
      errorIdentityCreator: () =>
        <Trans id="sheet_operation_review.status_change_dialog@progress_must_be_less_than_entities_in_batch_amount">
          Значение прогресса должно быть меньше размера партии - { entitiesInBatchAmount }
        </Trans>,
    });

  const newProgressNumber = Number(newProgress);

  return(
    required(newProgress) ||
    newProgressGreaterOrEqualToCurrentValidator(newProgressNumber) ||
    newProgressLessThenValidator(newProgressNumber)
  );
};

const _getDefectDataToSubmit = (
  formData,
  entitiesInBatchAmount,
  viewMode,
  isCurrentProgressModePercent,
) => {

  const {
    notDefectivePartProgress,
    isDefectiveEntitiesMarkingFormOpen,
    isMarkDefectiveWholeEntityBatchCheckboxChecked,
    defectivePartAmount,
    defectivePartProgress,
    defectReason,
    newProgress,
  } = formData;

  if(isDefectiveEntitiesMarkingFormOpen) {
    return {
      defectivePartAmount: isMarkDefectiveWholeEntityBatchCheckboxChecked ?
        entitiesInBatchAmount :
        Number(defectivePartAmount),
      defectivePartOperationProgress: viewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.FINISH ?
        null :
        Number(defectivePartProgress),
      notDefectivePartOperationProgress:
        isMarkDefectiveWholeEntityBatchCheckboxChecked || viewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.FINISH ?
          null :
          _getProgressInPercents(
            Number(notDefectivePartProgress),
            isCurrentProgressModePercent,

            //Сабмит возможен только если все валидации прошли, поэтому defectivePartAmount здесь уже можно не проверять
            subDecimals(Number(entitiesInBatchAmount), Number(defectivePartAmount)),
          ),
      defectReason,
      isMarkDefectiveWholeEntityBatch: isMarkDefectiveWholeEntityBatchCheckboxChecked,
    };
  }

  return {
    defectivePartAmount: null,
    notDefectivePartOperationProgress: viewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.FINISH ?
      null :
      _getProgressInPercents(Number(newProgress), isCurrentProgressModePercent, entitiesInBatchAmount),
    defectivePartOperationProgress: null,
    defectReason: null,
    isMarkDefectiveWholeEntityBatch: null,
  };
};

const _getProgressInPercents = (progressFromTextField, isCurrentProgressModePercent, entitiesInBatchAmount) => {
  if(isCurrentProgressModePercent) {
    return progressFromTextField;
  }

  return getProgressInPercentFromAmount(entitiesInBatchAmount, progressFromTextField);
};

SheetOperationStatusChangeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: FUNC_IS_REQUIRED_TYPE,
  viewMode: PropTypes.oneOf(Object.values(SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES)).isRequired,
  onSubmit: FUNC_IS_REQUIRED_TYPE,
  isDefectiveEntitiesMarkingPermissionGranted: PropTypes.bool.isRequired,
  sheetOperationData: PropTypes.shape({
    entityBatchId: PropTypes.number.isRequired,
    sheetOperationId: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    entitiesInBatchAmount: PropTypes.number.isRequired,
  }).isRequired,
};
