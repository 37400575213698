import React, { Component } from 'react';

import { SheetsContainer } from '../../Sheets/SheetsContainer';
import { CompletedSheetReviewContentContainer } from './CompletedSheetReviewContent/CompletedSheetReviewContentContainer';

import { PLANNER_APP_COMPLETED_SHEETS_ROUTE } from '../../../constants/routes';
import { SHEET_TYPE } from '../../../constants/sheets';
import { COMPLETED_SHEETS_FILTERS_SCHEMA } from '../../Sheets/sheetsFilters/sheetsFilters';
import { fetchCompletedSheets } from '../../../operations/sheets';
import { Trans } from '@lingui/macro';
import { getSheetDefectInfoLabels } from '../../../utils/sheets';


export class CompletedSheets extends Component {

  render() {
    return (
      <SheetsContainer
          className="completed-sheets"
          mainRoutePath={PLANNER_APP_COMPLETED_SHEETS_ROUTE}
          sheetsIdentity={SHEET_TYPE.COMPLETED}
          sheetItemIdProperty="sheetId"
          fetchSheetTypeEntitiesActionCreator={fetchCompletedSheets}
          sheetReviewContentComponent={CompletedSheetReviewContentContainer}
          noDataText={
            <Trans id="completed_sheets@no_completed_sheets">
              В данный момент нет ни одного завершенного маршрутного листа
            </Trans>
          }
          filtersSchema={COMPLETED_SHEETS_FILTERS_SCHEMA}
          getSheetItemAdditionalInfoLabels={getSheetDefectInfoLabels}
      />
    );
  }
}
