import { createSelector } from 'reselect';
import { tableCurrentRemoteDataSelector } from '../reducers/table/selectors';
import { ROLE_MODEL, USER_MODEL, USER_ROLE_MODEL } from '../constants/models';
import _size from 'lodash/size';
import {
  ADMIN_APP_ACTIVE_USERS_TABLE_ID,
  ADMIN_APP_DEACTIVATED_USERS_TABLE_ID,
} from '../components/AdminApp/UsersTable/constants';
import { roleEntitiesSelector } from '../reducers/entities/selectors';


const usersRemoteTableDataSelectorFactory = tableId => createSelector(
  state => tableCurrentRemoteDataSelector(state, { tableId }),
  currentRemoteData => {

    if(currentRemoteData === null) return [];

    const{
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const userEntitiesIds = currentRemoteItemsIds[USER_MODEL];

    if(_size(userEntitiesIds) === 0) return [];

    const {
      [USER_MODEL]: userEntities = {},
      [USER_ROLE_MODEL]: userRoleEntities = {},
      [ROLE_MODEL]: roleEntities = {},
    } = currentRemoteItemsById;

    const userRolesDataByUserId = Object
      .values(userRoleEntities)
      .reduce((acc, { userId, roleId }) => {

        const {
          [userId]: userRoles = [],
        } = acc;

        const {
          id,
          name,
        } = roleEntities[roleId];

        userRoles.push({ id, name });

        acc[userId] = userRoles;
        return acc;
      }, {});

    return userEntitiesIds
      .map(userId => {

        const {
          name,
          lastName,
          patronymicName,
          identity,
          createStamp,
          note,
        } = userEntities[userId];

        return {
          id: userId,
          name,
          lastName,
          patronymicName,
          identity,
          userRoles: userRolesDataByUserId[userId],
          createStamp,
          note,
        };
      });
  },
);

export const activeUsersRemoteTableDataSelector =
  usersRemoteTableDataSelectorFactory(ADMIN_APP_ACTIVE_USERS_TABLE_ID);

export const deactivatedUsersRemoteTableDataSelector =
  usersRemoteTableDataSelectorFactory(ADMIN_APP_DEACTIVATED_USERS_TABLE_ID);

export const userRoleSelectOptionsSelector = createSelector(
  roleEntitiesSelector,
  (roleEntities = {}) => Object
    .values(roleEntities)
    .map(({ id, name }) => ({
      id,
      name,
    })),
);
