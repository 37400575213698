import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';
import {
  SHEET_SUMMARY_TYPE, FUNC_IS_REQUIRED_TYPE,
  NUMBER_OR_STRING_TYPE,
} from '../../../../constants/propTypes';

import './style.css';
import { SimpleLabel } from '../../../common/SimpleLabel/SimpleLabel';
import { debugMessage } from '../../../../utils/debugMessage';
import { MATERIAL_UI_STYLE_COLOR } from '../../../../constants/materialUI';
import { DefaultSheetPartsAndMaterialsConsumeConfirmDialog } from './PartsAndMaterialsConsumeConfirmDialog/DefaultSheetPartsAndMaterialsConsumeConfirmDialog';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';
import { AmountLabelTrans, EntityLabelTrans } from '../../../../utils/commonTransComponents';


const DETAILED_DATA_TO_CONSUME_TABLE_COLUMNS_SCHEMA = [
  {
    id: 'sheetIdentity',
    title: (
      <Trans id="default_sheet_parts_and_materials_consuming.table_column@sheet_identity">
        ИД МЛ партии
      </Trans>
    ),
  },
  {
    id: 'amountToConsumeFromBatch',
    title: (
      <Trans id="default_sheet_parts_and_materials_consuming.table_column@amount">
        Потребляемое количество ДСЕ
      </Trans>
    ),
  },
];

export const DETAILED_DATA_TO_CONSUME_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    entityId: PropTypes.number.isRequired,
    entityCombinedName: PropTypes.string.isRequired,
    entityRequiredAmount: PropTypes.number.isRequired,
    entityMissingAmount: PropTypes.number.isRequired,
    entityBatchesToConsume: PropTypes.arrayOf(
      PropTypes.shape({
        entityBatchId: NUMBER_OR_STRING_TYPE.isRequired,
        sheetId: NUMBER_OR_STRING_TYPE.isRequired,
        sheetIdentity: PropTypes.string.isRequired,
        amountToConsumeFromBatch: PropTypes.number.isRequired,
        requiredEntityAmountAfterThisBatchConsuming: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
  }),
);

export class DefaultSheetPartsAndMaterialsConsumingContent extends Component {

  static propTypes = {
    detailedDataToConsume: DETAILED_DATA_TO_CONSUME_TYPE,
    sheetToReviewData: SHEET_SUMMARY_TYPE,
    onPartsAndMaterialsConsume: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  static defaultProps = {
    detailedDataToConsume: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isConsumeConfirmDialogOpen: false,
    };
  }

  _renderDefaultSheetPartsAndMaterialsConsumeButton = () => {
    const { detailedDataToConsume, PermissionsManager } = this.props;

    if(
      PermissionsManager.isDenied(PERMISSION.CONSUME_ENTITIES_FOR_DEFAULT_SHEET) ||
      detailedDataToConsume.length === 0 ||
      detailedDataToConsume.some(({ entityMissingAmount }) => entityMissingAmount !== 0)
    ) return null;

    return(
      <div className="default-sheet-parts-and-materials-consuming-content__consume-button">
        <Button
            color="secondary"
            variant="contained"
            onClick={this._openDefaultSheetPartsAndMaterialsConsumeConfirmDialog}
        >
          <Trans id="default_sheet_parts_and_materials_consuming@consume_button">
            Укомплектовать
          </Trans>
        </Button>
      </div>
    );
  };

  _openDefaultSheetPartsAndMaterialsConsumeConfirmDialog = () => this.setState({ isConsumeConfirmDialogOpen: true });
  _closeDefaultSheetPartsAndMaterialsConsumeConfirmDialog = () => this.setState({ isConsumeConfirmDialogOpen: false });

  _renderDefaultSheetPartsAndMaterialsConsumingTitle = () =>
    <div className="default-sheet-parts-and-materials-consuming-content__title">
      <Trans id="default_sheet_parts_and_materials_consuming@required_parts_and_materials_title">
        Необходимая комплектация
      </Trans>
    </div>;

  _renderDetailedConsumingCards = () =>
    <div className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-cards-wrap">
      {
        this.props.detailedDataToConsume
          .map(this._renderDetailedConsumingBlock)
      }
    </div>;

  _renderDetailedConsumingBlock = ({
    entityId,
    entityCombinedName,
    entityRequiredAmount,
    entityBatchesToConsume,
    entityMissingAmount,
  }) =>
    <Card key={entityId} className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card">
      {this._renderCardHeader(entityCombinedName, entityRequiredAmount, entityMissingAmount)}
      {this._renderCardContent(entityBatchesToConsume)}
    </Card>;

  _renderCardHeader = (entityCombinedName, entityRequiredAmount, entityMissingAmount) =>
    <CardHeader
        className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header"
        title={this._renderCardHeaderContent(entityCombinedName, entityRequiredAmount, entityMissingAmount)}
    />;

  _renderCardHeaderContent = (entityCombinedName, entityRequiredAmount, entityMissingAmount) =>
    <div className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-content">
      <div>
        <div className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-entity-info-row-wrap">
          {this._renderInfoRow(EntityLabelTrans, entityCombinedName)}
        </div>
        {this._renderInfoRow(AmountLabelTrans, entityRequiredAmount)}
      </div>
      <SimpleLabel
          className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-label"
          {...this._getCardHeaderLabelProps(entityMissingAmount)}
      />
    </div>;

  _renderInfoRow = (labelTrans, value) =>
    <div className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-info-row">
      <span className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-info-row-label">
        {labelTrans}
        {':'}
      </span>
      <span className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-header-info-row-value">
        {value}
      </span>
    </div>;

  _getCardHeaderLabelProps = entityMissingAmount => {
    if (entityMissingAmount < 0) {
      debugMessage('Количество недостающих комплектующих для ДСЕ в возможной комплектации, полученное с сервера, меньше 0, а должно быть больше или равно 0');
    }

    return entityMissingAmount === 0 ?
      ({
        content: (
          <Trans id="default_sheet_parts_and_materials_consuming@enough_parts_and_materials_for_entity">
            Комплектация в наличии
          </Trans>
        ),
        color: MATERIAL_UI_STYLE_COLOR.PRIMARY,
      }) :
      ({
        content: (
          <span>
            <Trans id="default_sheet_parts_and_materials_consuming@need_more_parts_and_materials_for_entity">
              Необходимо ещё
            </Trans>
            {': '}
            {entityMissingAmount}
          </span>
        ),
        color: MATERIAL_UI_STYLE_COLOR.SECONDARY,
      });
  };

  _renderCardContent = entityBatchesToConsume =>
    <CardContent className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-content">
      {this._renderCardTableTitle()}
      {
        entityBatchesToConsume.length === 0 ?
          this._renderNoDataToConsumeInfo() :
          this._renderCardTable(entityBatchesToConsume)
      }
    </CardContent>;

  _renderCardTableTitle = () =>
    <div className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-table-title">
      <Trans id="default_sheet_parts_and_materials_consuming@sheets_for_entity_batches_in_storage_to_consume">
        МЛ партий ДСЕ для комплектации
      </Trans>
      {':'}
    </div>;

  _renderNoDataToConsumeInfo = () =>
    <div className="default-sheet-parts-and-materials-consuming-content__no-data-to-consume-info">
      <Trans id="default_sheet_parts_and_materials_consuming@no_entity_batches_in_storage">
        Нет на складе
      </Trans>
    </div>;

  _renderCardTable = entityBatchesToConsume =>
    <Table className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-table">
      {this._renderCardTableHead()}
      {this._renderCardTableBody(entityBatchesToConsume)}
    </Table>;

  _renderCardTableHead = () =>
    <TableHead>
      <TableRow>
        {
          DETAILED_DATA_TO_CONSUME_TABLE_COLUMNS_SCHEMA
            .map(
              ({ id, title }) =>
                this._renderCardTableCell(['table-header-cell', id].join('--'), title),
            )
        }
      </TableRow>
    </TableHead>;

  _renderCardTableCell = (tableCellKey, tableCellValue) =>
    <TableCell
        key={tableCellKey}
        className="default-sheet-parts-and-materials-consuming-content__detailed-consuming-card-table-cell"
    >
      {tableCellValue}
    </TableCell>;

  _renderCardTableBody = entityBatchesToConsume =>
    <TableBody>
      {
        entityBatchesToConsume
          .map(
            rowData =>
              <TableRow key={rowData.entityBatchId}>
                {
                  DETAILED_DATA_TO_CONSUME_TABLE_COLUMNS_SCHEMA
                    .map(
                      ({ id }) =>
                        this._renderCardTableCell(['table-row-cell', id].join('--'), rowData[id]),
                    )
                }
              </TableRow>,
          )
      }
    </TableBody>;

  _renderDefaultSheetPartsAndMaterialsConsumeConfirmDialog = () => {
    const {
      onPartsAndMaterialsConsume,
    } = this.props;
    const {
      isConsumeConfirmDialogOpen,
    } = this.state;
    return(
      <DefaultSheetPartsAndMaterialsConsumeConfirmDialog
          isOpen={isConsumeConfirmDialogOpen}
          onSubmit={onPartsAndMaterialsConsume}
          closeDialog={this._closeDefaultSheetPartsAndMaterialsConsumeConfirmDialog}
      />
    );
  };

  render() {
    return (
      <div className="default-sheet-parts-and-materials-consuming-content">
        {this._renderDefaultSheetPartsAndMaterialsConsumeButton()}
        {this._renderDefaultSheetPartsAndMaterialsConsumingTitle()}
        {this._renderDetailedConsumingCards()}
        {this._renderDefaultSheetPartsAndMaterialsConsumeConfirmDialog()}
      </div>
    );
  }
}