import { connect } from 'react-redux';

import { showGlobalAppSpinnerSelector } from '../../selectors/globalAppSpinner';

import { GlobalAppSpinner } from './GlobalAppSpinner';

const mapStateToProps = state => ({
  show: showGlobalAppSpinnerSelector(state),
});

export const GlobalAppSpinnerContainer = connect(
  mapStateToProps,
)(GlobalAppSpinner);

GlobalAppSpinnerContainer.displayName = 'GlobalAppSpinnerContainer';
