import { TableCellRenderer } from '../TableCellRenderer';
import { renderSheetOperationProgress } from '../../../common/SheetOperationReviewDialog/constants';
import _isFunction from 'lodash/isFunction';

export class SheetOperationProgressRenderer extends TableCellRenderer {

  static getViewValue(props) {
    const {
      data: {
        progress,
        entitiesInBatchAmount,
      },
      renderProgress,
    } = props;

    /*
    * В некоторых таблицах в ряде данных может не быть ни entitiesInBatchAmount, ни progress, или поля могут
    * называться иначе. Для таких случаев добавлена возможность передать в рендерер callback, с помощью которого можно
    * кастомизировать и получение новых данных, чтобы вызвать renderSheetOperationProgress и, в целом, как-то
    * переопределить сам рендер, если это потребуется
    */
    return _isFunction(renderProgress) ?
      renderProgress(props) :
      renderSheetOperationProgress(entitiesInBatchAmount, progress);
  }

  render() {
    return SheetOperationProgressRenderer.getViewValue(this.props);
  }
}