import React from 'react';

import { Switch, Route } from 'react-router-dom';

import { WorkerNavigationPanelContainer } from './WorkerNavigationPanel/WorkerNavigationPanelContainer';
import { DepartmentChooseScreenContainer } from './TasksOwnerChooseScreen/DepartmentChooseScreenContainer';
import { TasksViewScreenContainer } from './TasksViewScreen/TasksViewScreenContainer';
import { EquipmentClassChooseScreenContainer } from './TasksOwnerChooseScreen/EquipmentClassChooseScreenContainer';

import { WORKER_APP_MAIN_ROUTE } from '../../constants/routes';

import './style.css';
import { AppTypeTopBarContainer } from '../AppTypeTopBar/AppTypeTopBarContainer';
import { APP_TYPE_BAR_SIZE } from '../AppTypeTopBar/constants';


const _renderWorkerAppTypeTopBarButtons = (reloadButton, userMenuButton, exitFromAppButton) => (
  <React.Fragment>
    {userMenuButton}
    {reloadButton}
    {exitFromAppButton}
  </React.Fragment>
);

export const WorkerApp = () =>
  <div className="worker-app">
    <AppTypeTopBarContainer
        className="worker-app__main-app-bar"
        position="fixed"
        size={APP_TYPE_BAR_SIZE.LARGE}
        showReloadButtonOnlyOnFullscreen={false}
        TitleComponent={WorkerNavigationPanelContainer}
        renderButtons={_renderWorkerAppTypeTopBarButtons}
    />
    <div className="worker-app__content-wrap">
      <Switch>
        <Route
            exact
            path={WORKER_APP_MAIN_ROUTE}
            component={DepartmentChooseScreenContainer}
        />
        <Route
            path={
              [
                WORKER_APP_MAIN_ROUTE,
                ':departmentId',
                ':equipmentClassId',
              ].join('/')
            }
            component={TasksViewScreenContainer}
        />
        <Route
            path={
              [
                WORKER_APP_MAIN_ROUTE,
                ':departmentId',
              ].join('/')
            }
            component={EquipmentClassChooseScreenContainer}
        />
      </Switch>
    </div>
  </div>;