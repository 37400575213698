import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { CardWithCustomHeader } from '../CardWithCustomHeader/CardWithCustomHeader';

import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';

import _get from 'lodash/get';
import cn from 'classnames';

import './style.css';
import { CARD_MENU_OPTIONS_TYPE } from '../CardWithCustomHeader/cardWithCustomHeader.types';
import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';


export class CardWithNavHeader extends Component {

  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    navTabs: PropTypes.arrayOf(PropTypes.shape({
      tabId: NUMBER_OR_STRING_TYPE.isRequired,
      tabTitle: PropTypes.node.isRequired,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.elementType.isRequired,
    }).isRequired),
    actionsContent: PropTypes.node,
    menuOptions: CARD_MENU_OPTIONS_TYPE,
  };

  static defaultProps = {
    navTabs: [],
  };

  constructor(props) {
    super(props);

    //при инициализации всегда выбираем первый таб из пропа массива, подразумевается, что табы всегда заданы, иначе
    //компонент не имеет смысла
    this.state = {
      selectedTabIndex: 0,
    };
  };

  _renderCardNavHeader = () =>
    <div className="card-with-nav-header__header">
      {this._renderCardTitle()}
      {this._renderTabs()}
    </div>;

  _renderCardTitle = () => {
    const { title } = this.props;
    if(!title) return null;
    return(
      <div className="card-with-nav-header__card-title">
        {title}
      </div>
    );
  };

  _renderTabs = () =>
    <Tabs
        classes={{
          root: 'card-with-nav-header__tabs',
          indicator: 'card-with-nav-header__tab-indicator',
        }}
        value={this.state.selectedTabIndex}
        onChange={this._handleTabChange}
        indicatorColor={MATERIAL_UI_STYLE_COLOR.PRIMARY}
        textColor={MATERIAL_UI_STYLE_COLOR.INHERIT}
    >
      {this.props.navTabs.map(this._renderTab)}
  </Tabs>;

  _handleTabChange = (_, selectedTabIndex) => {
    if(this.state.selectedTabIndex === selectedTabIndex) return;
    this.setState({ selectedTabIndex });
  };

  _renderTab = ({ tabId, tabTitle, tabIcon: TabIcon }) =>
    <Tab
        key={tabId}
        classes={{
          root: 'card-with-nav-header__tab',
          selected: 'card-with-nav-header__tab--selected',
        }}
        label={
          tabTitle
        }
        icon={
          TabIcon && <TabIcon />
        }
    />;

  _renderCardContent = () => {
    const { selectedTabIndex } = this.state;

    const SelectedTabContent = _get(this.props.navTabs, [selectedTabIndex, 'tabContent'], null);

    //Такое может быть только если this.props.navTabs совсем не заданы, обрабатываем просто чтобы приложение не падало,
    //компонент без табов не имеет смысла, проптайпсы оповестят о том, что проп не задан
    if(SelectedTabContent === null) return null;

    return(
      <div className="card-with-nav-header__card-content">
        <SelectedTabContent />
      </div>
    );
  };

  render() {
    const {
      className,
      actionsContent,
      menuOptions,
    } = this.props;
    return (
      <CardWithCustomHeader
          className={
            cn(
              className,
              'card-with-nav-header',
            )
          }
          header={this._renderCardNavHeader()}
          actionsContent={actionsContent}
          menuOptions={menuOptions}
          content={this._renderCardContent()}
      />
    );
  }
}
