import { compose } from 'redux';
import { connect } from 'react-redux';

import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';
import { WorkerTasksTableSettings } from './WorkerTasksTableSettings';

import {
  fetchSettings,
  saveSettingsEntity,
  deleteSettingsEntity,
} from '../../../operations/settings';
import {
  workerTasksTableSettingsToShowSelector,
} from '../../../reducers/workerTasksTableSettings/selectors';
import {
  workerTasksTableSelectedSettingsDataSelector,
  workerTasksTableSettingsSelectOptionsSelector,
} from '../../../selectors/workerTasksTableSettings';

import {
  getSettingsNameFromId,
  WORKER_TASKS_TABLE_MAIN_SETTINGS_ID,
  WORKER_TASKS_TABLE_SETTINGS_GROUP,
} from '../../../constants/settings';
import { withPermissionsManager } from '../../../hoc/withPermissionsManager/withPermissionsManager';
import { showWorkerTasksTableSettings } from '../../../reducers/workerTasksTableSettings/actions';
import { settingsEntitiesForGroupSelector } from '../../../selectors/settings';
import { fetchEquipmentClassesInDepartment } from '../../../operations/equipmentClasses';

const mapStateToProps = state => {
  const selectedSettingsId = workerTasksTableSettingsToShowSelector(state);
  const selectedSettingsData = workerTasksTableSelectedSettingsDataSelector(state, { settingsId: selectedSettingsId });
  const isMainSettingsSelected = selectedSettingsId === WORKER_TASKS_TABLE_MAIN_SETTINGS_ID;
  const settingsEntities = settingsEntitiesForGroupSelector(state, { group: WORKER_TASKS_TABLE_SETTINGS_GROUP });

  return{
    settingsSelectOptions: workerTasksTableSettingsSelectOptionsSelector(state),
    selectedSettingsId,
    selectedSettingsData,
    isMainSettingsSelected,
    settingsEntities,
  };
};

const mapDispatchToProps = {
  showWorkerTasksTableSettings,
  deleteSettingsEntity,
  fetchSettings,
  saveSettingsEntity,
  fetchEquipmentClassesInDepartment,
};

const mergeProps = (stateProps, dispatchProps) => {
  const {
    selectedSettingsId,
  } = stateProps;

  const {
    showWorkerTasksTableSettings,
    deleteSettingsEntity,
    fetchSettings,
    saveSettingsEntity,
    fetchEquipmentClassesInDepartment,
  } = dispatchProps;

  return {
    ...stateProps,

    fetchEquipmentClassesInDepartment,
    selectSettings: showWorkerTasksTableSettings,
    fetchSettings,
    saveSettingsEntity,
    deleteSelectedSettings: () => deleteSettingsEntity({ group: WORKER_TASKS_TABLE_SETTINGS_GROUP, name: getSettingsNameFromId(selectedSettingsId) }),
  };
};

export const WorkerTasksTableSettingsContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchSettings }) => fetchSettings([{ group: WORKER_TASKS_TABLE_SETTINGS_GROUP }]),
      },
    ],
  }),
  withPermissionsManager,
)(WorkerTasksTableSettings);

WorkerTasksTableSettingsContainer.displayName = 'WorkerTasksTableSettingsScreenContainer';