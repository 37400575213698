import React, { Component } from 'react';

import PropTypes from 'prop-types';

import './style.css';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import { EquipmentEntityCreationForm } from './EquipmentEntityCreationForm/EquipmentEntityCreationForm';
import { FUNC_IS_REQUIRED_TYPE, NUMBER_OR_STRING_TYPE } from '../../../../constants/propTypes';
import { NoDataLabelTrans, NotAssignedLabelTrans } from '../../../../utils/commonTransComponents';
import { DefaultAutocompleteContainer } from '../../Autocomplete/AutocompleteContainer';
import {
  createSheetOperationEquipmentAutocompleteId,
  getSheetOperationEquipmentAutocompleteLoadOptionsActionCreator,
} from './constants';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export class SheetOperationEquipmentSection extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isCreatingFormOpen: false,
    };

    const {
      departmentId,
      equipmentClassId,
    } = this.props;

    /*
    * Для селекта выбора оборудования необходимо создать экшн запроса опций. Для этого нужны данные по подразделению и
    * классу РЦ. Создаем этот экшн в конструкторе данного компонента.
    * Вызывается он один раз на маунт компонента автокомплита единиц оборудования. Вызов один, т.к. для компонента
    * автокомплита установлены пропсы isSearchable = false и shouldPreloadData = true. В таком виде у селекта отключена
    * логика поиска и выполнения запросов опций и на маунт запрашиваются все опции сразу.
    * */
    this.equipmentAutocompleteLoadOptionsActionCreator =
      getSheetOperationEquipmentAutocompleteLoadOptionsActionCreator(departmentId, equipmentClassId);

    /*
    * сформирован идентификатор селекта выбора оборудования. Формируется дин раз в конструкторе
    * */
    this.equipmentAutocompleteId = createSheetOperationEquipmentAutocompleteId(departmentId, equipmentClassId);
  }

  _renderSheetOperationEquipmentAutocomplete = () => {

    const {
      equipment,
      setSheetOperationEquipment,
    } = this.props;

    return (
      <React.Fragment>
        <div className="sheet-operation-equipment-section__autocomplete-and-creation-form-wrapper">
          <DefaultAutocompleteContainer
              id={this.equipmentAutocompleteId}
              wrapperClassName="sheet-operation-equipment-section__autocomplete"
              value={equipment}
              shouldPreloadData
              isSearchable={false}
              onChange={setSheetOperationEquipment}
              placeholder={NotAssignedLabelTrans}
              loadOptionsActionCreator={this.equipmentAutocompleteLoadOptionsActionCreator}
              getOptionLabel={getResourceCombinedName}
              noOptionsMessage={NoDataLabelTrans}
          />
          <span className="sheet-operation-equipment-section__create-equipment-btn">
            <IconButton onClick={() => this.setState({ isCreatingFormOpen: true })} size="large">
              <AddIcon/>
            </IconButton>
          </span>
        </div>
        {this._renderCreateEquipmentEntityForm()}
      </React.Fragment>
    );
  };

  _renderSheetOperationEquipmentReadOnlyInfo = () => {
    const {
      equipment,
    } = this.props;

    const label = equipment === null ?
      NotAssignedLabelTrans :
      getResourceCombinedName(equipment);

    return (
      <Chip
          className="sheet-operation-equipment-section__equipment-chip"
          label={label}
      />
    );
  };

  _renderCreateEquipmentEntityForm = () => {
    const {
      createEquipmentEntity,
      setSheetOperationEquipment,
      addAutocompleteOptions,
      departmentId,
      equipmentClassId,
    } = this.props;

    const {
      isCreatingFormOpen,
    } = this.state;

    return (
      <EquipmentEntityCreationForm
          isOpen={isCreatingFormOpen}
          departmentId={departmentId}
          equipmentClassId={equipmentClassId}
          createEquipmentEntity={createEquipmentEntity}
          setSheetOperationEquipment={setSheetOperationEquipment}
          addAutocompleteOptions={options => addAutocompleteOptions(this.equipmentAutocompleteId, options)}
          onClose={() => this.setState({ isCreatingFormOpen: false })}
      />
    );
  }

  render() {
    const {
      isEditable,
    } = this.props;

    return (
      <div className="sheet-operation-equipment-section">
        {
          isEditable ?
            this._renderSheetOperationEquipmentAutocomplete() :
            this._renderSheetOperationEquipmentReadOnlyInfo()
        }
      </div>
    );
  };
}

SheetOperationEquipmentSection.propTypes = {
  isEditable: PropTypes.bool,
  createEquipmentEntity: FUNC_IS_REQUIRED_TYPE,
  setSheetOperationEquipment: FUNC_IS_REQUIRED_TYPE,
  addAutocompleteOptions: FUNC_IS_REQUIRED_TYPE,
  departmentId: NUMBER_OR_STRING_TYPE.isRequired,
  equipmentClassId: NUMBER_OR_STRING_TYPE.isRequired,
  equipment: PropTypes.shape({
    id: NUMBER_OR_STRING_TYPE,
    name: PropTypes.string,
    identity: PropTypes.string,
  }),
};