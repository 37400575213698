import { Transformer } from '../Transformer';


export class SheetOperationAssignee extends Transformer {

  static transformToState(item) {
    const {
      entityRouteSheetOperationId: sheetOperationId,
      userId,
    } = item;


    return {
      id: [sheetOperationId, userId].join('/'),
      sheetOperationId,
      userId,
    };
  };
}