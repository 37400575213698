import { compose } from 'redux';
import { connect } from 'react-redux';

import { asyncComponent } from '../../hoc/asyncComponent/asyncComponent';

import { fetchSettings } from '../../operations/settings';

import { WorkerApp } from './WorkerApp';
import { WORKER_TASKS_TABLE_SETTINGS_GROUP } from '../../constants/settings';
import {
  WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_GROUP,
  WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_NAME,
} from '../AdminApp/WorkerTasksAdminFiltersSettings/constants';


/*
* В приложении "Рабочий" на экране просмотра заданий классов РЦ в подразделении используются созданные с системе
* настройки. Т.к. пока не очень понятно, будут ли, вообще, этими настройками пользоваться в текущем варианте, и,
* если будут, то наскольно много настроек будет обычно создаваться, то решено пока что не заморачиваться и при входе в
* приложение "Рабочий" запрашивать сразу все имеющиеся настройки 1 раз. Иначе, т.к. логика вычисления настроек не
* самая очевидная (Cначала пытаемся найти настройки для текущего класса РЦ в подрзделении. Если таких нет, то настройки
* для текущего подразделения. Ксли и таких нет, то "общие" настройки. Ксли и "общих" нет, то дефолтные, которые
* начинают считаться "общими"), то, если пытаться запрашивать настройки только после выбора конкретного класса РЦ в
* подразделении, то, приходиться, всё равно, выполнять 3 запроса настроек для класса РЦ в подразделении, для
* подразделения и "общие настройки". При этом, если каких-то из них нет в БД, то эти запросы постоянно повторяются,
* т.к. в стандартной реализации с entities мы "не знаем", что запрос уже выполнялся и обычно проверки проводятся только
* по наличию сущности в entities, а в описанном случае там никогда и не будет этих настроек и постоянно будут
* совершаться запросы, кажущиеся в данный момент лишними. Особенно это заметно, когда совсем нет настроек с системе,
* при каждом входе в интерфейс прсомотра заданий выполняется запрос для 3 видов настроек и каждый раз он пустой.
* В итоге, кажется, что прорабатывать какие-то более сложные обработки по ограничению запроса пока что бесмысленно, а в
* текущей ситуации намного более производительней будет запросить эти все настройки 1 раз при входе в приложении.
*
* Конечно, если настройками будут активно пользоваться и в системе их будет много (например, 20+), то появится смысл
* переработать эту логику, не запрашивать сразу все настройки, а проработать более сложный механизи получения только
* нужных в данный момент настроек.
*
* Дополнительно тут запрашиваем настройки фильтров заданий рабочего, т.к. для них не важно какой класс РЦ и
* подразделение выбраны, настройки фильтров для всех рабочих одинаковые.
* */
const mapDispatchToProps = dispatch => ({
  fetchWorkerTasksSettings: () => dispatch(fetchSettings(
    [
      {
        group: WORKER_TASKS_TABLE_SETTINGS_GROUP,
      },
      {
        group: WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_GROUP,
        name: WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_NAME,
      },
    ],
  )),
});

export const WorkerAppContainer = compose(
  connect(null, mapDispatchToProps),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchWorkerTasksSettings }) => fetchWorkerTasksSettings(),
      },
    ],
  }),
)(WorkerApp);