import { DateRenderer } from './DateRenderer/DateRenderer';
import { TableOptionsButtonRenderer } from './TableOptionsButtonRenderer/TableOptionsButtonRenderer';
import { OrderNameRenderer } from './OrderNameRenderer/OrderNameRenderer';
import { UsernameRenderer } from './UsernameRenderer/UsernameRenderer';
import { UserRolesRenderer } from './UserRolesRenderer/UserRolesRenderer';
import { AssigneesRenderer } from './AssigneesRenderer/AssigneesRenderer';
import { MasterTasksEquipmentCombinedDataRenderer } from './MasterTasksEquipmentCombinedDataRenderer/MasterTasksEquipmentCombinedDataRenderer';
import { WorkerTasksEquipmentCombinedDataRenderer } from './WorkerTasksEquipmentCombinedDataRenderer/WorkerTasksEquipmentCombinedDataRenderer';
import  { OperationLaborRenderer } from './OperationLaborRenderer/OperationLaborRenderer';
import { NumbersRenderer } from './NumbersRenderer/NumbersRenderer';
import { HumanizedTimeFromHoursRenderer } from './HumanizedTimeFromHoursRenderer/HumanizedTimeFromHoursRenderer';
import { DefaultNilValueLabelRenderer } from './nilValueLabelTableCellRendererFactory';
import { SheetOperationProgressRenderer } from './SheetOperationProgressRenderer/SheetOperationProgressRenderer';


export const TABLE_CELL_RENDERERS = {
  DateRenderer,
  TableOptionsButtonRenderer,
  OrderNameRenderer,
  UsernameRenderer,
  UserRolesRenderer,
  AssigneesRenderer,
  MasterTasksEquipmentCombinedDataRenderer,
  WorkerTasksEquipmentCombinedDataRenderer,
  PlannerSheetOperationsEquipmentCombinedDataRenderer: WorkerTasksEquipmentCombinedDataRenderer,
  OperationLaborRenderer,
  HumanizedTimeFromHoursRenderer,
  NumbersRenderer,
  DefaultNilValueLabelRenderer,
  SheetOperationProgressRenderer,
};
