import React, { Component }  from 'react';
import PropTypes from 'prop-types';

import { ClipLoader } from 'react-spinners';

import './style.css';

export class GlobalAppSpinner extends Component {

  static propTypes = {
    show: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  render() {
    if(!this.props.show) return null;
    return (
      <div className="global-app-spinner">
        <div className="global-app-spinner__shadow"/>
        <div className="global-app-spinner__loader">
          <ClipLoader size={80}/>
        </div>
      </div>
    );
  }
}
