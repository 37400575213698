import {
  fetchEntitiesFromServer,
} from '../reducers/entities/actions';
import {
  DEPARTMENT_MODEL,
  ENTITY_BATCH_MODEL,
  OPERATION_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_MODEL,
} from '../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../api/restCollectionApi';
import { setTasksDepartmentFilter } from '../reducers/workerApp/tasksMainFilters/actions';
import { ENTITY_BATCH_STATUS, SHEET_OPERATION_STATUS, SHEET_STATUS } from '../constants/sheets';
import { saveDepartmentsWithTasks } from '../reducers/workerApp/tasksOwners/actions';
import { isIdInUrlParamsValid } from '../utils/url';
import { RELATED_MODEL_FIELD_DELIMITER } from '../constants/magics';


export const DEPARTMENTS_WITH_TASKS_REQUEST_FILTERS = {
  filterGroupType: FILTER_GROUP_TYPES.AND,
  filters: [
    {
      column: [ENTITY_BATCH_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
      filterType: FILTER_TYPES.EQUALS,
      filterValue: ENTITY_BATCH_STATUS.IN_PRODUCTION,
    },
    {
      column: [SHEET_MODEL, 'type'].join(RELATED_MODEL_FIELD_DELIMITER),
      filterType: FILTER_TYPES.EQUALS,
      filterValue: SHEET_STATUS.ACTIVE,
    },
    {
      column: [SHEET_OPERATION_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
      filterType: FILTER_TYPES.NOT_EQUALS,
      filterValue: SHEET_OPERATION_STATUS.FINISHED,
    },
  ],
};

export const DEPARTMENTS_WITH_TASKS_REQUEST_MODEL_RELATIONS = {
  [SHEET_OPERATION_MODEL]: {
    level: 1,
  },
  [OPERATION_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
  [SHEET_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
  [ENTITY_BATCH_MODEL]: {
    level: 3,
    relates: SHEET_MODEL,
  },
};

export const fetchDepartmentsWithTasks = () =>
  dispatch =>
    dispatch(fetchEntitiesFromServer(
      DEPARTMENT_MODEL,
      {
        filter: DEPARTMENTS_WITH_TASKS_REQUEST_FILTERS,
      },
      { modelRelations: DEPARTMENTS_WITH_TASKS_REQUEST_MODEL_RELATIONS },
    ))
      .then(response => {
        if (!response || response.responseMeta.count === 0) return;

        const {
          entities: {
            [DEPARTMENT_MODEL]: departmentEntities,
          },
        } = response;

        return dispatch(saveDepartmentsWithTasks(departmentEntities));
      });


/*
* В роутинге устанавливаются фильтр заданий по подразделению в виде его id БД идентификатора. В url, теоретически,
* может быть написано всё что угодно + данные для которых устанавливался ранее фильтр могут быть изменены.
* Поэтому в определенных случаях необходимо выполнить проверку, есть ли подразделение с таким id в БД, чтобы корректно
* продолжить работу.
*
* Если такое подразделение существует, то устанавливаем фильтр в state. Если нет, то реджектим.
* */
export const initTasksDepartmentFilterFromRoute = departmentIdFromRoute =>
  dispatch => {

    if(!isIdInUrlParamsValid(departmentIdFromRoute))
      return Promise.reject(departmentIdFromRoute);

    const queryParams = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'id',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: departmentIdFromRoute,
          },
        ],
      },
    };

    return dispatch(fetchEntitiesFromServer(DEPARTMENT_MODEL, queryParams))
      .then(response => {
        const {
          entities: {
            [DEPARTMENT_MODEL]: departmentModelEntities,
          } = {},
        } = response;

        const departmentEntity = departmentModelEntities[departmentIdFromRoute];

        if(!!departmentEntity) {
          dispatch(setTasksDepartmentFilter(departmentEntity));
          return Promise.resolve(departmentEntity);
        }
        return Promise.reject(departmentIdFromRoute);
      });
  };