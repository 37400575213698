import { compose } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { asyncComponent } from '../../hoc/asyncComponent/asyncComponent';

import {
  hasAppCriticalErrorSelector,
  isAppUserAuthenticatedSelector,
} from '../../reducers/appState/selectors';

import { checkIfCurrentUrlPermittedAfterInitialAuthentication, initApp } from '../../operations/app';
import { authenticateUser } from '../../operations/users/index';

import { App } from './App';
import { withPermissionsManager } from '../../hoc/withPermissionsManager/withPermissionsManager';


const mapStateToProps = state => ({
  isAppUserAuthenticated: isAppUserAuthenticatedSelector(state),
  hasAppCriticalError: hasAppCriticalErrorSelector(state),
});

const mapDispatchToProps = dispatch => ({
  onAppStart: () =>
    dispatch(authenticateUser())
      .then(userData => dispatch(initApp(userData)))
      .then(userData => dispatch(checkIfCurrentUrlPermittedAfterInitialAuthentication(userData))),
});

export const AppContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  asyncComponent({
    resolve: [
      {
        fn: ({ onAppStart }) => onAppStart(),
      },
    ],

    //Обработка ошибок инициализации приложении осуществляется в самом App с учетом различных пропсов, которые
    //устанвливаются в процессе initApp. Поэтому даже если случилась ошибка рендерим основной компонент приложения
    shouldRenderOnError: true,
  }),
  withPermissionsManager,
)(App);