import { Transformer } from '../Transformer';


export class OrderEntry extends Transformer {

  static transformToState(item) {
    const {
      orderId,
      entityId,
      quantity,
      priority,
    } = item;


    return {
      id: [orderId, entityId].join('/'),
      orderId,
      entityId,
      quantity,
      priority,
    };
  };
}