const MASTER_APP_DEPARTMENTS_IDS_ROUTE_PARAMS_DELIMITER = '_';

export const getMasterAppDepartmentIdsRouteParam = departmentIds =>
  departmentIds.join(MASTER_APP_DEPARTMENTS_IDS_ROUTE_PARAMS_DELIMITER);

export const getMasterAppDepartmentIdsFromRouteParam = departmentIdsRouteParams =>
  departmentIdsRouteParams.split(MASTER_APP_DEPARTMENTS_IDS_ROUTE_PARAMS_DELIMITER);


/*
 * Служебная функция, которая генерирует новую ссылку для приложения мастера с учетом новых выбранных параметров
 * подразделений newDepartmentIds на основании текущий ссылки в приложении "Мастер - currentMasterAppPathname.
 *
 * ОЧЕВИДНО, что currentPathname должен быть валидной ссылкой из приложения мастера, т.е. функция, как правило, должна
 * вызываться из интерфейсов приложения "Мастер" с предварительно полученной текущей ссылкой селекторами
 * routerPathnameSelector или reactRouterPathSelector.
 * Также, ОЧЕВИДНО, что функция однозначно фиксирует логику роутинга приложения, если роутинг изменится, то функция
 * работать не будет.
 *
 * Известно, что роутинг приложения мастер выглядить так:
 * `/master/${текущие параметры подразделений} или же далее будут ещё саброуты -
 * `/master/${текущие параметры подразделений}/саброут1/саброт2 и т.д.
 * Учитывая, самый первый слэш в ссылке, получается, что если рассплитить ссылку по '/', то текущий параметр
 * подразделений будут третьим элементов массива, т.е. будет находиться по индексу 2. Нам нужно заменить именно эти
 * параметры, т.е. элемент массива по индексу 2, остальные элементы (если они есть), оставить как есть и собрать ссылку
 * обратно джоином через '/'
 * */
export const replaceDepartmentIdsParamInCurrentPathname = (currentMasterAppPathname, newDepartmentIds) => {
  const currentMasterAppPathnameParts = currentMasterAppPathname.split('/');

  const masterAppPathnameWithReplacedDepartmentIdsParamParts = [
    ...currentMasterAppPathnameParts.slice(0, 2),
    getMasterAppDepartmentIdsRouteParam(newDepartmentIds),
    ...currentMasterAppPathnameParts.slice(3, currentMasterAppPathnameParts.length),
  ];

  return masterAppPathnameWithReplacedDepartmentIdsParamParts.join('/');
};