import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { MATERIAL_UI_LABEL_PLACEMENT, MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';

import './style.css';

export const SwitchControl = props => {

  const {
    checked,
    onChange,
    label,
    className,
    labelPlacement,
  } = props;

  const changeHandler = useCallback(e => {
    const { checked } = e.target;

    onChange(checked);
  }, [onChange]);

  return (
    <FormGroup>
      <FormControlLabel
          className={cn('switch-control', className)}
          label={label}
          labelPlacement={labelPlacement}
          control={(
            <Switch
                checked={checked}
                onChange={changeHandler}
                color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
            />
        )}
      />
    </FormGroup>
  );
};

SwitchControl.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: FUNC_IS_REQUIRED_TYPE,
  label: PropTypes.node,
  className: PropTypes.string,
  labelPlacement: PropTypes.oneOf(Object.values(MATERIAL_UI_LABEL_PLACEMENT)),
};