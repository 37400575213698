import { fetchTemporaryData } from '../broadcastApi/temporaryData';

export const getClientMessagesDataArray = clientMessageFromSocket =>
  dispatch => {
    /*
    * Данные клиентских сообщений могут приходить как в теле самого сообщения, так и храниться на временной точке,
    * с которой их нужно получить
    * */
    const {
      data,
      temporaryDataKey,
    } = clientMessageFromSocket;

    if(data) return Promise.resolve(data);

    return dispatch(fetchTemporaryData(temporaryDataKey));
  };
