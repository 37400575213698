/*
* Константы доступных action-точек
* */

export const SERVER_ACTION_POINT = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  IMPORT: 'import',
  CREATE_USER: 'user/create',
  EDIT_USER: 'user/update',
  DEACTIVATE_USER: 'user/delete',
  SIMULATION_SESSION_FOR_TASKS: 'primarySimulationSession',
  START_SHEET: 'entityBatch/start',
  FINISH_SHEET: 'entityBatch/stop',
  CHANGE_SHEET_OPERATION_STATUS: 'changeEntityRouteSheetOperationStatus',
  CONSUME_PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET: 'warehouse/consume',
  RESERVE_PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET: 'warehouse/reserve',
  EXPORT: {
    PRODUCTION_STATE: 'export/productionState',
  },
  SET_SHEET_OPERATION_ASSIGNEES: 'entityRouteSheetOperation/setExecutor',
  SET_SHEET_OPERATION_EQUIPMENT: 'entityRouteSheetOperation/setEquipment',
  CREATE_EQUIPMENT_ENTITY: 'equipment/create',
  SET_ENTITY_NOTE: 'setNote',
  PAUSE_SHEET: 'entityBatch/pause',
  RESUME_SHEET: 'entityBatch/resume',
  SPLIT_ENTITY_BATCH: 'entityBatch/divide',
  SEND_SHEET_TO_PRODUCTION: 'entityBatch/providePartially',
  COMPLETE_ORDER: 'order/complete',
  CONSUME_RESERVED_ENTITIES_FOR_ASSEMBLY_SHEET: 'warehouse/consumeReserve',
  MARK_DEFECTIVE_ENTITIES: 'entity_batch/define_fault',

  CLEAR_DB: 'clearDb',
};

export const getPartsAndMaterialsForAssemblyEntityBatchDataPoint = entityBatchId => `entityBatch/${entityBatchId}/warehouseWithReserveDetails`;

/*
* Константы доступных команд для action-точек
* */
export const SERVER_ACTION_COMMAND = {
  LOGIN: 'login',
};