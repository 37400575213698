import _omit from 'lodash/omit';
import { DELETE_ASSEMBLY_SHEET_CONSUME_DATA, SET_ASSEMBLY_SHEET_CONSUME_DATA } from './actions';

export const consumeData = (state = {}, action) => {
  const { type, sheetId, data } = action;

  switch (type) {
  case SET_ASSEMBLY_SHEET_CONSUME_DATA: {
    return {
      ...state,
      [sheetId]: data,
    };
  }
  case DELETE_ASSEMBLY_SHEET_CONSUME_DATA: {
    return _omit(state, sheetId);
  }
  default: {
    return state;
  }
  }
};