import React from 'react';
import { fetchEntitiesFromServer, fetchRemoteTableEntities } from '../reducers/entities/actions';
import {
  ENTITY_MODEL,
  ORDER_MODEL,
  ORDER_ENTRY_MODEL,
} from '../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../api/restCollectionApi/index';

import _partial from 'lodash/partial';
import _isEmpty from 'lodash/isEmpty';
import { sendActionToServer } from '../api/index';
import { SERVER_ACTION_POINT } from '../constants/serverActions';
import { getErrorMessage, showError } from '../api/requestHandlers/errorHandlers/errorHandlers';
import { COMPLETE_ORDER_ERRORS } from '../api/requestHandlers/errorHandlers/errorMaps';
import { broadcastEventMessage } from '../api/socketApi/broadcastApi/broadcast/broadcastEventMessage';
import { ORDER_COMPLETED_EVENT_TYPE } from '../constants/sockets';

import {
  ORDERS_IN_PRODUCTION_REQUEST_FILTERS,
  ORDERS_READY_TO_COMPLETE_REQUEST_FILTERS,
  COMPLETED_ORDERS_REQUEST_FILTERS,
} from '../constants/orders';
import { NOTIFICATION_LEVEL, sendNotification } from '../constants/notification';
import { Trans } from '@lingui/macro';


const fetchOrderEntities = (
  orderTypeFilters,
  queryParams = {},
  requestOptions = {},
) =>
  dispatch => {

    const query = {
      ...queryParams,
      filter: getQueryFilterWithOrderTypeFilters(
        queryParams.filter,
        orderTypeFilters,
      ),
    };

    return dispatch(fetchEntitiesFromServer(
      ORDER_MODEL,
      query,
      requestOptions,
    ));
  };

const getQueryFilterWithOrderTypeFilters = (filterFromQueryParams, predefinedFilters) => ({
  filterGroupType: FILTER_GROUP_TYPES.AND,
  filters: filterFromQueryParams ?
    [...predefinedFilters, filterFromQueryParams] :
    predefinedFilters,
});

export const fetchOrdersInProduction = _partial(fetchOrderEntities, ORDERS_IN_PRODUCTION_REQUEST_FILTERS);
export const fetchOrdersReadyToComplete = _partial(fetchOrderEntities, ORDERS_READY_TO_COMPLETE_REQUEST_FILTERS);
export const fetchCompletedOrders = _partial(fetchOrderEntities, COMPLETED_ORDERS_REQUEST_FILTERS);


export const ORDER_ENTRY_ENTITIES_REQUEST_WITH_PARAMS = [
  ENTITY_MODEL,
];

const ORDER_ENTRY_REMOTE_TABLE_DATA_REQUEST_DEFAULT_OPTIONS = {
  modelRelations: {
    [ORDER_MODEL]: {
      level: 1,
    },
    [ENTITY_MODEL]: {
      level: 1,
    },

  },
};

const ORDER_ENTRY_REMOTE_TABLE_DATA_REQUEST_SORT_PARAMS = [
  {
    column: 'priority',
    params: [{ key: 'asc', value: true }],
  },
];

/*
* фабрика колбэков для запроса табличных данных по позициям просматриваемого заказа
* */
export const fetchOrderEntriesRemoteTableData = (orderId, tableParams) =>
  dispatch => {
    const {
      activePage,
      pageSize,
      filterParams,
    } = tableParams;

    const predefinedOrderIdFilter = {
      column: 'orderId',
      filterType: FILTER_TYPES.EQUALS,
      filterValue: orderId,
    };

    const query = {
      filter: ({
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: _isEmpty(filterParams) ?
          [predefinedOrderIdFilter] :
          Object
            .keys(filterParams)
            .map(column => {
              const {
                filterValue,
                filterType,
              } = filterParams[column];

              return {
                column,
                filterType,
                filterValue,
              };
            })
            .concat(predefinedOrderIdFilter),
      }),
      with: ORDER_ENTRY_ENTITIES_REQUEST_WITH_PARAMS,
      sortBy: ORDER_ENTRY_REMOTE_TABLE_DATA_REQUEST_SORT_PARAMS,
      limit: pageSize,
      page: activePage,
    };

    return dispatch(fetchRemoteTableEntities(
      ORDER_ENTRY_MODEL,
      query,
      ORDER_ENTRY_REMOTE_TABLE_DATA_REQUEST_DEFAULT_OPTIONS,
    ));
  };

const DEFAULT_COMPLETE_ORDER_REQUEST_OPTIONS = {
  isBlockingRequest: true,
  showServerError: false,
};

export const sendCompleteOrderActionOnServer = (orderId, writeOff, requestOptions = {}) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.COMPLETE_ORDER,
      {
        data: {
          orderId,
          writeOff,
        },
      },
      { ...requestOptions },
    ));


export const completeOrder = (orderId, writeOff) =>
  dispatch => dispatch(sendCompleteOrderActionOnServer(orderId, writeOff, DEFAULT_COMPLETE_ORDER_REQUEST_OPTIONS))
    .catch(({ response, status }) => {
      if(!response) return Promise.reject(status);

      const errorMsg = getErrorMessage(response, COMPLETE_ORDER_ERRORS);
      showError(errorMsg);
      return Promise.reject({ response, status });
    });

export const broadcastOrderCompleted = (id, name) => broadcastEventMessage(
  ORDER_COMPLETED_EVENT_TYPE,
  { id, name },
);

export const sendOrderCompletedNotification = name =>
  sendNotification(
    <Trans id="orders_ready_to_complete.complete_order@order_completed">
      Был завершен заказ {name}
    </Trans>,
    NOTIFICATION_LEVEL.INFO,
  );

export const sendOrderIsReadyToCompleteNotification = name =>
  sendNotification(
    <Trans id="orders_ready_to_complete.complete_order@order_is_ready_to_complete">
      Заказ {name} готов к завершению
    </Trans>,
    NOTIFICATION_LEVEL.INFO,
  );