import React from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import './style.css';
import { APP_CRITICAL_ERROR } from '../../constants/app';

const APP_CRITICAL_ERRORS_TRANS_MAP = {
  [APP_CRITICAL_ERROR.CONNECTION_ERROR]: (
    <Trans id="app_error@app_configuration_is_not_correct">
      Неверная конфигурация приложения. Обратитесь к системному администратору
    </Trans>
  ),
};

export const AppErrorScreen = ({ appCriticalError }) =>
  <div className="cyber-assistant-app-error-screen">
    {
      APP_CRITICAL_ERRORS_TRANS_MAP[appCriticalError]
    }
  </div>;

AppErrorScreen.propTypes = {
  appCriticalError: PropTypes.string.isRequired,
};
