import { matchPath } from 'react-router-dom';

import { APPS_DATA } from '../../constants/app';

import _mapKeys from 'lodash/mapKeys';
import { PERMISSION } from '../../hoc/withPermissionsManager/constants';


const APPS_DATA_BY_REVIEW_PERMISSION = _mapKeys(APPS_DATA, ({ appReviewPermission }) => appReviewPermission);

const APPS_REVIEW_PERMISSIONS_SET = new Set([
  PERMISSION.REVIEW_PLANNER_APP,
  PERMISSION.REVIEW_STORAGE_MANAGEMENT_APP,
  PERMISSION.REVIEW_MASTER_APP,
  PERMISSION.REVIEW_WORKER_APP,
  PERMISSION.REVIEW_ADMIN_APP,
]);


/*
* Функция, определяющая типы внутренних приложений, доступных пользователю для просмотра. Для этого в массиве всех
* разрешений ищутся разрешения, связанные с доступом просмотра (для удобства обработки эти разрешения предварительно
* зафиксированы в сете APPS_REVIEW_PERMISSIONS_SET)
* */
export const getUserAppsReviewPermissions = (userPermissions = []) =>
  userPermissions
    .filter(permission => APPS_REVIEW_PERMISSIONS_SET.has(permission));

/*
* Функция, проверяющая может ли только что аутентифицированный пользователь находиться на текущем урл (currentUrl),
* исходя из набора его разрешений (userPermissions) и возвращающая этот текущий урл, если проверка пройдена, или
* дефолтный урл на основании разрешений пользователя.
*
* Кейсы, когда это нужно:
*  1. Пользователь входит в приложение по какой-то ссылке. Выполняется успешная проверка аутентификации пользователя,
* т.к. куки с прошлой сессии работы ещё не истекли. После аутентификации становятся известны разрешения пользователя
* и необходимо проверить, что ссылка, по которой он входил в приложение, разрешена для него.
* 2. Пользователь входит в приложение по какой-то ссылке. Аутентифицировать пользователя не удается, т.к. он заходит
* в приложение в первый раз или куки с прошлой сессии работы истекли. Пользователя редиректит на экран формы
* аутентификации, при этом ссылка, по которой он входил в приложение, фиксируется в гет параметрах урла роута
* формы аутентификации. После ввода логина и пароля и успешной аутентификации нужно проверить, что ссылка из
* гетпараметров (на которую должен быть выполнен редирект с роута формы аутентификации), разрешена для него.
* 3. Пользователь работает в приложении, но при выполнении очередного запроса получает ответ, что он более
* неаутентифицирован, т.к. куки истекли. Пользователя редиректит на экран формы аутентификации. Далее всё аналогично
* описанному в п.2. Нужна проверка после повторной аутентификации, по прежнему ли для пользователя доступна ссылка,
* с которой его редиректнуло на роут формы аутентификации, т.к. мог ауетнтифицироваться другой пользователь с другими
* правами.
*
* Для всех описанных кейсов, если проверка пройдена, то эта функция возвращает тот самый урл, который и проверялся, т.е.
* фиксируется, что заданный урл разрешен для текущего пользователя.
*
* Если же, во всех кейсах, проверка не пройдена, значит заданный урл запрещен для пользователя и функция вместо него
* возвращает дефолтный разрешенный урл:
*  - или это корневой урл всего приложения (т.е. '/'), если у пользователя в списке разрешений имеются разрешения для
* просмотра нескольких внутренних типов приложения. По этому роуту располагается экран выбора типа внутреннго приложения
* для просмотра. В качестве дефолтного урла логично вернуть именно этот урл, т.к. если пользователь окажется на нём, то
* он сможет сам выбрать какой тип внутренних приложения из разрешенных ему необходим.
*  - или это коренвой урл внутреннего типа приложения, если у пользователя в списке разрешений есть разрещения на
* просмотр только для одного конкретного внутреннего типа приложения. В качестве дефолтного урла логично вернуть именно
* этот урл, т.к. урлы всех других типов приложения ему запрещены, а экран выбора типа приложений по корневому роуту
* всего приложения (т.е. '/') для него не имеет смысла, т.к. там будет только одна опция с разрешенным типом приложения.
* */
export const getUserPermittedUrlAfterAuthentication = (currentUrl, userPermissions = []) => {

  /*
  * Определяем типы внутренних приложений, доступных пользователю для просмотра и для этих разерешний сразу получаем
  * данные этих типов приложений (из карты APPS_DATA_BY_REVIEW_PERMISSION, сформированной на основании карты данных
  * всех типов приложений APPS_DATA), это будет нужно для дальнейшей обработки
  * */
  const appsDataPermittedForReview = getUserAppsReviewPermissions(userPermissions)
    .map(permission => APPS_DATA_BY_REVIEW_PERMISSION[permission]);

  /*
  * Если пользователь существует, но у него по каким-то причинам нет прав для просмотра ни одного из типов
  * приложения, то, получается, что у него и нет разрешенных для просмотра урлов. В этом случае функция возвращает null.
  * */
  if(appsDataPermittedForReview.length === 0) return null;

  /*
  * Обработка случая, когда текущий урл currentUrl задан и не равен корневому роуту '/' всего приложения.
  * В этом случае, нужно понять, относится ли этот урл к одному из типов приложения, доступных пользователю, данные
  * по которым были сформированы ранее appsDataPermittedForReview. Если относится, значит проверка пройдена, возращаем
  * этот урл из функции. Случай, когда в currentUrl указан корневой роут '/' в  не участвует в этой проверке и
  * обрабатывается отдельно, потому что он логически "относится ко всем типам приложений" и эта информация ничего не
  * дает, для него нужна дополнительная логика
  * */
  if(!!currentUrl && currentUrl !== '/') {

    const currentUrlRelatedAppDataPermittedForReview = appsDataPermittedForReview
      .find(({ appMainRouteLink }) => !!matchPath(
        currentUrl,
        { path: appMainRouteLink },
      ));

    if(!!currentUrlRelatedAppDataPermittedForReview)
      return currentUrl;
  }

  /*
  * Далее, по сути, уже обрабатывается условие, когда currentUrl равен корневому роуту всего приложения '/', потому что,
  * до сюда код дойдёт:
  *  - если currentUrl не задан, в этом случае принимаем, что предполагается именно корневой роут '/'. В целом, наверно,
  * с таким параметром функция вызываться не будет
  *  - если currentUrl непосредственно и задан, как корневой роут '/'
  *  - если проверка внутри прошло ифа не прошла, т.е. currentUrl не относится ни к одному из типов приложений, которые
  *  разрешены пользователю для просмотра. В это случае предполагаем, что дефолтным урлом для пользователя должен,
  * по идее, быть корневой роут '/'.
  *
  * Т.е. во всех описанные выше случаях считается, что урл для редиректа равен корневому роуту '/'. Но для корневого
  * роута есть ещё дополнительная логика:
  * На этом роуте располагается экран выбора типов приложений и нелогично делать его разрешенным для пользователя, если
  * у него есть разрешение на просмотр только одного из внутренних типов приложений, т.к. в этом случае на экране будет
  * только одна опция выбора, на которую, в любом случае, придется дополнительно нажимать. Поэтому, для этого случая
  * логично сразу вернуть из функции корневой роут этого единственного доступного пользователю типа приложения.
  *  * */
  const isOnlyOneAppPermittedForReview = appsDataPermittedForReview.length === 1;

  return isOnlyOneAppPermittedForReview ?
    appsDataPermittedForReview[0].appMainRouteLink :
    '/';
};