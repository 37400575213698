import {
  CLEAR_ALL_DEFAULT_SHEETS_PARTS_AND_MATERIALS_TO_CONSUME,
  CLEAR_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME,
  SET_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME,
  SET_DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS,
} from './actions';

import _omit from 'lodash/omit';


const DEFAULT_SHEETS_INITIAL_STATE = {
  sheetsIdsWithEnoughPartsAndMaterials: [],
  dataToConsumeForSheetIds: {},
};

export const defaultSheets = (state = { ...DEFAULT_SHEETS_INITIAL_STATE }, action) => {
  switch(action.type) {
  case SET_DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS:
    return{
      ...state,
      sheetsIdsWithEnoughPartsAndMaterials: action.sheetsIds,
    };
  case SET_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME: {
    const { sheetId, dataToConsume } = action;
    return {
      ...state,
      dataToConsumeForSheetIds: {
        ...state.dataToConsumeForSheetIds,
        [sheetId]: [...dataToConsume],
      },
    };
  }
  case CLEAR_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME: {
    return {
      ...state,
      dataToConsumeForSheetIds: _omit(state.dataToConsumeForSheetIds, [action.sheetId]),
    };
  }
  case CLEAR_ALL_DEFAULT_SHEETS_PARTS_AND_MATERIALS_TO_CONSUME:
    return {
      ...state,
      dataToConsumeForSheetIds: {},
    };
  default:
    return state;
  }
};