import React from 'react';
import { FILTER_TYPES, WITH_PARAMS } from '../api/restCollectionApi';
import {
  DEPARTMENT_MODEL,
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  SHEET_OPERATION_MODEL,
} from './models';
import { RELATED_MODEL_FIELD_DELIMITER } from './magics';
import moment from 'moment';

import _get from 'lodash/get';
import { Trans } from '@lingui/macro';


export const SHEET_TYPE = {
  ALL_TO_START: 'ALL_TO_START_SHEET_TYPE',
  ASSEMBLY_TO_START: 'ASSEMBLY_TO_START_SHEET_TYPE',
  IN_PRODUCTION: 'IN_PRODUCTION_SHEET_TYPE',
  PAUSED: 'PAUSED_SHEET_TYPE',
  COMPLETED: 'COMPLETED_SHEET_TYPE',
  DEFAULT_WAITING_PARTS_AND_MATERIALS: 'DEFAULT_WAITING_PARTS_AND_MATERIALS_SHEET_TYPE',
  ASSEMBLY_WAITING_PARTS_AND_MATERIALS: 'ASSEMBLY_WAITING_PARTS_AND_MATERIALS_SHEET_TYPE',
};

export const SHEET_STATUS = {
  ACTIVE: 0,
  ARCHIVE: 1,
};

export const ENTITY_BATCH_STATUS = {
  IN_PRODUCTION: 0,
  PAUSED: 1,
  FINISHED: 2,
};

export const ENTITY_BATCH_PROVIDING_STATE = {
  UNPROVIDED: 0,
  PARTIALLY_PROVIDED: 1,
  PROVIDED: 2,
};

/**
 * флаг дата точки ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS.
 * Для сборочныех мл true указывает на то, что есть хоть какие-то ДСЕ для комплектации.
 * Для стандартных МЛ true, если есть ВСЕ ДСЕ для комплектации
 */
export const ENTITY_BATCH_PROVIDABLE_STATE = {
  PROVIDABLE: true,
  UNPROVIDABLE: false,
};

export const ENTITY_BATCH_DEFECT_STATE = {
  WITHOUT_DEFECT: 0,
  FIXING_DEFECT: 1,
  CANDIDATE_TO_DEFECT: 2,
  DEFECT: 3,
};

export const SHEET_OPERATION_STATUS = {
  CREATED: 0,
  IN_PRODUCTION: 1,
  PAUSED: 2,
  FINISHED: 3,
};

export const SHEET_OPERATION_STATUS_TITLES_TRANS_MSG_MAP = {
  [SHEET_OPERATION_STATUS.CREATED]: (
    <Trans id="sheet_operation_status@planned">
      Запланирована
    </Trans>
  ),
  [SHEET_OPERATION_STATUS.IN_PRODUCTION]: (
    <Trans id="sheet_operation_status@in_production">
      В работе
    </Trans>
  ),
  [SHEET_OPERATION_STATUS.PAUSED]: (
    <Trans id="sheet_operation_status@paused">
      На паузе
    </Trans>
  ),
  [SHEET_OPERATION_STATUS.FINISHED]: (
    <Trans id="sheet_operation_status@finished">
      Завершена
    </Trans>
  ),
};

/*
* Общие связанные модели, отношения моделей и фильтры запросов разных типов МЛ используются в разных файлах, поэтому,
* по избежании возможных циклических зависимостей вынесены в отдельный файл
* */

export const SHEETS_REQUEST_ALL_WITH_PARAMS = [
  ENTITY_BATCH_MODEL,
  SHEET_OPERATION_MODEL,
  OPERATION_MODEL,
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  ENTITY_MODEL,
  {
    column: ORDER_MODEL,
    params: [{ key: WITH_PARAMS.STRICT, value: false }],
  },
];

export const SHEETS_DEFAULT_REQUEST_OPTIONS = {
  modelRelations: {
    [ENTITY_BATCH_MODEL]: {
      level: 1,
    },
    [SHEET_OPERATION_MODEL]: {
      level: 1,
    },
    [OPERATION_MODEL]: {
      level: 2,
      relates: SHEET_OPERATION_MODEL,
    },
    [DEPARTMENT_MODEL]: {
      level: 2,
      relates: SHEET_OPERATION_MODEL,
    },
    [EQUIPMENT_CLASS_MODEL]: {
      level: 2,
      relates: SHEET_OPERATION_MODEL,
    },
    [ENTITY_MODEL]: {
      level: 2,
      relates: ENTITY_BATCH_MODEL,
    },
    [ORDER_MODEL]: {
      level: 2,
      relates: ENTITY_BATCH_MODEL,
    },
  },
};



/*
* ATTENTION!!! Точно не уверен в даный момент, но для приложения плановик в разделе "МЛ в производстве", вроде как,
* сейчас нужно отображать МЛ и с комлектацией и без, поэтому тут поле providingState для партий этих МЛ может быть любым,
* т.е. фильтра по этому полю нет. Это может измениться в будущем
* */
export const SHEET_TYPE_IN_PRODUCTION_REQUEST_FILTERS = [
  {
    column: 'type',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: SHEET_STATUS.ACTIVE,
  },
  {
    column: [ENTITY_BATCH_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
    filterType: FILTER_TYPES.EQUALS,
    filterValue: ENTITY_BATCH_STATUS.IN_PRODUCTION,
  },
];

export const SHEET_TYPE_PAUSED_REQUEST_FILTERS = [
  {
    column: 'type',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: SHEET_STATUS.ACTIVE,
  },
  {
    column: [ENTITY_BATCH_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
    filterType: FILTER_TYPES.EQUALS,
    filterValue: ENTITY_BATCH_STATUS.PAUSED,
  },
];

export const SHEET_TYPE_COMPLETED_REQUEST_FILTERS = [
  {
    column: 'type',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: SHEET_STATUS.ACTIVE,
  },
  {
    column: [ENTITY_BATCH_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
    filterType: FILTER_TYPES.EQUALS,
    filterValue: ENTITY_BATCH_STATUS.FINISHED,
  },
];

export const SHEET_TYPE_DEFAULT_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS = [
  {
    column: 'type',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: SHEET_STATUS.ACTIVE,
  },
  {
    column: [ENTITY_BATCH_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
    filterType: FILTER_TYPES.EQUALS,
    filterValue: ENTITY_BATCH_STATUS.IN_PRODUCTION,
  },
  {
    column: [ENTITY_BATCH_MODEL, 'providingState'].join(RELATED_MODEL_FIELD_DELIMITER),
    filterType: FILTER_TYPES.EQUALS,
    filterValue: ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED,
  },
  {
    column: 'fullProvidingRequired',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: true,
  },
];

export const SHEET_TYPE_ASSEMBLY_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS = [
  {
    column: 'type',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: SHEET_STATUS.ACTIVE,
  },
  {
    column: [ENTITY_BATCH_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
    filterType: FILTER_TYPES.EQUALS,
    filterValue: ENTITY_BATCH_STATUS.IN_PRODUCTION,
  },
  {
    column: [ENTITY_BATCH_MODEL, 'providingState'].join(RELATED_MODEL_FIELD_DELIMITER),
    filterType: FILTER_TYPES.ONE_OF,
    filterValue: [
      ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED,
      ENTITY_BATCH_PROVIDING_STATE.PARTIALLY_PROVIDED,
    ],
  },
  {
    column: 'fullProvidingRequired',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: false,
  },
];

export const SHEET_OPERATIONS_DEFAULT_REQUEST_OPTIONS = {
  modelRelations: {
    [OPERATION_MODEL]: {
      level: 1,
    },
    [DEPARTMENT_MODEL]: {
      level: 1,
    },
    [EQUIPMENT_CLASS_MODEL]: {
      level: 1,
    },
    [SHEET_MODEL]: {
      level: 1,
    },
    [SHEET_OPERATION_ASSIGNEE_MODEL]: {
      level: 1,
    },
    [ENTITY_BATCH_MODEL]: {
      level: 2,
      relates: SHEET_MODEL,
    },
    [ENTITY_MODEL]: {
      level: 3,
      relates: ENTITY_BATCH_MODEL,
    },
    [ORDER_MODEL]: {
      level: 3,
      relates: ENTITY_BATCH_MODEL,
    },
  },
};


const startOfTodayMoment = moment().startOf('day');
export const DEFAULT_DATE_INTERVAL_START = startOfTodayMoment.format();
export const DEFAULT_DATE_INTERVAL_STOP = startOfTodayMoment
  .add(_get(window.config, 'DEFAULT_PLANNING_HORIZON_IN_DAYS'), 'days')
  .format();

export const DEFAULT_FLOAT_NUMS_PRECISION = 4;