import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
  NEW_USER_SUB_ROUTE,
} from '../../../constants/routes';
import { Button } from '@mui/material';

import './style.css';
import { SheetOperationFeatureScopeForm } from '../SheetOperationFeatureScopeForm/SheetOperationFeatureScopeForm';
import {
  ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID,
  CreatingNewSheetOperationFeatureModalTitleTrans,
  EditingSheetOperationFeatureModalTitleTrans,
  getFeatureScopeChildrenIds,
  getFeatureScopeDataForSubmit,
  getSheetOperationFeatureScopesTableCustomCellRenderersProps,
  initializeSheetOperationFeatureReviewData,
  isSheetOperationFeatureScopeUniqValidator,
  sendNoChangesOnFormNotification,
  SHEET_OPERATION_FEATURES_SCOPES_TABLE_MENU_OPTIONS,
} from './constants';
import {
  CreateLabelTrans,
  ResetLabelTrans,
  SaveLabelTrans,
} from '../../../utils/commonTransComponents';
import { CardWithCustomHeader } from '../../common/CardWithCustomHeader/CardWithCustomHeader';
import { Trans } from '@lingui/macro';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import { TextFormField } from '../../common/TextFormField/TextFormField';
import {
  MATERIAL_UI_DIALOG_MAX_WIDTH,
  MATERIAL_UI_LABEL_PLACEMENT,
  MATERIAL_UI_STYLE_COLOR,
} from '../../../constants/materialUI';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Table } from '../../Table/Table';
import _omit from 'lodash/omit';
import { required } from '../../../utils/formValidators';
import {
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL, OPERATION_MODEL,
  SHEET_OPERATION_FEATURE_SCOPE_MODEL,
} from '../../../constants/models';
import { NOTIFICATION_LEVEL, sendNotification } from '../../../constants/notification';
import { reactRouterParamsSelector } from '../../../selectors/reactRouter';
import { SwitchControl } from '../../common/SwitchControl/SwitchControl';
import { useConfirm } from '../../AppConfirm/AppConfirmContext';
import {
  createSheetOperationFeatureWithScopes,
  editSheetOperationFeatureWithScopes,
} from '../../../operations/sheetOperationFeature';
import _isEmpty from 'lodash/isEmpty';
import { clearTableRemoteData } from '../../../reducers/table/actions';
import {
  ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID,
  useSheetOperationFeatureScopesTableData,
} from '../SheetOperationFeatures/constants';
import { useConfirmOnLeave } from '../../../hoc/confirmOnLeave/confirmOnLeave';
import _isEqual from 'lodash/isEqual';
import Typography from '@mui/material/Typography';
import { TooltipWithForwardedRef } from '../../common/TooltipWrapper/TooltipWithForwardedRef';
import InfoIcon from '@mui/icons-material/Info';


export const SheetOperationFeatureReviewScreen = props => {

  const dispatch = useDispatch();

  const confirm = useConfirm();

  const [featureName, setFeatureName] = useState('');

  const [featureNameError, setFeatureNameError] = useState({});

  const [featureScopesById, setFeatureScopesById] = useState({});

  const [initialValues, setInitialValues] = useState(null);

  const { featureId, activeState } = reactRouterParamsSelector(null, props);

  const isCreateMode = useMemo(() => featureId === NEW_USER_SUB_ROUTE, [featureId]);

  const tableRowsData = useSheetOperationFeatureScopesTableData(featureScopesById, initialValues);

  const isFormDataChanged = useMemo(
    () => {
      if (initialValues === null) return false;

      return initialValues.featureName !== featureName ||
        !_isEqual(featureScopesById, initialValues.featureScopesById);
    },
    [initialValues, featureScopesById, featureName],
);

  const shouldCheckChangesOnLeaveRef = useRef(true);
  const setShouldCheckChangesOnLeave = useCallback(
    shouldCheckChangesOnLeave => shouldCheckChangesOnLeaveRef.current = shouldCheckChangesOnLeave,
    [shouldCheckChangesOnLeaveRef],
  );

  /*
  обработчики
  */
  const initializeData = useCallback(() => dispatch(initializeSheetOperationFeatureReviewData({
    featureId,
    setFeatureName,
    setFeatureScopesById,
    setInitialValues,
    isCreateMode,
    activeState,
  })), [
    featureId,
    dispatch,
    setFeatureName,
    setFeatureScopesById,
    setInitialValues,
    isCreateMode,
    activeState,
  ]);

  const returnToSheetOperationFeaturesScreen = useCallback(
    () => dispatch(push(ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE)),
    [dispatch],
  );

  const deleteFeatureScope = useCallback(
    rowId => setFeatureScopesById(prevState => _omit(prevState, rowId)),
    [setFeatureScopesById],
  );

  const sheetOperationFeatureScopeSubmitHandler = useCallback(featureScope => {
    const { id } = featureScope;

    const sheetOperationFeatureScopeErrorMessage = isSheetOperationFeatureScopeUniqValidator(id, featureScopesById);

    if (sheetOperationFeatureScopeErrorMessage) {
      return sendNotification(
        sheetOperationFeatureScopeErrorMessage,
        NOTIFICATION_LEVEL.ERROR,
        { timeOut: 10000 },
      );
    }

    /*
    Вычисляем дочерние области действия для добавляемой области
    */
    const childrenOfNewFeatureScopeIds = getFeatureScopeChildrenIds(id, Object.keys(featureScopesById));

    if (childrenOfNewFeatureScopeIds.length) {
      return confirm({
        confirmText: (
          <Trans id="sheet_operation_settings.sheet_operation_features@delete_children_features_scopes_confirm">
            При добавлении выбранной области действия, некоторые из текущих областей станут дублирующими и будут
            удалены (Количество дублирующих областей, которые будут удалены: {childrenOfNewFeatureScopeIds.length}).
            Вы уверены?
          </Trans>
        ),
        confirmDialogInnerProps: {
          dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
        },
      })
        .then(() =>  setFeatureScopesById(prevState => {
          const allFeaturesScopesById = {
            ...prevState,
            [id]: featureScope,
          };

          /*
          При добавлении новой области действия удаляем все её дочерние области из текущего стейта
          */
          return _omit(allFeaturesScopesById, childrenOfNewFeatureScopeIds);
        }));
    }

    setFeatureScopesById(prevState => ({
      ...prevState,
      [id]: featureScope,
    }));

  }, [confirm, featureScopesById, setFeatureScopesById]);


  const applyToAllOperationsChangeHandler = useCallback(checked => {
    if (checked) {
      return sheetOperationFeatureScopeSubmitHandler({
        id: ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID,
        [DEPARTMENT_MODEL]: { id: null },
        [EQUIPMENT_CLASS_MODEL]: { id: null },
        [OPERATION_MODEL]: { id: null },
      });
    }

    return setFeatureScopesById(prevState => _omit(prevState, [ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID]));
  }, [sheetOperationFeatureScopeSubmitHandler, setFeatureScopesById]);


  const submitHandler = useCallback(() => {
    if (initialValues === null) return;

    const featureNameErrorMessage = required(featureName);

    if (featureNameErrorMessage) {
      return setFeatureNameError({
        errorMsg: featureNameErrorMessage,
        errorValue: featureName,
      });
    }

    if (_isEmpty(featureScopesById)) {
      return sendNotification(
        <Trans id="sheet_operation_settings.sheet_operation_features@feature_scopes_is_empty_error">
          Для создания характеристики необходимо добавить её области действия или выбрать опцию "Назначить на все
          характеристики операции"
        </Trans>,
        NOTIFICATION_LEVEL.ERROR,
      );
    }

    if (isCreateMode) {
      const featureScopesDataToSubmit = Object
          .values(featureScopesById)
          .map(getFeatureScopeDataForSubmit);

      const sheetOperationFeatureDataToSubmit = {
        name: featureName,
      };

      return dispatch(createSheetOperationFeatureWithScopes(
        sheetOperationFeatureDataToSubmit,
        featureScopesDataToSubmit,
      ))
        /*
        После создания характеристики выполняем редирект на экран списком активных характеристик
        */
        .then(() => {
          dispatch(clearTableRemoteData([ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID]));
          setShouldCheckChangesOnLeave(false);
          returnToSheetOperationFeaturesScreen();
        });

    }

    /*
    Вычисляем добавленные области действия. Если в initialValues нет областей действия, которые есть в текущих областях,
    значит это новые добавленные области действия
    */
    const addedFeatureScopes = Object
      .values(featureScopesById)
      .filter(({ id }) => !initialValues.featureScopesById[id])
      .map(getFeatureScopeDataForSubmit);

    /*
    Вычисляем удалённые области действия. Если в текущих областях действия нет тех, которые есть в initialValue,
    значит эти области действия были удалены
    */
    const deletedFeatureScopesIds = Object
      .values(initialValues.featureScopesById)
      .filter(({ id }) => !featureScopesById[id])
      .map(({ sheetOperationFeatureScopeIdFromDb }) => sheetOperationFeatureScopeIdFromDb);

    const newFeatureName = featureName === initialValues.featureName ? null : featureName;

    /*
    Если в форме не было никаких изменений, то не выполняем запрос
    */
    if (!isFormDataChanged) {
      return sendNoChangesOnFormNotification();
    }

    const sheetOperationFeatureDataToSubmit = {
      name: newFeatureName,
      id: featureId,
    };


    dispatch(editSheetOperationFeatureWithScopes(
      sheetOperationFeatureDataToSubmit,
      deletedFeatureScopesIds,
      addedFeatureScopes,
    ))
      .then(({ areAllChangesSaved }) => {
        dispatch(clearTableRemoteData([ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID]));

        if (areAllChangesSaved) {
          setShouldCheckChangesOnLeave(false);
          returnToSheetOperationFeaturesScreen();
        }
      });
  }, [
    dispatch,
    featureId,
    initialValues,
    isCreateMode,
    featureName,
    featureScopesById,
    setFeatureNameError,
    returnToSheetOperationFeaturesScreen,
    setShouldCheckChangesOnLeave,
    isFormDataChanged,
  ]);

  useConfirmOnLeave(
    ({ shouldCheckChangesOnLeaveRef, isFormDataChanged }) => {
      if(!shouldCheckChangesOnLeaveRef.current) return false;

      return isFormDataChanged;
    },
    {
      shouldCheckChangesOnLeaveRef,
      isFormDataChanged,
    },
  );

  const customCellRenderersProps = useMemo(
    () => getSheetOperationFeatureScopesTableCustomCellRenderersProps(deleteFeatureScope),
    [deleteFeatureScope],
  );

  const resetState = useCallback(() => {
    if (!isFormDataChanged) {
      return sendNoChangesOnFormNotification();
    }

    confirm({
      confirmText: (
        <Trans id="sheet_operation_settings.sheet_operation_features@reset_feature_form_state_confirm">
          Все введённые  изменения будут удалены. Вы уверены?
        </Trans>
      ),
      confirmDialogInnerProps: {
        dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
      },
    })
      .then(() => {
        setFeatureName(initialValues.featureName);
        setFeatureScopesById(initialValues.featureScopesById);
      });
  }, [
    initialValues,
    setFeatureName,
    setFeatureScopesById,
    confirm,
    isFormDataChanged,
  ]);

  /*
  эффекты
  */

  /*
  Зависимости этого хука могут измениться только при обновлении ссылки в роутинге, сейчас это не используется, но
  могут появиться кейсы, в которых ссылка будет менять и тогда данные на экране просмотра характеристики автоматически
  обновятся
  */
  // eslint-disable-next-line
  useEffect(initializeData, [isCreateMode, featureId, activeState]);

  if (initialValues === null) return null;

  const isFeatureAppliedToAllOperations = !!featureScopesById[ALL_OPERATIONS_IN_SYSTEM_SCOPE_ID];

  return (
    // Используем не тег form, потому что в компоненте SheetOperationFeatureScopeForm уже есть form. По спецификации
    // теш form не может содержать в себе другой тег form. Если так сделать, то e.preventDefault() тут не сработает
    <div className="sheet-operation-feature-review-screen">
      <CardWithCustomHeader
          className="sheet-operation-feature-review-screen__card"
          header={
            <span className="sheet-operation-feature-review-screen__card-title">
              {isCreateMode ? CreatingNewSheetOperationFeatureModalTitleTrans : EditingSheetOperationFeatureModalTitleTrans}
            </span>
          }
          actionsContent={
            <Button onClick={returnToSheetOperationFeaturesScreen} className="sheet-operation-feature-review-screen__return-to-features-screen-button" >
              <NavigateBefore className="sheet-operation-feature-review-screen__return-to-features-screen-button-icon" />
              <Trans id="sheet_operation_settings.sheet_operation_features@return_to_features_screen">
                Вернуться к характеристикам
              </Trans>
            </Button>
          }
          content={
            <div>
              <CardContent  className="sheet-operation-feature-review-screen__content">

                <Typography className="sheet-operation-feature-review-screen__subtitle" variant="h4" component="h3">
                  <Trans id="sheet_operation_settings.sheet_operation_feature_scope_form@feature_name">
                    Наименование характеристики
                  </Trans>
                </Typography>

                <div className="sheet-operation-feature-review-screen__section">
                  <TextFormField
                      wrapperClassName="sheet-operation-feature-review-screen__text-field-wrapper"
                      className="sheet-operation-feature-review-screen__text-field"
                      value={featureName}
                      onChange={setFeatureName}
                      error={featureName === featureNameError.errorValue ? featureNameError.errorMsg : null}
                  />
                </div>

                <div className="sheet-operation-feature-review-screen__section">
                  <div className="sheet-operation-feature-review-screen__feature-scope-header">
                    <Typography className="sheet-operation-feature-review-screen__subtitle" variant="h4" component="h3">

                      <Trans id="sheet_operation_settings.sheet_operation_feature_scope_form@feature_scope">
                        Область действия характеристики
                      </Trans>

                      <TooltipWithForwardedRef
                          className="sheet-operation-feature-review-screen__tooltip"
                          title={
                            <Trans id="sheet_operation_settings.sheet_operation_features@scopes_tooltip">
                              От выбора области действия зависит на какие операции будет назначена характеристика. Если
                              выбрана опция "Назначить на все операции в системе", характеристика будет назначена на все
                              операции в системе. Если выбрано только подразделение, то характеристика будет назначена на
                              все операции подразделения. Если выбрано подразделение и класс РЦ, то характеристика будет
                              назначена на все операции класса РЦ в этом подразделении. Для назначения характеристики
                              на конкретную операцию нужно выбрать подразделение, класс РЦ и операцию.
                            </Trans>
                          }
                          placement="right"
                      >
                        <InfoIcon
                            className="sheet-operation-feature-review-screen__info-icon"
                            color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
                        />
                      </TooltipWithForwardedRef>
                    </Typography>

                    <SwitchControl
                        onChange={applyToAllOperationsChangeHandler}
                        checked={isFeatureAppliedToAllOperations}
                        labelPlacement={MATERIAL_UI_LABEL_PLACEMENT.START}
                        className="sheet-operation-feature-review-screen__switch-control"
                        label={
                        <Trans id="sheet_operation_settings.sheet_operation_features@applies_to_all_operations">
                          Назначить на все операции в системе
                        </Trans>
                      }
                    />
                  </div>

                  {
                    isFeatureAppliedToAllOperations ?
                      null :
                      <SheetOperationFeatureScopeForm
                          isFormDisabled={isFeatureAppliedToAllOperations}
                          onSubmit={sheetOperationFeatureScopeSubmitHandler}
                      />
                  }

                </div>

                <div className="sheet-operation-feature-review-screen__section">

                  <Table
                      tableId={SHEET_OPERATION_FEATURE_SCOPE_MODEL}
                      tableModel={SHEET_OPERATION_FEATURE_SCOPE_MODEL}
                      rowsData={tableRowsData}
                      noDataContent={(
                        <Trans id="sheet_operation_settings.sheet_operation_features@feature_scopes_no_data_label">
                          Для характеристики не добавлены области действия
                        </Trans>
                      )}
                      customCellRenderersProps={customCellRenderersProps}
                      disableFilter
                      tableMenu={SHEET_OPERATION_FEATURES_SCOPES_TABLE_MENU_OPTIONS}
                  />
                </div>

                <CardActions className="sheet-operation-feature-review-screen__card-actions">
                  <Button
                      className="sheet-operation-feature-review-screen__button"
                      color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
                      onClick={resetState}
                  >
                    {ResetLabelTrans}
                  </Button>
                  <Button
                      className="sheet-operation-feature-review-screen__button"
                      onClick={submitHandler}
                  >
                    {isCreateMode ? CreateLabelTrans : SaveLabelTrans}
                  </Button>
                </CardActions>
              </CardContent>
            </div>
          }
      />
    </div>
  );
};


