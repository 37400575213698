import React from 'react';
import PropTypes from 'prop-types';

import Tab from '@mui/material/Tab';

import { Route, NavLink } from 'react-router-dom';

import { CardWithCustomHeader } from '../CardWithCustomHeader/CardWithCustomHeader';
import { CardWithNavHeader } from '../CardWithNavHeader/CardWithNavHeader';

import { CARD_MENU_OPTIONS_TYPE } from '../CardWithCustomHeader/cardWithCustomHeader.types';
import {  NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';

import cn from 'classnames';

import './style.css';

export class CardWithNavLinksHeader extends CardWithNavHeader {

  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    navTabs: PropTypes.arrayOf(PropTypes.shape({
      tabId: NUMBER_OR_STRING_TYPE.isRequired,
      tabTitle: PropTypes.node.isRequired,
      tabIcon: PropTypes.func,
      tabLink: PropTypes.string.isRequired,
      tabRouteParams: PropTypes.shape({
        path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        component: PropTypes.elementType,
        render: PropTypes.func,
      }).isRequired,
    }).isRequired),
    menuOptions: CARD_MENU_OPTIONS_TYPE,
    actionsContent: PropTypes.node,
  };

  static defaultProps = {
    navTabs: [],
  };


  _renderTab = navLinkTabData => {
    const {
      tabId,
      tabTitle,
      tabIcon: TabIcon,
      tabLink,
    } = navLinkTabData;
    return(
      <Tab
          key={tabId}
          classes={{
            root: 'card-with-nav-links-header__tab-link card-with-nav-header__tab',
          }}
          icon={
            TabIcon && <TabIcon />
          }
          label={tabTitle}
          component={NavLink}
          to={tabLink}
      />
    );
  };

  _renderCardContent = () =>
    <div className="card-with-nav-links-header__card-content card-with-nav-header__card-content">
      {
        this.props.navTabs
          .map(
            ({ tabId, tabLink, tabRouteParams = {} }) => {
              const {
                path,
                component: Component,
                render,
              } = tabRouteParams;

              const resultRoutePath = path || tabLink;

              /*
              * Реализована возможность задавать то, что нужно рендерить по роуту, или через component, или через
              * render, какой-то дополнительной проверки, что задали оба пропса нет, т.к. задавать таким образом
              * абсолютно не логично и в этом случае ругнется сам Route, сказав, что render будет проигнорирован
              * */
              return (
                <Route
                    key={tabId}
                    path={resultRoutePath}
                    component={Component}
                    render={render}
                />
              );
            },
          )
      }
    </div>;

  render() {
    const {
      className,
      menuOptions,
      actionsContent,
    } = this.props;
    return (
      <CardWithCustomHeader
          className={
            cn(
              className,
              'card-with-nav-links-header',
              'card-with-nav-header',
            )
          }
          header={this._renderCardNavHeader()}
          menuOptions={menuOptions}
          content={this._renderCardContent()}
          actionsContent={actionsContent}
      />
    );
  }
}
