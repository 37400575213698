import React from 'react';
import PropTypes from 'prop-types';
import { ORDER_ENTRIES_TABLE_DATA_TYPE, OrderEntriesTable } from '../../OrderEntriesTable/OrderEntriesTable';


export const OrdersInProductionReviewContent = props => {
  const {
    orderEntriesTableId,
    orderEntriesTableData,
    fetchOrderEntriesTableData,
  } = props;

  return (
    <OrderEntriesTable
        orderEntriesTableId={orderEntriesTableId}
        orderEntriesTableData={orderEntriesTableData}
        fetchOrderEntriesTableData={fetchOrderEntriesTableData}
    />
  );
};

OrdersInProductionReviewContent.propTypes = {
  orderEntriesTableId: PropTypes.string.isRequired,
  orderEntriesTableData: ORDER_ENTRIES_TABLE_DATA_TYPE,
  fetchOrderEntriesTableData: PropTypes.func.isRequired,
};