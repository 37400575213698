import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import _isNil from 'lodash/isNil';
import cn from 'classnames';
import { GRID_VIEW_INFO_ROW_GRID_PROPS_TYPE } from './GridViewInfoRow.types';


export const GridViewInfoRow = ({
  wrapperClassName,
  labelClassName,
  label,
  valueClassName,
  value,
  emptyValue,
  delimiter = ':',
  gridProps = {},
}) => {

  const {
    containerGridSpacing,
    labelItemGridBreakpoints,
    valueItemGridBreakpoints,
  } = gridProps;

  return (
    <Grid
        container
        className={
          cn(
            'grid-view-info-row',
            wrapperClassName,
          )
        }
        spacing={containerGridSpacing}
    >
      <Grid
          item
          className={
            cn(
              'grid-view-info-row__label',
              labelClassName,
            )
          }
          {...labelItemGridBreakpoints}
      >
        {label}
        {delimiter}
      </Grid>
      <Grid
          item
          className={
            cn(
              'grid-view-info-row__value',
              valueClassName,
            )
          }
          {...valueItemGridBreakpoints}
      >
        {_isNil(value) ? emptyValue : value}
      </Grid>
    </Grid>
  );
};

GridViewInfoRow.propTypes = {
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.node.isRequired,
  valueClassName: PropTypes.string,
  value: PropTypes.node.isRequired,
  emptyValue: PropTypes.node,
  delimiter: PropTypes.string,
  gridProps: GRID_VIEW_INFO_ROW_GRID_PROPS_TYPE.isRequired,
};