import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { UserEditingScreen } from './UserEditingScreen';
import { reactRouterParamsSelector } from '../../../selectors/reactRouter';
import { ADMIN_APP_USERS_ROUTE, NEW_USER_SUB_ROUTE } from '../../../constants/routes';
import { push } from 'connected-react-router';
import {
  fetchUserById,
  createUser,
  editUser,
  deactivateUser,
  reFetchActiveUsersRemoteTableData,
} from '../../../operations/users';
import {
  activeUsersRemoteTableDataSelector,
} from '../../../selectors/usersAdministration';
import { appUserIdSelector } from '../../../reducers/appState/selectors';
import { clearTableRemoteData } from '../../../reducers/table/actions';
import { ADMIN_APP_DEACTIVATED_USERS_TABLE_ID } from '../UsersTable/constants';
import { withPermissionsManager } from '../../../hoc/withPermissionsManager/withPermissionsManager';
import { PERMISSION } from '../../../hoc/withPermissionsManager/constants';
import {
  _prepareFormWithServerValidationMultiSelectValueForSubmit,
  _prepareFormWithServerValidationTextFieldValueForSubmit,
} from '../../../utils/formsWithServerValidation';


const mapStateToProps = (state, ownProps) => {
  const { userId: userIdFromRoute } = reactRouterParamsSelector(null, ownProps);

  return {
    appUserId: appUserIdSelector(state),
    userIdFromRoute,
    activeUsersTableData: activeUsersRemoteTableDataSelector(state),
    isCreatingMode: userIdFromRoute === NEW_USER_SUB_ROUTE,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchUserById,
    reFetchActiveUsersRemoteTableData,
    clearTableRemoteData,
    createUser,
    editUser,
    deactivateUser,
  }, dispatch),
  redirectToUserAdministrationScreen: () => dispatch(push(ADMIN_APP_USERS_ROUTE)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    appUserId,
    userIdFromRoute,
    activeUsersTableData,
    isCreatingMode,
  } = stateProps;

  const {
    fetchUserById,
    fetchUserRoles,
    reFetchActiveUsersRemoteTableData,
    clearTableRemoteData,
    createUser,
    editUser,
    deactivateUser,
    redirectToUserAdministrationScreen,
    onCancel,
  } = dispatchProps;

  const {
    PermissionsManager,
  } = ownProps;

  return {
    userIdFromRoute,
    activeUsersTableData,
    isCreatingMode,
    fetchUserById,
    fetchUserRoles,
    redirectToUserAdministrationScreen,
    onCancel,

    /*
    * Когда нет разрешения для функционала (!isUserDeactivationPermitted), то просто не отрисовываем кнопку, когда
    * для функицонала есть разрешения, то кнопку отрисовываем в любом случае, даже если опция, всё равно, недоступна,
    * в данном случае для случая, когда текущий пользователь просматривает сам себя (isUserEditingHimself), а мы
    * запрещаем деактивировать самого себя, т.к. это нелогично. Можно было бы всегда просто не отрисовывать кнопку,
    * но для дублирующего функционала деактивации в меню у таблицы пользователей в UsersTableScreen именно такая
    * логика (подробнее почему там так, в комментарии в UsersTableScreen при формировании опций меню таблицы), здесь
    * делаем для однотипности
    * */
    isUserDeactivationPermitted: PermissionsManager.isGranted(PERMISSION.DEACTIVATE_USER),
    isUserEditingHimself: String(appUserId) === userIdFromRoute,
    reFetchActiveUsersRemoteTableData,
    clearDeactivatedUsersTableData: () => clearTableRemoteData([ADMIN_APP_DEACTIVATED_USERS_TABLE_ID]),
    onDeactivate: () => deactivateUser(userIdFromRoute),
    onSubmit: formData => {
      const {
        name,
        lastName,
        patronymicName,
        identity,
        userRoles,
        password,
        note,
      } = formData;

      const userData = {
        name: _prepareFormWithServerValidationTextFieldValueForSubmit(name),
        lastName: _prepareFormWithServerValidationTextFieldValueForSubmit(lastName),
        patronymicName: _prepareFormWithServerValidationTextFieldValueForSubmit(patronymicName),
        identity: _prepareFormWithServerValidationTextFieldValueForSubmit(identity),
        password: _prepareFormWithServerValidationTextFieldValueForSubmit(password),
        note: _prepareFormWithServerValidationTextFieldValueForSubmit(note),
        roleIds: _prepareFormWithServerValidationMultiSelectValueForSubmit(
          userRoles.map(({ id }) => id),
        ),
      };

      if(isCreatingMode) {
        return createUser(userData);
      }

      return editUser({ ...userData, id: Number(userIdFromRoute) });
    },
  };
};

export const UserEditingScreenContainer = compose(
  withPermissionsManager,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(UserEditingScreen);