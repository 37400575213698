import { getSettingsEntityUniqId } from '../../../constants/settings';

export const WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_GROUP = 'WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_GROUP';
export const WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_NAME = 'WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_NAME';

export const WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_ID = getSettingsEntityUniqId(
  WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_GROUP,
  WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_NAME,
);

export const WORKER_TASKS_ADMIN_FILTERS_SETTINGS_INITIAL_STATE = {
  isWorkerTasksFiltersManagingByAdmin: false,
  tasksFilters: [],
};