import {
  SHEET_STARTED_EVENT_TYPE,
  SHEET_FINISHED_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED_EVENT_TYPE,
  SHEET_OPERATION_STATUS_CHANGED_EVENT_TYPE,
  SHEET_OPERATION_DATA_CHANGED_EVENT_TYPE,
  EQUIPMENT_ENTITY_CREATED_EVENT_TYPE,
  SHEET_PAUSED_EVENT_TYPE,
  SHEET_RESUMED_EVENT_TYPE,
  ENTITY_BATCH_SPLIT_EVENT_TYPE,
  ORDER_COMPLETED_EVENT_TYPE,
  ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_RESERVED_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_CONSUMED_EVENT_TYPE,
  DEFECTIVE_ENTITIES_MARKED_EVENT_TYPE,
  SHEET_OPERATION_FEATURE_DELETED_EVENT_TYPE,
} from '../../../../constants/sockets';
import { handleSheetStart } from './sheetStart';
import { handleSheetFinish } from './sheetFinish';
import _isFunction from 'lodash/isFunction';
import { getClientMessagesDataArray } from '../getClientMessagesDataArray';
import { handleSheetOperationStatusChange } from './sheetOperationStatusChange';
import { handlePartsAndMaterialsForDefaultSheetConsumption } from './partsAndMaterialsForDefaultSheetConsumption';
import { handleSheetOperationDataChange } from './sheetOperationDataChange';
import { handleEquipmentEntityCreation } from './equipmentEntityCreation';
import { handleEntityBatchSplit } from './entityBatchSplit';
import { handleSheetPause } from './sheetPause';
import { handleSheetResume } from './sheetResume';
import { handleOrderComplete } from './orderComplete';
import { handleAssemblySheetWasSendToProduction } from './assemblySheetWasSendToProduction';
import { handlePartsAndMaterialsForAssemblySheetReservation } from './partsAndMaterialsForAssemblySheetReservation';
import { handlePartsAndMaterialsForAssemblySheetConsumption } from './partsAndMaterialsForAssemblySheetConsumption';
import { handleDefectiveEntitiesMarked } from './defectiveEntitiesMarked';
import { handleSheetOperationFeatureDeleted } from './sheetOperationFeatureDeleted';



const EVENTS_HANDLERS = {
  [SHEET_STARTED_EVENT_TYPE]: handleSheetStart,
  [SHEET_FINISHED_EVENT_TYPE]: handleSheetFinish,
  [SHEET_OPERATION_STATUS_CHANGED_EVENT_TYPE]: handleSheetOperationStatusChange,
  [PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED_EVENT_TYPE]: handlePartsAndMaterialsForDefaultSheetConsumption,
  [PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_RESERVED_EVENT_TYPE]: handlePartsAndMaterialsForAssemblySheetReservation,
  [PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_CONSUMED_EVENT_TYPE]: handlePartsAndMaterialsForAssemblySheetConsumption,
  [SHEET_OPERATION_DATA_CHANGED_EVENT_TYPE]: handleSheetOperationDataChange,
  [EQUIPMENT_ENTITY_CREATED_EVENT_TYPE]: handleEquipmentEntityCreation,
  [SHEET_PAUSED_EVENT_TYPE]: handleSheetPause,
  [SHEET_RESUMED_EVENT_TYPE]: handleSheetResume,
  [ENTITY_BATCH_SPLIT_EVENT_TYPE]: handleEntityBatchSplit,
  [ORDER_COMPLETED_EVENT_TYPE]: handleOrderComplete,
  [ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION_EVENT_TYPE]: handleAssemblySheetWasSendToProduction,
  [DEFECTIVE_ENTITIES_MARKED_EVENT_TYPE]: handleDefectiveEntitiesMarked,
  [SHEET_OPERATION_FEATURE_DELETED_EVENT_TYPE]: handleSheetOperationFeatureDeleted,
};

export const handleCaClientMessage = message =>
  dispatch => {
    dispatch(getClientMessagesDataArray(message))
      .then(messageDataArr =>
        messageDataArr
          .forEach(({ data, event }) => {
            const handler = EVENTS_HANDLERS[event];
            if(!_isFunction(handler)) return;

            dispatch(handler(data));
          }));
  };