import {
  CLEAR_AUTOCOMPLETE_DATA,
  SELECT_AUTOCOMPLETE_VALUE,
  SET_AUTOCOMPLETE_OPTIONS,
  ADD_AUTOCOMPLETE_OPTIONS,
} from './actions';

import _omit from 'lodash/omit';


const AUTOCOMPLETE_INITIAL_STATE = {
  options: [],
  value: null,
};

export const autocompleteReducer = (state = {}, action) => {
  const { id, type } = action;

  switch(type) {
  case SET_AUTOCOMPLETE_OPTIONS:
  case ADD_AUTOCOMPLETE_OPTIONS:
  case SELECT_AUTOCOMPLETE_VALUE: {
    return {
      ...state,
      [id]: autocompleteForIdReducer(state[id], action),
    };
  }
  case CLEAR_AUTOCOMPLETE_DATA: {
    return {
      ..._omit(state, [id]),
    };
  }
  default: {
    return state;
  }
  }
};

const autocompleteForIdReducer = (state = { ...AUTOCOMPLETE_INITIAL_STATE }, action) => {
  const {
    options,
    value,
    type,
  } = action;

  switch(type) {
  case SET_AUTOCOMPLETE_OPTIONS: {
    return {
      ...state,
      options,
    };
  }
  case ADD_AUTOCOMPLETE_OPTIONS: {
    return {
      ...state,
      options: [...state.options, ...options],
    };
  }
  case SELECT_AUTOCOMPLETE_VALUE: {
    return {
      ...state,
      value,
    };
  }
  default: {
    return state;
  }
  }
};