import _isArray from 'lodash/isArray';
import humps from 'humps';
import _get from 'lodash/get';

/*
* Довольно частым являются случаи, когда идентификаторы БД сущностей располагаются в адресной строке браузера
* (чтобы ссылка содержала указатель на данные и можно было удобно обмениваться ссылками). Т.к. адресная строка может
* редактироваться пользователями вручную, то попасть в неё может, теоретически, всё что угодно. Поэтому логика вычисления
* валидности строки, которая должная являться идентификатора вынесена в отдельную функцию. Функция проверяет, что
* идентификатор БД сущности должен быть валидным целым числом, т.к. это идентификаторы в БД. Функция обычно используется,
* перед осуществлением запроса сущности из БД, запрос не выполняется, если итак понятно, что идентификатор невалиден.
*
* По реализации:
* Регулярное выражение проверяет, что строка содержит только числа и не начинается с нуля, чтобы нельзя было
* задать 0 (т.к. таких идентификаторов в БД не бывает) или строку вида 0123 с нулями впереди
*/
export const isIdInUrlParamsValid = idString => /^[1-9][0-9]*$/.test(idString);

/*
* Трансформирует объект гет параметров, заданных объектом queryParams в строку гет параметров, которая подставляется в
* ссылке запросе после '?'.
* При обработке:
*  - Декамелайзит ключи параметров, заданные в queryParams
*  - Для массивов значение ключа дублируется со всеми значениями, это используется для указания множественного параметра
*  - Есть обработка специального "блочного параметра" объекта, вида
* { column: 'name', params: [{ key: 'key1', value: 'value1' }, { key: 'key2', value: 'value2' }, ...]}, когда для
* определенного ключа нужны дополнительные связанные ключи и их параметры.
* Например:
* queryParams = {
*   userName: 'John',
*   hobby: ['soccer', 'swimming'],
*   sortBy: { column: 'age', params: [{ key: 'asc', value: true }, { key: 'ignore_nulls', value: false }] }
* }
*
* transformQueryParamsToQueryString(queryParams) =
* 'user_name=John&hobby=soccer&hobby=swimming&sort_by=age&asc=true&ignore_nulls=false
* */
export const transformQueryParamsToQueryString = queryParams => {
  const queryKeys = Object.keys(queryParams);
  if(!queryKeys.length) return;

  return queryKeys
    .map(key => {
      const queryParam = queryParams[key];
      return _isArray(queryParam) ?
        queryParam.map(param => encodeQueryParam(key, param)).join('&') :
        encodeQueryParam(key, queryParam);
    })
    .join('&');
};

const encodeQueryParam = (queryKey, queryParam) => {
  const encodeQueryParamsCb = isQueryParamWithParamsBlock(queryParam) ?
    encodeQueryParamWithParamsBlock :
    encodeQueryParamWithStringValue;

  return encodeQueryParamsCb(queryKey, queryParam);
};

const encodeQueryParamWithParamsBlock = (queryKey, queryParam) => {
  const { column, params } = queryParam;
  return [
    encodeQueryParamWithStringValue(queryKey, column),
    ...params.map(({ key, value }) => encodeQueryParamWithStringValue(key, value)),
  ].join('&');
};

const encodeQueryParamWithStringValue = (queryKey, queryParam) =>
  [
    encodeURIComponent(humps.decamelize(queryKey)),
    encodeURIComponent(queryParam),
  ].join('=');

export const isQueryParamWithParamsBlock = queryParamValue => !!_get(queryParamValue, 'params');

/*
* Функция парсинга значения query параметра. Используется JSON.parse.
* У JSON.parse есть встроенная логика проверки типа аргумента, поэтому помимо застрингифаенных объектов и массивов,
* для которых в большинстве случаев он и предназначен, он нормально отрабатывает с другими тимпа:
*  - Для числа JSON.parse('10') --> 10
*  - Для булевых значений JSON.parse(true) --> true и JSON.parse('true') --> true
*  - Для null JSON.parse(null) --> null и JSON.parse('null') --> null
* JSON.parse выбросит исключение для:
*  - Для строк JSON.parse('john')
*  - Для undefined JSON.parse(), JSON.parse(undefined)
* Для этих случаев в catch вернем само значение (john и undefined в примерах выше).
* Отдельно, на всякий случай, обработаем строку 'undefined' (маловероятно, но это, всё же, возможно). По умолчанию
* этот случай будет обрабатываться как любая строка, т.е. вернется, как есть - строка 'undefined'. Поэтому для этого
* случая явно вернем undefined.
* */
const parseStringifiedQueryParam = stringifiedQueryParam => {
  try {
    return JSON.parse(stringifiedQueryParam);
  } catch (e) {
    if(stringifiedQueryParam === 'undefined') return undefined;

    return stringifiedQueryParam;
  }
};


const transformEncodedAndStringifiedQueryParamValue = encodedAndStringifiedQueryParamValue => {
  const stringifiedQueryParam = decodeURIComponent(encodedAndStringifiedQueryParamValue);
  return parseStringifiedQueryParam(stringifiedQueryParam);
};

export const getQueryParamsFromQueryString = queryString => {

  if(queryString === null || queryString === undefined || queryString === '')
    return {};

  const queryParamsPairs = queryString
    .slice(1)
    .split('&');

  return queryParamsPairs
    .reduce(
      (resultQueryParams, currentQueryParamPair) => {
        const [queryParamKey, encodedAndStringifiedQueryParamValue] = currentQueryParamPair.split('=');
        const camelizedQueryParamKey = humps.camelize(queryParamKey);
        // eslint-disable-next-line no-param-reassign
        resultQueryParams[camelizedQueryParamKey] = transformEncodedAndStringifiedQueryParamValue(
          encodedAndStringifiedQueryParamValue,
        );
        return resultQueryParams;
      },
      {},
    );
};

export const URL_REGEX = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*/g;
