import { Transformer } from '../Transformer';

export class SheetToStart extends Transformer {

  static transformToState(item) {
    const {
      entityBatchId,
      entityBatchIdentity,
      entityIdentity,
      entityCode,
      entityName,
      entityBatchAmount: entitiesInBatchAmount,
      fromState,
      orderPriority,
      orderName,
      startDate,
      stopDate,
    } = item;

    return{
      entityBatchId,
      entityBatchIdentity,
      entityIdentity,
      entityCode,
      entityName,
      entitiesInBatchAmount,
      fromState,
      orderPriority,
      orderName,
      startDate,
      stopDate,
    };
  }
}