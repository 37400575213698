import React from 'react';
import PropTypes from 'prop-types';
import { TableCellRenderer } from '../TableCellRenderer';
import { NUMBER_OR_STRING_TYPE } from '../../../../constants/propTypes';
import { getUserLastNameWithInitials } from '@bfg-frontend/utils/lib/stringBuilders/entity';
import _size from 'lodash/size';
import { NotAssignedPluralLabelTrans } from '../../../../utils/commonTransComponents';


export class AssigneesRenderer extends TableCellRenderer {

  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({
      id: NUMBER_OR_STRING_TYPE.isRequired,
      name: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      patronymicName: PropTypes.string,
    })),
  };

  static getViewValue(props) {
    const {
      value,
    } = props;

    if(_size(value) === 0) {
      return null;
    }

    return value
      .map(getUserLastNameWithInitials)
      .join(', ');
  };

  render() {
    const viewValue = AssigneesRenderer.getViewValue(this.props);

    return(
      <div title={viewValue}>
        {
          viewValue === null ?
            NotAssignedPluralLabelTrans :
            viewValue
        }
      </div>
    );
  }
}
