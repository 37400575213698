import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import _constant from 'lodash/constant';

import './style.css';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import { Link, matchPath } from 'react-router-dom';


const defaultIsDisabled = _constant(false);

export class SideBarMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subMenuOpenStateByKey: {},
    };
  }

  _renderMenu = menuSchema => (
    <List>
      {
        menuSchema
          .map(this._renderMenuItem)
      }
    </List>
  );

  _renderMenuItem = item => {
    const {
      key,
      icon: IconComponent,
      title,
      linkTo,
      isDisabled = defaultIsDisabled,
      subMenu,
    } = item;

    const {
      currentPath,
    } = this.props;

    const match = matchPath(this.props.currentPath, { path: linkTo });
    const isItemLinkActive = !!match;
    const isItemLinkExact = isItemLinkActive && match.isExact;

    const listItemMarkup = (
      <ListItem
          className={cn('sidebar-menu__item', { 'sidebar-menu__item--active': isItemLinkActive })}
          button
          selected={isItemLinkActive}
          disabled={isDisabled(currentPath)}
          onClick={this._itemClickHandlerFactory(item)}
      >
        <ListItemIcon className="sidebar-menu__item-icon">
          {IconComponent ? <IconComponent/> : <span/>}
        </ListItemIcon>
        <span className="sidebar-menu__item-title">{title}</span>
        {this._renderSubMenuCaretIcon(subMenu, key)}
      </ListItem>
    );

    return (
      <div key={key}>
        {
          !!subMenu || (isItemLinkActive && isItemLinkExact) ?
            listItemMarkup :
            <Link to={linkTo}>{listItemMarkup}</Link>
        }
        {this._renderSubmenu(subMenu, key)}
      </div>
    );
  };

  _itemClickHandlerFactory = item => {
    const {
      key,
      subMenu,
    } = item;

    if(!subMenu) return undefined;

    return () => this.setState({
      subMenuOpenStateByKey: {
        ...this.state.subMenuOpenStateByKey,
        [key]: !this.state.subMenuOpenStateByKey[key],
      },
    });
  }

  _renderSubMenuCaretIcon = (subMenu, key) => {
    if(!subMenu) return null;

    return (
      <span className="sidebar-menu__item-caret-icon">
        {this.state.subMenuOpenStateByKey[key] ? <ExpandLess/> : <ExpandMore/>}
      </span>
    );
  };

  _renderSubmenu = (subMenu, key) => {
    if(!subMenu) return null;

    const {
      subMenuOpenStateByKey: {
        [key]: isSubMenuOpen,
      },
    } = this.state;

    return (
      <Collapse
          in={isSubMenuOpen}
          unmountOnExit
      >
        <div className="sidebar-menu__submenu-wrapper">
          {this._renderMenu(subMenu)}
        </div>
      </Collapse>
    );
  }

  render() {
    const {
      menuSchema,
      className,
      anchor,
    } = this.props;

    return (
      <Drawer
          className={cn('sidebar-menu', className)}
          variant="permanent"
          anchor={anchor}
          classes={{
            paper: 'sidebar-menu__paper',
          }}
      >
        {this._renderMenu(menuSchema)}
      </Drawer>
    );
  }
}

SideBarMenu.defaultProps = {
  anchor: 'left',
};

SideBarMenu.propTypes = {
  currentPath: PropTypes.string.isRequired,
  menuSchema: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.elementType,
      title: PropTypes.node.isRequired,
      linkTo: PropTypes.string.isRequired,
      isDisabled: PropTypes.func,
    }),
  ).isRequired,
  className: PropTypes.string,
  anchor: PropTypes.oneOf(['left', 'right']),
};