import React from 'react';
import PropTypes from 'prop-types';
import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';
import { Table } from '../../Table/Table';
import { FUNC_IS_REQUIRED_TYPE, MASTER_TASKS_TABLE_ROW_DATA_TYPE } from '../../../constants/propTypes';
import { MASTER_TASKS_MODEL } from '../../../reducers/schemaModel/models/masterTasksSchema';

import './style.css';


const MASTER_TASKS_TABLE_MENU_PROPS = {
  disableSummaryOption: true,
};

const MASTER_TASKS_TABLE_CUSTOM_CELL_RENDERERS_PROPS = {
  startDate: {
    dateFormat: FORMAT_SHORT_TIME,
  },
  stopDate: {
    dateFormat: FORMAT_SHORT_TIME,
  },
};

export const MasterTasksTable = props => {
  const {
    tableId,
    fetchRemoteTableData,
    tableData,
    noDataContent,
    onRowClick,
    getRowStyle,
  } = props;
  return (
    <div className="master-tasks-table">
      <Table
          tableId={tableId}
          tableModel={MASTER_TASKS_MODEL}
          getRowStyle={getRowStyle}
          fetchRemoteTableData={fetchRemoteTableData}
          rowsData={tableData}
          noDataContent={noDataContent}
          onRowClick={onRowClick}
          customCellRenderersProps={MASTER_TASKS_TABLE_CUSTOM_CELL_RENDERERS_PROPS}
          tableMenu={MASTER_TASKS_TABLE_MENU_PROPS}
          disableSort
      />
    </div>
  );
};

MasterTasksTable.propTypes = {
  tableId: PropTypes.string.isRequired,
  fetchRemoteTableData: FUNC_IS_REQUIRED_TYPE,
  tableData: PropTypes.arrayOf(MASTER_TASKS_TABLE_ROW_DATA_TYPE),
  noDataContent: PropTypes.node.isRequired,
  onRowClick: PropTypes.func,
  getRowStyle: PropTypes.func,
};