import { compose } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { push } from 'connected-react-router';

import { getSheetDataFromEntitiesById, sheetsRemoteTableDataSelector } from '../../selectors/sheets';
import { reactRouterParamsSelector } from '../../selectors/reactRouter';

import _isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { FILTER_GROUP_TYPES, FILTER_TYPES, WITH_PARAMS } from '../../api/restCollectionApi/index';

import { Sheets } from './Sheets';
import {
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  ORDER_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_MODEL,
} from '../../constants/models';
import { getClientMessagesDataArray } from '../../api/socketApi/socketMessageHandlers/getClientMessagesDataArray';


export const SHEETS_REMOTE_TABLE_DATA_WITH_PARAMS = [
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  {
    column: ORDER_MODEL,
    params: [{ key: WITH_PARAMS.STRICT, value: false }],
  },
];

export const SHEETS_REMOTE_TABLE_DATA_SORT_PARAMS = [
  {
    column: 'startDate',
    params: [{ key: 'asc', value: true }],
  },

  //Дополнительная сортировка по id для стабильности
  {
    column: 'id',
    params: [{ key: 'asc', value: true }],
  },
];


const mapStateToProps = (state, ownProps) => {
  const {
    sheetsIdentity,
    fetchSheetTypeEntitiesActionCreator,
    sheetsData,
  } = ownProps;

  const { sheetId: sheetToReviewIdFromRoute } = reactRouterParamsSelector(null, ownProps);

  return{
    sheetsData: _isFunction(fetchSheetTypeEntitiesActionCreator) ?
      sheetsRemoteTableDataSelector(state, { sheetsIdentity }) :
      sheetsData,
    sheetToReviewIdFromRoute,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {

  const {
    mainRoutePath,
    fetchSheetTypeEntitiesActionCreator,
  } = ownProps;

  const pushNewPathName = newPathName => dispatch(push(newPathName));

  return {
    startSheetReview: sheetId =>
      pushNewPathName([mainRoutePath, sheetId].join('/')),

    stopSheetReview: () => pushNewPathName(mainRoutePath),

    fetchSheetsData: _isFunction(fetchSheetTypeEntitiesActionCreator) ?
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchSheetTypeEntitiesActionCreator) :
      undefined,

    fetchSheetToReview: _isFunction(fetchSheetTypeEntitiesActionCreator) ?
      fetchSheetTypeToReviewAndPrepareReviewDataCbFactory(dispatch, fetchSheetTypeEntitiesActionCreator) :
      undefined,

    getClientMessagesDataArray: message => dispatch(getClientMessagesDataArray(message)),
  };
};

export const fetchSheetTypeRemoteTableDataCbFactory = (dispatch, fetchSheetTypeEntitiesActionCreator) =>
  ({ tableParams }) => {

    const {
      activePage,
      pageSize,
      filterParams,
    } = tableParams;

    const query = {
      filter: _isEmpty(filterParams) ?
        undefined :
        ({
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: Object
            .keys(filterParams)
            .map(column => {
              const {
                filterValue,
                filterType,
              } = filterParams[column];

              return {
                column,
                filterType,
                filterValue,
              };
            }),
        }),
      with: SHEETS_REMOTE_TABLE_DATA_WITH_PARAMS,
      sortBy: SHEETS_REMOTE_TABLE_DATA_SORT_PARAMS,
      limit: pageSize,
      page: activePage,
    };

    return dispatch(fetchSheetTypeEntitiesActionCreator(query))
      .then(({ responseEntitiesIds = {}, entities = {}, responseMeta }) => ({
        itemsIds: responseEntitiesIds,
        itemsById: entities,
        totalItemsAmount: _get(responseMeta, 'count', 0),
      }));
  };

export const fetchSheetTypeToReviewCbFactory = (dispatch, fetchSheetTypeEntitiesActionCreator) =>
  sheetId => {

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'id',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: sheetId,
          },
        ],
      },
      with: SHEETS_REMOTE_TABLE_DATA_WITH_PARAMS,
    };

    return dispatch(fetchSheetTypeEntitiesActionCreator(query));
  };

/*
* создаем отдельную фабрику для реализации логики запроса и преобразования данных, а не модифицируем
* fetchSheetTypeToReviewCbFactory, т.к. она экспортируемая и активно используется в обработчиках событий.
* Здесь же мы просто добавляем частную логику преобразования полученных данных.
* */
const fetchSheetTypeToReviewAndPrepareReviewDataCbFactory = (dispatch, fetchSheetTypeEntitiesActionCreator) => {
  const fetchSheet = fetchSheetTypeToReviewCbFactory(dispatch, fetchSheetTypeEntitiesActionCreator);

  return sheetId => fetchSheet(sheetId)
    .then(response => {
      if(!response || response.responseMeta.count === 0) return null;

      const {
        entities: {
          [SHEET_MODEL]: sheetEntities = {},
          [ENTITY_BATCH_MODEL]: entityBatchEntities = {},
          [SHEET_OPERATION_MODEL]: sheetOperationEntities = {},
          [ENTITY_MODEL]: entityModelEntities = {},
          [ORDER_MODEL]: orderEntities = {},
        },
      } = response;

      return getSheetDataFromEntitiesById(
        Number(sheetId),
        sheetEntities,
        entityBatchEntities,
        sheetOperationEntities,
        entityModelEntities,
        orderEntities,
      );
    });
};

//mergeProps нужен для того, чтобы в компонент не передавались все ownProps, как это делается при дефолтном mergeProps
const mergeProps = (stateProps, dispatchProps, ownProps) => {

  const {
    sheetsData,
    sheetToReviewIdFromRoute,
  } = stateProps;

  const {
    startSheetReview,
    stopSheetReview,
    fetchSheetsData,
    fetchSheetToReview,
    getClientMessagesDataArray,
  } = dispatchProps;

  /*
  * Чтобы не было путаницы с пропсами, явно распаковываем и передаем дальше в компонент используемые им пропсы из
  * ownProps контейнера. Сами ownProps не распаковываем.
  * */
  const {
    className,
    mainRoutePath,
    sheetsIdentity,
    sheetItemIdProperty,
    noDataText,
    getSheetItemAdditionalInfoLabels,
    sheetReviewContentComponent,
    filtersSchema,
  } = ownProps;

  return {
    sheetsData,
    sheetToReviewIdFromRoute,
    startSheetReview,
    stopSheetReview,
    fetchSheetsData,
    fetchSheetToReview,

    className,
    mainRoutePath,
    sheetsIdentity,
    sheetItemIdProperty,
    noDataText,
    getSheetItemAdditionalInfoLabels,
    sheetReviewContentComponent,
    filtersSchema,
    getClientMessagesDataArray,
  };
};

export const SheetsContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(Sheets);

SheetsContainer.displayName = 'SheetsContainer';