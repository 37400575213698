import React from 'react';
import PropTypes from 'prop-types';
import _isFunction from 'lodash/isFunction';
import _isNil from 'lodash/isNil';
import _size from 'lodash/size';
import _isArray from 'lodash/isArray';
import { MATERIAL_UI_STYLE_COLOR } from '../../../../../../constants/materialUI';
import { SimpleLabel } from '../../../../SimpleLabel/SimpleLabel';
import { OBJECT_OF_ANY_TYPE } from '../../../../../../constants/propTypes';

import './style.css';
import { TooltipWithForwardedRef } from '../../../../TooltipWrapper/TooltipWithForwardedRef';
import { debugMessage } from '../../../../../../utils/debugMessage';


export const EntitiesReviewTableItemDefaultContent = props => {
  const {
    entityData,
    renderItemLeftSideContent,
    renderItemRightSideContent,
    getItemAdditionalInfoLabels,
  } = props;

  return (
    <React.Fragment>
      {_renderItemLeftSideContent(entityData, renderItemLeftSideContent)}
      {_renderItemRightSideContent(entityData, renderItemRightSideContent)}
      {_renderItemAdditionalInfoLabels(entityData, getItemAdditionalInfoLabels)}
    </React.Fragment>
  );
};

const _renderItemLeftSideContent = (entityData, renderItemLeftSideContent) => (
  <div className="entities-review-table__item-left-side-content">
    {
      _isFunction(renderItemLeftSideContent) ?
        renderItemLeftSideContent(entityData) :
        null
    }
  </div>
);

const _renderItemRightSideContent = (entityData, renderItemRightSideContent) => (
  <div className="entities-review-table__item-right-side-content">
    {
      _isFunction(renderItemRightSideContent) ?
        renderItemRightSideContent(entityData) :
        null
    }
  </div>
);

const _renderItemAdditionalInfoLabels = (entityData, getItemAdditionalInfoLabels) => {
  if(!_isFunction(getItemAdditionalInfoLabels)) return null;

  const additionalInfoLabelsProps = getItemAdditionalInfoLabels(entityData);

  if (!_isArray(additionalInfoLabelsProps)) {
    debugMessage('Props getItemAdditionalInfoLabels должен быть функцией, которая возвращает массив');
    return null;
  }

  if(!_size(additionalInfoLabelsProps)) {
    return null;
  }

  return (
    <div className="entities-review-table__item-additional-info-label-list">
      {
        additionalInfoLabelsProps
          .filter(labelProps => !_isNil(labelProps))
          .map(({
              labelContent,
              labelTooltipContent,
              labelStyle = MATERIAL_UI_STYLE_COLOR.DEFAULT,
              labelKey,
            }) => {

            if(!labelTooltipContent) {
              return(
              <SimpleLabel
                  key={labelKey}
                  className="entities-review-table__item-additional-info-label"
                  content={labelContent}
                  color={labelStyle}
              />
              );
            }

            return (
            <TooltipWithForwardedRef
                key={labelKey}
                className="entities-review-table__item-additional-info-label"
                title={
                  <div className="entities-review-table__item-additional-info-label-tooltip-content">
                    {labelTooltipContent}
                  </div>
                }
                placement="top"
            >
              <SimpleLabel
                  content={labelContent}
                  color={labelStyle}
              />
            </TooltipWithForwardedRef>
            );
          })
      }
    </div>
  );
};

EntitiesReviewTableItemDefaultContent.propTypes = {
  entityData: OBJECT_OF_ANY_TYPE,
  renderItemLeftSideContent: PropTypes.func,
  renderItemRightSideContent: PropTypes.func,
  getItemAdditionalInfoLabels: PropTypes.func,
};
