export const SET_ASSEMBLY_SHEET_CONSUME_DATA = 'SET_ASSEMBLY_SHEET_CONSUME_DATA';
export const DELETE_ASSEMBLY_SHEET_CONSUME_DATA = 'DELETE_ASSEMBLY_SHEET_CONSUME_DATA';

export const setAssemblySheetConsumeData = ({ data, sheetId }) => ({
  type: SET_ASSEMBLY_SHEET_CONSUME_DATA,
  data,
  sheetId,
});

export const deleteAssemblySheetConsumeData = ({ sheetId }) => ({
  type: DELETE_ASSEMBLY_SHEET_CONSUME_DATA,
  sheetId,
});