import { COLUMN_TYPE } from '../constants/table';
import { dateComparator, numberComparator, stringComparator } from '@bfg-frontend/utils/lib/array';


// справочник компараторов для различных типов данных, используется пока только в DataGridTable
export const COLUMN_TYPE_COMPARATORS = {
  [COLUMN_TYPE.STRING]: stringComparator,
  [COLUMN_TYPE.NUMBER]: numberComparator,
  [COLUMN_TYPE.INTEGER]: numberComparator,
  [COLUMN_TYPE.DATE]: dateComparator,
};
