import { createSelector } from 'reselect';


export const autocompleteStateSelector = state => state.autocomplete;

export const autocompleteByIdSelector = (state, { id })  => autocompleteStateSelector(state)[id];

export const autocompleteSelectedValueSelector = createSelector(
  autocompleteByIdSelector,
  (autocompleteData = {}) => autocompleteData.value || null,
);

export const autocompleteOptionsSelector = createSelector(
  autocompleteByIdSelector,
  (autocompleteData = {}) => autocompleteData.options || [],
);