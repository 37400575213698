import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withPermissionsManager } from '../../../../../hoc/withPermissionsManager/withPermissionsManager';
import { ConsumeEntities } from './ConsumeEntitites';
import {
  consumePartsAndMaterialsForAssemblySheet,
  fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch, sendPartsAndMaterialsForAssemblySheetConsumedNotification,
} from '../../../../../operations/sheets';
import {
  assemblySheetConsumeDataSelector,
} from '../../../../../reducers/workerApp/assemblySheets/consumeData/selectors';

export const ConsumeEntitiesContainer = withPermissionsManager(props => {
  const {
    sheetOperationData,
    PermissionsManager,
    onEditableValuesChange,
  } = props;

  const {
    entityBatchId,
    sheetId,
    sheetIdentity,
  } = sheetOperationData;

  const dispatch = useDispatch();

  const consumeData = useSelector(state => assemblySheetConsumeDataSelector(state, { sheetId }));

  useEffect(() => {
    // делаем явную проверку на undefined, в случае с пустыми данными запрос вернёт {}
    if (consumeData !== undefined) return;
    dispatch(fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch(entityBatchId, sheetId));
  }, [consumeData, dispatch, entityBatchId, sheetId]);

  if (consumeData === undefined) return null;

  const _consumeEntities = consumeData => {
    dispatch(consumePartsAndMaterialsForAssemblySheet(
    sheetId,
    sheetIdentity,
    entityBatchId,
    consumeData,
  ))
    .then(_handlePartsAndMaterialsForAssemblySheetConsumed);
  };

  const _handlePartsAndMaterialsForAssemblySheetConsumed = () => {
  // выводим нотификейшн об успешном потреблении ДСЕ
    sendPartsAndMaterialsForAssemblySheetConsumedNotification(sheetIdentity);
  // перезапрашиваем данные таблицы потребления
    dispatch(fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch(entityBatchId, sheetId));
  };

  return (
    <ConsumeEntities
        onEditableValuesChange={onEditableValuesChange}
        initialConsumeData={consumeData}
        onEntitiesConsume={_consumeEntities}
        PermissionsManager={PermissionsManager}
    />
  );
});