import { CLIENT_SOCKET_MESSAGE_TEMPORARY_DATA_POINT } from '../../../constants/sockets';
import {
  fetchData,
} from '../../httpRequestsApi/actions';
import {
  makePOSTHttpRequest,
} from '../../httpRequestsApi/index';
import { HTTP_REQUEST_STATUS } from '../../httpRequestsApi/constants';


const TEMPORARY_REQUEST_ERROR = 'TEMPORARY_REQUEST_FAILED';
const BASE_TEMPORARY_DATA_URL = [
  window.config.IA_BACKEND_SERVER_PROXY_HOST,
  CLIENT_SOCKET_MESSAGE_TEMPORARY_DATA_POINT,
].join('/');

const handleTemporaryRequestError = error => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console, no-mixed-operators
    console.error(error && error.stack || error);
  }
  return Promise.reject(error);
};

export const sendTemporaryData = (data, key) =>
  makePOSTHttpRequest(
    BASE_TEMPORARY_DATA_URL,
    {
      data,
      key,
    },
  ).then(({ status, response }) =>
    status === HTTP_REQUEST_STATUS.SUCCESS ?
      Promise.resolve(response) :
      Promise.reject(TEMPORARY_REQUEST_ERROR))
   .catch(handleTemporaryRequestError);

export const fetchTemporaryData = key =>
  dispatch => dispatch(fetchData(
    [BASE_TEMPORARY_DATA_URL, key].join('/'),
    {},
    { isBlockingRequest: false },
  )).then(({ data }) => data);
