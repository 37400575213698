import { connect } from 'react-redux';
import { appUserSelector } from '../../reducers/appState/selectors';
import { logout } from '../../operations/users/index';
import { AppTypeTopBarUserMenu } from './AppTypeTopBarUserMenu';


const mapStateToProps = state => ({
  userData: appUserSelector(state),
});

const mapDispatchToProps = {
  logout,
};

export const AppTypeTopBarUserMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)
(AppTypeTopBarUserMenu);