import PropTypes from 'prop-types';
import { Component } from 'react';

/*
* "Абстрактный" компонент рендереров ячеек таблиц - TableCellRenderer, описывает "интерфейс" такого рода компонентов.
* Все рендереры ячеек должны наследоваться от этого компонента, на этом построена логика обработки в таблицах. Если
* указан рендерер, но он не наследуется от этого компонента, то при отрисовке таблицы будет выкинута ошибка о том, что
* задан рендрер неизвестной структуры.
* Пока "интерфейс" предполагает наличие одной статической функции getViewValue, которая принимает props компонента
* отрисовщика ячейки таблицы и должна возвращать view значение (по умолчанию, это props.value). Функция статическая,
* потому, что это используется при различных обработках (например, при фильтрации, чтобы фильтровать по view, т.е. по
* тому, что пользователь видит на экране), и там не создаются экземпляры классов компонентов (да и в реакте, мы вообще сами не
* создаем экземпляры классов компонентов, всё делает реакт) + эта логика получения view значения верна для всех
* экземпляров класса, поэтому вполне логично сделать функцию статической.
* Каждый наследующийся от этого рендерер переопределяет статическую функцию (ну или не переопределяет, а использует
* функцию этого родительского класса)
*
* Если рендерер отрисовывает какую-то прям кастомную графику (например, pie диаграмму с несколькими значениями) и
* нормально значение для view не придумать, то можно просто вернуть из функции null, например, а о дальнейшей логике
* по запрету фильтрации по этой колонке таблицу, или какой-то кастомной фильтрации придется позаботится дополнительно.
*
* */
export class TableCellRenderer extends Component {

  static getViewValue(props) { return props.value; };

  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }

  render() {
    return this.props.value;
  }
}