import { compose } from 'redux';
import { connect } from 'react-redux';

import { push } from 'connected-react-router';

import { AppChoosingScreen } from './AppChoosingScreen';
import { withPermissionsManager } from '../../hoc/withPermissionsManager/withPermissionsManager';


const mapDispatchToProps = dispatch => ({
  onAppSelect: appMainRouteLink => dispatch(push(appMainRouteLink)),
});

export const AppChoosingScreenContainer = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withPermissionsManager,
)(AppChoosingScreen);

AppChoosingScreenContainer.displayName = 'AppChoosingScreenContainer';