import {
  deleteEntitiesFromStore,
  saveEntitiesOnServerAndAddToStore,
  fetchEntitiesFromServerAndAddToStore,
  deleteEntitiesFromServer,
} from '../reducers/entities/actions';
import { entitySelector } from '../reducers/entities/selectors';

import { SETTINGS_MODEL } from '../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../api/restCollectionApi';

import _size from 'lodash/size';
import _isNil from 'lodash/isNil';
import { workerTasksTableSettingsSelectOptionsSelector } from '../selectors/workerTasksTableSettings';
import { getErrorMessage, showError } from '../api/requestHandlers/errorHandlers/errorHandlers';
import { SETTINGS_ERRORS } from '../api/requestHandlers/errorHandlers/errorMaps';
import { getSettingsEntityUniqId } from '../constants/settings';
import { showWorkerTasksTableSettings } from '../reducers/workerTasksTableSettings/actions';


/*
* Экшен криеторы для работы с моделью БД settings. Особенность модели в том, что данные в ней хранятся в поле
* JSON и, фактически, здесь может храниться любая нужная информация, без создания дополнительных моделей.
* */

/**
 *
 * @param settingsGroupsAndNames {array} - [
 *  {
 *    group: 'SETTINGS_GROUP',
 *    name: 'SETTINGS_NAME'
 *  },
 *  ...
 * ]
 */
export const fetchSettings = (settingsGroupsAndNames = []) =>
  dispatch => {

    const settingsFilters = settingsGroupsAndNames
      .filter(({ group, name }) => group || name)
      .map(({ group, name }) => {

        const filters = [];

        if (!_isNil(group)) {
          filters.push({
            column: 'group',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: group,
          });
        }

        if (!_isNil(name)) {
          filters.push({
            column: 'name',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: name,
          });
        }

        return {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: filters,
        };
      });

    const query = _size(settingsFilters) ? {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.OR,
        filters: settingsFilters,
      },
    } : undefined;


    return dispatch(fetchEntitiesFromServerAndAddToStore(SETTINGS_MODEL, query));
  };


const DEFAULT_SAVE_SETTINGS_OPTIONS = {
  showServerError: false,
  isBlockingRequest: false,
};

export const saveSettingsEntity = ({ group, name, value }, options = {}) =>
  (dispatch, getState) => {
    const id = getSettingsEntityUniqId(group, name);
    const isSettingNew = !entitySelector(getState(), { model: SETTINGS_MODEL, id });

    return dispatch(saveEntitiesOnServerAndAddToStore(
      SETTINGS_MODEL,
      {
        group,
        name,
        value,
      },
      isSettingNew,
      {
        ...DEFAULT_SAVE_SETTINGS_OPTIONS,
        options,
      },
    ))
    /*
      * saveEntitiesInIaAndAddToStore возвращает нормализованные данные. Сохраняем всегда одни настройки. поэтому в
      * единственно значение - это сущность сохраненных настроек
      * */
      .then(({ normalizedSavedEntities }) => Object.values(normalizedSavedEntities)[0])
      .catch(({ response, status }) => {
        if(!response) return Promise.reject(status);

        const errorMsg = getErrorMessage(response, SETTINGS_ERRORS);
        showError(errorMsg);
        return Promise.reject({ response, status });
      });
  };


/*
* Экшн для удаления выбранных в селекте настроек на экране администратора, для него есть следующие особенности:
*  - нужно удалить настройки с сервера использовав реальный id в БД с предопределенным "префиксом" группы настроек
*  приложения (подробнее про группу настроек в комментарии в начале этого файла).
*  - после удаление настроек с сервера нужно удалить настройки из store, чтобы опции селекта обновились. Настройки
*  в store уже хранятся с простым идентификатором настроек без префикса, про который описывалось в предыдущем пункте
*  - также, после удаления нужно заселектить предыдущую по порядку опцию настроек в списке опций
*  - любые удаляемые настройки всегда есть в списке опций селекта, т.е. по id настроек всегда можно найти индекс опции
*  удаляемых настроек, дополнительных проверок на это не нужно.
*  - настройки с id WORKER_TASKS_TABLE_SETTINGS_ID запрещается удалять из приложения, это общие настройки и они должны
*  быть всегда всистеме (в интерфейсе вообще нет возможности их удалить)
*  - учитывая прошлый пункт, очевидно, что всегда можно найти предыдущая опцию настроек в списке (которую нужно заселектить),
*  относительно удаляемой, т.е. на индекс предыдущей по порядку опции тоже не нужно делать дополнительных проверок
*  *
* */

export const deleteSettingsEntity = ({ group, name }) =>
  (dispatch, getState) => dispatch(deleteEntitiesFromServer(
      SETTINGS_MODEL,
    {
      group,
      name,
    },
    ))
      .then(() => {
        const settingsId = getSettingsEntityUniqId(group, name);
        const settingsSelectOptions = workerTasksTableSettingsSelectOptionsSelector(getState());

        const deletedOptionIndex = settingsSelectOptions
        //eslint-disable-next-line
          .findIndex(({ id }) => settingsId == id);

        const newSelectedSettingsId = settingsSelectOptions[deletedOptionIndex - 1].id;

        dispatch([
          deleteEntitiesFromStore(SETTINGS_MODEL, [settingsId]),
          showWorkerTasksTableSettings(newSelectedSettingsId),
        ]);
      });