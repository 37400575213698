import { createSelector } from 'reselect';
import { tableCurrentRemoteDataSelector } from '../reducers/table/selectors';
import {
  ENTITY_MODEL,
  ORDER_ENTRY_MODEL,
  ORDER_MODEL,
} from '../constants/models';
import _size from 'lodash/size';
import { getEntityDataByTemplate } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const ordersRemoteTableDataSelector = createSelector(
  (state, { ordersIdentity }) =>
    tableCurrentRemoteDataSelector(state, { tableId: ordersIdentity }),
  currentRemoteData => {

    if(currentRemoteData === null) return [];

    const{
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const orderEntitiesIds = currentRemoteItemsIds[ORDER_MODEL];

    if(_size(orderEntitiesIds) === 0) return [];

    const {
      [ORDER_MODEL]: orderEntities = {},
    } = currentRemoteItemsById;

    return orderEntitiesIds.map(orderId => orderEntities[orderId]);
  },
);

/*
* выделяем отдельный шаблон для модели entity с неймингом колонок для серверной фильтрации и сортировки
* */
const ENTITY_MODEL_DATA_FROM_CA_ENTITY_TEMPLATE = {
  entity__identity: 'identity',
  entity__code: 'code',
  entity__name: 'name',
};

export const orderEntriesTableDataSelector = createSelector(
  tableCurrentRemoteDataSelector,
  currentRemoteData => {

    if(currentRemoteData === null) return [];

    const{
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const orderEntryEntitiesIds = currentRemoteItemsIds[ORDER_ENTRY_MODEL];

    if(_size(orderEntryEntitiesIds) === 0) return [];

    const {
      [ORDER_ENTRY_MODEL]: orderEntryModelEntities = {},
      [ENTITY_MODEL]: entityModelEntities = {},
    } = currentRemoteItemsById;

    return orderEntryEntitiesIds
      .map(orderEntryId => {
        const orderEntryEntity = orderEntryModelEntities[orderEntryId];

        return {
          ...orderEntryEntity,
          ...getEntityDataByTemplate(
            entityModelEntities[orderEntryEntity.entityId],
            ENTITY_MODEL_DATA_FROM_CA_ENTITY_TEMPLATE,
          ),
        };
      });
  },
);
