import PropTypes from 'prop-types';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';


const {
  string,
  shape,
} = PropTypes;

export const SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ = {
  //Компонент абстрактный иногда сюда попадают операции с составным идентификатором и в этом случае он строка,
  //хотя обычно это число, т.к. это идентификатор БД
  sheetOperationId: NUMBER_OR_STRING_TYPE.isRequired,
  operationIdentity: string.isRequired,
  operationNumber: string.isRequired,
  operationName: string.isRequired,
  departmentIdentity: string.isRequired,
  departmentName: string.isRequired,
  equipmentClassIdentity: string.isRequired,
  equipmentClassName: string.isRequired,
  operationStartDate: string.isRequired,
  operationStopDate: string.isRequired,
};

export const SHEET_OPERATION_TABLE_ROW_DATA_TYPE = shape(SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ);