import { createSelector } from 'reselect';

import { plannerAppStateSelector } from '../selectors';

import {
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL, ORDER_MODEL,
  SIMULATION_ENTITY_BATCH_MODEL,
  SIMULATION_OPERATION_TASK_MODEL,
  SIMULATION_ORDER_ENTITY_BATCH_MODEL,
} from '../../../constants/models';

import {
  DEPARTMENT_DATA_FROM_IA_ENTITY_TEMPLATE,
  EQUIPMENT_CLASS_DATA_FROM_IA_ENTITY_TEMPLATE,
  OPERATION_DATA_FROM_IA_ENTITY_TEMPLATE,
  SIMULATION_OPERATION_TASK_DATA_FROM_IA_ENTITY_TEMPLATE,
  ORDER_DATA_FROM_IA_ENTITY_TEMPLATE,
} from '../../../utils/entities';
import {
  complexComparatorFactory,
  dateComparator,
  fieldComparatorFactory,
  numberComparator,
  stringComparator,
} from '@bfg-frontend/utils/lib/array';

import _groupBy from 'lodash/groupBy';
import _minBy from 'lodash/minBy';
import _maxBy from 'lodash/maxBy';
import _pick from 'lodash/pick';
import _isNil from 'lodash/isNil';

import _mapValues from 'lodash/mapValues';
import _get from 'lodash/get';
import { createSheetsToStartFiltersSchema } from '../../../components/Sheets/sheetsFilters/sheetsFilters';
import { SHEET_TYPE } from '../../../constants/sheets';
import { prepareOperationProdTimeAndLaborValues } from '../../../utils/tasks';
import { divDecimals } from '../../../utils/decimal';
import { SECS_IN_HOUR } from '../../../constants/magics';
import { getEntityDataByTemplate } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const sheetsToStartStateSelector = state =>
  plannerAppStateSelector(state).sheetsToStart;

export const sheetsToStartSelectedTypeSelector = state =>
  sheetsToStartStateSelector(state).selectedType;

export const sheetsToStartStateSheetsDataSelector = state =>
  sheetsToStartStateSelector(state).sheetsData;

export const sheetsToStartRequestParamsSelector = createSelector(
  sheetsToStartStateSheetsDataSelector,
  ({ mainPlanningSessionId, startDate, stopDate }) => ({
    mainPlanningSessionId,
    startDate,
    stopDate,
  }),
);

const _prepareSheetsToStartData = sheetToStartDataEntities => {
  if(_isNil(sheetToStartDataEntities)) {
    return [];
  }

  return Object
    .values(sheetToStartDataEntities)
    .sort(
      complexComparatorFactory([
        fieldComparatorFactory(dateComparator, 'startDate'),
        fieldComparatorFactory(numberComparator, 'entityBatchId'),
      ]),
    );
};


export const sheetsToStartDataSelector = createSelector(
  sheetsToStartStateSheetsDataSelector,
  ({ dataEntities }) => _prepareSheetsToStartData(dataEntities),
);

const _prepareSheetsToStartFiltersSchemaBySheetsToStartData = (sheetsToStartData, sheetType) => {
  const {
    entityIdentity: entityFilterOptionsMap = {},
    orderName: orderFilterOptionsMap = {},
    entityBatchIdentity: entityBatchFilterOptionsMap = {},
  } = sheetsToStartData
    .reduce(
      (filterKeysOptionsAcc, sheetToStartData) =>
        _mapValues(
          filterKeysOptionsAcc,
          (filterKeyOptionsAcc, filterKey) => {

            const uniqFilterKeyOptionValue = sheetToStartData[filterKey];
            if (!uniqFilterKeyOptionValue || filterKeyOptionsAcc[uniqFilterKeyOptionValue]) return filterKeyOptionsAcc;

            filterKeyOptionsAcc[uniqFilterKeyOptionValue] = _pick(
              sheetToStartData,
              SHEETS_TO_START_FILTER_OPTIONS_FIELDS_FROM_SHEET_DATA[filterKey],
            );

            return filterKeyOptionsAcc;
          },
        ),
      _mapValues(
        SHEETS_TO_START_FILTER_OPTIONS_FIELDS_FROM_SHEET_DATA,
        () => ({}),
      ),
    );

  return createSheetsToStartFiltersSchema(
    Object.values(entityFilterOptionsMap),
    Object.values(orderFilterOptionsMap),
    Object.values(entityBatchFilterOptionsMap),
    sheetType,
  );
};

const SHEETS_TO_START_FILTER_OPTIONS_FIELDS_FROM_SHEET_DATA = {
  entityIdentity: ['entityIdentity', 'entityCode', 'entityName'],
  orderName: ['orderPriority', 'orderName'],
  entityBatchIdentity: ['entityBatchId', 'entityBatchIdentity', 'fromState', 'startDate', 'stopDate'],
};

export const sheetsToStartFiltersSchemaSelector = createSelector(
  sheetsToStartDataSelector,
  (sheetsToStartData = []) => _prepareSheetsToStartFiltersSchemaBySheetsToStartData(sheetsToStartData, SHEET_TYPE.ALL_TO_START),
);

export const isSheetsToStartDataAlreadyInStoreSelector = createSelector(
  sheetsToStartStateSheetsDataSelector,
  (_, { mainPlanningSessionId, startDate, stopDate }) => ({
    mainPlanningSessionId,
    startDate,
    stopDate,
  }),
  (
    {
      mainPlanningSessionId: mainPlanningSessionIdInStore,
      startDate: startDateInStore,
      stopDate: stopDateInStore,
      dataEntities,
    },
    {
      mainPlanningSessionId,
      startDate,
      stopDate,
    },
  ) => {
    /*
    * Если сессия новая, или границы окна получения партий на запуск не установлены в store (это означает, что запросы
    * ещё вообще не выполнялись), или для установленных границ данные ещё не запрашивались, или же для этих границ был
    * сброшен кэш (в последних 2 случаях dataEntities === null), то это сразу показатель, что данныъ в стор нет \
    * данные неактуальны
    * */
    if(
      mainPlanningSessionIdInStore !== mainPlanningSessionId ||
      startDateInStore === null ||
      stopDateInStore === null ||
      dataEntities === null
    ) {
      return false;
    }

    /*
    * В случае если прошлые условия выполнены, то это означает, что для установленных границ окна получения партия
    * в стор записаны актуальные данные. В этом случае нужно проверить, не заданы ли текущие границы окна получения
    * партий, потому что если заданы те же самые, то в стор данные для них уже есть
    * */
    return (
      (new Date(startDateInStore).getTime() === new Date(startDate).getTime()) &&
      (new Date(stopDateInStore).getTime() === new Date(stopDate).getTime())
    );
  },
);

export const sheetsToStartStateAssemblySheetsDataSelector = state =>
  sheetsToStartStateSelector(state).assemblySheetsData;

export const assemblySheetsToStartDataSelector = createSelector(
  sheetsToStartStateAssemblySheetsDataSelector,
  ({ dataEntities }) => _prepareSheetsToStartData(dataEntities),
);

export const assemblySheetsToStartFiltersSchemaSelector = createSelector(
  assemblySheetsToStartDataSelector,
  (sheetsToStartData = []) => _prepareSheetsToStartFiltersSchemaBySheetsToStartData(sheetsToStartData, SHEET_TYPE.ASSEMBLY_TO_START),
);

export const isAssemblySheetsToStartDataAlreadyInStoreSelector = createSelector(
  sheetsToStartStateAssemblySheetsDataSelector,
  (_, { mainPlanningSessionId }) => mainPlanningSessionId,
  ({ mainPlanningSessionId: mainPlanningSessionIdInStore, dataEntities }, mainPlanningSessionId) =>
    mainPlanningSessionIdInStore === mainPlanningSessionId &&
    dataEntities !== null,
);


export const sheetsToStartStateSheetOperationsDataSelector = state =>
  sheetsToStartStateSelector(state).sheetOperationsData;

export const sheetToStartOperationsRequestParamsSelector = createSelector(
  sheetsToStartStateSheetOperationsDataSelector,
  ({ mainPlanningSessionId, entityBatchFromIaId }) => ({
    mainPlanningSessionId,
    entityBatchFromIaId,
  }),
);

export const isSheetToStartOperationsDataAlreadyInStoreSelector = createSelector(
  sheetToStartOperationsRequestParamsSelector,
  (_, { mainPlanningSessionId, entityBatchFromIaId }) => ({
    mainPlanningSessionId,
    entityBatchFromIaId,
  }),
  (
    {
      mainPlanningSessionId: mainPlanningSessionIdInStore,
      entityBatchFromIaId: entityBatchFromIaIdInStore,
    },
    {
      mainPlanningSessionId,
      entityBatchFromIaId,
    },
  ) => {

    //если сессия новая или mainPlanningSessionId не установлена в store (это означает, что запросы ещё вообще не
    //выполнялись), то данные точно старые, их нужно обновить
    if (
      mainPlanningSessionIdInStore !== mainPlanningSessionId ||
      mainPlanningSessionIdInStore === null
    )
      return false;

    return entityBatchFromIaIdInStore === entityBatchFromIaId;
  },
);


export const sheetToStartOperationsDataEntitiesSelector = state =>
  sheetsToStartStateSheetOperationsDataSelector(state).dataEntities;

/*
* Селектор формирования данных для предполагаемых операций запускаемого маршрутного листа на основании данных запланированных
* заданий и подзаданий, полученных из приложения IA.
* В store уже хранятся задания и подзадания только типов "П-З" и "операция", запрос выполняется с фильтрацией по типам тасков.
* Операция маршрутного листа объединяют информацию всех подзаданий, а также задания типов "П-З" и "Операция" для одной
* технлогической операции. Т.е. задания выдаются отдельно на П-З работы и самоу операцию + могут быть разделены на подзадания
* при возникновении перерывов, здесь же в рамках операции маршрутного листа это всё нужно объединить.
* */
export const sheetToStartOperationsTableDataSelector = createSelector(
  sheetToStartOperationsDataEntitiesSelector,
  operationsDataEntities => {

    if(_isNil(operationsDataEntities)) {
      return [];
    }

    const{
      [SIMULATION_OPERATION_TASK_MODEL]: simulationOperationTasksEntities = {},
      [OPERATION_MODEL]: operationEntities = {},
      [DEPARTMENT_MODEL]: departmentEntities = {},
      [EQUIPMENT_CLASS_MODEL]: equipmentClassEntities = {},
      [SIMULATION_ENTITY_BATCH_MODEL]: simulationEntityBatchEntities = {},
      [SIMULATION_ORDER_ENTITY_BATCH_MODEL]: simulationOrderEntityBatchEntities = {},
      [ORDER_MODEL]: orderEntities = {},
    } = operationsDataEntities;

    //Формируем таски со всеми связанным данными
    const tasksData = Object
      .values(simulationOperationTasksEntities)
      .map(simulationOperationTaskEntity => {

        const simulationOperationTaskParams =
          _pick(
            getEntityDataByTemplate(simulationOperationTaskEntity, SIMULATION_OPERATION_TASK_DATA_FROM_IA_ENTITY_TEMPLATE),
            ['operationStartDate', 'operationStopDate', 'operationId'],
          );

        const { operationId } = simulationOperationTaskParams;

        const operationParams = getEntityDataByTemplate(
          operationEntities[operationId],
          OPERATION_DATA_FROM_IA_ENTITY_TEMPLATE,
        );

        const {
          departmentId,
          equipmentClassId,
          prodTime,
        } = operationParams;

        const departmentParams = getEntityDataByTemplate(
          departmentEntities[departmentId],
          DEPARTMENT_DATA_FROM_IA_ENTITY_TEMPLATE,
        );

        const equipmentClassParams = getEntityDataByTemplate(
          equipmentClassEntities[equipmentClassId],
          EQUIPMENT_CLASS_DATA_FROM_IA_ENTITY_TEMPLATE,
        );

        const { simulationEntityBatchId } = simulationOperationTaskEntity;

        const simulationOrderEntityBatchEntity = Object
          .values(simulationOrderEntityBatchEntities)
          .find(
            simulationOrderEntityBatchEntity =>
            // eslint-disable-next-line
            simulationOrderEntityBatchEntity.simulationEntityBatchId === simulationEntityBatchId
          );

        const simulationEntityBatchEntity = simulationEntityBatchEntities[simulationEntityBatchId];

        const entitiesInBatchAmount = _get(simulationEntityBatchEntity, 'amount');

        const {
          operationProdTime,
          operationLabor,
        } = prepareOperationProdTimeAndLaborValues(
          divDecimals(prodTime, SECS_IN_HOUR),
          entitiesInBatchAmount,
        );

        const orderParams = getOrderParams(simulationOrderEntityBatchEntity, orderEntities);

        return {
          ...simulationOperationTaskParams,
          ...operationParams,
          ...departmentParams,
          ...equipmentClassParams,
          ...orderParams,
          operationProdTime,
          operationLabor,
        };
      });

    if(!tasksData.length) return [];

    //группируем таски одной технологической операции, таким в образом, внутри одной группы будут,
    //таск (или сабтаски, если делилась) "П-З работ" и таск (или сабтаски, если делились) самой "Операции".
    const sheetOperationsByTasksGroupedData = _groupBy(
      tasksData,
      ({ operationIdentity, operationNumber }) => [operationIdentity, operationNumber].join('_'),
    );

    return Object
      .keys(sheetOperationsByTasksGroupedData)
      .map(sheetOperationUniqKey => {

        const groupedTasksArray = sheetOperationsByTasksGroupedData[sheetOperationUniqKey];

        //любая из записей группы будет содержать одинаковую адекватную информацию по операции маршрутного листа, кроме
        //времен начала и окончания, поэтому здесь просто берем первую, т.к. хотя бы одна запись всегда будет в группе.
        const sheetOperationCommonData = groupedTasksArray[0];

        //Среди тасков группы ищем начало самого раннего и окончания самого позднего и записываем эти данные для
        //операции маршрутного листа
        const operationStartDate = _minBy(
          groupedTasksArray,
          ({ operationStartDate }) => new Date(operationStartDate).getTime(),
        ).operationStartDate;

        const operationStopDate = _maxBy(
          groupedTasksArray,
          ({ operationStopDate }) => new Date(operationStopDate).getTime(),
        ).operationStopDate;

        return {
          ...sheetOperationCommonData,
          sheetOperationId: sheetOperationUniqKey,
          operationStartDate,
          operationStopDate,
        };
      })
      .sort(fieldComparatorFactory(stringComparator, 'operationNumber'));
  },
);

const getOrderParams = (simulationOrderEntityBatchEntity, orderEntities) => {
  //если нет simulationOrderEntityBatchEntity, значит это партия без заказа, для такого случая проставляем null'ы,
  if(!simulationOrderEntityBatchEntity) {
    return {
      orderId: null,
      orderName: null,
      orderPriority: null,
    };
  }
  const { orderId } = simulationOrderEntityBatchEntity;
  return {
    orderId,
    ...getEntityDataByTemplate(
      orderEntities[orderId],
      ORDER_DATA_FROM_IA_ENTITY_TEMPLATE,
    ),
  };
};
