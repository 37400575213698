import { FILTER_TYPES } from '../../../api/restCollectionApi/index';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../constants/table';

/*
* Функция трансформирования строго привязана к формату гет параметров в строке роута для таблиц. Пока что он
* зафиксирован именно таким, с полями page, limit, sortBy, asc, columnNames и search именно такого формата,
* обработка которого ведется здесь.
* Функция не учитывает множественную сортировку или сложную фильтрацию, т.к. таблицы на текущий момент не реализуют такой
* функционал. Если\когда формат изменится, логику транформации тоже нужно будет изменить
* */

export const transformUrlGetParamsToTableParams = urlGetParams => {

  const {
    page,
    limit,
    sortBy,
    asc,
    columnNames,
    search,
  } = urlGetParams;

  return {
    ..._getActivePageParams(page),
    ..._getPageSizeParams(limit),
    ..._getSortParams(sortBy, asc),
    ..._getFilterParams(columnNames, search),
  };
};

const _getActivePageParams = page => ({
  activePage: page || 1,
});

const _getPageSizeParams = limit => ({
  pageSize: limit || DEFAULT_TABLE_PAGE_SIZE,
});

const _getSortParams = (sortBy, asc) => {

  if(!sortBy)
    return { sortParams: [] };

  return {
    sortParams: [
      {
        column: sortBy,
        asc,
      },
    ],
  };
};

const _getFilterParams = (columnNames, search) => {
  if(!columnNames)
    return { filterParams: {} };

  return {
    filterParams: columnNames
      .split(',')
      .reduce(
        (columnsFilters, columnName, index) => {
          // eslint-disable-next-line no-param-reassign
          columnsFilters[columnName] = {
            filterValue: search[index],
            filterType: FILTER_TYPES.CONTAINS,
          };
          return columnsFilters;
        },
        {},
      ),
  };
};
