import { Transformer } from '../Transformer';


export class DefaultSheetWithEnoughPartsAndMaterials extends Transformer {

  static transformToState(item) {
    const {
      entityBatchId,
      entityRouteSheetId,
    } = item;
    return{
      entityBatchId,
      sheetId: entityRouteSheetId,
    };
  }
}