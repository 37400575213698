import humps from 'humps';
import _get from 'lodash/get';


/*
* Карта ошибок валидации структур данных.
* */
export const REQUEST_BODY_FIELDS_SERVER_ERRORS = {
  REQUIRED: 'VALUE_IS_REQUIRED',
  INVALID_TYPE: 'VALUE_HAS_INVALID_TYPE',
  CAN_NOT_BE_NULL: 'VALUE_CAN_NOT_BE_NULL',
  INVALID: 'VALUE_IS_INVALID',
  NOT_A_VALID_INTEGER: 'VALUE_IS_NOT_A_VALID_INTEGER',
  NOT_A_VALID_STRING: 'VALUE_IS_NOT_A_VALID_STRING',
  NOT_A_VALID_UNICODE_STRING: 'VALUE_IS_NOT_A_VALID_UNICODE_STRING',
  NOT_A_VALID_BOOLEAN: 'VALUE_IS_NOT_A_VALID_BOOLEAN',
  NOT_A_VALID_FLOAT: 'VALUE_IS_NOT_A_VALID_FLOAT',
  NOT_A_VALID_DATETIME: 'VALUE_IS_NOT_A_VALID_DATETIME',
  NOT_A_VALID_LIST: 'VALUE_IS_NOT_A_VALID_LIST',
  IS_SHORTER_THAN_MINIMUM: 'VALUE_LENGTH_IS_SHORTER_THAN_MINIMUM',
  IS_LONGER_THAN_MAXIMUM: 'VALUE_LENGTH_IS_LONGER_THAN_MAXIMUM',
  IS_NOT_BETWEEN_MINIMUM_AND_MAXIMUM: 'VALUE_LENGTH_IS_NOT_BETWEEN_MINIMUM_AND_MAXIMUM',
  LENGTH_IS_NOT_EQUAL_TO: 'VALUE_LENGTH_IS_NOT_EQUAL_TO',
  MUST_BE_ONE_OF: 'VALUE_MUST_BE_ONE_OF_ALLOWED_VALUES',
};

/*
* Функция трансформирует ошибки валидации полей из ответа на http запрос responseWithRequestBodyFieldsServerErrors,
* который имеет вид:
* {
*   'data': {
*     'data': {
*       'field1': ['VALUE_LENGTH_IS_SHORTER_THAN_MINIMUM', 'VALUE_MUST_BE_ONE_OF_ALLOWED_VALUES'],
*       'field2': [
*         'VALUE_IS_REQUIRED',
*         {
*           'data': {
*             'min': 3,
*             'max': null,
*             'equal': null,
*           },
*           'message': 'VALUE_LENGTH_IS_SHORTER_THAN_MINIMUM'
*         }
*       ]
*   },
*   'identity': 'REQUEST_JSON_STRUCTURE',
*   'source': 'WEBSERVER',
* }
* 
* К виду:
* {
*   [field1]: [
*     {
*       message: 'VALUE_LENGTH_IS_SHORTER_THAN_MINIMUM',
*       data: null
*     },
*     {
*       message: 'VALUE_MUST_BE_ONE_OF_ALLOWED_VALUES',
*       data: null
*     }
*   ],
*   [field2]: [
*      {
*        message: 'VALUE_IS_REQUIRED',
*        data: null,
*      },
*      {
*        message: 'VALUE_LENGTH_IS_SHORTER_THAN_MINIMUM',
*        data: {
*          'min': 3,
*          'max': null,
*          'equal': null,
*        }
*      }
*   ]
* }
* 
* Как видим, преобразование осуществляется в менее вложенную и в однотипную структуру. Ошибки на сервере могут 
* описываться простой строковой константой или же объектом с полями message (строковый идентификатор ошибки) и
* data (пэйлоад ошибки). Для удобства дальнейшей обработки преобразуем все ошибки к формату с message и data. Для
* ошибок, описываемых строковой константой в таком случае для data проставляется null
*
* Параметры:
*  - responseWithRequestBodyFieldsServerErrors - объект описания ошибки запроса, структура которого представлена в
* начале комментария
*  - responseKeysTransformMap - карта, описывающая какие ключи полей из ответа сервера нужно заменить на клиентские.
* Если откамелайженный (humps.camelize) ключ ответа не задан в карте для трансформации, то это означает, что
* преобразовывать ключ не нужно, просто берется откамелайженный ключ из самого ответа
* */
export const transformRequestBodyFieldsServerErrors = (
  responseWithRequestBodyFieldsServerErrors,
  responseErrorFieldsTransformMap = {},
) => {
  const errors = _get(responseWithRequestBodyFieldsServerErrors, ['data', 'data'], {});

  return Object
    .keys(errors)
    .reduce(
      (acc, key) => {

        const errorsForKeyArray = errors[key]
          .map(error => {
            if(typeof error === 'string')
              return {
                message: error,
                data: null,
              };

            return error;
          });

        const camelizedResponseKey = humps.camelize(key);

        const fieldKey = responseErrorFieldsTransformMap[camelizedResponseKey] || camelizedResponseKey;

        acc[fieldKey] = errorsForKeyArray;
        return acc;
      },
      {},
    );
};