import { COLUMN_TYPE, TOOLS_COLUMN_NAME } from '../../../constants/table';
import { Trans } from '@lingui/macro';
import React from 'react';

export const USER_FULL_NAME_TABLE_COLUMN = 'userFullName';

export const USER_ROLES_TABLE_COLUMN = 'userRoles';

export const userSchema = {
  fields: {
    [USER_FULL_NAME_TABLE_COLUMN]: {
      columnName: USER_FULL_NAME_TABLE_COLUMN,
      displayName: (
        <Trans id="users_table.table_column@name">
          ФИО
        </Trans>
      ),
      order: 0,
      type: COLUMN_TYPE.STRING,
      customComponent: 'UsernameRenderer',
    },
    identity: {
      columnName: 'identity',
      displayName: (
        <Trans id="users_table.table_column@identity">
          Табельный №
        </Trans>
      ),
      order: 1,
      type: COLUMN_TYPE.STRING,
    },
    [USER_ROLES_TABLE_COLUMN]: {
      columnName: USER_ROLES_TABLE_COLUMN,
      displayName: (
        <Trans id="users_table.table_column@roles_list">
          Список ролей
        </Trans>
      ),
      order: 2,
      type: COLUMN_TYPE.STRING,
      customComponent: 'UserRolesRenderer',
      disableSort: true,
    },
    createStamp:{
      columnName: 'createStamp',
      displayName: (
        <Trans id="users_table.table_column@registration_date">
          Дата регистрации
        </Trans>
      ),
      order: 3,
      type: COLUMN_TYPE.DATE,
      customComponent: 'DateRenderer',
      disableFilter: true,
    },
    [TOOLS_COLUMN_NAME]: {
      order: 4,
    },
  },
  sortParams: [
    {
      column: USER_FULL_NAME_TABLE_COLUMN,
      asc: true,
    },
  ],
};