import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import {
  ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
  FUNC_IS_REQUIRED_TYPE,
} from '../../../../../constants/propTypes';
import {
  NotSelectedLabelTrans,
  NoDataLabelTrans,
} from '../../../../../utils/commonTransComponents';

import './style.css';
import { DefaultAutocompleteContainer } from '../../../../common/Autocomplete/AutocompleteContainer';
import { DefaultDepartmentsAutocomplete } from '../../../../entitiesAutocomplete/DepartmentsAutocomplete/DepartmentsAutocomplete';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


const ADMIN_SCREEN_DEPARTMENTS_AUTOCOMPLETE_ID = 'ADMIN_SCREEN_DEPARTMENTS_AUTOCOMPLETE_ID';

export class NewSettingsOwnerSelects extends Component {

  static propTypes = {
    selectedDepartment: ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
    selectDepartment: FUNC_IS_REQUIRED_TYPE,
    selectedEquipmentClass: ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
    equipmentClassSelectOptions: PropTypes.arrayOf(ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE).isRequired,
    selectEquipmentClass: FUNC_IS_REQUIRED_TYPE,
  };

  _renderDepartmentSelect = () => {
    const {
      selectDepartment,
      selectedDepartment,
    } = this.props;
    return(
      <div className="new-settings-owner-select__wrapper">
        <Trans id="worker_tasks_table_settings">
          Выберите подразделение для настроек
        </Trans>
        <DefaultDepartmentsAutocomplete
            id={ADMIN_SCREEN_DEPARTMENTS_AUTOCOMPLETE_ID}
            value={selectedDepartment}
            onChange={selectDepartment}
            isClearable={false}
            wrapperClassName="new-settings-owner-selects__department-select"
        />
      </div>
    );
  };

  _renderEquipmentClassSelect = () => {
    const {
      selectedDepartment,
      selectedEquipmentClass,
      equipmentClassSelectOptions,
      selectEquipmentClass,
    } = this.props;
    return(
      <div className="new-settings-owner-select__wrapper">
        <Trans id="worker_tasks_table_settings@choose_equipment_class_for_settings">
          Выберите класс РЦ для настроек (не обязательно)
        </Trans>
        <DefaultAutocompleteContainer
            value={selectedEquipmentClass}
            onChange={selectEquipmentClass}
            options={equipmentClassSelectOptions}
            getOptionLabel={getResourceCombinedName}
            isClearable
            isDisabled={!selectedDepartment}
            placeholder={NotSelectedLabelTrans}
            noOptionsMessage={NoDataLabelTrans}
            wrapperClassName="new-settings-owner-selects__equipment-class-select"
        />
      </div>
    );
  };

  render() {
    return (
      <div className="new-settings-owner-selects">
        {this._renderDepartmentSelect()}
        {this._renderEquipmentClassSelect()}
      </div>
    );
  }
}