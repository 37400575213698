import { SheetOperationSettings } from './SheetOperationSettings';
import { connect } from 'react-redux';
import {
  SHEET_OPERATION_SETTINGS_GROUP,
  SHEET_OPERATION_MAIN_SETTINGS_NAME,
} from './constants';
import { saveSettingsEntity } from '../../../operations/settings';
import { compose } from 'redux';
import { sheetOperationSettingsSelector } from '../../../selectors/settings';
import { sendSettingsSavedNotification } from '../../../constants/settings';


const mapStateToProps = state => ({
  settings: sheetOperationSettingsSelector(state),
});

const mapDispatchToProps = {
  saveSettingsEntity,
};

const mergeProps = (stateProps, dispatchProps) => {

  const {
    settings,
  } = stateProps;

  const {
    saveSettingsEntity: saveSettings,
  } = dispatchProps;

  return {
    settings: settings,
    onChange: ({ target: { checked, name } }) => {
      const newSettingValue = {
        [name]: checked,
      };

      saveSettings({
        group: SHEET_OPERATION_SETTINGS_GROUP,
        name: SHEET_OPERATION_MAIN_SETTINGS_NAME,
        value: { ...settings, ...newSettingValue },
      })
        .then(sendSettingsSavedNotification);
    },
  };
};

export const SheetOperationSettingsContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(SheetOperationSettings);