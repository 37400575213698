import { Trans } from '@lingui/macro';
import React from 'react';
import { COLUMN_TYPE } from '../../../constants/table';


export const MASTER_TASKS_MODEL = 'MASTER_TASKS_MODEL';

export const masterTasksSchema = {
  fields: {
    order__name: {
      columnName: 'order__name',
      displayName: (
        <Trans id="master_tasks.table_column@order_name">
          Заказ
        </Trans>
      ),
      order: 0,
      type: COLUMN_TYPE.STRING,
      customComponent: 'OrderNameRenderer',
    },
    entityRouteSheet__identity: {
      columnName: 'entityRouteSheet__identity',
      displayName: (
        <Trans id="master_tasks.table_column@sheet">
          Маршрутный лист
        </Trans>
      ),
      order: 1,
      type: COLUMN_TYPE.STRING,
    },
    department__identity:{
      columnName: 'department__identity',
      displayName: (
        <Trans id="master_tasks.table_column@department_identity">
          ИД Подразделения
        </Trans>
      ),
      order: 2,
      type: COLUMN_TYPE.STRING,
      show: false,
      disableFilter: true,
    },
    department__name: {
      columnName: 'department__name',
      displayName: (
        <Trans id="master_tasks.table_column@department_name">
          Подразделение
        </Trans>
      ),
      order: 3,
      type: COLUMN_TYPE.STRING,
      show: false,
      disableFilter: true,
    },
    equipmentClass__identity: {
      columnName: 'equipmentClass__identity',
      displayName: (
        <Trans id="master_tasks.table_column@equipment_class_identity">
          ИД Класса РЦ
        </Trans>
      ),
      order: 4,
      type: COLUMN_TYPE.STRING,
      show: false,
    },
    equipmentClass__name: {
      columnName: 'equipmentClass__name',
      displayName: (
        <Trans id="master_tasks.table_column@equipment_class_name">
          Класс РЦ
        </Trans>
      ),
      order: 5,
      type: COLUMN_TYPE.STRING,
    },
    entity__identity: {
      columnName: 'entity__identity',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@entity_identity">
          ИД ДСЕ
        </Trans>
      ),
      order: 6,
      show: false,
    },
    entity__code: {
      columnName: 'entity__code',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@entity_code">
          Шифр ДСЕ
        </Trans>
      ),
      order: 7,
      show: false,
    },
    entity__name: {
      columnName: 'entity__name',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@entity_name">
          ДСЕ
        </Trans>
      ),
      order: 8,
    },
    operation__identity: {
      columnName: 'operation__identity',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@operation_identity">
          ИД Операции
        </Trans>
      ),
      order: 9,
      show: false,
    },
    operation__nop: {
      columnName: 'operation__nop',
      type: COLUMN_TYPE.NUMBER,
      displayName: (
        <Trans id="master_tasks.table_column@operation_number">
          № Операции
        </Trans>
      ),
      order: 10,
    },
    operation__name: {
      columnName: 'operation__name',
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@operation_name">
          Операция
        </Trans>
      ),
      order: 11,
    },
    entitiesInBatchAmount: {
      columnName: 'entitiesInBatchAmount',
      displayName: (
        <Trans id="master_tasks.table_column@entities_in_batch_amount">
          Размер партии
        </Trans>
      ),
      order: 12,
      type: COLUMN_TYPE.NUMBER,
    },
    startDate: {
      columnName: 'startDate',
      type: COLUMN_TYPE.DATE,
      displayName: (
        <Trans id="master_tasks.table_column@operation_start_date">
          Плановая дата начала
        </Trans>
      ),
      order: 13,
      customComponent: 'DateRenderer',
      disableFilter: true,
      show: false,
    },
    stopDate: {
      columnName: 'stopDate',
      type: COLUMN_TYPE.DATE,
      displayName: (
        <Trans id="master_tasks.table_column@operation_stop_date">
          Плановая дата окончания
        </Trans>
      ),
      order: 14,
      customComponent: 'DateRenderer',
      disableFilter: true,
      show: false,
    },
    operationProdTime: {
      // TODO имя колонки указано неправильно, в модели это поле называется prodTime, нужно исправить схемы и селекторы
      // на текущий момент фильтрация отключена, потому что она не работает. НО даже с правильно указанной колонкой
      // работать она всё равно не будет, потому что в запросе приходят секунды, соответственно и фльтр должен быть по
      // секундам. Для дробных значений при запросе 400 ошибка. Нужно будет реализовать кастомный фильтр, каоторый
      // будет преобразовывать введённые пользователем "1ч. 2мин. 3сек." в секунды.
      columnName: 'operationProdTime',
      type: COLUMN_TYPE.NUMBER,
      displayName: (
        <Trans id="master_tasks.table_column@operation_prod_time">
          Тшт., ч.
        </Trans>
      ),
      order: 15,
      customComponent: 'HumanizedTimeFromHoursRenderer',
      disableFilter: true,
    },
    progress: {
      columnName: 'progress',
      displayName: (
        <Trans id="master_tasks.table_column@operation_progress">
          Прогресс выполнения
        </Trans>
      ),
      order: 16,
      type: COLUMN_TYPE.NUMBER,
      customComponent: 'SheetOperationProgressRenderer',
      disableFilter: true,
    },
    operationLabor: {
      columnName: 'operationLabor',
      type: COLUMN_TYPE.NUMBER,
      displayName: (
        <Trans id="master_tasks.table_column@operation_labor">
          Трудоемкость операции, ч.
        </Trans>
      ),
      order: 17,
      customComponent: 'OperationLaborRenderer',
      disableFilter: true,
    },
    assignees: {
      columnName: 'assignees',
      displayName: (
        <Trans id="master_tasks.table_column@assignees">
          Исполнители
        </Trans>
      ),
      type: COLUMN_TYPE.STRING,
      order: 18,
      disableFilter: true,
      customComponent: 'AssigneesRenderer',
    },
    equipment: {
      columnName: 'equipment',
      displayName: (
        <Trans id="master_tasks.table_column@equipment">
          Оборудование
        </Trans>
      ),
      order: 19,
      type: COLUMN_TYPE.STRING,
      disableFilter: true,
      customComponent: 'MasterTasksEquipmentCombinedDataRenderer',
    },
  },
};
