import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Checkbox from '@mui/material/Checkbox';

import { OBJECT_OF_ANY_TYPE, FUNC_IS_REQUIRED_TYPE } from '../../../../../constants/propTypes';

import { fieldComparatorFactory, numberComparator } from '@bfg-frontend/utils/lib/array';

import cn from 'classnames';

import './style.css';


export class TasksTableColumnsSettingsGroup extends Component {

  static propTypes = {
    isReadOnly: PropTypes.bool.isRequired,
    tasksTableColumnsSettings: OBJECT_OF_ANY_TYPE.isRequired,
    editTasksTableColumnsSettings: FUNC_IS_REQUIRED_TYPE,
  };

  _columnDragEndCbFactory = columnsSortedByOrderArr => dragEndData => {

    const {
      source: dragSourceData,
      destination: dragDestinationData,
    } = dragEndData;


    // если дропнули за пределами контейнера или на тот же индекс, то ничего не делаем
    if (!dragDestinationData)  return;

    const { index: dragSourceIndex } = dragSourceData;
    const { index: dragDestinationIndex } = dragDestinationData;

    if(dragSourceIndex === dragDestinationIndex) return;

    const newSortedByOrderArr = columnsSortedByOrderArr.slice();
    const [draggedItem] = newSortedByOrderArr.splice(dragSourceIndex, 1);
    newSortedByOrderArr.splice(dragDestinationIndex, 0, draggedItem);

    const newTasksTableColumnsSettings = newSortedByOrderArr
      .reduce(
        (tasksTableColumnsData, columnData, columnNewOrderIndex) => {
          const { columnName: columnId } = columnData;
          // eslint-disable-next-line no-param-reassign
          tasksTableColumnsData[columnId] = {
            ...columnData,
            order: columnNewOrderIndex,
          };
          return tasksTableColumnsData;
        },
        {},
      );

    this.props.editTasksTableColumnsSettings(newTasksTableColumnsSettings);
  };

  _renderColumnsDroppableContainer = columnsSortedByOrderArr =>
    (provided, snapshot) => (
      <div
          ref={provided.innerRef}
          className={
            cn(
              'tasks-table-columns-settings-group__columns-droppable-container',
              { 'tasks-table-columns-settings-group__columns-droppable-container--is-dragging-over': snapshot.isDraggingOver },
            )
          }
          {...provided.droppableProps}
      >
        {this._renderDraggableColumnsItems(columnsSortedByOrderArr)}
        {provided.placeholder}
      </div>
    );

  _renderDraggableColumnsItems = columnsSortedByOrderArr =>
    <div className="tasks-table-columns-settings-group__columns-list">
      {
        columnsSortedByOrderArr
          .map(({ columnName, displayName, show }, index) => (
            <Draggable
                key={columnName}
                draggableId={columnName}
                index={index}
                isDragDisabled={this.props.isReadOnly}
            >
              {this._renderDraggableColumnItem(columnName, displayName, show)}
            </Draggable>
          ))
      }
    </div>;

  _renderDraggableColumnItem = (columnId, columnTitle, isColumnVisible) =>
    (provided, snapshot) => (
      <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={
            cn(
              'tasks-table-columns-settings-group__draggable-column-item',
              { 'tasks-table-columns-settings-group__draggable-column-item--is-dragging': snapshot.isDragging },
            )
          }
          style={{
            ...provided.draggableProps.style,
          }}
      >
        <div className="tasks-table-columns-settings-group__draggable-column-content">
          <div className="tasks-table-columns-settings-group__draggable-column-title">
            {columnTitle}
          </div>
          <Checkbox
              className="tasks-table-columns-settings-group__draggable-column-visibility-checkbox"
              checked={isColumnVisible}
              onChange={
                this.props.isReadOnly ?
                  undefined :
                  this._columnVisibilityChangeCbFactory(columnId)
              }
              value={String(columnId)}
              color="default"
          />
        </div>
      </div>
    );

  _columnVisibilityChangeCbFactory = columnId => e => {
    e.stopPropagation();

    const {
      tasksTableColumnsSettings,
    } = this.props;

    const checked = e.target.checked;

    /*
    * Если пытаемся сделать невидимой последнюю из видимых колонок, то ничего не делаем, должна быть хотя бы одна
    * видимая колонка
    * */
    if(
      !checked &&
      Object.values(tasksTableColumnsSettings).filter(({ show }) => show).length === 1
    ) return;

    const newTasksTableColumnsSettings = {
      ...tasksTableColumnsSettings,
      [columnId]: {
        ...tasksTableColumnsSettings[columnId],
        show: checked,
      },
    };

    this.props.editTasksTableColumnsSettings(newTasksTableColumnsSettings);
  };

  render() {
    const columnsSortedByOrderArr = Object
      .values(this.props.tasksTableColumnsSettings)
      .sort(fieldComparatorFactory(numberComparator, 'order'));

    return (
      <div className="tasks-table-columns-settings-group">
        <div className="tasks-table-columns-settings-group__title">
          <Trans id="worker_tasks_table_settings@tasks_table_columns_settings_group">
            Порядок и видимость колонок отображаемой таблицы заданий
          </Trans>
        </div>
        <DragDropContext onDragEnd={this._columnDragEndCbFactory(columnsSortedByOrderArr)}>
          <Droppable
              droppableId="tasks-table-columns-settings-group__tasks-table-columns-dragging-droppable"
              direction="horizontal"
          >
            {this._renderColumnsDroppableContainer(columnsSortedByOrderArr)}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}
