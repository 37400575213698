import { sendActionToServer } from '../../api';
import { reInitReduxState } from '../../reducers/actions';
import { NOTIFICATION_LEVEL, sendNotification } from '../../constants/notification';
import { SERVER_ACTION_POINT } from '../../constants/serverActions';
import { Trans } from '@lingui/macro';
import React from 'react';

export const clearDbActionCreator = () => dispatch =>
  dispatch(sendActionToServer(SERVER_ACTION_POINT.CLEAR_DB, {}, {
    isBlockingRequest: true,
  }))
    .then(() => {
      dispatch(reInitReduxState({
        stateKeysToPreserve: ['router', 'appState'],
      }));
      sendNotification(
        <Trans id="clear_db@db_clear_success_notification">
          База данных очищена
        </Trans>,
        NOTIFICATION_LEVEL.SUCCESS,
      );
    })
    .catch(error => {
      sendNotification(
        <Trans id="clear_db@db_clear_error_notification">
          Не удалось очистить базу данных, повторите попытку
        </Trans>,
        NOTIFICATION_LEVEL.ERROR,
      );
      return Promise.reject(error);
    });