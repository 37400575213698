import {
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  SHEET_OPERATION_FEATURES_MODEL,
} from '../../../constants/models';
import { Trans } from '@lingui/macro';
import React, { useMemo } from 'react';
import {
  ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
  ADMIN_APP_DISABLED_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
} from '../../../constants/routes';
import { ActiveSheetOperationFeaturesTable } from './ActiveSheetOperationFeaturesTable';
import { DisabledSheetOperationFeaturesTable } from './DisabledSheetOperationFeaturesTable';
import { fetchRemoteTableEntities } from '../../../reducers/entities/actions';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import {
  prepareRemoteTableSortParamsForRequest,
} from '../../../utils/tables';
import { reFetchRemoteTableData } from '../../../reducers/table/actions';
import { parseCombinedSheetOperationFeatureScopeId } from '../SheetOperationFeatureReviewScreen/constants';
import _get from 'lodash/get';
import {
  DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE,
  EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE,
  OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE,
} from '../../../utils/entities';
import { getEntityDataByTemplate } from '@bfg-frontend/utils/lib/stringBuilders/entity';

export const SheetOperationFeaturesTitleTrans = (
  <Trans id="sheet_operation_settings.sheet_operation_features@title">
    Дополнительные характеристики операции
  </Trans>
);

export const CreateNewSheetOperationFeatureFeatureTrans = (
  <Trans id="sheet_operation_settings.sheet_operation_features@create_new">
    Создать новую характеристику
  </Trans>
);

export const SHEET_OPERATION_FEATURES_TABLE_ID = 'SHEET_OPERATION_FEATURES_TABLE_ID';
export const ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID = 'ACTIVE_SHEET_OPERATION_FEATURES_TABLE_ID';
export const DISABLED_SHEET_OPERATION_FEATURES_TABLE_ID = 'DISABLED_SHEET_OPERATION_FEATURES_TABLE_ID';

export const SHEET_OPERATION_FEATURES_TABLE_MENU_OPTIONS = {
  disableSummaryOption: true,
};

export const SHEET_OPERATION_FEATURES_NAV_TABS = [
  {
    tabId: 'active-sheet-operation-features-table',
    tabTitle: (
      <Trans id="users_table@active_users_tab_title">
        Активные
      </Trans>
    ),
    tabLink: ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
    tabRouteParams: {
      path: ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
      component: ActiveSheetOperationFeaturesTable,
    },
  },
  {
    tabId: 'disabled-sheet-operation-features-table',
    tabTitle: (
      <Trans id="users_table@deactivated_users_tab_title">
        Неактивные
      </Trans>
    ),
    tabLink: ADMIN_APP_DISABLED_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
    tabRouteParams: {
      path: ADMIN_APP_DISABLED_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE,
      component: DisabledSheetOperationFeaturesTable,
    },
  },
];

export const fetchSheetOperationFeaturesTableData = (tableParams, disabled = false) =>
  dispatch => {

    const {
      activePage,
      pageSize,
      sortParams = [],
      filterParams = {},
    } = tableParams;

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'disabled',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: disabled,
          },
          ...Object
            .keys(filterParams)
            .map(column => {
              const {
                filterType,
                filterValue,
              } = filterParams[column];

              return {
                /*
                * Для колонки name кастомный фильтр автокомплит, у которого filterType IN, а в filterValue массив
                * идентификаторов характеристик, поэтому нужно заменить колонку фильтра в запросе
                * */
                column: column === 'name' ? 'id' : column,
                filterType,
                filterValue,
              };
            }),
        ],
      },
      limit: pageSize,
      page: activePage,
      sortBy: prepareRemoteTableSortParamsForRequest(sortParams, ['id']),
    };

    return dispatch(fetchRemoteTableEntities(SHEET_OPERATION_FEATURES_MODEL, query));
  };

export const reFetchSheetOperationFeaturesTableData = (tableId, disabled = false) =>
  dispatch => dispatch(reFetchRemoteTableData(
    tableId,
    SHEET_OPERATION_FEATURES_MODEL,
    ({ tableParams }) => dispatch(fetchSheetOperationFeaturesTableData(tableParams, disabled)),
  ));


export const useSheetOperationFeatureScopesTableData = (featureScopesById, initialValues) =>
  useMemo(() => {
    if (initialValues === null) return [];

    return Object
      .keys(featureScopesById)
      .map(id => {

        const [departmentId, equipmentClassId, operationId] = parseCombinedSheetOperationFeatureScopeId(id);

        const { sheetOperationFeatureScopeIdFromDb } = _get(featureScopesById, [id], {});

        const {
          departmentName,
          departmentIdentity,
        } = getEntityDataByTemplate(
          _get(featureScopesById, [id, DEPARTMENT_MODEL]),
          DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE,
        );

        const {
          equipmentClassName,
          equipmentClassIdentity,
        } = getEntityDataByTemplate(
          _get(featureScopesById, [id, EQUIPMENT_CLASS_MODEL]),
          EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE,
        );

        const {
          operationName,
          operationIdentity,
          operationNumber,
        } = getEntityDataByTemplate(
          _get(featureScopesById, [id, OPERATION_MODEL]),
          OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE,
        );

        return {
          id,
          sheetOperationFeatureScopeIdFromDb,

          /*
          если не выбрано подразделение, то все его поля будут undefined, а идентификатор будет пустой строкой,
          потому что получаем через split, поэтому тут преобразуем пустую строку к undefined для однообразности
          */
          departmentId: departmentId === '' ? undefined : departmentId,
          departmentName,
          departmentIdentity,

          equipmentClassId: equipmentClassId === '' ? undefined : equipmentClassId,
          equipmentClassName,
          equipmentClassIdentity,

          operationId: operationId === '' ? undefined : operationId,
          operationName,
          operationIdentity,
          operationNumber,
        };
      });
  }, [featureScopesById, initialValues]);
