import {
  blockingAsyncActionStarted,
  blockingAsyncActionFinished,
} from '../../reducers/blockingAsyncAction/actions';

import {
  makeGETHttpRequest,
  makePOSTHttpRequest,
  makePUTHttpRequest,
  makeDELETEHttpRequest,
} from './index';

import { getUniqId } from '@bfg-frontend/utils/lib/getUniqId';

import humps from 'humps';
import { transformQueryParamsToQueryString } from '../../utils/url';
import { HTTP_REQUEST_STATUS, HTTP_REQUEST_METHOD } from './constants';


/*
* Абстрактный экшен криетор для выполнения GET http запросов.
*
* Перед запросом данные url и query параметров декамелайзятся (
* на фронте переменные хранятся в camelCase, для сервера данные преобразуются в snake_case), после запроса, данные,
* наоборот, камелайзятся.
*
* Объект query параметров queryParams преобразуется в правильную url encoded строку строку в url'e.
*
* Производится обработка ошибок при запросе.
*
* @param url {String} - урл для запроса
* @param queryParams {Object} - объект квери параметров. В ключах идентификатор параметра, в значении могут быть:
*  - строковое значение для простого квери параметра, например {id: '3'} ---> 'id=3'
*  - значение в виде объекта для указания блока параметра, например
*  {sortBy: {column: 'name', params: {param1: 1, param2: 2, param3: 3}}} ---> 'sortBy=name&param1=1&param2=2&param3=3'
*  - значение в виде массива, для указания множественный параметров с одним ключом. Внутри массива может быть также
*  значение в виде объекта, описанное выше, для указания блока параметра
*  {id: ['2', {column: '3', params: {param1: 1}}, '4', '5']}  ----> 'id=2&id=3&param1=1&id=4&id=5',
* @param fetchDataOptions - дополнительные опции запроса, переопределяющие опции по-умолчанию.
*   - isBlockingRequest {bool} - если true, то перед стартом запросы вызывается экшн устанавливающий флаг в стор, что
*   началось асинхронное действие, окончание которого нужно ждать, перед тем как продолжить работу.
*  (По этому флагу крутится какой-нибудь глобальный спиннер). Если false, то ничего не происходит. (По умолчанию - true)
*   - showServerError {bool} - если true, то в случае ошибки запроса, кроме ошибок авторизации и ошибки соединения,
*  данные ошибки будут выведены в виде нотификейшена (По умолчанию - true)
*   - также здесь могут быть заданы параметры для низкроуровневой абстракции выполнения запроса makeGETHttpRequest
*
*  Dispatch получаемого экшена вернет Promise c camelCase ответом или с ошибкой, если запрос закончился ошибкой
* */
export const fetchData = (url, queryParams = {}, fetchDataOptions = {}) =>
  dispatch => {
    const options = {
      ...DEFAULT_FETCH_DATA_OPTIONS,
      ...fetchDataOptions,
    };

    const { isBlockingRequest } = options;

    const requestUniqId = getUniqId();

    isBlockingRequest && dispatch(blockingAsyncActionStarted(requestUniqId));

    const decamelizedUrl = humps.decamelize(url);
    const queryString = transformQueryParamsToQueryString(queryParams);

    const urlWithQueryString = queryString ?
      [decamelizedUrl, queryString].join('?') :
      decamelizedUrl;

    return makeGETHttpRequest(urlWithQueryString, options)
      .then(({ status, response }) => {
        isBlockingRequest && dispatch(blockingAsyncActionFinished(requestUniqId));

        return status === HTTP_REQUEST_STATUS.SUCCESS ?
          getResponseWithCamelizedKeys(response) :
          Promise.reject({ status, response, options });
      });
  };

const DEFAULT_FETCH_DATA_OPTIONS = {
  isBlockingRequest: true,
  showServerError: true,
};

/*
* Некоторые ключи на сервере, возвращаемые в ответе, могут быть изредка полностью UPPERCASE'ом или начинаться с _. В
* этом случае обычная функция camelize отработает некорректно, для этих случаев отдельная обработка:
*  - в случае ключа, когда все символы UPPERCASE'ом и цифрами, игнорируем
*  - если ключ начинается с _, то после преобразования так же добавляем в начало этот символ
*  Пример: если без функции ключ _errors_in_request ---> ErrorsInRequest, с функцией _errors_in_request---> errorsInRequest
* */
const getResponseWithCamelizedKeys = response =>
  humps.camelizeKeys(response, responseCamelizeKeysConversionCb);
const responseCamelizeKeysConversionCb = (key, convert) => {
  if(/^[A-Z0-9_]+$/.test(key)) return key;
  if(/^_+/.test(key)) return `_${convert(key)}`;
  return convert(key);
};

/*
* Абстрактный экшен криетор для выполнения POST или PUT http запросов
*
* Перед запросом данные url и тела запроса (только ключи) декамелайзятся (на фронте переменные хранятся в camelCase,
* для сервера данные преобразуются в snake_case), после запроса, данные, наоборот, камелайзятся.
*
* Производится обработка ошибок при запросе.
*
* @param url {String} - урл для запроса
* @param data {Object} - Тело запроса, которое преобразуется в JSON строку
* @param postDataOptions - дополнительные опции запроса, переопределяющие опции по-умолчанию.
*   - httpMethod {String} - строка 'POST' или 'PUT', определяющая какой запрос с телом выполнять (по умолчанию POST)
*   - isBlockingRequest {bool} - если true, то перед стартом запросы вызывается экшн устанавливающий флаг в стор, что
*   началось асинхронное действие, окончание которого нужно ждать, перед тем как продолжить работу.
*  (По этому флагу крутится какой-нибудь глобальный спиннер). Если false, то ничего не происходит. (По умолчанию - false)
*   - showServerError {bool} - если true, то в случае ошибки запроса, кроме ошибок авторизации и ошибки соединения,
*  данные ошибки будут выведены в виде нотификейшена (По умолчанию - true)
*   - также здесь могут быть заданы параметры для низкоуровневых абстракций выполнения запросов makePOSTHttpRequest и
*   makePUTHttpRequest
*
*  Dispatch получаемого экшена вернет Promise c camelCase ответом или с ошибкой, если запрос закончился ошибкой
* */
export const postData = (url, data = {}, postDataOptions = {}) =>
  dispatch => {
    const options = {
      ...DEFAULT_POST_DATA_OPTIONS,
      ...postDataOptions,
    };

    const {
      isBlockingRequest,
      httpMethod,
    } = options;

    const requestUniqId = getUniqId();

    isBlockingRequest && dispatch(blockingAsyncActionStarted(requestUniqId));

    const decamelizedUrl = humps.decamelize(url);
    const dataWithDecamelizedKeys = humps.decamelizeKeys(data);

    const requestCb = httpMethod === HTTP_REQUEST_METHOD.POST ?
      makePOSTHttpRequest : makePUTHttpRequest;

    return requestCb(decamelizedUrl, dataWithDecamelizedKeys, options)
      .then(({ status, response }) => {
        isBlockingRequest && dispatch(blockingAsyncActionFinished(requestUniqId));

        return status === HTTP_REQUEST_STATUS.SUCCESS ?
          getResponseWithCamelizedKeys(response) :
          Promise.reject({ status, response, options });
      });
  };

const DEFAULT_POST_DATA_OPTIONS = {
  isBlockingRequest: false,
  showServerError: true,
  httpMethod: HTTP_REQUEST_METHOD.POST,
};


/*
* Абстрактный экшн криетор для выполнения DELETE запроса по url
* */
export const deleteData = (url, data, deleteDataRequestOptions = {}) =>
  dispatch => {

    const options = {
      ...DELETE_DATA_OPTIONS,
      ...deleteDataRequestOptions,
    };

    const { isBlockingRequest } = options;

    const requestUniqId = getUniqId();

    isBlockingRequest && dispatch(blockingAsyncActionStarted(requestUniqId));

    return makeDELETEHttpRequest(url, data, options)
      .then(({ status, response }) => {
        isBlockingRequest && dispatch(blockingAsyncActionFinished(requestUniqId));

        return status === HTTP_REQUEST_STATUS.SUCCESS ?
          response :
          Promise.reject({ status, response, options });
      });

  };

export const DELETE_DATA_OPTIONS = {
  isBlockingRequest: true,
  showServerError: true,
};
