import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { UserRoleSelectContainer } from '../../UserRoleSelect/UserRoleSelectContainer';

import {
  AllAvailableUserRolesSelectedLabelTrans,
  NoMatchesFoundLabelTrans,
  RoleLabelTrans,
} from '../../../../utils/commonTransComponents';

import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../../reducers/autocomplete/actions';

import { USER_MODEL } from '../../../../constants/models';
import { FILTER_DATA_TYPE } from '../../../common/Filters/filters.types';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import {
  ACTIVE_USERS_REQUEST_PREDEFINED_FILTERS,
  DEACTIVATED_USERS_REQUEST_PREDEFINED_FILTERS,
  USER_ROLE_CHIP_PARAMS_MAP,
} from '../../../../constants/users';

import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../../api/restCollectionApi';

import { FILTER_COMPONENTS_MAP } from '../../../common/Filters/FilterComponents';


import _omit from 'lodash/omit';
import _get from 'lodash/get';
import { getUserFullName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


const userFullNameRenderFilterChipCb = options => {
  const {
    filterKey,
    filters,
    onApplyFilters,
    getFilterTitle,
    renderDefaultChip,
  } = options;

  const {
    filterAdditionalData,
    filterType,
  } = filters[filterKey];

  return filterAdditionalData
    .map(data => {

      const {
        id,
        name,
        lastName,
        patronymicName,
      } = data;

      const filterChipContent = (
        <span>
          {getFilterTitle(filters[filterKey])}
          {': '}
          <b>{getUserFullName({ name, lastName, patronymicName })}</b>
        </span>
      );

      return renderDefaultChip({
        filterKey: [filterKey, id].join('__'),
        label: filterChipContent,
        onDelete: () => {

          /* убираем данные по удаляемой сущности из ключа filterAdditionalData */
          const updatedFilterAdditionalData = filterAdditionalData
            .filter(({ id: userId }) => userId !== id);

          /*
          * если в filterAdditionalData пустой массив, значит удалили последний чип для ключа ФИО,
          * в таком случае необходимо обнулить фильтр для всего ключа
          * */
          if(updatedFilterAdditionalData.length === 0)
            return onApplyFilters(_omit(filters, filterKey));

          return onApplyFilters({
            ...filters,
            [filterKey]: {
              /* формируем массив идентификаторов сущностей для фильтрации */
              filterValue: updatedFilterAdditionalData.map(({ id }) => id),
              filterAdditionalData: updatedFilterAdditionalData,
              filterType,
            },
          });
        },
      });
    });
};

const getUserFullNameFilterComponentProps = predefinedFilter => ({
  isMulti: true,
  getOptionLabel: ({ name, lastName, patronymicName }) => getUserFullName({ name, lastName, patronymicName }),
  autocompleteId: 'users-table-screen-username-filter',
  noOptionsMessage: NoMatchesFoundLabelTrans,
  loadOptionsActionCreator: createCaEntitiesAutocompleteLoadOptionsActionCreator(
    {
      requestModel: USER_MODEL,
      getRequestQuery: inputValue => {

        if(!inputValue) return { filter: predefinedFilter };

        return {
          filter: {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters: [
              predefinedFilter,
              {
                filterGroupType: FILTER_GROUP_TYPES.OR,
                filters: [
                  {
                    column: 'name',
                    filterType: FILTER_TYPES.CONTAINS,
                    filterValue: inputValue,
                  },
                  {
                    column: 'lastName',
                    filterType: FILTER_TYPES.CONTAINS,
                    filterValue: inputValue,
                  },
                  {
                    column: 'patronymicName',
                    filterType: FILTER_TYPES.CONTAINS,
                    filterValue: inputValue,
                  },
                ],
              },
            ],
          },
        };
      },
    },
  ),
});


/*
* Пока непонятно пригодится ли именно такой табличный фильтр ролей пользователей в каких то ещё таблицах, поэтому он
* сформирован локально, а не в директории абстрактных кастомных фильтров
* */
const UserRolesFilterComponent = props => {
  const {
    filterKey,
    filterData,
    onChange,
  } = props;

  const selectValue = _get(filterData, 'filterAdditionalData', null);

  const handleSelectChange = useCallback(
    value => {

      onChange({
        [filterKey]: {
          filterType: FILTER_TYPES.EQUALS,
          filterValue: value === null ?  null : value.id,
          filterAdditionalData: value,
        },
      });
    },
    [filterKey, onChange],
  );


  return (
    <div className="user-roles-filter-component">
      <UserRoleSelectContainer
          className="user-roles-filter-component__select"
          onChange={handleSelectChange}
          value={selectValue}
          placeholder={null}
          noOptionsMessage={AllAvailableUserRolesSelectedLabelTrans}
          isClearable={false}
      />
    </div>
  );
};

UserRolesFilterComponent.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filterData: FILTER_DATA_TYPE,
  onChange: FUNC_IS_REQUIRED_TYPE,
};

const userRolesRenderFilterChipCb = options => {
  const {
    filterKey,
    filters,
    getFilterTitle,
    onApplyFilters,
    renderDefaultChip,
  } = options;

  const {
    filterAdditionalData,
  } = filters[filterKey];

  const {
    name: userRoleIdentity,
  } = filterAdditionalData;

  const userRoleParams = USER_ROLE_CHIP_PARAMS_MAP[userRoleIdentity];

  return renderDefaultChip({
    filterKey,
    label: (
      <span>
        {getFilterTitle()}
        {': '}
        <b>
          {
            !!userRoleParams ?
              userRoleParams.label :
              userRoleIdentity
          }
        </b>
        </span>
    ),
    onDelete: () => onApplyFilters(_omit(filters, filterKey)),
  });
};


const getUsersTableCustomFilters = predefinedFilter => ({
  userFullName: {
    renderFilterChip: userFullNameRenderFilterChipCb,
    filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
    filterComponentProps: getUserFullNameFilterComponentProps(predefinedFilter),
  },
  userRoles: {
    getFilterTitle: () => RoleLabelTrans,
    renderFilterChip: userRolesRenderFilterChipCb,
    filterComponent: UserRolesFilterComponent,
  },
});

export const ACTIVE_USERS_TABLE_CUSTOM_FILTERS = getUsersTableCustomFilters(ACTIVE_USERS_REQUEST_PREDEFINED_FILTERS);
export const DEACTIVATED_USERS_TABLE_CUSTOM_FILTERS = getUsersTableCustomFilters(DEACTIVATED_USERS_REQUEST_PREDEFINED_FILTERS);
