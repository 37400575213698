import {
  fetchEntitiesFromServer,
} from '../reducers/entities/actions';

import {
  FILTER_GROUP_TYPES,
  FILTER_TYPES,
} from '../api/restCollectionApi';
import {
  DEPARTMENT_MODEL,
  SHEET_OPERATION_MODEL,
  EQUIPMENT_CLASS_MODEL,
  SHEET_MODEL,
  ENTITY_BATCH_MODEL,
  OPERATION_MODEL,
} from '../constants/models';
import { setTasksEquipmentClassFilter } from '../reducers/workerApp/tasksMainFilters/actions';
import { ENTITY_BATCH_STATUS, SHEET_OPERATION_STATUS, SHEET_STATUS } from '../constants/sheets';
import { saveEquipmentClassesInDepartmentWithTasks } from '../reducers/workerApp/tasksOwners/actions';
import { isIdInUrlParamsValid } from '../utils/url';
import { RELATED_MODEL_FIELD_DELIMITER } from '../constants/magics';


const EQUIPMENT_CLASSES_CHOOSE_SCREEN_REQUEST_MODEL_RELATIONS = {
  [SHEET_OPERATION_MODEL]: {
    level: 1,
  },
  [DEPARTMENT_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
  [OPERATION_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
  [SHEET_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
  [ENTITY_BATCH_MODEL]: {
    level: 3,
    relates: SHEET_MODEL,
  },
};

export const fetchEquipmentClassesForDepartmentWithTasks = departmentId =>
  dispatch => {

    const queryParams = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: [DEPARTMENT_MODEL, 'id'].join(RELATED_MODEL_FIELD_DELIMITER),
            filterType: FILTER_TYPES.EQUALS,
            filterValue: departmentId,
          },
          {
            column: [ENTITY_BATCH_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
            filterType: FILTER_TYPES.EQUALS,
            filterValue: ENTITY_BATCH_STATUS.IN_PRODUCTION,
          },
          {
            column: [SHEET_MODEL, 'type'].join(RELATED_MODEL_FIELD_DELIMITER),
            filterType: FILTER_TYPES.EQUALS,
            filterValue: SHEET_STATUS.ACTIVE,
          },
          {
            column: [SHEET_OPERATION_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
            filterType: FILTER_TYPES.NOT_EQUALS,
            filterValue: SHEET_OPERATION_STATUS.FINISHED,
          },
        ],
      },
    };

    return dispatch(
      fetchEntitiesFromServer(
        EQUIPMENT_CLASS_MODEL,
        queryParams,
        {
          modelRelations: EQUIPMENT_CLASSES_CHOOSE_SCREEN_REQUEST_MODEL_RELATIONS,
        },
      ),
    )
      .then(response  => {
        if(!response || response.responseMeta.count === 0) return;

        const {
          entities: {
            [EQUIPMENT_CLASS_MODEL]: equipmentClassesInDepartmentEntities,
          },
        } = response;

        dispatch(saveEquipmentClassesInDepartmentWithTasks(departmentId, equipmentClassesInDepartmentEntities));
      });
  };

/*
* В роутинге устанавливаются фильтр заданий по классу РЦ в подразделении в виде его id БД. В url, теоретически, может
* быть написано всё что угодно + данные для которые устанавливался ранее фильтр могут быть изменены.
* Поэтому в определенных случаях необходимо выполнить проверку, есть ли класс РЦ с таким id в БД, чтобы корректно
* продолжить работу.
*
* Если такой класс РЦ существует, то устанавливаем фильтр в state. Если нет, то реджектим
* */
export const initTasksEquipmentClassFilterFromRoute = equipmentClassIdFromRoute =>
  dispatch => {

    if(!isIdInUrlParamsValid(equipmentClassIdFromRoute))
      return Promise.reject(equipmentClassIdFromRoute);

    const queryParams = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'id',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: equipmentClassIdFromRoute,
          },
        ],
      },
    };

    return dispatch(fetchEntitiesFromServer(EQUIPMENT_CLASS_MODEL, queryParams))
      .then(response => {
        const {
          entities: {
            [EQUIPMENT_CLASS_MODEL]: equipmentClassEntities,
          } = {},
        } = response;

        const equipmentClassEntity = equipmentClassEntities[equipmentClassIdFromRoute];

        if(!!equipmentClassEntity) {
          dispatch(setTasksEquipmentClassFilter(equipmentClassEntity));
          return Promise.resolve(equipmentClassEntity);
        }
        return Promise.reject(equipmentClassIdFromRoute);
      });
  };

const EQUIPMENT_CLASSES_IN_DEPARTMENT_REQUEST_MODEL_RELATIONS = {
  [SHEET_OPERATION_MODEL]: {
    level: 1,
  },
  [DEPARTMENT_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
};

export const fetchEquipmentClassesInDepartment = (departmentId, query = {}, requestOptions = {}) =>
  dispatch => dispatch(fetchEntitiesFromServer(
    EQUIPMENT_CLASS_MODEL,
    {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: [DEPARTMENT_MODEL, 'id'].join('__'),
            filterType: FILTER_TYPES.EQUALS,
            filterValue: departmentId,
          },
        ],
      },
      ...query,
    },
    {
      modelRelations: EQUIPMENT_CLASSES_IN_DEPARTMENT_REQUEST_MODEL_RELATIONS,
      ...requestOptions,
    },
  ));