import {
  BLOCKING_ASYNC_ACTION_STARTED,
  BLOCKING_ASYNC_ACTION_FINISHED,
} from './actions';

import _omit from 'lodash/omit';

const BLOCKING_ASYNC_ACTION_INITIAL_STATE = {};

export const blockingAsyncAction = (state = { ...BLOCKING_ASYNC_ACTION_INITIAL_STATE }, action) => {
  switch (action.type) {
  case BLOCKING_ASYNC_ACTION_STARTED:
    return {
      ...state,
      [action.asyncActionUniqId]: true,
    };
  case BLOCKING_ASYNC_ACTION_FINISHED:
    return _omit(state, action.asyncActionUniqId);
  default:
    return state;
  }
};
