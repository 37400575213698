import {
  SET_TASKS_DEPARTMENT_FILTER,
  RESET_TASKS_DEPARTMENT_FILTER,
  SET_TASKS_EQUIPMENT_CLASS_FILTER,
  RESET_TASKS_EQUIPMENT_CLASS_FILTER,
  RESET_ALL_TASKS_MAIN_FILTERS,
} from './actions';


export const TASKS_MAIN_FILTERS_INITIAL_STATE = {
  department: {},
  equipmentClass: {},
};

export const tasksMainFilters = (state = { ...TASKS_MAIN_FILTERS_INITIAL_STATE }, action) => {
  switch(action.type) {
  case SET_TASKS_DEPARTMENT_FILTER: {
    const { departmentEntity } = action;
    return{
      ...state,
      department: { ...departmentEntity },
    };
  }
  case RESET_TASKS_DEPARTMENT_FILTER:
    return {
      ...state,
      department: {},
    };
  case SET_TASKS_EQUIPMENT_CLASS_FILTER: {
    const { equipmentClassEntity } = action;
    return{
      ...state,
      equipmentClass: { ...equipmentClassEntity },
    };
  }
  case RESET_TASKS_EQUIPMENT_CLASS_FILTER:
    return {
      ...state,
      equipmentClass: {},
    };
  case RESET_ALL_TASKS_MAIN_FILTERS:
    return { ...TASKS_MAIN_FILTERS_INITIAL_STATE };
  default:
    return state;
  }
};
