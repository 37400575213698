import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';


import {
  FUNC_IS_REQUIRED_TYPE,
  NUMBER_OR_STRING_TYPE,
  OBJECT_OF_ANY_TYPE,
} from '../../../constants/propTypes';


import cn from 'classnames';

import './style.css';


export class SimpleItemChoosePanel extends Component {

  static propTypes = {
    itemsList: PropTypes.arrayOf(PropTypes.shape({
      itemId: NUMBER_OR_STRING_TYPE.isRequired,
      itemData: OBJECT_OF_ANY_TYPE,
      itemContent: PropTypes.element.isRequired,
      gridItemWrapClassName: PropTypes.string,
      itemClassName: PropTypes.string,
      disabled: PropTypes.bool,
      tooltipContent: PropTypes.node,
    })),
    onSelectItem: FUNC_IS_REQUIRED_TYPE,
    gridProps: PropTypes.shape({
      direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
      justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around']),
      alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
    }),
    noItemsText: PropTypes.node,
  };

  static defaultProps = {
    itemsList: [],
    gridProps: {
      justify: 'center',
    },
  };

  _renderItems = () => {
    const {
      gridProps: { justify, direction, alignItems },
      itemsList,
    } = this.props;
    return (
      <Grid
          className="simple-item-choose-panel__grid"
          container
          direction={direction}
          justifyContent={justify}
          alignItems={alignItems}
      >
        {itemsList.map(this._renderItem)}
      </Grid>
    );
  };

  _renderItem = item => {
    const {
      itemId,
      itemData,
      itemContent,
      gridItemWrapClassName,
      itemClassName,
      disabled,
      tooltipContent,
    } = item;

    const itemPaper = (
      <Paper
          className={
            cn(
              'simple-item-choose-panel__item',
              { 'simple-item-choose-panel__item--disabled': disabled },
              itemClassName,
            )
          }
          onClick={
            disabled ?
              undefined :
              this._getOnSelectItemCb(itemId, itemData)
          }
          elevation={3}
      >
        <div className="simple-item-choose-panel__item-content-wrap">
          {itemContent}
        </div>
      </Paper>
    );

    return(
      <Grid
          key={itemId}
          item
          className={
            cn(
              'simple-item-choose-panel__grid-item-wrap',
              gridItemWrapClassName,
            )
          }
      >
        {
          tooltipContent ?
            <Tooltip
                classes={{
                  popper: 'simple-item-choose-panel__item-tooltip-popper',
                  tooltip: 'simple-item-choose-panel__item-tooltip',
                }}
                title={tooltipContent}
            >
              {itemPaper}
            </Tooltip> :
            itemPaper
        }
      </Grid>

    );
  };

  _getOnSelectItemCb = (itemId, itemData) => () => this.props.onSelectItem(itemId, itemData);

  _renderNoItemsInfo = () =>
    <div className="simple-item-choose-panel__no-items-text-wrap">
      {this.props.noItemsText}
    </div>;

  render() {
    return(
      <div className="simple-item-choose-panel">
        {
          !!this.props.itemsList.length ?
            this._renderItems() :
            this._renderNoItemsInfo()
        }
      </div>
    );
  }
}
