import { tableFactory } from '../tableFactory/tableFactory';
import { reactTablePropsAdapter } from './propsAdapters/reactTablePropsAdapter/reactTablePropsAdapter';
import { ReactTableWrapper } from './tableComponents/ReactTableWrapper/ReactTableWrapper';
import React  from 'react';

import { TABLE_CELL_RENDERERS } from './TableCellRenderers/index';

import { Pagination } from '../common/Pagination/Pagination';
import { TableFilters } from './tableComponents/TableFilters/TableFilters';
import { NestedMenu } from '../common/NestedMenu/NestedMenu';

import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ViewListIcon from '@mui/icons-material/ViewList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import { withSimpleClickAwayListener } from '../common/SimpleClickAwayListener/SimpleClickAwayListener';
import { tablePaginationPropsAdapter } from './propsAdapters/tablePaginationPropsAdapter/tablePaginationPropsAdapter';
import {
  AdditionalLabelTrans,
  RowsPerPageLabelTrans,
  SummaryInfoLabelTrans,
  TableColumnsVisibilityLabelTrans,
} from '../../utils/commonTransComponents';
import { MATERIAL_UI_STYLE_COLOR } from '../../constants/materialUI';


const TABLE_MENU_WIDTH_IN_PX = 250;
const MenuComponent = withSimpleClickAwayListener(NestedMenu);

export const Table = tableFactory({
  tableClassName: 'app-main-table',
  tableCustomCellRenderers: {
    components: TABLE_CELL_RENDERERS,
  },
  tableComponents: {
    table: {
      component: ReactTableWrapper,
      propsAdapter: reactTablePropsAdapter,
    },
    openMenuButton: {
      //eslint-disable-next-line
      component: ({ titles, className, onClick }) => (
        <Button className={className} onClick={onClick} color={MATERIAL_UI_STYLE_COLOR.INHERIT}>
          {titles && titles.menu}
        </Button>
      ),
      titles: {
        menu: AdditionalLabelTrans,
      },
    },
    menu: {
      component: props => (
        <div style={{ position: 'relative' }}>
          <MenuComponent
              {...props}
              subMenuCaretIconComponent={ArrowLeftIcon}
              nestedMenuWrapperComponent={Paper}
              nestedMenuWrapperComponentProps={{ elevation: 8 }}
              menuItemWidthInPx={TABLE_MENU_WIDTH_IN_PX}
          />
        </div>
      ),
      titles: {
        summaryOption: SummaryInfoLabelTrans,
        visibilityOption: TableColumnsVisibilityLabelTrans,
        pageSizeOption: RowsPerPageLabelTrans,
      },
      icons: {
        visibilityOption: VisibilityIcon,
        visibleColumn: CheckIcon,
        pageSizeOption: ViewListIcon,
        summaryOption: EqualizerIcon,
      },
    },
    pagination: {
      component: Pagination,
      propsAdapter: tablePaginationPropsAdapter,
    },
    filters: {
      component: TableFilters,
    },
    summary: {
      //eslint-disable-next-line
      component: ({ closeSummaryModal, tableSummary = {}, tableSummaryModalClassName }) => (
        <span className={tableSummaryModalClassName} onClick={closeSummaryModal}>
          Заглушка: сводная информация
          {tableSummary.summary}
        </span>
      ),
    },
  },
});
