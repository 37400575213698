import { NOTIFICATION_LEVEL, sendNotification } from '../../../constants/notification';
import _get from 'lodash/get';
import { HTTP_REQUEST_STATUS } from '../../httpRequestsApi/constants';
import { handleLogout } from '../../../operations/users/handleLogout';
import { closeAllSocketConnections } from '../../socketApi/index';
import { setAppCriticalError } from '../../../reducers/appState/actions';
import { APP_CRITICAL_ERROR } from '../../../constants/app';
import { SERVER_ERROR_IDENTITY, SERVER_ERROR_SOURCE } from '../../../constants/serverErrors/index';
import { CommonServerErrorLabelTrans, NoPermissionForRequestLabelTrans } from '../../../utils/commonTransComponents';


export const errorHandlerIa = ({ status, options, response }) =>
  dispatch => {

    /*
    * Запросы к IA выполняются через проксирующую точку веб-сервера CA. Таким образом, если клиент авторизован в CA, то
    * он всегда авторизован и в IA и, если мы получаем в ответе HTTP_REQUEST_STATUS.UNAUTHORIZED, то это значит клиент
    * не авторизован именно в CA, поэтому обрабатываем такую ошибку аналогично errorHandlerCa = выполняем общую
    * обработку события логаута (handleLogout), т.е. отключаемся от сокетов, очищаем все данные в redux state, что
    * приведет к редиректу на форму авторизации.
    * */
    if(status === HTTP_REQUEST_STATUS.UNAUTHORIZED) {
      dispatch(handleLogout());
      return;
    }

    /*
    * Запросы к IA выполняются через проксирующую точку веб-сервера CA, поэтому до конца непонятно, какие именно
    * действия будут запрещены такому служебному пользователю и возможна ли ошибка HTTP_REQUEST_STATUS.FORBIDDEN,
    * особенно с учетом того, что в CA мы никак не управляем данными из IA, а используем их "только для чтения", как
    * источник информации, но, теоретически, какие-то ошибки, связанные с ограничением доступа возможны, поэтому в
    * этом случае будет просто выводиться абстрактная ошибка
    * */
    if(status === HTTP_REQUEST_STATUS.FORBIDDEN) {
      showError(NoPermissionForRequestLabelTrans);
      return;
    }

   /*
    * Ошибка с таким параметрами возникает, когда совсем нет доступа к серверу IA (неверный адрес сервера, т.е.
    * неверная конфигурация, или сервер "упал"). В этом случае считаем, что работать дальше не имеет смысла,
    * отключаемся от сокетов и устанавливаем критическую ошибку в state, которая отобразится на экране и
    * больше в приложении нельзя будет работать до перезагрузки страницы
    * */
    if(
      status === HTTP_REQUEST_STATUS.FAILED &&
      _get(response, 'source') === SERVER_ERROR_SOURCE.IA &&
      _get(response, 'identity') === SERVER_ERROR_IDENTITY.CONNECTION
    ) {
      closeAllSocketConnections();
      dispatch(setAppCriticalError(APP_CRITICAL_ERROR.CONNECTION_ERROR));
      return;
    }

    /*
    * Для всех остальных статусов, кроме HTTP_REQUEST_STATUS.CONNECTION_ERROR, который обрабатывается отдельно
    * абстрактно для всех обработчиков, выводим, по умолчанию, абстрактное сообщение а-ля "ошибка сервера".
    * Дополнительную обработку пока принято осуществлять прямо в catch у функций\экшенов запросов в месте их вызова.
    * Если в случае кастомной обработка вывод этого общего сообщение не нужен, то указывается опция запроса
    * showServerError = false.
    * */
    if(options.showServerError) {
      showError(CommonServerErrorLabelTrans);
    }
  };

export const showError = errorMessage => sendNotification(
    errorMessage,
    NOTIFICATION_LEVEL.ERROR,
  );

export const errorHandlerCa = ({ status, options }) =>
  dispatch => {

    /*
    * Если пользователь не аутентифицирован при запросе, то выполняем общую обработку события логаута
    * (handleLogout), т.е. отключаемся от сокетов, очищаем все данные в redux state, что приведет к редиректу на
    * форму авторизации.
    * */
    if(status === HTTP_REQUEST_STATUS.UNAUTHORIZED) {
      dispatch(handleLogout());
      return;
    }

    /*
    * Если у пользователя нет доступа на это действие, то выводим общую ошибку про "отсутствие прав". В общем случае,
    * такая ошибки будет встречаться редко, т.к. клиентское приложение само аналогично обрабатывает все права и
    * не даст выполнять действие, для которых у пользователя недостаточно прав. Но, понятно, что могут возникать
    * разные ситуации, когда ошибка будет возникать и, конечно, это надо обработать.
    * */
    if(status === HTTP_REQUEST_STATUS.FORBIDDEN) {
      showError(NoPermissionForRequestLabelTrans);
      return;
    }

    /*
    * Для всех остальных статусов, кроме HTTP_REQUEST_STATUS.CONNECTION_ERROR, который обрабатывается отдельно
    * абстрактно для всех обработчиков, выводим, по умолчанию, абстрактное сообщение а-ля "ошибка сервера".
    * Дополнительную обработку пока принято осуществлять прямо в catch у функций\экшенов запросов в месте их вызова.
    * Если в случае кастомной обработка вывод этого общего сообщение не нужен, то указывается опция запроса
    * showServerError = false.
    * */
    if(options.showServerError) {
      showError(CommonServerErrorLabelTrans);
    }
  };

export const getErrorMessage = (response, errorMap) => {
  if (!response) return CommonServerErrorLabelTrans;
  const { source, identity } = response;
  return _get(errorMap, [source, identity], CommonServerErrorLabelTrans);
};