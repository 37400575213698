import { compose } from 'redux';
import { connect } from 'react-redux';
import { MasterWorkspace } from './MasterWorkspace';
import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';
import {
  departmentIdsFromRouteParamSelector,
  departmentIdsRouteParamSelector,
  departmentsAutocompleteValueSelector,
} from '../../../selectors/masterWorkspace';
import { initMasterDepartments } from '../../../operations/masterWorkspace/index';
import { addEntitiesToStore } from '../../../reducers/entities/actions';
import { departmentEntitiesSelector } from '../../../reducers/entities/selectors';
import { push } from 'connected-react-router';
import { MASTER_APP_MAIN_ROUTE } from '../../../constants/routes';
import {
  replaceDepartmentIdsParamInCurrentPathname,
} from './masterAppDepartmentIdsRouteParam';
import { DEPARTMENT_MODEL } from '../../../constants/models';
import _keyBy from 'lodash/keyBy';
import { reactRouterCurrentPathnameSelector, reactRouterParamsSelector } from '../../../selectors/reactRouter';
import { clearTableRemoteData } from '../../../reducers/table/actions';


/*
* Особенности текущей реализации:
*  - Идентификаторы выбранных подразделений хранятся в параметре роутера в виде id1_id2_id3. Преобразования
* идентификаторов в параметр роутера и обратно осуществляется при помощи функций из файла
* masterAppDepartmentIdsRouteParam.js
* - Идентификаторы в параметраз роутера являются основным "источником правды" о выбранных подразделениях, т.е. данные
* для выбранных значений селекта и сами отображаемые задания строятся именно на основании этих данных. При выборе
* опции в селекте, производится push новых идентификаторов в параметр роутера.
* - Т.к. в параметрах роутера только идентификаторы, а для значения селекта нужны сами сущности, то, в любом случае,
* нужно их где-то хранить. В данные момент решено не создавать дополнительных хранилищ, а использовать entities для
* записи и получения данных самих сущностей по идентификаторам из параметров роутера. В опциях селекта тоже
* находятся сущности подразделений, но, понятно, что они постоянно меняются, а для получения данных для значения нужно
* другое постоянное хранилище. Отдельное хранилище не прорабатывалось ещё и потому, что предполагается, что
* подразделения мастера в будущем будут задаваться для пользователя, как разрешения, поэтому, вероятно, будет
* испоьлзоваться какое-то другое отдельное хранилище, а для текущей реализации тогда непонятно стоит заморачиваться
* или нет, entities, в принципе, нормально подходит, минусов этого подхода не найдено.
* - На маунт производится инициализация имеющихся идентификаторов в параметре роутера, подробнее об этом в комменатриях
* к экшену initMasterDepartments. Коротко: идентификаторы проверяются на вилдность, сущности по идентификатор на
* существование, нужные сущности подразделений запрашиваются и записываются в entities, как и описывалось в
* прошлом пункте. При дальнейшей работе, при выборе новой опции, сущность из опции, тоже записывается в entities, если
* раньше её там ещё не было, чтобы выбранные значения всегда были в entities.
* */
const mapStateToProps = (state, ownProps) => ({
  currentPathname: reactRouterCurrentPathnameSelector(null, ownProps),
  departmentIdsRouteParam: departmentIdsRouteParamSelector(null, ownProps),
  departmentIdsFromRoute: departmentIdsFromRouteParamSelector(null, ownProps),
  departmentEntities: departmentEntitiesSelector(state),
  departmentsAutocompleteValue: departmentsAutocompleteValueSelector(state, ownProps),
  viewMode: reactRouterParamsSelector(null, ownProps).viewMode,
});

const mapDispatchToProps = {
  addEntitiesToStore,
  push,
  clearTableRemoteData,
  initMasterDepartments,
};

const mergeProps = (stateProps, dispatchProps) => {
  const {
    currentPathname,
    departmentIdsRouteParam,
    departmentIdsFromRoute,
    departmentEntities,
    departmentsAutocompleteValue,
    viewMode,
  } = stateProps;

  const {
    addEntitiesToStore,
    push,
    clearTableRemoteData,
    initMasterDepartments,
  } = dispatchProps;

  return {
    viewMode,
    departmentIdsRouteParam,
    departmentIdsFromRoute,
    departmentsAutocompleteValue,
    handleMasterAppViewModeChange: newViewMode => push([
      MASTER_APP_MAIN_ROUTE,
      departmentIdsRouteParam,
      newViewMode,
    ].join('/')),
    onDepartmentsAutocompleteChange: newSelectedDepartmentEntities => {
      if(newSelectedDepartmentEntities.length === 0) {
        return push(MASTER_APP_MAIN_ROUTE);
      }

      const newDepartmentsEntitiesToSave = newSelectedDepartmentEntities
        .filter(({ id }) => !departmentEntities[id]);

      if(newDepartmentsEntitiesToSave.length > 0) {
        addEntitiesToStore({
          [DEPARTMENT_MODEL]: _keyBy(newDepartmentsEntitiesToSave, 'id'),
        });
      }

      const newSelectedDepartmentIds = newSelectedDepartmentEntities
        .map(({ id }) => id);

      /*
      * Контейнер MasterWorkspaceContainer находится на роутах приложения "Мастер", поэтому можно воспользоваться
      * специальной функцией преобразования текущей ссылки в приложении мастер, на ссылку с измененными параметрами
      * подразделений - replaceDepartmentIdsParamInCurrentPathname, передав в неё текущую ссылку, полученную из
      * селектора routerPathnameSelector, и новые выбранные идентификаторыподразделений
      * */
      const currentPathnameWithReplacedDepartmentIdsParam = replaceDepartmentIdsParamInCurrentPathname(
        currentPathname,
        newSelectedDepartmentIds,
      );

      push(currentPathnameWithReplacedDepartmentIdsParam);
    },
    clearTableRemoteData,
    initMasterDepartments,
  };
};

export const MasterWorkspaceContainer = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  asyncComponent({
    resolve: [
      {
        fn: ({ departmentIdsFromRoute, initMasterDepartments }) => initMasterDepartments(departmentIdsFromRoute),
      },
    ],
  }),
)(MasterWorkspace);

MasterWorkspaceContainer.displayName = 'MasterWorkspaceContainer';