import { batchActions } from 'redux-batched-actions';
import _isPlainObject from 'lodash/isPlainObject';
import _partition from 'lodash/partition';

/*
 * Мидлвара, обрабатывающая массив экшенов в dispatch. В массиве экшенов детектируются экшены - plain объекты, группируются
 * в другой массив, который отправляется в dispatch через специальный action-creator batchActions, который, по сути, объединяет
 * несколько plain экшенов в один, что будет отрабатано в редьюсере задекорированном так же через служебной метод библиотеки
 * redux-batched-actions, store будет обновлен только 1 раз сразу с учетом всех эшенов в массиве.
 * batchActions отрабатывает внутри себя только plain экшены, именно поэтому производится предварительная фильтрация
 *
 * ТАКИМ ОБРАЗОМ используется для 2-х целей:
 * 1) для оптимизации (несколько plain action в результате обновляют стор один раз -> меньше перерисовок)
 * 2) удобство вызова сразу нескольких actions, независимо от их типа. Мидлвара сама разберется, что можно вызвать через
 * batchActions, а что нет. То, что нельзя отправит в следующую мидлвару
 * - вместо:
 *    dispatch(actionCreator1());
 *    dispatch(actionCreator2())
*    делаем:
*     dispatch([actionCreator(), actionCreator2()];
*
*
*  ОБЯЗАТЕЛЬНО нужно добавлять перед thunk и прочими мидлварами - т.к. иначе thunk в массиве actions не будут срабатывать.
*
*  Если некоторые plain экшены, всё-таки, должны быть обработаны по-другому, то для этой цели создан дополнительный параметр -
*  callback isPlainActionForAnotherMiddleware для фильтрации экшенов, который можно передать в функцию генерации мидлвары.
*  Callback isPlainActionForAnotherMiddleware принимает на вход plainAction, и должен вернуть true/false, т.е. нужно
*  ли включать его в массив, который будет обработан через batchActions или же это какой-то кастомный экшен в виже plain
*  объекта для обработки другой мидлварой.
*
 * */

const isPlainActionForAnotherMiddlewareDefault = () => false;

export const createArrayMiddleware = (isPlainActionForAnotherMiddleware = isPlainActionForAnotherMiddlewareDefault) =>
  ({ dispatch }) => next => actions => {
    //мидлвара обрабатывает только массив экшенов
    if (!Array.isArray(actions)) return next(actions);

    const [actionsToBatch, otherActions] = _partition(
      actions,
      action => _isPlainObject(action) && !isPlainActionForAnotherMiddleware(action),
    );

    otherActions.forEach(action => next(action));

    //Иногда все экшены в массиве непригодны для batchActions
    if(actionsToBatch.length) dispatch(batchActions(actionsToBatch));
  };
