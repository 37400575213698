import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';


import moment from 'moment';

import cn from 'classnames';
import { SimpleSummary } from '../../common/SimpleSummary/SimpleSummary';
import {
  EntitiesInBatchAmountLabelTrans,
  EntityBatchLabelTrans,
  EntityLabelTrans,
  OrderLabelTrans,
  SheetLabelTrans,
  SheetPlannedStartDateLabelTrans,
  SheetPlannedStopDateLabelTrans,
  WithoutOrderLabelTrans,
} from '../../../utils/commonTransComponents';
import { getEntityCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


const getFormattedDateValue = date => {
  if(!date) return;

  return moment(date).format(FORMAT_SHORT_TIME);
};

/*
* Схема информационных рядов, которые представляют собой основную информацию, которая отображается более крупным шрифтом
* в самом верху
* */
const ENTITY_SUMMARY_SCHEMA_WITHOUT_LABEL = {
  key: 'combinedEntityInfo',
  rowIdentity: 'combined-entity-info',
  getCustomValue: ({ entityIdentity, entityCode, entityName }) => {
    if(!entityIdentity) return;

    return getEntityCombinedName({
      identity: entityIdentity,
      code: entityCode,
      name: entityName,
    });
  },
};
const ENTITY_SUMMARY_SCHEMA_WITH_LABEL = {
  ...ENTITY_SUMMARY_SCHEMA_WITHOUT_LABEL,
  label: EntityLabelTrans,
};

/*
* Схема для информационных рядов, которые представляют собой дополнительную информацию, которая отображается более
* мелким шрифтом под основной инфомрацией
* */
const SHEET_SECONDARY_SUMMARY_SCHEMA = [
  {
    key: 'sheetIdentity',
    label: SheetLabelTrans,
    rowIdentity: 'sheet-identity',
  },
  {
    key: 'entityBatchIdentity',
    label: EntityBatchLabelTrans,
    rowIdentity: 'entity-batch-identity',
  },
  {
    key: 'entitiesInBatchAmount',
    label: EntitiesInBatchAmountLabelTrans,
    rowIdentity: 'entities-in-batch-amount',
  },
  {
    key: 'orderName',
    label: OrderLabelTrans,
    rowIdentity: 'order-name',
    getCustomValue: ({ orderName }) => {
      if(orderName === null)
        return WithoutOrderLabelTrans;

      return orderName;
    },
  },
  {
    key: 'startDate',
    label: SheetPlannedStartDateLabelTrans,
    rowIdentity: 'start-date',
    getCustomValue: ({ startDate }) => getFormattedDateValue(startDate),
  },
  {
    key: 'stopDate',
    label: SheetPlannedStopDateLabelTrans,
    rowIdentity: 'stop-date',
    getCustomValue: ({ stopDate }) => getFormattedDateValue(stopDate),
  },
];

export class SheetSummary extends Component {

  static propTypes = {
    className: PropTypes.string,
    entityIdentity: PropTypes.string,
    entityCode: PropTypes.string,
    entityName: PropTypes.string,
    sheetIdentity: PropTypes.string,
    entitiesInBatchAmount: PropTypes.number,
    entityBatchIdentity: PropTypes.string,
    orderName: PropTypes.string,
    startDate: PropTypes.string,
    stopDate: PropTypes.string,
    withEntityLabel: PropTypes.bool,
  };

  render() {
    const{
      className,
      entityIdentity,
      entityCode,
      entityName,
      sheetIdentity,
      entitiesInBatchAmount,
      entityBatchIdentity,
      orderName,
      startDate,
      stopDate,
      withEntityLabel,
    } = this.props;
    return (
      <SimpleSummary
          className={
            cn(
              'sheet-summary',
              className,
            )
          }
          summaryData={{
            entityIdentity,
            entityCode,
            entityName,
            sheetIdentity,
            entitiesInBatchAmount,
            entityBatchIdentity,
            orderName,
            startDate,
            stopDate,
          }}
          primarySummarySchema={
            withEntityLabel ?
            [ENTITY_SUMMARY_SCHEMA_WITH_LABEL] :
            [ENTITY_SUMMARY_SCHEMA_WITHOUT_LABEL]
          }
          secondarySummarySchema={SHEET_SECONDARY_SUMMARY_SCHEMA}
      />
    );
  }
}
