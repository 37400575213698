import { createSelector } from 'reselect';
import {
  entitiesForModelSelectorFactory,
  entitiesListForModelSelectorFactory,
  entitySelector,
} from '../reducers/entities/selectors';
import { SETTINGS_MODEL } from '../constants/models';
import _get from 'lodash/get';
import {
  SHEET_OPERATION_MAIN_SETTINGS_ID,
  SHEET_OPERATION_SETTINGS_INITIAL_STATE,
} from '../components/AdminApp/SheetOperationSettings/constants';
import _pickBy from 'lodash/pickBy';
import {
  WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_ID, WORKER_TASKS_ADMIN_FILTERS_SETTINGS_INITIAL_STATE,
} from '../components/AdminApp/WorkerTasksAdminFiltersSettings/constants';

export const settingsEntitiesSelector = entitiesForModelSelectorFactory(SETTINGS_MODEL);

export const settingsEntitiesListSelector = entitiesListForModelSelectorFactory(SETTINGS_MODEL);

export const settingsEntitiesListForGroupSelector = createSelector(
  settingsEntitiesListSelector,
  (_, { group }) => group,
  (settingsEntitiesList, groupFromArgs) => settingsEntitiesList.filter(({ group }) => groupFromArgs === group),
);

export const settingsEntitiesForGroupSelector = createSelector(
  settingsEntitiesSelector,
  (_, { group }) => group,
  (settingsEntities, groupFromArgs) =>  _pickBy(
      settingsEntities,
      ({ group }) => group === groupFromArgs,
    ),

);

export const sheetOperationSettingsSelector = createSelector(
  state => entitySelector(state, {
    model: SETTINGS_MODEL,
    id: SHEET_OPERATION_MAIN_SETTINGS_ID,
  }),
  settingsEntity => _get(settingsEntity, 'value') || SHEET_OPERATION_SETTINGS_INITIAL_STATE,
);

export const workerTasksAdminFiltersSettingsSelector = createSelector(
  state => entitySelector(state, {
    model: SETTINGS_MODEL,
    id: WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_ID,
  }),
  settingsEntity => _get(settingsEntity, 'value') || WORKER_TASKS_ADMIN_FILTERS_SETTINGS_INITIAL_STATE,
);