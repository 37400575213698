export const SET_DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS = 'SET_DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS';
export const SET_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME = 'SET_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME';
export const CLEAR_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME = 'CLEAR_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME';
export const CLEAR_ALL_DEFAULT_SHEETS_PARTS_AND_MATERIALS_TO_CONSUME = 'CLEAR_ALL_DEFAULT_SHEETS_PARTS_AND_MATERIALS_TO_CONSUME';

export const setDefaultSheetsIdsWithEnoughPartsAndMaterials = sheetsIds => ({
  type: SET_DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS,
  sheetsIds,
});

export const setDefaultSheetPartsAndMaterialsToConsume = (sheetId, dataToConsume) => ({
  type: SET_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME,
  sheetId,
  dataToConsume,
});

export const clearDefaultSheetPartsAndMaterialsToConsume = sheetId => ({
  type: CLEAR_DEFAULT_SHEET_PARTS_AND_MATERIALS_TO_CONSUME,
  sheetId,
});

export const clearAllDefaultSheetsPartsAndMaterialsToConsume = () => ({
  type: CLEAR_ALL_DEFAULT_SHEETS_PARTS_AND_MATERIALS_TO_CONSUME,
});