import {
  SET_MASTER_TASKS_TO_DO_ADDITIONAL_FILTERS,
} from './actions';
import { MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY } from '../../../constants/tasks';

const MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_INITIAL_STATE = [
  MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.ALL_TASKS,
];

export const masterTasksToDoAdditionalFilters = (
  state = [...MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_INITIAL_STATE],
  action,
) => {
  switch(action.type) {
  case SET_MASTER_TASKS_TO_DO_ADDITIONAL_FILTERS:
    return action.filters;
  default:
    return state;
  }
};