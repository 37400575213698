import { createSelector } from 'reselect';

import { workerAppStateSelector } from '../selectors';

import { fieldComparatorFactory, stringComparator } from '@bfg-frontend/utils/lib/array';


const identityFieldComparator = fieldComparatorFactory(stringComparator, 'identity');

export const tasksOwnersStateSelector = state => workerAppStateSelector(state).tasksOwners;

export const departmentsWithTasksEntitiesSelector = state => tasksOwnersStateSelector(state).departments;

export const departmentsWithTasksListSortedByIdentitySelector = createSelector(
  departmentsWithTasksEntitiesSelector,
  (entities = {}) => Object
    .values(entities)
    .sort(identityFieldComparator),
);

/*
* Чтобы хоть немного сократить нейминг, сокращаем equipmentClasses до eqClInDep и до eqClForDep
* */
export const eqClInDepsWithTasksEntitiesSelector =
    state => tasksOwnersStateSelector(state).equipmentClassesInDepartments;

export const eqClForDepWithTasksEntitiesSelector = (state, { departmentId }) =>
  eqClInDepsWithTasksEntitiesSelector(state)[departmentId];

export const eqClForDepWithTasksListSortedByIdentitySelector = createSelector(
  eqClForDepWithTasksEntitiesSelector,
  (entities = {}) => Object
    .values(entities)
    .sort(identityFieldComparator),
);
