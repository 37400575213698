import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import { SheetOperationsTable } from '../../PlannerApp/SheetOperationsTable/SheetOperationsTable';
import PropTypes from 'prop-types';
import { SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ } from '../../PlannerApp/SheetOperationsTable/SheetOperationsTable.types';
import {
  FUNC_IS_REQUIRED_TYPE,
  SHEET_OPERATION_STATUS_TYPE, SHEET_SUMMARY_TYPE,
} from '../../../constants/propTypes';
import '../../Sheets/SheetOperationTransactionsTable/style.css';

import { SheetOperationReviewDialogContainer } from '../../common/SheetOperationReviewDialog/SheetOperationReviewDialogContainer';
import _constant from 'lodash/constant';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../hoc/withPermissionsManager/constants';
import Button from '@mui/material/Button';
import { ResumeSheetDialog } from '../ResumeSheetDialog/ResumeSheetDialog';

import './style.css';
import { sheetOperationStatusRowStyle } from '../../../tableProperties/rowStyles/sheetOperationRowStyles';
import { SimpleInfoRow } from '../../common/SimpleInfoRow/SimpleInfoRow';
import { SimpleHelpAlert } from '../../common/SimpleHelpAlert/SimpleHelpAlert';
import { ResumeToProductionLabelTrans } from '../../../utils/commonTransComponents';


const isAlwaysTrueCb = _constant(true);

export class PausedSheetReviewContent extends Component {

  static propTypes = {
    sheetOperationsTableId: PropTypes.string.isRequired,
    sheetOperationsTableData: PropTypes.arrayOf(
      PropTypes.shape({
        ...SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ,
        status: SHEET_OPERATION_STATUS_TYPE.isRequired,
        progress: PropTypes.number.isRequired,
        sheetNote: PropTypes.string,
      }),
    ),
    fetchSheetOperations: FUNC_IS_REQUIRED_TYPE,
    sheetToReviewData: SHEET_SUMMARY_TYPE,
    resumeSheet: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  constructor(props) {
    super(props);
    this.state = {
      isResumeSheetDialogOpen: false,
      selectedSheetOperationData: null,
    };
  };

  _renderPausedSheetNote = () => {

    const {
      sheetToReviewData: {
        sheetNote,
      },
    } = this.props;

    return(
      <SimpleHelpAlert
          className="paused-sheet-review-content__paused-sheet-note"
          content={
            <SimpleInfoRow
                labelClassName="paused-sheet-review-content__paused-sheet-note-label"
                label={
                  <Trans id="paused_sheet@pause_reason">
                    Причина остановки МЛ
                  </Trans>
                }
                value={sheetNote}
                emptyValue={
                  <Trans id="paused_sheet@pause_reason_not_defined">
                    Не указана
                  </Trans>
                }
            />
        }
      />
    );
  };


  _renderResumeSheetButton = () =>
    <div className="paused-sheet-review-content__resume-button">
      <Button
          color="secondary"
          variant="contained"
          onClick={this._openResumeSheetDialog}
      >
        {ResumeToProductionLabelTrans}
      </Button>
    </div>;

  _openResumeSheetDialog = () => this.setState({ isResumeSheetDialogOpen: true });

  _renderSheetOperationsTable = () => {
    const {
      sheetOperationsTableId,
      sheetOperationsTableData,
      fetchSheetOperations,
    } = this.props;

    return(
      <SheetOperationsTable
          sheetOperationsTableId={sheetOperationsTableId}
          sheetOperationsTableData={sheetOperationsTableData}
          fetchSheetOperations={fetchSheetOperations}
          onSheetOperationRowClick={this._handleSheetOperationRowClick}
          getSheetOperationsTableRowStyle={sheetOperationStatusRowStyle}
      />
    );
  };

  _handleSheetOperationRowClick = (_, sheetOperationData) => {

    const {
      sheetToReviewData: {
        entityIdentity,
        entityCode,
        entityName,
        sheetIdentity,
        entityBatchId,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },
    } = this.props;

    this.setState({
      selectedSheetOperationData: {
        ...sheetOperationData,
        entityIdentity,
        entityCode,
        entityName,
        entityBatchId,
        sheetIdentity,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },

    });
  };

  _renderResumeSheetDialog = () => {
    const {
      sheetToReviewData,
      resumeSheet,
    } = this.props;
    const {
      isResumeSheetDialogOpen,
    } = this.state;
    return(
      <ResumeSheetDialog
          isOpen={isResumeSheetDialogOpen}
          onSubmit={resumeSheet}
          closeDialog={this._handleResumeSheetDialogClose}
          sheetToResumeData={{ ...sheetToReviewData }}
      />
    );
  };

  _handleResumeSheetDialogClose = () => this.setState({ isResumeSheetDialogOpen: false });

  _renderSheetOperationReviewDialog = () => {
    const {
      selectedSheetOperationData,
    } = this.state;

    if(selectedSheetOperationData === null) return;

    return(
      <SheetOperationReviewDialogContainer
          closeDialog={this._handleSheetOperationReviewDialogClose}
          sheetOperationData={selectedSheetOperationData}
          areStatusChangeButtonsHidden={isAlwaysTrueCb}
          isPauseSheetAdditionalActionHidden={isAlwaysTrueCb}
          isSplitEntityBatchAdditionalActionHidden={isAlwaysTrueCb}
          isAssigneesChangeDisabled={isAlwaysTrueCb}
          isEquipmentChangeDisabled={isAlwaysTrueCb}
          isNoteChangeDisabled={isAlwaysTrueCb}
          isConsumeEntitiesActionHidden={isAlwaysTrueCb}
      />
    );
  };

  _handleSheetOperationReviewDialogClose = () => this.setState({ selectedSheetOperationData: null });

  render() {
    return (
      <div className="paused-sheet-review-content">
        {this._renderPausedSheetNote()}
        {
          this.props.PermissionsManager.isGranted(PERMISSION.PAUSE_OR_RESUME_SHEET) ?
            this._renderResumeSheetButton() :
            null
        }
        {this._renderSheetOperationsTable()}
        {this._renderResumeSheetDialog()}
        {this._renderSheetOperationReviewDialog()}
      </div>
    );
  }
}