import PropTypes from 'prop-types';
import React from 'react';

import MoreIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import _isFunction from 'lodash/isFunction';

import cn from 'classnames';

import './style.css';

import { TableCellRenderer } from '../TableCellRenderer';
import { MATERIAL_UI_STYLE_COLOR } from '../../../../constants/materialUI';




/**
 * Представляет собой выпадающее меню с различными действиями, переданными в свойстве toolsOptions.
 * toolsOptions - массив опций, например,
 * toolsOptions: [
 * {
 *  id: 'setBaseOptionId',
 *  title: 'Сделать базовым',
 *  onClick: itemId => dispatch(setBaseAnalytic(itemId)),
 *  isVisible: (itemId, item) => !item.isBase
 * },
 * {
 *  id: 'deleteOptionId',
 *  title: 'Удалить',
 *  onClick: itemId => dispatch(deleteItem(itemId))
 *  isDisabled: itemId => this.equipmentVariationIsUsedByAnalytics(itemId).length,
 *  getTooltip: itemId => 'подсказка',
 * }
 * ]
 *
 * Возможные параметры опции:
 * id - строковый идентификатор пункта меню - обязательный
 * title - название пункта меню - обязательный
 * onClick - функция callback, которая будет вызвана при клике на пункт меню - обязательный.
 * isVisible - функция, если возвращает false, то опция будет скрыта - не обязательный.
 * isDisabled - функция, если возвращает false, то опция будет не кликабельна - не обязательный.
 * getTooltip - функция, если возвращает не пустую строку, то будет появляться подсказка при наведении на данный пункт меню - не обязательный.
 * isActive - функция, если возвращает true, то данный пункт меню будет выделен как активный. При повторном клике функция должна его деактивировать. - не обязательный.
 */
export class TableOptionsButtonRenderer extends TableCellRenderer {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  static getViewValue() { return null; };

  setAnchorEl = anchorEl => this.setState({ anchorEl });

  handleClick = event => {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  isActive(option) {
    const {
      itemId,
      data,
    } = this.props;

    const { isActive } = option;

    return _isFunction(isActive) ? isActive(itemId, data) : false;
  }

  isDisabled(option) {
    const {
      itemId,
      data,
    } = this.props;

    const { isDisabled } = option;

    return  _isFunction(isDisabled) ? isDisabled(itemId, data) : false;
  }

  getVisibleOptions() {
    const {
      itemId,
      data,
      toolsOptions,
    } = this.props;

    return toolsOptions
      .filter(option => {
        const { isVisible } = option;

        return !_isFunction(isVisible) || isVisible(itemId, data);
      });
  }

  optionClickHandlerFactory = (option, isDisabled, isActive) => {
    if(isDisabled) return;

    const {
      itemId,
      data,
    } = this.props;

    const { onClick } = option;

    return e => {
      e.stopPropagation();
      this.handleClose();
      _isFunction(onClick) && onClick(itemId, data, !isActive);
    };
  };

  render() {
    const {
      anchorEl,
    } = this.state;

    const {
      itemId,
      data,
    } = this.props;

    const isMenuOpen = !!anchorEl;

    return (
      <div
          className="table-options-button-renderer"
          onClick={e => e.stopPropagation()}
      >
        <IconButton
            className="table-options-button-renderer__menu-btn"
            onClick={this.handleClick}
            size="small"
            color={isMenuOpen ? MATERIAL_UI_STYLE_COLOR.PRIMARY : MATERIAL_UI_STYLE_COLOR.INHERIT}
        >
          <MoreIcon/>
        </IconButton>
        <Menu
            className="table-options-button-renderer__menu"
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            elevation={4}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
        >
          {
            this.getVisibleOptions()
              .map(option => {
                const {
                  id,
                  getTooltip,
                  title,
                } = option;

                const isDisabled = this.isDisabled(option);
                const isActive = this.isActive(option);

                const tooltip = _isFunction(getTooltip) && getTooltip(itemId, data);

                const MenuItemMarkup = (
                  <MenuItem
                      className={
                        cn(
                          'table-options-button-renderer__menu-item',
                          {
                            'table-options-button-renderer__menu-item--active': isActive,
                            'table-options-button-renderer__menu-item--disabled': isDisabled,
                          },
                        )
                      }
                      onClick={this.optionClickHandlerFactory(option, isDisabled, isActive)}
                  >
                    {title}
                  </MenuItem>
                );

                return (
                  <div key={id}>
                    {
                      tooltip ?
                        <Tooltip
                            title={
                              <div className="table-options-button-renderer__menu-item-tooltip">
                                {tooltip}
                              </div>
                            }
                            placement="left"
                        >
                          {MenuItemMarkup}
                        </Tooltip> :
                        MenuItemMarkup
                    }
                  </div>
                );
              })
          }
        </Menu>
      </div>
    );
  }
}

TableOptionsButtonRenderer.propTypes = {
  toolsOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    isVisible: PropTypes.func,
    isDisabled: PropTypes.func,
    isActive: PropTypes.func,
    getTooltip: PropTypes.func,
  })),
  itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.objectOf(PropTypes.any),
};

TableOptionsButtonRenderer.defaultProps = {
  toolsOptions: [],
};
