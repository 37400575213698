import PropTypes from 'prop-types';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';


export const ADMIN_APP_ACTIVE_USERS_TABLE_ID = 'ADMIN_APP_ACTIVE_USERS_TABLE_ID';
export const ADMIN_APP_DEACTIVATED_USERS_TABLE_ID = 'ADMIN_APP_DEACTIVATED_USERS_TABLE_ID';

export const USERS_TABLE_DATA_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    id: NUMBER_OR_STRING_TYPE,
    name: PropTypes.string,
    lastName: PropTypes.string,
    patronymicName: PropTypes.string,
    identity: PropTypes.string,
    userRoles: PropTypes.arrayOf(PropTypes.shape({
      id: NUMBER_OR_STRING_TYPE.isRequired,
      name: PropTypes.string.isRequired,
    })),
    createStamp: PropTypes.string,
  }),
);