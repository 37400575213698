export const SET_TASKS_DEPARTMENT_FILTER = 'SET_TASKS_DEPARTMENT_FILTER';
export const RESET_TASKS_DEPARTMENT_FILTER = 'RESET_TASKS_DEPARTMENT_FILTER';
export const SET_TASKS_EQUIPMENT_CLASS_FILTER = 'SET_TASKS_EQUIPMENT_CLASS_FILTER';
export const RESET_TASKS_EQUIPMENT_CLASS_FILTER = 'RESET_TASKS_EQUIPMENT_CLASS_FILTER';
export const RESET_ALL_TASKS_MAIN_FILTERS = 'RESET_ALL_TASKS_MAIN_FILTERS';


export const setTasksDepartmentFilter = departmentEntity => ({
  type: SET_TASKS_DEPARTMENT_FILTER,
  departmentEntity,
});

export const resetTasksDepartmentFilter = () => ({
  type: RESET_TASKS_DEPARTMENT_FILTER,
});

export const setTasksEquipmentClassFilter = equipmentClassEntity => ({
  type: SET_TASKS_EQUIPMENT_CLASS_FILTER,
  equipmentClassEntity,
});

export const resetTasksEquipmentClassFilter = () => ({
  type: RESET_TASKS_EQUIPMENT_CLASS_FILTER,
});

export const resetAllTasksMainFilters = () => ({
  type: RESET_ALL_TASKS_MAIN_FILTERS,
});