import { sendActionToServer } from '../api/index';
import { SERVER_ACTION_POINT } from '../constants/serverActions';
import { getErrorMessage, showError } from '../api/requestHandlers/errorHandlers/errorHandlers';
import {
  CREATE_EQUIPMENT_ERRORS,
  SET_ENTITY_NOTE_ERRORS,
} from '../api/requestHandlers/errorHandlers/errorMaps';

import humps from 'humps';
import { SERVER_ERROR_IDENTITY, SERVER_ERROR_SOURCE } from '../constants/serverErrors/index';
import { transformRequestBodyFieldsServerErrors } from '../constants/serverErrors/requestBodyFieldsServerErrors';
import { broadcastEventMessage } from '../api/socketApi/broadcastApi/broadcast/broadcastEventMessage';
import { EQUIPMENT_ENTITY_CREATED_EVENT_TYPE } from '../constants/sockets';


const CREATE_EQUIPMENT_ENTITY_ACTION_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const createEquipmentEntity = ({ name, identity, departmentId, equipmentClassId }, requestOptions = {}) =>
  dispatch => dispatch(sendActionToServer(
    SERVER_ACTION_POINT.CREATE_EQUIPMENT_ENTITY,
    {
      data: {
        name,
        identity,
        departmentId,
        equipmentClassId,
      },
    },
    {
      ...CREATE_EQUIPMENT_ENTITY_ACTION_DEFAULT_REQUEST_OPTIONS,
      ...requestOptions,
    },
  ))
    .catch(  ({ response }) => {
      const {
        source,
        identity,
      } = response;

      if(source === SERVER_ERROR_SOURCE.WEBSERVER && identity === SERVER_ERROR_IDENTITY.REQUEST_JSON_STRUCTURE)
        return Promise.resolve({
          validationErrors: transformRequestBodyFieldsServerErrors(response),
        });

      if(source === SERVER_ERROR_SOURCE.IO && identity === SERVER_ERROR_IDENTITY.EQUIPMENT_IDENTITY_ALREADY_EXISTS) {
        return Promise.resolve({
          validationErrors: {
            identity: [
              {
                message: SERVER_ERROR_IDENTITY.EQUIPMENT_IDENTITY_ALREADY_EXISTS,
                data: null,
              },
            ],
          },
        });
      }

      const errorMsg = getErrorMessage(response, CREATE_EQUIPMENT_ERRORS);
      showError(errorMsg);
      return Promise.reject(response);
    });

export const broadcastEquipmentEntityCreated = equipmentEntity =>
  broadcastEventMessage(
    EQUIPMENT_ENTITY_CREATED_EVENT_TYPE,
    equipmentEntity,
  );


const SET_ENTITY_NOTE_ACTION_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const setEntityNote = (entityModel, entityId, note, requestOptions = {}) =>
  dispatch => dispatch(sendActionToServer(
    SERVER_ACTION_POINT.SET_ENTITY_NOTE,
    {
      data: {
        id: entityId,

        /*
        * Значения в теле запроса не декамелайзятся по умолчанию, а для параметра наименования модели это, конечно же,
        * важно, на сервер нужно отправить наименование модели в snake_case
        * */
        model: humps.decamelize(entityModel),
        note,
      },
    },
    {
      ...SET_ENTITY_NOTE_ACTION_DEFAULT_REQUEST_OPTIONS,
      ...requestOptions,
    },
  ))
    .catch(({ response, status }) => {
      if (!response) return Promise.reject(status);

      const errorMsg = getErrorMessage(response, SET_ENTITY_NOTE_ERRORS);
      showError(errorMsg);
      return Promise.reject({ response, status });
    });