import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

import {
  TOOLS_COLUMN_NAME,
} from '../../../constants/table';
import {
  OBJECT_OF_ANY_TYPE,
  TABLE_MENU_OPTIONS_TYPE,
} from '../../../constants/propTypes';

import _isFunction from 'lodash/isFunction';
import _partial from 'lodash/partial';
import _size from 'lodash/size';


const DEFAULT_TABLE_PAGE_SIZES_ARRAY = [20, 40, 80, 120];

const DefaultVisibilityMenuOptionIconComponent = () => <span>&#10003;</span>;

export class TableMenuWrapper extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      showTableSummary: false,
    };
  }

  setTableSummaryState = showTableSummary => this.setState({ showTableSummary });

  _showTableSummary = _partial(this.setTableSummaryState, true);
  _hideTableSummary = _partial(this.setTableSummaryState, false);

  _onIsMenuOpenChange = e => {
    e.stopPropagation();
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  }

  _closeMenu = () => this.setState({ isMenuOpen: false });

  _renderTableMenuToggleButton = () => {
    const {
      tableOpenMenuButtonComponentPropsAdapter,
      TableOpenMenuButtonComponent,
      titles,
      icons,
    } = this.props;

    const componentPropsFromFactory = {
      onClick: this._onIsMenuOpenChange,
      titles: titles.openMenuButton,
      icons: icons.openMenuButton,
    };

    return (
      <TableOpenMenuButtonComponent
          {...tableOpenMenuButtonComponentPropsAdapter(componentPropsFromFactory)}
          className="table-menu__dropdown-btn"
      />
    );
  };

  _renderTableMenuOptions = () => {
    const { isMenuOpen } = this.state;

    if(!isMenuOpen) return null;

    const {
      TableMenuComponent,
      tableMenuComponentPropsAdapter,
      titles,
      icons,
    } = this.props;

    const componentPropsFromFactory = {
      menu: this._prepareMenuOptions(),
      onClickAway: this._closeMenu,
      titles: titles.menu,
      icons: icons.menu,
    };

    return(
      <TableMenuComponent
          {...tableMenuComponentPropsAdapter(componentPropsFromFactory)}
          className="table-dropdown-menu"
      />
    );
  };

  _prepareMenuOptions = () => {
    const {
      menuOptions,
      tableProps,
    } = this.props;


    const updatedMenuOptions = [
      ...this._getDefaultMenuOptions(),
      ...menuOptions,
    ];

    return updatedMenuOptions
      .filter(({ isVisible }) => !_isFunction(isVisible) || isVisible(tableProps))
      .map(this._createOnSelectWithInjectedTableProps);
  };

  _getDefaultMenuOptions = () => {
    const {
      tableProps: {
        tableMenu: {
          disableVisibilityOption,
          disableSummaryOption,
          disablePageSizeOption,
        } = {},
      },
    } = this.props;

    const defaultTableMenuOptionsArray = [];

    if(!disableVisibilityOption) defaultTableMenuOptionsArray.push(this._getColumnVisibilityMenuOption());

    if(!disablePageSizeOption) defaultTableMenuOptionsArray.push(this._getPageSizeMenuOption());

    if (!disableSummaryOption) defaultTableMenuOptionsArray.push(this._getSummaryMenuOption());

    return defaultTableMenuOptionsArray;
  };


  _getColumnVisibilityMenuOption = () => {
    const {
      tableProps: {
        schemaFields,
        changeColumnVisibility,
      },
      titles: {
        menu: {
          visibilityOption: visibilityOptionTitle,
        } = {},
      },
      icons: {
        menu: {
          visibilityOption: visibilityOptionIconComponent,
          visibleColumn: visibleColumnIconComponent = DefaultVisibilityMenuOptionIconComponent,
          invisibleColumn: invisibleColumnIconComponent = null,
        } = {},
      },
    } = this.props;
    return {
      optionId: 'table-columns-visibility-option',
      optionTitle: visibilityOptionTitle,
      optionIconComponent: visibilityOptionIconComponent,
      subMenu: {
        subMenuClassName: 'table-columns-visibility-option-sub-menu',
        subMenuOptions: Object
          .values(schemaFields)
          .filter(({ columnName, display }) => display && columnName !== TOOLS_COLUMN_NAME)
          .map(({ show, columnName, displayName }) => ({
            optionId: `${columnName}-column-visibility-option`,
            optionTitle: displayName,
            optionIconComponent: show !== false ? visibleColumnIconComponent : invisibleColumnIconComponent,
            onSelect: () => changeColumnVisibility(columnName, show === undefined ? false : !show),
          })),
      },
    };
  };

  _getPageSizeMenuOption = () => {
    const {
      tableProps: {
        changePageSize,
        pageSize: currentPageSize,
        tableMenu: {
          availableTablePageSizesArray = DEFAULT_TABLE_PAGE_SIZES_ARRAY,
        } = {},
      },
      titles: {
        menu: {
          pageSizeOption: pageSizeOptionTitle,
        } = {},
      },
      icons: {
        menu: {
          pageSizeOption: pageSizeOptionIconComponent,
        } = {},
      },
    } = this.props;

    return {
      optionId: 'table-columns-page-size-option',
      optionTitle: pageSizeOptionTitle,
      optionIconComponent: pageSizeOptionIconComponent,
      subMenu: {
        subMenuClassName: 'table-columns-page-size-option-sub-menu',
        subMenuOptions: availableTablePageSizesArray
          .map(pageSize => ({
            optionId: `${pageSize}-column-page-size-option`,
            optionTitle: `${pageSize}`,
            isActive: () => pageSize === currentPageSize,
            onSelect: (_, closeCb) => {
              changePageSize(pageSize);
              closeCb();
            },
          })),
      },
    };
  };

  _getSummaryMenuOption = () => {
    const {
      titles: {
        menu: {
          summaryOption: summaryOptionTitle,
        } = {},
      },
      icons: {
        menu: {
          summaryOption: summaryOptionIconComponent,
        } = {},
      },
    } = this.props;

    return {
      optionId: 'table-summary-option',
      optionTitle: summaryOptionTitle,
      optionIconComponent: summaryOptionIconComponent,
      isVisible: tableProps => _size(tableProps.filteredAndSortedAllRowsData) > 0,
      onSelect: (_, closeCb) => {
        this._showTableSummary();
        closeCb();
      },
    };
  };


  _createOnSelectWithInjectedTableProps = option => {
    const {
      onSelect,
      subMenu,
    } = option;

    const onSelectWithInjectedTableProps = e => {
      e.stopPropagation();
      return onSelect(this.props.tableProps, this._closeMenu, e);
    };

    if(!subMenu)
      return {
        ...option,
        onSelect: onSelectWithInjectedTableProps,
      };

    const updatedSubMenu = {
      ...subMenu,
      subMenuOptions: subMenu.subMenuOptions.map(this._createOnSelectWithInjectedTableProps),
    };

    if(!onSelect)
      return {
        ...option,
        subMenu: updatedSubMenu,
      };

    return {
      ...option,
      onSelect: onSelectWithInjectedTableProps,
      subMenu:updatedSubMenu,
    };
  };

  _renderTableSummary = () => {
    const { showTableSummary } = this.state;

    if (!showTableSummary) return null;

    const {
      tableSummaryModalComponentPropsAdapter,
      TableSummaryModalComponent,
      tableSummaryModalClassName,
      tableProps: {
        tableSummary = {},
      },
      titles,
      icons,
    } = this.props;

    const componentPropsFromFactory = {
      closeSummaryModal: this._hideTableSummary,
      icons: icons.summary,
      titles: titles.summary,
      tableSummaryModalClassName,
    };

    return(
      <TableSummaryModalComponent
          {...tableSummaryModalComponentPropsAdapter(componentPropsFromFactory)}
          tableSummary={tableSummary}
      />
    );
  };

  render() {
    return (
      <div className="table-menu">
        <div>
          {this._renderTableMenuToggleButton()}
          {this._renderTableMenuOptions()}
          {this._renderTableSummary()}
        </div>
      </div>
    );
  }
}

TableMenuWrapper.propTypes = {
  menuOptions: TABLE_MENU_OPTIONS_TYPE.isRequired,
  tableProps: OBJECT_OF_ANY_TYPE,
  tableSummaryModalClassName: PropTypes.string,
  TableMenuComponent: PropTypes.elementType,
  tableMenuComponentPropsAdapter: PropTypes.func,
  TableSummaryComponent: PropTypes.elementType,
  tableSummaryComponentPropsAdapter: PropTypes.func,
  TableOpenMenuButtonComponent: PropTypes.elementType,
  tableOpenMenuButtonComponentPropsAdapter: PropTypes.func,
  titles: OBJECT_OF_ANY_TYPE,
  icons: OBJECT_OF_ANY_TYPE,
  TableSummaryModalComponent: PropTypes.elementType,
  tableSummaryModalComponentPropsAdapter: PropTypes.func,
};
