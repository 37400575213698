import {
  SET_APP_USER_DATA,
  SET_MAIN_PLANNING_SESSION,
  SET_APP_LANGUAGE,
  SET_APP_CRITICAL_ERROR,
} from './actions';


const APP_INITIAL_STATE = {
  userData: null,
  mainPlanningSession: null,

  /*
  * Сами каталоги сообщений не хранятся в redux store, т.к. это на plain объект, подробней см. в TranslationsProvider.
  * Хранить сам язык удобней в store, т.к. он может быть необходим в различных компонентах.
  * (так рекомендует в примере и сам lingui.js, язык в store, каталоги в локальном state провайдера)
  *
  * Локализация приложения сейчас задаётся только в файле конфигурации и не изменяется из интерфейса. Более подробнее
  * в комментарии к LOCALE в src/appConfig/config.js
  * */
  language: window.config.LOCALE,
  criticalError: null,
};

export const appState = (state = { ...APP_INITIAL_STATE }, action) => {
  switch(action.type) {
  case SET_APP_USER_DATA:
    return {
      ...state,
      userData: { ...action.userData },
    };
  case SET_MAIN_PLANNING_SESSION:
    return {
      ...state,
      mainPlanningSession: { ...action.mainPlanningSession },
    };
  case SET_APP_LANGUAGE:
    return {
      ...state,
      language: action.language,
    };
  case SET_APP_CRITICAL_ERROR:
    return {
      ...state,
      criticalError: action.criticalError,
    };
  default:
    return state;
  }
};
