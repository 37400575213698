import { Transformer } from '../Transformer';
import { SECS_IN_HOUR } from '../../constants/magics';
import { divDecimals } from '../../utils/decimal';


export class SheetOperation extends Transformer {

  static transformToState(item) {
    const {
      id,
      calculationSessionId,
      calculationIdentity,
      entityRouteSheetId,
      startDate,
      stopDate,
      status,
      progress,
      executorId,
      operationId,
      departmentId,
      equipmentClassId,
      equipmentId,
      note,
      prodTime,
    } = item;


    return {
      id,
      mainPlanningSessionId: calculationSessionId,
      identity: calculationIdentity,
      sheetId: entityRouteSheetId,
      startDate,
      stopDate,
      status,
      progress,
      executorId,
      operationId,
      departmentId,
      equipmentClassId,
      equipmentId,
      note,

      /*
      * в приложении времена везде фигурируют в часах, поэтому сразу в трансформере преобразовываем значение к
      * желаемому виду
      * */
      prodTime: divDecimals(prodTime, SECS_IN_HOUR),
    };
  };

}
