import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'connected-react-router';

import {
  resetTasksDepartmentFilter,
  setTasksDepartmentFilter,
} from '../../../reducers/workerApp/tasksMainFilters/actions';

import { WORKER_APP_MAIN_ROUTE } from '../../../constants/routes';

import {
  clearNotifications,
  DEPARTMENT_CHOOSE_SCREEN_NOTIFICATION_ID,
  NOTIFICATION_LEVEL,
  sendNotification,
} from '../../../constants/notification';

import { TasksOwnerChooseScreen } from './TasksOwnerChooseScreen';
import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';
import { fetchDepartmentsWithTasks } from '../../../operations/departments';
import {
  departmentsWithTasksListSortedByIdentitySelector,
} from '../../../reducers/workerApp/tasksOwners/selectors';
import { NoDepartmentsWithTasksTrans, OnlyDepartmentsWithTasksDisplayedTrans } from './constants';


const mapStateToProps = state => ({
  departmentsList: departmentsWithTasksListSortedByIdentitySelector(state),
});

const mapDispatchToProps = {
  push,
  setTasksDepartmentFilter,
  resetTasksDepartmentFilter,
  fetchDepartmentsWithTasks,
};

const mergeProps = (stateProps, dispatchProps) => {
  const { departmentsList } = stateProps;
  const {
    push,
    setTasksDepartmentFilter,
    resetTasksDepartmentFilter,
    fetchDepartmentsWithTasks,
  } = dispatchProps;
  return {
    tasksOwnersList: departmentsList
      .map(departmentEntity => {
        const {
          id,
          name: ownerMainTitle,
          identity: ownerSecondaryTitle,
        } = departmentEntity;
        return {
          id,
          ownerMainTitle,
          ownerSecondaryTitle,
          ownerOriginalData: departmentEntity,
        };
      }),
    resetTasksOwnerInStore: resetTasksDepartmentFilter,
    onSelectTasksOwner: (_, departmentEntity) => {
      setTasksDepartmentFilter(departmentEntity);
      push([WORKER_APP_MAIN_ROUTE, departmentEntity.id].join('/'));
    },
    emptyOwnerListTrans: NoDepartmentsWithTasksTrans,
    showDisplayedTasksOwnersInfoNotification: () =>
      sendNotification(
        OnlyDepartmentsWithTasksDisplayedTrans,
        NOTIFICATION_LEVEL.INFO,
        { timeOut: 0, id: DEPARTMENT_CHOOSE_SCREEN_NOTIFICATION_ID },
      ),
    clearDisplayedTasksOwnersInfoNotification: () =>
      clearNotifications([DEPARTMENT_CHOOSE_SCREEN_NOTIFICATION_ID]),
    fetchDepartmentsWithTasks,
  };
};

export const DepartmentChooseScreenContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchDepartmentsWithTasks, tasksOwnersList }) => {
          if(tasksOwnersList.length > 0) return Promise.resolve();

          return fetchDepartmentsWithTasks();
        },
      },
    ],
  }),
)(TasksOwnerChooseScreen);

DepartmentChooseScreenContainer.displayName = 'DepartmentChooseScreenContainer';