import React from 'react';
import _get from 'lodash/get';

export const FullScreenContext = React.createContext({});


export const SHOW_APP_RELOAD_BUTTONS_ONLY_ON_FULL_SCREEN =
  _get(
    window.config,
    'SHOW_APP_RELOAD_BUTTONS_ONLY_ON_FULL_SCREEN',

    //TODO Заменить дефолтное значение на true, т.к. оно более логично, сейчас реализация для hotfix
    false,
  );