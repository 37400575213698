import PropTypes from 'prop-types';
import { OBJECT_OF_ANY_TYPE } from '../../../constants/propTypes';

export const AUTOCOMPLETE_PROP_TYPES = {
  value: PropTypes.oneOfType([
    OBJECT_OF_ANY_TYPE,
    PropTypes.string,
    PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.arrayOf(PropTypes.oneOfType([OBJECT_OF_ANY_TYPE, PropTypes.string])),
  onChange: PropTypes.func.isRequired,
  onUnmount: PropTypes.func,
  loadOptions: PropTypes.func,
  shouldPreloadData: PropTypes.bool,
  preloadInputValue: PropTypes.string,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  onInputChange: PropTypes.func,
  isMulti: PropTypes.bool,
  title: PropTypes.node,
  error: PropTypes.node,
  showError: PropTypes.bool,
  placeholder: PropTypes.node,
  noOptionsMessage: PropTypes.node,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  reactSelectProps: OBJECT_OF_ANY_TYPE,
  wrapperClassName: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
  styles: PropTypes.objectOf(PropTypes.func),
};

export const AUTOCOMPLETE_DEFAULT_PROPS = {
  value: null,
  isClearable: true,
  isDisabled: false,
  isSearchable: true,
  showError: true,
  reactSelectProps: {},
  shouldPreloadData: false,
  preloadInputValue: '',
  getOptionValue: ({ id }) => id,
  getOptionLabel: ({ name }) => name,
};