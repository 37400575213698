import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

import { SheetSummary } from '../../../SheetSummary/SheetSummary';

import {
  SHEET_TO_START_SUMMARY_TYPE,
  FUNC_IS_REQUIRED_TYPE,
} from '../../../../../constants/propTypes';

import { required } from '../../../../../utils/formValidators/index';

import './style.css';
import { SimpleConfirmDialog } from '../../../../common/SimpleConfirmDialog/SimpleConfirmDialog';
import {
  CancelLabelTrans,
  SheetIdentityLabelTrans,
  StartInProductionLabelTrans,
  StartSheetInProductionLabelTrans,
} from '../../../../../utils/commonTransComponents';

import _get from 'lodash/get';
import { MATERIAL_UI_VARIANT } from '../../../../../constants/materialUI';


const SHEET_IDENTITY_PREFIX = _get(window, ['config', 'DEFAULT_SHEET_IDENTITY_PREFIX'], '');

export class SheetToStartParamsFormDialog extends Component {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSubmit: FUNC_IS_REQUIRED_TYPE,
    closeDialog: FUNC_IS_REQUIRED_TYPE,
    sheetToStartData: SHEET_TO_START_SUMMARY_TYPE.isRequired,
    mainPlanningSessionId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      sheetToStartData: {
        fromState,
        entityBatchIdentity,
      },
      mainPlanningSessionId,
    } = this.props;

    this.state = {
      newSheetIdentity: fromState ?
        entityBatchIdentity :
        `${SHEET_IDENTITY_PREFIX}${entityBatchIdentity}_${mainPlanningSessionId}`,
    };
  }

  _handleSubmitNewSheetStart = () => {
    const { onSubmit } = this.props;
    const { newSheetIdentity } = this.state;
    onSubmit(newSheetIdentity.trim());
  };

  _renderDialogContent = sheetToStartIdentityFieldError =>
    <div className="sheet-to-start-params-form-dialog__content">
      {this._renderSheetToStartSummary()}
      {this._renderSheetToStartIdentityInput(sheetToStartIdentityFieldError)}
    </div>;

  _renderSheetToStartSummary = () =>
    <SheetSummary
        className="sheet-to-start-params-form-dialog__summary"
        withEntityLabel
        {...this.props.sheetToStartData}
    />;

  _renderSheetToStartIdentityInput = sheetToStartIdentityFieldError => {
    const { sheetToStartData: { fromState } } = this.props;
    return (
      <TextField
          disabled={fromState}
          className="sheet-to-start-params-form-dialog__text-field"
          error={!!sheetToStartIdentityFieldError}
          autoFocus
          margin="dense"
          id="sheet-to-start-identity"
          label={this._renderTranslatedSheetToStartIdentityInputLabel()}
          type="text"
          variant={MATERIAL_UI_VARIANT.STANDARD}
          value={this.state.newSheetIdentity}
          fullWidth
          onChange={this._handleSheetToStartIdentityChange}
          helperText={
            this._renderTranslatedSheetToStartIdentityInputHelperText(
              sheetToStartIdentityFieldError,
              fromState,
            )
          }
          InputLabelProps={{
            shrink: true,
          }}
      />
    );
  };

  _renderTranslatedSheetToStartIdentityInputLabel = () => SheetIdentityLabelTrans;

  _renderTranslatedSheetToStartIdentityInputHelperText = (sheetToStartIdentityFieldError, fromState) => {

    if(fromState)
      return (
        <Trans id="sheet_to_start@identity_from_state">
          Партия была загружена в систему как НЗП, но маршрутный лист "В производстве" по ней отсутствует. Для
          корректной работы системы, идентификатор запускаемого маршрутного листа такой партии должен быть равен
          идентификатору самой партии
        </Trans>
      );

    return sheetToStartIdentityFieldError;
  };

  _handleSheetToStartIdentityChange = e => this.setState({ newSheetIdentity: e.target.value });

  render() {
    const { closeDialog, isOpen } = this.props;
    const { newSheetIdentity } = this.state;

    const sheetToStartIdentityFieldError = required(newSheetIdentity);

    return(
      <SimpleConfirmDialog
          className="sheet-to-start-params-form-dialog"
          closeDialog={closeDialog}
          isOpen={isOpen}
          title={StartInProductionLabelTrans}
          confirmBtn={StartSheetInProductionLabelTrans}
          cancelBtn={CancelLabelTrans}
          onConfirm={this._handleSubmitNewSheetStart}
          additionalComponent={this._renderDialogContent(sheetToStartIdentityFieldError)}
          disableConfirm={!!sheetToStartIdentityFieldError}
      />
    );
  }
}
