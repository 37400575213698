import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { pausedSheetsViewFactory } from '../../pausedSheetsViewFactory/pausedSheetsViewFactory';
import { fetchPausedSheets } from '../../../operations/sheets';
import { PAUSED_SHEETS_FILTERS_SCHEMA } from '../../Sheets/sheetsFilters/sheetsFilters';
import { MASTER_APP_PAUSED_SHEETS_TABLE_ID } from '../../../constants/table';
import {
  MASTER_APP_MAIN_ROUTE,
  MASTER_APP_PAUSED_SHEETS_SUB_ROUTE,
  MASTER_APP_SHEETS_SUB_ROUTE,
} from '../../../constants/routes';
import { getMasterAppDepartmentIdsRouteParam } from '../MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import _isEqual from 'lodash/isEqual';


export const MasterPausedSheetsComponentFromFactory = pausedSheetsViewFactory({
  tableId: MASTER_APP_PAUSED_SHEETS_TABLE_ID,
  fetchPausedSheets,
  filtersSchema: PAUSED_SHEETS_FILTERS_SCHEMA,
  getMainRoutePath: ({ departmentIds }) => [
    MASTER_APP_MAIN_ROUTE,
    getMasterAppDepartmentIdsRouteParam(departmentIds),
    MASTER_APP_SHEETS_SUB_ROUTE,
    MASTER_APP_PAUSED_SHEETS_SUB_ROUTE,
  ].join('/'),
});

export class MasterPausedSheets extends Component {
  componentDidUpdate(prevProps) {
    const {
      departmentIds,
    } = this.props;

    if(_isEqual(departmentIds, prevProps.departmentIds)) return;

    const {
      reFetchMasterPausedSheetsRemoteTableData,
      clearMasterTasksRemoteTablesData,
    } = this.props;


    clearMasterTasksRemoteTablesData();

    reFetchMasterPausedSheetsRemoteTableData();
  }

  render() {
    return (
      <MasterPausedSheetsComponentFromFactory departmentIds={this.props.departmentIds}/>
    );
  };
}

MasterPausedSheets.propTypes = {
  departmentIds: PropTypes.arrayOf(PropTypes.number),
  reFetchMasterPausedSheetsRemoteTableData: FUNC_IS_REQUIRED_TYPE,
  clearMasterTasksRemoteTablesData: FUNC_IS_REQUIRED_TYPE,
};