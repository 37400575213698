import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { FUNC_IS_REQUIRED_TYPE } from '../../../../../constants/propTypes';

import './style.css';
import { CancelLabelTrans, SaveLabelTrans } from '../../../../../utils/commonTransComponents';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../../constants/materialUI';

export class EditFormControlButtonsPanel extends Component {

  static propTypes = {
    settingsSaveDisableReasonTrans: PropTypes.node,
    onSettingsEditCancel: FUNC_IS_REQUIRED_TYPE,
    onSettingsSave: FUNC_IS_REQUIRED_TYPE,
  };

  render() {
    const {
      settingsSaveDisableReasonTrans,
      onSettingsEditCancel,
      onSettingsSave,
    } = this.props;
    return(
      <div className="edit-form-control-buttons-panel">
        {
          !!settingsSaveDisableReasonTrans ?
            <div className="edit-form-control-buttons-panel__submit-disable-reason-text">
              {settingsSaveDisableReasonTrans}
            </div> :
            null
        }
        <Button
            onClick={onSettingsEditCancel}
            variant={MATERIAL_UI_VARIANT.CONTAINED}
            color={MATERIAL_UI_STYLE_COLOR.INHERIT}
        >
          {CancelLabelTrans}
        </Button>
        <Button
            onClick={onSettingsSave}
            disabled={!!settingsSaveDisableReasonTrans}
            type="submit"
            variant={MATERIAL_UI_VARIANT.CONTAINED}
            color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
        >
          {SaveLabelTrans}
        </Button>
      </div>
    );
  }
}
