import React, { Component } from 'react';

import PropTypes from 'prop-types';

import cn from 'classnames';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import './style.css';
import { AppTypeTopBarUserMenuContainer } from '../AppTypeTopBarUserMenu/AppTypeTopBarUserMenuContainer';
import IconButton from '@mui/material/IconButton';
import { ExitToApp } from '@mui/icons-material';
import { FUNC_IS_REQUIRED_TYPE } from '../../constants/propTypes';
import { APP_TYPE_BAR_SIZE } from './constants';
import { FullScreenReloadIconButton } from '../../fullscreen/FullScreenReloadIconButton/FullScreenReloadIconButton';
import { SHOW_APP_RELOAD_BUTTONS_ONLY_ON_FULL_SCREEN } from '../../fullscreen/constants';
import { ReloadIconButton } from '../ReloadIconButton/ReloadIconButton';
import _isFunction from 'lodash/isFunction';


export class AppTypeTopBar extends Component {

  static propTypes = {
    className: PropTypes.string,
    exitFromAppType: FUNC_IS_REQUIRED_TYPE,
    appTitle: PropTypes.node,
    isOnlyOneAppReviewPermitted: PropTypes.bool.isRequired,
    position: PropTypes.oneOf(['fixed', 'absolute', 'sticky', 'static', 'relative']),
    size: PropTypes.oneOf(Object.values(APP_TYPE_BAR_SIZE)),
    showReloadButtonOnlyOnFullscreen: PropTypes.bool,
    TitleComponent: PropTypes.elementType,
    renderButtons: PropTypes.func,
  };

  static defaultProps = {
    position: 'static',
    size: APP_TYPE_BAR_SIZE.SMALL,
    showReloadButtonOnlyOnFullscreen: true,
  };

  _renderAppTitle = () => {
    const {
      TitleComponent,
    } = this.props;

    if (TitleComponent) {
      return <TitleComponent />;
    }

    return (
      <div className="app-type-top-bar__app-title">
        "{this.props.appTitle}"
      </div>
    );
  }

  _renderExitFromAppTypeIcon = () => {
    if (this.props.isOnlyOneAppReviewPermitted) return null;

    return (
      <IconButton
          className="app-type-top-bar__exit-from-app-type-icon"
          onClick={this.props.exitFromAppType}
          color="inherit"
          size="large"
      >
        <ExitToApp />
      </IconButton>
    );
  }

  _renderReloadButton = () => {

    /*
    * Если параметр из конфигурации явно задает, что кнопка
    * перезагрузки должна быть не только при полноэкранном режиме,
    * то дополнительных проверок не нужно, сразу рендерим постоянную
    * кнопку ReloadIconButton, т.к. параметр конфигурации, в данном
    * случае является определяющим
    * */
    if(!SHOW_APP_RELOAD_BUTTONS_ONLY_ON_FULL_SCREEN) {
      return <ReloadIconButton />;
    }

    /*
    * Если в конфигурации задана настройка, что отображаем кнопку
    * перезагрузки страницы только при полноэкранном режиме, то
    * это поведение можно дополнительно отключить одноименным пропсом
    * showReloadButtonOnlyOnFullscreen. Такая реализация, потому что есть
    * специальные кейсы, когда и в конфигурации отображения "только в полноэкранном
    * режиме" кнопку нужно отображать постоянно
    * */
    return this.props.showReloadButtonOnlyOnFullscreen ?
      <FullScreenReloadIconButton /> :
      <ReloadIconButton />;
  }

  _renderButtons = () => {
    /*
    пропс renderButtons используется для переопределения последовательности отрисовки кнопок, В некоторых кейсах исторически кнопки на панели были в другом порядке и пока
     нужно было эту последовательность сохранить используя уже общий компонент
    TODO возможно это временное решение, что-то более серьёзное будет прорабатываться в отдельной задаче
    */
    const { renderButtons } = this.props;

    if (_isFunction(renderButtons)) {
      return renderButtons(
        this._renderReloadButton(),
        <AppTypeTopBarUserMenuContainer/>,
        this._renderExitFromAppTypeIcon(),
      );
    }

    return (
      <React.Fragment>
        {this._renderReloadButton()}
        <AppTypeTopBarUserMenuContainer/>
        {this._renderExitFromAppTypeIcon()}
      </React.Fragment>
    );
  }

  render() {
    const {
      className,
      position,
      size,
    } = this.props;

    return (
      <AppBar
          className={
            cn('app-type-top-bar', className, {
              'app-type-top-bar--large': size === APP_TYPE_BAR_SIZE.LARGE,
            })
          }
          position={position}
      >
        <Toolbar className="app-type-top-bar__toolbar">
          {this._renderAppTitle()}
          <div className="app-type-top-bar__control-panel">
            {this._renderButtons()}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}
