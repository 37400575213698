import { Decimal } from 'decimal.js-light';


export const addDecimals = (a, b) => new Decimal(a).add(b).toNumber();

export const subDecimals = (a, b) => new Decimal(a).sub(b).toNumber();

export const mulDecimals = (a, b) => new Decimal(a).mul(b).toNumber();

export const divDecimals = (a, b) => new Decimal(a).div(b).toNumber();

export const modDecimals = (a, b) => new Decimal(a).mod(b).toNumber();