import PropTypes from 'prop-types';
import {
  FUNC_IS_REQUIRED_TYPE,
  NUMBER_OR_STRING_TYPE,
  SHEET_OPERATION_STATUS_TYPE,
} from '../../../constants/propTypes';
import { PERMISSIONS_MANAGER_TYPE } from '../../../hoc/withPermissionsManager/constants';
import { SHEET_OPERATION_FEATURES_VALUES_TYPE } from './SheetOperationFeaturesValuesForm/constants';

export const sheetOperationDataShape = PropTypes.shape({
  sheetOperationId: NUMBER_OR_STRING_TYPE.isRequired,
  sheetId:  NUMBER_OR_STRING_TYPE.isRequired,
  operationIdentity: PropTypes.string.isRequired,
  operationNumber: PropTypes.string.isRequired,
  operationName: PropTypes.string.isRequired,
  operationStartDate: PropTypes.string.isRequired,
  operationStopDate: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  status: SHEET_OPERATION_STATUS_TYPE.isRequired,
  departmentId:  NUMBER_OR_STRING_TYPE.isRequired,
  departmentIdentity: PropTypes.string.isRequired,
  departmentName: PropTypes.string.isRequired,
  equipmentClassId:  NUMBER_OR_STRING_TYPE.isRequired,
  equipmentClassIdentity: PropTypes.string.isRequired,
  equipmentClassName: PropTypes.string.isRequired,
  equipmentId:  NUMBER_OR_STRING_TYPE,
  equipmentIdentity: PropTypes.string,
  equipmentName: PropTypes.string,
  sheetIdentity: PropTypes.string.isRequired,
  entityBatchId: NUMBER_OR_STRING_TYPE.isRequired,
  entityBatchIdentity: PropTypes.string,
  entitiesInBatchAmount: PropTypes.number.isRequired,
  entityCode: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  orderName: PropTypes.string.isRequired,
  assignees: PropTypes.arrayOf(
      PropTypes.shape({
        id: NUMBER_OR_STRING_TYPE.isRequired,
        name: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        patronymicName: PropTypes.string,
      }),
    ).isRequired,
  note: PropTypes.string,
  isPreviousSheetOperationFinished: PropTypes.bool.isRequired,
  isLastAssemblySheetOperation: PropTypes.bool,
}).isRequired;

export const SHEET_OPERATION_REVIEW_DIALOG_PROP_TYPES = {
  className: PropTypes.string,
  closeDialog: PropTypes.func,
  sheetOperationData: sheetOperationDataShape,
  areStatusChangeButtonsHidden: PropTypes.func,
  isPauseSheetAdditionalActionHidden: PropTypes.func,
  isSplitEntityBatchAdditionalActionHidden: PropTypes.func,
  isConsumeEntitiesActionHidden: PropTypes.func,
  getHelpAlertContent: PropTypes.func,
  isAssigneesChangeDisabled: PropTypes.func,
  isEquipmentChangeDisabled: PropTypes.func,
  isNoteChangeDisabled: PropTypes.func,
  startSheetOperation: FUNC_IS_REQUIRED_TYPE,
  pauseSheetOperation: FUNC_IS_REQUIRED_TYPE,
  continueSheetOperation: FUNC_IS_REQUIRED_TYPE,
  finishSheetOperation: FUNC_IS_REQUIRED_TYPE,
  setSheetOperationAssignees: FUNC_IS_REQUIRED_TYPE,
  setSheetOperationEquipment: FUNC_IS_REQUIRED_TYPE,
  createEquipmentEntity: FUNC_IS_REQUIRED_TYPE,
  addAutocompleteOptions: FUNC_IS_REQUIRED_TYPE,
  setSheetOperationNote: FUNC_IS_REQUIRED_TYPE,
  pauseSheet: FUNC_IS_REQUIRED_TYPE,
  splitEntityBatch: FUNC_IS_REQUIRED_TYPE,
  PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch: FUNC_IS_REQUIRED_TYPE,
  fetchOperationData: FUNC_IS_REQUIRED_TYPE,
  deleteAssemblySheetConsumeData: FUNC_IS_REQUIRED_TYPE,
  getClientMessagesDataArray: FUNC_IS_REQUIRED_TYPE,

  setIsConsumeEntitiesDialogOpen: FUNC_IS_REQUIRED_TYPE,
  isConsumeEntitiesDialogOpen: PropTypes.bool.isRequired,

  isEquipmentForOperationRequired: PropTypes.bool.isRequired,
  shouldCheckEquipmentAvailability: PropTypes.bool.isRequired,

  sheetOperationFeaturesValues: SHEET_OPERATION_FEATURES_VALUES_TYPE,
};