import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Table } from '../../Table/Table';
import { SHEET_OPERATION_TRANSACTION_MODEL } from '../../../constants/models';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';
import { SimpleHelpAlert } from '../../common/SimpleHelpAlert/SimpleHelpAlert';
import './style.css';
import { SHEET_OPERATION_STATUS } from '../../../constants/sheets';
import { SHEET_OPERATION_TRANSACTIONS_TABLE_DATA_TYPE } from './constants';
import { renderSheetOperationProgress } from '../../common/SheetOperationReviewDialog/constants';


const SHEET_OPERATION_TRANSACTION_TABLE_HEIGHT = 200;

export class SheetOperationTransactionsTable extends Component {
  static propTypes = {
    tableId: PropTypes.string.isRequired,
    sheetOperationTransactionsTableData: SHEET_OPERATION_TRANSACTIONS_TABLE_DATA_TYPE,
    fetchSheetOperationTransactions: FUNC_IS_REQUIRED_TYPE,
    clearTableRemoteData: FUNC_IS_REQUIRED_TYPE,
    operationStatus: PropTypes.number.isRequired,
    entitiesInBatchAmount: PropTypes.number.isRequired,
  };

  componentWillUnmount() {
    const {
      clearTableRemoteData,
      tableId,
    } = this.props;

    return clearTableRemoteData([tableId]);
  }

  _renderTableTranslatedNoDataText = () =>
    <Trans id="sheet_operation_review@no_history_data">
      Не было зарегистрировано никаких работ
    </Trans>;

  _renderWarningAlert = () => {
    const {
      operationStatus,
    } = this.props;

    if(operationStatus !== SHEET_OPERATION_STATUS.FINISHED) return null;

    return (
      <SimpleHelpAlert
          className="sheet-operation-transaction-table__warning"
          content={(
            <span>
              {'* '}
              <Trans id="sheet_operation_review@no_history_for_completed_operation_warning">
                Если для завершенной операции частично или полностью отсутствует информация по истории выполненных
                работ, значит операция была завершена принудительно из приложения "Плановик"
              </Trans>
            </span>
          )}
      />
    );
  }

  _renderProgress = ({ data: { stopProgress } }) => {
    const {
      entitiesInBatchAmount,
    } = this.props;

    return renderSheetOperationProgress(entitiesInBatchAmount, stopProgress);
  }

  render() {
    const {
      tableId,
      fetchSheetOperationTransactions,
      sheetOperationTransactionsTableData,
    } = this.props;

    return (
      <div className="sheet-operation-transaction-table__wrapper">
        {this._renderWarningAlert()}
        <Table
            tableId={tableId}
            tableModel={SHEET_OPERATION_TRANSACTION_MODEL}
            fetchRemoteTableData={fetchSheetOperationTransactions}
            rowsData={sheetOperationTransactionsTableData}
            disableFilter
            disableSort
            tableMenu={{
              disableMenu: true,
            }}
            customCellRenderersProps={{
              startDate: {
                dateFormat: FORMAT_SHORT_TIME,
              },
              stopDate: {
                dateFormat: FORMAT_SHORT_TIME,
              },
              stopProgress: {
                renderProgress: this._renderProgress,
              },
            }}
            noDataContent={this._renderTableTranslatedNoDataText()}
            wrappedTableComponentProps={{
              style: {
                height: SHEET_OPERATION_TRANSACTION_TABLE_HEIGHT,
              },
            }}
        />
      </div>
    );
  }
}