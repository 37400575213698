import { initSchema } from './initSchema';
import _mapValues from 'lodash/mapValues';
import {
  ORDER_ENTRY_MODEL, SHEET_OPERATION_FEATURE_SCOPE_MODEL,
  SHEET_OPERATION_FEATURES_MODEL,
  SHEET_OPERATION_MODEL,
  SHEET_OPERATION_TRANSACTION_MODEL,
  USER_MODEL,
} from '../../constants/models';
import { sheetOperationSchema } from './models/sheetOperationSchema';
import { TASK_VIEW_SCREEN_MODEL, taskViewScreenSchema } from './models/taskViewScreenSchema';
import { sheetOperationTransactionSchema } from './models/sheetOperationTransactionSchema';
import { userSchema } from './models/userSchema';
import { MASTER_TASKS_MODEL, masterTasksSchema } from './models/masterTasksSchema';
import {
  SHEET_TO_START_OPERATION_MODEL,
  sheetToStartOperationSchema,
} from './models/sheetToStartOperationSchema';
import { orderEntrySchema } from './models/orderEntrySchema';
import { sheetOperationFeaturesSchema } from './models/sheetOperationFeaturesSchema';
import { sheetOperationFeatureScopesSchema } from './models/sheetOperationFeatureScopesSchema';

const TABLE_SCHEMA_BY_MODEL_MAP = {
  [SHEET_OPERATION_MODEL]: sheetOperationSchema,
  [SHEET_TO_START_OPERATION_MODEL]: sheetToStartOperationSchema,
  [TASK_VIEW_SCREEN_MODEL]: taskViewScreenSchema,
  [SHEET_OPERATION_TRANSACTION_MODEL]: sheetOperationTransactionSchema,
  [USER_MODEL]: userSchema,
  [MASTER_TASKS_MODEL]: masterTasksSchema,
  [ORDER_ENTRY_MODEL]: orderEntrySchema,
  [SHEET_OPERATION_FEATURES_MODEL]: sheetOperationFeaturesSchema,
  [SHEET_OPERATION_FEATURE_SCOPE_MODEL]: sheetOperationFeatureScopesSchema,
};

export const SCHEMA_MODEL_INITIAL_STATE = _mapValues(TABLE_SCHEMA_BY_MODEL_MAP, initSchema);