import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FUNC_IS_REQUIRED_TYPE,
  OBJECT_OF_ANY_TYPE,
  TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
} from '../../constants/propTypes';

import cn from 'classnames';
import { SimpleSummary } from '../common/SimpleSummary/SimpleSummary';
import { EntitiesReviewTableFromFactory }
from '../common/EntitiesReview/EntitiesReviewTableFromFactory/EntitiesReviewTableFromFactory';
import { EntitiesReviewTableItemDefaultContent }
from '../common/EntitiesReview/EntitiesReviewTableFromFactory/EntitiesReviewTableComponent/EntitiesReviewTableItemDefaultContent/EntitiesReviewTableItemDefaultContent';
import { EntityReviewDialog } from '../common/EntitiesReview/EntityReviewDialog/EntityReviewDialog';

import './style.css';
import { ORDER_PRIMARY_CONTENT_SCHEMA, ORDER_SECONDARY_CONTENT_SCHEMA } from './constants';
import { NoDataLabelTrans } from '../../utils/commonTransComponents';


const ORDER_REVIEW_DIALOG_TOP_PANEL_HEIGHT_IN_PX = 129;


export class Orders extends Component {

  static propTypes = {
    className: PropTypes.string,
    ordersIdentity: PropTypes.string.isRequired,
    orderItemIdProperty: PropTypes.string.isRequired,
    fetchOrdersData: PropTypes.func,
    ordersData: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
    fetchOrderToReview: PropTypes.func,
    orderToReviewIdFromRoute: PropTypes.string,
    noDataText: PropTypes.node,
    orderReviewContentComponent: PropTypes.elementType,
    getOrderItemAdditionalInfoLabel: PropTypes.func,
    startOrderReview: FUNC_IS_REQUIRED_TYPE,
    stopOrderReview: FUNC_IS_REQUIRED_TYPE,
    filtersSchema: TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
  };

  static defaultProps = {
    ordersData: [],
    noDataText: NoDataLabelTrans,
    filtersSchema: {},
  };

  _renderOrdersTableFromFactory = () => {
    const {
      ordersIdentity,
      orderItemIdProperty,
      fetchOrdersData,
      ordersData,
      noDataText,
      startOrderReview,
      filtersSchema,
    } = this.props;

    return(
      <EntitiesReviewTableFromFactory
          tableId={ordersIdentity}
          tableModel={ordersIdentity}
          rowsData={ordersData}
          fetchRemoteTableData={fetchOrdersData}
          noDataContent={noDataText}
          onRowClick={startOrderReview}
          wrappedTableComponentProps={{
            entityItemIdProperty: orderItemIdProperty,
            renderItemContent: this._renderItemContent,
          }}
          customFilters={filtersSchema}
      />
    );
  };

  _renderItemContent = entityData => (
    <EntitiesReviewTableItemDefaultContent
        entityData={entityData}
        renderItemLeftSideContent={this._renderOrderSummaryContent}
        getItemAdditionalInfoLabels={this.props.getOrderItemAdditionalInfoLabel}
    />
  );

  _renderOrderSummaryContent = orderData => (
      <SimpleSummary
          summaryData={orderData}
          primarySummarySchema={ORDER_PRIMARY_CONTENT_SCHEMA}
          secondarySummarySchema={ORDER_SECONDARY_CONTENT_SCHEMA}
      />
    );

  _renderOrderReviewDialogBarContent = entityToReviewData => (
      <div className="order-review-dialog__bar-content">
        <div className="order-review-dialog__summary">
          {this._renderOrderSummaryContent(entityToReviewData)}
        </div>
      </div>
    );

  _renderOrderReviewDialog = () => {
    const {
      orderToReviewIdFromRoute,
      fetchOrderToReview,
      orderReviewContentComponent,
      stopOrderReview,
      fetchOrdersData,
      orderItemIdProperty,
      ordersData,
    } = this.props;
    return(
      <EntityReviewDialog
          entityToReviewIdFromRoute={orderToReviewIdFromRoute}
          fetchEntityToReview={fetchOrderToReview}
          stopEntityReview={stopOrderReview}
          entityReviewContentComponent={orderReviewContentComponent}
          renderDialogAppBarContent={this._renderOrderReviewDialogBarContent}
          fetchEntitiesData={fetchOrdersData}
          entityIdProperty={orderItemIdProperty}
          entitiesData={ordersData}
          fixedDialogAppBarHeight={ORDER_REVIEW_DIALOG_TOP_PANEL_HEIGHT_IN_PX}
      />
    );
  };


  render() {

    return (
      <div
          className={
            cn(
              this.props.className,
              'orders',
            )
          }
      >
        {this._renderOrdersTableFromFactory()}
        {this._renderOrderReviewDialog()}
      </div>
    );
  }
}