import React, { Component } from 'react';

import PropTypes from 'prop-types';
import {
  SHEET_SUMMARY_TYPE,
  FUNC_IS_REQUIRED_TYPE,
  TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
} from '../../constants/propTypes';


import cn from 'classnames';
import './style.css';
import { EntitiesReviewTableFromFactory }
from '../common/EntitiesReview/EntitiesReviewTableFromFactory/EntitiesReviewTableFromFactory';
import { SheetSummary } from '../PlannerApp/SheetSummary/SheetSummary';
import { EntitiesReviewTableItemDefaultContent }
from '../common/EntitiesReview/EntitiesReviewTableFromFactory/EntitiesReviewTableComponent/EntitiesReviewTableItemDefaultContent/EntitiesReviewTableItemDefaultContent';
import { SimpleSummary } from '../common/SimpleSummary/SimpleSummary';
import { SHEET_OPERATION_STATUS_TITLES_TRANS_MSG_MAP } from '../../constants/sheets';
import { EntityReviewDialog } from '../common/EntitiesReview/EntityReviewDialog/EntityReviewDialog';
import {
  ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED_EVENT_TYPE,
} from '../../constants/sockets';
import {
  NoDataLabelTrans,
  OperationLabelTrans,
  OperationNumberLabelTrans,
  SheetOperationProgressLabelTrans,
  SheetOperationStatusLabelTrans,
} from '../../utils/commonTransComponents';
import { renderSheetOperationProgress } from '../common/SheetOperationReviewDialog/constants';


const SHEET_REVIEW_DIALOG_TOP_PANEL_HEIGHT_IN_PX = 217;

const CURRENT_SHEET_OPERATION_DATA_SECONDARY_SUMMARY_SCHEMA = [
  {
    key: 'currentSheetOperationNumber',
    label: OperationNumberLabelTrans,
    rowIdentity: 'current-sheet-operation-number',
    getCustomValue: ({ nop }) => {
      if(nop === undefined) return null;

      return nop;
    },
  },
  {
    key: 'combinedOperationName',
    rowIdentity: 'combined-operation-name',
    label: OperationLabelTrans,
    getCustomValue: ({ name, identity }) => {
      if(name === undefined || identity === undefined) return null;

      return `${name} (${identity})`;
    },
  },
  {
    key: 'currentSheetOperationStatus',
    label: SheetOperationStatusLabelTrans,
    rowIdentity: 'current-sheet-operation-status',
    // eslint-disable-next-line react/prop-types
    getCustomValue: ({ status }) => {
      if(status === undefined) return null;

      return SHEET_OPERATION_STATUS_TITLES_TRANS_MSG_MAP[status];
    },
  },
  {
    key: 'currentSheetOperationProgress',
    label: SheetOperationProgressLabelTrans,
    rowIdentity: 'current-sheet-operation-progress',
    getCustomValue: ({ progress, entitiesInBatchAmount }) => {
      if(progress === undefined) return null;

      return renderSheetOperationProgress(entitiesInBatchAmount, progress);
    },
  },
];

export class Sheets extends Component {

  static propTypes = {
    className: PropTypes.string,
    sheetsIdentity: PropTypes.string.isRequired,
    sheetItemIdProperty: PropTypes.string.isRequired,
    fetchSheetsData: PropTypes.func,
    sheetsData: PropTypes.arrayOf(SHEET_SUMMARY_TYPE),
    fetchSheetToReview: PropTypes.func,
    sheetToReviewIdFromRoute: PropTypes.string,
    noDataText: PropTypes.node,
    getSheetItemAdditionalInfoLabels: PropTypes.func,
    sheetReviewContentComponent: PropTypes.elementType,
    startSheetReview: FUNC_IS_REQUIRED_TYPE,
    stopSheetReview: FUNC_IS_REQUIRED_TYPE,
    filtersSchema: TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
    getClientMessagesDataArray: FUNC_IS_REQUIRED_TYPE,
  };

  static defaultProps = {
    sheetsData: [],
    noDataText: NoDataLabelTrans,
    filtersSchema: {},
  };

  /*
   * Из-за текущей реализации с множеством локальных стейтов проблематично реализовывать обновление данных при
   * броадкастинге. У нас глобальные обработчики для событий броадкастинга, в которых нет данных о локальных стейтах
   * компонента. Поэтому пока нашлось решение для таких компонентов добавлять для сокета дополнительный обработчик
   * прямо в компоненте. В данном случае, речь о EntityReviewDialog, которому в пропсы можно передать обработчик
   * событий в сокете. Возвращаемые аргументы обработчика, которые можно использовать описаны в EntityReviewDialog
   * */
  _sheetReviewSocketMsgHandler = (
    message,
    { entityToReviewData: sheetToReviewData },
    _,
    { initEntityToReviewDataRemote: updateSheetToReviewData },
  ) => {
    const {
      getClientMessagesDataArray,
    } = this.props;

    if (sheetToReviewData === null) return;

    const {
      entityBatchId,
    } = sheetToReviewData;

    getClientMessagesDataArray(message)
      .then(messageDataArr => {

        /*
        * Отслеживаем следующие кейсы при просмотре маршрутного листа:
        *  - стандартный маршрутный лист комплектуют
        *  - сборочный маршрутный лист отправляют в производство
        *
        * В этих случаях важно обновить локальный стейт entityReview, т.к. есть сценарии работы в этих случаях, когда
        * важно обновить интерфейс:
        *  - когда комплектуют стандартный МЛ, то на экране завершения МЛ в производстве нужно чтобы обновился
        * статус, в зависимости от этого на экране меняются информационные сообщения и доступность кнопок
        *  - когда в производство отправляют сборочный МЛ, то при просмотре этого же МЛ важно, чтобы информация
        * обновилась, иначе отправлять в производство можно будет повторно другому пользователю
        *
        * Вероятно, обработчик будет расширяться, т.к. есть и другие кейсы обновления данных просматриваемого МЛ,
        * просто пока их обработка не признана критичной в отличии от описанных кейсов
        * */
        const shouldUpdateSheetToReview = !!messageDataArr
          .find(
            ({ data, event }) =>
              (
                event === PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED_EVENT_TYPE ||
                event === ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION_EVENT_TYPE
              )  && entityBatchId === data.entityBatchId,
          );

        if (shouldUpdateSheetToReview) {
          updateSheetToReviewData();
        }
      });
  }

  _renderSheetsTableFromFactory = () => {
    const {
      sheetsIdentity,
      sheetItemIdProperty,
      fetchSheetsData,
      sheetsData,
      noDataText,
      startSheetReview,
      filtersSchema,
    } = this.props;

    return(
      <EntitiesReviewTableFromFactory
          tableId={sheetsIdentity}
          tableModel={sheetsIdentity}
          rowsData={sheetsData}
          fetchRemoteTableData={fetchSheetsData}
          noDataContent={noDataText}
          onRowClick={startSheetReview}
          wrappedTableComponentProps={{
            entityItemIdProperty: sheetItemIdProperty,
            renderItemContent: this._renderItemContent,
          }}
          customFilters={filtersSchema}
      />
    );
  };

  _renderItemContent = entityData => (
    <EntitiesReviewTableItemDefaultContent
        entityData={entityData}
        renderItemLeftSideContent={this._renderSheetItemLeftSideContent}
        renderItemRightSideContent={this._renderSheetItemRightSideContent}
        getItemAdditionalInfoLabels={this.props.getSheetItemAdditionalInfoLabels}
    />
  )

  _renderSheetItemRightSideContent = sheetData => {
    const {
      currentSheetOperationData = {},
      entitiesInBatchAmount,
    } = sheetData;

    return (
      <SimpleSummary
          summaryData={{
            ...currentSheetOperationData,
            entitiesInBatchAmount,
          }}
          secondarySummarySchema={CURRENT_SHEET_OPERATION_DATA_SECONDARY_SUMMARY_SCHEMA}
      />
    );
  };

  _renderSheetItemLeftSideContent = sheetData => {
    const {
      entityIdentity,
      entityCode,
      entityName,
      sheetIdentity,
      entitiesInBatchAmount,
      entityBatchIdentity,
      orderName,
      startDate,
      stopDate,
    } = sheetData;

    return (
      <SheetSummary
          entityIdentity={entityIdentity}
          entityCode={entityCode}
          entityName={entityName}
          sheetIdentity={sheetIdentity}
          entitiesInBatchAmount={entitiesInBatchAmount}
          entityBatchIdentity={entityBatchIdentity}
          orderName={orderName}
          startDate={startDate}
          stopDate={stopDate}
      />
    );
  };

  _renderSheetReviewDialogBarContent = entityToReviewData => (
      <div className="sheet-review-dialog__bar-content">
        <div className="sheet-review-dialog__summary">
          <SheetSummary {...entityToReviewData}/>
        </div>
      </div>
    );

  _renderSheetReviewDialog = () => {
    const {
      sheetToReviewIdFromRoute,
      fetchSheetToReview,
      sheetReviewContentComponent,
      stopSheetReview,
      fetchSheetsData,
      sheetItemIdProperty,
      sheetsData,
    } = this.props;

    return(
      <EntityReviewDialog
          entityToReviewIdFromRoute={sheetToReviewIdFromRoute}
          fetchEntityToReview={fetchSheetToReview}
          stopEntityReview={stopSheetReview}
          entityReviewContentComponent={sheetReviewContentComponent}
          entityReviewContentComponentPropsAdapter={this.sheetReviewComponentPropsAdapter}
          renderDialogAppBarContent={this._renderSheetReviewDialogBarContent}
          fetchEntitiesData={fetchSheetsData}
          entityIdProperty={sheetItemIdProperty}
          entitiesData={sheetsData}
          fixedDialogAppBarHeight={SHEET_REVIEW_DIALOG_TOP_PANEL_HEIGHT_IN_PX}
          onSocketMessage={this._sheetReviewSocketMsgHandler}
      />
    );
  };

  sheetReviewComponentPropsAdapter = props => ({
    closeReviewDialog: props.stopEntityReview,
    fetchSheetsData: props.fetchEntitiesData,
    sheetToReviewData: props.entityToReviewData,
    updateSheetToReviewData: props.initEntityToReviewDataRemote,
  });


  render() {

    return (
      <div
          className={
            cn(
              this.props.className,
              'sheets',
            )
          }
      >
        {this._renderSheetsTableFromFactory()}
        {this._renderSheetReviewDialog()}
      </div>
    );
  }
}
