import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { matchPath } from 'react-router-dom';
import {
  PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
  PLANNER_APP_ORDERS_COMPLETED_ROUTE,
} from '../../../../constants/routes';
import {
  fetchOrdersReadyToComplete,
  fetchCompletedOrders,
  sendOrderCompletedNotification,
} from '../../../../operations/orders';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { createOrderEntriesTableId } from '../../../../utils/tables';
import { ORDER_TYPE } from '../../../../constants/orders';
import { fetchOrderRemoteTableDataCbFactory } from '../../../../components/Orders/OrdersContainer';
import { push } from 'connected-react-router';


export const handleOrderComplete = message =>
  (dispatch, getState) => {
    const {
      id,
      name,
    } = message;

    sendOrderCompletedNotification(name);

    const state = getState();

    const currentPathname = routerPathnameSelector(state);

    //Если находимся в разделе "Плановик. Заказы. Готовые к завершению"
    const ordersReadyToCompleteRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
    });
    if (ordersReadyToCompleteRouteMatch !== null)
      return dispatch(_updateIfOnOrdersReadyToCompleteScreen(id, currentPathname));

    //Если находимся в разделе "Плановик. Заказы. Завершенные"
    const ordersCompletedRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_COMPLETED_ROUTE,
    });
    if (ordersCompletedRouteMatch !== null)
      return dispatch(_updateIfOnOrdersCompletedScreen(id));

    /*
    * для всех остальных разделов приложения обработка одинаковая
    * - сброс кэша таблицы завершенных заказов и заказов, готовых к завершению
    * - очистка данных таблицы просмотра содержимого заказа
    * */
    return dispatch([
      clearTableRemoteData([
        ORDER_TYPE.IN_PRODUCTION,
        ORDER_TYPE.READY_TO_COMPLETE,
      ]),
      clearTableData([
        createOrderEntriesTableId(ORDER_TYPE.READY_TO_COMPLETE, id),
      ]),
    ]);
  };

const _updateIfOnOrdersReadyToCompleteScreen = (orderId, currentPathname) =>
  dispatch => {

    // перезапрашиваем данные таблицы заказов готовых к завершению
    dispatch(reFetchRemoteTableData(
      ORDER_TYPE.READY_TO_COMPLETE,
      ORDER_TYPE.READY_TO_COMPLETE,
      fetchOrderRemoteTableDataCbFactory(dispatch, fetchOrdersReadyToComplete),
    ));

    // сбрасываем кэш таблицы завершенных заказов и таблицы просмотра содержимого заказа в списке готовых к завершению
    dispatch([
      clearTableRemoteData([
        ORDER_TYPE.COMPLETED,
      ]),
      clearTableData([
        createOrderEntriesTableId(ORDER_TYPE.READY_TO_COMPLETE, orderId),
      ]),
    ]);

    /*
    * Для случая, когда находимся на роте просмотра заказа, который был завершен, выполняем редирект к списку заказов,
    * готовых к завершению
    * */
    const plannerAppReadyToCompleteOrderReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE}/:orderId`,
    });

    if(
      plannerAppReadyToCompleteOrderReviewRouteMatch !== null &&
      plannerAppReadyToCompleteOrderReviewRouteMatch.params.orderId === orderId.toString()
    ) {
      return dispatch(push(PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE));
    }
  };

const _updateIfOnOrdersCompletedScreen = orderId =>
  dispatch => {

    // перезапрашиваем данные таблицы завершенных заказов
    dispatch(reFetchRemoteTableData(
      ORDER_TYPE.COMPLETED,
      ORDER_TYPE.COMPLETED,
      fetchOrderRemoteTableDataCbFactory(dispatch, fetchCompletedOrders),
    ));

    // сбрасываем кэш таблицы заказов готовых к завершению и таблицы просмотра содержимого заказа
    dispatch([
      clearTableRemoteData([
        ORDER_TYPE.READY_TO_COMPLETE,
      ]),
      clearTableData([
        createOrderEntriesTableId(ORDER_TYPE.READY_TO_COMPLETE, orderId),
      ]),
    ]);
  };