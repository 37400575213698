import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../../reducers/autocomplete/actions';
import { USER_MODEL } from '../../../../constants/models';
import { ACTIVE_USERS_REQUEST_PREDEFINED_FILTERS } from '../../../../constants/users';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../../api/restCollectionApi/index';


export const sheetOperationAssigneeAutocompleteLoadOptionsActionCreator =
  createCaEntitiesAutocompleteLoadOptionsActionCreator(
    {
      requestModel: USER_MODEL,
      getRequestQuery: inputValue => {

        if(!inputValue) return { filter: ACTIVE_USERS_REQUEST_PREDEFINED_FILTERS };

        return {
          filter: {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters: [
              ACTIVE_USERS_REQUEST_PREDEFINED_FILTERS,
              {
                filterGroupType: FILTER_GROUP_TYPES.OR,
                filters: [
                  {
                    column: 'name',
                    filterType: FILTER_TYPES.CONTAINS,
                    filterValue: inputValue,
                  },
                  {
                    column: 'lastName',
                    filterType: FILTER_TYPES.CONTAINS,
                    filterValue: inputValue,
                  },
                  {
                    column: 'patronymicName',
                    filterType: FILTER_TYPES.CONTAINS,
                    filterValue: inputValue,
                  },
                  {
                    column: 'note',
                    filterType: FILTER_TYPES.CONTAINS,
                    filterValue: inputValue,
                  },
                ],
              },
            ],
          },
        };
      },
    },
  );

export const SHEET_OPERATION_ASSIGNEE_AUTOCOMPLETE_ID = 'sheet-operation-assignee-autocomplete';