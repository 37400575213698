import { OPERATION_MODEL, SHEET_OPERATION_MODEL } from '../../../constants/models';
import { RELATED_MODEL_FIELD_DELIMITER } from '../../../constants/magics';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi/index';
import { fetchEntitiesFromServer } from '../../entities/actions';
import { SHEET_OPERATIONS_DEFAULT_REQUEST_OPTIONS } from '../../../constants/sheets';

export const SET_LAST_SHEET_OPERATION = 'SET_LAST_SHEET_OPERATION';
export const CLEAR_LAST_SHEET_OPERATION = 'CLEAR_LAST_SHEET_OPERATION';
export const CLEAR_ALL_LAST_SHEETS_OPERATIONS = 'CLEAR_ALL_LAST_SHEETS_OPERATIONS';

export const setLastSheetOperation = (sheetId, sheetOperationId) => ({
  type: SET_LAST_SHEET_OPERATION,
  sheetId,
  sheetOperationId,
});

export const clearLastSheetOperation = sheetId => ({
  type: CLEAR_LAST_SHEET_OPERATION,
  sheetId,
});

export const clearAllLastSheetsOperations = () => ({
  type: CLEAR_ALL_LAST_SHEETS_OPERATIONS,
});

/*
* Чтобы получить последнюю операцию по маршруту партии, сортируем операции маршрута по номеру операции по убыванию (и
* возьмём первый элемент, т.е. операцию с наибольшим номером операции). Теоретически, сортировать можно было бы и по
* плановым датам начала или окончания операции, т.к. планируется они последовательно, исходя из номера операции, но у
* нас в системе, всё ещё используются для разных целей нулевые операции, тогда сортировка по датам может быть
* некорректной, т.к. даты у операций могут быть одинаковыми. Ну и, в целом, именно номер операции определяет
* последовательность следования операций в маршруте, поэтому, кажется, что логично сортировать именно по нему.
* */
const LAST_SHEET_OPERATION_REQUEST_SORT_PARAMS = [
  {
    column: [OPERATION_MODEL, 'nop'].join(RELATED_MODEL_FIELD_DELIMITER),
    params: [{ key: 'asc', value: false }],
  },
];
export const fetchLastSheetOperation = sheetId =>
  dispatch => {

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'entityRouteSheetId',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: sheetId,
          },
        ],
      },
      sortBy: LAST_SHEET_OPERATION_REQUEST_SORT_PARAMS,
      limit: 1,
      page: 1,
    };

    return dispatch(fetchEntitiesFromServer(SHEET_OPERATION_MODEL, query, SHEET_OPERATIONS_DEFAULT_REQUEST_OPTIONS))
      .then(response => {
        if(!response || response.responseMeta.count === 0) return;

        const sheetOperationId = response.responseEntitiesIds[SHEET_OPERATION_MODEL][0];

        dispatch(setLastSheetOperation(sheetId, sheetOperationId));
      });
  };