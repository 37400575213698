import _isFunction from 'lodash/isFunction';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import cn from 'classnames';
import { FUNC_IS_REQUIRED_TYPE, MATERIAL_UI_DIALOG_MAX_WIDTH_TYPE } from '../../../constants/propTypes';

import './style.css';
import {
  MATERIAL_UI_DIALOG_MAX_WIDTH,
  MATERIAL_UI_SIZE,
  MATERIAL_UI_STYLE_COLOR,
} from '../../../constants/materialUI';


const NODE_OR_STRING_TYPE = PropTypes.oneOfType([PropTypes.node, PropTypes.string]);

export class SimpleConfirmDialog extends Component {

  static propTypes = {
    className: PropTypes.string,
    closeDialog: FUNC_IS_REQUIRED_TYPE,
    isOpen: PropTypes.bool.isRequired,
    title: NODE_OR_STRING_TYPE.isRequired,
    confirmText: NODE_OR_STRING_TYPE,
    additionalComponent: PropTypes.node,
    confirmBtn: NODE_OR_STRING_TYPE,
    cancelBtn: NODE_OR_STRING_TYPE,
    onConfirm: PropTypes.func,
    disableConfirm: PropTypes.bool,
    dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH_TYPE,
    confirmTextChipClassName: PropTypes.string,
    renderDialogActions: PropTypes.func,
  };

  static defaultProps = {
    disableConfirm: false,
    dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.MD,
    renderDialogActions: null,
    onConfirm: null,
  };


  _handleConfirm = e => {
    e.preventDefault();
    if (!_isFunction(this.props.onConfirm)) return;

    this.props.onConfirm();
  };

  _renderDialogTitle = () =>
    <DialogTitle className="simple-confirm-dialog__title">
      <div>
        {this.props.title}
      </div>
    </DialogTitle>;

  _renderDialogContent = () =>
    <DialogContent
        className={cn('simple-confirm-dialog__content', {
          'simple-confirm-dialog__content--with-additional-component': !!this.props.additionalComponent,
        })}
    >
      {this._renderConfirmText()}
      {this._renderAdditionalComponent()}
    </DialogContent>;

  _renderConfirmText = () => {
    const { confirmText, confirmTextChipClassName } = this.props;
    if(!confirmText) return null;

    return(
      <div className="simple-confirm-dialog__confirm-text">
        <Chip
            className={cn('simple-confirm-dialog__confirm-text-chip', confirmTextChipClassName)}
            label={confirmText}
        />
      </div>
    );
  };

  _renderAdditionalComponent = () => {
    const { additionalComponent } = this.props;
    if(!additionalComponent) return null;

    return(
      <div className="simple-confirm-dialog__additional-component">
        {additionalComponent}
      </div>
    );
  };

  _renderDialogActions = () => {
    const {
      closeDialog,
      confirmBtn,
      cancelBtn,
      disableConfirm,
      renderDialogActions,
    } = this.props;

    if (_isFunction(renderDialogActions)) return renderDialogActions();

    return(
      <DialogActions className="simple-confirm-dialog__buttons-panel">
        <Button
            className="simple-confirm-dialog__cancel-button"
            onClick={closeDialog}
            color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
            size={MATERIAL_UI_SIZE.MEDIUM}
        >
          {cancelBtn}
        </Button>
        <Button
            className={
              cn(
                'simple-confirm-dialog__confirm-button',
                { 'simple-confirm-dialog__confirm-button--disabled': disableConfirm },
              )
            }
            type="submit"
            color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
            disabled={disableConfirm}
            size={MATERIAL_UI_SIZE.MEDIUM}
        >
          {confirmBtn}
        </Button>
      </DialogActions>
    );
  };

  render() {
    const {
      className,
      closeDialog,
      isOpen,
      dialogMaxWidth,
    } = this.props;
    return (
      <Dialog
          classes={{
            root: cn(
              'simple-confirm-dialog',
              className,
            ),
            paper: 'simple-confirm-dialog__dialog-paper',
          }}
          open={isOpen}
          onClose={closeDialog}
          maxWidth={dialogMaxWidth}
          fullWidth
      >
        {
          <form onSubmit={this._handleConfirm} className="simple-confirm-dialog__dialog-form">
            {
              isOpen ?
                <React.Fragment>
                  {this._renderDialogTitle()}
                  {this._renderDialogContent()}
                  {this._renderDialogActions()}
                </React.Fragment> :
                null
            }
          </form>
        }
      </Dialog>
    );
  }
}