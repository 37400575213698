import { connect } from 'react-redux';
import { AdminApp } from './AdminApp';
import { routerPathnameSelector } from '../../reducers/router/selectors';
import { withPermissionsManager } from '../../hoc/withPermissionsManager/withPermissionsManager';
import { compose } from 'redux';


const mapStateToProps = state => ({
  pathName: routerPathnameSelector(state),
});

export const AdminAppContainer = compose(
  connect(
    mapStateToProps,
  ),
  withPermissionsManager,
)(AdminApp);