/*
* С точки зрения абстрактной обработки сервера, пустая строка ("") и null в значении поля, передаваемого в POST запросе,
* это разные ситуации, что логично. С точки зрения полей формы на клиенте, пустая строка и null это одно и то же и
* обозначает незаполненность поля, что эквивалентно значению null на сервере (т.е. то, что поле не определено). В том
* числе, это важно с точки зрения валидаций, т.к. именно на значение null сервером проверяется, что поле обязательно,
* для пустой строки есть другие ошибки, типа "минимального значения длины значения", что в случае с полями формы
* логически является другим типом валидаций.
* Поэтому для текстовых полей мы заменяем символ пустой строки на null перед отправкой запроса.
* */
export const _prepareFormWithServerValidationTextFieldValueForSubmit = textFieldValue =>
  textFieldValue === '' ?
    null :
    textFieldValue;

/*
* Логика, аналогичная той, что описана в комментарии к _prepareFormWithServerValidationTextFieldValueForSubmit.
* Т.е., если не выбрана ни одна из опций в мульстиселекте на форме, то значить что поле "не задано"\"не определено",
*  что эквивалентно значению null для сервера. Осуществляем эту замену перед отправкой запроса
* */
export const _prepareFormWithServerValidationMultiSelectValueForSubmit = multiSelectValue =>
  multiSelectValue.length === 0 ?
    null :
    multiSelectValue;