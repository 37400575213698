import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import { SHEET_OPERATION_TABLE_ROW_DATA_TYPE } from './SheetOperationsTable.types';
import { Table } from '../../Table/Table';
import { SHEET_OPERATION_MODEL } from '../../../constants/models';
import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';


export class SheetOperationsTable extends Component {

  static propTypes = {
    sheetOperationsTableModel: PropTypes.string,
    sheetOperationsTableId: PropTypes.string,
    sheetOperationsTableData: PropTypes.arrayOf(SHEET_OPERATION_TABLE_ROW_DATA_TYPE),
    fetchSheetOperations: PropTypes.func,
    onSheetOperationRowClick: PropTypes.func,
    getSheetOperationsTableRowStyle: PropTypes.func,
  };

  static defaultProps = {
    sheetOperationsTableModel: SHEET_OPERATION_MODEL,
  };

  _renderTableTranslatedNoDataText = () =>
    <Trans id="sheet_operations@no_operations_data">
      Нет данных об операциях выбранного маршрутного листа
    </Trans>;

  render() {
    const {
      sheetOperationsTableId,
      sheetOperationsTableData,
      fetchSheetOperations,
      onSheetOperationRowClick,
      getSheetOperationsTableRowStyle,
      sheetOperationsTableModel,
    } = this.props;

    return (
      <div className="sheet-operations-table-wrapper">
        <Table
            tableId={sheetOperationsTableId}
            tableModel={sheetOperationsTableModel}
            getRowStyle={getSheetOperationsTableRowStyle}
            fetchRemoteTableData={fetchSheetOperations}
            rowsData={sheetOperationsTableData}
            noDataContent={this._renderTableTranslatedNoDataText()}
            onRowClick={onSheetOperationRowClick}
            tableMenu={{
              disableMenu: true,
            }}
            customCellRenderersProps={{
              operationStartDate: {
                dateFormat: FORMAT_SHORT_TIME,
              },
              operationStopDate: {
                dateFormat: FORMAT_SHORT_TIME,
              },
            }}
            disableFilter
            disableSort
        />
      </div>
    );
  }
}
