import { TableCellRenderer } from '../TableCellRenderer';
import React from 'react';
import PropTypes from 'prop-types';
import _size from 'lodash/size';

import { UserRoleChip } from '../../../common/UserRoleChip/UserRoleChip';
import { NUMBER_OR_STRING_TYPE } from '../../../../constants/propTypes';
import { NoDataLabelTrans } from '../../../../utils/commonTransComponents';


export class UserRolesRenderer extends TableCellRenderer {

  static getViewValue() {
    return null;
  };

  render() {
    const { value } = this.props;

    if(!_size(value)) return NoDataLabelTrans;

    return(
      <div className="user-roles-renderer">
        {
          value
            .map(({ id, name }) => (
              <UserRoleChip
                  key={id}
                  roleIdentity={name}
              />
            ))
        }
      </div>
    );
  }
}

UserRolesRenderer.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    id: NUMBER_OR_STRING_TYPE.isRequired,
    name: PropTypes.string.isRequired,
  })),
};