import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { TableCellRenderer } from '../TableCellRenderer';
import { FORMAT_FULL_TIME } from '../../../../constants/dateFormats';
import _isNil from 'lodash/isNil';


export class DateRenderer extends TableCellRenderer {

  static getViewValue(props) {
    const { value, dateFormat } = props;

    if(_isNil(value)) return null;

    return moment(value).format(dateFormat || FORMAT_FULL_TIME);
  };

  render() {
    const dateValue = DateRenderer.getViewValue(this.props);

    return(
      <div title={dateValue}>
        {dateValue}
      </div>
    );
  }
}

DateRenderer.propTypes = {
  value: PropTypes.string,
  dateFormat: PropTypes.string,
};