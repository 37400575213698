import { ADD_ENTITIES_TO_STORE, DELETE_ALL_MODEL_ENTITIES_FROM_STORE, DELETE_ENTITIES_FROM_STORE } from './actions';

import _mapValues from 'lodash/mapValues';
import _omit from 'lodash/omit';


export const entities = (state = {}, action) => {
  switch(action.type) {
  case ADD_ENTITIES_TO_STORE:
    const updatedModelsEntitiesState = _mapValues(
      action.entitiesData,
      (updatedModelEntities, model) => ({
        ...state[model],
        ...updatedModelEntities,
      }),
    );
    return {
      ...state,
      ...updatedModelsEntitiesState,
    };
  case DELETE_ENTITIES_FROM_STORE:
    return{
      ...state,
      [action.model]: _omit(state[action.model], action.ids),
    };
  case DELETE_ALL_MODEL_ENTITIES_FROM_STORE:
    return _omit(state, action.models);

  default:
    return state;
  }
};