import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import {
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  SHEET_OPERATION_MODEL,
} from '../../../constants/models';
import { getSheetOperationCombinedName } from '../../../utils/stringBuilder';
import { NoDataLabelTrans, NotSelectedLabelTrans, OperationLabelTrans } from '../../../utils/commonTransComponents';
import {
  createCaEntitiesAutocompleteLoadOptionsActionCreator,
} from '../../../reducers/autocomplete/actions';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';
import { AUTOCOMPLETE_DEFAULT_PROPS, AUTOCOMPLETE_PROP_TYPES } from '../../common/Autocomplete/constants';

export const OperationsAutocomplete = props => {

  const { id, departmentId, equipmentClassId, shouldLoadOptions } = props;

  const loadOptionsActionCreator = useMemo(() => {
    if(!shouldLoadOptions || !departmentId || !equipmentClassId) {
      return undefined;
    }

    return _getOperationsLoadOptionsActionCreator(departmentId, equipmentClassId);
  }, [departmentId, equipmentClassId, shouldLoadOptions]);


  return (
    <AutocompleteContainer
        id={id}
        noOptionsMessage={NoDataLabelTrans}
        loadOptionsActionCreator={loadOptionsActionCreator}
        title={OperationLabelTrans}
        placeholder={NotSelectedLabelTrans}
        {...props}
    />
  );
};

const _getOperationsLoadOptionsActionCreator = (departmentId, equipmentClassId) =>
  createCaEntitiesAutocompleteLoadOptionsActionCreator({
    requestModel: OPERATION_MODEL,
    getRequestQuery: inputValue => {

      const filters = [
        {
          column: [DEPARTMENT_MODEL, 'id'].join('__'),
          filterType: FILTER_TYPES.EQUALS,
          filterValue: departmentId,
        },
        {
          column: [EQUIPMENT_CLASS_MODEL, 'id'].join('__'),
          filterType: FILTER_TYPES.EQUALS,
          filterValue: equipmentClassId,
        },
      ];

      /*
      Если задан пропс shouldPreloadData, то при первом запросе inputValue будет пустым, поэтому добавляем эти фильтры
      только если value есть
      */
      if (inputValue) {
        filters.push({
          filterGroupType: FILTER_GROUP_TYPES.OR,
          filters: [
            {
              column: 'name',
              filterType: FILTER_TYPES.CONTAINS,
              filterValue: inputValue,
            },
            {
              column: 'identity',
              filterType: FILTER_TYPES.CONTAINS,
              filterValue: inputValue,
            },
            {
              column: 'nop',
              filterType: FILTER_TYPES.CONTAINS,
              filterValue: inputValue,
            },
          ],
        });
      }

      return {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters,
        },
        limit: 70,
      };
    },
    getRequestOptions: () => ({
      modelRelations: {
        [SHEET_OPERATION_MODEL]: {
          level: 1,
        },
        [DEPARTMENT_MODEL]: {
          level: 2,
          relates: SHEET_OPERATION_MODEL,
        },
        [EQUIPMENT_CLASS_MODEL]: {
          level: 2,
          relates: SHEET_OPERATION_MODEL,
        },
      },
    }),
  });


OperationsAutocomplete.propTypes = {
  ...AUTOCOMPLETE_PROP_TYPES,
  departmentId: NUMBER_OR_STRING_TYPE,
  equipmentClassId: NUMBER_OR_STRING_TYPE,
  shouldLoadOptions: PropTypes.bool,
};

OperationsAutocomplete.defaultProps = {
  ...AUTOCOMPLETE_DEFAULT_PROPS,
  shouldLoadOptions: true,
  getOptionLabel: ({ nop, name, identity }) => getSheetOperationCombinedName(nop, name, identity),
  getOptionValue: ({ id }) => id,
};