import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextFormField } from '../../TextFormField/TextFormField';
import { dateComparator, fieldComparatorFactory } from '@bfg-frontend/utils/lib/array';
import { Button, Grid } from '@mui/material';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import {
  ResetLabelTrans,
  SaveLabelTrans,
} from '../../../../utils/commonTransComponents';
import { Trans } from '@lingui/macro';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';
import { clearSheetOperationFeaturesValues } from '../../../../reducers/sheetOperationReview/actions';
import { useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';

import './style.css';
import { useConfirmOnLeave } from '../../../../hoc/confirmOnLeave/confirmOnLeave';
import { SHEET_OPERATION_FEATURES_VALUES_TYPE } from './constants';
import {
  SHEET_OPERATION_TRANSACTIONS_TABLE_DATA_TYPE,
} from '../../../Sheets/SheetOperationTransactionsTable/constants';
import _get from 'lodash/get';
import { deepPlainCopy } from '@bfg-frontend/utils/lib/deepPlainCopy';


export const SheetOperationFeaturesValuesForm = props => {
  const {
    sheetOperationFeaturesValues,
    onSubmit,
    sheetOperationId,
    fetchSheetOperationFeaturesValuesAndAddToStore,
    isFormDisabled,
  } = props;

  const dispatch = useDispatch();

  /*
  в стейте храним только поля, value которых было отредактировано
  */
  const [changedFieldsDataById, setChangedFieldsDataById] = useState({});

  const sheetOperationFeaturesValuesOnMountRef = useRef(deepPlainCopy(sheetOperationFeaturesValues));

  /*
  Сортируем поля формы по дате создания, чтобы при обновлении списка полей их порядок всегда был одинаковым
  */
  const fieldsDataSortedArray = useMemo(
    () => Object
      .values(sheetOperationFeaturesValues)
      .sort(fieldComparatorFactory(dateComparator, 'createStamp')),
    [sheetOperationFeaturesValues],
  );

  const areFieldsDataChanged = useMemo(
    () => (
      !_isEmpty(changedFieldsDataById) &&
      Object
        .keys(changedFieldsDataById)
        .some(sheetOperationFeatureId => {
          const initialValue = sheetOperationFeaturesValues[sheetOperationFeatureId].value;

          return changedFieldsDataById[sheetOperationFeatureId].trim() !== _getInitialValueForTextField(initialValue);
        })
    ),
    [changedFieldsDataById, sheetOperationFeaturesValues],
  );


  /*
  TODO Сейчас useConfirmOnLeave срабатывает только при перезагрузке страницы, для закрытия модельника просмотра операции он
  не работает, потому что модальник реализован без роутинга. После переноса окна просмотра операции на отдельный роут
  всё должно заработать, нужно будет перепроверить
  */
  useConfirmOnLeave(
    ({ areFieldsDataChanged }) => areFieldsDataChanged,
    {
      areFieldsDataChanged,
    },
  );

  const fieldChangeHandler = useCallback((newTextFieldValue, sheetOperationFeatureId) => {
    setChangedFieldsDataById(prevState => {

      const initialValue = sheetOperationFeaturesValues[sheetOperationFeatureId].value;

      /*
      Если новое значение равно значению из начального стейта, то удаляем поле из changeFieldsDataById
      */
      if (newTextFieldValue === _getInitialValueForTextField(initialValue)) {
        return _omit(prevState, sheetOperationFeatureId);
      }

      return {
        ...prevState,
        [sheetOperationFeatureId]: newTextFieldValue,
      };
    });
  }, [sheetOperationFeaturesValues, setChangedFieldsDataById]);


  const resetFieldsDataState = useCallback(() => {
    setChangedFieldsDataById({});
  }, [setChangedFieldsDataById]);

  const submitHandler = useCallback(e => {
    e.preventDefault();

    const sheetOperationFeaturesValuesEntitiesToCreate = [];
    const sheetOperationFeaturesValuesEntitiesToEdit = [];

    Object
      .keys(changedFieldsDataById)
      .forEach(sheetOperationFeatureId => {

        const initialSheetOperationFeature = sheetOperationFeaturesValues[sheetOperationFeatureId];

        /*
        * Кейс, когда в sheetOperationFeaturesValues нет ключа, который есть в changedFieldsDataById возможен, в
        * случае удаления характеристики другим пользователем и обновлении sheetOperationFeaturesValues, но пока ещё
        * без обновления changedFieldsDataById. Это очень маловероятный случай, но даже для порядка отсутствие ключа
        * стоит обработать на всякий случай
        * */
        if(initialSheetOperationFeature === undefined) {
          return;
        }

        const initialValueForFeature = initialSheetOperationFeature.value;

        const newValueForFeature = changedFieldsDataById[sheetOperationFeatureId];
        const trimmedNewValueForFeature = newValueForFeature.trim();

        /*
        * Если для ключа характеристики было изменено значение (т.е. он есть в changedFieldsDataById), а
        * начальное значение для этого ключа null, то значит, что нужно создать модель значения для характеристики
        * на операцию.
        * Дополнительно проверяем кейс, когда новое введенное значение - это строка с пробелами в начале или конце,
        * т.к. это идентично тому, что значение не вводилось. Это важно, т.к. areFieldsDataChanged может быть равно
        * true, т.к. изменено другое поле, а текущее поле сохранять не нужно, т.к. в нём только пробелы
        */
        if(initialValueForFeature === null && trimmedNewValueForFeature !== '') {
          sheetOperationFeaturesValuesEntitiesToCreate.push({
            sheetOperationId,
            sheetOperationFeatureId,
            value: trimmedNewValueForFeature,
          });

          return;
        }

        /*
        * Может показаться, что все остальные ключи, кроме прошлого ифа - это отредактированные характеристики, потому
        * что они среди "измененных" и по ним уже ранее вводились значения. НО здесь есть особенный кейс, когда
        * характеристики изменяет кто-то параллельно. В этом случае, начальные sheetOperationFeaturesValues обновится
        * и среди значений, которые изменял текущий пользователь (которые в changedFieldsDataById), изначально
        * новые значения могут стать уже "измененными", а изначально измененные, могут перестать быть такими, потому
        * что параллельно с нами ввели такие же значения, как и локально сейчас в changedFieldsDataById.
        * Поэтому на сабмит важно, сделать проверку на измененные, чтобы гарантировать корректный запрос.
        *
        * Во втором описанном кейсе, когда измененные становятся неизмененными, в интерфейсе по-прежнему будет
        * отображаться, что пользователь изменил данные (хотя по факту, они уже не измененные). Но в логике формы это
        * нормальные, мы просто показываем, что текущий пользователь вносил изменения, это логично. А, логика самих
        * запросов далее корректно отработает, благодаря этой проверке
         */


        /*
        * trimmedNewValueForFeature не проверяется на null (т.к. это невозможно в логике формы), а пустая строка, в случае
        * изменения значения - это валидное значение, его нужно записать именно таким, чтобы было понятно, что
        * характеристику именно редактировали и сбросили значение. В том числе, поэтому, в таком кейсе модель значений
        * не удаляется. Это может быть полезно, в будущем, когда тут будут записываться транзакции по характеристикам
        * на операцию
        * */
        if(initialValueForFeature !== trimmedNewValueForFeature) {
          sheetOperationFeaturesValuesEntitiesToEdit.push({
            sheetOperationId,
            sheetOperationFeatureId,
            value: trimmedNewValueForFeature,
          });
        }

      });


    onSubmit({
      sheetOperationId,
      sheetOperationFeaturesValuesEntitiesToCreate,
      sheetOperationFeaturesValuesEntitiesToEdit,
    })
      /*
      После сабмита ресетим состояние формы и делаем перезапрос данных, чтобы значения полей соответствовали серверным.
      Это самый простой способ обработки, т.к. логика выполнения запросов и логика самой формы довольно сложная:

      Во время работы с формой и изменении changedFieldsDataById может произойти обновление пропса
      sheetOperationFeaturesValues (если другой пользователь изменил характеристики той же операции). В этом случае,
      нам важно не сбросить введенные значения текущим пользователем, но обновить те, которые он не редактировал.
      Это реализует сама форма из-за принципа отображения данные на основании changedFieldsDataById и
      sheetOperationFeaturesValues. Всё это может усложниться ещё тем, что мы сохраняем введенные текущим пользователем
      характеристики несколькими запросами и в массивах, и возможны кейсы, когда данные сохранятся частично.

      Для всех этих кейсов на сабмит лучше гарантировано выполнить перезапрос и показать пользователю именно те
      значения, которые в базе после действия сабмита. Для кейса, когда бывают частичные ошибки запросов выводится в
      дополнительно нотификейшен в saveSheetOperationFeaturesValues

      */
      .then(() =>
        fetchSheetOperationFeaturesValuesAndAddToStore(sheetOperationId)
          .then(resetFieldsDataState));
  }, [
    onSubmit,
    changedFieldsDataById,
    sheetOperationFeaturesValues,
    resetFieldsDataState,
    fetchSheetOperationFeaturesValuesAndAddToStore,
    sheetOperationId,
  ]);

  /*
  * Проверка кейсов, когда в changedFieldsDataById есть характеристики, которых больше нет после обновления
  * sheetOperationFeaturesValues (например, в случае, когда характеристику удалили)
  * */
  useEffect(
    () => {

      setChangedFieldsDataById(
        prevState => {

          const featuresToOmit = Object
            .keys(prevState)
            .filter(featureId => !sheetOperationFeaturesValues[featureId]);

          if(featuresToOmit.length === 0) {
            return prevState;
          }

          return _omit(prevState, featuresToOmit);
        },
      );
    },
    [sheetOperationFeaturesValues, setChangedFieldsDataById],
  );

  useEffect(() => 
     () => dispatch(clearSheetOperationFeaturesValues())
  // eslint-disable-next-line
  , []);

  if (fieldsDataSortedArray.length === 0) {
    return (
      <div className="sheet-operations-features-form__no-data-text">
        <Trans id="sheet_operation_review@no_features_for_this_operation">
          Для этой операции нет дополнительных характеристик
        </Trans>
      </div>
    );
  }

  return (
    <form className="sheet-operations-features-form" onSubmit={submitHandler}>

      <Grid container spacing={2}>
        {
          fieldsDataSortedArray
            .map(({ sheetOperationFeatureId, name, value: initialValue }) => {

              const valueFromChangedFieldsData = changedFieldsDataById[sheetOperationFeatureId];

              const textFieldValue = valueFromChangedFieldsData === undefined  ?
                _getInitialValueForTextField(initialValue) :
                valueFromChangedFieldsData;

              const initialValueOnMount = _get(
                sheetOperationFeaturesValuesOnMountRef,
                ['current', sheetOperationFeatureId, 'value'],
                null,
              );

              /*
              * Запрещаем редактировать значения характеристик после того, как было закрыто модальное окно
              * просмотра операции, т.е. значение, характеристики, которое было на маунт компонента, которое
              * записывается в sheetOperationFeaturesValuesOnMountRef не равно null
              */
              const isInputDisabled = isFormDisabled || initialValueOnMount !== null;

              return (
                <Grid key={sheetOperationFeatureId} item xs={6}>
                  <TextFormField
                      label={name}
                      isDisabled={isInputDisabled}
                      value={textFieldValue}
                      onChange={newTextFieldValue => fieldChangeHandler(newTextFieldValue, sheetOperationFeatureId)}
                      wrapperClassName="sheet-operations-features-form__text-field-wrapper"
                      variant={MATERIAL_UI_VARIANT.OUTLINED}
                  />
                </Grid>
              );
            })
        }
      </Grid>

      {
        areFieldsDataChanged &&
        <div className="sheet-operations-features-form__actions">
          <Button
              color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
              onClick={resetFieldsDataState}
              className="sheet-operations-features-form__btn"
          >
            {ResetLabelTrans}
          </Button>
          <Button
              className="sheet-operations-features-form__btn"
              type="submit"
          >
            {SaveLabelTrans}
          </Button>
        </div>
      }

    </form>
  );
};

/*
* Начальное value значения характеристики, которая никогда не заполнялась === null. null в начальных данных
* оставлен намеренно, чтобы можно было вычислить какие значения характеристик отредактированы и какие были
* заполнены впервые. Для текстового поля null не подходит, это эквивалентно пустой строке
*/
const _getInitialValueForTextField = initialValue =>
  initialValue === null ?
    '' :
    initialValue;

SheetOperationFeaturesValuesForm.propTypes = {
  sheetOperationFeaturesValues: SHEET_OPERATION_FEATURES_VALUES_TYPE.isRequired,
  fetchSheetOperationFeaturesValuesAndAddToStore: FUNC_IS_REQUIRED_TYPE,
  onSubmit: FUNC_IS_REQUIRED_TYPE,
  sheetOperationId: PropTypes.number.isRequired,
  isFormDisabled: PropTypes.bool,
  sheetOperationTransactionsTableData: SHEET_OPERATION_TRANSACTIONS_TABLE_DATA_TYPE,
};

SheetOperationFeaturesValuesForm.defaultProps = {
  sheetOperationFeaturesValues: {},
  isFormDisabled: false,
};