import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Trans } from '@lingui/macro';
import TextField from '@mui/material/TextField';
import _isEmpty from 'lodash/isEmpty';
import './style.css';
import _isNil from 'lodash/isNil';
import _isEqual from 'lodash/isEqual';
import { REQUEST_BODY_FIELDS_SERVER_ERRORS } from '../../../../../constants/serverErrors/requestBodyFieldsServerErrors';
import { SERVER_ERROR_IDENTITY } from '../../../../../constants/serverErrors/index';
import { FUNC_IS_REQUIRED_TYPE, NUMBER_OR_STRING_TYPE } from '../../../../../constants/propTypes';
import { MATERIAL_UI_DIALOG_MAX_WIDTH, MATERIAL_UI_VARIANT } from '../../../../../constants/materialUI';
import { SimpleConfirmDialog } from '../../../SimpleConfirmDialog/SimpleConfirmDialog';
import {
  CancelLabelTrans,
  CreateLabelTrans,
  IdentityLabelTrans,
  NameLabelTrans,
  RequestBodyFieldUnknownErrorLabelTrans,
} from '../../../../../utils/commonTransComponents';
import _mapValues from 'lodash/mapValues';


export const EquipmentEntityCreationForm = props => {

  const [name, setName] = useState('');
  const [identity, setIdentity] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const {
    isOpen,
    onClose,
    departmentId,
    equipmentClassId,
    createEquipmentEntity,
    addAutocompleteOptions,
    setSheetOperationEquipment,
  } = props;

  const clearForm = useCallback(() => {
    setName('');
    setIdentity('');
    setFormErrors({});
  }, [setName, setIdentity, setFormErrors]);

  const formDataRef = useRef({ name, identity });

  useEffect(() => {
    formDataRef.current = { name, identity };
  });

  const handleFormSubmit = useCallback(
    () => {

      /*
      * Перед вызовом колбэка сабмита формы, обрезаем пробелы в начале и в конце строки для значений из текстовых полей
      * */
      const formDataToSubmit = _mapValues(
        formDataRef.current,
        (value => value.trim()),
      );

      createEquipmentEntity(formDataToSubmit)
        .then(({ validationErrors, data: equipmentId }) => {

          /*
          * Если запрос выполнился без ошибок (сущность единицы оборудования успешно создана):
          * - создаем новую опцию для селекта оборудования на основе полученных из ответа на запрос данных
          * - добавляем созданную опцию к уже имеющимся опциям селекта оборудования
          * - выполняем запрос на точку назначения оборудования
          * */
          if(_isEmpty(validationErrors)) {
            const createdEquipmentEntity = {
              id: equipmentId,
              departmentId,
              equipmentClassId,
              ...formDataToSubmit,
            };

            addAutocompleteOptions([createdEquipmentEntity]);

            setSheetOperationEquipment(createdEquipmentEntity);

            clearForm();
            return onClose();
          }

          const formErrors = Object
            .keys(validationErrors)
            .reduce(
              (acc, key) => {
                /*
                * Сервер в ответ присылает массив ошибок для каждого поля, чтобы иметь возможность описать сразу
                * несколько ошибок для поля. Мы же хотим отображать в данный момент только одну ошибку, т.к. места под
                * отображение ошибок не так много. Поэтому записываем в стейт только первую из ошибок
                * */
                acc[key] = {
                  errorValue: formDataToSubmit[key],
                  errorMsg: validationErrors[key][0].message,
                };

                return acc;
              },
              {},
            );

          return setFormErrors(formErrors);
        });
    },
    [
      createEquipmentEntity,
      departmentId,
      equipmentClassId,
      addAutocompleteOptions,
      setSheetOperationEquipment,
      clearForm,
      onClose,
      formDataRef,
      setFormErrors,
    ],
  );

  const nameError = getError(formErrors.name, name);
  const identityError = getError(formErrors.identity, identity);

  const isSubmitBtnDisabled = !!(nameError || identityError);

  return (
    <SimpleConfirmDialog
        dialogMaxWidth={MATERIAL_UI_DIALOG_MAX_WIDTH.SM}
        isOpen={isOpen}
        closeDialog={onClose}
        title={
          <Trans id="sheet_operation_review.create_equipment@dialog_title">
            Создание единицы оборудования
          </Trans>
        }
        confirmBtn={CreateLabelTrans}
        additionalComponent={
          <div className="equipment-entity-creation-form">
            {
              _renderTextField({
                fieldKey: 'identity',
                label: IdentityLabelTrans,
                value: identity,
                error: identityError,
                onChange: setIdentity,
              })
            }
            {
              _renderTextField({
                fieldKey: 'name',
                label: NameLabelTrans,
                value: name,
                error: nameError,
                onChange: setName,
              })
            }
          </div>
        }
        cancelBtn={CancelLabelTrans}
        onConfirm={handleFormSubmit}
        disableConfirm={isSubmitBtnDisabled}
    />
  );
};

EquipmentEntityCreationForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: FUNC_IS_REQUIRED_TYPE,
  departmentId: NUMBER_OR_STRING_TYPE.isRequired,
  equipmentClassId: NUMBER_OR_STRING_TYPE.isRequired,
  createEquipmentEntity: FUNC_IS_REQUIRED_TYPE,
  addAutocompleteOptions: FUNC_IS_REQUIRED_TYPE,
  setSheetOperationEquipment: FUNC_IS_REQUIRED_TYPE,
};


const EQUIPMENT_ENTITY_CREATE_REQUEST_SERVER_ERRORS_TRANS_MAP = {
  [SERVER_ERROR_IDENTITY.EQUIPMENT_IDENTITY_ALREADY_EXISTS]: (
    <Trans id="sheet_operation_review.create_equipment@equipment_already_exists_error">
      Оборудование с таким идентификатором уже существует в системе
    </Trans>
  ),
  [REQUEST_BODY_FIELDS_SERVER_ERRORS.CAN_NOT_BE_NULL]: (
    <Trans id="sheet_operation_review.create_equipment@field_is_required_error">
      Обязательное поле
    </Trans>
  ),
};

const getError = (formDataError, value) => {
  if(_isNil(formDataError)) return null;

  const {
    errorMsg,
    errorValue,
  } = formDataError;

  /*
  * В formDataError записываются подготовленные данные для ключа, которые отправляются в обработчик сабмита формы. Для
  * строковых значений при подготовке данных выполняется trim(). Именно эти значения и попадают в эту функцию
  * в errorValue. Поэтому, чтобы корректно сравнить, что текущее значение строкового поля value идентично тому, с
  * которым была ошибка при прошлом сабмите, тоже выполняем для него trim(), иначе добавив пробел в начале или конце
  * после ошибочного сабмита в форме будет пропадать идентификация ошибки, хотя фактически были просто добавлены
  * незначащие пробелы.
  * */

  if(!_isEqual(errorValue, value.trim())) {
    return null;
  }

  return (
    EQUIPMENT_ENTITY_CREATE_REQUEST_SERVER_ERRORS_TRANS_MAP[errorMsg] ||
    RequestBodyFieldUnknownErrorLabelTrans
  );
};

const _renderTextField = options => {
  const {
    fieldKey,
    label,
    value,
    error,
    onChange,
  } = options;

  return (
    <TextField
        variant={MATERIAL_UI_VARIANT.STANDARD}
        InputLabelProps={{ shrink: true }}
        className={cn(
          'equipment-entity-creation-form__text-field',
          {
            'equipment-entity-creation-form__text-field--error': !!error,
          },
        )}
        id={fieldKey}
        label={label}
        error={!!error}
        value={value}
        fullWidth
        onChange={e => onChange(e.target.value)}
        helperText={error}
        InputProps={{
          className: 'equipment-entity-creation-form__text-field-input-wrap',
        }}
    />
  );
};
