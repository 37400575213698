import { getUniqId } from '@bfg-frontend/utils/lib/getUniqId';

export const BLOCKING_ASYNC_ACTION_STARTED = 'BLOCKING_ASYNC_ACTION_STARTED';
export const BLOCKING_ASYNC_ACTION_FINISHED = 'BLOCKING_ASYNC_ACTION_FINISHED';

export const blockingAsyncActionStarted = asyncActionUniqId => ({
  type: BLOCKING_ASYNC_ACTION_STARTED,
  asyncActionUniqId,
});

export const blockingAsyncActionFinished = asyncActionUniqId => ({
  type: BLOCKING_ASYNC_ACTION_FINISHED,
  asyncActionUniqId,
});

export const blockingAsyncActionWrap = asyncAction =>
  dispatch => {
    const asyncActionUniqId = getUniqId();

    dispatch(blockingAsyncActionStarted(asyncActionUniqId));
    const blockingAsyncActionFinishedCb = () => dispatch(blockingAsyncActionFinished(asyncActionUniqId));

    return asyncAction()
      .then(result => {
        blockingAsyncActionFinishedCb();
        return result;
      })
      .catch(error => {
        blockingAsyncActionFinishedCb();
        return Promise.reject(error);
      });
  };
