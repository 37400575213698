import React, { useCallback, useState } from 'react';

import { FUNC_IS_REQUIRED_TYPE, OBJECT_OF_ANY_TYPE, USER_DATA_TYPE } from '../../constants/propTypes';

import IconButton from '@mui/material/IconButton';
import { AccountCircle, Person, PowerSettingsNew } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';

import { getUserLastNameWithInitials } from '@bfg-frontend/utils/lib/stringBuilders/entity';

import './style.css';
import Button from '@mui/material/Button';
import { LogoutLabelTrans, UserFullNameLabelTrans, UserIdentityLabelTrans } from '../../utils/commonTransComponents';
import { MATERIAL_UI_STYLE_COLOR } from '../../constants/materialUI';


const menuAnchorParams = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const AppTypeTopBarUserMenu = props => {
  const {
    logout,
    userData,
    menuIconButtonProps,
  } = props;

  const [menuAnchor, setMenuAnchor] = useState(null);

  const _handleMenuOpen = useCallback(e => setMenuAnchor(e.currentTarget), [setMenuAnchor]);
  const _handleMenuClose = useCallback(() => setMenuAnchor(null), [setMenuAnchor]);

  const userFullName = getUserLastNameWithInitials(userData);

  return (
    <div className="app-type-top-bar-user-menu">
      <div className="app-type-top-bar-user-menu__menu-icon-button-wrap" onClick={_handleMenuOpen}>
        <IconButton
            className="app-type-top-bar-user-menu__menu-icon-button"
            {...menuIconButtonProps}
            size="large"
        >
          <AccountCircle />
        </IconButton>
      </div>
      <Menu
          {...menuAnchorParams}
          className="app-type-top-bar-user-menu__menu"
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={_handleMenuClose}
      >
        <Card>
          <div className="app-type-top-bar-user-menu__card-header">
            <Avatar className="app-type-top-bar-user-menu__avatar">
              <Person/>
            </Avatar>
          </div>
          <CardContent className="app-type-top-bar-user-menu__card-content">
            <Divider/>
            {
              _renderContentRow(
                UserFullNameLabelTrans,
                userFullName,
              )
            }
            {
              _renderContentRow(
                UserIdentityLabelTrans,
                userData.identity,
              )
            }
            <Divider/>
          </CardContent>
          <CardActions className="app-type-top-bar-user-menu__action-button-block">
            <Button
                className="app-type-top-bar-user-menu__action-button"
                startIcon={<PowerSettingsNew/>}
                onClick={logout}
                color={MATERIAL_UI_STYLE_COLOR.INHERIT}
            >
              {LogoutLabelTrans}
            </Button>
          </CardActions>
        </Card>
      </Menu>
    </div>
  );
};

const _renderContentRow = (label, content) => (
  <div className="app-type-top-bar-user-menu__card-content-row">
    <label>{label}</label>
    <span>{content}</span>
  </div>
);

AppTypeTopBarUserMenu.propTypes = {
  logout: FUNC_IS_REQUIRED_TYPE,
  userData: USER_DATA_TYPE,
  menuIconButtonProps: OBJECT_OF_ANY_TYPE,
};
