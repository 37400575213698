import { SHEET_OPERATION_TRANSACTION_MODEL } from '../../../constants/models';
import PropTypes from 'prop-types';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';

export const createSheetOperationTransactionsTableId =
  sheetOperationId => [SHEET_OPERATION_TRANSACTION_MODEL, sheetOperationId].join('--');

export const SHEET_OPERATION_TRANSACTIONS_TABLE_DATA_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    id: NUMBER_OR_STRING_TYPE.isRequired,
    startDate: PropTypes.string.isRequired,
    stopDate: PropTypes.string,
    stopProgress: PropTypes.number,
  }),
);