import { Transformer } from '../Transformer';

export class SheetOperationFeaturesValueDataPoint extends Transformer {
  static transformToState(item) {
    const {
      entityRouteSheetOperationFeatureTargetId: sheetOperationFeatureTargetId,
      entityRouteSheetOperationFeatureId: sheetOperationFeatureId,
      entityRouteSheetOperationId: sheetOperationId,
      entityRouteSheetOperationFeatureName: name,
      entityRouteSheetOperationFeatureValue: value,
      entityRouteSheetOperationFeatureCreateStamp: createStamp,
    } = item;

    return {
      sheetOperationId,
      sheetOperationFeatureId,
      name,
      value,
      createStamp,
      sheetOperationFeatureTargetId,
    };
  }
}