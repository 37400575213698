import { SET_COLLAPSIBLE_IS_OPEN } from './actions';

export const COLLAPSIBLE_INITIAL_STATE = {};

export const collapsibleReducer = (state = COLLAPSIBLE_INITIAL_STATE, action) => {

  const { type, isOpen, id } = action;

  switch (type) {

  case SET_COLLAPSIBLE_IS_OPEN: {
    return {
      ...state,
      [id]: isOpen,
    };
  }

  default:
    return state;
  }
};
