import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import './style.css';
import Collapse from '@mui/material/Collapse';
import cn from 'classnames';

export const Collapsible = props => {

  const {
    onChange,
    id,
    children,
    isOpen,
    header,
    className,
  } = props;

  const _toggleOpen = () => onChange(!isOpen);

  const iconClassNames = cn('collapsible__header-icon', {
    'collapsible__header-icon--closed': !isOpen,
  });

  return (
    <div  className={cn('collapsible', className)} id={id}>
      <div onClick={_toggleOpen} className="collapsible__header">
        <ExpandMoreIcon className={iconClassNames}/>
        <div className="collapsible__header-content">
          { header }
        </div>
      </div>
      <Collapse in={isOpen}>
        <div className="collapsible__content">
          { children }
        </div>
      </Collapse>
    </div>
  );
};

Collapsible.propTypes = {
  onChange: FUNC_IS_REQUIRED_TYPE,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  header: PropTypes.node.isRequired,
  className: PropTypes.string,
};