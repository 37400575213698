import React from 'react';
import IconButton from '@mui/material/IconButton';
import Refresh from '@mui/icons-material/Refresh';
import { MATERIAL_UI_SIZE } from '../../constants/materialUI';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './style.css';


export const ReloadIconButton = ({ className, size }) => {
  return (
    <IconButton
        className={
          cn(
            'reload-icon-button',
            className,
          )
        }
        onClick={() => document.location.reload()}
        size={size}
    >
      <Refresh />
    </IconButton>
  );
};

ReloadIconButton.defaultProps = {
  size: MATERIAL_UI_SIZE.LARGE,
};

ReloadIconButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(MATERIAL_UI_SIZE)),
};