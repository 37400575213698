import { createSheetTypeOperationsTableId } from '../../utils/tables';
import { sheetOperationsRemoteTableDataSelector } from '../../selectors/sheets';
import { allTasksTablesIdsSelector } from '../../selectors/taskView';
import { bindActionCreators, compose } from 'redux';
import {
  broadcastSheetResumed,
  fetchReviewedSheetOperationsRemoteTableEntities,
  resumeSheet,
  sendSheetResumedNotification,
} from '../../operations/sheets';
import { PAUSED_SHEETS_TABLES_IDS_ARRAY } from '../../constants/table';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../reducers/table/actions';
import { SHEET_TYPE } from '../../constants/sheets';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../MasterApp/MasterTasksToDo/constants';
import {
  clearAllDepartmentsWithTasks,
  clearAllEquipmentClassesInDepartmentWithTask,
} from '../../reducers/workerApp/tasksOwners/actions';
import { connect } from 'react-redux';
import { withPermissionsManager } from '../../hoc/withPermissionsManager/withPermissionsManager';
import { PausedSheetReviewContent } from './PausedSheetReviewContent/PausedSheetReviewContent';


/*
* Функция создает связку компонент + контейнер для отображения модального окна операций маршрутного листа и окна просмотра
* данных по выбранной операции маршрутного листа
* */
export const pausedSheetReviewContentFactory = pausedSheetsTableId => {

  /*
  * находим идентификаторы таблиц приостановленных МЛ и отфильтровываем текущую таблицу.  Для текущей нужно будет
  * выполнить перезапрос данных а для всех остальных - сбросить кэш как для самих таблиц, так и для таблиц
  * операций возобновляемого МЛ
  * */
  const pausedSheetsTableToClearCacheIds = PAUSED_SHEETS_TABLES_IDS_ARRAY
    .filter(tableId => tableId !== pausedSheetsTableId);


  const mapStateToProps = (state, { sheetToReviewData }) => {
    const {
      sheetId,
      entitiesInBatchAmount,
    } = sheetToReviewData;

    const sheetOperationsTableId = createSheetTypeOperationsTableId(pausedSheetsTableId, sheetId);

    return {
      sheetOperationsTableId,
      sheetOperationsTableData: sheetOperationsRemoteTableDataSelector(
        state,
        { sheetOperationsTableId, entitiesInBatchAmount },
      ),
      allTasksTablesIds: allTasksTablesIdsSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch, { fetchSheetsData }) => ({
    ...bindActionCreators(
      {
        fetchReviewedSheetOperationsRemoteTableEntities,
        resumeSheet,
      },
      dispatch,
    ),


    /*
    * Влияние события возобновления МЛ на разные типы приложения и обработка этого с учетом того, что возобновление
    * происходит на экране просмотра приостановленного МЛ:
    *
    * 1. Плановик.
    * 1.1. Раздел "МЛ в производстве". Возобновленный МЛ должен появиться в списке МЛ в производстве:
    *      - Очищаем хранилище этого списка (табличное хранилище по ID таблицы), чтобы при следующем входе в этот раздел
    *        был выполнен новый запрос.
    *
    * 1.2. Раздел "МЛ на запуск". Возобновленный МЛ не влияет на этот раздел
    *
    * 1.3  Раздел "МЛ приостановленные". Возобновленный МЛ должен исчезнуть из списка "МЛ на паузе":
    *      - Список серверный, поэтому если элемент списка исчезает со страницы, значит в списке должно произойти
    *      "смещение", т.е. подгрузиться новый элемент, если он есть, в соответствии с имеющейся сортировкой.
    *      Чтобы упросить обработку просто перезапрашиваем данные таблицы с идентификатором из pausedSheetsTableId для
    *      текущих параметров списка. Если возобновленный МЛ на странице последний, то логика табличной абстракции,
    *      через которую реализован список, обработает этот момент автоматически и перезапросит предыдущую страницу
    *      таблицы
    *      - Т.к. МЛ теперь  в производстве, то в разделе уже нельзя будет просматривать его операции, поэтому есть
    *      смысл очистить данные всех таблиц операций приостановленного МЛ, который был возобновлен (которые были
    *      запрошены при переходе из текущей таблицы просмотра МЛ). В разделе "МЛ. В производстве" при просмотре
    *      операций будет новая таблица с другим идентификатором
    *      - Необходимо также очистить данные по всем таблицам приостановленных маршрутных листов кроме текущей,
    *      идентификаторы таблиц хранятся в pausedSheetsTableToClearCacheIds
    *
    * 1.4. Раздел "МЛ завершенные". Возобновленный МЛ не влияет на этот раздел
    *
    * 2. Комплектование.
    * 2.1. Раздел "МЛ ожидающие комплектации". Если для возобновленного МЛ требуется комплектация, то он должен появиться
    *     в списке:
    *      - По идее, аналогично МЛ в производстве, нужно бы сбросить "кэш" по этой таблице, чтобы при следующем входе
    *        на этот экран был выполнен новый запрос. НО, в данный момент этот список осталась клиентским, т.к., сейчас
    *        перевести его на серверный не получается из-за особенностей сортировки и необходимости получать дополнительные
    *        параметры о том, могут ли МЛ быть укомплектованы. Поэтому в данный момент тут другая логика. Данные по МЛ
    *        хранятся в entities + специальном хранилище в store. Эти данные перезапрашиваются по новой при каждом входе в
    *        этот раздел, поэтому СЕЙЧАС НИЧЕГО НЕ ДЕЛАЕМ. Но, эта таблица тоже должна стать серверной, поэтому это нужно
    *        будет изменить, сейчас при таком обновлении будет запрашиваться много данных.
    * 2.2 Раздел "сборочные". Возобновление МЛ влияет на лейблы в списке сборочных МЛ, поэтмоу нужно сбросить кеш для
    * таблицы.
    *
    * 3. Мастер
    * 3.1 Раздел просмотра заданий Мастера. В списке "заданий, которые нужно сделать" должны появиться задания по
    *  восстановленному МЛ:
    *      - Очищаем кэш таблицы "заданий, которые нужно сделать". Мы не знаем затронуло ли возобновление МЛ задания
    *      для подразделений Мастера, поэтому вынуждены очищать кэш в любом случае. При следующем входе в эти раздел
    *      будет выполнен новый запрос и данные обновятся,
    *      - "Завершенные задания" у Мастера запрашиваются для всех МЛ, в том числе для приостановленных, т.к. это
    *      интефейс "истории выполненных работ". Поэтому возобновление МЛ не влияет на этот раздел, ничего очищать не
    *      нужно
    * 3.2 Раздел "МЛ приостановленные". Возобновленный МЛ должен исчезнуть из списка "МЛ на паузе":
    *      - Список серверный, поэтому если элемент списка исчезает со страницы, значит в списке должно произойти
    *      "смещение", т.е. подгрузиться новый элемент, если он есть, в соответствии с имеющейся сортировкой.
    *      Чтобы упросить обработку просто перезапрашиваем данные таблицы с идентификатором из pausedSheetsTableId для
    *      текущих параметров списка. Если возобновленный МЛ на странице последний, то логика табличной абстракции,
    *      через которую реализован список, обработает этот момент автоматически и перезапросит предыдущую страницу
    *      таблицы
    *      - Т.к. МЛ теперь  в производстве, то в разделе уже нельзя будет просматривать его операции, поэтому есть
    *      смысл очистить данные всех таблиц операций приостановленного МЛ, который был возобновлен (которые были
    *      запрошены при переходе из текущей таблицы просмотра МЛ). В разделе "МЛ. В производстве" при просмотре
    *      операций будет новая таблица с другим идентификатором
    *      - Необходимо также очистить данные по всем таблицам приостановленных маршрутных листов кроме текущей,
    *      идентификаторы таблиц хранятся в pausedSheetsTableToClearCacheIds
    *
    * 4. Рабочий.
    * 4.1. Раздел "Выбора подразделений". Задания приостановленных МЛ недоступны для выполнения, поэтому эти задания
    * не учитываются при отображении подразделений, в которых есть задания. Т.е., когда МЛ возобновляется, то
    * невыполненные задания по этому МЛ могут выполняться в подразделениях, в которых раньше не было заданий, т.е.
    * подразделений не было в списке. Поэтому список нужно обновить:
    *      - Очищаем все текущие подразделения в хранилище, чтобы при следующем входе в этот раздел был выполнен новый
    *        запрос.
    *
    * 4.2. Раздел "Выбора класса РЦ в подразделении". Задания приостановленных МЛ недоступны для выполнения, поэтому эти
    * задания не учитываются при отображении классов РЦ в подразделении, в которых есть задания. Т.е., когда МЛ
    * возобновляется, то невыполненные задания по этому МЛ могут выполняться на классах РЦ в подразделении, в которых
    * раньше не было заданий, т.е. классов РЦ не было в списке. Поэтому список нужно обновить.
    *      - Очищаем все текущие данные по классам РЦ в подразделениях в хранилище, чтобы при следующем входе в этот раздел
    *        был выполнен новый запрос.
    *
    * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Задания приостановленных МЛ недоступны для
    * выполнения, поэтому эти задания не отображаются в списке заданий. Т.е., когда МЛ возобновляется, то невыполненные
    * задания по этому МЛ должны появиться в таблице заданий:
    *      - Очищаем кэши всех таблиц просмотра заданий, т.к. не знаем какие именно затронуло возобновление нового МЛ. При
    *        следующем входе в эти раздел будет выполнен новый запрос и данные обновятся
    * */
    handleSheetResumed: (sheetId, sheetIdentity, allTasksTablesIds) => {

      //1.3 перезапрос
      dispatch(reFetchRemoteTableData(pausedSheetsTableId, pausedSheetsTableId, fetchSheetsData));

      //Выводим нотификейшен об успешном возобновлении МЛ
      sendSheetResumedNotification(sheetIdentity);

      dispatch([
        //1.3 очистка всех данных таблиц операций возобновленного МЛ для всех подобных таблиц
        clearTableData(
          PAUSED_SHEETS_TABLES_IDS_ARRAY
            .map(id => createSheetTypeOperationsTableId(id, sheetId)),
        ),

        //1.1, 2.2, 3.1, 3.2, 4.3
        clearTableRemoteData([
          SHEET_TYPE.IN_PRODUCTION,
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
          MASTER_TASKS_TO_DO_TABLE_ID,
          ...allTasksTablesIds,
          ...pausedSheetsTableToClearCacheIds,
        ]),

        //4.1
        clearAllDepartmentsWithTasks(),

        //4.2
        clearAllEquipmentClassesInDepartmentWithTask(),
      ]);

    },
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => {

    const {
      sheetOperationsTableId,
      sheetOperationsTableData,
      allTasksTablesIds,
    } = stateProps;

    const {
      fetchReviewedSheetOperationsRemoteTableEntities,
      resumeSheet,
      handleSheetResumed: handleSheetResumedFromDispatchProps,
    } = dispatchProps;
    const {
      sheetToReviewData,
      closeReviewDialog,
    } = ownProps;

    const fetchSheetOperations = ({ tableParams }) =>
      fetchReviewedSheetOperationsRemoteTableEntities(sheetToReviewData.sheetId, tableParams);

    return {
      sheetOperationsTableId,
      sheetOperationsTableData,
      sheetToReviewData,
      fetchSheetOperations,

      resumeSheet: () => {
        const {
          sheetId,
          sheetIdentity,
          entityBatchId,
        } = sheetToReviewData;

        return resumeSheet(entityBatchId)
          .then(() => {
            broadcastSheetResumed(sheetId, sheetIdentity);
            handleSheetResumedFromDispatchProps(sheetId, sheetIdentity, allTasksTablesIds);
            closeReviewDialog();
          });
      },
    };
  };

  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps,
    ),
    withPermissionsManager,
  )(PausedSheetReviewContent);
};
