import { compose } from 'redux';
import { connect } from 'react-redux';

import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';

import { fetchUserRoles } from '../../../operations/users';
import { userRoleSelectOptionsSelector } from '../../../selectors/usersAdministration';
import { UserRoleSelect } from './UserRoleSelect';


/*
* Контейнер для компонента обертка селекта выбора ролей пользователя. Контейнер работает вместе с компонентом
* UserRoleSelect, комментарий к самому компоненту файле компонента
*
* Имеющиеся в системе роли, в общем случае, являются неизвестными для клиента, т.к. предполагается, что они когда-то
* будут создаваться, в том числе, самими пользователями. Поэтому, чтобы отобразить опции селекта ролей, сначала
* нужно запросить роли с сервера. Этот запрос закладывается в логику самого компонента селекта ролей, т.к. это
* необходимые данные для его отображения.
* В данный момент принята логика, что полученные данные по ролям после запроса сохраняются просто в entities.
* Если какие-либо данные по модели ролей уже есть в entities (хотя бы 1 сущность), то считается, что роли уже
* запрашивались и повторно запрос не выполняется (это есть в логике самого экшена запроса fetchRoles). Подразумевается,
* что в случае создании новых ролей, когда это будет реализовано, они будут добавлены дополнительно в entities, в том
* числе при помощи броадкастинга этого события, чтобы быть в курсе создаваемых другими пользователями ролей.
* Вероятно, можно было бы подумать и о каком-то отдельном хранилище, но пока в этом нет какой-то явной необходимости,
* поэтому используется entities. Поэтому, и селектор опций userRoleSelectOptionsSelector собирает данные именно из
* entities
* */

const mapStateToProps = state => ({
  options: userRoleSelectOptionsSelector(state),
});

const mapDispatchToProps = {
  fetchUserRoles,
};

export const UserRoleSelectContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchUserRoles }) => fetchUserRoles(),
      },
    ],

    /*
    * Т.к. селект обычно является частью какого-то интерфейса или формы ввода данных, то если использовать
    * asyncComponent, как обычно, т.е. не отрисовывать компонент до окончания запроса, то получается, если запрос по
    * каким-то причинам длится так долго, что пользователь успевает это заметить, то, например, пользователь видит
    * форму на которой есть все поля и пустое поле селекта, который появляется чуть позже после окончания запроса.
    * Это не очень хорошее поведение, поэтому принято решение отрисовывать селект и во время запроса, и даже в
    * случае ошибки запроса. В принципе, с этим никаких проблем: запрос у нас такой, что провоцирует появление
    * глобального спиннера приложения, т.е. пользователь видит сам селект, но пока данные не запрошены, то не может
    * им пользоваться, т.е. не может посмотреть опции селекта и возмутиться, что их нет. В случае, если у селекта
    * есть выбранное значение\значения, то они сразу отобразятся, т.к. значение у селекта должно описывать себя
    * полностью, эти данные должны будут как-то запрошены отдельно в любом случае, для этого не нужны опции ролей.
    * */
    shouldRenderAlways: true,
  }),
)(UserRoleSelect);

UserRoleSelectContainer.displayName = 'UserRoleSelectContainer';