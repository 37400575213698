import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';


export const SimpleClickAwayListener = props => {
  const wrapperRef = useRef();

  const { onClickAway } = props;

  useEffect(
    () => {
      const handleClickOutside = e => {
        if (!wrapperRef.current || wrapperRef.current.contains(e.target)) return;
        onClickAway();
      };

      document.addEventListener('click', handleClickOutside);

      return () => document.removeEventListener('click', handleClickOutside);
    },
    [onClickAway],
  );

  return (
    <div ref={wrapperRef}>
      {props.children}
    </div>
  );
};

SimpleClickAwayListener.propTypes = {
  children: PropTypes.element.isRequired,
  onClickAway: FUNC_IS_REQUIRED_TYPE,
};

export const withSimpleClickAwayListener = Component =>
  // eslint-disable-next-line react/prop-types
  ({ onClickAway, ...rest }) => (
    <SimpleClickAwayListener onClickAway={onClickAway}>
      <Component {...rest}/>
    </SimpleClickAwayListener>
  );
