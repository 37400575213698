import { connect } from 'react-redux';

import { createSelector } from 'reselect';

import { appUserPermissionsSelector } from '../../reducers/appState/selectors';
import _constant from 'lodash/constant';


/**
 * HOC для работы с разрешениями текущего пользователя.
 * Добавляет к пропсам декорируемого компонента служебный объект пропс PermissionsManager, у которого есть ключи:
 *  - isGranted. Функция, которая может вызываться в компоненте с параметром разрешения (разрешения представлены в
 *  константе PERMISSION) и возвращает в ответ есть ли у текущего аутентифицированного пользователя (данные которого
 *  записаны в appState.userData) это разрешение. Пример вызова - PermissionsManager.isGranted(PERMISSION.START_SHEET)
 *  - isDenied. Всё аналогично isGranted, только возращает в ответ, наоборот, что у пользователя нет заданного
 *  разрешения. Т.е. результат противоположен isGranted. Добавлено для удобства. Пример вызова -
 *  PermissionsManager.isGranted(PERMISSION.START_SHEET)
 * Эти проверки часто бывают необходимы, чтобы разрешать или запрещать функционал в компонентах в зависимости от
 * доступных разрешений. Если в компоненте будет необходимо составить какую-то более сложную логику на основании
 * нескольких разрешений, то функции isGranted и isDenied можно выполнить несколько раз, например
 * (PermissionsManager.isGranted(PERMISSION.START_SHEET) || PermissionsManager.isGranted(PERMISSION.STOP_SHEET)) &&
 * PermissionsManager.isDenied(PERMISSION.SPLIT_ENTITY_BATCH)
 * */
export const withPermissionsManager = DecoratedComponent => {

  const mapStateToProps = state => ({
    PermissionsManager: permissionsMangerSelector(state),
  });

  const WithPermissionsManager = connect(mapStateToProps)(DecoratedComponent);

  WithPermissionsManager.displayName = 'WithPermissionsManager';

  return WithPermissionsManager;
};


/*
* Селектор расположен в этом же файле, чтобы было больше контекста. Да и HOC, по сути, только и формирует пропс,
* получаемый из этого селектора, располагать его в отдельном файле селекторов было бы усложнением
* */
const permissionsMangerSelector = createSelector(
  appUserPermissionsSelector,
  appUserPermissions => {

    /*
    * Если пользователь не аутентифицирован, т.е. его данные отсутствуют в appState.userData, а декорируемый
    * withPermissionsManger компонент отрисовывается и функции isGranted или isDenied могут быть вызваны вызваны, то
    * делаем так, чтобы в этом случае всегда возвразался false для isGranted и true для isDenied. Неаутентифицированному
    * пользователю доступ запрещен для всего.
    * */
    if(appUserPermissions === null)
      return {
        isGranted: _constant(false),
        isDenied: _constant(true),
      };

    /*
     * Фиксируем разрешения в Set вне функций, чтобы упростить проверки при вызове самих функций
     * */
    const appUserPermissionsSet = new Set(appUserPermissions);

    return {
      isGranted: permission => appUserPermissionsSet.has(permission),
      isDenied: permission => !appUserPermissionsSet.has(permission),
    };
  },
);