/*
* При принятии новой сессии для заданий, перелогиниваемся, чтобы точно быть в системе при дальнейшем
* запросе самой сессии, иначе может случится провал с обновлением данных в интерфейсах.
* */
import { fetchMainPlanningSession } from '../../../../operations/app';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import {
  fetchAssemblySheetsToStart,
  fetchSheetsToStart,
  saveSheetsToStartData,
} from '../../../../reducers/plannerApp/sheetsToStart/actions';
import { PLANNER_APP_SHEETS_TO_START_ROUTE } from '../../../../constants/routes';
import { NOTIFICATION_LEVEL, sendNotification } from '../../../../constants/notification';
import {
  sheetsToStartRequestParamsSelector,
  sheetsToStartSelectedTypeSelector,
} from '../../../../reducers/plannerApp/sheetsToStart/selectors';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { Trans } from '@lingui/macro';
import React from 'react';



/*
* Обработка события смены основной сессии моделирования:
* 1. Перезапрашиваем новую основную сессию моделирования и записываем её в стор (fetchMainPlanningSession)
* 2. Далее идет разная обработка для списков МЛ на запуск.
* - Для всех МЛ:
*   Если данные ранее не запрашивались, то ничего не делаем, т.к. МЛ на запуск в интерфейсе доступны только по требованию,
*   т.е. при следующем входе пользователь выберет нужные параметры и запросит данные, в этом случае обновления во время
*   наступления события не нужны. Если данные ранее запрашивались и хранятся в стор, то актуализируем. Если находимся в
*   разделе "Плановик. все МЛ на запуск", то нужно сразу же перезапросить данные, чтобы список обновился.
*   В остальных случаях просто очищаем кэш, чтобы при повторном входе в интерфейс не отображались неактуальные данные.
*   Новые данные в этом случае пользователь должен будет запросить "по требованию". Ещё подробнее о логике обновлений
*   в комментариях к коду в самом теле обработчика
* - для сборочных МЛ
*   Если пользователь находится в разделе Плановик. Сборочные МЛ на запуск, то необходимо перезапросить данные списка,
*   чтобы обновился интерфейс.
*   Если пользователь НЕ находится в этом разделе, то делать ничего не нужно, т.к. данные списка при входе в интерфейс
*   будут перезапрошены из-за того, что мы установили в п.2 новый идентфикатор основного расчета
* */
export const handleMainPlanningSessionChange = () =>
  (dispatch, getState) =>
    dispatch(fetchMainPlanningSession())
      .then(mainPlanningSessionEntity => {
        sendNotification(
          <Trans id="system_message@main_planning_session_changed">
            В системе было совершено перепланирование, данные по маршрутным листам на запуск были обновлены
          </Trans>,
          NOTIFICATION_LEVEL.INFO,
        );

        //Если случилась какая-то непридвиденная ситуация и сессию новую не удалось получить, то ничего не делаем
        if(!mainPlanningSessionEntity) return;

        const store = getState();

        const currentPathName = routerPathnameSelector(store);

        /*
        * получаем выбранный в интерфейсе на запуск тип листов для просмотра
        * */
        const sheetsToStartSelectedType = sheetsToStartSelectedTypeSelector(store);

        const isOnSheetToStartRoute = currentPathName.startsWith(PLANNER_APP_SHEETS_TO_START_ROUTE);

        handleAllSheetsToStartList(dispatch, store, isOnSheetToStartRoute, mainPlanningSessionEntity, sheetsToStartSelectedType);
        handleAssemblySheetsToStartList(dispatch, isOnSheetToStartRoute, mainPlanningSessionEntity, sheetsToStartSelectedType);

        /*
        * Если пользователь находимся в режиме просмотра МЛ на запуск, а мы перезапрашиваем данные МЛ на запуск, после
        * принятия новой сессии, то информация о текущем МЛ на запуск становится неактуальной, вероятно, с таким же
        * идентификатором, как сейчас, в новом расчете может быть совсем другая партия или та же партия с измененными
        * параметрами. Поэтому, лучше закрыть модальник просмотра МЛ и вернуться к списку МЛ
        * */
        const sheetToStartReviewRouteMatch = matchPath(currentPathName, {
          path: `${PLANNER_APP_SHEETS_TO_START_ROUTE}/:sheetId`,
        });

        if(sheetToStartReviewRouteMatch !== null) {
          dispatch(push(PLANNER_APP_SHEETS_TO_START_ROUTE));
        }
      });

const handleAllSheetsToStartList = (dispatch, store, isOnSheetToStartRoute, mainPlanningSessionEntity, sheetsToStartSelectedType) => {
  const { startDate, stopDate } = sheetsToStartRequestParamsSelector(store);

  /*
  * Если параметров для запроса не устанавливалось, т.е. пользователь ниразу не запрашивал данные по всем МЛ на
  * запуск, то ничего не делаем, когда пользователю понадобятся эти данные, он зайдёт в интерфейс, выберет
  * нужные даты в дейтпикере и только тогда запросит эти данные
  * */
  if(startDate === null && stopDate === null) {
    return;
  }

  /*
  * Если пользователь находится в разделе "Плановик. все МЛ на запуск" и данные он уже когда-то запрашивал (иначе
  * сработал бы предыдщий if), т.е. данные сейчас отображаются в интерфейсе, то, очевидно, что они неактульны
  * и их нужно обновить. Вызываем запрос всех МЛ на запуск с новой сессией и текущими параметрами дейтпикеров
  * */
  if(isOnSheetToStartRoute && sheetsToStartSelectedType === SHEET_TYPE.ALL_TO_START) {
    return dispatch(fetchSheetsToStart(mainPlanningSessionEntity.id, startDate, stopDate));
  }

  /*
  * Если пользователь НЕ находится в разделе "Плановик. все МЛ на запуск" (е выполнилось предыдущее условие) и
  * данные он уже когда-то запрашивал (иначе сработал бы предыдущий if), то текущие данные в стор по МЛ на запуск
  * неактуальны, нужно сбросить этот кэш, чтобы при последующем входе в раздел эти данные не отображались, а был
  * дейтпикер с установленными в прошлый раз данными с активной кнопкой "Применить".
  *
  * В качестве первого параметра - основной сессии - проставляем сразу новое значение основной сессии. На самом
  * деле, это может показаться и не очень то и логичным, т.к. в стор в данном случае не хранятся данные для этой
  * сессии, происходит просто сброс кэша данных. Но, при этом нам важно сохранить прошлые значения дейтпикеров,
  * чтобы пользователь не выбирал их по новой. Т.е., в теории, можно было бы сбросить и сессию и данные, но
  * оставить дейтпикеры. Возможно, и для дейтпикеров, в итоге, логичней было бы даже использовать какое-то
  * отдельное хранилище, т.к.хранилище данных МЛ на запуск могло бы всегда логично отображать комбинацию
  * параметров (сессия + даты), с которыми сейчас запрошены данные, поэтому их все логично было бы сбрасывать,
  * когда сбрасывается кэш. В этом случае и было бы меньше проблем с логикой вычисления того, что данные не
  * запрашивались и их нет в сторе. Но, с другой стороны, это, возможно, было бы некоторым усложнением, т.к.
  * приходилось бы манипулировать несколькими хранилище. Да и это, в целом, сейчас видится какая-то аналогия на
  * табличное хранилище, у которого мы, как правило, очищаем только данные (remoteData=null), сохраняя параметры
  * последнего запроса, чтобы с ними потом выполнить новый запрос. Просто в этом случае сессия сюда не очень
  * подходит, т.к. мы вынуждены её обновлять вместе со сбросом кэша, и, как правило, сессию мы всё равно из
  * других данных получаем для новых запросов. Пока решено попробовать такую реализацию, если появится путаница,
  * то можно этот момент переделать.
  *
  * В случае, когда сессия меняется достаточно часто и, мы, например, уже сбросили кэш данных, новые данные так
  * и не запросили, снова находимся не в разделе "Плановик. МЛ на запуск", а сессия снова изменилась, то снова
  * будет вызван этот же экшн, хотя, фактически, кэш уже был сброшен. Сейчас мы перезаписываем ещё сессию, поэтому
  * это выглядит хоть немного оправдано, но с учетом описанного в прошлом разделе, есть понимание, что может
  * обнловлять сессию когда-то здесь и не будем. Тогда, возможно, когда-то правильней будет вызывать именно
  * экшн сброса кэша только тогда, когда данные в сторе есть, чтобы не вызывать дополнительных перерисовок. Пока
  * на это не обращается внимание
  * */

  return dispatch(saveSheetsToStartData(
    mainPlanningSessionEntity.id,
    startDate,
    stopDate,
    null,
  ));
};

const handleAssemblySheetsToStartList = (dispatch, isOnSheetToStartRoute, mainPlanningSessionEntity, sheetsToStartSelectedType) => {
  /*
  * Если пользователь находится в разделе "Плановик. сборочные МЛ на запуск" то данные списка нужно перезапросить.
  * */
  if(isOnSheetToStartRoute && sheetsToStartSelectedType === SHEET_TYPE.ASSEMBLY_TO_START) {
    return dispatch(fetchAssemblySheetsToStart(mainPlanningSessionEntity.id));
  }
};