import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import cn from 'classnames';
import { MATERIAL_UI_VARIANT } from '../../../constants/materialUI';

import './style.css';
import { FUNC_IS_REQUIRED_TYPE, OBJECT_OF_ANY_TYPE } from '../../../constants/propTypes';

export const TextFormField = props => {
  const {
    label,
    onChange,
    inputProps,
    value,
    error,
    helperText,
    onBlur,
    onFocus,
    formKey,
    variant,
    className,
    wrapperClassName,
    isDisabled,
    autoFocus,
  } = props;

  return (
    <span className={cn('text-form-field', wrapperClassName)}>
      <TextField
          InputLabelProps={{ shrink: true }}
          className={cn(
            'text-form-field__text-field',
            className,
            {
              'text-form-field__text-field--error': !!error,
            },
          )}
          id={['text-form-field__text-field', formKey].join('_')}
          variant={variant}
          label={label}
          error={!!error}
          value={value}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          onFocus={onFocus}
          helperText={error ? error : helperText}
          fullWidth
          InputProps={{
            ...inputProps,
            className: 'text-form-field__text-field-input-wrap',
          }}
          disabled={isDisabled}
          autoFocus={autoFocus}
      />
    </span>
  );
};

TextFormField.propTypes = {
  label: PropTypes.node,
  onChange: FUNC_IS_REQUIRED_TYPE,
  inputProps: OBJECT_OF_ANY_TYPE,
  value: PropTypes.string,
  error: PropTypes.node,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  formKey: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  helperText: PropTypes.node,
};

TextFormField.defaultProps = {
  variant: MATERIAL_UI_VARIANT.STANDARD,
  isDisabled: false,
  autoFocus: false,
};