import { Icon } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './style.css';


// eslint-disable-next-line react/prop-types
const CloseButton = ({ closeToast }) => (
  <Icon className="Toastify__close-icon" onClick={closeToast}>
    <Cancel/>
  </Icon>
);

export const NotificationSystem = () => (
  <ToastContainer
      transition={Slide}
      newestOnTop
      closeButton={CloseButton}
  />
);
