import React from 'react';
import PropTypes from 'prop-types';
import { TableCellRenderer } from '../TableCellRenderer';
import { NUMBER_OR_STRING_TYPE } from '../../../../constants/propTypes';
import { NotAssignedLabelTrans } from '../../../../utils/commonTransComponents';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


/*
* Можно заметить явное сходство с MasterTasksEquipmentCombinedDataRenderer, единственное разиличне для этих таблиц,
* что формат данных отличается, поэтому информация об оборудовании находится в разных полях. Если не изменять
* формат на единый, то единственный выход это создание нового, но на 95% похожего, рендерара под конкретные форматы
* таблиц. До конца не понятно нужен ли нам здесь единый формат, но, скорее всего, когда-то к этому придем из-за
* таких вот вещей, т.к. разные форматы неудобны. В этом случае можно будет сделать общий рендерере и использовать
* его для 2 таблиц
* */
export class WorkerTasksEquipmentCombinedDataRenderer extends TableCellRenderer {

  static propTypes = {
    data: PropTypes.shape({
      equipmentId:  NUMBER_OR_STRING_TYPE,
      equipmentIdentity: PropTypes.string,
      equipmentName: PropTypes.string,
    }),
  };

  static getViewValue(props) {
    const {
      data: {
        equipmentId,
        equipmentIdentity,
        equipmentName,
      },
    } = props;

    if(equipmentId === null) {
      return null;
    }

    return getResourceCombinedName({ identity: equipmentIdentity, name: equipmentName });
  };

  render() {
    const viewValue = WorkerTasksEquipmentCombinedDataRenderer.getViewValue(this.props);

    return(
      <div title={viewValue}>
        {
          viewValue === null ?
            NotAssignedLabelTrans :
            viewValue
        }
      </div>
    );
  }
}
