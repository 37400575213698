import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Trans } from '@lingui/macro';

import { SheetsContainer } from '../../../Sheets/SheetsContainer';
import {
  DEFAULT_SHEET_WAITING_PARTS_AND_MATERIALS_SUMMARY_TYPE,
} from '../../../../constants/propTypes';

import '../style.css';
import { MATERIAL_UI_STYLE_COLOR } from '../../../../constants/materialUI';
import { DefaultSheetPartsAndMaterialsConsumingContentContainer } from '../PartsAndMaterialsConsumingContent/DefaultSheetPartsAndMaterialsConsumingContentContainer';
import {
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
} from '../../../../constants/routes';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_FILTERS_SCHEMA } from '../../../Sheets/sheetsFilters/sheetsFilters';


export class DefaultSheetsWaitingPartsAndMaterials extends Component {

  static propTypes = {
    defaultSheetsWaitingPartsAndMaterials: PropTypes.arrayOf(DEFAULT_SHEET_WAITING_PARTS_AND_MATERIALS_SUMMARY_TYPE),
  };

  _getDefaultSheetWaitingPartsAndMaterialsAdditionalInfoLabels = ({ canPartsAndMaterialsBeConsumed }) => ([
    canPartsAndMaterialsBeConsumed ?
    {
      labelContent: (
        <Trans id="default_sheets_waiting_parts_and_materials@parts_and_materials_are_available">
          ДСЕ доступны
        </Trans>
      ),
      labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
      labelKey: 'canBeConsumed',
    } :
    {
      labelContent: (
        <Trans id="default_sheets_waiting_parts_and_materials@not_enough_parts_are_not_available">
          ДСЕ недоступны
        </Trans>
      ),
      labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
      labelKey: 'cantBeConsumed',
    },
  ]);

  render() {
    const {
      defaultSheetsWaitingPartsAndMaterials,
    } = this.props;

    return(
      <SheetsContainer
          className="default-sheets-waiting-parts-and-materials-list"
          mainRoutePath={STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}
          sheetsIdentity={SHEET_TYPE.DEFAULT_WAITING_PARTS_AND_MATERIALS}
          sheetItemIdProperty="sheetId"
          sheetsData={defaultSheetsWaitingPartsAndMaterials}
          sheetReviewContentComponent={DefaultSheetPartsAndMaterialsConsumingContentContainer}
          noDataText={
            <Trans id="default_sheets_waiting_parts_and_materials@no_sheets_waiting_parts_and_materials">
              В данный момент нет ни одного маршрутного листа, ожидающего комплектации
            </Trans>
          }
          filtersSchema={DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_FILTERS_SCHEMA}
          getSheetItemAdditionalInfoLabels={this._getDefaultSheetWaitingPartsAndMaterialsAdditionalInfoLabels}
      />
    );
  }
}