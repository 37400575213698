import React, { Component } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  PLANNER_APP_COMPLETED_SHEETS_ROUTE,
  PLANNER_APP_ORDERS_COMPLETED_ROUTE,
  PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
  PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
  PLANNER_APP_ORDERS_ROUTE, PLANNER_APP_ORDERS_SUB_ROUTE,
  PLANNER_APP_PAUSED_SHEETS_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  PLANNER_APP_SHEETS_ROUTE, PLANNER_APP_SHEETS_SUB_ROUTE,
  PLANNER_APP_SHEETS_TO_START_ROUTE,
} from '../../../constants/routes';
import ToggleButton from '@mui/material/ToggleButton';
import { reactRouterParamsSelector } from '../../../selectors/reactRouter';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CardWithNavLinksHeader } from '../../common/CardWithNavLinksHeader/CardWithNavLinksHeader';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../hoc/withPermissionsManager/constants';
import _partialRight from 'lodash/partialRight';
import { SHEET_TYPE } from '../../../constants/sheets';
import { SheetsInProduction } from '../SheetsInProduction/SheetsInProduction';
import { SheetsToStartContainer } from '../SheetsToStart/SheetsToStartContainer';
import { PlannerPausedSheets } from '../PlannerPausedSheets/PlannerPausedSheets';
import { CompletedSheets } from '../CompletedSheets/CompletedSheets';
import { ORDER_TYPE } from '../../../constants/orders';
import { OrdersInProduction } from '../OrdersInProduction/OrdersInProduction';
import { OrdersReadyToComplete } from '../OrdersReadyToComplete/OrdersReadyToComplete';
import { CompletedOrders } from '../CompletedOrders/CompletedOrders';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import './style.css';
import {
  CompletedLabelTrans,
  InProductionLabelTrans,
  OrdersLabelTrans,
  PausedLabelTrans, ProductionStateExportLabelTrans,
  ReadyToCompleteLabelTrans,
  SheetsLabelTrans,
  ToStartLabelTrans,
} from '../../../utils/commonTransComponents';


/*
* Для экранов просмотра сущностей по типам справедлива логика, что по основному роуту отображается список сущностей, а
* по роуту ${основной роут}/:entityIdRouteParam - интерфейс просмотра конкретной сущности. Чтобы иметь доступ к
* параметру идентификатора сущности (:entityIdRouteParam) в компоненте, назначаем для path (который далее установится в
* Route у CardWithNavLinkHeader) сразу оба роута в массиве.
* */
const getEntitiesReviewTabRouteParams = (mainRoute, Component, entityIdRouteParam) => ({
  path: [
    [mainRoute, `:${entityIdRouteParam}`].join('/'),
    mainRoute,
  ],
  component: Component,
});

const getSheetsReviewTabRouteParams = _partialRight(getEntitiesReviewTabRouteParams, 'sheetId');
const getOrdersReviewTabRouteParams = _partialRight(getEntitiesReviewTabRouteParams, 'orderId');

const PLANNER_APP_SHEETS_TABS = [
  {
    tabId: SHEET_TYPE.IN_PRODUCTION,
    tabTitle: InProductionLabelTrans,
    tabLink: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    tabRouteParams: getSheetsReviewTabRouteParams(
      PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
      SheetsInProduction,
    ),
  },
  {
    tabId: SHEET_TYPE.ALL_TO_START,
    tabTitle: ToStartLabelTrans,
    tabLink: PLANNER_APP_SHEETS_TO_START_ROUTE,
    tabRouteParams: getSheetsReviewTabRouteParams(
      PLANNER_APP_SHEETS_TO_START_ROUTE,
      SheetsToStartContainer,
    ),
  },
  {
    tabId: SHEET_TYPE.PAUSED,
    tabTitle: PausedLabelTrans,
    tabLink: PLANNER_APP_PAUSED_SHEETS_ROUTE,
    tabRouteParams: getSheetsReviewTabRouteParams(
      PLANNER_APP_PAUSED_SHEETS_ROUTE,
      PlannerPausedSheets,
    ),
  },
  {
    tabId: SHEET_TYPE.COMPLETED,
    tabTitle: CompletedLabelTrans,
    tabLink: PLANNER_APP_COMPLETED_SHEETS_ROUTE,
    tabRouteParams: getSheetsReviewTabRouteParams(
      PLANNER_APP_COMPLETED_SHEETS_ROUTE,
      CompletedSheets,
    ),
  },
];

const PLANNER_APP_ORDERS_TABS = [
  {
    tabId: ORDER_TYPE.IN_PRODUCTION,
    tabTitle: InProductionLabelTrans,
    tabLink: PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
    tabRouteParams: getOrdersReviewTabRouteParams(
      PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
      OrdersInProduction,
    ),
  },
  {
    tabId: ORDER_TYPE.READY_TO_COMPLETE,
    tabTitle: ReadyToCompleteLabelTrans,
    tabLink: PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
    tabRouteParams: getOrdersReviewTabRouteParams(
      PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
      OrdersReadyToComplete,
    ),
  },
  {
    tabId: ORDER_TYPE.COMPLETED,
    tabTitle: CompletedLabelTrans,
    tabLink: PLANNER_APP_ORDERS_COMPLETED_ROUTE,
    tabRouteParams: getOrdersReviewTabRouteParams(
      PLANNER_APP_ORDERS_COMPLETED_ROUTE,
      CompletedOrders,
    ),
  },
];


export class PlannerWorkspace extends Component {

  _renderViewModeControl = () => {

    const { viewMode } = reactRouterParamsSelector(null, this.props);
    return (
      <ToggleButtonGroup
          className="planner-workspace__view-mode-toggle-btn-group"
          value={viewMode}
          exclusive
          onChange={this._onViewModeChange}
      >
        {this._renderToggleButton(PLANNER_APP_SHEETS_SUB_ROUTE, SheetsLabelTrans)}
        {this._renderToggleButton(PLANNER_APP_ORDERS_SUB_ROUTE, OrdersLabelTrans)}
      </ToggleButtonGroup>
    );
  }

  _onViewModeChange = (e, newViewMode) => {
    /*
    * Если повторно нажата кнопка уже выбранного режима то в обработчик приходит viewMode = null. В таком случае не
    * требуется вызывать обработчик handlePlannerAppViewModeChange, т.к. значение не изменилось
    * */
    if (newViewMode === null) return;

    this.props.handlePlannerAppViewModeChange(newViewMode);
  }

  _renderToggleButton = (value, content) => (
    <ToggleButton
        value={value}
        classes={{
          selected: 'planner-workspace__view-mode-toggle-btn--selected',
        }}
    >
      {content}
    </ToggleButton>
  );

  _renderContent = () =>
    <div className="planner-workspace__content">
      <Switch>
        <Route
            path={PLANNER_APP_SHEETS_ROUTE}
            render={this._renderPlannerSheetsCard}
        />
        <Route
            path={PLANNER_APP_ORDERS_ROUTE}
            render={this._renderPlannerOrdersCard}
        />
      </Switch>
    </div>;

  _renderPlannerSheetsCard = ({ match: { isExact } }) => {
    if(isExact)
      return <Redirect to={PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE}/>;

    return (
      <CardWithNavLinksHeader
          className="planner-workspace__sheets-card"
          title={SheetsLabelTrans}
          navTabs={PLANNER_APP_SHEETS_TABS}
          menuOptions={this._getPlannerSheetsCardMenuOptions()}
      />
    );
  };

  _getPlannerSheetsCardMenuOptions = () => {
    const { PermissionsManager, exportProductionState } = this.props;

    if(PermissionsManager.isDenied(PERMISSION.EXPORT_SHEETS_IN_PRODUCTION_DATA))
      return undefined;

    return [
      {
        menuItemId: 'planner-workspace__production-state-export-menu-item-id',
        menuItemTitle: ProductionStateExportLabelTrans,
        menuItemClick: exportProductionState,
      },
    ];
  };

  _renderPlannerOrdersCard = ({ match: { isExact } }) => {
    if(isExact)
      return <Redirect to={PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE}/>;

    return (
      <CardWithNavLinksHeader
          className="planner-workspace__orders-card"
          title={OrdersLabelTrans}
          navTabs={PLANNER_APP_ORDERS_TABS}
      />
    );
  };

  render() {
    return (
      <div className="planner-workspace">
        <div className="planner-workspace__control-panel-wrapper">
          {this._renderViewModeControl()}
        </div>
        {this._renderContent()}
      </div>
    );
  }
}

PlannerWorkspace.propTypes = {
  handlePlannerAppViewModeChange: FUNC_IS_REQUIRED_TYPE,
  exportProductionState: FUNC_IS_REQUIRED_TYPE,
  PermissionsManager: PERMISSIONS_MANAGER_TYPE.isRequired,
};