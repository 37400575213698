import { Transformer } from '../Transformer';

export class SheetOperationFeatureScope extends Transformer {

  static transformToState(item) {
    const {
      id,
      entityRouteSheetOperationFeatureId,
      departmentId,
      equipmentClassId,
      operationId,
    } = item;


    return {
      id,
      sheetOperationFeatureId: entityRouteSheetOperationFeatureId,
      departmentId,
      equipmentClassId,
      operationId,
    };
  };

  static transformToDb(item) {
    const {
      id,
      sheetOperationFeatureId: entityRouteSheetOperationFeatureId,
      departmentId,
      equipmentClassId,
      operationId,
    } = item;

    return {
      id,
      entityRouteSheetOperationFeatureId,
      departmentId,
      equipmentClassId,
      operationId,
    };
  };
}
