export const SERVER_ERROR_SOURCE = {
  IO: 'IO',
  WEBSERVER: 'WEBSERVER',
  IA: 'IA',
};

export const SERVER_ERROR_IDENTITY = {
  REQUEST_JSON_STRUCTURE: 'REQUEST_JSON_STRUCTURE',
  USER_IDENTITY_ALREADY_EXISTS: 'USER_IDENTITY_ALREADY_EXISTS',
  EQUIPMENT_IDENTITY_ALREADY_EXISTS: 'EQUIPMENT_IDENTITY_ALREADY_EXISTS',
  CONNECTION: 'CONNECTION',
};