import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { WorkerTasksTableSettingsControlPanel } from './WorkerTasksTableSettingsControlPanel/WorkerTasksTableSettingsControlPanel';
import { WorkerTasksTableSettingsEditForm } from './WorkerTasksTableSettingsEditForm/WorkerTasksTableSettingsEditForm';


import {
  FUNC_IS_REQUIRED_TYPE,
  SELECTED_WORKER_TASKS_TABLE_SETTINGS_DATA_TYPE,
  SETTINGS_SELECT_OPTION_TYPE,
} from '../../../constants/propTypes';
import {
  getSettingsNameFromId,
  WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE,
  WORKER_TASKS_TABLE_SETTINGS_GROUP,
  getSettingsEntityUniqId,
} from '../../../constants/settings';

import _mapValues from 'lodash/mapValues';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

import './style.css';
import { TASK_VIEW_SCREEN_MODEL } from '../../../reducers/schemaModel/models/taskViewScreenSchema';
import { EQUIPMENT_CLASS_MODEL } from '../../../constants/models';
import { SCHEMA_MODEL_INITIAL_STATE } from '../../../reducers/schemaModel/initialState';
import { useConfirmOnLeave } from '../../../hoc/confirmOnLeave/confirmOnLeave';
import { PERMISSIONS_MANAGER_TYPE } from '../../../hoc/withPermissionsManager/constants';
import { Trans } from '@lingui/macro';

const TASKS_TABLE_COLUMNS_DATA_KEYS_TO_SAVE = [
  'show',
  'order',
];

export class WorkerTasksTableSettings extends Component {
  static _getInitialStateForSelectedSettingsFromProps = props => {
    const {
      selectedSettingsId,
      selectedSettingsData: {
        tasksTableColumns,
      },
    } = props;
    return {
      /*
      * храним в локальном state дублирующую информацию об идентификаторе заселекченных настроек, т.к. это необходимо
      * для сравнения в getDerivedStateFromProps (Это рекомендуемый способ из доков реакта, т.к. в  getDerivedStateFromProps
      * осознанно нет доступа к prevProps и не будет)
      */
      selectedSettingsId,
      viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.READ_ONLY,
      selectedDepartment: null,
      selectedEquipmentClass: null,
      tasksTableColumns: _mapValues(
        tasksTableColumns,
        columnData => ({ ...columnData }),
      ),
    };
  };

  static propTypes = {
    isMainSettingsSelected: PropTypes.bool.isRequired,
    selectedSettingsId: PropTypes.string.isRequired,
    selectedSettingsData: SELECTED_WORKER_TASKS_TABLE_SETTINGS_DATA_TYPE,
    settingsSelectOptions: PropTypes.arrayOf(SETTINGS_SELECT_OPTION_TYPE).isRequired,
    selectSettings: FUNC_IS_REQUIRED_TYPE,
    deleteSelectedSettings: FUNC_IS_REQUIRED_TYPE,
    saveSettingsEntity: FUNC_IS_REQUIRED_TYPE,
    settingsEntities: PropTypes.objectOf(SETTINGS_SELECT_OPTION_TYPE).isRequired,
    fetchEquipmentClassesInDepartment: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  /*
  * По идее, getDerivedStateFromProps вызывается и при initial рендере и возвращает сам state, т.е. конструктор,
  * теоретически, можно было и не определять. С другой стороны, в этом случае в getDerivedStateFromProps второй аргумент
  * равен null и нужно делать дополнительные проверки. Кроме того, в общем случае, если не определить state в
  * конструкторе, то обязательно нужно предусмотреть, чтобы getDerivedStateFromProps вернул не null при initial рендере,
  * т.к. иначе state не будет инициализирован. Реокмендованный способ (в том числе для читаемости) - в конструкторе
  * инициализировать state "пустыми данными", а в getDerivedStateFormProps заполнять их
  * */
  constructor(props) {
    super(props);
    this.state = {
      selectedSettingsId: null,
      viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.READ_ONLY,
      selectedDepartment: null,
      selectedEquipmentClass: null,
      equipmentClassesOptionsByDepartmentId: {},
      tasksTableColumns: {},
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.selectedSettingsId === state.selectedSettingsId) return null;
    return WorkerTasksTableSettings._getInitialStateForSelectedSettingsFromProps(props);
  };

  _isViewModeEqualsToCbFactory = viewMode => () => this.state.viewMode === viewMode;
  _isReadOnlyViewMode = this._isViewModeEqualsToCbFactory(WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.READ_ONLY);
  _isCreatingViewMode = this._isViewModeEqualsToCbFactory(WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.CREATE);

  _renderSettingsControlPanel = () => {
    const {
      selectedSettingsId,
      settingsSelectOptions,
      selectSettings,
      deleteSelectedSettings,
      isMainSettingsSelected,
      PermissionsManager,
    } = this.props;
    return(
      <WorkerTasksTableSettingsControlPanel
          isDisabled={!this._isReadOnlyViewMode()}
          startNewSettingsCreating={this._handleStartNewSettingsCreating}
          startNewSettingsCreatingUsingSelectedAsTemplate={this._handleCreateNewSettingsUsingSelectedAsTemplate}
          selectedSettingsId={selectedSettingsId}
          settingsSelectOptions={settingsSelectOptions}
          selectSettings={({ id }) => selectSettings(id)}
          startSettingsEditing={this._handleStartSettingsEditing}
          deleteSelectedSettings={deleteSelectedSettings}
          isDeleteSettingsButtonVisible={!isMainSettingsSelected}
          PermissionsManager={PermissionsManager}
      />
    );
  };

  _handleStartNewSettingsCreating = () =>
    this.setState({
      viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.CREATE,
      tasksTableColumns: _mapValues(
        SCHEMA_MODEL_INITIAL_STATE[TASK_VIEW_SCREEN_MODEL].fields,
        columnData => _pick(
          columnData,
          [
            'columnName',
            'displayName',
            'order',
            'show',
          ],
        ),
      ),
    });

  _handleCreateNewSettingsUsingSelectedAsTemplate = () =>
    this.setState({
      viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.CREATE,
    });

  _handleStartSettingsEditing = () =>
    this.setState({
      viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.EDIT,
    });

  _renderSelectedSettingsForm = () => {
    const {
      selectedDepartment,
      selectedEquipmentClass,
      tasksTableColumns,
      equipmentClassesOptionsByDepartmentId,
    } = this.state;

    const equipmentClassSelectOptions = selectedDepartment ?
      equipmentClassesOptionsByDepartmentId[selectedDepartment.id] :
      [];

    return(
      <WorkerTasksTableSettingsEditForm
          isReadOnly={this._isReadOnlyViewMode()}
          isCreating={this._isCreatingViewMode()}
          selectedDepartment={selectedDepartment}
          selectDepartment={this._handleDepartmentSelect}
          selectedEquipmentClass={selectedEquipmentClass}
          equipmentClassSelectOptions={equipmentClassSelectOptions}
          selectEquipmentClass={this._handleEquipmentClassSelect}
          tasksTableColumnsSettings={tasksTableColumns}
          editTasksTableColumnsSettings={this._handleTasksTableColumnsSettingsEdit}
          settingsSaveDisableReasonTrans={this._getNewSettingsSaveDisableReasonTrans()}
          onSettingsEditCancel={this._handleEditingCancel}
          onSettingsSave={this._handleSaveSettings}
      />
    );
  };

  _handleDepartmentSelect = departmentEntity => {
    const { fetchEquipmentClassesInDepartment } = this.props;

    const { equipmentClassesOptionsByDepartmentId } = this.state;

    const { id } = departmentEntity;

    if(equipmentClassesOptionsByDepartmentId[id])
      return this.setState({
        selectedDepartment: departmentEntity,
        selectedEquipmentClass: null,
      });

    return fetchEquipmentClassesInDepartment(id)
      .then(response  => {
        if(!response || response.responseMeta.count === 0) return;

        const {
          entities: {
            [EQUIPMENT_CLASS_MODEL]: equipmentClassesInDepartmentEntities,
          },
        } = response;

        this.setState({
          selectedDepartment: departmentEntity,
          selectedEquipmentClass: null,
          equipmentClassesOptionsByDepartmentId: {
            ...equipmentClassesOptionsByDepartmentId,
            [id]: Object.values(equipmentClassesInDepartmentEntities),
          },
        });
      });
  };

  _handleEquipmentClassSelect = equipmentClassEntity =>
    this.setState({
      selectedEquipmentClass: equipmentClassEntity,
    });

  _handleTasksTableColumnsSettingsEdit = newTasksTableColumnsSettings =>
    this.setState({
      tasksTableColumns: newTasksTableColumnsSettings,
    });

  _getNewSettingsSaveDisableReasonTrans = () => {
    const {
      selectedDepartment,
      selectedEquipmentClass,
    } = this.state;
    const { settingsEntities } = this.props;

    const newSettingsNameToSave = [selectedDepartment, selectedEquipmentClass]
      .filter(owner => owner !== null)
      .map(({ id }) => id)
      .join('_');

    switch(true) {
    case !this._isCreatingViewMode():
      return null;
    case !selectedDepartment:
      return (
        <Trans id="worker_tasks_table_settings@department_should_be_choosen_for_settings">
          Для новых настроек необходимо выбрать хотя бы одно подразделение
        </Trans>
      );
    case !!settingsEntities[getSettingsEntityUniqId(WORKER_TASKS_TABLE_SETTINGS_GROUP, newSettingsNameToSave)]:
      return selectedEquipmentClass === null ?

        <Trans id="worker_tasks_table_settings@settings_for_department_already_exists">
          Настройки для выбранного подразделения уже существуют
        </Trans> :

        <Trans id="worker_tasks_table_settings@settings_for_equipment_class_in_department_already_exists">
          Настройки для выбранного класса РЦ в подразделения уже существуют
        </Trans>;
    default:
      return null;
    }
  };

  _handleEditingCancel = () =>
    this.setState(WorkerTasksTableSettings._getInitialStateForSelectedSettingsFromProps(this.props));

  _handleSaveSettings = () =>
    this._isCreatingViewMode() ?
      this._saveNewSetting() :
      this._editSettings();

  _saveNewSetting = () => {
    const {
      saveSettingsEntity,
      selectSettings,
    } = this.props;
    const {
      selectedDepartment: {
        id: selectedDepartmentId,
        name: selectedDepartmentName,
        identity: selectedDepartmentIdentity,
      },
      selectedEquipmentClass,
      tasksTableColumns,
    } = this.state;

    const newSettingsName = selectedEquipmentClass ?
      [selectedDepartmentId, selectedEquipmentClass.id].join('_') :
      selectedDepartmentId;

    const newSettingsData = {
      departmentId: selectedDepartmentId,
      departmentIdentity: selectedDepartmentIdentity,
      departmentName: selectedDepartmentName,
      equipmentClassId: _get(selectedEquipmentClass, 'id', null),
      equipmentClassIdentity: _get(selectedEquipmentClass, 'identity', null),
      equipmentClassName: _get(selectedEquipmentClass, 'name', null),
      tasksTableColumns,
    };

    return saveSettingsEntity({
      group: WORKER_TASKS_TABLE_SETTINGS_GROUP,
      name: newSettingsName.toString(),
      value: {
        ...newSettingsData,
        tasksTableColumns: _mapValues(
          tasksTableColumns,
          columnData => _pick(columnData, TASKS_TABLE_COLUMNS_DATA_KEYS_TO_SAVE),
        ),
      },
    })
      .then(({ id: settingsId }) => selectSettings(settingsId));
  };

  _editSettings = () => {
    const {
      selectedSettingsId,
      selectedSettingsData,
      saveSettingsEntity,
    } = this.props;

    const { tasksTableColumns } = this.state;

    return saveSettingsEntity({
      group: WORKER_TASKS_TABLE_SETTINGS_GROUP,
      name: getSettingsNameFromId(selectedSettingsId),
      value: {
        ...selectedSettingsData,
        tasksTableColumns: _mapValues(
          tasksTableColumns,
          columnData => _pick(columnData, TASKS_TABLE_COLUMNS_DATA_KEYS_TO_SAVE),
        ),
      },
    })
      .then(() => this.setState({
        viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.READ_ONLY,
      }));
  };

  _renderUseConfirmOnLeaveComponent = () =>
    <WorkerTasksTableSettingsUseConfirmOnLeaveComponent viewMode={this.state.viewMode} />;

  render() {
    return(
      <div className="worker-tasks-table-settings-screen">
        {this._renderSettingsControlPanel()}
        {this._renderSelectedSettingsForm()}
        {this._renderUseConfirmOnLeaveComponent()}
      </div>
    );
  };
}

/*
* Декоратор confirmOnLeave и хук useConformOnLeave не могут применяться напрямую к WorkerTasksTableSettings, т.к.
* confirmOnLeave не учитывает и не может учитывать локальный стейт декорируемого компонента, т.к. декоратор создаёт
* компонента обертку над ним, ну а хук нельзя применить к компоненту, реализованному через класс. Поэтому,
* приходится создавать служебный компонент для реализации этой логики. Можно было реализовать это через любой из
* вариантов, т.к. в служебный компонент уже нужные данные передается просто в виде пропсов. Решено реализовать через
* хук, т.к. через декоратор создастся и сам служебный компонент, который не рендерит ничего, и ещё и обертка вокруг
* него для реализации конфирмации
* */
const WorkerTasksTableSettingsUseConfirmOnLeaveComponent = props => {

  useConfirmOnLeave(
    ({ viewMode }) => viewMode !== WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.READ_ONLY,
    props,
  );

  return null;
};
