import React from 'react';
import PropTypes from 'prop-types';

import _isNil from 'lodash/isNil';
import cn from 'classnames';

import './style.css';


export const SimpleInfoRow = ({
  wrapperClassName,
  labelClassName,
  label,
  valueClassName,
  value,
  emptyValue,
  delimiter = ':',
}) =>
  <div
      className={
        cn(
          'simple-info-row',
          wrapperClassName,
        )
      }
  >
    {
      label &&
      <span
          className={
            cn(
              'simple-info-row__label',
              labelClassName,
            )
          }
      >
        {label}
        {delimiter}
      </span>
    }
    <span
        className={
          cn(
            'simple-info-row__value',
            valueClassName,
          )
        }
    >
      {_isNil(value) ? emptyValue : value}
    </span>
  </div>;

const { string, number, node, oneOfType } = PropTypes;

const valueType = oneOfType([string, number, node]);

SimpleInfoRow.propTypes = {
  wrapperClassName: string,
  labelClassName: string,
  label: node,
  valueClassName: string,
  value: valueType,
  emptyValue: valueType,
  delimiter: string,
};
