import React, { useCallback, useRef } from 'react';
import { Dialog } from '@mui/material';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { MATERIAL_UI_DIALOG_MAX_WIDTH } from '../../../../constants/materialUI';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import { ConsumeEntitiesContainer } from './ConsumeEntities/ConsumeEntitiesContainer';
import { sheetOperationDataShape } from '../propTypes';
import { useConfirm } from '../../../AppConfirm/AppConfirmContext';
import cn from 'classnames';


export const ConsumeEntitiesDialog = props => {

  const {
    className,
    isOpen,
    onClose,
    sheetOperationData,
  } = props;

  const areEditableAValuesChangedRef = useRef();

  const editableValuesChangeHandler = useCallback(
    areEditableAValuesChanged =>
      areEditableAValuesChangedRef.current = areEditableAValuesChanged,
    [],
  );

  const confirm = useConfirm();

  const closeHandler = useCallback(() => {
    if (!areEditableAValuesChangedRef.current) {
      onClose();
      return;
    }

    confirm({
      confirmText: (
        <Trans id="sheet_operation_consume_entities_dialog@confirm_consume_data_reset">
          Есть несохраненные изменения данных, при закрытии окна потребления эти данные будут потеряны. Вы уверены?
        </Trans>
      ),
      confirmDialogInnerProps: {
        dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
      },
    })
      .then(onClose);
  }, [confirm, areEditableAValuesChangedRef, onClose]);

  return (
      <Dialog
          classes={{
            root: cn(
              'consume-entities-dialog',
              className,
            ),
            paper: 'consume-entities-dialog__dialog-paper',
          }}
          open={isOpen}
          onClose={closeHandler}
          maxWidth={MATERIAL_UI_DIALOG_MAX_WIDTH.LG}
          fullWidth
      >
        <ConsumeEntitiesContainer
            sheetOperationData={sheetOperationData}
            onEditableValuesChange={editableValuesChangeHandler}
        />
      </Dialog>
  );
};


ConsumeEntitiesDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: FUNC_IS_REQUIRED_TYPE,
  sheetOperationData: sheetOperationDataShape,
};