import { CHANGE_FILTERS } from './actions';


export const filters = (state = {}, action) => {
  switch (action.type) {
  case CHANGE_FILTERS:
    return {
      ...state,
      [action.filterId]: action.filters,
    };
  default:
    return { ...state };
  }
};
