import React  from 'react';
import PropTypes from 'prop-types';

import { SimpleConfirmDialog } from '../common/SimpleConfirmDialog/SimpleConfirmDialog';
import { OBJECT_OF_ANY_TYPE } from '../../constants/propTypes';
import {
  ActionNeedsConfirmationLabelTrans,
  AreYouSureLabelTans,
  NoLabelTrans,
  YesLabelTrans,
} from '../../utils/commonTransComponents';


export const AppConfirmDialog = props => {

  if(!props.isConfirmModalOpen) return null;

  const {
    confirmModalTitle,
    confirmText,
    confirmBtnTitle,
    cancelBtnTitle,
    onConfirm,
    onCancel,
    confirmDialogInnerProps,
  } = props;

  return (
    <SimpleConfirmDialog
        isOpen
        onConfirm={onConfirm}
        closeDialog={onCancel}
        title={confirmModalTitle}
        confirmText={confirmText}
        confirmBtn={confirmBtnTitle}
        cancelBtn={cancelBtnTitle}
        {...confirmDialogInnerProps}
    />
  );
};

AppConfirmDialog.defaultProps = {
  confirmModalTitle: ActionNeedsConfirmationLabelTrans,
  confirmText: AreYouSureLabelTans,
  confirmBtnTitle: YesLabelTrans,
  cancelBtnTitle: NoLabelTrans,
};

AppConfirmDialog.propTypes = {
  isConfirmModalOpen: PropTypes.bool.isRequired,
  confirmModalTitle: PropTypes.node,
  confirmText: PropTypes.node,
  confirmBtnTitle: PropTypes.node,
  cancelBtnTitle: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmDialogInnerProps: OBJECT_OF_ANY_TYPE,
};