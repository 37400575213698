import { OrdersContainer } from '../../Orders/OrdersContainer';
import {
  PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
} from '../../../constants/routes';
import { fetchOrdersReadyToComplete } from '../../../operations/orders';
import { ORDERS_READY_TO_COMPLETE_FILTERS_SCHEMA } from '../../Orders/ordersFilters/ordersFilters';
import React from 'react';
import { OrdersReadyToCompleteReviewContentContainer }
  from './OrdersReadyToCompleteReviewContent/OrdersReadyToCompleteReviewContentContainer';
import { ORDER_TYPE } from '../../../constants/orders';
import { Trans } from '@lingui/macro';


export const OrdersReadyToComplete = () => (
  <OrdersContainer
      className="orders-ready-to-complete"
      mainRoutePath={PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE}
      ordersIdentity={ORDER_TYPE.READY_TO_COMPLETE}
      orderItemIdProperty="id"
      fetchOrderEntitiesActionCreator={fetchOrdersReadyToComplete}
      orderReviewContentComponent={OrdersReadyToCompleteReviewContentContainer}
      noDataText={
        <Trans id="orders_ready_to_complete@no_orders_ready_to_complete">
          В данный момент нет ни одного заказа, который можно завершить
        </Trans>
      }
      filtersSchema={ORDERS_READY_TO_COMPLETE_FILTERS_SCHEMA}
  />
);
