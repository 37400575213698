import {
  SHEET_OPERATION_SCHEMA_FIELDS,
} from './sheetOperationSchema';
import { SHEET_OPERATION_MODEL } from '../../../constants/models';
import { initializeTableModelFields } from '../initializeTableModelFields';
import { SHEET_TYPE } from '../../../constants/sheets';


export const SHEET_TO_START_OPERATION_MODEL = [SHEET_OPERATION_MODEL, SHEET_TYPE.ALL_TO_START].join('__');

const COMMON_SHEET_OPERATION_SCHEMA_FIELDS_TO_OMIT = [
  'progress',
  'assignees',
  'equipment',
];

export const sheetToStartOperationSchema = {
  fields: initializeTableModelFields(
    SHEET_OPERATION_SCHEMA_FIELDS
      .filter(({ columnName }) => !COMMON_SHEET_OPERATION_SCHEMA_FIELDS_TO_OMIT.includes(columnName)),
  ),
};