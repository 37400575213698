import { Trans } from '@lingui/macro';
import {
  calculateDetailedEntitiesReserveOrConsumeFromEntityBatches,
  reserveAndConsumeEntitiesComparatorFactory,
} from '../../../../utils/reserveAndConsumeCommon/index';
import { subDecimals } from '../../../../utils/decimal';
import React from 'react';


export const DATA_TO_RESERVE_TABLE_COLUMNS_SCHEMA = [
  {
    key: 'entityCombinedName',
    title: (
      <Trans id="assembly_sheet_parts_and_materials_reserving.table_column@entity_combined_name">
        ДСЕ
      </Trans>
    ),
  },
  {
    key: 'reservedAmount',
    title: (
      <Trans id="assembly_sheet_parts_and_materials_reserving.table_column@reserved_amount">
        Зарезервировано
      </Trans>
    ),
  },
  {
    key: 'requiredAmount',
    title: (
      <Trans id="assembly_sheet_parts_and_materials_reserving.table_column@required_amount">
        Всего необходимо
      </Trans>
    ),
  },
  {
    key: 'warehouseAmount',
    title: (
      <Trans id="assembly_sheet_parts_and_materials_reserving.table_column@warehouse_amount">
        На складе
      </Trans>
    ),
  },
];

export const reserveEntitiesComparator = reserveAndConsumeEntitiesComparatorFactory('warehouseAmount');

export const reserveEntityValidator = (
  initialReserveData,
  newReservedAmount,
) => {

  if(newReservedAmount === '') {
    return (
      <Trans id="assembly_sheet_parts_and_materials_reserving@reserved_field_can_not_be_empty">
        Необходимо задать значение зарезервированных ДСЕ
      </Trans>
    );
  }

  const newReservedAmountNumber = Number(newReservedAmount);

  const {
    requiredAmount: initialRequiredAmount,
    warehouseAmount: initialWarehouseAmount,
    reservedAmount: initialReservedAmount,
  } = initialReserveData;

  /*
  * Есть подозрение, что довольно часто в кейсах использования у большинства ДСЕ не будет изменений, поэтому будет
  * достаточно много перерисовок, когда будет вызываться валидатор и, по сути, остальные проверки перебирать не нужно,
  * поэтому данная проверка выполняется специально отдельно раньше остальных проверок (кроме проверки на пустую строку,
  * т.к. это особый случай и удобней сначала выполнить её и не делать преобразования к Number, если она не прошла)
  * */
  if(newReservedAmountNumber === initialReservedAmount)
    return undefined;

  if(newReservedAmountNumber < initialReservedAmount) {
    return (
      <Trans id="assembly_sheet_parts_and_materials_reserving@can_not_reserve_less_than_already_reserved">
        Новое значение зарезервированных ДСЕ не может быть меньше текущего значения
      </Trans>
    );
  }

  if(subDecimals(newReservedAmountNumber, initialReservedAmount) > initialWarehouseAmount) {
    return(
      <Trans id="assembly_sheet_parts_and_materials_reserving@can_not_reserve_more_than_present_in_warehouse">
        Нельзя зарезервировать больше, чем доступно на складе
      </Trans>
    );
  }

  if(newReservedAmountNumber > initialRequiredAmount) {
    return (
      <Trans id="assembly_sheet_parts_and_materials_reserving@can_not_reserve_more_than_required">
        Нельзя зарезервировать больше, чем требуется для полной комплектации
      </Trans>
    );
  }

  return undefined;
};

export const reserveEntitiesPrepareTableRowData = (initialReserveData, newReservedAmount, reservedAmountError) => {
  const {
    entityId,
    entityCombinedName,
    requiredAmount,
    warehouseAmount: initialWarehouseAmount,
    reservedAmount: initialReservedAmount,
    remainingAmount: initialRemainingAmount,
  } = initialReserveData;

  return {
    entityId,
    entityCombinedName,
    requiredAmount,
    initialReservedAmount,

    //Значение редактируемого поля таблицы, оставляем его строкой, т.к. компоненты поля ввода ожидают строку в значении
    reservedAmount: newReservedAmount,

    reservedAmountError,

    warehouseAmount: !!reservedAmountError ?
      initialWarehouseAmount :
      subDecimals(
        initialWarehouseAmount,
        subDecimals(Number(newReservedAmount), initialReservedAmount),
      ),

    remainingAmount: !!reservedAmountError ?
      initialRemainingAmount :
      subDecimals(requiredAmount, Number(newReservedAmount)),
  };
};


export const RESERVE_ENTITIES_PREPARE_DATA_SCHEMA_FIELDS = {
  entityPrevAmountKey: 'reservedAmount',
  entityBatchAvailableAmountKey: 'warehouseAmount',
};

export const reserveEntitiesPrepareDataBeforeSubmit = (initialReserveData, newReservedAmountData) =>
  calculateDetailedEntitiesReserveOrConsumeFromEntityBatches(
    initialReserveData,
    newReservedAmountData,
    RESERVE_ENTITIES_PREPARE_DATA_SCHEMA_FIELDS,
  );