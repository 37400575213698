import { saveAs } from 'file-saver';
import { getActionUrl } from '../api/actionsApi/actions';
import { NOTIFICATION_LEVEL, sendNotification } from '../constants/notification';
import { SERVER_ACTION_POINT } from '../constants/serverActions';
import {
  DEFAULT_HTTP_REQUEST_WITH_JSON_BODY_HEADERS,
  HTTP_REQUEST_METHOD,
  HTTP_REQUEST_STATUS,
} from '../api/httpRequestsApi/constants';
import moment from 'moment/moment';
import humps from 'humps';
import { FORMAT_FULL_TIME } from '../constants/dateFormats';
import { getUniqId } from '@bfg-frontend/utils/lib/getUniqId';
import { blockingAsyncActionFinished, blockingAsyncActionStarted } from '../reducers/blockingAsyncAction/actions';
import { getRequestStatus } from '../api/httpRequestsApi/index';
import { CommonServerErrorLabelTrans, NoPermissionForRequestLabelTrans } from '../utils/commonTransComponents';



/*
* Экспорт "Состояния производства" (сейчас это только экспорт НЗП в определенном формате) осуществляется через
* специальную экшн точку на сервере. Необходимо отправить POST запрос на эту точку, при этом, в данный момент, синхронно
* в потоке сервера будут сформированы необходимые данные и  тело файла будет включено в ответ на этот же запрос.
* Получив ответ, мы сохранем его через blob и специальную библиотеку file-saver.
*
* Запрос получается достаточно частным, наши абстракции запросов не умеют обрабатывать часть логики без
* невероятного наифливания (например, в ответ в случае успеха приходит тело файла, а в случае ошибки джейсон, а мы,
* вообще, только джейсоны привыкли всегда ожидать), поэтому тут вся логика этого запроса проработана отдельно в рамках
* только этой функции, без использования каких-то абстракции.
* Т.Е., ПРИ КАКОМ-ТО КОМБИНИРОВАННОМ ИСПОЛЬЗОВАНИИ СЛЕДУЕТ ЭТО УЧИТЫВАТЬ И ОТНОСИТЬСЯ К ЭТОМУ ВНИМАТЕЛЬНО,
* НЕКОТОРЫЕ ВЕЩИ, ХАРАКТЕРНЫЕ АБСТРАКЦИЯМ ЗАПРОСОВ, МОГУТ БЫТЬ НЕ РЕАЛИЗОВАНЫ ДЛЯ ЭТОГО ЗАПРОСА.
* */
export const exportProductionState = () =>
  dispatch => {
    const fileNameToExport = _getProductionStateExportFileName();

    const requestUrl = getActionUrl(
      window.config.CA_BACKEND_SERVER_HOST,
      SERVER_ACTION_POINT.EXPORT.PRODUCTION_STATE,
    );

    /*
    * Для запроса можно определять параметр имени экспортируемого файла. Но, с учетом того, что мы сохраняем файл через
    * библиотеку file-saver, нам это не особо нужно, т.к. имя файла нужно указывать в функции этой библиотеки saveAs.
    * По идее, сам параметр filename не является обязательным для запроса и можно было  бы его не писать, но, тогда
    * бы мы были вынуждены отправлять что-то типа { data: null }, т.к. сам ключ data в теле запроса, всё же, является
    * обязательным. В итоге, отправлять пустую data показалось, всё-таки, не особо очевидным, поэтому мы отправляем
    * и filename внутри, хотя, как и указывалось, это в данный момент ни на что особо не влияет.
    * */
    const requestData = {
      data: { filename: fileNameToExport },
    };

    /*
    * В текущей реализации, когда экспорт выполняется в потоке сервера, во время обработки было решено
    * крутить блокирующий спиннер пока ожидаем ответ от сервера, чтобы один пользователь не отправлял подряд кучу
    * запросов, в случае, если подготовка данных занимает какое-то значительное время.
    * */
    const requestUniqId = getUniqId();

    dispatch(blockingAsyncActionStarted(requestUniqId));

    return fetch(
      humps.decamelize(requestUrl),
      {
        credentials: 'include',
        method: HTTP_REQUEST_METHOD.POST,
        body: JSON.stringify(requestData),
        headers: {
          ...DEFAULT_HTTP_REQUEST_WITH_JSON_BODY_HEADERS,
        },
      },
    )
      .then(
        response =>
          response.ok ?
            response.blob() :
            Promise.reject(response),
      )
      .then(blob => {
        dispatch(blockingAsyncActionFinished(requestUniqId));
        saveAs(blob, fileNameToExport);
      })
      .catch(response => {
        dispatch(blockingAsyncActionFinished(requestUniqId));

        /*
        * Т.к. запрос возвращает не json в случае успешного ответа, то он реализован не через абстракции, а кастомно
        * через fetch и в этом случае нет никаких общих обработок, которые выполняются абстракциями запроса. Надо
        * выполнить их вручную, в данном случае просто выводим другое сообщение, для случая, когда запрос не удался,
        * т.к. у пользователя не было на это прав.
        * */

        const status = getRequestStatus(response.status);

        const errorTransMsg = status === HTTP_REQUEST_STATUS.FORBIDDEN ?
          NoPermissionForRequestLabelTrans :
          CommonServerErrorLabelTrans;

        sendNotification(
          errorTransMsg,
          NOTIFICATION_LEVEL.ERROR,
        );
        return Promise.reject(response);
      });
  };

//Вероятно, в будущем, можно добавить обработку локализации при формировании имени экспортируемого файла
//(наименование и формат даты)
const _getProductionStateExportFileName = () => `НЗП (${moment().format(FORMAT_FULL_TIME)}).xlsx`;
