import { connect } from 'react-redux';

import {
  blockingAsyncActionStarted,
  blockingAsyncActionFinished,
} from '../../reducers/blockingAsyncAction/actions';

import { Component } from 'react';

import { getUniqId } from '@bfg-frontend/utils/lib/getUniqId';

import { FUNC_IS_REQUIRED_TYPE } from '../../constants/propTypes';


class DefaultLoadingComponent extends Component {

  constructor(props) {
    super(props);
    this.id = getUniqId();
  }

  componentDidMount() {
    this.props.blockingAsyncActionStarted(this.id);
  }

  componentWillUnmount() {
    this.props.blockingAsyncActionFinished(this.id);
  }

  render() {
    return null;
  }
}

DefaultLoadingComponent.propTypes = {
  blockingAsyncActionStarted: FUNC_IS_REQUIRED_TYPE,
  blockingAsyncActionFinished: FUNC_IS_REQUIRED_TYPE,
};

const mapDispatchToProps = {
  blockingAsyncActionStarted,
  blockingAsyncActionFinished,
};

export default connect(null, mapDispatchToProps)(DefaultLoadingComponent);
