import React from 'react';
import { PartsAndMaterialsReserveForAssemblySheetContainer }
  from '../PartsAndMaterialsReserveForAssemblySheet/PartsAndMaterialsReserveForAssemblySheetContainer';
import _get from 'lodash/get';
import {
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
} from '../../../../constants/routes';
import { SHEET_TYPE } from '../../../../constants/sheets';
import {
  ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_FILTERS_SCHEMA,
} from '../../../Sheets/sheetsFilters/sheetsFilters';
import { SheetsContainer } from '../../../Sheets/SheetsContainer';
import {
  fetchAssemblySheetsWaitingPartsAndMaterials,
} from '../../../../operations/sheets';
import { fetchEntitiesFromServer } from '../../../../reducers/entities/actions';
import {
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  ORDER_MODEL, SHEET_MODEL,
} from '../../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../../api/restCollectionApi';
import { getAssemblySheetsWaitingPartsAndMaterialsInfoLabels } from '../../../../utils/sheets';
import { Trans } from '@lingui/macro';

const ASSEMBLY_PARTS_AND_MATERIALS_MODELS_RELATIONS = {
  [ENTITY_MODEL]: {
    level: 1,
  },
  [ORDER_MODEL]: {
    level: 1,
  },
};

/**
 * Пока точка /data/entity_batch/list НЕ доработана делается дополнительный запрос для получения данных
 * моделей order и entity. В дальнейшем все данные для этого компонента будут
 * получаться запросом fetchAssemblySheetsWaitingPartsAndMaterials.
 */

// TODO удалить запрос из компонента и доработать fetchFullAssemblySheetsPartsAndMaterials

export const fetchFullAssemblySheetsPartsAndMaterials = queryParams => dispatch =>
  dispatch(fetchAssemblySheetsWaitingPartsAndMaterials(queryParams))
  .then(response => {

    const {
      responseMeta,
      responseEntitiesIds,
      entities,
    } = response;

    if (_get(responseMeta, 'count') === 0) return Promise.resolve(response);

    return dispatch(fetchEntitiesFromServer(
      ENTITY_BATCH_MODEL,
      {
        with: [ENTITY_MODEL, ORDER_MODEL],
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: [
            {
              column: 'id',
              filterType: FILTER_TYPES.ONE_OF,
              filterValue: responseEntitiesIds[ENTITY_BATCH_MODEL],
            },
          ],
        },
      },
      {
        modelRelations: ASSEMBLY_PARTS_AND_MATERIALS_MODELS_RELATIONS,
      },
    ))
      .then(additionalAssemblySheetsData => {

        const entity = additionalAssemblySheetsData.entities[ENTITY_MODEL];
        const order = additionalAssemblySheetsData.entities[ORDER_MODEL];

        return {
          entities: {
            [ENTITY_BATCH_MODEL]: entities[ENTITY_BATCH_MODEL],
            [SHEET_MODEL]: entities[SHEET_MODEL],
            [ENTITY_MODEL]: entity,
            [ORDER_MODEL]: order,
          },
          responseEntitiesIds: {
            [SHEET_MODEL]: responseEntitiesIds[SHEET_MODEL],
            [ENTITY_BATCH_MODEL]: responseEntitiesIds[ENTITY_BATCH_MODEL],
          },
          responseMeta,
        };
      });
  });

export const AssemblySheetsWaitingPartsAndMaterials = () => (
    <SheetsContainer
        className="assembly-sheets-waiting-parts-and-materials-list"
        mainRoutePath={STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}
        sheetsIdentity={SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS}
        sheetItemIdProperty="entityBatchId"
        fetchSheetTypeEntitiesActionCreator={fetchFullAssemblySheetsPartsAndMaterials}
        sheetReviewContentComponent={PartsAndMaterialsReserveForAssemblySheetContainer}
        noDataText={
          <Trans id="assembly_sheets_waiting_parts_and_materials@no_sheets_waiting_parts_and_materials">
            В данный момент нет ни одного маршрутного листа, ожидающего комплектации
          </Trans>
        }
        filtersSchema={ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_FILTERS_SCHEMA}
        getSheetItemAdditionalInfoLabels={getAssemblySheetsWaitingPartsAndMaterialsInfoLabels}
    />
  );