import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { asyncComponent } from '../../../../hoc/asyncComponent/asyncComponent';

import { mainPlanningSessionIdSelector } from '../../../../reducers/appState/selectors';
import { sheetToStartOperationsTableDataSelector } from '../../../../reducers/plannerApp/sheetsToStart/selectors';

import {
  fetchSheetToStartOperationsFromIa,
  removeSheetToStart,
  sendSheetStartActionOnServer,
  startSheet,
} from '../../../../reducers/plannerApp/sheetsToStart/actions';

import _get from 'lodash/get';

import { SheetToStartReviewContent } from './SheetToStartReviewContent';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { sendSheetStartedNotification } from '../../../../operations/sheets';
import { clearTableRemoteData } from '../../../../reducers/table/actions';
import {
  clearAllDepartmentsWithTasks,
  clearAllEquipmentClassesInDepartmentWithTask,
} from '../../../../reducers/workerApp/tasksOwners/actions';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { withPermissionsManager } from '../../../../hoc/withPermissionsManager/withPermissionsManager';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../MasterApp/MasterTasksToDo/constants';
import { ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS } from '../../../../constants/orders';


const mapStateToProps = state => ({
  mainPlanningSessionId: mainPlanningSessionIdSelector(state),
  sheetOperationsTableData: sheetToStartOperationsTableDataSelector(state),
  allTasksTablesIds: allTasksTablesIdsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchSheetToStartOperationsFromIa,
    sendSheetStartActionOnServer,
    startSheet,
  }, dispatch),

  /*
  * Влияние события запуска МЛ на разные типы приложения и обработка этого с учетом того, что запуск МЛ происходит на
  * экране просмотра МЛ на запуск:
  *
  * 1. Плановик.
  * 1.1. Раздел "МЛ в производстве". Запущенный МЛ должен появиться в списке МЛ в производстве:
  *      - Очищаем хранилище этого списка (табличное хранилище по ID таблицы), чтобы при следующем входе в этот раздел
  *        был выполнен новый запрос.
  *
  * 1.2. Раздел "МЛ на запуск". Запущенный МЛ должен исчезнуть из всех списков МЛ на запуск (подразумевается, что он мог
  *      быть как в списке всех МЛ так и в списке сборочных МЛ):
  *      - Удаляем информацию из специального хранилища списков МЛ на запуск в store, т.к. списки не серверные.
  *
  * 1.3. Раздел "МЛ приостановленные". Запущенный МЛ не влияет на этот раздел.
  *
  * 1.4. Раздел "МЛ завершенные". Запущенный МЛ не влияет на этот раздел.
  *
  * При запуске МЛ в базе СА может быть создан новый заказ, поэтому нужно всегда обновлять список заказов в производстве.
  * Состав заказов в СА обновляется каждый раз при запуске МЛ. Соответственно может возникнуть ситация, когда заказ был
  * в списке "готовых к завершению" и перешел в список "в производстве". Поэтому независимо от того, какие заказы сейчас
  * известны в СА, необходимо очистить оба списка, "в производстве" и "готовые к завершению"
  *
  * 1.5. Раздел "Заказы в производстве". Заказ, относящийся к запущенному МЛ должен появиться в списке заказов в производстве:
  *      - Очищаем хранилище этого списка (табличное хранилище по ID таблицы), чтобы при следующем входе в этот раздел
  *        был выполнен новый запрос.
  *
  * 1.6. Раздел "Заказы готовые к завершению". Заказ, относящийся к запущенному МЛ должен пропасть из списка заказов, т.к.
  *      по нему был запущен новый МЛ и он меняет статус на "в производстве":
  *      - Очищаем хранилище этого списка (табличное хранилище по ID таблицы), чтобы при следующем входе в этот раздел
  *        был выполнен новый запрос.
  *
  * 2. Комплектование.
  * 2.1. Раздел "МЛ ожидающие комплектации". Если для запущенного МЛ требуется комплектация, то он должен появиться в
  *      таблице:
  *      - По идее, аналогично МЛ в производстве, нужно бы сбросить "кэш" по этой таблице, чтобы при следующем входе
  *        на этот экран был выполнен новый запрос. НО, в данный момент этот список осталась клиентским, т.к., сейчас
  *        перевести его на серверный не получается из-за особенностей сортировки и необходимости получать дополнительные
  *        параметры о том, могут ли МЛ быть укомплектованы. Поэтому в данный момент тут другая логика. Данные по МЛ
  *        хранятся в entities + специальном хранилище в store. Эти данные перезапрашиваются по новой при каждом входе в
  *        этот раздел, поэтому СЕЙЧАС НИЧЕГО НЕ ДЕЛАЕМ. Но, эта таблица тоже должна стать серверной, поэтому это нужно
  *        будет изменить, сейчас при таком обновлении будет запрашиваться много данных.
  * 2.2 Раздел "сборочные". Если запущенный МЛ "сборочный", то он должен появиться в списке, поэтому очищаем данные
  * таблицы сборочных МЛ, чтобы при следуещем входе в этот раздел был выполнен запрос данных.
  *
  * 3. Мастер
  * 3.1 Раздел просмотра заданий Мастера. В списке "заданий, которые нужно сделать" должны появиться задания по новому
  *  запущенному МЛ:
  *      - Очищаем кэш таблицы "заданий, которые нужно сделать". Мы не знаем затронул ли запуск нового МЛ задания
  *      для подразделений Мастера, поэтому вынуждены очищать кэш в любом случае. При следующем входе в эти раздел
  *      будет выполнен новый запрос и данные обновятся,
  *      - Запуск нового МЛ, очевидно, не влияет на таблицу "выполненных заданий"
  * 3.2 Раздел "МЛ приостановленные". Запущенный МЛ не влияет на этот раздел.
  *
  *
  * 4. Рабочий.
  * 4.1. Раздел "Выбора подразделений". Новые задания по запущенному МЛ могут выполняться в подразделениях, в которых
  *      раньше не было заданий, т.е. подразделений не было в списке. Поэтому список нужно обновить:
  *      - Очищаем все текущие подразделения в хранилище, чтобы при следующем входе в этот раздел был выполнен новый
  *        запрос.
  *
  * 4.2. Раздел "Выбора класса РЦ в подразделении". Новые задания по запущенному МЛ могут выполняться на классаъ РЦ в
  *      подразделениях, в которых раньше не было заданий, т.е. классов РЦ не было в списке. Поэтому список нужно обновить.
  *      - Очищаем все текущие данные по классам РЦ в подразделениях в хранилище, чтобы при следующем входе в этот раздел
  *        был выполнен новый запрос.
  *
  * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Должны появиться задания по новому запущенному МЛ:
  *      - Очищаем кэши всех таблиц просмотра заданий, т.к. не знаем какие из именно затронул запуск нового МЛ. При
  *        следующем входе в эти раздел будет выплонен новый запрос и данные обновятся
  * */
  handleSheetStarted: (entityBatchFromIaId, sheetIdentity, allTasksTablesIds) => {
    //Выводим нотификейшен об успешном старте МЛ
    sendSheetStartedNotification(sheetIdentity);

    return dispatch([
      //1.1, 1.5, 2.2, 1.6, 3.1, 4.3
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),

      //1.2
      removeSheetToStart(entityBatchFromIaId),

      //4.1
      clearAllDepartmentsWithTasks(),

      //4.2
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    sheetToReviewData,
    closeReviewDialog,
  } = ownProps;
  const{
    mainPlanningSessionId,
    sheetOperationsTableData,
    allTasksTablesIds,
  } = stateProps;
  const{
    fetchSheetToStartOperationsFromIa,
    startSheet,
    handleSheetStarted,
  } = dispatchProps;
  return {
    mainPlanningSessionId,
    sheetToReviewData,
    sheetOperationsTableData,
    fetchSheetToStartOperationsFromIa: () =>
      fetchSheetToStartOperationsFromIa(mainPlanningSessionId, _get(sheetToReviewData, 'entityBatchId')),
    startSheet: (entityBatchFromIaId, entityBatchFromIaIdentity, sheetIdentity) =>
      startSheet(entityBatchFromIaId, entityBatchFromIaIdentity, sheetIdentity)
        .then(
          () => {
            handleSheetStarted(entityBatchFromIaId, sheetIdentity, allTasksTablesIds);
            closeReviewDialog();
          },
        ),
  };
};

export const SheetToStartReviewContentContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchSheetToStartOperationsFromIa }) => fetchSheetToStartOperationsFromIa(),
      },
    ],
  }),
  withPermissionsManager,
)(SheetToStartReviewContent);

SheetToStartReviewContentContainer.displayName = 'SheetToStartReviewContentContainer';