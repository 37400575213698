import { Transformer } from '../Transformer';


export class UserRole extends Transformer {

  static transformToState(item) {
    const {
      roleId,
      userId,
    } = item;


    return {
      id: [userId, roleId].join('/'),
      roleId,
      userId,
    };
  };
}