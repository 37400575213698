import React from 'react';
import {
  sendPartAndMaterialsForAssemblySheetReservedNotification,
  fetchPartAndMaterialsReserveDataForAssemblyEntityBatch,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
  fetchSheetsInProduction,
  fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
} from '../../../../operations/sheets';
import { clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { SHEET_TYPE } from '../../../../constants/sheets';

import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import {
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
} from '../../../../constants/routes';
import { matchPath } from 'react-router-dom';

import {
  fetchSheetTypeRemoteTableDataCbFactory, fetchSheetTypeToReviewCbFactory,
} from '../../../../components/Sheets/SheetsContainer';
import { fetchFullAssemblySheetsPartsAndMaterials } from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import {
  deleteAllAssemblySheetsReserveData,
} from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { NOTIFICATION_LEVEL, sendNotification } from '../../../../constants/notification';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { fetchEntitiesFromServer } from '../../../../reducers/entities/actions';
import { SHEET_MODEL } from '../../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../restCollectionApi';
import { clearAllDefaultSheetsPartsAndMaterialsToConsume } from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import { sheetOperationReviewIsConsumeEntitiesDialogOpenSelector } from '../../../../reducers/sheetOperationReview/selectors';
import { Trans } from '@lingui/macro';


/*
 * Подробно о влиянии события резервирования на типы приложения описано в комментарии к
 *  handlePartsAndMaterialsForAssemblySheetReserved в PartsAndMaterialsReserveForAssemblySheetContainer.
 *  Обработка в handlePartsAndMaterialsForAssemblySheetReserved реализована для
 * пользователя, подтверждающего резервирование в интерфейсе Комплектование -> сборочные -> резервирование.
 * В случае с получением события резервирования другими пользователями нужна похожая обработка, но, с учетом, того, что
 * пользователи могут находиться в этот момент в разделах, которые сразу же должны быть обновлены - в этом случае,
 * сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если перед перезапросом данных мы сначала
 * очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим новые данные, то у пользователя в интерфейсе
 * случится "скачок" (данные быстро пропадут, кое-где мелькнет сообщение о пустых данных, а потом тут же появятся новые
 * данные), что нежелательно.
 * Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
 * повторном входе в интерфейсы запросы выполнились заново
 * */
export const handlePartsAndMaterialsForAssemblySheetReservation = message =>
  (dispatch, getState) => {
    const {
      sheetId,
      sheetIdentity,
      entityBatchId,
    } = message;

    sendPartAndMaterialsForAssemblySheetReservedNotification(sheetIdentity);

    const state = getState();

    const currentPathname = routerPathnameSelector(state);

    //Если находимся в разделе "Комплектование - "стандартные" маршрутные листы
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if (defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        currentPathname,
      ));


    //Если находимся в разделе "Комплектование - "сборочные" маршрутные листы"
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if (assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(
        entityBatchId,
        sheetId,
        sheetIdentity,
        currentPathname,
      ));

    /*
     * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
     * очистить данные всех интерфейсов, где должны быть обновления из-за резервирования, чтобы при следующем
     * входе в эти разделы данные были запрошены заново.
     * данные потребления для МЛ очищаем (deleteAssemblySheetConsumeData_ только если в стор не установлен флаг
     * isConsumeEntitiesDialogOpen, т.к. иначе вся обработка выполняется внутри SheetOperationReviewDialog, который
     * рендерится на разных роутах, содержит нужные данные по просматриваемой операции и самостоятельно корректно
     * обрабатывает случаи, когда нужен сброс кэша или перезапрос
     * */

    let actionsToDispatch = [
      clearTableRemoteData([
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      ]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      deleteAllAssemblySheetsReserveData(),
    ];

    const isConsumeEntitiesDialogOpen = sheetOperationReviewIsConsumeEntitiesDialogOpenSelector(state);

    if(!isConsumeEntitiesDialogOpen)  {
      actionsToDispatch.push(deleteAssemblySheetConsumeData({ sheetId }));
    }

    dispatch(actionsToDispatch);
  };

const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen = (sheetId, currentPathname) =>
  dispatch => {
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      ]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteAllAssemblySheetsReserveData(),
    ]);

    dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage());

    const sheetWaitingPartsAndMaterialsReviewRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    if(sheetWaitingPartsAndMaterialsReviewRouteMatch === null) return;

    const {
      sheetId: sheetIdFromRoute,
    } = sheetWaitingPartsAndMaterialsReviewRouteMatch.params;

    //Т.к. при броадкастинге нам, в общем случае, в обработчике неизвестны параметры просматриваемого МЛ (сейчас
    //они в entities, но таблица должна стать серверной и данные по просматриваемому МЛ будут храниться в локальном
    //state), то придется запросить его, чтобы получить идентификатор партии для перезапроса детальной комплектации
    const fetchSheet = fetchSheetTypeToReviewCbFactory(dispatch, fetchSheetsInProduction);

    fetchSheet(sheetIdFromRoute)
      .then(response => {

        if(!response || response.responseMeta.count === 0) return;

        const { entityBatchId } = response.entities[SHEET_MODEL][sheetIdFromRoute];

        dispatch(fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet(
          entityBatchId,
          sheetIdFromRoute,
        ));
      });

  };

/**
 * Если находимся на экране резервирования, то просто обновляем данные. В данном случае не нужно проверять,
 * находимся ли мы на том же МЛ, для которого рпоизошло резервирование, потому что для остальных МЛ тоже нужно
 * обновить данные из-за изменения количества ЛСЕ на складе. Мы не можем знать для каких именно МЛ произойдёт и
 * зменение, поэтому делаем перезапрос для любого МЛ. Для остальных МЛ очищаем данные резервирования, чтобы при
 * следующем переходе на экран резервирования остальных МЛ данные обновились
 */
const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = (
  entityBatchId,
  sheetId,
  sheetIdentity,
  currentPathname,
) =>
  dispatch => {
    dispatch([
      deleteAssemblySheetConsumeData({ sheetId }),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchFullAssemblySheetsPartsAndMaterials),
    ));


    const partsAndMaterialsReserveRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    // если находимся НЕ на экране резервирования, то просто очищаем все данные резервирования, чтобы при следующем
    // переходе на экраны резервирования данные обновились
    if (partsAndMaterialsReserveRouteMatch === null) {
      return dispatch(deleteAllAssemblySheetsReserveData());
    }

    const currentSheetId = partsAndMaterialsReserveRouteMatch.params.sheetId;

    // Ели пользователь находится на экране резервирования МЛ, по которому произошло резервирование, то выводим
    // нотификейшн с пояснением, что данные обновились и необходимо скорректировать введённое им количества
    // резервирования ДСЕ
    if (currentSheetId === sheetId.toString()) {
      sendNotification(
        <Trans id="assembly_sheet_parts_and_materials_reserving@reserve_data_was_updated">
          Для МЛ "{sheetIdentity}" было выполнено резервирование, данные обновились, пожалуйста, проверьте и
          скорректируйте введённые вами количества ДСЕ для резервирования
        </Trans>,
          NOTIFICATION_LEVEL.INFO,
        );

      dispatch(deleteAllAssemblySheetsReserveData([sheetId]));
      return dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(entityBatchId, sheetId));
    }

    /**
    * Если пользователь на экране резервирования МЛ, по которому НЕ происходило резервирование, то сначала
    * запрашиваем МЛ, из которого нам нужено entityBatchId (т.к. в параметрах роута есть только sheetId) для
    * выполнения корректного перезапроса данных резервирования, а потом выполняем перезапрос и обновляем текущий
    * просматриваемый экран резервирвоания
    */
    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'id',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: currentSheetId,
          },
        ],
      },
    };

    return dispatch(fetchEntitiesFromServer(
      SHEET_MODEL,
      query,
    ))
      .then(response => {
        const {
          responseEntitiesIds,
          responseMeta: {
            count,
          },
        } = response;

        if (count === 0) {
          return dispatch(deleteAllAssemblySheetsReserveData([currentSheetId]));
        }

        const currentEntityBatchId = responseEntitiesIds[SHEET_MODEL][0];

        dispatch(deleteAllAssemblySheetsReserveData([currentSheetId]));
        return dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(currentEntityBatchId, currentSheetId));
      });
  };