import { connect } from 'react-redux';
import { MasterCompletedTasks } from './MasterCompletedTasks';
import {
  fetchMasterDepartmentsCompletedTasksRemoteTableData,
} from '../../../operations/masterWorkspace/index';
import { masterCompletedTasksTableDataSelector } from '../../../selectors/masterWorkspace';
import { reFetchRemoteTableData, clearTableRemoteData } from '../../../reducers/table/actions';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from './constants';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../MasterTasksToDo/constants';
import { MASTER_APP_PAUSED_SHEETS_TABLE_ID } from '../../../constants/table';


const mapStateToProps = state => ({
  masterCompletedTasksTableData: masterCompletedTasksTableDataSelector(state),
});

const mapDispatchToProps = (dispatch, { departmentIds }) => {
  const fetchMasterCompletedTasksRemoteTableData = ({ tableParams }) =>
    dispatch(fetchMasterDepartmentsCompletedTasksRemoteTableData(departmentIds, tableParams));

  const reFetchMasterCompletedTasksRemoteTableData = () =>
    dispatch(reFetchRemoteTableData(
      MASTER_COMPLETED_TASKS_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      fetchMasterCompletedTasksRemoteTableData,
    ));

  return {
    fetchMasterCompletedTasksRemoteTableData,
    reFetchMasterCompletedTasksRemoteTableData,
    clearMasterAppDepartmentsRelatedRemoteTablesData: () => dispatch(clearTableRemoteData([
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_APP_PAUSED_SHEETS_TABLE_ID,
    ])),
  };
};

export const MasterCompletedTasksContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MasterCompletedTasks);

MasterCompletedTasksContainer.displayName = 'MasterCompletedTasksContainer';