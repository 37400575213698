import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchSheetOperationTransactions } from '../../../operations/sheetOperations/index';
import { sheetOperationTransactionsTableDataSelector } from '../../../selectors/sheets';
import { SheetOperationTransactionsTable } from './SheetOperationTransactionsTable';
import { clearTableRemoteData } from '../../../reducers/table/actions';
import { createSheetOperationTransactionsTableId } from './constants';


const mapStateToProps = (state, { sheetOperationId, entitiesInBatchAmount }) => {
  const tableId = createSheetOperationTransactionsTableId(sheetOperationId);

  return {
    tableId,
    sheetOperationTransactionsTableData: sheetOperationTransactionsTableDataSelector(state, { tableId }),
    entitiesInBatchAmount,
  };
};

const mapDispatchToProps = (dispatch, { sheetOperationId }) => ({
  fetchSheetOperationTransactions: tableParams => dispatch(fetchSheetOperationTransactions(sheetOperationId, tableParams)),
  ...bindActionCreators({
    clearTableRemoteData,
  }, dispatch),
});

export const SheetOperationTransactionsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SheetOperationTransactionsTable);
