import { Transformer } from '../Transformer';

export class SheetOperationFeatureValueEntity extends Transformer {
  static transformToState(item) {
    const {
      entityRouteSheetOperationFeatureId: sheetOperationFeatureId,
      entityRouteSheetOperationId: sheetOperationId,
      value,
    } = item;

    return {
      sheetOperationFeatureId,
      sheetOperationId,
      value,
    };
  }

  static transformToDb(item) {
    const {
      sheetOperationFeatureId: entityRouteSheetOperationFeatureId,
      sheetOperationId: entityRouteSheetOperationId,
      value,
    } = item;

    return {
      entityRouteSheetOperationFeatureId,
      entityRouteSheetOperationId,
      value,
    };
  }
}