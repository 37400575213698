import React from 'react';
import {
  fieldComparatorFactory,
  numberComparator,
} from '@bfg-frontend/utils/lib/array';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _pick from 'lodash/pick';
import _isNil from 'lodash/isNil';
import { TOOLS_COLUMN_NAME } from '../../../../constants/table';
import _reduce from 'lodash/reduce';
import _last from 'lodash/last';
import _isFunction from 'lodash/isFunction';


const CUSTOM_CELL_RENDERER_PROPS_FROM_TABLE_DATA_LAYER = [
  'tableId',
  'tableModel',
  'activePage',
  'changePage',
  'pageSize',
  'changePageSize',
  'sortParams',
  'changeSort',
  'filterParams',
  'changeFilters',
];


export const reactTablePropsAdapter = tableDataLayerProps => {
  const{
    rowIdProperty,
    filteredAndSortedRowsDataForCurrentPage,
    noDataContent,
    sortParams,
    changeSort,
    onRowCombinedClicksHandler,
    resizeColumns,
    pageSize,
    activePage,
    /*
    * changeColumnsOrder
    * изменение порядка колонок придется видимо вручную делать, там люди для этого hoc'и пишут. В react-table v7 уже
    * появился хук useColumnReorder
    * */
    getRowStyle,
    wrappedTableComponentProps = {},
  } = tableDataLayerProps;

  const {
    getTrProps,
  } = wrappedTableComponentProps;

  return{
    tableColumns: getColumns(tableDataLayerProps),
    tableData: filteredAndSortedRowsDataForCurrentPage,
    noDataText: noDataContent,
    sorted: _isFunction(changeSort) ?
      sortParams
        .map(({ column, asc }) => ({
          id: column,
          desc: !asc,
        })) :
      undefined,
    onSortedChange: _isFunction(changeSort) ?
      sorted => {
        const {
          id: newColumn,
          desc,
        } = _last(sorted);

        const newSortParams = [
          {
            column: newColumn,
            asc: !desc,
          },
        ];

        return changeSort(newSortParams);
      } : undefined,
    resizeColumns: _isFunction(resizeColumns) ?
      columnSizesData => {
        const updatedColumnSizesData = _reduce(
          columnSizesData,
          (acc, { id, value }) => ({
            ...acc,
            [id]: value,
          }),
          {},
        );

        return resizeColumns(updatedColumnSizesData);
      } :
      undefined,
    onRowClick: _isFunction(onRowCombinedClicksHandler) ?
      (rowOriginal, rowIndex) => onRowCombinedClicksHandler(_get(rowOriginal, rowIdProperty), rowOriginal, rowIndex) :
      undefined,
    getTrProps:  getTrPropsCbFactory(getRowStyle, getTrProps, rowIdProperty),

    //На самом деле, эти пропсы не важны для таблицы, т.к. мы управляем всем самостоятельно, но, если их не задать, то
    //react-table делает много ненужного, подробней в ReactTableWrapper
    pageSize,
    page: activePage,
  };
};

const getTrPropsCbFactory = (getRowStyle, getTrProps, rowIdProperty) => {
  if(!_isFunction(getRowStyle) && !_isFunction(getTrProps)) return undefined;

  if(!_isFunction(getRowStyle)) return getTrProps;

  return (state, rowInfo, column, instance) => {
    const {
      original: rowOriginal,
      index: rowIndex,
    } = rowInfo;
    return {
      style: getRowStyle(_get(rowOriginal, rowIdProperty), rowOriginal, rowIndex),
      ...(_isFunction(getTrProps) ? getTrProps(state, rowInfo, column, instance) : {}),
    };
  };
};

const getDataGridCellClassName = (classNamesArrayFromSchema = [], textAlign) => {

  const classNameFromSchema = classNamesArrayFromSchema.join(' ');

  const textAlignClassName = textAlign === 'center' ?
    'table-cell-with-center-text-align' :
    '';

  /*
  * Для однотипности и понятности обработки, все сравнения ведутся с пустой строкой, хотя она falsy и можно было бы
  * написать проще + d случае, если никакие классы не определены, хочется вернуть явно именно undefined для className
  * */
  const resultCellClassName = [
    'react-table-wrapper__table-column-default-cell', // добавляем дефолтный класс для всех ячеек
    classNameFromSchema,
    textAlignClassName,
  ]
    .filter(classNamePart => classNamePart !== '')
    .join(' ');

  return resultCellClassName === '' ?
    undefined :
    resultCellClassName;
};

const getColumnRender = (
  schemaFieldData,
  rowIdProperty,
  columnCustomCellRendererProps,
  customCellRenderersCommonTableProps,
) => {

  const {
    customComponent: CustomComponent,
    tooltip,
  } = schemaFieldData;

  const columnRender = CustomComponent ?
    getCustomColumnRender({
      CustomComponent,
      rowIdProperty,
      schemaFieldData,
      columnCustomCellRendererProps,
      customCellRenderersCommonTableProps,
    }) :
    defaultColumnRender;

  return !!tooltip ?
    getColumnRenderWithTooltip(columnRender) :
    columnRender;

};

const getCustomColumnRender = ({
  CustomComponent,
  rowIdProperty,
  schemaFieldData,
  columnCustomCellRendererProps,
  customCellRenderersCommonTableProps,
}) =>
  /* eslint-disable react/prop-types */
  ({ value, row }) => (
    <CustomComponent
        {...columnCustomCellRendererProps}
        itemId={row._original[rowIdProperty]}
        value={value}
        data={row._original}
        schemaFieldData={schemaFieldData}
        commonTableProps={customCellRenderersCommonTableProps}
    />
  );
/* eslint-enable react/prop-types */

const defaultColumnRender = ({ value }) =>
  _isNil(value) ? '' : value;


const getColumnRenderWithTooltip = columnRender =>
  props => (
    // eslint-disable-next-line react/prop-types
    <div title={props.value}>
      {columnRender(props)}
    </div>
  );

const getColumns = tableDataLayerProps => {
  const {
    schemaFields,
    rowIdProperty,
    customCellRenderersProps,
    disableSort: tableDisableSort,
    disableColumnsResize: tableDisableColumnsResize,
  } = tableDataLayerProps;

  const customCellRenderersCommonTableProps = _pick(
    tableDataLayerProps,
    CUSTOM_CELL_RENDERER_PROPS_FROM_TABLE_DATA_LAYER,
  );

  return Object
    .values(schemaFields)
    .filter(({ display, columnName }) => {
      //для служебной колонки кроме свойства display также проверяем наличие опций в customCellRenderersProps, иначе
      //смысла её отрисовывать нет
      if (columnName === TOOLS_COLUMN_NAME) {
        return (
          display &&
          _size(
            _get(customCellRenderersProps, [TOOLS_COLUMN_NAME, 'toolsOptions']),
          ) > 0
        );
      }
      return display;
    })
    .sort(fieldComparatorFactory(numberComparator, 'order'))
    .map(schemaFieldData => {
      const {
        columnName,
        displayName,
        show,
        width,
        type,
        textAlign,
        className,
        order,
        disableSort: columnsDisableSort,
        disableResize: columnsDisableResize,
      } = schemaFieldData;

      return {
        order,
        Header: displayName,
        accessor: columnName,
        sortable: !tableDisableSort && !columnsDisableSort,
        resizable: !tableDisableColumnsResize && !columnsDisableResize,
        show,
        width,
        type,
        className: getDataGridCellClassName(className, textAlign),
        Cell: getColumnRender(
          schemaFieldData,
          rowIdProperty,
          customCellRenderersProps[columnName],
          customCellRenderersCommonTableProps,
        ),
      };
    });
};
