import React, { useState, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import './style.css';
import { MATERIAL_UI_DIALOG_MAX_WIDTH, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';
import { CancelLabelTrans } from '../../../../utils/commonTransComponents';
import { SimpleConfirmDialog } from '../../SimpleConfirmDialog/SimpleConfirmDialog';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import TextField from '@mui/material/TextField';
import { floatNumsNormalizer } from '@bfg-frontend/normalizers';
import { required } from '../../../../utils/formValidators/index';
import {
  amountOfDigitsInFloatNumberValidatorFabric,
  isInRangeValidatorFabric,
} from '@bfg-frontend/validators';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { subDecimals } from '../../../../utils/decimal';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../../api/restCollectionApi/index';
import { fetchEntitiesFromServer } from '../../../../reducers/entities/actions';
import { WAREHOUSE_ENTITY_BATCH_RESERVE_MODEL } from '../../../../constants/models';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { SimpleHelpAlert } from '../../SimpleHelpAlert/SimpleHelpAlert';


//См. описание использования action creatorа внутри компонента. Это временное решение! Поэтому расположено прямо в
//компоненте
const _checkIfSomethingIsConsumedForBatch = batchId =>
  dispatch => {

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'entityBatchId',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: batchId,
          },
          {
            column: 'consumedQuantity',
            filterType: FILTER_TYPES.GREATER_THAN,
            filterValue: 0,
          },
        ],
      },
      page: 1,
      limit: 1,
    };

    return dispatch(fetchEntitiesFromServer(WAREHOUSE_ENTITY_BATCH_RESERVE_MODEL, query))
      .then(({ responseMeta }) => _get(responseMeta, 'count') > 0)
      .catch(() => null);
  };

const ENTITY_BATCH_SPLIT_REASON_TEXTAREA_ROWS = 3;

export const SplitEntityBatchDialog = props => {
  const {
    isOpen,
    onSubmit,
    closeDialog,
    batchSize: initialBatchSize,

    batchId,
  } = props;

  const [reason, setReason] = useState('');

  const [batchSize, setBatchSize] = useState('0');

  const [errors, setErrors] = useState({});

  /*
  * ВРЕМЕННОЕ РЕШЕНИЕ!
  * Запрещаем делить сборочную партию, если по ней было потребление, т.к. сейчас нет логики, которая позволяла бы
  * корректно поделить потребленное количество ДСЕ при делении партии, и это может приводить к тому, что под
  * разделенную партию будет потреблено больше, чем требуется и для чего-то не хватит деталей. Поэтому, пока
  * лучше запретить, в будущем логика должна появиться
  */
  //TODO удалить временное решение
  const [isConsumedForBatch, setIsConsumedForBatch] = useState(null);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(_checkIfSomethingIsConsumedForBatch(batchId))
        .then(setIsConsumedForBatch);
    },
    [dispatch, batchId, setIsConsumedForBatch],
  );

  if(isConsumedForBatch === null) {
    return null;
  }

  return (
    <SimpleConfirmDialog
        className="split-entity-batch-dialog"
        dialogMaxWidth={MATERIAL_UI_DIALOG_MAX_WIDTH.SM}
        isOpen={isOpen}
        closeDialog={closeDialog}
        title={
          <Trans id="sheet_operation_review.split_entity_batch_dialog@title">
            Разделить партию
          </Trans>
        }
        additionalComponent={
          <div>
            {
              isConsumedForBatch ?
                <SimpleHelpAlert
                    className="split-entity-batch-dialog__is-consumed-for-batch-alert"
                    content={
                      <Trans id="sheet_operation_review.split_entity_batch_dialog@can_not_split_because_already_consumed">
                        Под партию потреблены комплектующие, такую партию запрещается делить
                      </Trans>
                    }
                /> :
                <React.Fragment>
                  {_renderInitialBatchSize(batchSize, initialBatchSize)}
                  {_renderBatchSizeTextField(batchSize, setBatchSize, errors.batchSize)}
                  {_renderEntityBatchSplitReasonField(reason, setReason, errors.reason)}
                </React.Fragment>
            }
          </div>
        }
        confirmBtn={
          <Trans id="sheet_operation_review.split_entity_batch_dialog@submit_button">
            Разделить партию
          </Trans>
        }
        cancelBtn={CancelLabelTrans}
        onConfirm={() => {
          const errors =  _getErrors(batchSize, initialBatchSize, reason);

          if(!!errors.batchSize || !!errors.reason) {
            return setErrors(errors);
          }

          return onSubmit({
            batchSize: Number(batchSize),
            reason: reason.trim(),
          });
        }}

        disableConfirm={isConsumedForBatch}
    />
  );
};

const MAX_AMOUNT_OF_DIGITS_IN_BATCH_SIZE = 4;
const amountOfDigitsInBatchSizeValidator = amountOfDigitsInFloatNumberValidatorFabric({
  amountOfDigits: MAX_AMOUNT_OF_DIGITS_IN_BATCH_SIZE,
  errorIdentityCreator: (_, amountOfDigits) =>
    <Trans id="sheet_operation_review.split_entity_batch_dialog@amount_of_digits_in_batch_size_must_be_in_range">
      Значение размера отделяемой партии не должно содержать более { amountOfDigits } знаков после запятой
    </Trans>,
});

const _getErrors = (batchSize, initialBatchSize, reason) => {

  const minValue = 0;
  const maxValue = initialBatchSize;

  const batchSizeRangeValidator = isInRangeValidatorFabric({
    minValue,
    minValueInclusive: false,
    maxValue,
    maxValueInclusive: false,
    errorIdentityCreator: () =>
      <Trans id="sheet_operation_review.split_entity_batch_dialog@batch_size_must_be_in_range">
        Размер отделяемой партии должен быть больше {minValue} и не превышать размер исходной партии {maxValue}
      </Trans>,
  });

  const batchSizeError = required(batchSize) ||
    amountOfDigitsInBatchSizeValidator(batchSize) ||
    batchSizeRangeValidator(Number(batchSize));

  const reasonError = required(reason);

  return {
    batchSize: !!batchSizeError ?
      ({
        errorValue: batchSize,
        errorMsg: batchSizeError,
      }) :
      undefined,

    reason: !!reasonError ?
      ({
        errorValue: reason,
        errorMsg: reasonError,
      }) :
      undefined,
  };
};

const _renderInitialBatchSize = (batchSize, initialBatchSize) => (
  <div className="split-entity-batch-dialog__initial-batch-size">
    <div className="split-entity-batch-dialog__initial-batch-size-label">
      <Trans id="sheet_operation_review.split_entity_batch_dialog@initial_batch_size_label">
        Количество ДСЕ в текущей партии
      </Trans>
      {':'}
    </div>
    <span className="split-entity-batch-dialog__initial-batch-size-value">
        {
          <React.Fragment>
            {initialBatchSize}
            <ArrowRightAltIcon className="split-entity-batch-dialog__initial-batch-size-value-arrow-icon"/>
            {
              batchSize === '' ?
                initialBatchSize :
                subDecimals(initialBatchSize, Number(batchSize))
            }
          </React.Fragment>
        }
      </span>
    </div>
);

const _renderBatchSizeTextField = (batchSize, setBatchSize, batchSizeErrorData) => {

  const batchSizeErrorMsg = _getFieldErrorMessage(batchSizeErrorData, batchSize);

  return(
    <div className="split-entity-batch-dialog__batch-size">
      <div className="split-entity-batch-dialog__batch-size-label">
        <Trans id="sheet_operation_review.split_entity_batch_dialog@batch_size_field_label">
          Количество ДСЕ в отделяемой партии
        </Trans>
        {':'}
      </div>
      <TextField
          id="split-entity-batch-dialog__batch-size-text-field"
          className="split-entity-batch-dialog__batch-size-text-field"
          type="text"
          value={batchSize}
          onChange={e => setBatchSize(floatNumsNormalizer(e.target.value))}
          error={!!batchSizeErrorMsg}
          helperText={batchSizeErrorMsg}
          fullWidth
          autoFocus
          variant={MATERIAL_UI_VARIANT.OUTLINED}
      />
    </div>
  );
};

const _renderEntityBatchSplitReasonField = (reason, setReason, reasonErrorData) =>  {

  const reasonErrorMsg = _getFieldErrorMessage(reasonErrorData, reason);

  return(
    <div className="split-entity-batch-dialog__split-reason">
      <div className="split-entity-batch-dialog__split-reason-label">
        <Trans id="sheet_operation_review.split_entity_batch_dialog@reason_rield_label">
          Причина деления партии
        </Trans>
        {':'}
      </div>
      <TextField
          id="split-entity-batch-dialog__split-reason-textarea"
          className="split-entity-batch-dialog__split-reason-textarea"
          multiline
          rows={ENTITY_BATCH_SPLIT_REASON_TEXTAREA_ROWS}
          variant={MATERIAL_UI_VARIANT.OUTLINED}
          fullWidth
          value={reason}
          onChange={e => setReason(e.target.value)}
          error={!!reasonErrorMsg}
          helperText={reasonErrorMsg}
      />
    </div>
  );
};

const _getFieldErrorMessage = (fieldErrorData, fieldValue) => {
  if(!fieldErrorData) {
    return undefined;
  }

  const { errorValue, errorMsg }  = fieldErrorData;

  return errorValue === fieldValue ?
    errorMsg :
    undefined;
};

SplitEntityBatchDialog.propTypes = {
  closeDialog: FUNC_IS_REQUIRED_TYPE,
  onSubmit: FUNC_IS_REQUIRED_TYPE,
  isOpen: PropTypes.bool.isRequired,
  batchSize: PropTypes.number.isRequired,

  //Временное решение, см. комментарий в компоненте
  batchId: PropTypes.number.isRequired,
};