import { FILTER_GROUP_TYPES, FILTER_TYPES, WITH_PARAMS } from '../../api/restCollectionApi';
import {
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  SHEET_OPERATION_FEATURE_SCOPE_MODEL,
  SHEET_OPERATION_FEATURES_MODEL,
} from '../../constants/models';
import {
  defaultSaveOrDeleteEntitiesErrorHandler,
  deleteEntitiesFromServer,
  fetchEntitiesFromServer,
  saveEntitiesOnServer,
} from '../../reducers/entities/actions';
import {
  getSheetOperationFeatureNameErrorMessage,
  sendNotAlCreatedSheetOperationFeatureScopesSavedNotification,
  sendSheetOperationFeatureCreatedNotification,
  sendSheetOperationFeatureSuccessfullyDeletedNotification,
  sendNotAllEditedSheetOperationFeatureScopesWasSavedNotification,
  getDeleteSheetOperationFeatureErrorMessage,
  AllAddedFeatureScopesNotSavedTrans,
  AllDeletedFeatureScopesNotSavedTrans,
  FeatureWasNotCreatedTrans,
} from './constants';
import { sendChangesSavedSuccessfullyNotification } from '../../constants/notification';
import { broadcastEventMessage } from '../../api/socketApi/broadcastApi/broadcast/broadcastEventMessage';
import {
  SHEET_OPERATION_FEATURE_DELETED_EVENT_TYPE,
} from '../../constants/sockets';
import { AllEntitiesNotSavedServerErrorLabelTrans } from '../../utils/commonTransComponents';
import _get from 'lodash/get';

const SHEET_OPERATION_FEATURE_EDITING_SCREEN_DATA_WITH_PARAMS = [
  SHEET_OPERATION_FEATURE_SCOPE_MODEL,
  {
    column: DEPARTMENT_MODEL,
    params: [{ key: WITH_PARAMS.STRICT, value: false }],
  },
  {
    column: EQUIPMENT_CLASS_MODEL,
    params: [{ key: WITH_PARAMS.STRICT, value: false }],
  },
  {
    column: OPERATION_MODEL,
    params: [{ key: WITH_PARAMS.STRICT, value: false }],
  },
];

const SHEET_OPERATION_FEATURE_EDITING_SCREEN_REQUEST_MODEL_RELATIONS = {
  [SHEET_OPERATION_FEATURE_SCOPE_MODEL]: {
    level: 1,
  },
  [DEPARTMENT_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_FEATURE_SCOPE_MODEL,
  },
  [EQUIPMENT_CLASS_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_FEATURE_SCOPE_MODEL,
  },
  [OPERATION_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_FEATURE_SCOPE_MODEL,
  },
};

export const fetchSheetOperationFeatureData = (sheetOperationFeatureId, queryParams = {}, options = {}) =>
  dispatch => {
    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'id',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: sheetOperationFeatureId,
          },
        ],
      },
      with: SHEET_OPERATION_FEATURE_EDITING_SCREEN_DATA_WITH_PARAMS,
      ...queryParams,
    };


    return dispatch(fetchEntitiesFromServer(
      SHEET_OPERATION_FEATURES_MODEL,
      query,
      {
        modelRelations: SHEET_OPERATION_FEATURE_EDITING_SCREEN_REQUEST_MODEL_RELATIONS,
        ...options,
      },
    ));
  };

export const createSheetOperationFeatureWithScopes = (
  sheetOperationFeatureData,
  sheetOperationFeatureScopesDataArray,
) =>
  async dispatch => {

    const {
      responseEntitiesWithoutErrors: createdSheetOperationFeatureEntities,
    }  = await dispatch(createOrEditSheetOperationFeature(sheetOperationFeatureData));

    /*
    Мы всегда создаём только один шаблон характеристики, но в ответ получаем массив, поэтому берем первый элемент
    из ответа
    */
    const createdSheetOperationFeatureEntity = createdSheetOperationFeatureEntities[0];
    const { id } = createdSheetOperationFeatureEntity;

    const {
      areAllEntitiesSaved: areSheetOperationFeatureScopesSaved,
      responseEntitiesWithoutErrors: sheetOperationFeatureScopeResponseEntities,
    } = await dispatch(createFeatureScopes(sheetOperationFeatureScopesDataArray, id, FeatureWasNotCreatedTrans))
      .catch(response => {
        /*
        Если сервер ответил с ошибкой, то удаляем созданную
        характеристику, потому что она без областей видимости
        */

        dispatch(deleteEntitiesFromServer(
          SHEET_OPERATION_FEATURES_MODEL,
          [{ id }],
        ));

        return Promise.reject(response);
      });

    if (!areSheetOperationFeatureScopesSaved) {
      sendNotAlCreatedSheetOperationFeatureScopesSavedNotification();

      return {
        [SHEET_OPERATION_FEATURES_MODEL]: createdSheetOperationFeatureEntity,
        [SHEET_OPERATION_FEATURE_SCOPE_MODEL]: sheetOperationFeatureScopeResponseEntities,
        areAllEntitiesSaved: areSheetOperationFeatureScopesSaved,
      };
    }

    sendSheetOperationFeatureCreatedNotification();

    return {
      [SHEET_OPERATION_FEATURES_MODEL]: createdSheetOperationFeatureEntity,
      [SHEET_OPERATION_FEATURE_SCOPE_MODEL]: sheetOperationFeatureScopeResponseEntities,
      areAllEntitiesSaved: areSheetOperationFeatureScopesSaved,
    };
  };

export const editSheetOperationFeatureWithScopes = (
  sheetOperationFeatureData,
  deletedFeatureScopesIds,
  addedFeatureScopes,
) =>
  async dispatch => {
    const {
      name,
      id,
    } = sheetOperationFeatureData;

    const isNameChanged = name !== null;

    /*
    Отправляем запрос на редактирование характеристики, если изменилось её имя
    */
    if (isNameChanged) {
      await dispatch(createOrEditSheetOperationFeature(sheetOperationFeatureData, false));
    }

    /*
    Отправляем запрос на удаление областей действия, только если были переданы идентификаторы удалённых областей
    действия
    */
    const {
      responseEntitiesWithoutErrors: deletedSheetOperationFeatureScopesEntities = [],
    } = deletedFeatureScopesIds.length === 0 ?
      {} :
      await dispatch(deleteSheetOperationFeatureScopes(deletedFeatureScopesIds));

    /*
    Отправляем запрос на создание областей действия, только если были переданы entities добавленных областей действия
    */
    const {
      responseEntitiesWithoutErrors: addedSheetOperationFeatureScopeEntities = [],
    } = addedFeatureScopes.length === 0 ?
      {} :
      await dispatch(createFeatureScopes(addedFeatureScopes, id, AllAddedFeatureScopesNotSavedTrans));


    const areAllChangesSaved = (deletedFeatureScopesIds.length === deletedSheetOperationFeatureScopesEntities.length) &&
      (addedFeatureScopes.length === addedSheetOperationFeatureScopeEntities.length);

    if (!areAllChangesSaved) {
      sendNotAllEditedSheetOperationFeatureScopesWasSavedNotification();
    } else {
      sendChangesSavedSuccessfullyNotification();
    }

    return {
      addedSheetOperationFeatureScopeEntities,
      deletedSheetOperationFeatureScopesEntities,
      areAllChangesSaved,
    };
  };

export const createOrEditSheetOperationFeature = (sheetOperationFeatureData, isCreating = true) =>
  async dispatch => {
    const {
      name,
      id,
    } = sheetOperationFeatureData;

    try {
      return await dispatch(saveEntitiesOnServer(
        SHEET_OPERATION_FEATURES_MODEL,
        /*
        REST точка шаблонов характеристик работает только с массивами, документация:
        https://colab.bfg-soft.ru/pages/viewpage.action?pageId=248971357
        */
        [{ name, id }],
        isCreating,
        { showServerError: false },
      ));
    } catch (response) {

      const responseEntitiesWithErrors = _get(response, ['responseEntitiesWithErrors']);

      const errorMessage = getSheetOperationFeatureNameErrorMessage(responseEntitiesWithErrors) ||
        AllEntitiesNotSavedServerErrorLabelTrans;

      defaultSaveOrDeleteEntitiesErrorHandler(errorMessage);

      return Promise.reject(response);
    }
  };

export const createFeatureScopes = (newFeatureScopesData, sheetOperationFeatureId, requestErrorMessage) =>
  async dispatch => {

    const addedFeatureScopesForSubmit = newFeatureScopesData
      .map(featureScope => ({
        ...featureScope,
        sheetOperationFeatureId,
      }));

    try {

      const {
        responseEntitiesWithoutErrors,
        areAllEntitiesSaved,
      } = await dispatch(saveEntitiesOnServer(
        SHEET_OPERATION_FEATURE_SCOPE_MODEL,
        addedFeatureScopesForSubmit,
        true,
        { showServerError: false },
      ));

      return {
        responseEntitiesWithoutErrors,
        areAllEntitiesSaved,
      };

    } catch (response) {

      defaultSaveOrDeleteEntitiesErrorHandler(requestErrorMessage);

      return Promise.reject(response);
    }
  };

export const deleteSheetOperationFeatureScopes = sheetOperationFeatureScopeIds =>
  async dispatch => {

    const transformedFeatureScopeIdsScopesIds = sheetOperationFeatureScopeIds
      .map(id => ({ id }));

    try {

      const {
        responseEntitiesWithoutErrors,
        areAllEntitiesSaved,
      } = await dispatch(deleteEntitiesFromServer(
        SHEET_OPERATION_FEATURE_SCOPE_MODEL,
        transformedFeatureScopeIdsScopesIds,
        { showServerError: false },
      ));

      return {
        responseEntitiesWithoutErrors,
        areAllEntitiesSaved,
      };
    } catch (response) {
      defaultSaveOrDeleteEntitiesErrorHandler(AllDeletedFeatureScopesNotSavedTrans);

      return Promise.reject(response);
    }
  };

export const deleteSheetOperationFeature = (sheetOperationFeatureId, withDeactivationInfo) =>
  dispatch => {
    const sheetOperationFeaturesIdToDelete = [{
      id: sheetOperationFeatureId,
    }];

    return dispatch(deleteEntitiesFromServer(
      SHEET_OPERATION_FEATURES_MODEL,
      sheetOperationFeaturesIdToDelete,
      { showServerError: false },
    ))
      .then(response => {
        broadcastSheetOperationFeatureDeleted(sheetOperationFeatureId);

        sendSheetOperationFeatureSuccessfullyDeletedNotification();

        return response;
      })
      .catch(response => {
        const responseEntitiesWithErrors = _get(response, ['responseEntitiesWithErrors']);

        const errorMessage = getDeleteSheetOperationFeatureErrorMessage(responseEntitiesWithErrors, withDeactivationInfo)
          || AllEntitiesNotSavedServerErrorLabelTrans;

        defaultSaveOrDeleteEntitiesErrorHandler(errorMessage);

        return Promise.reject(response);
      });
  };
/*
* В данный момент, PUT запрос для редактирования модели характеристики обязательно требует поля name, даже если
* оно не редактируется. Иначе ошибка запроса. Возможно, в будущем это изменится и можно будет его не указывать.
* */
export const toggleSheetOperationFeatureActiveStateRequest = (sheetOperationFeatureId, sheetOperationFeatureName, disabled) =>
  dispatch => dispatch(saveEntitiesOnServer(
    SHEET_OPERATION_FEATURES_MODEL,
    [
      {
        id: sheetOperationFeatureId,
        name: sheetOperationFeatureName,
        disabled,
      },
    ],
    false,
  ));

export const broadcastSheetOperationFeatureDeleted = sheetOperationFeatureId =>
  broadcastEventMessage(
    SHEET_OPERATION_FEATURE_DELETED_EVENT_TYPE,
    { sheetOperationFeatureId },
  );