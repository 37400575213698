import { SERVER_DATA_POINT } from '../../constants/serverDataPoints';

import _size from 'lodash/size';
import { SheetToStart } from './SheetToStart';
import { DefaultSheetWithEnoughPartsAndMaterials } from './DefaultSheetWithEnoughPartsAndMaterials';
import { SheetOperationFeaturesValueDataPoint } from './SheetOperationFeaturesValueDataPoint';

const DATA_POINT_TRANSFORMERS = {
  [SERVER_DATA_POINT.SHEETS_TO_START]: SheetToStart,
  [SERVER_DATA_POINT.ASSEMBLY_SHEETS_TO_START]: SheetToStart,
  [SERVER_DATA_POINT.DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS]: DefaultSheetWithEnoughPartsAndMaterials,
  [SERVER_DATA_POINT.SHEET_OPERATION_FEATURES_VALUE]: SheetOperationFeaturesValueDataPoint,
};

/*
* Подразумевается, что все трансформеры наследуются от абстрактного класса Transformer, у которого есть реализация
* по умолчанюи для методов transformToState, поэтому дополнительных проверок на существование
* этих функций не делается.
*
* С дата точек данные только запрашиваются, а не записываются в БД, поэтому обратного метода (по аналогии с трансформерами
* сущностей - entities) transformToDb нет
* */
export const transformDataPointToState = (responseDataFieldArray, dataPoint, state) => {
  if(!_size(responseDataFieldArray)) return [];

  const DataPointTransformer = DATA_POINT_TRANSFORMERS[dataPoint];

  if(!DataPointTransformer) return responseDataFieldArray;

  return responseDataFieldArray.map(item => DataPointTransformer.transformToState(item, state));
};