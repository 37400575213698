import React, { Component } from 'react';

import {
  Switch,
  Route,
} from 'react-router-dom';
import { ADMIN_APP_USERS_ROUTE, NEW_USER_SUB_ROUTE } from '../../../constants/routes';
import { UsersTableScreenContainer } from '../UsersTable/UsersTableScreenContainer';
import { UserEditingScreenContainer } from '../UserEditingScreen/UserEditingScreenContainer';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../hoc/withPermissionsManager/constants';
import { reactRouterParamsSelector } from '../../../selectors/reactRouter';


export class UserAdministrationScreen extends Component {

  static propTypes = {
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  _renderContent = () =>
    <Switch>
      <Route
          path={`${ADMIN_APP_USERS_ROUTE}/:userId`}
          render={this._userEditingScreenRouteRenderProp}
      />
      <Route
          exact
          path={ADMIN_APP_USERS_ROUTE}
          component={UsersTableScreenContainer}
      />
    </Switch>;

  _userEditingScreenRouteRenderProp = reactRouterProps => {
    const { PermissionsManager } = this.props;

    const { userId: userIdFromRoute } = reactRouterParamsSelector(null, reactRouterProps);

    const isCreatingRoute = userIdFromRoute === NEW_USER_SUB_ROUTE;

    if(
      (isCreatingRoute && PermissionsManager.isGranted(PERMISSION.CREATE_USER)) ||
      (!isCreatingRoute && PermissionsManager.isGranted(PERMISSION.EDIT_USER))
    ) {
      return <UserEditingScreenContainer {...reactRouterProps} />;
    }

    return null;
  };

  render() {

    return (
      <div className="user-administration">
        {this._renderContent()}
      </div>
    );
  }
}