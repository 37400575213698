import { connect } from 'react-redux';
import { clearTableRemoteData, reFetchRemoteTableData } from '../../../reducers/table/actions';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../MasterTasksToDo/constants';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../MasterCompletedTasks/constants';
import { MASTER_APP_PAUSED_SHEETS_TABLE_ID } from '../../../constants/table';
import { fetchPausedSheets } from '../../../operations/sheets';
import { MasterPausedSheets } from './MasterPausedSheets';
import { fetchSheetTypeRemoteTableDataCbFactory } from '../../Sheets/SheetsContainer';


const mapDispatchToProps = dispatch => ({
  reFetchMasterPausedSheetsRemoteTableData: () => dispatch(reFetchRemoteTableData(
    MASTER_APP_PAUSED_SHEETS_TABLE_ID,
    MASTER_APP_PAUSED_SHEETS_TABLE_ID,
    fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchPausedSheets),
  )),
  clearMasterTasksRemoteTablesData: () => dispatch(clearTableRemoteData([
    MASTER_TASKS_TO_DO_TABLE_ID,
    MASTER_COMPLETED_TASKS_TABLE_ID,
  ])),
});

export const MasterPausedSheetsContainer = connect(
  null,
  mapDispatchToProps,
)(MasterPausedSheets);