import { PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE } from '../../../constants/routes';
import { fetchOrdersInProduction } from '../../../operations/orders';
import { OrdersContainer } from '../../Orders/OrdersContainer';
import React from 'react';
import { OrdersInProductionReviewContentContainer }
from './OrdersInProductionReviewContent/OrdersInProductionReviewContentContainer';
import { ORDERS_IN_PRODUCTION_FILTERS_SCHEMA } from '../../Orders/ordersFilters/ordersFilters';
import { ORDER_TYPE } from '../../../constants/orders';
import { Trans } from '@lingui/macro';


export const OrdersInProduction = () => (
  <OrdersContainer
      className="orders-in-production"
      mainRoutePath={PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE}
      ordersIdentity={ORDER_TYPE.IN_PRODUCTION}
      orderItemIdProperty="id"
      fetchOrderEntitiesActionCreator={fetchOrdersInProduction}
      orderReviewContentComponent={OrdersInProductionReviewContentContainer}
      noDataText={
        <Trans id="orders_in_production@no_orders_in_production">
          В данный момент нет ни одного заказа в производстве
        </Trans>
      }
      filtersSchema={ORDERS_IN_PRODUCTION_FILTERS_SCHEMA}
  />
);
