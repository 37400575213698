import React from 'react';
import Button from '@mui/material/Button';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import { ADDITIONAL_ACTIONS_MENU_POSITION_PROPS } from '../constants';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import { AdditionalLabelTrans } from '../../../../utils/commonTransComponents';

export const AdditionalActionsDropdown = props => {
  const {
    menuOptions,
    menuAnchorEl,
    onOpen,
    onClose,
  } = props;

  if (menuOptions.length === 0) {
    return null;
  }

  return (
    <div className="sheet-operation-review-dialog__additional-actions-dropdown">
      <Button
          className="sheet-operation-review-dialog__additional-actions-dropdown-button"
          variant={MATERIAL_UI_VARIANT.CONTAINED}
          color={MATERIAL_UI_STYLE_COLOR.INHERIT}
          disableElevation
          onClick={onOpen}
      >
        <div className="sheet-operation-review-dialog__additional-actions-dropdown-button-content">
          {AdditionalLabelTrans}
          <ExpandMoreIcon />
        </div>
      </Button>
      <Menu
          className="sheet-operation-review-dialog__additional-actions-dropdown-menu"
          anchorEl={menuAnchorEl}
          open={!!menuAnchorEl}
          onClose={onClose}
          {...ADDITIONAL_ACTIONS_MENU_POSITION_PROPS}
      >
        {
          menuOptions
            .map(
              ({ menuItemIdentity, menuItemTitle, onMenuItemClick, disabledMenuItemTooltip }) => {
                const MenuItemMarkup = (
                  <MenuItem
                      key={`sheet-operation-review-dialog__additional-actions-dropdown-menu-item-${menuItemIdentity}`}
                      className="sheet-operation-review-dialog__additional-actions-dropdown-menu-item"
                      onClick={onMenuItemClick}
                      disabled={!!disabledMenuItemTooltip}
                  >
                    <div>{menuItemTitle}</div>
                  </MenuItem>
                );

                if(!disabledMenuItemTooltip) {
                  return MenuItemMarkup;
                }

                /*
                * Чтобы тултип адекватно работал с задизейбленным пунктом меню нужна любая обертка над ним в child
                * у Tooltip, в данном случае, это просто span
                * */
                return(
                  <Tooltip
                      key={`sheet-operation-review-dialog__additional-actions-dropdown-menu-item-tooltip-${menuItemIdentity}`}
                      classes={{
                        popper: 'sheet-operation-review-dialog__additional-actions-dropdown-menu-item-tooltip-popper',
                        tooltip: 'sheet-operation-review-dialog__additional-actions-dropdown-menu-item-tooltip',
                      }}
                      title={
                      disabledMenuItemTooltip
                    }
                      placement="left"
                  >
                        <span>
                          {MenuItemMarkup}
                        </span>
                  </Tooltip>
                );
              },
            )
        }
      </Menu>
    </div>
  );
};

const MENU_OPTIONS_TYPE = PropTypes.arrayOf(PropTypes.shape({
  menuItemIdentity: PropTypes.string,
  menuItemTitle: PropTypes.element,
  onMenuItemClick: PropTypes.func,
  disabledMenuItemTooltip: PropTypes.element,
})).isRequired;

AdditionalActionsDropdown.propTypes = {
  menuOptions: MENU_OPTIONS_TYPE,
  menuAnchorEl: PropTypes.instanceOf(Element),
  onOpen: FUNC_IS_REQUIRED_TYPE,
  onClose: FUNC_IS_REQUIRED_TYPE,
};
