import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  FUNC_IS_REQUIRED_TYPE,
  OBJECT_OF_ANY_TYPE,
} from '../../../constants/propTypes';
import {
  MATERIAL_UI_SIZE,
  MATERIAL_UI_STYLE_COLOR,
} from '../../../constants/materialUI';

import isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _omitBy from 'lodash/omitBy';
import _omit from 'lodash/omit';
import _isFunction from 'lodash/isFunction';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Chip from '@mui/material/Chip';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DefaultAutocompleteContainer } from '../Autocomplete/AutocompleteContainer';
import './style.css';
import { FILTER_COMPONENTS_MAP } from './FilterComponents';
import { FILTER_DATA_TYPE } from './filters.types';


export class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      currentFilters: {},
    };
  }

  componentDidUpdate(props) {
    if(this.state.modalOpened && !isEqual(props.filters, this.props.filters)) {
      this.setState({ currentFilters: { ...this.props.filters } });
    }
  }

  _renderAddFilterButton = () =>
    <Button onClick={this._openModal} color={MATERIAL_UI_STYLE_COLOR.INHERIT}>
      {this.props.addFiltersBtnTitle}
    </Button>;

  _openModal = () => {
    this.setState({
      modalOpened: true,
      currentFilters: { ...this.props.filters },
    });
  };

  _renderRemoveAllFiltersButton = () => {

    const {
      filters,
      removeAllFiltersBtnTitle,
    } = this.props;

    // если фильтр только 1, то не рисуем
    if (Object.keys(filters).length <= 1) return null;

    return (
      <Button onClick={this._removeAllFilters}>
        {removeAllFiltersBtnTitle}
      </Button>
    );
  };

  _removeAllFilters = () => {
    const { onApplyFilters } = this.props;
    onApplyFilters({});
  };

  _renderSelectedFiltersChips = () => {
    const {
      classes: {
        filterChip,
      },
      filters,
      filterSchema,
      onApplyFilters,
    } = this.props;

    return Object
      .keys(filters)
      .map(filterKey => {
        const {
          renderFilterChip,
          getFilterChipContent = this._defaultFilterChipContent,
          getFilterTitle,
        } = filterSchema.find(({ filterKey: filterKeyFromSchema }) => filterKeyFromSchema === filterKey);

        const filterData = filters[filterKey];

        if(_isFunction(renderFilterChip))
          return renderFilterChip({
            filterKey,
            filters,
            onApplyFilters,
            getFilterTitle,
            renderDefaultChip: this._renderDefaultChip,
          });

        return this._renderDefaultChip({
          filterKey,
          label: getFilterChipContent(filterData, getFilterTitle(filterData)),
          className: filterChip,
          onDelete: this._removeFilterCbFactory(filters, filterKey),
        });
      });
  };

  _defaultFilterChipContent = ({ filterValue }, title) => (
    <span>
      {title}
      <b>{`: ${filterValue}`}</b>
    </span>
  );

  _renderDefaultChip = ({ filterKey, label, className, onDelete }) => (
    <Chip
        key={filterKey}
        label={label}
        className={cn('filters__chip', className)}
        onDelete={onDelete}
    />
  );

  _removeFilterCbFactory = (filters, filterKey) => (() => {
    const { onApplyFilters } = this.props;
    onApplyFilters(_omit(filters, filterKey));
  });

  _renderFiltersSelectingModal = () =>
    <Dialog
        classes={{
          root: 'filters__filters-selecting-modal',
          paper: 'filters__filters-selecting-modal-paper',
        }}
        open={this.state.modalOpened}
        onClose={this._closeModal}
        maxWidth="sm"
        fullWidth
    >
      <form
          onSubmit={this._handleSubmitFilters}
          className={'filters__filters-selecting-form'}
      >
        {this._renderFiltersDialogTitle()}
        {this._renderCreateFilterControl()}
        {this._renderFiltersComponents()}
        {this._renderFiltersApplyButtons()}
      </form>
    </Dialog>;

  _closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  };

  _handleSubmitFilters = e => {
    e.preventDefault();

    this._applyFilters();
    this._closeModal();
  };

  _applyFilters = () => {
    const {
      onApplyFilters,
    } = this.props;
    const { currentFilters } = this.state;

    const filtersWithoutNulls = _omitBy(
      currentFilters,
      filter => filter === null,
    );

    return onApplyFilters(filtersWithoutNulls);
  };

  _renderFiltersDialogTitle = () =>
    <DialogTitle className="filters__title">
      <div>
        {this.props.filtersModalTitle}
      </div>
    </DialogTitle>;

  _renderCreateFilterControl = () => {
    if(!this.props.withFiltersEditing) return null;

    const {
      currentFilters,
    } = this.state;

    const {
      filterSchema,
      createFilterControlNoOptionsTitle,
      allAvailableFiltersAddedPlaceholderTitle,
      chooseFilterToAddPlaceholderTitle,
    } = this.props;

    const filtersToAddOptions = filterSchema
      .filter(({ filterKey }) => !(filterKey in currentFilters))
      .map(({ getFilterTitle, filterKey }) => ({
        id: filterKey,
        name: getFilterTitle(),
      }));

    const areAllAvailableFiltersAdded = !filtersToAddOptions.length;

    /*
    * isClearable=false добавляется для автокомплита, т.к. в значении null и оно нам не важнО, а с isClearable=true
    * обработчик onChange срабатывает и при использование backspace и delete с клавиатуры, что не требуется и,
    * более того, такиу случае не обрабатываются и получаем эксепшены в this._onCreateFilterControlChange
    * isSearchable=false добавляется для автокомплита, т.к. до конца не решен вопрос с тем, когда в его опции в
    * качестве лейблов попадает разметка (например, от компонентов, реализующих переводы), а в случае с наименованиями
    * колонок для фильтрации, это может происходить доволно часто
    * */
    return (
      <DialogContent className="filters__create-filter-control">
        <DefaultAutocompleteContainer
            value={null}
            options={filtersToAddOptions}
            onChange={this._onCreateFilterControlChange}
            isDisabled={areAllAvailableFiltersAdded}
            reactSelectProps={{
              autoFocus: true,
            }}
            placeholder={
              areAllAvailableFiltersAdded ?
                allAvailableFiltersAddedPlaceholderTitle :
                chooseFilterToAddPlaceholderTitle
            }
            noOptionsMessage={createFilterControlNoOptionsTitle}
            isClearable={false}
            isSearchable={false}
        />
      </DialogContent>
    );
  };

  _onCreateFilterControlChange = ({ id }) => this._setCurrentFilters({ [id]: null });

  _setCurrentFilters = currentFilters =>
    this.setState({
      currentFilters: {
        ...this.state.currentFilters,
        ...currentFilters,
      },
    });

  _renderFiltersComponents = () => {
    const { currentFilters } = this.state;

    const {
      filterSchema,
      withFiltersEditing,
    } = this.props;

    return (
      <DialogContent className="filters__filters-components-wrapper">
        {
          filterSchema
            .filter(({ filterKey }) => !withFiltersEditing || filterKey in currentFilters)
            .map(
              ({
                 filterKey,
                 filterComponent: FilterComponent = FILTER_COMPONENTS_MAP.TEXT_INPUT,
                 filterComponentProps,
                 getFilterTitle,
               }) => {

                const filterData = _get(currentFilters, [filterKey], null);

                return (
                  <div
                      className="filters__filter-component"
                      key={filterKey}
                  >
                    <div className="filters__filter-component-row">
                      <div>
                        {getFilterTitle(filterData)}
                      </div>
                      <FilterComponent
                          {...filterComponentProps}
                          filterKey={filterKey}
                          filterData={filterData}
                          onChange={this._setCurrentFilters}
                      />
                    </div>
                    {
                      withFiltersEditing &&
                      <IconButton
                          className="filters__filter-component-delete-button"
                          onClick={() => this._handleFilterComponentDelete(filterKey)}
                          size="large"
                      >
                        <CloseIcon/>
                      </IconButton>
                    }
                  </div>
                );
              },
            )
        }
      </DialogContent>
    );
  };

  _handleFilterComponentDelete = filterKey => this.setState({
    currentFilters: _omit(this.state.currentFilters, filterKey),
  });

  _renderFiltersApplyButtons = () => {
    const {
      filters,
    } = this.props;
    return (
      <DialogActions className="filters__filters-btn-block">
        <Button
            onClick={this._closeModal}
            color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
            size={MATERIAL_UI_SIZE.MEDIUM}
        >
          {this.props.cancelFiltersBtnTitle}
        </Button>
        <Button
            type="submit"
            size={MATERIAL_UI_SIZE.MEDIUM}
            color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
            disabled={
              isEqual(
                _omitBy(this.state.currentFilters, filter => filter === null),
                filters,
              )
            }
        >
          {this.props.applyFiltersBtnTitle}
        </Button>
      </DialogActions>
    );
  };

  render() {
    const {
      classes: {
        root,
      },
    } = this.props;

    return (
      <div className={cn('filters', root)}>
        {this._renderAddFilterButton()}
        {this._renderRemoveAllFiltersButton()}
        {this._renderSelectedFiltersChips()}
        {this._renderFiltersSelectingModal()}
      </div>
    );
  }
}

Filters.defaultProps = {
  filters: {},
  classes: {},
  withFiltersEditing: false,

  //дефолтные значения оставил такие же, как и в предыдущей реализации компонента Filters
  filtersModalTitle: 'Choose filters',
  applyFiltersBtnTitle: 'Apply filters',
  cancelFiltersBtnTitle: 'Cancel',
  addFiltersBtnTitle: 'Add filters',
  removeAllFiltersBtnTitle: 'Remove all filters',
  createFilterControlNoOptionsTitle: 'No matches found',
  allAvailableFiltersAddedPlaceholderTitle: 'All available filters added',
  chooseFilterToAddPlaceholderTitle:'Choose filter',
};

Filters.propTypes = {
  filters: PropTypes.objectOf(FILTER_DATA_TYPE),
  filterSchema: PropTypes.arrayOf(
    PropTypes.shape({
      filterKey: PropTypes.string,
      filterComponent: PropTypes.elementType,
      filterComponentProps: OBJECT_OF_ANY_TYPE,
      getFilterTitle: FUNC_IS_REQUIRED_TYPE,
      getFilterChipContent: PropTypes.func,
      renderFilterChip: PropTypes.func,
    }),
  ),
  withFiltersEditing: PropTypes.bool,
  onApplyFilters: FUNC_IS_REQUIRED_TYPE,
  filtersModalTitle: PropTypes.node,
  applyFiltersBtnTitle: PropTypes.node,
  cancelFiltersBtnTitle: PropTypes.node,
  addFiltersBtnTitle: PropTypes.node,
  removeAllFiltersBtnTitle: PropTypes.node,
  createFilterControlNoOptionsTitle: PropTypes.node,
  allAvailableFiltersAddedPlaceholderTitle: PropTypes.node,
  chooseFilterToAddPlaceholderTitle: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
    filterChip: PropTypes.string,
  }),
};
