import moment from 'moment';
import { FORMAT_SHORT_TIME } from '../../constants/dateFormats';
import {
  NoDataLabelTrans,
  OrderNameLabelTrans,
  OrderPlannedStartDateLabelTrans,
  OrderPlannedStopDateLabelTrans,
} from '../../utils/commonTransComponents';


export const ORDER_PRIMARY_CONTENT_SCHEMA = [
  {
    key: 'name',
    rowIdentity: 'order-name',
  },
];

export const ORDER_PRIMARY_CONTENT_SCHEMA_WITH_ORDER__NAME_LABEL = [
  {
    key: 'name',
    rowIdentity: 'order-name',
    label: OrderNameLabelTrans,
  },
];

export const ORDER_SECONDARY_CONTENT_SCHEMA = [
  {
    key: 'startDate',
    label: OrderPlannedStartDateLabelTrans,
    rowIdentity: 'order-start-date',
    // eslint-disable-next-line react/prop-types
    getCustomValue: ({ startDate }) => {
      if (!startDate) return NoDataLabelTrans;

      return moment(startDate).format(FORMAT_SHORT_TIME);
    },
  },
  {
    key: 'stopDate',
    label: OrderPlannedStopDateLabelTrans,
    rowIdentity: 'order-stop-date',
    // eslint-disable-next-line react/prop-types
    getCustomValue: ({ stopDate }) => {
      if (!stopDate) return NoDataLabelTrans;

      return moment(stopDate).format(FORMAT_SHORT_TIME);
    },
  },
];
