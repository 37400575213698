import { RELATED_MODEL_FIELD_DELIMITER } from '../../constants/magics';

import _pick from 'lodash/pick';
import _flowRight from 'lodash/flowRight';
import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';

import humps from 'humps';
import { isQueryParamWithParamsBlock } from '../../utils/url';

export const REST_REQUEST_TYPE = 'rest';
export const REST_COLLECTION_API_REQUEST_TYPE = [REST_REQUEST_TYPE, 'collection'].join('/');

const FILTER_BLOCK_BEGIN_RESERVED_SYMBOL = '{';
const FILTER_BLOCK_END_RESERVED_SYMBOL = '}';

//Символ экранирования служебных символовов в значении фильтров \, его невозможно определить в коде
//незаэкранировав его, т.е. --> \\
const FILTER_VALUE_ESCAPING_RESERVED_SYMBOL = '\\';

//Символ экранирования используется в регэкспе, где экранируется этим же символом, т.е. учитыая
//комментарий к FILTER_VALUE_ESCAPING_RESERVED_SYMBOL, уже получается \\ --> \\\\
const FILTER_VALUE_ESCAPING_RESERVED_SYMBOL_FOR_REGEXP = '\\\\';

//Регэксп для поиска всех зарезервированных символов под формат API коллекций в значении фильтра, для корректной
//фильтрации их нужно заэкранировать. Это символы начала и конца группы фильтров и сам символ экранирования
const RESERVED_SYMBOLS_TO_ESCAPE_REG_EXP = new RegExp(
  [
    '[',
    FILTER_BLOCK_BEGIN_RESERVED_SYMBOL,
    FILTER_BLOCK_END_RESERVED_SYMBOL,
    FILTER_VALUE_ESCAPING_RESERVED_SYMBOL_FOR_REGEXP,
    ']',
  ].join(''),
  'g',
);

const REST_COLLECTION_API_QUERY_KEYS = [
  'orderBy',
  'with',
  'start',
  'stop',
  'filter',
];

export const transformQueryParamsToCollectionApi = queryParams => {
  const transformedQueryParams = _pick(transformQueryParams(queryParams), REST_COLLECTION_API_QUERY_KEYS);
  return humps.decamelizeKeys(transformedQueryParams);
};

const transformSortParams = queryParams => {
  const { sortBy } = queryParams;
  if(!sortBy) return queryParams;
  return {
    ...queryParams,
    orderBy: sortBy
      .map(({ column, params }) => ({
        column: humps.decamelize(column),
        params,
      })),
  };
};

const transformWithParams = queryParams => {
  if(!queryParams.with) return queryParams;
  return{
    ...queryParams,
    with: queryParams.with
      .map(withParamValue => {
        if(!isQueryParamWithParamsBlock(withParamValue)) return humps.decamelize(withParamValue);
        const { column, params } = withParamValue;
        return{
          column: humps.decamelize(column),
          params: params
            .map(({ key, value }) => ({
              key: humps.decamelize(key),
              value: value,
            })),
        };
      }),
  };
};

export const transformLimitParams = queryParams => {
  const { limit, page = 1 } = queryParams;
  if(!limit) return queryParams;
  return{
    ...queryParams,
    start: limit * (page - 1),
    stop: limit * page,
  };
};

export const FILTER_TYPES = {
  EQUALS: 'eq',
  NOT_EQUALS: 'ne',

  //Фильтр contains всегда c игнорированием регистра
  CONTAINS: 'ict',
  GREATER_THAN: 'gt',
  LESS_THAN: 'lt',
  GREATER_OR_EQUAL: 'ge',
  LESS_OR_EQUAL: 'le',
  ONE_OF: 'in',
  IS: 'is',
  IS_NOT: 'ns',
};

export const FILTER_GROUP_TYPES = {
  AND: 'AND',
  OR: 'OR',
};

export const WITH_PARAMS = {
  STRICT: 'withStrict',
};

const transformFilterParams = queryParams => {
  const { filter } = queryParams;
  if(!filter) return queryParams;
  return {
    /*
    * В результате преобразований каждая группа в результирующей строке заключаются внутрь служебных скобок.
    * Для самого верхнего уровня это, по идее не нужно, т.е. получается, например:
    * для одного фильтра --> { { column eq 2 } }
    * для комбинированных фильтров --> { { column1 eq 1 } and { column2 eq 2 } }.
    * Можно было так и оставить, но было решено обрезать для верхнего уровня эти служебные скобки и пробелы, т.е. первые 2
    * символа и последние два символа, чтобы получалось вот так:
    * для одного фильтра --> { column eq 2 }
    * для комбинированных фильтров --> { column1 eq 1 } and { column2 eq 2 }
    * */
    ...queryParams,
    filter: transformFilterGroup(filter)
      .slice(2, -2),
  };
};

const transformFilterGroup = filterGroup => {
  const {
    filterGroupType,
    filters,
  } = filterGroup;
  const groupFilterString = filters
    .map(
      filterGroupElement =>
      filterGroupElement.column ?
        transformColumnFilter(filterGroupElement) :
        transformFilterGroup(filterGroupElement),
    )
    /*
    * Разделяем группы фильтров и комбинирующий группы символ дополнительным пробелом для читаемости строки фильтра, а
    * также для однотипности с точки зрения пробелов, т.к. строки внутри группы фильтров обязательно должны разделяться
    * пробелом, согласно формату API коллекций
    * Пример
    * { column1 eq 2 } and { column2 eq 3 }, а не { column1 eq 2 }and{ column2 eq 3 }
    * */
    .join(` ${FILTER_GROUP_TYPES[filterGroupType].toLowerCase()} `);
  return wrapFilterBlock(groupFilterString);
};

const transformColumnFilter = ({ column, filterValue, filterType }) => {
  const columnFilterString = [
    /*
    * Приходится декамелайзить здесь, т.к. значения тоже могут содержать camelCase, а их преобразовывать, очевидно,
    * не нужно. Все фильтры здесь преобразуются в одну строку и понять далее где имя колонки, а где значение уже будет,
    * практически, невозможно.
    *
    * */
    humps.decamelize(column),
    transformFilterValue(filterValue),
  ]
    .join(` ${filterType} `);
  return wrapFilterBlock(columnFilterString);
};

/*
* Для API коллекций строго определено, что в формате выражения каждого фильтра все строковые значения должны
* обязательно разделяться одним пробелом, поэтому join(' ').
* Пример правильного варианта:
* { column eq 2 }
* Пример неправильного варианта
* {column eq 2 }
* { column eq 2}
* {column eq 2}
* */
const wrapFilterBlock = filterString => [
  FILTER_BLOCK_BEGIN_RESERVED_SYMBOL,
  filterString,
  FILTER_BLOCK_END_RESERVED_SYMBOL,
].join(' ');

const transformFilterValue = filterValue => {
  if(filterValue === null) return JSON.stringify(filterValue);

  /*
  * Сначала стрингифаим значение фильтра в виде массива, а только потом экранируем, т.к.
  * JSON.stringify дополнительно добавляет своё экранирование, а на сервере при разборе
  * фильтра в виде массива именно обратная последовательность (условно, сначала разэкранирование,
  * а после него JSON.parse). Поэтому, только при такой последовательности сервер получит
  * корректный результат при разборе фильтра
  * */
  if(_isArray(filterValue))
    return escapeReservedSymbolsInFilterValue(JSON.stringify(filterValue));

  if(typeof filterValue === 'string')
    return escapeReservedSymbolsInFilterValue(filterValue);

  return filterValue;
};

export const escapeReservedSymbolsInFilterValue = value =>
  value.replace(
    RESERVED_SYMBOLS_TO_ESCAPE_REG_EXP,
    //вставляем символ экранирования перед служебными символами в значении фильтра
    `${FILTER_VALUE_ESCAPING_RESERVED_SYMBOL}$&`,
  );


const transformQueryParams = _flowRight(
  transformSortParams,
  transformWithParams,
  transformLimitParams,
  transformFilterParams,
);

export const transformModelsInQueryParams = (queryParams, modelsRelations) => {
  if(!modelsRelations) return queryParams;
  return {
    ...queryParams,
    ...transformModelsInSortParams(queryParams, modelsRelations),
    ...transformModelsInWithParams(queryParams, modelsRelations),
    ...transformModelsInFilterParams(queryParams, modelsRelations),
  };
};

const transformModelsInSortParams = ({ sortBy }, modelsRelations) => {
  if(!sortBy) return {};
  return {
    sortBy: sortBy
      .map(columnSortData => ({
        ...columnSortData,
        column: transformColumnName(columnSortData.column, modelsRelations),
      })),
  };
};

const transformModelsInWithParams = (queryParams, modelsRelations) => {
  if(!queryParams.with) return {};
  /*
  * Модели with могут быть заданы как строкой так и блочным параметром в виде объекта, обрабатываем оба возможных случая
  * */
  return {
    with: queryParams.with
      .map(
        relatedModelData =>
          _isString(relatedModelData) ?
          generateApiModelWithRelations(relatedModelData, modelsRelations) :
          ({
            ...relatedModelData,
            column: generateApiModelWithRelations(relatedModelData.column, modelsRelations),
          }),
      ),
  };
};

const transformModelsInFilterParams = ({ filter }, modelsRelations) => {
  if(!filter) return {};
  return{
    filter: transformModelsInFilterGroup(filter, modelsRelations),
  };
};

const transformModelsInFilterGroup = (filterGroup, modelsRelations) => {
  const {
    filterGroupType,
    filters,
  } = filterGroup;
  return {
    filterGroupType,
    filters: filters
      .map(filterGroupElement =>
        filterGroupElement.column ?
          ({
            ...filterGroupElement,
            column: transformColumnName(filterGroupElement.column, modelsRelations),
          }) :
          transformModelsInFilterGroup(filterGroupElement, modelsRelations)),
  };
};

export const transformColumnName = (columnName, modelsRelations) => {
  const isRelatedModelColumn = columnName.includes(RELATED_MODEL_FIELD_DELIMITER);
  if(!isRelatedModelColumn) return columnName;

  const [relatedModel, field] = columnName.split(RELATED_MODEL_FIELD_DELIMITER);
  return [generateApiModelWithRelations(relatedModel, modelsRelations), field].join('.');
};

const generateApiModelWithRelations = (model, modelRelations) => {
  const relations = collectRelations(model, modelRelations);
  /*
  * В методе collectRelations, все отношения модели любого уровня вложенности собираются "с конца" до модели первого
  * уровня, по которой осуществляется запрос (уровни описываются в modelRelations). Например,
  * модель entityRoute (4 уровень вложенности), у неё есть с связь с operation (3 уровень вложенности), у operation есть
  * связь с task (2 уровень вложенности), у таска есть связь с equipment (1 уровень вложенности). CollectRelations
  * пробежавшись по modelRelations, соберет массив [operation', 'task', 'equipment'].
  * Чтобы корректно сформировать модель такой связанной модели, нужно развернуть массив, добавить саму модель и соединить
  * все имена модели через точку, т.е. чтобы получилось equipment.task.operation.entityRoute
  * */
  return relations.reverse().concat(model).join('.');
};

const collectRelations = (model, modelRelations, relations = []) => {
  const relatedModel = modelRelations[model].relates;
  if(!relatedModel) return relations;
  return collectRelations(relatedModel, modelRelations, relations.concat(relatedModel));
};