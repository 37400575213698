export const SERVER_DATA_POINT = {
  SHEETS_TO_START: 'productionPlan',
  ASSEMBLY_SHEETS_TO_START: 'productionPlan/assembly',
  DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS: 'providableEntityBatches',
  POSSIBLE_PARTS_AND_MATERIALS_TO_CONSUME_FOR_DEFAULT_SHEET: 'entityBatchPickingDetails',
  SHEET_OPERATIONS_AGGREGATED_DATA: 'entityRouteSheetOperation',
  SHEET_TASKS_DATA: 'entityRouteSheetOperation/list',
  CURRENT_SHEET_OPERATION_DATA: 'entityRouteSheetCurrentOperation',
  ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS: 'entityBatch/list',
  ENTITY_BATCH_RESERVE_STATE: 'entityBatch/reserveState',
  SHEET_OPERATION_FEATURES_VALUE: 'entityRouteSheetOperationFeatureValue',
};

export const getCheckOrderWarehouseNeedlessEntityBatchesDataPoint = orderId => `order/${orderId}/checkWarehouseRemains`;