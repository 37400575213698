export const SAVE_DEPARTMENTS_WITH_TASKS = 'SAVE_DEPARTMENTS_WITH_TASKS';
export const CLEAR_ALL_DEPARTMENTS_WITH_TASKS = 'CLEAR_ALL_DEPARTMENTS_WITH_TASKS';

export const saveDepartmentsWithTasks = departmentsEntities => ({
  type: SAVE_DEPARTMENTS_WITH_TASKS,
  departmentsEntities,
});

export const clearAllDepartmentsWithTasks = () => ({
  type: CLEAR_ALL_DEPARTMENTS_WITH_TASKS,
});


export const SAVE_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS = 'SAVE_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS';
export const CLEAR_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS = 'CLEAR_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS';
export const CLEAR_ALL_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS = 'CLEAR_ALL_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS';

export const saveEquipmentClassesInDepartmentWithTasks = (departmentId, equipmentClassesInDepartmentEntities) => ({
  type: SAVE_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS,
  departmentId,
  equipmentClassesInDepartmentEntities,
});

export const clearEquipmentClassesInDepartmentWithTasks = departmentIds => ({
  type: CLEAR_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS,
  departmentIds,
});

export const clearAllEquipmentClassesInDepartmentWithTask = () => ({
  type: CLEAR_ALL_EQUIPMENT_CLASSES_IN_DEPARTMENT_WITH_TASKS,
});

export const CLEAR_ALL_TASKS_OWNERS_DATA = 'CLEAR_ALL_TASKS_OWNERS_DATA';

export const clearAllTasksOwnersData = () => ({
  type: CLEAR_ALL_TASKS_OWNERS_DATA,
});
