import { Trans } from '@lingui/macro';
import React from 'react';

export const SHEET_OPERATION_FEATURES_DEPARTMENT_CLASS_AUTOCOMPLETE_ID = 'SHEET_OPERATION_FEATURES_DEPARTMENT_CLASS_AUTOCOMPLETE_ID';
export const SHEET_OPERATION_FEATURES_EQUIPMENT_CLASS_AUTOCOMPLETE_ID = 'SHEET_OPERATION_FEATURES_EQUIPMENT_CLASS_AUTOCOMPLETE_ID';
export const SHEET_OPERATION_FEATURES_OPERATION_AUTOCOMPLETE_ID = 'SHEET_OPERATION_FEATURES_OPERATION_AUTOCOMPLETE_ID';


export const SheetOperationFeatureScopeFormEquipmentClassTooltipTrans = (
  <Trans id="sheet_operation_settings.sheet_operation_feature_scope_form@equipment_class_tooltip">
    Для выбора класса РЦ необходимо сначала выбрать подразделение
  </Trans>
);

export const SheetOperationFeatureScopeFormOperationTooltipTrans = (
  <Trans id="sheet_operation_settings.sheet_operation_feature_scope_form@operation_tooltip">
    Для выбора операции необходимо сначала выбрать подразделение и класс РЦ
  </Trans>
);
