import {
  CLEAR_SHEET_OPERATION_FEATURES_VALUES,
  DELETE_SHEET_OPERATION_FEATURES,
  SET_SHEET_OPERATION_FEATURES_VALUES, SET_SHEET_OPERATION_PROGRESS_MODE,
  SHEET_OPERATION_REVIEW_SET_IS_CONSUME_ENTITIES_DIALOG_OPEN,
} from './actions';
import _omit from 'lodash/omit';
import {
  SHEET_OPERATION_PROGRESS_MODE,
} from '../../components/common/SheetOperationReviewDialog/SheetOperationProgressInput/constants';


export const SHEET_OPERATION_REVIEW_INITIAL_STATE = {
  isConsumeEntitiesDialogOpen: false,
  sheetOperationFeaturesValues: {},
  progressMode: SHEET_OPERATION_PROGRESS_MODE.PERCENT,
};

/*
* До определенного момента все параметры интерфейса просмотра операции МЛ были в локальном стейте. Но для
* обработки броадкастинга событий важно понимать из общих обработчиков, где именно мы находимся, иначе мы не знаем
* нужно ли очищать кэш или делать перезапрос. Даже в случае, когда мы добавляем локальный обработчик событий, как это
* делается в SheetOperationReviewDialog, этого недостаточно, потому что перезапрос для обновления данных нужно делать
* именно когда компонент на экране, а очистку кэша во всех остальных случаях в общем обработчике, при этом мы не
* можем это детектировать и вынуждены очищать кэш в любом случае, что приводит к дёрганию в интерфейсе и многда
* даже к очистке локальных стейтов, что очень нежелательно.
*
* Описанных вопрос решил бы более правильный роутинг, чтобы через ссылки определять открыт ли модальник просмотра
* операции или открыты его внутренние составляющие, но в данный момент такой роутинг не проработан и это достаточно
* трудоёмко, учитывая, что SheetOperationReviewDialog добавляется отдельно в каждый из типов приложения. Уже давно
* была идея выделить окно просмотра операции на отдельный общий роут и переходит на него из любых интерфейсов, где
* есть просмотр операции, но это тоже достаточно трудоёмкая задача, которая требует ещё и отдельного проектирования.
*
* Поэтому, пока для решения текущих задач броадкастинга было принято решения частично вынести параметры интерфейса
* просмотра операции МЛ в store из локального стейта, чтобы иметь к ним доступ в общих обработчиках броадкастинга.
* Параметры не выносятся все, т.к., как описывалось выше, более правильно будет вынести просмотр операции на отдельный
* роут и тогда store не понадобится. Поэтому сейчас, фактически, выпонятся только необходимые для броадкастинга
* параметры (пока это только состояние открытости модального окна потребления)
*
* */
export const sheetOperationReviewReducer = (state = { ...SHEET_OPERATION_REVIEW_INITIAL_STATE }, action)  => {
  switch (action.type) {
  case SHEET_OPERATION_REVIEW_SET_IS_CONSUME_ENTITIES_DIALOG_OPEN:
    return {
      ...state,
      isConsumeEntitiesDialogOpen: action.isOpen,
    };

  case SET_SHEET_OPERATION_FEATURES_VALUES:

    const { data } = action;

    return {
      ...state,
      sheetOperationFeaturesValues: { ...data },
    };

  case CLEAR_SHEET_OPERATION_FEATURES_VALUES:
    return {
      ...state,
      sheetOperationFeaturesValues: {},
    };

  case DELETE_SHEET_OPERATION_FEATURES:
    const { sheetOperationFeaturesIds } = action;

    return {
      ...state,
      sheetOperationFeaturesValues: _omit(state.sheetOperationFeaturesValues, sheetOperationFeaturesIds),
    };

  case SET_SHEET_OPERATION_PROGRESS_MODE:
    return {
      ...state,
      progressMode: action.mode,
    };

  default: {
    return state;
  }
  }
};
