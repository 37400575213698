import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';
import { MATERIAL_UI_VARIANT } from '../../../constants/materialUI';
import { Search } from '@mui/icons-material';

import cn from 'classnames';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';

import './style.css';
import { DebouncedTextField } from '../DebouncedTextField/DebouncedTextField';


export const SearchField = ({
  className,
  value,
  onChange,
  ...debouncedTextFieldRestProps
}) => (
  <DebouncedTextField
      {...debouncedTextFieldRestProps}
      className={
        cn(
          'common-search-field',
          className,
        )
      }
      value={value}
      onChange={onChange}
      variant={MATERIAL_UI_VARIANT.OUTLINED}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Search className="common-search-field__search-icon" />
          </InputAdornment>,
      }}
  />
);

SearchField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: FUNC_IS_REQUIRED_TYPE,
  onChangeDebounceTimeoutInMs: PropTypes.number,
};