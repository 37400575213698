import React from 'react';
import PropTypes from 'prop-types';

import { nilValueLabelTableCellRendererFactory } from '../nilValueLabelTableCellRendererFactory';
import { TableCellRenderer } from '../TableCellRenderer';
import { humanizeNumber } from '@bfg-frontend/utils/lib/humanizeNumber';


const NUMBERS_RENDERER_FRACTION_DIGITS = 2;


class NumbersRendererComponent extends TableCellRenderer {
  static PropTypes = {
    fractionDigits: PropTypes.number,
  };

  static defaultProps = {
    fractionDigits: NUMBERS_RENDERER_FRACTION_DIGITS,
  }

  static getViewValue(props) {
    const {
      value,
      fractionDigits,
    } = props;

    return humanizeNumber(value, { truncate: fractionDigits });
  }

  render() {
    const valueToDisplay = NumbersRendererComponent.getViewValue(this.props);

    return (
      <div>
        {valueToDisplay}
      </div>
    );
  };
}


export const NumbersRenderer = nilValueLabelTableCellRendererFactory(NumbersRendererComponent);
