import { MATERIAL_UI_STYLE_COLOR } from '../constants/materialUI';
import {
  ENTITY_BATCH_DEFECT_STATE,
  ENTITY_BATCH_PROVIDABLE_STATE,
  ENTITY_BATCH_PROVIDING_STATE,
  ENTITY_BATCH_STATUS,
  SHEET_OPERATION_STATUS,
} from '../constants/sheets';
import {
  DefectLabelTrans,
  HasPartsAndMaterialsLabelTrans,
  InProductionLabelTrans,
  PartsAndMaterialsAreAvailableForSheetLabelTrans,
  PartsPartsAndMaterialsAreNotAvailableForSheetLabelTrans,
  SheetDoesNotHavePartsAndMaterialsLabelTrans,
  SheetHasPartiallyPartsAndMaterialsLabelTrans,
  SinglePausedLabelTrans,
} from './commonTransComponents';

const ENTITY_BATCH_PROVIDING_STATE_LABELS_MAP = {
  [ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED]: {
    labelContent: SheetDoesNotHavePartsAndMaterialsLabelTrans,
    labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
    labelKey: 'unprovided',
  },
  [ENTITY_BATCH_PROVIDING_STATE.PARTIALLY_PROVIDED]: {
    labelContent: SheetHasPartiallyPartsAndMaterialsLabelTrans,
    labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
    labelKey: 'partiallyProvided',
  },
  [ENTITY_BATCH_PROVIDING_STATE.PROVIDED]: {
    labelContent: HasPartsAndMaterialsLabelTrans,
    labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
    labelKey: 'provided',
  },
};

export const getSheetPartsAndMaterialsInfoLabels = ({ providingState }) => ([
  ENTITY_BATCH_PROVIDING_STATE_LABELS_MAP[providingState],
]);


const PROVIDABLE_INFO_LABEL_PROPS = {
  labelContent: PartsAndMaterialsAreAvailableForSheetLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
  labelKey: 'providable',
};
const UNPROVIDABLE_INFO_LABEL_PROPS = {
  labelContent: PartsPartsAndMaterialsAreNotAvailableForSheetLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
  labelKey: 'unprovidable',
};

const IN_PRODUCTION_INFO_LABEL_PROPS = {
  labelContent: InProductionLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
  labelKey: 'inProduction',
};

const PAUSED_INFO_LABEL_PROPS = {
  labelContent: SinglePausedLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
  labelKey: 'paused',
};

export const getAssemblySheetsWaitingPartsAndMaterialsInfoLabels = ({ providableState, entityBatchStatus, providingState }) => [
  providingState === ENTITY_BATCH_PROVIDING_STATE.PARTIALLY_PROVIDED ?
    IN_PRODUCTION_INFO_LABEL_PROPS :
    null,
  entityBatchStatus === ENTITY_BATCH_STATUS.PAUSED ?
    PAUSED_INFO_LABEL_PROPS :
    null,
  providableState === ENTITY_BATCH_PROVIDABLE_STATE.PROVIDABLE ?
    PROVIDABLE_INFO_LABEL_PROPS :
    UNPROVIDABLE_INFO_LABEL_PROPS,
];


/*
* Вспомогательная функция для определения сценария в рамках которого происходит фиксация брака по партии МЛ.
* Нас интересует следующие сценарии, для того, чтобы корректно различать обработку после фиксации брака:
*   1. В брак переводится вся партия при завершении операции.
*   2. В брак переводится вся партия при приостановке операции.
*   3. В брак переводится часть партии при завершении операции и это была не последняя операция.
*   4. В брак переводится часть партии при завершении операции и это была последняя операция.
*   5. В брак переводится часть партии при приостановке операции.
*/

export const DEFECT_MARKING_USE_CASES = {
  WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH: 'WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH',
  WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE: 'WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE',
  PART_OF_ENTITY_BATCH_ON_OPERATION_FINISH: 'PART_OF_ENTITY_BATCH_ON_OPERATION_FINISH',
  PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH: 'PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH',
  PART_OF_ENTITY_BATCH_ON_OPERATION_PAUSE: 'PART_OF_ENTITY_BATCH_ON_OPERATION_PAUSE',
};
export const getDefectMarkingUseCase = (sheetOperationStatus, wasEntityBatchSplit, wasEntityBatchFinished) => {

  if(!wasEntityBatchSplit) {
    // 1. В брак переводится вся партия при завершении операции
    if(sheetOperationStatus === SHEET_OPERATION_STATUS.FINISHED) {
      return DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH;
    }

    // 2. В брак переводится вся партия при приостановке операции
    if(sheetOperationStatus === SHEET_OPERATION_STATUS.PAUSED) {
      return DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE;
    }
  }

  // 5. В брак переводится часть партии при приостановке операции
  if(sheetOperationStatus === SHEET_OPERATION_STATUS.PAUSED) {
    return DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_OPERATION_PAUSE;
  }

  // 4. В брак переводится часть партии при завершении операции и это была последняя операция
  if(wasEntityBatchFinished) {
    return DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH;
  }

  // 3. В брак переводится часть партии при завершении операции и это была не последняя операция
  return DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_OPERATION_FINISH;
};

export const getSheetDefectInfoLabels = ({ defectState }) => ([
  defectState === ENTITY_BATCH_DEFECT_STATE.DEFECT ?
    ({
      labelContent: DefectLabelTrans,
      labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
      labelKey: 'defect',
    }) :
    null,
]);