import React from 'react';
import { Trans } from '@lingui/macro';
import { clearNotifications, NOTIFICATION_LEVEL, sendNotification } from '../../../constants/notification';

import moment from 'moment';
import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';
import { SHEET_OPERATION_STATUS, SHEET_OPERATION_STATUS_TITLES_TRANS_MSG_MAP } from '../../../constants/sheets';
import Chip from '@mui/material/Chip';
import { MATERIAL_UI_THEME_COLOR_CODES } from '../../../constants/materialUI';
import { PERMISSION } from '../../../hoc/withPermissionsManager/constants';
import _isFunction from 'lodash/isFunction';
import {
  CurrentOperationLaborLabelTrans,
  DepartmentLabelTrans,
  EntitiesInBatchAmountLabelTrans,
  EntityLabelTrans,
  EquipmentClassLabelTrans,
  NoDataLabelTrans,
  OperationLabelTrans,
  OperationNumberLabelTrans,
  OperationProdTimeLabelTrans,
  OrderLabelTrans,
  OutOfLabelTrans,
  SheetLabelTrans,
  SheetOperationPlannedStartDateLabelTrans,
  SheetOperationPlannedStopDateLabelTrans,
  SheetOperationProgressLabelTrans,
  SheetOperationStatusLabelTrans,
  WithoutOrderLabelTrans,
} from '../../../utils/commonTransComponents';
import { NotificationContentWithAction } from '../../NotificationContentWithAction/NotificationContentWithAction';
import { calculateLaborValue } from '../../../utils/tasks';
import {
  humanizeTimeFromHoursWithoutSeconds,
} from '../../../utils/humanizeTimeFromHours/humanizedTimeFromHoursFactory';
import _isNil from 'lodash/isNil';
import {
  getProgressInAmountFromPercent,
  prepareSheetOperationProgressValueToDisplay,
  SHEET_OPERATION_PROGRESS_MODE,
  SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP,
} from './SheetOperationProgressInput/constants';
import { copyToClipboard } from '@bfg-frontend/utils/lib/copyToClipboard';
import { getEntityCombinedName, getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const renderSheetOperationProgress = (entitiesInBatchAmount, progress) => {
  if (progress === null) return (
    <span>-</span>
  );

  return (
    <span>
      {prepareSheetOperationProgressValueToDisplay(progress)}
      {SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP[SHEET_OPERATION_PROGRESS_MODE.PERCENT]}
      {' '}
      {'('}
      {'~'}
      {' '}
      {prepareSheetOperationProgressValueToDisplay(getProgressInAmountFromPercent(entitiesInBatchAmount, progress))}
      {' '}
      {SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP[SHEET_OPERATION_PROGRESS_MODE.AMOUNT]}
      {')'}
    </span>
  );
};

export const OPERATION_DETAILS_FIRST_BLOCK_SUMMARY_SCHEMA = [
  {
    key: 'operationStartDate',
    label: SheetOperationPlannedStartDateLabelTrans,
    rowIdentity: 'operation-start-date',
    getCustomValue: ({ operationStartDate }) => getFormattedDateValue(operationStartDate),
  },
  {
    key: 'operationStopDate',
    label: SheetOperationPlannedStopDateLabelTrans,
    rowIdentity: 'operation-stop-date',
    getCustomValue: ({ operationStopDate }) => getFormattedDateValue(operationStopDate),
  },
  {
    key: 'combinedEntityInfo',
    rowIdentity: 'combined-entity-info',
    label: EntityLabelTrans,
    getCustomValue: ({ entityIdentity, entityCode, entityName }) => {
      if(!entityIdentity) return;

      return getEntityCombinedName({
        identity: entityIdentity,
        code: entityCode,
        name: entityName,
      });
    },
  },
  {
    key: 'operationNumber',
    label: OperationNumberLabelTrans,
    rowIdentity: 'operation-number',
  },
  {
    key: 'combinedOperationName',
    label: OperationLabelTrans,
    rowIdentity: 'combined-operation-name',
    getCustomValue: ({ operationIdentity, operationName }) => `${operationName} (${operationIdentity})`,
  },
  {
    key: 'operationProdTime',
    label: OperationProdTimeLabelTrans,
    rowIdentity: 'operation-prod-time',
    getCustomValue: ({ operationProdTime }) => {
      if(_isNil(operationProdTime)) return NoDataLabelTrans;
      
      return humanizeTimeFromHoursWithoutSeconds(operationProdTime);
    },
  },
  {
    key: 'combinedEquipmentClassInfo',
    rowIdentity: 'combined-equipment-class-info',
    label: EquipmentClassLabelTrans,
    getCustomValue: ({ equipmentClassIdentity, equipmentClassName }) => {
      if(!equipmentClassIdentity) return;

      return getResourceCombinedName({ identity: equipmentClassIdentity, name: equipmentClassName });
    },
  },
];

export const OPERATION_DETAILS_SECOND_BLOCK_SUMMARY_SCHEMA = [
  {
    key: 'status',
    label: SheetOperationStatusLabelTrans,
    rowIdentity: 'operation-status',
    // eslint-disable-next-line react/prop-types
    getCustomValue: ({ status }) =>
      <Chip
          style={{
            backgroundColor: OPERATION_STATUS_CHIP_BACKGROUND_COLORS_MAP[status],
            color: status === SHEET_OPERATION_STATUS.FINISHED ? 'white' : 'inherit',
          }}
          label={SHEET_OPERATION_STATUS_TITLES_TRANS_MSG_MAP[status]}
      />,
  },
  {
    key: 'progress',
    label: SheetOperationProgressLabelTrans,
    rowIdentity: 'operation-progress',
    getCustomValue: ({ progress, entitiesInBatchAmount }) =>
      renderSheetOperationProgress(entitiesInBatchAmount, progress),
  },
  {
    key: 'operationLabor',
    label: CurrentOperationLaborLabelTrans,
    rowIdentity: 'operation-labor',
    getCustomValue: ({ operationLabor, progress }) => {
      if(_isNil(operationLabor)) return NoDataLabelTrans;

      const initialHumanizedLaborValue = humanizeTimeFromHoursWithoutSeconds(operationLabor);

      const currentLaborValue = calculateLaborValue(operationLabor, progress);

      const currentHumanizedLaborValue = humanizeTimeFromHoursWithoutSeconds(currentLaborValue);

      return (
        <div>
          {currentHumanizedLaborValue}
          {' '}
          {OutOfLabelTrans}
          {' '}
          {initialHumanizedLaborValue}
        </div>
      );
    },
  },
  {
    key: 'orderName',
    label: OrderLabelTrans,
    rowIdentity: 'order-name',
    getCustomValue: ({ orderName }) => {
      if(orderName === null)
        return WithoutOrderLabelTrans;

      return orderName;
    },
  },
  {
    key: 'sheetIdentity',
    label: SheetLabelTrans,
    rowIdentity: 'sheet-identity',
  },
  {
    key: 'entitiesInBatchAmount',
    label: EntitiesInBatchAmountLabelTrans,
    rowIdentity: 'entities-in-batch-amount',
  },
  {
    key: 'combinedDepartmentInfo',
    rowIdentity: 'combined-department-info',
    label: DepartmentLabelTrans,
    getCustomValue: ({ departmentIdentity, departmentName }) => {
      if(!departmentIdentity) return;

      return getResourceCombinedName({ identity: departmentIdentity, name: departmentName });
    },
  },
];

const OPERATION_STATUS_CHIP_BACKGROUND_COLORS_MAP = {
  [SHEET_OPERATION_STATUS.CREATED]: MATERIAL_UI_THEME_COLOR_CODES.DEFAULT,
  [SHEET_OPERATION_STATUS.IN_PRODUCTION]: MATERIAL_UI_THEME_COLOR_CODES.WARNING,
  [SHEET_OPERATION_STATUS.PAUSED]: MATERIAL_UI_THEME_COLOR_CODES.WARNING,
  [SHEET_OPERATION_STATUS.FINISHED]: MATERIAL_UI_THEME_COLOR_CODES.SUCCESS,
};


const getFormattedDateValue = date => {
  if(!date) return;

  return moment(date).format(FORMAT_SHORT_TIME);
};

export const OPERATION_DETAILS_INFO_GRID_PROPS = {
  labelItemGridBreakpoints: {
    xs: 4,
  },
  valueItemGridBreakpoints: {
    xs: true,
  },
};

export const STATUS_CHANGE_BUTTON_IDENTITY = {
  START_SHEET_OPERATION: 'START_SHEET_OPERATION',
  PAUSE_SHEET_OPERATION: 'PAUSE_SHEET_OPERATION',
  CONTINUE_SHEET_OPERATION: 'CONTINUE_SHEET_OPERATION',
  FINISH_SHEET_OPERATION: 'FINISH_SHEET_OPERATION',
  CONSUME_ENTITIES: 'CONSUME_ENTITIES',
};

const checkEquipmentAvailabilityOnOperationStatusChangeHandler = (props, state, continueOrStartOperationCb) => {

  const {
    checkOperationInProductionOnEquipment,
    shouldCheckEquipmentAvailability,
  } = props;

  const { sheetOperationEditableFieldsData: { equipment } } = state;

  if (!shouldCheckEquipmentAvailability || !equipment) return continueOrStartOperationCb();

  const { id: equipmentId } = equipment;

  checkOperationInProductionOnEquipment(equipmentId)
    // если checkOperationInProductionOnEquipment возвращает не null, значит оборудование занято
    .then(operationOnEquipmentCombinedName => {

      if (!operationOnEquipmentCombinedName) return continueOrStartOperationCb();

      return sendEquipmentIsNotAvailableNotification(operationOnEquipmentCombinedName);
    });
};

const continueOrStartOperationBtnDisabled = ({ isEquipmentForOperationRequired }, { sheetOperationEditableFieldsData: { equipment } }) =>
  isEquipmentForOperationRequired && !equipment;

export const SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES = {
  PAUSE: 'PAUSE',
  FINISH: 'FINISH',
};

const STATUS_CHANGE_BUTTONS = {
  [STATUS_CHANGE_BUTTON_IDENTITY.START_SHEET_OPERATION]: {
    btnIdentity: STATUS_CHANGE_BUTTON_IDENTITY.START_SHEET_OPERATION,
    btnTitle: (
      <Trans id="sheet_operation_review@start_operation_button">
        Начать работу
      </Trans>
    ),
    onBtnClick: (props, state) => checkEquipmentAvailabilityOnOperationStatusChangeHandler(
      props,
      state,
      props.startSheetOperation,
    ),
    isDisabled: continueOrStartOperationBtnDisabled,
  },
  [STATUS_CHANGE_BUTTON_IDENTITY.PAUSE_SHEET_OPERATION]: {
    btnIdentity: STATUS_CHANGE_BUTTON_IDENTITY.PAUSE_SHEET_OPERATION,
    btnTitle: (
      <Trans id="sheet_operation_review@pause_operation_button">
        Остановить работу
      </Trans>
    ),
    onBtnClick: (props, state, setState) =>
      setState({ sheetOperationStatusChangeDialogViewMode: SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.PAUSE }),
  },
  [STATUS_CHANGE_BUTTON_IDENTITY.CONTINUE_SHEET_OPERATION]: {
    btnIdentity: STATUS_CHANGE_BUTTON_IDENTITY.CONTINUE_SHEET_OPERATION,
    btnTitle: (
      <Trans id="sheet_operation_review@continue_operation_button">
        Возобновить работу
      </Trans>
    ),
    onBtnClick: (props, state) => checkEquipmentAvailabilityOnOperationStatusChangeHandler(
      props,
      state,
      props.continueSheetOperation,
    ),
    isDisabled: continueOrStartOperationBtnDisabled,
  },
  [STATUS_CHANGE_BUTTON_IDENTITY.FINISH_SHEET_OPERATION]: {
    btnIdentity: STATUS_CHANGE_BUTTON_IDENTITY.FINISH_SHEET_OPERATION,
    btnTitle: (
      <Trans id="sheet_operation_review@finish_operation_button">
        Завершить работу
      </Trans>
    ),
    onBtnClick: (props, state, setState) =>
      setState({ sheetOperationStatusChangeDialogViewMode: SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.FINISH }),
  },
};

export const STATUS_CHANGE_ACTION_BUTTONS_FOR_CURRENT_STATUS_MAP = {

  [SHEET_OPERATION_STATUS.CREATED]: [
    STATUS_CHANGE_BUTTONS[STATUS_CHANGE_BUTTON_IDENTITY.START_SHEET_OPERATION],
  ],

  [SHEET_OPERATION_STATUS.PAUSED]: [
    STATUS_CHANGE_BUTTONS[STATUS_CHANGE_BUTTON_IDENTITY.CONTINUE_SHEET_OPERATION],
  ],

  [SHEET_OPERATION_STATUS.IN_PRODUCTION]: [
    STATUS_CHANGE_BUTTONS[STATUS_CHANGE_BUTTON_IDENTITY.PAUSE_SHEET_OPERATION],
    STATUS_CHANGE_BUTTONS[STATUS_CHANGE_BUTTON_IDENTITY.FINISH_SHEET_OPERATION],
  ],

};

export const CONSUME_ENTITIES_BUTTON =  {
  btnIdentity: STATUS_CHANGE_BUTTON_IDENTITY.CONSUME_ENTITIES,
  btnTitle: (
    <Trans id="sheet_operation_review@consume_entities_button">
      Потребление ДСЕ
    </Trans>
  ),
  onBtnClick: props => props.setIsConsumeEntitiesDialogOpen(true),
};

export const CONSUME_ENTITIES_BUTTON_FOR_CURRENT_STATUS_MAP = {
  [SHEET_OPERATION_STATUS.IN_PRODUCTION]: [
    CONSUME_ENTITIES_BUTTON,
  ],
};

export const ADDITIONAL_ACTION_MENU_OPTION_IDENTITY = {
  PAUSE_SHEET: 'PAUSE_SHEET',
  SPLIT_ENTITY_BATCH: 'SPLIT_ENTITY_BATCH',
};

export const PAUSE_SHEET_DISABLE_REASON_FOR_SHEET_OPERATION_STATUS_MAP = {
  [SHEET_OPERATION_STATUS.CREATED]: (
    <Trans id="sheet_operation_review.pause_sheet_disable_reason@sheet_operation_not_started">
      Для остановки маршрутного листа важно зафиксировать операцию, на которой это произошло, и прогресс по ней.
      Если вы хотите остановить маршрутный лист на выбранной операции в текущем состоянии, то начните работу по
      этой операции, сразу же остановите работы и укажите прогресс выполнения 0%, после этого опция остановки
      маршрутного листа станет доступной
    </Trans>
  ),
  [SHEET_OPERATION_STATUS.IN_PRODUCTION]: (
    <Trans id="sheet_operation_review.pause_sheet_disable_reason@sheet_operation_in_production">
      Для остановки маршрутного листа важно зафиксировать операцию, на которой это произошло, и прогресс по ней.
      Если вы хотите остановить маршрутный лист на выбранной операции, то остановите работу по этой операции и
      укажите её актуальный прогресс выполнения, после этого опция остановки маршрутного листа станет доступной
    </Trans>
  ),
  [SHEET_OPERATION_STATUS.FINISHED]: (
    <Trans id="sheet_operation_review.pause_sheet_disable_reason@sheet_operation_finished">
      Нельзя остановить маршрутный лист на завершенной операции
    </Trans>
  ),
};

export const ADDITIONAL_ACTIONS_MENU_OPTIONS = [
  {
    menuItemIdentity: ADDITIONAL_ACTION_MENU_OPTION_IDENTITY.PAUSE_SHEET,
    menuItemTitle: (
      <Trans id="sheet_operation_review@pause_sheet_additional_menu_item">
        Остановить маршрутный лист
      </Trans>
    ),
    onMenuItemClick: (props, state, setState) => setState({ isSheetPausing: true }),
    isMenuItemVisible: (props, state) => {

      if(props.PermissionsManager.isDenied(PERMISSION.PAUSE_OR_RESUME_SHEET)) {
        return false;
      }

      const { sheetOperationData, isPauseSheetAdditionalActionHidden } = props;

      const { sheetOperationEditableFieldsData } = state;

      if(!_isFunction(isPauseSheetAdditionalActionHidden)) {
        return true;
      }

      return !isPauseSheetAdditionalActionHidden(sheetOperationData, sheetOperationEditableFieldsData);
    },
    getDisabledMenuItemTooltip: props => {
      //eslint-disable-next-line
      const { sheetOperationData: { status } } = props;
      const pauseSheetDisableReason = PAUSE_SHEET_DISABLE_REASON_FOR_SHEET_OPERATION_STATUS_MAP[status];

      return pauseSheetDisableReason || null;
    },
  },
  {
    menuItemIdentity: ADDITIONAL_ACTION_MENU_OPTION_IDENTITY.SPLIT_ENTITY_BATCH,
    menuItemTitle: (
      <Trans id="sheet_operation_review@split_entity_batch_additional_menu_item">
        Разделить партию
      </Trans>
    ),
    onMenuItemClick: (props, state, setState) => setState({ isEntityBatchSplitting: true }),
    isMenuItemVisible: (props, state) => {

      if(props.PermissionsManager.isDenied(PERMISSION.SPLIT_ENTITY_BATCH)) return false;

      const { sheetOperationData, isSplitEntityBatchAdditionalActionHidden } = props;

      if(!_isFunction(isSplitEntityBatchAdditionalActionHidden)) return true;

      const { sheetOperationEditableFieldsData } = state;

      return !isSplitEntityBatchAdditionalActionHidden(sheetOperationData, sheetOperationEditableFieldsData);
    },
    getDisabledMenuItemTooltip: props => {
      const {
        sheetOperationData: {
          status,
          isPreviousSheetOperationFinished,
        },
      } = props;

      if(status !== SHEET_OPERATION_STATUS.CREATED)
        return(
          <Trans id="sheet_operation_review.split_entity_batch_disable_reason@sheet_operation_wrong_status">
            Делить партию маршрутного листа разрешается только до начала работ по операции, когда она находится в
            статусе "Запланирована"
          </Trans>
        );

      if(!isPreviousSheetOperationFinished)
        return(
          <Trans id="sheet_operation_review.split_entity_batch_disable_reason@previous_sheet_operation_is_not_finished">
            Партия маршрутного листа ещё не находится на этой операции, предыдущие операции не завершены
          </Trans>
        );
    },
  },
];

export const ADDITIONAL_ACTIONS_MENU_POSITION_PROPS = {

  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const EQUIPMENT_IS_NOT_AVAILABLE_NOTIFICATION_ID = 'EQUIPMENT_IS_NOT_AVAILABLE_NOTIFICATION_ID';

const _copyOperationName = operationOnEquipmentCombinedName => {
  copyToClipboard(operationOnEquipmentCombinedName, '.sheet-operation-review-dialog__dialog-paper')
    .then(() => {
      sendNotification(
        <Trans id="sheet_operation_review@operation_name_was_copied">
          Название операции успешно скопировано
        </Trans>,
        NOTIFICATION_LEVEL.SUCCESS,
      );
      clearNotifications([EQUIPMENT_IS_NOT_AVAILABLE_NOTIFICATION_ID]);
    })
    .catch(() =>
      sendNotification(
        <Trans id="sheet_operation_review@operation_name_was_not_copied">
          Произошла ошибка, название операции не было скопировано
        </Trans>,
        NOTIFICATION_LEVEL.ERROR,
      ));
};

export const sendEquipmentIsNotAvailableNotification = operationOnEquipmentCombinedName =>
  sendNotification(
  <NotificationContentWithAction
      onClick={() => _copyOperationName(operationOnEquipmentCombinedName)}
      message={
      <Trans id="sheet_operation_review@selected_equipment_is_used_for_another_operation">
        Выбранное оборудование используется для выполнения операции "{operationOnEquipmentCombinedName}".
        Выберите другое оборудование или дождитесь когда освободится текущее
      </Trans>
    }
      actionTitle={
      <Trans id="sheet_operation_review@copy_operation_name_notification_button">
        Скопировать название операции
      </Trans>
    }
  />,
    NOTIFICATION_LEVEL.INFO,
    {
      timeOut: 8000,
      closeOnClick: false,
      id: EQUIPMENT_IS_NOT_AVAILABLE_NOTIFICATION_ID,
    },
);