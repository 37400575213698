import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextFormField } from '../../TextFormField/TextFormField';
import {
  getProgressInAmountFromPercent,
  getProgressInPercentFromAmount,
  prepareSheetOperationProgressValueToDisplay,
  SHEET_OPERATION_PROGRESS_MODE,
  SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP,
} from './constants';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import './style.css';
import { onlyNumsNormalizer } from '@bfg-frontend/normalizers';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';

export const SheetOperationProgressInput = props => {

  const {
    progress,
    onChange,
    label,
    autoFocus,
    error,
    entitiesInBatchAmount,
    sheetOperationProgressMode,
    onSheetOperationProgressModeChange,
  } = props;

  const inputRef = useRef(null);

  const changeHandler = useCallback(
    value => {
      onChange(onlyNumsNormalizer(value));
    },
    [onChange],
);

  const progressModeChangeHandler = useCallback(
    (e, value) => {
      /*
      * При клике на кнопки переключения режима фокус с инпута сбрасывается и приходится делать дополнительный клик по
      * инпуту, чтобы начать вводить значение. Для удобства делаем фокус на инпут при переключении режима ввода прогресса
      */
      inputRef.current.focus();

      /*
      * По умолчанию кнопки в компоненте ToggleButtonGroup можно "отжимать" - если кликнуть на активную кнопку, то она
      * станет неактивной. То есть можно сделать так, что ни одна из кнопок не будет активной и value будет равно null.
      * Для этого компонента такой функционал не нужен - какой-то из режимов ввода должен быть выбран всегда. Поэтому,
      * если value === null, то не меняем значение sheetOperationProgressMode
      */
      if (value === null) return;

      onSheetOperationProgressModeChange(value);

    },
    [onSheetOperationProgressModeChange],
  );

  return (
    <div>

      <TextFormField
          className="sheet-operation-progress-input"
          wrapperClassName="sheet-operation-progress-input__wrapper"
          onChange={changeHandler}
          value={progress}
          autoFocus={autoFocus}
          error={error}
          helperText={
            !!error ?
              null :
              renderAnotherModeProgressHelper(progress, entitiesInBatchAmount, sheetOperationProgressMode)
          }
          label={(
            <span>
              {label},
              {' '}
              {SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP[sheetOperationProgressMode]}
            </span>
          )}
          fullWidth
          inputProps={{
            inputRef,
            endAdornment: (
              <ToggleButtonGroup
                  value={sheetOperationProgressMode}
                  exclusive
                  onChange={progressModeChangeHandler}
                  className="sheet-operation-progress-input__toggle-button-group"
              >
                <ToggleButton
                    value={SHEET_OPERATION_PROGRESS_MODE.PERCENT}
                    className="sheet-operation-progress-input__toggle-button"
                >
                  {SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP[SHEET_OPERATION_PROGRESS_MODE.PERCENT]}
                </ToggleButton>
                <ToggleButton
                    value={SHEET_OPERATION_PROGRESS_MODE.AMOUNT}
                    className="sheet-operation-progress-input__toggle-button"
                >
                  {SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP[SHEET_OPERATION_PROGRESS_MODE.AMOUNT]}
                </ToggleButton>
              </ToggleButtonGroup>
            ),
          }}
      />
    </div>
  );
};

const renderAnotherModeProgressHelper = (progress, entitiesInBatchAmount, sheetOperationProgressMode) => {
  const {
    anotherModeProgressValue,
    anotherModeProgressLabel,
  } = _getAnotherModeProgressDataToShow(progress, entitiesInBatchAmount, sheetOperationProgressMode);

  const anotherModeProgressValueToShow = prepareSheetOperationProgressValueToDisplay(anotherModeProgressValue);

  return (
    <span>
      {'~'}
      {' '}
      {anotherModeProgressValueToShow}
      {
        sheetOperationProgressMode === SHEET_OPERATION_PROGRESS_MODE.AMOUNT ?
          null :
          ' '
      }
      {anotherModeProgressLabel}
    </span>
  );
};

const _getAnotherModeProgressDataToShow = (progress, entitiesInBatchAmount, sheetOperationProgressMode) =>
  sheetOperationProgressMode === SHEET_OPERATION_PROGRESS_MODE.PERCENT ?
    ({
      anotherModeProgressValue: getProgressInAmountFromPercent(entitiesInBatchAmount, progress),
      anotherModeProgressLabel: SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP[SHEET_OPERATION_PROGRESS_MODE.AMOUNT],
    }) :
    ({
      anotherModeProgressValue: getProgressInPercentFromAmount(entitiesInBatchAmount, progress),
      anotherModeProgressLabel: SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP[SHEET_OPERATION_PROGRESS_MODE.PERCENT],
    });

SheetOperationProgressInput.propTypes = {
  progress: PropTypes.string.isRequired,
  onChange: FUNC_IS_REQUIRED_TYPE,
  label: PropTypes.node.isRequired,
  autoFocus: PropTypes.bool,
  error: PropTypes.node,
  entitiesInBatchAmount: PropTypes.number.isRequired,
  sheetOperationProgressMode: PropTypes.oneOf(Object.values(SHEET_OPERATION_PROGRESS_MODE)).isRequired,
  onSheetOperationProgressModeChange: FUNC_IS_REQUIRED_TYPE,
};

SheetOperationProgressInput.defaultProps = {
  autoFocus: false,
};