import React, { Component } from 'react';
import { SheetOperationsTable } from '../../SheetOperationsTable/SheetOperationsTable';
import PropTypes from 'prop-types';
import { SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ } from '../../SheetOperationsTable/SheetOperationsTable.types';
import {
  FUNC_IS_REQUIRED_TYPE,
  SHEET_OPERATION_STATUS_TYPE, SHEET_SUMMARY_TYPE,
} from '../../../../constants/propTypes';
import '../../../Sheets/SheetOperationTransactionsTable/style.css';
import { SheetOperationReviewDialogContainer } from '../../../common/SheetOperationReviewDialog/SheetOperationReviewDialogContainer';

import _constant from 'lodash/constant';

const isAlwaysTrueCb = _constant(true);


export class CompletedSheetReviewContent extends Component {

  static propTypes = {
    sheetOperationsTableId: PropTypes.string.isRequired,
    sheetOperationsTableData: PropTypes.arrayOf(
      PropTypes.shape({
        ...SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ,
        status: SHEET_OPERATION_STATUS_TYPE.isRequired,
        progress: PropTypes.number.isRequired,
      }),
    ),
    fetchSheetOperations: FUNC_IS_REQUIRED_TYPE,
    getSheetOperationsTableRowStyle: FUNC_IS_REQUIRED_TYPE,
    sheetToReviewData: SHEET_SUMMARY_TYPE,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedSheetOperationData: null,
    };
  };

  _renderSheetOperationsTable = () => {
    const {
      sheetOperationsTableId,
      sheetOperationsTableData,
      fetchSheetOperations,
      getSheetOperationsTableRowStyle,
    } = this.props;

    return(
      <SheetOperationsTable
          sheetOperationsTableId={sheetOperationsTableId}
          sheetOperationsTableData={sheetOperationsTableData}
          fetchSheetOperations={fetchSheetOperations}
          onSheetOperationRowClick={this._handleSheetOperationRowClick}
          getSheetOperationsTableRowStyle={getSheetOperationsTableRowStyle}
      />
    );
  };

  _handleSheetOperationRowClick = (_, sheetOperationData) => {

    const {
      sheetToReviewData: {
        entityIdentity,
        entityCode,
        entityName,
        sheetIdentity,
        entityBatchId,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },
    } = this.props;

    this.setState({
      selectedSheetOperationData: {
        ...sheetOperationData,
        entityIdentity,
        entityCode,
        entityName,
        sheetIdentity,
        entityBatchId,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },

    });
  };

  _renderSheetOperationReviewDialog = () => {
    const {
      selectedSheetOperationData,
    } = this.state;

    if(selectedSheetOperationData === null) return;

    return(
      <SheetOperationReviewDialogContainer
          closeDialog={this._handleSheetOperationReviewDialogClose}
          sheetOperationData={selectedSheetOperationData}
          areStatusChangeButtonsHidden={isAlwaysTrueCb}
          isPauseSheetAdditionalActionHidden={isAlwaysTrueCb}
          isSplitEntityBatchAdditionalActionHidden={isAlwaysTrueCb}
          isAssigneesChangeDisabled={isAlwaysTrueCb}
          isEquipmentChangeDisabled={isAlwaysTrueCb}
          isNoteChangeDisabled={isAlwaysTrueCb}
          isConsumeEntitiesActionHidden={isAlwaysTrueCb}
      />
    );
  };

  _handleSheetOperationReviewDialogClose = () => this.setState({ selectedSheetOperationData: null });

  render() {
    return (
      <div className="completed-sheet-review-content">
        {this._renderSheetOperationsTable()}
        {this._renderSheetOperationReviewDialog()}
      </div>
    );
  }
}