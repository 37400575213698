import React from 'react';
import PropTypes from 'prop-types';

import MuiPagination from '@mui/material/Pagination';
import './style.css';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';



const MATERIAL_UI_CLASSES = {
  root: 'common-pagination',
};

export const Pagination = props => {
  const {
    className,
    activePage,
    totalPagesAmount,
    changePage,
  } = props;

  return (
    <div className={className}>
      <MuiPagination
          classes={MATERIAL_UI_CLASSES}
          page={activePage}
          count={totalPagesAmount}
          onChange={(_, newActivePage) => changePage(newActivePage)}
          boundaryCount={2}
          color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
          shape="rounded"
      />
    </div>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  activePage: PropTypes.number.isRequired,
  totalPagesAmount: PropTypes.number.isRequired,
  changePage: FUNC_IS_REQUIRED_TYPE,
};