import cn from 'classnames';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React from 'react';
import { UserRoleSelectContainer } from '../UserRoleSelect/UserRoleSelectContainer';
import {
  AdditionalLabelTrans,
  AllAvailableUserRolesSelectedLabelTrans,
  FirstNameLabelTrans,
  LastNameLabelTrans,
  PasswordLabelTrans,
  PatronymicNameLabelTrans,
  UserIdentityLabelTrans,
  UserRolesListLabelTrans,
} from '../../../utils/commonTransComponents';
import { TextFormField } from '../../common/TextFormField/TextFormField';


export const CURRENT_USER_ENCRYPTED_FAKE_PASSWORD = '__________';
export const EMPTY_PASSWORD_VALUE = '';


const UserRolesFormField = props => {
  /* eslint-disable react/prop-types */
  const {
    label,
    onChange,
    value,
    error,
  } = props;
  /* eslint-enable react/prop-types */

  return (
    <div
        className={
          cn(
            'user-editing-screen__user-roles-select-field',
            {
              'user-editing-screen__user-roles-select-field--error': !!error,
            },
          )
        }
    >
      <UserRoleSelectContainer
          className="user-editing-screen__user-roles-select-component"
          title={label}
          onChange={onChange}
          value={value}
          error={error}
          isMulti
          placeholder={null}
          noOptionsMessage={AllAvailableUserRolesSelectedLabelTrans}
      />
    </div>
  );
};

export const USER_EDITING_SCREEN_FIELDS_SCHEMA = [
  {
    key: 'lastName',
    label: LastNameLabelTrans,
    component: TextFormField,
    className: 'user-editing-screen__last-name-field',
  },
  {
    key: 'name',
    label: FirstNameLabelTrans,
    component: TextFormField,
    className: 'user-editing-screen__name-field',
  },
  {
    key: 'patronymicName',
    label: PatronymicNameLabelTrans,
    component: TextFormField,
    className: 'user-editing-screen__patronymic-name-field',
  },
  {
    key: 'userRoles',
    label: UserRolesListLabelTrans,
    component: UserRolesFormField,
  },
  {
    key: 'identity',
    label: UserIdentityLabelTrans,
    component: TextFormField,
    className: 'user-editing-screen__identity-field',
  },
  {
    key: 'password',
    label: PasswordLabelTrans,
    component: TextFormField,
    className: 'user-editing-screen__password-field',
    getProps: (state, { isCreatingMode }) => {
      const {
        values,
        showPassword,
        setShowPassword,
        isPasswordFieldFocused,
        setIsPasswordFieldFocused,
      } = state;

      const {
        password,
      } = values;

      const isPasswordValueEmpty = password === EMPTY_PASSWORD_VALUE;

      return {
        onFocus: () => setIsPasswordFieldFocused(true),
        onBlur: () => setIsPasswordFieldFocused(false),
        value: !isCreatingMode && isPasswordValueEmpty && !isPasswordFieldFocused ?
          CURRENT_USER_ENCRYPTED_FAKE_PASSWORD :
          password,
        inputProps: {
          type: showPassword && !isPasswordValueEmpty ? 'text' : 'password',
          endAdornment: isPasswordValueEmpty ? null : (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        },
      };
    },
  },
  {
    key: 'note',
    label: AdditionalLabelTrans,
    component: TextFormField,
    className: 'user-editing-screen__note-field',
  },
];