import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';

import 'moment/locale/ru';
import moment from 'moment';

import cn from 'classnames';
import Button from '@mui/material/Button';
import { TextField, Tooltip } from '@mui/material';

import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import './style.css';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';
import { CloseLabelTrans, CurrentDateLabelTrans } from '../../../../utils/commonTransComponents';

import _get from 'lodash/get';
import _constant from 'lodash/constant';
import { useSelector } from 'react-redux';
import { appLanguageSelector } from '../../../../reducers/appState/selectors';


const intervalDefaultValidator = (intervalStart, intervalStop) =>
  moment(intervalStart).isAfter(intervalStop) ?
    'Interval start date is later than it\'s stop date' :
    undefined;

export const DateTimeIntervalForm = props => {
  const {
    format,
    intervalStartLabel,
    intervalStopLabel,
    intervalStartInitValue,
    intervalStopInitValue,
    showTodayButton,
    submitBtnLabel,
    cancelChangesBtnLabel,
    onSubmit,
    validator,
    isSubmitBtnHidden,
  } = props;

  const [intervalStart, setIntervalStart] = useState(intervalStartInitValue);
  const [intervalStop, setIntervalStop] = useState(intervalStopInitValue);

  const language = useSelector(appLanguageSelector);

  const handleSubmit = e => {
    e.preventDefault();

    onSubmit(intervalStart, intervalStop);
  };

  const isIntervalChanged =
    !moment(intervalStartInitValue).isSame(intervalStart) ||
    !moment(intervalStopInitValue).isSame(intervalStop);

  const handleIntervalChangesCancel = useCallback(
    () => {
      setIntervalStart(intervalStartInitValue);
      setIntervalStop(intervalStopInitValue);
    },
    [setIntervalStart, intervalStartInitValue, setIntervalStop, intervalStopInitValue],
  );

  const intervalErrorMsg = validator(intervalStart, intervalStop);

  return (
    <LocalizationProvider
        dateAdapter={AdapterMoment}
        locale={_get(window.config, ['AVAILABLE_LOCALES', language, 'ISOLanguageCode'], 'en')}
    >
      <form
          className="date-time-interval-form"
          onSubmit={handleSubmit}
      >
        <div className="date-time-interval-form__date-time-pickers-section-wrap">
          {_renderDateTimePicker({
            format,
            label: intervalStartLabel,
            value: intervalStart,
            onChange: setIntervalStart,
            showTodayButton,
          })}
          {_renderDateTimePicker({
            format,
            label: intervalStopLabel,
            value: intervalStop,
            onChange: setIntervalStop,
            showTodayButton,
          })}
          {
            isSubmitBtnHidden(intervalStart, intervalStop) ?
              null :
              _renderButton({
                className: 'date-time-interval-form__submit-button',
                label: submitBtnLabel,
                type: 'submit',
                variant: MATERIAL_UI_VARIANT.CONTAINED,
                color: MATERIAL_UI_STYLE_COLOR.PRIMARY,
                errorMsg: intervalErrorMsg,
              })
          }
          {
            isIntervalChanged ?
              _renderButton({
                className: 'date-time-interval-form__cancel-button',
                label: cancelChangesBtnLabel,
                variant: MATERIAL_UI_VARIANT.CONTAINED,
                color: MATERIAL_UI_STYLE_COLOR.INHERIT,
                onClick: handleIntervalChangesCancel,
              }) :
              null
          }
        </div>
      </form>
    </LocalizationProvider>
  );
};


DateTimeIntervalForm.propTypes = {
  intervalStartLabel: PropTypes.node,
  intervalStopLabel: PropTypes.node,
  submitBtnLabel: PropTypes.node,
  cancelChangesBtnLabel: PropTypes.node,
  intervalStartInitValue: PropTypes.string.isRequired,
  intervalStopInitValue: PropTypes.string.isRequired,
  showTodayButton: PropTypes.bool,
  onSubmit: FUNC_IS_REQUIRED_TYPE,
  format: PropTypes.string.isRequired,
  validator: PropTypes.func,
  isSubmitBtnHidden: PropTypes.func.isRequired,
};

DateTimeIntervalForm.defaultProps = {
  showTodayButton: true,
  intervalStartLabel: 'Interval start',
  intervalStopLabel: 'Interval stop',
  submitBtnLabel: 'Ok',
  cancelChangesBtnLabel: 'Cancel',
  validator: intervalDefaultValidator,
  isSubmitBtnHidden: _constant(false),
};

/* eslint-disable react/prop-types */
const _renderDateTimePicker = ({
  label,
  format,
  value,
  onChange,
  showTodayButton = true,
  className,
}) =>
/* eslint-enable react/prop-types */
   (
     <div className="date-time-interval-form__date-time-picker-wrap">
       <div className="date-time-interval-form__date-time-picker-label">
         {label}
       </div>
       <div className={cn('date-time-interval-form__date-time-picker', className)}>
         <MobileDateTimePicker
             DialogProps={{
               className: 'date-time-interval-form__modal-container',
             }}
             renderInput={
               props =>
                 <TextField variant={MATERIAL_UI_VARIANT.OUTLINED} {...props} />
             }
             inputFormat={format}
             showTodayButton={showTodayButton}
             todayText={CurrentDateLabelTrans}
             value={value}
             disableMaskedInput
             toolbarTitle={null}

             /*
             * Начиная с версии mui 5 колбэк onChange срабатывает сразу же при выборе чего угодно (год, день, время), т.е.
             * это происходит до нажатия на кнопку "Применить" (okText) у пикера. При этом, получается, что, фактически,
             * кнопки "Применить" и "Отменить" (cancelText) у пикера особо ничего не делают, если на onChange мы сразу
             * сохраняем значение, эти кнопки просто закрывают модальник пикера.
             * Есть 2 варианта развития событий:
             * 1. Использовать onAccept вместо onChange (при этом, т.к. onChange обязательный пропс, нужно будет
             * для него тоже задать какую-то заглушку). В этом случае, работает также, как до версии 5 - мы выбираем
             * значение в пикере, но изменение в логике происходит только при нажатии кнопки "Применить". При нажатии
             * "Отмена" модальник пикера просто закрывается, т.е. изменений никаких не фиксируется. Но, здесь, видимо,
             * есть недоработка в текущей версии пикера, потому что он, судя по всем, не сбрасывает свой локальный
             * стейт при отмене. Получается, в поле для ввода мы видим неизмененное значение, которое передается в
             * value, но если повторно открыть пикер, то там будут прошлые изменения, которые мы "отменили". Т.е.,
             * если делать такую реализацию, нужно добавлять ещё кастомную логику отмены на onClose или что-то подобное.
             * На самом деле, \то будет не так уж и просто, потому что, видимо, придется как-то хранить ещё состояние
             * дат до открытия модальника пикера, т.к. сбрасывать просто на initialValue здесь не логично, здесь именно
             *  нужно сбросить на значение, которое было при текущем открытии модальника пикера (на сброс к
             * initialValue есть отдельная кнопка вне пикеров)
             * 2. Использовать новое дефолтное поведение пикера, когда он изменяет значение при любом действии у пикера.
             * В этом случае, кнопка "Применить" и "Отмена", как будто бы, просто закрывают модальник пикера. Это
             * не очень хорошо, потому что пользователь может ожидать другое поведение. Поэтому, если делать этот
             * вариант, то логичным будет оставить одну кнопку для пользователя "Закрыть", которая будет описывать
             * именно то, поведение, которое и происходит при нажатии - просто закрывается модальник пикера.
             *
             * Пока принято сделать вариант 2, т.к. он проще.
             */
             onChange={valueMoment => onChange(valueMoment.format())}
             okText={CloseLabelTrans}
             cancelText={null}
         />
       </div>
     </div>
  );

const _renderButton = ({
  /*eslint-disable react/prop-types*/
  className,
  label,
  type,
  variant,
  color,
  onClick,
  errorMsg,
  /*eslint-enable react/prop-types*/
}) => {

  const ButtonElement = (
    <Button
        className={cn('date-time-interval-form__button', className)}
        type={type}
        variant={variant}
        color={color}
        onClick={onClick}
        disabled={!!errorMsg}
        disableElevation
    >
      {label}
    </Button>
  );

  /*
  * Чтобы тултип адекватно работал с задизейбленной кнопкой нужна её любая обертка в child у Tooltip, в данном
  * случае, это span
  * */
  return !!errorMsg ?
    <Tooltip
        classes={{
          popper: 'date-time-interval-form__button-tooltip-popper',
          tooltip: 'date-time-interval-form__button-tooltip',
        }}
        title={errorMsg}
    >
      <span>
        {ButtonElement}
      </span>
    </Tooltip> :
    ButtonElement;
};
