import { DEPARTMENT_MODEL, EQUIPMENT_CLASS_MODEL, SHEET_OPERATION_MODEL } from '../../../constants/models';
import PropTypes from 'prop-types';
import {
  EquipmentClassLabelTrans,
  NoDataLabelTrans,
  NotSelectedLabelTrans,
} from '../../../utils/commonTransComponents';
import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import React, { useMemo } from 'react';
import {
  createCaEntitiesAutocompleteLoadOptionsActionCreator,
} from '../../../reducers/autocomplete/actions';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';
import { AUTOCOMPLETE_DEFAULT_PROPS, AUTOCOMPLETE_PROP_TYPES } from '../../common/Autocomplete/constants';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const EquipmentClassesAutocomplete = props => {

  const { id, departmentId, shouldLoadOptions } = props;

  const loadOptionsActionCreator = useMemo(() => {
    if(!shouldLoadOptions || !departmentId) {
      return undefined;
    }

    return _getEquipmentClassAutocompleteLoadOptionsActionCreator(departmentId);
  },
    [departmentId, shouldLoadOptions]);

  return (
    <AutocompleteContainer
        id={id}
        noOptionsMessage={NoDataLabelTrans}
        loadOptionsActionCreator={loadOptionsActionCreator}
        title={EquipmentClassLabelTrans}
        placeholder={NotSelectedLabelTrans}
        {...props}
    />
  );
};

const _getEquipmentClassAutocompleteLoadOptionsActionCreator = departmentId =>
  createCaEntitiesAutocompleteLoadOptionsActionCreator({
    requestModel: EQUIPMENT_CLASS_MODEL,
    getRequestQuery: inputValue => {
      const filters = [
        {
          column: [DEPARTMENT_MODEL, 'id'].join('__'),
          filterType: FILTER_TYPES.EQUALS,
          filterValue: departmentId,
        },
      ];

      /*
      Если задан пропс shouldPreloadData, то при первом запросе inputValue будет пустым, поэтому добавляем эти фильтры
      только если value есть
      */
      if (inputValue) {
        filters.push({
          filterGroupType: FILTER_GROUP_TYPES.OR,
          filters: [
            {
              column: 'name',
              filterType: FILTER_TYPES.CONTAINS,
              filterValue: inputValue || '',
            },
            {
              column: 'identity',
              filterType: FILTER_TYPES.CONTAINS,
              filterValue: inputValue,
            },
          ],
        });
      }

      return {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters,
        },
        limit: 70,
      };
    },
    getRequestOptions: () => ({
      modelRelations: {
        [SHEET_OPERATION_MODEL]: {
          level: 1,
        },
        [DEPARTMENT_MODEL]: {
          level: 2,
          relates: SHEET_OPERATION_MODEL,
        },
      },
    }),
  });

EquipmentClassesAutocomplete.propTypes = {
  ...AUTOCOMPLETE_PROP_TYPES,
  departmentId: NUMBER_OR_STRING_TYPE,
  shouldLoadOptions: PropTypes.bool,
};

EquipmentClassesAutocomplete.defaultProps = {
  ...AUTOCOMPLETE_DEFAULT_PROPS,
  shouldLoadOptions: true,
  getOptionLabel: getResourceCombinedName,
  getOptionValue: ({ id }) => id,
};