import { subDecimals } from '../../../../../utils/decimal';
import React from 'react';
import { Trans } from '@lingui/macro';
import {
  calculateDetailedEntitiesReserveOrConsumeFromEntityBatches,
  reserveAndConsumeEntitiesComparatorFactory,
} from '../../../../../utils/reserveAndConsumeCommon/index';


export const CONSUME_ENTITIES_TABLE_COLUMNS_SCHEMA = [
  {
    key: 'entityCombinedName',
    title: (
      <Trans id="sheet_operation_consume_entities_dialog.table_column@entity">
        ДСЕ
      </Trans>
    ),
  },
  {
    key: 'consumedAmount',
    title: (
      <Trans id="sheet_operation_consume_entities_dialog.table_column@consumed_amount">
        Потреблено
      </Trans>
    ),
  },
  {
    key: 'requiredAmount',
    title: (
      <Trans id="sheet_operation_consume_entities_dialog.table_column@required_amount">
        Всего необходимо
      </Trans>
    ),
  },
  {
    key: 'reservedAmount',
    title: (
      <Trans id="sheet_operation_consume_entities_dialog.table_column@reserved_amount">
        Зарезервировано
      </Trans>
    ),
  },
];

export const consumeEntitiesComparator = reserveAndConsumeEntitiesComparatorFactory('reservedAmount');

export const consumeEntityValidator = (
  initialConsumeData,
  newConsumedAmount,
) => {

  if(newConsumedAmount === '') {
    return (
      <Trans id="sheet_operation_consume_entities_dialog@consumed_field_can_not_be_empty_error">
        Необходимо задать значение потребленных ДСЕ
      </Trans>
    );
  }

  const newConsumedAmountNumber = Number(newConsumedAmount);

  const {
    requiredAmount: initialRequiredAmount,
    reservedAmount: initialReservedAmount,
    consumedAmount: initialConsumedAmount,
  } = initialConsumeData;

  /*
  * Есть подозрение, что довольно часто в кейсах использования у большинства ДСЕ не будет изменений, поэтому будет
  * достаточно много перерисовок, когда будет вызываться валидатор и, по сути, остальные проверки перебирать не нужно,
  * поэтому данная проверка выполняется специально отдельно раньше остальных проверок (кроме проверки на пустую строку,
  * т.к. это особый случай и удобней сначала выполнить её и не делать преобразования к Number, если она не прошла)
  * */
  if(newConsumedAmountNumber === initialConsumedAmount)
    return undefined;

  if(newConsumedAmountNumber < initialConsumedAmount) {

    return (
      <Trans id="sheet_operation_consume_entities_dialog@less_than_consumed_error">
        Новое значение потребленных ДСЕ не может быть меньше текущего значения
      </Trans>
    );
  }

  if (subDecimals(newConsumedAmountNumber, initialConsumedAmount) > initialReservedAmount) {
    return (
      <Trans id="sheet_operation_consume_entities_dialog@more_than_reserved_error">
        Нельзя потребить больше, чем зарезервировано
      </Trans>
    );
  }

  if(newConsumedAmountNumber > initialRequiredAmount) {
    return (
      <Trans id="sheet_operation_consume_entities_dialog@more_than_required_error">
        Нельзя потребить больше, чем требуется для полной комплектации
      </Trans>
    );
  }

  return undefined;
};

export const consumeEntitiesPrepareTableRowData = (initialConsumeData, newConsumedAmount, consumedAmountError) => {
  const {
    entityId,
    entityCombinedName,
    requiredAmount,
    reservedAmount: initialReservedAmount,
    consumedAmount: initialConsumedAmount,
    remainingAmount: initialRemainingAmount,
  } = initialConsumeData;

  return {
    entityId,
    entityCombinedName,
    requiredAmount,
    initialConsumedAmount,

    //Значение редактируемого поля таблицы, оставляем его строкой, т.к. компоненты поля ввода ожидают строку в значении
    consumedAmount: newConsumedAmount,

    consumedAmountError,

    reservedAmount: !!consumedAmountError ?
      initialReservedAmount :
      subDecimals(
        initialReservedAmount,
        subDecimals(Number(newConsumedAmount), initialConsumedAmount),
      ),

    remainingAmount: !!consumedAmountError ?
      initialRemainingAmount :
      subDecimals(requiredAmount, Number(newConsumedAmount)),
  };
};

export const CONSUME_ENTITIES_SCHEMA_FIELDS = {
  entityPrevAmountKey: 'consumedAmount',
  entityBatchAvailableAmountKey: 'reservedAmount',
};

export const consumeEntitiesPrepareDataBeforeSubmit = (initialConsumedData, newConsumedAmountData) =>
  calculateDetailedEntitiesReserveOrConsumeFromEntityBatches(
    initialConsumedData,
    newConsumedAmountData,
    CONSUME_ENTITIES_SCHEMA_FIELDS,
  );