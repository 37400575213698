import {
  DELETE_ALL_ASSEMBLY_SHEETS_RESERVE_DATA,
  DELETE_ASSEMBLY_SHEET_RESERVE_DATA,
  SET_ASSEMBLY_SHEET_RESERVE_DATA,
} from './actions';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
const initialState = {};


export const reserveData = (state = initialState, action) => {
  const { type, data, sheetId, sheetsIdsToOmit } = action;

  switch (type) {
  case SET_ASSEMBLY_SHEET_RESERVE_DATA: {
    return {
      ...state,
      [sheetId]: data,
    };
  }
  case DELETE_ASSEMBLY_SHEET_RESERVE_DATA: {
    return _omit(state, sheetId);
  }
  case DELETE_ALL_ASSEMBLY_SHEETS_RESERVE_DATA: {
    return !sheetsIdsToOmit.length ? {} : _pick(state, sheetsIdsToOmit);
  }
  default: {
    return state;
  }
  }
};