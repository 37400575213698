
/*
* Служебная функция для формирования моделей таблиц из наборов колонок. На вход подается массив объектов с описанием колонок
* модели. Предполагается, что в описании колонки присутствует ОБЯЗАТЕЛЬНЫЙ КЛЮЧ columnName
* На выходе получается объект для записи в модели таблицы по ключу fields. В ключах - значение columnName, в значениях -
* объект с описанием колонки + order, выставленный по индексу массива, который был передан на вход функции.
*
* Кейс использования:
* 1. формируем массив с объектами описаниями колонок в нужном порядке
* 2. вызываем для него эту функцию
* 3. результат записываем в ключ fields
*
* Другой кейс, нужно оформить модель таблицы "такую же как вот эту, но не такую же", например, опустить одну из колонок.
* Если модель другой таблицы была создана с помощью initializeTableModelFields, то существует константа с описанием
* всех колонок таблицы в правильном порядке. Теперь, чтобы избавиться от одной из колонок нужно:
*
* 1. взять константу модели исходной таблицы
* 2. выполнить filter, чтобы отфильтровать ненужную(ые) колонку(и)
* 3. вызвать для реузультирующего массива функцию initializeTableModelFields
* 4. результат записать в fields
*
* */
export const initializeTableModelFields = fields => fields
  .reduce(
    (acc, item, index) => {

      acc[item.columnName] = {
        ...item,
        order: index,
      };

      return acc;
    },
    {},
  );