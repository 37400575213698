import React from 'react';

import { Provider } from 'react-redux';
import { NotificationSystem } from './components/NotificationSystem/NotificationSystem';
import { TranslationsProvider } from './components/TranslationsProvider/TranslationsProvider';

import { store, history } from './reduxStore';

import { ConnectedRouter } from 'connected-react-router';

import { AppContainer } from './components/App/AppContainer';
import { GlobalAppSpinnerContainer } from './components/GlobalAppSpinner/GlobalAppSpinnerContainer';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import './style.css';

import { AppConfirmProvider } from './components/AppConfirm/AppConfirmProvider';
import { blue, pink } from '@mui/material/colors';
import { FullScreenProvider } from './fullscreen/FullScreenProvider';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
  typography: {
    fontFamily: [
      'Roboto Condensed',
      'sans-serif',
    ].join(','),
  },
});

const RootComponent = () =>
  <Provider store={store}>
    <React.Fragment>
      <TranslationsProvider>
          <ConnectedRouter history={history}>
            <React.Fragment>
              <ThemeProvider theme={theme}>
                <FullScreenProvider>
                  <AppConfirmProvider>
                    <AppContainer />
                  </AppConfirmProvider>
                </FullScreenProvider>
              </ThemeProvider>
              <NotificationSystem />
            </React.Fragment>
          </ConnectedRouter>
      </TranslationsProvider>
      <GlobalAppSpinnerContainer />
    </React.Fragment>
  </Provider>;

export default RootComponent;
