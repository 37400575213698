import React from 'react';
import ReactDOM from 'react-dom';

import RootComponent from './RootComponent';

const rootElement = document.getElementById('root');

const renderReactAppRootComponent = RootComponent =>
  ReactDOM.render(<RootComponent />, rootElement);

renderReactAppRootComponent(RootComponent);

if(module.hot) {
  module.hot.accept('./RootComponent', () => {
    const NextRootComponent = require('./RootComponent').default;
    renderReactAppRootComponent(NextRootComponent);
  });
}
