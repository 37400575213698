import React, { Component } from 'react';

import { SheetsContainer } from '../../Sheets/SheetsContainer';
import { SheetInProductionReviewContentContainer } from './SheetInProductionReviewContent/SheetInProductionReviewContentContainer';

import { PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE } from '../../../constants/routes';
import { SHEET_TYPE } from '../../../constants/sheets';
import { SHEETS_IN_PRODUCTION_FILTERS_SCHEMA } from '../../Sheets/sheetsFilters/sheetsFilters';
import { fetchSheetsInProduction } from '../../../operations/sheets';
import { getSheetPartsAndMaterialsInfoLabels } from '../../../utils/sheets';
import { Trans } from '@lingui/macro';


export class SheetsInProduction extends Component {

  render() {
    return (
      <SheetsContainer
          className="sheets-in-production"
          mainRoutePath={PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE}
          sheetsIdentity={SHEET_TYPE.IN_PRODUCTION}
          sheetItemIdProperty="sheetId"
          fetchSheetTypeEntitiesActionCreator={fetchSheetsInProduction}
          sheetReviewContentComponent={SheetInProductionReviewContentContainer}
          noDataText={
            <Trans id="sheets_in_production@no_sheets_in_production">
              В данный момент нет ни одного маршрутного листа в производстве
            </Trans>
          }
          filtersSchema={SHEETS_IN_PRODUCTION_FILTERS_SCHEMA}
          getSheetItemAdditionalInfoLabels={getSheetPartsAndMaterialsInfoLabels}
      />
    );
  }
}