import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import './style.css';


/*
* Данный компонент создан для того, чтобы передать реф из компонента Tooltip из material-ui и повесить на оборачиваемый
* кастомный компонент, который передается в children. Без этого, в Tooltip можно использовать только компоненты из
* material-ui или примитивные элементы
* */
export const TooltipWithForwardedRef = React.forwardRef(
  ({ refElementClassName, children, ...restProps }, ref) => (
    <Tooltip
        {...restProps}
        classes={{
          popper: 'tooltip-with-forwarded-ref__tooltip-popper',
          tooltip: 'tooltip-with-forwarded-ref__tooltip',
        }}
    >
      <div className={refElementClassName} ref={ref}>
        {children}
      </div>
    </Tooltip>
  ),
);

TooltipWithForwardedRef.propTypes = {
  children: PropTypes.element.isRequired,
  refElementClassName: PropTypes.string,
};