import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';
import { Table } from '../../Table/Table';
import { USER_MODEL } from '../../../constants/models';
import { TOOLS_COLUMN_NAME } from '../../../constants/table';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import './style.css';
import { USERS_TABLE_DATA_TYPE } from './constants';
import { CardWithNavHeader } from '../../common/CardWithNavHeader/CardWithNavHeader';
import {
  ACTIVE_USERS_TABLE_CUSTOM_FILTERS,
  DEACTIVATED_USERS_TABLE_CUSTOM_FILTERS,
} from './usersTableCustomFilters/usersTableCustomFilters';
import { AppConfirmContext } from '../../AppConfirm/AppConfirmContext';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../hoc/withPermissionsManager/constants';
import {
  ActiveLabelTrans,
  CreateNewUserLabelTrans,
  DeactivateLabelTrans,
  EditLabelTrans,
  UsersListLabelTrans,
} from '../../../utils/commonTransComponents';


const deactivatedUsersTableGetRowStyleCb = () => ({ opacity: 0.5 });
const USERS_TABLE_MENU_PROPS = {
  disableSummaryOption: true,
};


export class UsersTableScreen extends Component {
  static propTypes = {
    appUserId: PropTypes.number,
    activeUsersTableId: PropTypes.string,
    deactivatedUsersTableId: PropTypes.string,
    fetchActiveUsers: FUNC_IS_REQUIRED_TYPE,
    fetchDeactivatedUsers: FUNC_IS_REQUIRED_TYPE,
    activeUsersTableData: USERS_TABLE_DATA_TYPE,
    deactivatedUsersTableData: USERS_TABLE_DATA_TYPE,
    editUser: FUNC_IS_REQUIRED_TYPE,
    deactivateUser: FUNC_IS_REQUIRED_TYPE,
    onAddUser: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  static contextType = AppConfirmContext;

  _renderCreateNewUserButton = () =>
    this.props.PermissionsManager.isGranted(PERMISSION.CREATE_USER) ?
      <Button
          className="users-table-screen__create-new-user-btn"
          onClick={this.props.onAddUser}
      >
        {CreateNewUserLabelTrans}
      </Button> :
      null;

  _renderTable = ({
    tableId,
    className,
    fetchRemoteTableData,
    rowsData,
    customCellRenderersProps,
    getRowStyle,
    customFilters,
  }) => (
    <Table
        tableId={tableId}
        tableModel={USER_MODEL}
        className={className}
        fetchRemoteTableData={fetchRemoteTableData}
        rowsData={rowsData}
        noDataContent={
          <Trans id="users_table@no_users_data">
            Нет данных о пользователях
          </Trans>
        }
        tableMenu={USERS_TABLE_MENU_PROPS}
        customCellRenderersProps={customCellRenderersProps}
        getRowStyle={getRowStyle}
        customFilters={customFilters}
    />
  );

  _renderActiveUsersTable = () => {
    const {
      fetchActiveUsers,
      activeUsersTableData,
      activeUsersTableId,
    } = this.props;

    return this._renderTable({
      tableId: activeUsersTableId,
      className: 'users-table-screen__active-users-table',
      fetchRemoteTableData: fetchActiveUsers,
      rowsData: activeUsersTableData,
      customCellRenderersProps: {
        [TOOLS_COLUMN_NAME]: {
          toolsOptions: this._getActiveUsersTableToolsOptions(),
        },
      },
      customFilters: ACTIVE_USERS_TABLE_CUSTOM_FILTERS,
    });
  };

  _getActiveUsersTableToolsOptions = () => {

    const {
      context: confirm,
      props,
    } = this;

    const {
      appUserId,
      PermissionsManager,
      editUser,
      deactivateUser,
    } = props;



    /*
    * Не реализуем невидимость опций через параметр isVisible, т.к. в данном случае нет ни одной постоянной
    * опции, а когда все опции возвращают false в isVisible, то отрисовывается некрасивое пустое меню. Этот
    * функционал больше для случая, когда есть постоянные опции. В случае, если опции совсем не разрешены, просто не
    * формируем
    *
    * В то же время, опция "деактивации пользователя" бывает недоступна ещё для ряда в таблице с текущим пользователем.
    * В этом случае мы не можем просто скрыть её, т.к. для определения видимости нам нужны данные ряда. При этом,
    * опять же, возникает ситуация, что может быть разрешена только эта опция, т.е. она будет одна в списке и для
    * неё isVisible будет false, снова будет неарксивое пустое меню. Поэтому, пока решено в этом случае реализовать
    * недоступность этой опции через isDisabled + сообщение почему опция недоступна. Поэтому и дублирующая кнопка
    * внутри формы аналогично реализует такую же логику с видимостью и дизейблом
    * */

    const editUserOption = PermissionsManager.isGranted(PERMISSION.EDIT_USER) ?
      ({
        id: 'edit-option',
        title: EditLabelTrans,
        onClick: editUser,
      }) :
      null;

    const deactivateUserOptions = PermissionsManager.isGranted(PERMISSION.DEACTIVATE_USER) ?
      ({
        id: 'deactivate-option',
        title: DeactivateLabelTrans,
        onClick: id => confirm({
          confirmModalTitle: (
            <Trans id="users_table@confirm_user_deactivate_title">
              Сделать пользователя неактивным
            </Trans>
          ),
          confirmText: (
            <Trans id="users_table@confirm_user_deactivate_message">
              Вы уверены, что хотите сделать пользователя неактивным? Неактивные пользователи не имеют доступа к
              приложению, но история их действий сохраняется
            </Trans>
          ),
        })
          .then(() => deactivateUser(id)),
        isDisabled: id => id === appUserId,
        getTooltip: id => {
          if(id !== appUserId) return null;

          return (
            <Trans id="users_table@you_can_not_deactivate_yourself_menu_option_tooltip">
              Нельзя сделать неактивной свою собственную учетную запись
            </Trans>
          );
        },
      }) :
      null;

    return [
      editUserOption,
      deactivateUserOptions,
    ]
      .filter(option => option !== null);
  };

  _renderDeactivatedUsersTable = () => {
    const {
      fetchDeactivatedUsers,
      deactivatedUsersTableData,
      deactivatedUsersTableId,
    } = this.props;

    return this._renderTable({
      tableId: deactivatedUsersTableId,
      className: 'users-table-screen__deactivated-users-table',
      fetchRemoteTableData: fetchDeactivatedUsers,
      rowsData: deactivatedUsersTableData,
      getRowStyle: deactivatedUsersTableGetRowStyleCb,
      customFilters: DEACTIVATED_USERS_TABLE_CUSTOM_FILTERS,
    });
  };

  render() {
    return (
      <Paper className="users-table-screen">
        <CardWithNavHeader
            className="users-table-screen__card-with-header"
            title={UsersListLabelTrans}
            actionsContent={this._renderCreateNewUserButton()}
            navTabs={[
              {
                tabId: 'active-users-table',
                tabTitle: ActiveLabelTrans,
                tabContent: () => (
                  <div className="tab-content-wrapper">
                    {this._renderActiveUsersTable()}
                  </div>
                ),
              },
              {
                tabId: 'deactivated-users-table',
                tabTitle: (
                  <Trans id="users_table@deactivated_users_tab_title">
                    Неактивные
                  </Trans>
                ),
                tabContent: () => (
                  <div className="tab-content-wrapper">
                    {this._renderDeactivatedUsersTable()}
                  </div>
                ),
              },
            ]}
        />
      </Paper>
    );
  }
}