import React from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  MATERIAL_UI_DIALOG_MAX_WIDTH,
  MATERIAL_UI_STYLE_COLOR,
  MATERIAL_UI_VARIANT,
} from '../../../../constants/materialUI';
import { useConfirm } from '../../../AppConfirm/AppConfirmContext';
import { clearDbActionCreator } from '../../../../operations/dataAdministration/clearDb';

export const ClearDbScreen = () => {

  const dispatch = useDispatch();
  const confirm = useConfirm();

  const clearDbHandler = () => confirm({
    confirmText: (
      <Trans id="clear_db@confirm">
        Данные удалятся без возможности восстановления, вы уверены?
      </Trans>
    ),
    confirmDialogInnerProps: {
      dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
    },
  })
    .then(() => dispatch(clearDbActionCreator()));

  return (
    <>
      <Button
          style={{
            fontSize: '1.4rem',
          }}
          color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
          variant={MATERIAL_UI_VARIANT.CONTAINED}
          onClick={clearDbHandler}
      >
        <Trans id="clear_db@title">
          Очистить базу данных
        </Trans>
      </Button>
    </>
  );
};