import React from 'react';
import {
  NoMatchesFoundLabelTrans,
} from '../../../../utils/commonTransComponents';

import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../../reducers/autocomplete/actions';

import { SHEET_OPERATION_FEATURES_MODEL } from '../../../../constants/models';

import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../../api/restCollectionApi';

import { FILTER_COMPONENTS_MAP } from '../../../common/Filters/FilterComponents';

import _omit from 'lodash/omit';


const sheetOperationFeaturesRenderFilterChipCb = options => {
  const {
    filterKey,
    filters,
    onApplyFilters,
    getFilterTitle,
    renderDefaultChip,
  } = options;

  const {
    filterAdditionalData,
    filterType,
  } = filters[filterKey];

  return filterAdditionalData
    .map(data => {

      const {
        id,
        name,
      } = data;

      const filterChipContent = (
        <span>
          {getFilterTitle(filters[filterKey])}
          {': '}
          <b>{name}</b>
        </span>
      );

      return renderDefaultChip({
        filterKey: [filterKey, id].join('__'),
        label: filterChipContent,
        onDelete: () => {

          /* убираем данные по удаляемой сущности из ключа filterAdditionalData */
          const updatedFilterAdditionalData = filterAdditionalData
            .filter(({ id: sheetOperationId }) => sheetOperationId !== id);

          /*
          * если в filterAdditionalData пустой массив, значит удалили последний чип для ключа name характеристики,
          * в таком случае необходимо обнулить фильтр для всего ключа
          * */
          if(updatedFilterAdditionalData.length === 0)
            return onApplyFilters(_omit(filters, filterKey));

          return onApplyFilters({
            ...filters,
            [filterKey]: {
              /* формируем массив идентификаторов сущностей для фильтрации */
              filterValue: updatedFilterAdditionalData.map(({ id }) => id),
              filterAdditionalData: updatedFilterAdditionalData,
              filterType,
            },
          });
        },
      });
    });
};

const getSheetOperationFeaturesFilterComponentProps = isDisabled => ({
  isMulti: true,
  //getOptionLabel: ({ name }) => name,
  autocompleteId: 'sheet-operation-features-filter',
  noOptionsMessage: NoMatchesFoundLabelTrans,
  loadOptionsActionCreator: createCaEntitiesAutocompleteLoadOptionsActionCreator(
    {
      requestModel: SHEET_OPERATION_FEATURES_MODEL,
      getRequestQuery: inputValue => {

        const predefinedFilter = {
          column: 'disabled',
          filterType: FILTER_TYPES.EQUALS,
          filterValue: isDisabled,
        };

        return {
          filter: {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters: !!inputValue ?
              ([
                predefinedFilter,
                {
                  column: 'name',
                  filterType: FILTER_TYPES.CONTAINS,
                  filterValue: inputValue,
                },
              ]) :
              [predefinedFilter],
          },
        };
      },
    },
  ),
});


const getSheetOperationFeaturesTableCustomFilters = isDisabled => ({
  name: {
    renderFilterChip: sheetOperationFeaturesRenderFilterChipCb,
    filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
    filterComponentProps: getSheetOperationFeaturesFilterComponentProps(isDisabled),
  },
});

export const ACTIVE_SHEET_OPERATION_FEATURES_TABLE_CUSTOM_FILTERS = getSheetOperationFeaturesTableCustomFilters(false);
export const DISABLED_SHEET_OPERATION_FEATURES_TABLE_CUSTOM_FILTERS = getSheetOperationFeaturesTableCustomFilters(true);
