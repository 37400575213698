import {
  fetchPartAndMaterialsReserveDataForAssemblyEntityBatch,
  fetchSheetsInProduction,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
  sendEntityBatchSplitNotification,
} from '../../../../operations/sheets';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import {
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE, STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_MAIN_ROUTE,
} from '../../../../constants/routes';
import { matchPath } from 'react-router-dom';
import { getMasterAppDepartmentIdsFromRouteParam }
from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { ENTITY_BATCH_PROVIDING_STATE, SHEET_TYPE } from '../../../../constants/sheets';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import {
  fetchSheetTypeRemoteTableDataCbFactory,
  SHEETS_REMOTE_TABLE_DATA_WITH_PARAMS,
} from '../../../../components/Sheets/SheetsContainer';
import { fetchMasterDepartmentsTasksToDoRemoteTableData } from '../../../../operations/masterWorkspace/index';
import { getEquipmentClassInDepartmentTasksTableId } from '../../../../utils/tables';
import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
} from '../../../../operations/tasks';
import { push } from 'connected-react-router';
import { clearDefaultSheetPartsAndMaterialsToConsume } from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import { addEntitiesToStore } from '../../../../reducers/entities/actions';
import {
  ENTITY_BATCH_MODEL, SHEET_MODEL,
} from '../../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../restCollectionApi/index';
import { masterTasksToDoAdditionalFiltersSelector } from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import { fetchFullAssemblySheetsPartsAndMaterials } from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import {
  deleteAssemblySheetReserveData,
} from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { sheetOperationReviewIsConsumeEntitiesDialogOpenSelector } from '../../../../reducers/sheetOperationReview/selectors';


/*
* Подробно о влиянии события деления партии на типы приложения описано в комментарии к handleEntityBatchSplit в
* SheetInProductionReviewContentContainer, MasterTasksTodoContainer и TasksViewContainer. Т.к. эти обработчики вызываются в
* интерфейсах, где вызывается и само действие деление партии, то в них для всех остальных разделов, где надо обновить
* данные, в основном, сбрасывается "клиентский кэш" (т.е. данные из стора), чтобы, при повторном входе в интерфейсы,
* запросы выполнились по новой и данные сохранились.
* В случае с получением события деления ппартии другими пользователями нужна похожая обработка, но, с учетом, того, что
* пользователи могут находиться в этот момент в разделах, которые сразу же должны быть обновлены - в этом случае,
* сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если перед перезапросом данных мы сначала
* очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим новые данные, то у пользователя в интерфейсе
* случится "скачок" (данные пропадут, кое-где мелькнет сообщение о пустых данных, а потом тут же появятся новые данные),
* что нежелательно.
* Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
* повторном входе в интерфейсы запросы выполнились заново
* */
export const handleEntityBatchSplit = message =>
  (dispatch, getState) => {
    const {
      sheetId,
      sheetIdentity,
      entityBatchId,
      newCreatedSheetId,
    } = message;

    //Выводим нотификейшен о приостановке МЛ
    sendEntityBatchSplitNotification(sheetIdentity);

    const state = getState();

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);


    //Если находимся в разделе "МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });
    if(sheetsInProductionRouteMatch !== null)
      return dispatch(_updateIfOnSheetsInProductionScreen(sheetId, currentPathname, allTasksTablesIds));


    //Если находимся в разделе "Комплектование - "стандартные" неукомплектованные маршрутные листы"
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        newCreatedSheetId,
      ));

    //Если находимся в разделе "Комплектоване - сборочные" маршрутные листы
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });

    if (assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null) {
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        entityBatchId,
        currentPathname,
        allTasksTablesIds,
      ));
    }

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
      ));


    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_MAIN_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        sheetId,
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
      ));
    }

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за появившегося при разделения партии нового МЛ,
    * чтобы при следующем входе в эти разделы данные были запрошены заново
    * */
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetReserveData({ sheetId }),
      deleteAssemblySheetConsumeData({ sheetId }),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);

  };

/*
* При разделении партии создается новая партия и соответствующий ей МЛ, этот МЛ должен сражу же появиться в списке МЛ в
* производстве, у исходной партии в списке должно измениться количество, т.е. нужен перезапрос данных. Для всех
* остальных разделов очищаем данные кэша, чтобы при повторном входе данные запросились заново.
* Дополнительно, если находимся по роуту просмотра МЛ, партию, которого разделили, то редиректимся на список
* МЛ, т.к. данные должны обновиться
* */
const _updateIfOnSheetsInProductionScreen = (sheetId, currentPathname, allTasksTablesIds) =>
  (dispatch, getState) => {

    let actionsToDispatch = [
      clearTableRemoteData([
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetReserveData({ sheetId }),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ];

    //TODO похоже, здесь может быть баг, т.к. очистка не выполняется, если окно потребления открыто, но далее
    // по коду идёт редирект, если текущий МЛ просматриваем и, теоретически, обработка в SheetOperationReviewDialog,
    // которая вызовет перезапрос данных может не выполниться. Надо выносит окно просмотра операции на отдельный роут
    const isConsumeEntitiesDialogOpen = sheetOperationReviewIsConsumeEntitiesDialogOpenSelector(getState());

    if(!isConsumeEntitiesDialogOpen)  {
      actionsToDispatch.push(deleteAssemblySheetConsumeData({ sheetId }));
    }

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.IN_PRODUCTION,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchSheetsInProduction),
    ));

    const sheetInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE}/:sheetId`,
    });

    if(
      sheetInProductionReviewRouteMatch !== null &&
      sheetInProductionReviewRouteMatch.params.sheetId === sheetId.toString()
    ) {
      dispatch(push(PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE));
    }
  };


/*
* Если находимся в разделе "Комплектование - стандартные МЛ", то очищаем данные всех интерфейсов, где должны быть обновления
* из-за деления партии. После этого запрашиваем сущности изменнного исходного МЛ нового отделенного МЛ, чтобы понять является
*  ли он неукомплектованными и если являются, то обновить список МЛ, ожидающих комплектацию, т.к. у исходного МЛ
* изменилось количество, а новый должен добавиться в список..
* С учетом всех описанных в комментарии к handleEntityBatchSplit в SheetInProductionReviewContentContainer особенностей
* про клиентскую таблицу МЛ, ожидающих комплектацию, требуется:
* - Записать сущность только что созданного отделенного МЛ в хранилище entities, обновить сущность исходного МЛ, т.к.
* количество должно измениться. У нас нет сущностей МЛ при обработке броадкастинга, поэтому предварительно мы их
* должны запросить.
* - Перезапросить информацию о МЛ, которые можно укомплектовать
* - Если дополнительно находимся при просмотре детальной информации МЛ исходной партии, то редиректимся на список
* МЛ, т.к. сейчас принято, что это логично, партия к которой относится просматриваемый МЛ разделена, её данные
* изменились, чтобы они обновились делаем этот редирект. Сами данные детальной комплектации тоже очищаются ещё раньше
* этой проверки
*
* Когда таблица станет серверной, то эту обработку надо будет заменить на перезапрос текущей страницы просмотра,
* аналогично обрботке списка МЛ в производстве. (Возможно, в будущем и запрос о возможности комплектации можно будет
* сделать на 1 МЛ, а не на все МЛ, тогда тут можно будет подумать над новой логикой запроса и хранения кэшированной
* информации по МЛ, которые можно укомплектовать).
* */
const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen = (sheetId, currentPathname, allTasksTablesIds, newCreatedSheetId) =>
  dispatch => {

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetReserveData({ sheetId }),
      deleteAssemblySheetConsumeData({ sheetId }),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);

    const defaultSheetWaitingPartsAndMaterialsReviewRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    if(
      defaultSheetWaitingPartsAndMaterialsReviewRouteMatch !== null &&
      defaultSheetWaitingPartsAndMaterialsReviewRouteMatch.params.sheetId === sheetId.toString()
    ) {
      dispatch(push(STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE));
    }

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'id',
            filterType: FILTER_TYPES.ONE_OF,
            filterValue: [sheetId, newCreatedSheetId],
          },
        ],
      },
      with: SHEETS_REMOTE_TABLE_DATA_WITH_PARAMS,
    };

    dispatch(fetchSheetsInProduction(query))
      .then(response => {

        if(!response || response.responseMeta.count === 0) return;

        const {
          entities,
          responseEntitiesIds,
        } = response;

        const { isAssembly } = entities[SHEET_MODEL][sheetId];

        if(isAssembly) return;
        /*
        * Новая отделенная партия копирует информацию исходной партии, поэтому не важно на основании какой из партий
        * мы будем проверять укомплектованность партий, берем первую по порядку из запрошенных (т.е. любую), флаг
        * укомплектованности у партий будет одинаков, поэтом это не важно. Либо обе партии укомплектованы, тогда
        * ничего обновлять в списке не надо, либо обе партии неукомплектованы, тогда нужно добавить новую партию в
        * список, а по старой обновить данные, т.к. количество изменилось. Не паримся и обновляем все данные, добавляя,
        * из в entities, а не только количество. После этого перезапрашиваем МЛ, которые можно укомплектовать
        * */
        const entityBatchId = responseEntitiesIds[ENTITY_BATCH_MODEL][0];

        const { providingState } = entities[ENTITY_BATCH_MODEL][entityBatchId];

        if(providingState === ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED) {
          dispatch(addEntitiesToStore(entities));

          //Это thunk, поэтому нет смысла объединять с прошлым dispatch
          return dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage());
        }
      });

  };

/*
* Если находимся в разделе "Комплектование. Сборочные", то очищаем все данные других интерфейсов кроме этого,
* где должны быть обновления из-за деления партии, а для этого раздела перезапрашиваем данные для таблицы сборочных МЛ.
* */
const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = (
  sheetId,
  entityBatchId,
  currentPathname,
  allTasksTablesIds,
) =>
  dispatch => {
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
      deleteAssemblySheetConsumeData({ sheetId }),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchFullAssemblySheetsPartsAndMaterials),
    ));

    const partsAndMaterialsReserveRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    // если находимся НЕ на экране резервирвоания или на экране резервирования другого МЛ, то удаляем данные
    // резервирования только того МЛ, от которого отделилась партия, т.к. в нём меняется крличество ДСЕ для
    // резервирования
    if (
      partsAndMaterialsReserveRouteMatch === null ||
      partsAndMaterialsReserveRouteMatch.params.sheetId !== sheetId.toString()
    ) {
      return dispatch(deleteAssemblySheetReserveData({ sheetId }));
    }

    // если на экране резервирования, то перезапрашиваем данные резервирования для него
    return dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(entityBatchId, sheetId));
  };

/*
* Если находимся в разделе "Мастер. Требуется выполнить", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за деления партии, а для этого раздела перезапрашиваем данные для таблицы заданий,
* которые нужно выполнить в подразделениях из параметров роутинга, с учетом текущих параметров таблицы
* */
const _updateIfOnMasterTasksTodoScreen = (sheetId, departmentIds, allTasksTablesIds) =>
  (dispatch, getState) => {

    let actionsToDispatch = [
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetReserveData({ sheetId }),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ];

    const isConsumeEntitiesDialogOpen = sheetOperationReviewIsConsumeEntitiesDialogOpenSelector(getState());

    if(!isConsumeEntitiesDialogOpen)  {
      actionsToDispatch.push(deleteAssemblySheetConsumeData({ sheetId }));
    }

    dispatch(actionsToDispatch);

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_ID,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(departmentIds, masterTasksToDoAdditionalFilters, tableParams)),
    ));
  };


/*
* Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем все данные других
* интерфейсов кроме этого, где должны быть обновления из-за деления партии, а для этого раздела перезапрашиваем
* задания для текущих параметров просматриваеомй таблицы, чтобы информация обновилась
* */
const _updateIfOnTasksViewScreen = (
  sheetId,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
) =>
  (dispatch, getState) => {

    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    let actionsToDispatch = [
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
      deleteAssemblySheetReserveData({ sheetId }),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ];

    const isConsumeEntitiesDialogOpen = sheetOperationReviewIsConsumeEntitiesDialogOpenSelector(getState());

    if(!isConsumeEntitiesDialogOpen)  {
      actionsToDispatch.push(deleteAssemblySheetConsumeData({ sheetId }));
    }

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
        )),
    ));
  };